import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Fieldset,
} from "@chakra-ui/react";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import SelectAgent from "./select-agent";
import SelectMerchant from "./select-merchant";

const DisconnectFromMerchantForm = ({
  cache,
  clearCache,
}: {
  cache: object;
  clearCache: () => void;
}) => {
  const auth0 = useAuth0();

  const { colorScheme } = useAppSelector((state) => state.theme);

  const [selectedAgent, setSelectedAgent] = useState<NewAgentDomain | null>(
    null
  );
  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);

  useEffect(() => {
    setSelectedMerchants([]);
  }, [selectedAgent]);

  const resetValues = () => {
    setSelectedAgent(null);
    setSelectedMerchants([]);
    clearCache();
  };

  const disconnectAgent = async () => {
    try {
      await AdminService.disconnectAgentFromMerchants(auth0, {
        agentId: selectedAgent!.id,
        merchantIds: selectedMerchants!.map((m) => m.id),
      });
      toaster.create({
        type: "success",
        title: "Agent was successfully disconnected from selected merchants",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't disconnect agent from selected merchants", e);
      toaster.create({
        type: "error",
        title: "Couldn't disconnect agent from selected merchants",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content>
        <Field label="Select Agent" required>
          <SelectAgent
            isMulti={false}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
          />
        </Field>

        <Field label="Choose Merchants" required>
          <SelectMerchant
            isMulti={true}
            selectedMerchants={selectedMerchants}
            setSelectedMerchants={setSelectedMerchants}
            isDisabled={!selectedAgent}
            filterCallback={(merchantId) => {
              if (!selectedAgent) {
                return true;
              }

              return selectedAgent.merchants
                .map((m) => m.id)
                .includes(merchantId);
            }}
          />
        </Field>
      </Fieldset.Content>
      <Button
        colorPalette={colorScheme}
        onClick={disconnectAgent}
        disabled={!selectedMerchants.length || !selectedAgent}
        mt={8}
        mb={8}
        size="lg"
      >
        Disconnect Agent
      </Button>
    </Fieldset.Root>
  );
};

export default DisconnectFromMerchantForm;
