import { Flex, Link, Text } from "@chakra-ui/react";
import React from "react";

export default function PoweredByFooter() {
  return (
    <Flex justify="center">
      <Text>
        Powered by{" "}
        <Link
          href={process.env.REACT_APP_FUZEY_WEBSITE}
          fontWeight="bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fuzey
        </Link>
      </Text>
    </Flex>
  );
}
