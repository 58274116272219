import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { Checkbox } from "components/ui/checkbox";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import {
  StrictnessType,
  TierType,
} from "entities/domain/admin/merchants/merchant-domain";
import MerchantTierLimitsDomain from "entities/domain/admin/merchants/merchant-tier-limits-domain";
import React, { useEffect, useMemo, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import SelectMerchant from "./select-merchant";

interface MerchantTierOptionTypes {
  value?: TierType;
  label?: string;
}

interface MerchantTierStrictnessOptionTypes {
  value?: StrictnessType;
  label?: string;
}

const UpdateMerchantTier = () => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const [selectedTier, setSelectedTier] = useState<TierType | null>(
    selectedMerchant?.tier || null
  );

  const [selectedStrictness, setSelectedStrictness] =
    useState<StrictnessType | null>(selectedMerchant?.strictness || null);

  const [newLimits, setNewLimits] = useState<
    MerchantTierLimitsDomain | undefined
  >(selectedMerchant?.limits || undefined);
  const [campaignLimitPlaceholder, setCampaignLimitPlaceholder] =
    useState<string>("Enter a number");
  const [smsLimitPlaceholder, setSmsLimitPlaceholder] =
    useState<string>("Enter a number");

  const [isModifyingLimits, setIsModifyingLimits] = useState<0 | 1>(0);

  const [selectedTierValue, setSelectedTierValue] =
    useState<SingleValue<MerchantTierOptionTypes>>(null);

  const [selectedStrictnessValue, setSelectedStrictnessValue] =
    useState<SingleValue<MerchantTierStrictnessOptionTypes>>(null);

  const tierOptions: MerchantTierOptionTypes[] = [
    {
      value: TierType.UNLIMITED,
      label: "Unlimited",
    },
    {
      value: TierType.PROFESSIONAL,
      label: "Professional",
    },
    {
      value: TierType.GROWTH,
      label: "Growth",
    },
    {
      value: TierType.STARTER,
      label: "Starter",
    },
  ];

  const strictnessOptions: MerchantTierStrictnessOptionTypes[] = [
    {
      value: StrictnessType.HARD,
      label: "Hard",
    },
    {
      value: StrictnessType.SOFT,
      label: "Soft",
    },
  ];

  const handleTierChange = (e: SingleValue<MerchantTierOptionTypes>) => {
    setSelectedTier(e?.value || null);
    setSelectedTierValue(e);
  };

  const handleStrictnessChange = (
    e: SingleValue<MerchantTierStrictnessOptionTypes>
  ) => {
    setSelectedStrictness(e?.value || null);
    setSelectedStrictnessValue(e);
  };

  useEffect(() => {
    setSelectedTier(selectedMerchant?.tier || null);
    setSelectedTierValue(
      tierOptions.find((t) => t.value === selectedMerchant?.tier) || null
    );
    setSelectedStrictness(selectedMerchant?.strictness || null);
    setSelectedStrictnessValue(
      strictnessOptions.find((t) => t.value === selectedMerchant?.strictness) ||
        null
    );
    setSmsLimitPlaceholder(
      selectedMerchant?.limits?.monthlyOutgoingSmsMessages?.toString() ||
        "Enter a number"
    );
    setCampaignLimitPlaceholder(
      selectedMerchant?.limits?.monthlyCampaignMessages?.toString() ||
        "Enter a number"
    );
  }, [selectedMerchant]);

  const updateTier = async () => {
    if (!selectedMerchant || !selectedTier) {
      return;
    }

    setIsSaving(true);

    try {
      await AdminService.updateMerchantTier(auth0Context, {
        merchantId: selectedMerchant.id,
        tier: selectedTier || TierType.STARTER,
        strictness: selectedStrictness || StrictnessType.HARD,
        customLimits: newLimits,
      });

      toaster.create({
        type: "success",
        title: "Merchant Tier Updated Successfully",
      });
      setSelectedTier(null);
      setSelectedMerchant(null);
      setSelectedStrictness(null);
      setNewLimits(undefined);
      setCampaignLimitPlaceholder("Enter a number");
      setSmsLimitPlaceholder("Enter a number");
      setSelectedTierValue(null);
      setSelectedStrictnessValue(null);
    } catch (_err: unknown) {
      toaster.create({
        type: "error",
        title: "Couldn't update merchant's tier",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content gap={3} alignItems="center">
        <Field label="Select Merchant" required>
          <SelectMerchant
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
          />
        </Field>
        <Field label="Update Merchant's Tier" required>
          <Select
            placeholder="Select Tier"
            value={selectedTierValue}
            onChange={handleTierChange}
            options={tierOptions}
            styles={{
              ...getReactSelectStyles(colorMode || "light", colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: "100%",
              }),
            }}
            isDisabled={!selectedMerchant}
          />
        </Field>
        <Field label="Update Merchant's Tier Strictness" required>
          <Select
            placeholder="Select Strictness"
            value={selectedStrictnessValue}
            onChange={handleStrictnessChange}
            options={strictnessOptions}
            styles={{
              ...getReactSelectStyles(colorMode || "light", colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: "100%",
              }),
            }}
            isDisabled={!selectedMerchant}
          />
        </Field>
        <Field>
          <Checkbox
            checked={!!isModifyingLimits}
            onCheckedChange={() => {
              const isRemovingCheck = isModifyingLimits === 1;

              if (isRemovingCheck) {
                setNewLimits(selectedMerchant?.limits || undefined);
              }

              setIsModifyingLimits(isRemovingCheck ? 0 : 1);
            }}
            disabled={!selectedMerchant}
          >
            Custom Limits
          </Checkbox>
        </Field>
        {isModifyingLimits ? (
          <>
            <Field label="Monthly Outgoing SMS Messages">
              <Input
                placeholder={smsLimitPlaceholder}
                type="number"
                inputMode="numeric"
                min={-1}
                value={newLimits?.monthlyOutgoingSmsMessages || undefined}
                onChange={(e) => {
                  setNewLimits({
                    monthlyOutgoingSmsMessages: e.target.value
                      ? parseInt(e.target.value, 10)
                      : undefined,
                    monthlyCampaignMessages:
                      newLimits?.monthlyCampaignMessages || undefined,
                  });
                }}
              />
            </Field>
            <Field label="Monthly Campaign Messages">
              <Input
                placeholder={campaignLimitPlaceholder}
                type="number"
                inputMode="numeric"
                min={-1}
                value={newLimits?.monthlyCampaignMessages || undefined}
                onChange={(e) => {
                  setNewLimits({
                    monthlyOutgoingSmsMessages:
                      newLimits?.monthlyOutgoingSmsMessages || undefined,
                    monthlyCampaignMessages: e.target.value
                      ? parseInt(e.target.value, 10)
                      : undefined,
                  });
                }}
              />
            </Field>
          </>
        ) : null}
      </Fieldset.Content>
      <Button
        w="fit-content"
        size="lg"
        onClick={updateTier}
        disabled={isSaving || !selectedMerchant}
      >
        Save Tier
      </Button>
    </Fieldset.Root>
  );
};

export default UpdateMerchantTier;
