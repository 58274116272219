import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import { useColorMode } from "components/ui/color-mode";
import Select, { MultiValue, SingleValue } from "react-select";
import { setMerchantMakesAndModels } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import MerchantService from "services/merchant";
import { getSelectStylesForQueryBuilder } from "util/methods";
import TextFieldInput from "./TextFieldInput";

interface ModelOption {
  value: string;
  label: string;
}

const transformFromModelToModelOption = (make: string): ModelOption => ({
  value: make,
  label: make,
});

const extractModels = (makesAndModels: Record<string, string[]>): string[] => {
  return [
    ...new Set(
      Object.values(makesAndModels).reduce(
        (acc, model) => acc.concat(model),
        []
      )
    ),
  ];
};

const ModelFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { merchant, makesAndModels } = useAppSelector(
    (state) => state.merchant
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isLoadingMakesAndModels, setIsLoadingMakesAndModels] = useState(
    !Object.keys(makesAndModels).length
  );

  const getSelectedModel = (model: string): ModelOption | null => {
    if (!model) {
      return null;
    }

    const selectedModel = extractModels(makesAndModels).find(
      (m) => m === model
    );

    if (!selectedModel) {
      return null;
    }

    return transformFromModelToModelOption(selectedModel);
  };

  useEffect(() => {
    if (!isLoadingMakesAndModels || Object.keys(makesAndModels).length) {
      return;
    }

    MerchantService.getMerchantVehicleMakesAndModels(
      auth0Context,
      merchant.groupId
    )
      .then((mm) => {
        dispatch(setMerchantMakesAndModels(mm));
      })
      .finally(() => {
        setIsLoadingMakesAndModels(false);
      });
  }, []);

  const [modelOptions, setModelOptions] = useState<MultiValue<ModelOption>>(
    extractModels(makesAndModels).map(transformFromModelToModelOption)
  );

  const [selectedModelOptionValue, setSelectedModelOptionValue] = useState<
    SingleValue<ModelOption>
  >(getSelectedModel(value));

  useEffect(() => {
    setSelectedModelOptionValue(getSelectedModel(value));
  }, [value]);

  useEffect(() => {
    setModelOptions(
      extractModels(makesAndModels).map(transformFromModelToModelOption)
    );
  }, [makesAndModels]);

  return !isLoadingMakesAndModels &&
    Object.keys(makesAndModels).length === 0 ? (
    <TextFieldInput id={id} value={value} onChange={onChange} />
  ) : (
    <Select
      isLoading={isLoadingMakesAndModels}
      id={id}
      isMulti={false}
      placeholder="select model"
      isClearable={false}
      isSearchable={true}
      value={selectedModelOptionValue}
      onChange={(newValue) => {
        if (newValue && !("value" in newValue)) {
          return;
        }

        onChange(newValue?.value || "");
      }}
      options={modelOptions}
      styles={getSelectStylesForQueryBuilder(colorScheme, colorMode as "light" | "dark" || "light")}
    />
  );
};

export default ModelFieldInput;
