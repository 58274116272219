import { Icon } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";

const BackIconButton = ({
  onBackIconClick,
  displayBackIcon,
}: {
  onBackIconClick?: () => void;
  displayBackIcon?: boolean;
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Icon
        cursor="pointer"
        asChild={true}
        display={displayBackIcon ? "initial" : "none"}
        css={{
          "& svg": {
            height: "15px",
            width: "20px",
            fill:
              colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.500`,
          },
        }}
        onClick={onBackIconClick}
      >
        <ReactSVG src="/left-arrow.svg" />
      </Icon>
    </>
  );
};

export default BackIconButton;
