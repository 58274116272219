import { Button, Icon, useBreakpointValue } from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import { useColorMode } from "components/ui/color-mode";
import {
  DrawerBackdrop,
  DrawerContent,
  DrawerRoot,
} from "components/ui/drawer";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useState } from "react";
import { LuUserRoundPlus } from "react-icons/lu";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";

interface AddNewContactProps {
  prefilledName?: string;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
}

const AddNewContact = ({
  prefilledName,
  setRecentlyCreatedContact,
}: AddNewContactProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);

  return (
    <>
      <Button
        aria-label="Add new contact"
        variant={isBaseSize ? "plain" : "solid"}
        colorPalette={colorScheme}
        display="flex"
        gridGap={2}
        size="md"
        onClick={() => setOpenNewContactForm(true)}
        _focus={{ outline: "none" }}
      >
        <Icon
          as={LuUserRoundPlus}
          color={
            isBaseSize
              ? `${colorScheme}.400`
              : colorMode === "dark"
              ? `${colorScheme}.950`
              : "white"
          }
        />
        {!isBaseSize && "Create Contact"}
      </Button>

      <QuickAddContactForm
        searchString={prefilledName}
        isOpen={openNewContactForm}
        onClose={() => setOpenNewContactForm(false)}
        setRecentlyCreatedContact={setRecentlyCreatedContact}
        shouldRedirectToDetails={true}
      />
    </>
  );
};

export default AddNewContact;
