import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { convertToChannelGroup } from "util/constants";
import { format, isSameDay, parseISO } from "date-fns";
import { updateFormatAccordingToCountry } from "util/methods";
import CustomerChannelDomain from "./contact-channel-domain";
import CustomerAddressDomain from "./customer-address-domain";
import CustomerAttributeDomain, {
  AttributeValueType,
} from "./contact-attribute-domain";

const order: {
  [key: string]: number;
} = {
  sms: 1,
  whatsapp: 2,
  facebook: 3,
  zapier_facebook: 4,
  instagram: 5,
  email: 6,
};

const sortChannels = (a: CustomerChannelDomain, b: CustomerChannelDomain) => {
  const orderA = order[a.type] || 7;
  const orderB = order[b.type] || 7;

  return orderA - orderB;
};

export default class ContactDomain {
  id?: number;

  groupId?: string;

  name: string;

  surname: string;

  address: CustomerAddressDomain | undefined;

  channels: CustomerChannelDomain[];

  fullName: string;

  type: string;

  avatar: string;

  tagIds: string[];

  notes: string;

  isArchived: boolean;

  attributes: CustomerAttributeDomain<AttributeValueType>[] = [];

  getAlphabeticalKey(): string {
    if (!this.name) {
      return "#";
    }

    const nameToSplit = this.name
      ? this.name.toUpperCase().trim()
      : this.surname.toUpperCase().trim();
    const firstLetter = nameToSplit.charAt(0);

    if (!/[A-Z]/.test(firstLetter)) {
      return "#";
    }

    return firstLetter;
  }

  getPhoneNum(): string {
    return this.channels
      .map((chan) =>
        convertToChannelGroup(chan.type) === "Phone" ? chan.handle : ""
      )
      .filter((chan) => chan)[0];
  }

  static getEmailChannel(
    channels: CustomerChannelDomain[]
  ): CustomerChannelDomain | undefined {
    return channels.find(
      ({ type }: CustomerChannelDomain) => type === ConversationChannel.EMAIL
    );
  }

  getEmailAddress(): string {
    const { handle = "" } = ContactDomain.getEmailChannel(this.channels) || {};

    return handle;
  }

  static getEmailChannelId(
    channels: CustomerChannelDomain[]
  ): string | undefined {
    const { id } = ContactDomain.getEmailChannel(channels) || {};

    return id;
  }

  getDisplayName(isMobile?: boolean | undefined): string {
    if (this.fullName) {
      return this.fullName;
    }

    if (this.name || this.surname) {
      return `${this.name || ""} ${this.surname || ""}`;
    }

    if (isMobile && !this.fullName && (!this.name || !this.surname)) {
      return this.getPhoneNum();
    }

    return "";
  }

  getPicture() {
    if (this.avatar) {
      return this.avatar;
    }

    return "";
  }

  getSmsMarketingStatus(): string {
    const smsChannel = this.channels.filter((chan) => chan.type === "sms")[0];

    return smsChannel ? smsChannel.getMarketingStatusDisplay() : "-";
  }

  getWhatsappMarketingStatus(): string {
    const whatsappChannel = this.channels.filter(
      (chan) => chan.type === "whatsapp"
    )[0];

    return whatsappChannel ? whatsappChannel.getMarketingStatusDisplay() : "-";
  }

  getLatestAttributeWithAttributeId(
    attributeId: string
  ): CustomerAttributeDomain<AttributeValueType> | undefined {
    const allAttributesWithMatchingAttributeId = this.attributes.filter(
      (attr) => attr.attributeId === attributeId
    );

    if (allAttributesWithMatchingAttributeId.length === 0) {
      return undefined;
    }

    const latestAttributeWithMatchingAttributeId =
      allAttributesWithMatchingAttributeId.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

    return latestAttributeWithMatchingAttributeId[0];
  }

  constructor(
    id: number | undefined,
    groupId: string | undefined,
    name: string,
    surname: string,
    address: CustomerAddressDomain | undefined,
    channels: CustomerChannelDomain[],
    fullName: string,
    type: string,
    avatar: string,
    tagIds: string[],
    notes: string,
    isArchived: boolean,
    attributes: CustomerAttributeDomain<AttributeValueType>[] = []
  ) {
    this.id = id;
    this.groupId = groupId;
    this.name = name;
    this.surname = surname;
    this.address = address;

    const disabledChannels = channels
      .filter((channel) => !channel.isActive)
      .sort(sortChannels);
    const enabledChannels = channels
      .filter((channel) => channel.isActive)
      .sort(sortChannels);

    this.channels = [...enabledChannels, ...disabledChannels];
    this.fullName = fullName;
    this.type = type;
    this.avatar = avatar;
    this.tagIds = tagIds;
    this.notes = notes;
    this.isArchived = isArchived;
    this.attributes = attributes;
  }
}
