import {
  Button,
  Icon,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { IoFunnelSharp, IoPeople } from "react-icons/io5";
import { LuCalendarRange } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface DesktopAnalyticsTabsProps {
  tabIndex: number;
  setTabIndex: (index: number) => void;
}

const DesktopAnalyticsTabs = ({
  tabIndex,
  setTabIndex,
}: DesktopAnalyticsTabsProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  return isBaseSize ? null : (
    <VStack gap={2} width="10rem" minWidth="10rem" maxWidth="10rem" pr={2}>
      <Button
        pl={8}
        variant={tabIndex === 0 ? "subtle" : "ghost"}
        colorPalette={colorScheme}
        borderLeftRadius={0}
        justifyContent="start"
        alignItems="center"
        w="100%"
        onClick={() => {
          setTabIndex(0);
        }}
        color={
          tabIndex !== 0
            ? colorMode === "dark"
              ? "gray.200"
              : "gray.600"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.600`
        }
      >
        <Icon
          as={IoPeople}
          mr={2}
          width="1rem"
          height="1rem"
          fill={
            tabIndex !== 1
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        />
        <Text>Agents</Text>
      </Button>
      <Button
        pl={8}
        w="100%"
        borderLeftRadius={0}
        variant={tabIndex === 1 ? "subtle" : "ghost"}
        colorPalette={colorScheme}
        justifyContent="start"
        alignItems="center"
        onClick={() => {
          setTabIndex(1);
        }}
      >
        <Icon
          as={IoFunnelSharp}
          mr={2}
          width="1rem"
          height="1rem"
          fill={
            tabIndex !== 1
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        />
        <Text
          color={
            tabIndex !== 1
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          Leads
        </Text>
      </Button>
      <Button
        pl={8}
        w="100%"
        borderLeftRadius={0}
        variant={tabIndex === 2 ? "subtle" : "ghost"}
        colorPalette={colorScheme}
        justifyContent="start"
        alignItems="center"
        onClick={() => {
          setTabIndex(2);
        }}
      >
        <Icon
          as={LuCalendarRange}
          mr={2}
          width="1rem"
          height="1rem"
          color={
            tabIndex !== 2
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        />
        <Text
          color={
            tabIndex !== 2
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          Bookings
        </Text>
      </Button>
    </VStack>
  );
};

export default DesktopAnalyticsTabs;
