import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Text } from "@chakra-ui/react";
import { Avatar } from "components/ui/avatar";
import { useColorMode } from "components/ui/color-mode";
import debounce from "debounce-promise";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useRef, useState } from "react";
import { GroupBase, OptionProps, components } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import { GetContactsFilter } from "util/ContactsFilter";
import { numberOfContactsPerLoad } from "util/constants";
import { getReactSelectStyles } from "util/methods";

interface SelectCustomerProps {
  selectedCustomer: ContactDomain | null;
  isDisabled?: boolean;
  setSelectedCustomer: (newSelectedCustomer: ContactDomain | null) => void;
  filterCallback?: (id: number) => boolean;
}

interface CustomerOptionTypes {
  value: number;
  label: string;
  logo?: string | null;
}

const CustomerColumn = ({ item }: { item: CustomerOptionTypes }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      px={8}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Avatar
        key={item.value}
        size="sm"
        src={item.logo || undefined}
        name={item.label}
      />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const CustomerOption = function (props: OptionProps<CustomerOptionTypes>) {
  return (
    <components.Option {...props}>
      <CustomerColumn item={props.data} />
    </components.Option>
  );
};

type AdditionalType = {};

const transformCustomerToOption = (
  customer: ContactDomain
): CustomerOptionTypes => ({
  value: customer.id!,
  label: `${customer.getDisplayName()} (${customer.getPhoneNum() || "N/A"})`,
  logo: customer.getPicture(),
});

const SelectCustomer: React.FC<SelectCustomerProps> = ({
  selectedCustomer,
  isDisabled = false,
  setSelectedCustomer,
  filterCallback,
}) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [cache, clearCache] = useState<object>({});
  const [previousSearchText, setPreviousSearchText] = useState<string>("");
  const [customerOptions, setCustomerOptions] = useState<CustomerOptionTypes[]>(
    []
  );
  const [customers, setCustomers] = useState<ContactDomain[]>([]);
  const lastRequest = useRef<object>({});

  const hasMoreCustomers = (
    customersAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (customersAmount < numberOfContactsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfContactsPerLoad) {
      return false;
    }

    return true;
  };

  const fetchMoreCustomers: LoadOptions<
    CustomerOptionTypes,
    GroupBase<CustomerOptionTypes>,
    AdditionalType
  > = async (searchText = "") => {
    lastRequest.current = {};
    const currentRequest = lastRequest.current;
    let offset = customers?.length || 0;

    if (searchText !== previousSearchText) {
      offset = 0;
    }

    setPreviousSearchText(searchText);

    const contactsFilter = new GetContactsFilter({ name: searchText });

    const nextPage = (
      await ContactsService.getAllContacts(
        auth0Context,
        offset,
        merchant.groupId,
        contactsFilter,
        undefined,
        undefined,
        numberOfContactsPerLoad
      )
    ).contacts;

    if (currentRequest !== lastRequest.current) {
      return {
        options: customerOptions,
        hasMore: hasMoreCustomers(customerOptions.length, 0),
      };
    }

    const newCustomers = customers?.length
      ? customers.concat(nextPage)
      : nextPage;

    setCustomers(newCustomers);

    const nextOptions = nextPage.map<CustomerOptionTypes>(
      transformCustomerToOption
    );

    const newCustomerOptions: CustomerOptionTypes[] = customerOptions?.length
      ? customerOptions.concat(nextOptions)
      : nextOptions;

    setCustomerOptions(newCustomerOptions);

    return {
      options:
        typeof filterCallback !== "undefined"
          ? nextOptions.filter((c) => filterCallback(c.value))
          : nextOptions,
      hasMore: hasMoreCustomers(
        newCustomerOptions.length,
        nextPage?.length || 0
      ),
    };
  };

  const debouncedFetchMoreCustomers = debounce(
    fetchMoreCustomers,
    200
  ) as LoadOptions<
    CustomerOptionTypes,
    GroupBase<CustomerOptionTypes>,
    unknown
  >;

  return (
    <AsyncPaginate
      loadOptions={debouncedFetchMoreCustomers}
      cacheUniqs={[cache]}
      onMenuClose={() => {
        clearCache({});
        setCustomerOptions([]);
        setCustomers([]);
        setPreviousSearchText("");
      }}
      placeholder="Select Customer"
      isMulti={false}
      onChange={(c) =>
        setSelectedCustomer(
          customers.find((customer) => c?.value === customer.id) || null
        )
      }
      value={
        selectedCustomer ? transformCustomerToOption(selectedCustomer) : null
      }
      components={{
        Option: CustomerOption,
      }}
      styles={{
        ...{
          ...getReactSelectStyles(colorMode || "light", colorScheme),
          container: (provided: any) => ({
            ...provided,
            width: "100%",
            fontSize: "0.85rem",
          }),
        },
      }}
      isDisabled={isDisabled}
    />
  );
};

export default SelectCustomer;
