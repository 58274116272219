import {
  Country,
  getCountryCallingCode,
} from "components/shared/LazyPhoneInput";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAnalytics from "hooks/use-analytics";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { v4 as uuidv4 } from "uuid";
import ContactForm from "./ContactForm";

interface QuickAddContactFormProps {
  isOpen: boolean;
  onClose: () => void;
  shouldRedirectToDetails?: boolean;
  setRecentlyCreatedContact: (contact: ContactDomain) => void;
  searchString?: string;
}

const QuickAddContactForm = ({
  isOpen,
  onClose,
  setRecentlyCreatedContact,
  shouldRedirectToDetails = false,
  searchString,
}: QuickAddContactFormProps) => {
  const { createContact } = useContactsStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const navigate = useNavigate();
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { track } = useAnalytics();

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [checkedChannels, setCheckedChannels] = React.useState<Array<string>>(
    []
  );
  const [idempotencyKey, setIdempotencyKey] = React.useState<string | null>(
    null
  );

  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    if (merchant.isMerchantChannelEnabled(ConversationChannel.WHATSAPP)) {
      setCheckedChannels([
        ConversationChannel.SMS,
        ConversationChannel.WHATSAPP,
      ]);
    } else {
      setCheckedChannels([ConversationChannel.SMS]);
    }
    setIdempotencyKey(uuidv4());
  };

  useEffect(() => {
    reset();
  }, []);

  const isPossiblePhoneNumber = (str: string) => {
    const simplifiedPhoneRegExp = new RegExp(/^\+*[0-9\s]+$/);

    return simplifiedPhoneRegExp.test(str);
  };

  const isPossibleEmail = (str: string) => {
    const simplifiedEmailRegExp = new RegExp(/^.+@.*$/);

    return simplifiedEmailRegExp.test(str);
  };

  const replaceAllWhitespace = (str: string) => str.replace(/\s+/g, "");

  const trySetPhoneNumber = (potentialPhoneNumber: string) => {
    if (isPossiblePhoneNumber(potentialPhoneNumber)) {
      const filteredNumber = replaceAllWhitespace(potentialPhoneNumber);
      const hasCountryCode = new RegExp(/^\+[0-9]+/).test(filteredNumber);

      if (hasCountryCode) {
        setPhoneNumber(filteredNumber);
        return true;
      }

      const merchantLocation = merchant.getMerchantLocation();

      if (merchantLocation) {
        const countryCode = getCountryCallingCode(merchantLocation as Country);
        setPhoneNumber(`+${countryCode}${filteredNumber}`);
        return true;
      }
    }

    return false;
  };

  const trySetEmail = (potentialEmail: string) => {
    if (isPossibleEmail(potentialEmail)) {
      setEmail(replaceAllWhitespace(potentialEmail));
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (trySetPhoneNumber(searchString || "")) {
      return;
    }

    if (trySetEmail(searchString || "")) {
      return;
    }

    setFirstName(searchString?.split(" ")[0] || "");
    setLastName(searchString?.split(" ")[1] || "");
  }, [searchString]);

  const onNewContactClose = () => {
    reset();
    onClose();
  };

  const handleSave = async () => {
    setIsSaving(true);

    const channels = [];

    if (phoneNumber) {
      checkedChannels.forEach((chan) =>
        channels.push({
          type: chan,
          handle: phoneNumber,
          is_active: true,
          is_subscribed: true,
        })
      );
    }

    if (email) {
      channels.push({
        type: ConversationChannel.EMAIL,
        handle: email,
        is_active: true,
        is_subscribed: true,
      });
    }

    const createContactResponse = await createContact(
      {
        name: firstName || undefined,
        surname: lastName || undefined,
        tags: [],
        tagIds: [],
        address: undefined,
        channels,
        notes: null,
      },
      idempotencyKey!
    );

    setIsSaving(false);

    if (createContactResponse) {
      track("new_customer_attempt_succeeded", {
        conversation_id: activeConversation?.id || null,
        customer_id: createContactResponse.id,
        channel: activeConversation?.channel,
      });

      setRecentlyCreatedContact(createContactResponse);
      onNewContactClose();
      if (shouldRedirectToDetails) {
        navigate(`/${merchant.id}/contacts/${createContactResponse.id}`);
      }
    }
  };

  return (
    <ContactForm
      isOpen={isOpen}
      onClose={onNewContactClose}
      modalHeader="Create new contact"
      onFirstNameChange={(e) => setFirstName(e.target.value)}
      firstName={firstName}
      onLastnameChange={(e) => setLastName(e.target.value)}
      lastName={lastName}
      fullNameRequired={true}
      email={email}
      onEmailChange={(e) => setEmail(e.target.value)}
      isPhoneDisabled={false}
      isPhoneNullable={true}
      isEmailNullable={true}
      phoneNumber={phoneNumber}
      onPhoneNumChange={(value: React.SetStateAction<string>) =>
        setPhoneNumber(value)
      }
      onCheckChan={(newCheckedChannels) => {
        setCheckedChannels(newCheckedChannels);
      }}
      checkedChannels={checkedChannels}
      defaultCheckedChans={checkedChannels}
      defaultHiddenChans={["facebook", "instagram", "zapier_facebook"]}
      handleSave={() => {
        if (isSaving) {
          return;
        }

        handleSave();
      }}
      isSaving={isSaving}
      disabledChans={[]}
    />
  );
};

export default QuickAddContactForm;
