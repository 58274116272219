import { Button, Heading, Icon } from "@chakra-ui/react";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import React, { useEffect, useState } from "react";
import { updateMerchantSuccess } from "redux/features/merchant";

import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { toaster } from "components/ui/toaster";
import {
  getFacebookMessengerOauthEndpoint,
  getFacebookMessengerRefreshEndpoint,
} from "components/user-settings/constants";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { FiRefreshCcw } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { isMobileApp } from "util/methods";

interface MessengerIntegrationItemProps {}

const MessengerIntegrationItem = (_props: MessengerIntegrationItemProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isContinueConfirmationOpen, setIsContinueConfirmationOpen] =
    useState<boolean>(false);

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.MESSENGER);

  const onDisconnectMessenger = async () => {
    try {
      await InboxService.removeFacebookMessengerIntegration(
        auth0Context,
        merchant.id
      );

      merchant.integrations[IntegrationName.MESSENGER].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not disconnect Facebook Messenger at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      icon={<Icon as={MessengerIcon} height="3rem" width="3rem" />}
      isConnected={isConnected}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectMessenger} />
        ) : null
      }
      primaryButton={
        isDisabled ? null : isConnected ? (
          <Button
            colorPalette={colorScheme}
            onClick={() => {
              const facebookMessengerRefreshEndpoint =
                getFacebookMessengerRefreshEndpoint(merchant.id);

              if (isMobileApp()) {
                window.natively.openExternalURL(
                  facebookMessengerRefreshEndpoint
                );
              } else {
                window.open(facebookMessengerRefreshEndpoint, "_blank");
              }
            }}
          >
            <Icon as={FiRefreshCcw} /> Refresh
          </Button>
        ) : (
          <>
            <Button
              colorPalette={colorScheme}
              onClick={() => {
                setIsContinueConfirmationOpen(true);
              }}
            >
              <Icon as={LuPlus} /> Connect
            </Button>
            <ConfirmationDialog
              headerText="Please, read the instructions carefully!"
              messageText="Continue with Facebook to add Messenger to your Fuzey account. Once redirected please select ALL the pages you have access to. You will have an opportunity to select the page you want to connect to Fuzey on a final step."
              buttonText="Continue"
              isDangerous={false}
              isOpen={isContinueConfirmationOpen}
              setIsOpen={setIsContinueConfirmationOpen}
              confirmationCallback={() => {
                const facebookMessengerOauthEndpoint =
                  getFacebookMessengerOauthEndpoint(merchant.id);

                if (isMobileApp()) {
                  window.natively.openExternalURL(
                    facebookMessengerOauthEndpoint
                  );
                } else {
                  window.open(facebookMessengerOauthEndpoint, "_blank");
                }
              }}
            />
          </>
        )
      }
    >
      <Heading as="h3" size="lg">
        Messenger
      </Heading>
      <IntegrationDescription text="Expand your reach by linking your platform with Facebook, allowing easy content sharing, social login and etc." />
    </Integration>
  );
};

export default MessengerIntegrationItem;
