import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@chakra-ui/react";
import { toaster } from "components/ui/toaster";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "redux/hooks";
import InvoiceService from "services/invoice";

const QuickbooksPage = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  const quickbooks_redirect_url = new URLSearchParams(useLocation().search);
  const quickbooks_authorization_code = quickbooks_redirect_url.get("code");
  const quickbooks_company_id = quickbooks_redirect_url.get("realmId");
  const state = quickbooks_redirect_url.get("state");
  const merchantIdFromState = state ? parseInt(state, 10) : null;

  const auth0Context = useAuth0();

  const sendQuickbooksCode = () => {
    InvoiceService.sendQuickbooksCode(
      auth0Context,
      quickbooks_authorization_code,
      quickbooks_company_id,
      merchant.id
    )
      .then((res) => {
        toaster.create({ type: "success", title: "Quickbooks set up successfully" });
        window.location.assign(
          `/${merchant.id}/settings?redirect_location=quickbooks`
        );
      })
      .catch((e) => {
        toaster.create({
          type: "error",
          title: "Something went wrong. Please try again",
        });
        window.location.assign(`/${merchant.id}/settings`);
      });
  };

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (!merchantIdFromState || typeof merchantIdFromState !== "number") {
      // eslint-disable-next-line
      console.error(
        "Merchant ID didn't come with the sate to Quickbooks callback",
        {
          url: quickbooks_redirect_url,
          passedMerchantId: state,
        }
      );
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Quickbooks account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);

      return;
    }

    const merchantIsAvailable = currentAgent!.merchants
      .map((m: AgentMerchantDomain) => m.id)
      .includes(merchantIdFromState);

    if (merchantIdFromState !== merchant.id) {
      if (!merchantIsAvailable) {
        // eslint-disable-next-line
        console.error(
          "Merchant ID is not available to agent during Quickbooks callback processing",
          {
            url: quickbooks_redirect_url,
            passedMerchantId: state,
            agentId: currentAgent!.id,
          }
        );
        localStorage.setItem(
          "callback_fail",
          JSON.stringify({
            title: "We couldn't connect your Quickbooks account...",
            description: "Please try again later or contact our support team.",
          })
        );
        window.location.assign(`/public/callback/fail`);

        return;
      }

      localStorage.setItem("fuzey:merchant:id", merchantIdFromState.toString());
      window.location.assign(window.location.href);

      return;
    }

    if (quickbooks_authorization_code) {
      sendQuickbooksCode();
    }
  }, [merchant.id, currentAgent?.merchants]);

  return <Spinner />;
};

export default QuickbooksPage;
