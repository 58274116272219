import { Button, Icon, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import {
  MessageButton,
  MessageButtonType,
} from "entities/domain/conversations/message-domain";
import React from "react";
import { LuPhone, LuReply } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface ButtonsPreviewProps {
  buttons: MessageButton[];
  forceColorMode?: "light" | "dark";
}

const MessageButtonsPreview = ({
  buttons,
  forceColorMode,
}: ButtonsPreviewProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return buttons.length === 0 ? null : (
    <VStack width="100%" gap={0} flexGrow={0} className="dark">
      {buttons.map((button, index) => (
        <Tooltip
          key={index}
          content={
            button.type === MessageButtonType.PHONE_NUMBER
              ? button.phoneNumber
              : button.type === MessageButtonType.URL
              ? button.url
              : ""
          }
          disabled={button.type === MessageButtonType.QUICK_REPLY}
        >
          <Button
            zIndex={5} // To prevent the button from being covered by the tail
            width="100%"
            variant="ghost"
            display="flex"
            textTransform="none"
            alignItems="center"
            colorPalette={colorScheme}
            borderTopRadius={0}
            borderBottomRadius={index === buttons.length - 1 ? "xl" : 0}
            color="white"
            borderColor={
              (forceColorMode || colorMode) === "dark" ? "white" : "gray.200"
            }
            borderTopWidth={index === 0 ? "1px" : 0}
            borderLeftWidth={0}
            borderRightWidth={0}
            borderBottomWidth={index === buttons.length - 1 ? 0 : "1px"}
          >
            <Icon
              as={
                button.type === MessageButtonType.QUICK_REPLY
                  ? LuReply
                  : button.type === MessageButtonType.PHONE_NUMBER
                  ? LuPhone
                  : LuReply
              }
              {...(button.type === MessageButtonType.QUICK_REPLY
                ? {
                    color:
                      (forceColorMode || colorMode) === "dark"
                        ? "white"
                        : "black",
                  }
                : {})}
            />
            {button.text}
          </Button>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default MessageButtonsPreview;
