import { Button, Code, List } from "@chakra-ui/react";

import { Alert } from "components/ui/alert";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import React, { useState } from "react";

interface CampaignIdeasProps {
  campaignStatus: CampaignStatus;
  isShown: boolean;
  useIdea: (idea: string) => void;
}

const CampaignIdeas = ({
  isShown,
  useIdea,
  campaignStatus,
}: CampaignIdeasProps) => {
  if (!isShown || campaignStatus === CampaignStatus.DONE) {
    return null;
  }

  const [campaignIdeas, setCampaignIdeas] = useState<string[]>([
    "Hey {contact_first_name}, how can I help you?",
    "What is up {contact_first_name}?",
  ]);

  return (
    <Alert
      status="info"
      mb={4}
      variant="subtle"
      title="Do not know where to start?"
    >
      Try using one of these AI suggestions:
      <List.Root gap={2} mt={2}>
        {campaignIdeas.map((idea) => (
          <List.Item display="flex" gridGap={4} alignItems="center">
            <Button
              variant="outline"
              size="sm"
              colorPalette="yellow"
              flexShrink={0}
              flexGrow={0}
              flexBasis="auto"
              onClick={() => {
                useIdea(idea);
              }}
            >
              Use 👉
            </Button>
            <Code borderRadius="lg" textAlign="left" colorPalette="yellow">
              {idea}
            </Code>
          </List.Item>
        ))}
      </List.Root>
    </Alert>
  );
};

export default CampaignIdeas;
