import { Box, Button, Flex, useBreakpointValue } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import { useColorMode } from "components/ui/color-mode";
import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import Select, { ActionMeta, MultiValue } from "react-select";
import { setSelectedCalendarAgents } from "redux/features/calendar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import FullCalendarComponent, { AgentOptionTypes } from "./FullCalendar";
import NewEventModal from "./NewEventModal";

const Calendar = () => {
  const dispatch = useAppDispatch();
  const { loading: agentsAreLoading } = useAppSelector((state) => state.agents);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();
  const { currentAgent, agents } = useAppSelector((state) => state.agents);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [openEventForm, setOpenEventForm] = useState<boolean>(false);
  const { selectedAgentIds } = useAppSelector((state) => state.calendar);

  const agentOptions: AgentOptionTypes[] = agents.map(
    transformFromAgentDomainToOptionType
  );

  const handleSelectedAgents = (e: MultiValue<AgentOptionTypes>) => {
    dispatch(setSelectedCalendarAgents(e.map((ag) => ag.value)));
  };

  useEffect(() => {
    dispatch(setSelectedCalendarAgents([currentAgent!.id]));
  }, [merchant.id]);

  return (
    <>
      <Flex
        direction="column"
        position="relative"
        flex={1}
        width="100%"
        height="100%"
        {...(isBaseSize
          ? {}
          : {
              bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
              pl: "2rem",
              pr: "1rem",
            })}
      >
        {isBaseSize ? (
          <Topbar
            title="Calendar"
            rightChildrenMobile={
              <Button
                id="new-event-button"
                colorPalette={colorScheme}
                size="sm"
                onClick={() => setOpenEventForm(true)}
              >
                New Event
              </Button>
            }
          />
        ) : (
          <Flex
            py={4}
            pr={4}
            justifyContent="start"
            gridGap={4}
            alignItems="center"
          >
            <Button
              id="new-event-button"
              colorPalette={colorScheme}
              size="md"
              onClick={() => setOpenEventForm(true)}
            >
              New Event <LuPlus />
            </Button>
            <Select
              closeMenuOnSelect={false}
              value={selectedAgentIds
                .flatMap((id: number) => agents.filter((ag) => ag.id === id))
                .map(transformFromAgentDomainToOptionType)}
              isMulti
              placeholder="Select Agent"
              onChange={
                handleSelectedAgents as (
                  newValue: unknown,
                  actionMeta: ActionMeta<unknown>
                ) => void
              }
              options={agentOptions}
              styles={getReactSelectStyles(colorMode || "light", colorScheme)}
              isDisabled={agentsAreLoading}
            />
          </Flex>
        )}
        {isBaseSize ? (
          <Box p={2}>
            <Select
              closeMenuOnSelect={false}
              value={selectedAgentIds
                .flatMap((id: number) => agents.filter((ag) => ag.id === id))
                .map(transformFromAgentDomainToOptionType)}
              isMulti
              placeholder="Select Agent"
              onChange={
                handleSelectedAgents as (
                  newValue: unknown,
                  actionMeta: ActionMeta<unknown>
                ) => void
              }
              options={agentOptions}
              styles={getReactSelectStyles(colorMode || "light", colorScheme)}
              isDisabled={agentsAreLoading}
            />
          </Box>
        ) : null}
        <Box
          borderTopRadius={isBaseSize ? 0 : "3xl"}
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          w="100%"
          h="100%"
          overflowY="hidden"
          position="relative"
          pb={6}
        >
          <FullCalendarComponent />
        </Box>
      </Flex>
      <NewEventModal
        openEventForm={openEventForm}
        closeNewEventForm={() => setOpenEventForm(false)}
      />
    </>
  );
};

export default Calendar;
