import { Button, Heading, Icon, IconButton, Text } from "@chakra-ui/react";
import { DialogBody, DialogFooter } from "components/ui/dialog";
import React from "react";
import { LuArrowLeft, LuUpload } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface PaymentsStepTwoProps {
  amount: string;
  servicesRendered: string;
  proceed: () => void;
  goBack: () => void;
  proceedWithInvoice: boolean;
  showInvoice: boolean;
}

const PaymentsStepTwo = ({
  amount,
  servicesRendered,
  proceed,
  goBack,
  proceedWithInvoice,
  showInvoice,
}: PaymentsStepTwoProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <DialogBody maxHeight="50vh" w="100%">
        <Heading display="block" mb="22px" textAlign="center" size="sm">
          Are you sure you want to send this request?
        </Heading>

        <Heading
          display="block"
          mb="8px"
          size="sm"
          color={`${colorScheme}.400`}
        >
          {amount}
        </Heading>

        <Text
          display="block"
          overflowY="scroll"
          css={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {servicesRendered}
        </Text>
        {showInvoice && (
          <Text fontSize="sm" mb="5px" mt="15px">
            {proceedWithInvoice
              ? "* Invoice included"
              : "* Invoice not included"}
          </Text>
        )}
      </DialogBody>
      <DialogFooter justifyContent="space-between">
        <IconButton aria-label="Go back" onClick={goBack} variant="ghost">
          <Icon as={LuArrowLeft} />
        </IconButton>
        <Button colorPalette={colorScheme} onClick={proceed}>
          Confirm and Send
          <Icon as={LuUpload} color="white" />
        </Button>
      </DialogFooter>
    </>
  );
};

export default PaymentsStepTwo;
