import {
  Flex,
} from "@chakra-ui/react";
import { StepsItem, StepsList, StepsRoot } from "components/ui/steps";
import React from "react";
import { useAppSelector } from "redux/hooks";
import { ExtendedConnectSteps } from "../../../Integrations/ConnectIntegrationTypes";

interface ProgressStepsProps {
  size: string;
  steps: ExtendedConnectSteps[];
  activeStep: number;
}

const ProgressSteps = ({ size, steps, activeStep }: ProgressStepsProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex flexDir="column" width="100%">
      <StepsRoot step={activeStep} colorPalette={colorScheme}>
        <StepsList>
        {steps.map((_step, index) => (
          <StepsItem key={index} index={index} />
        ))}
        </StepsList>
      </StepsRoot>
    </Flex>
  );
};

export default ProgressSteps;
