import {
  Button,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogRoot,
} from "components/ui/dialog";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface CancelPaymentProps {
  isCancelPaymentsOpen: boolean;
  onClose: () => void;
  onCancelPayment: () => void;
}

const CancelPaymentModal = ({
  isCancelPaymentsOpen,
  onClose,
  onCancelPayment,
}: CancelPaymentProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <DialogRoot
        open={isCancelPaymentsOpen}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
          }
        }}
        size={isBaseSize ? "full" : "xs"}
      >
        <DialogBackdrop />
        <DialogContent>
          <Heading as="h5" size="md" margin="20px 40px 8px">
            {" "}
            Cancel payment
          </Heading>
          <DialogCloseTrigger />
          <DialogBody>
            <Text>Are you sure you want to cancel this payment?</Text>
          </DialogBody>
          <Flex
            margin="24px 32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={() => onClose()} colorPalette="gray">
              No, keep it
            </Button>
            <Button colorPalette={colorScheme} onClick={onCancelPayment}>
              Yes, cancel it
            </Button>
          </Flex>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default CancelPaymentModal;
