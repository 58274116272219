import {
  HStack,
  StackProps,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { SkeletonCircle, SkeletonText } from "components/ui/skeleton";
import React from "react";

interface SkeletonOverlayProps {
  chatBackgroundStyles: StackProps;
}

const SkeletonOverlay: React.FC<SkeletonOverlayProps> = ({
  chatBackgroundStyles,
}) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <VStack
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      width="100%"
      height="100%"
      {...chatBackgroundStyles}
      zIndex={101}
    >
      <HStack
        position="absolute"
        top={0}
        left={0}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        zIndex={102}
        py={3}
        px={3}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack>
          <SkeletonCircle size="10" />
          <SkeletonText noOfLines={1} width="10rem" height={3} />
        </HStack>
        <HStack>
          <SkeletonCircle size="10" />
          <SkeletonCircle size="10" />
          <SkeletonCircle size="10" mr={10} />
        </HStack>
      </HStack>
      <HStack
        position="absolute"
        {...(isBaseSize
          ? {
              bottom: 0,
              left: 0,
              right: 0,
            }
          : {
              bottom: 4,
              left: 4,
              right: 4,
              borderRadius: "xl",
            })}
        bgColor={colorMode === "dark" ? "gray.700" : "white"}
        zIndex={102}
        pt={4}
        px={4}
        pb={8}
        alignItems="start"
        justifyContent="start"
      >
        <SkeletonText noOfLines={3} height={3} />
      </HStack>
    </VStack>
  );
};

export default SkeletonOverlay;
