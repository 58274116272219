import { Box, Text } from "@chakra-ui/react";
import { CustomFieldKey } from "entities/domain/templates";
import React from "react";

interface CustomFieldsInterface {
  onClick: () => void;
  displayKey: string;
  fieldKey: CustomFieldKey | string;
}

const CustomField = ({
  onClick,
  displayKey,
  fieldKey,
}: CustomFieldsInterface) => {
  return (
    <Box
      key={fieldKey}
      role="button"
      onClick={onClick}
      cursor="pointer"
      bgColor="mediumspringgreen"
      color="white"
      my="1px"
      mx="1px"
      px="0.35rem"
      py="0.1rem"
      borderRadius="4px"
      display="inline-block"
      _hover={{
        opacity: 0.8,
      }}
    >
      <Text
        as="span"
        data-custom-field-key={fieldKey}
        bgColor="mediumspringgreen"
      >
        {displayKey}
      </Text>
    </Box>
  );
};

export default CustomField;
