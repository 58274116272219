import {
  Box,
  Button,
  Fieldset,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
import QueryBuilder from "components/shared/QueryBuilder";
import { GroupConfig } from "components/shared/QueryBuilder/QueryCondition";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import AudienceDomain, { AudienceCriteria } from "entities/domain/audience";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { hasEmptyConditions } from "entities/domain/criteria";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import QueryConfirmationModal from "./QueryConfirmationModal";
import { customerGroup, vehicleGroup } from "./QueryFields";

interface NewAudienceProps {
  isSavingAudience: boolean;
  audienceName: string | null;
  audienceCriteria: AudienceCriteria | null;
  campaignChannel: ConversationChannel;
  setAudienceCriteria: (criteria: AudienceCriteria) => void;
  setAudienceName: (audienceName: string | null) => void;
  onAudienceSelect: (newAudience: AudienceDomain) => void;
}

const NewAudienceTab = ({
  isSavingAudience,
  audienceName,
  campaignChannel,
  audienceCriteria,
  onAudienceSelect,
  setAudienceCriteria,
  setAudienceName,
}: NewAudienceProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [isQueryBuilderDisabled, setIsQueryBuilderDisabled] =
    useState<boolean>(false);
  const [isCriteriaConfirmationOpen, setIsCriteriaConfirmationOpen] =
    useState<boolean>(false);
  const [enabledGroups, setEnabledGroups] = useState<GroupConfig[]>([
    customerGroup,
  ]);
  const [isCriteriaFormed, setIsCriteriaFormed] = useState<boolean>(false);

  useEffect(() => {
    if (merchant.isIntegrationEnabled(IntegrationName.KEYLOOP)) {
      setEnabledGroups([customerGroup, vehicleGroup]);
    } else {
      setEnabledGroups([customerGroup]);
    }
  }, [merchant]);

  useEffect(() => {
    if (audienceCriteria && !hasEmptyConditions(audienceCriteria)) {
      setIsCriteriaFormed(true);
    } else {
      setIsCriteriaFormed(false);
    }
  }, [audienceCriteria]);

  return (
    <Fieldset.Root width={isBaseSize ? "100%" : "60%"} mx="auto">
      <Fieldset.Content>
        <Field label="Audience Name" mt={6} required={true}>
          <Input
            colorPalette={colorScheme}
            id="campaign-audience-name-input"
            rounded="full"
            placeholder="My prospects"
            disabled={isSavingAudience}
            value={audienceName || ""}
            onChange={(e) => {
              setAudienceName(e.target.value);
            }}
          />
        </Field>
        <Field
          my={6}
          label="Criteria"
          required={true}
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          <Box
            w="100%"
            {...(isBaseSize
              ? {
                  padding: 2,
                }
              : {
                  padding: 6,
                  borderRadius: "xl",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: colorMode === "dark" ? "gray.500" : "gray.100",
                })}
          >
            <QueryBuilder
              entityNamePlural="customers"
              groups={enabledGroups}
              criteria={audienceCriteria}
              setCriteria={setAudienceCriteria}
              isDisabled={isQueryBuilderDisabled}
              disabledFields={["channel_type"]}
            />
          </Box>
        </Field>
      </Fieldset.Content>
      <Button
        colorPalette="green"
        id="campaign-test-criteria-button"
        onClick={() => {
          setIsCriteriaConfirmationOpen(true);
          setIsQueryBuilderDisabled(true);
        }}
        disabled={!audienceName || !isCriteriaFormed}
      >
        Test Criteria
      </Button>
      <QueryConfirmationModal
        groups={enabledGroups}
        criteria={audienceCriteria}
        campaignChannel={campaignChannel}
        audienceName={audienceName || null}
        isOpen={isCriteriaConfirmationOpen}
        onConfirm={(createdAudience) => {
          onAudienceSelect(createdAudience);
          setIsQueryBuilderDisabled(true);
        }}
        onClose={() => {
          setIsCriteriaConfirmationOpen(false);
          setIsQueryBuilderDisabled(false);
        }}
      />
    </Fieldset.Root>
  );
};

export default NewAudienceTab;
