import { AttributeType } from "entities/domain/attributes/attribute-domain";
import CustomerAttributeDomain, {
  AttributeValueType,
} from "entities/domain/customers/contact-attribute-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import CustomerAddressDomain from "entities/domain/customers/customer-address-domain";
import {
  ContactAddressDTO,
  ContactAttributesDTO,
  ContactChannelsDTO,
  ContactDTO,
} from "entities/dto/ContactDTO";
import { getChannelIcon } from "util/constants";

interface ChannelOptionTypes {
  value: string;
  label: string;
  icon?: string;
  color?: string;
}

export const transformFromContactChannelToOptions = (
  channel: CustomerChannelDomain
): ChannelOptionTypes => ({
  value: channel.id!,
  label: `${channel.getDisplayChannelName()} (${channel.displayHandle})`,
  icon: getChannelIcon(channel.type),
});

export const contactChannelTransformFromDtoToDomain = (
  channel: ContactChannelsDTO
): CustomerChannelDomain =>
  new CustomerChannelDomain(
    channel.id,
    channel.type,
    channel.handle,
    channel.display_handle,
    channel.is_active,
    channel.is_subscribed
  );

export const contactAttributeTransformFromDtoToDomain = (
  attribute: ContactAttributesDTO
): CustomerAttributeDomain<AttributeValueType> => {
  if (attribute.value_string) {
    return new CustomerAttributeDomain<string>(
      attribute.id,
      attribute.attribute_id,
      attribute.value_string,
      AttributeType.STRING,
      attribute.created_at,
      attribute.updated_at
    );
  }

  if (attribute.value_integer) {
    return new CustomerAttributeDomain<number>(
      attribute.id,
      attribute.attribute_id,
      attribute.value_integer,
      AttributeType.INTEGER,
      attribute.created_at,
      attribute.updated_at
    );
  }

  if (attribute.value_float) {
    return new CustomerAttributeDomain<number>(
      attribute.id,
      attribute.attribute_id,
      attribute.value_float,
      AttributeType.FLOAT,
      attribute.created_at,
      attribute.updated_at
    );
  }

  if (typeof attribute.value_boolean === "boolean") {
    return new CustomerAttributeDomain<boolean>(
      attribute.id,
      attribute.attribute_id,
      attribute.value_boolean,
      AttributeType.BOOLEAN,
      attribute.created_at,
      attribute.updated_at
    );
  }

  if (attribute.value_datetime) {
    return new CustomerAttributeDomain<Date>(
      attribute.id,
      attribute.attribute_id,
      new Date(attribute.value_datetime),
      AttributeType.DATETIME,
      attribute.created_at,
      attribute.updated_at
    );
  }

  return new CustomerAttributeDomain<undefined>(
    attribute.id,
    attribute.attribute_id,
    undefined,
    undefined,
    attribute.created_at,
    attribute.updated_at
  );
};

export const contactAddressTransformFromDtoToDomain = (
  address: ContactAddressDTO
): CustomerAddressDomain =>
  new CustomerAddressDomain(
    address.first_line,
    address.second_line,
    address.city,
    address.country,
    address.state,
    address.postcode
  );

export const contactTransformFromDtoToDomain = (
  contact: ContactDTO
): ContactDomain =>
  new ContactDomain(
    contact.id,
    contact.group_id,
    contact.name,
    contact.surname,
    contact.address
      ? contactAddressTransformFromDtoToDomain(contact.address)
      : undefined,
    contact.channels.map((chan) =>
      contactChannelTransformFromDtoToDomain(chan)
    ),
    contact.full_name,
    contact.type,
    contact.avatar,
    contact.tag_ids,
    contact.notes,
    contact.archived,
    contact.attributes.map((attr) =>
      contactAttributeTransformFromDtoToDomain(attr)
    )
  );

export const contactListTransformFromDtoToDomain = (
  contacts: ContactDTO[],
  totalCount: string
): ContactListDomain =>
  new ContactListDomain(
    contacts.map(contactTransformFromDtoToDomain),
    Number(totalCount)
  );
