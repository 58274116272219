import {
  Tag as ChakraTag,
  TagRootProps as ChakraTagProps,
  TagCloseTrigger,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import React, { memo, useId, useMemo } from "react";

type TagProps = {
  label: string;
  color?: string;
  fontSize?: string;
  fontColor?: string;
  onCloseTag?: () => void;
  displayToolTip?: boolean;
} & ChakraTagProps;

export const MAX_TAG_WIDTH_IN_PIXELS = 96;
export const MAX_TAG_WIDTH_IN_CHAKRA_UNITS = 24;

const Tag = memo(
  ({
    label,
    color,
    fontSize,
    fontColor,
    onCloseTag,
    displayToolTip = true,
    ...rest
  }: TagProps) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const { colorMode } = useColorMode();
    const placement = useMemo(() => ({ placement: "top" }), []);

    return (
      <Tooltip
        content={label}
        positioning={placement as { placement: "top" }}
        disabled={!displayToolTip || isBaseSize}
      >
        <ChakraTag.Root
          fontSize={fontSize}
          variant="outline"
          px={1}
          boxShadow="none"
          border="1px solid"
          colorPalette={color}
          borderColor={color || (colorMode === "dark" ? "gray.50" : "gray.300")}
          color={fontColor || (colorMode === "dark" ? "gray.300" : "gray.600")}
          size="sm"
          maxWidth={24}
          bgColor="transparent"
          {...rest}
          overflow="hidden"
        >
          <ChakraTag.Label lineClamp={1}>{label}</ChakraTag.Label>

          {onCloseTag && (
            <TagCloseTrigger
              onClick={onCloseTag}
              _focus={{ outline: "none" }}
            />
          )}
        </ChakraTag.Root>
      </Tooltip>
    );
  }
);

export default Tag;
