import { defineSlotRecipe } from "@chakra-ui/react";
import { numberInputAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: numberInputAnatomy.keys(),
  base: {
    root: {
      borderRadius: "full",
      overflow: "hidden",
      width: "100%",
    },
    incrementTrigger: {
      border: "none",
      pr: "0.25rem",
    },
    decrementTrigger: {
      border: "none",
      pr: "0.25rem",
    },
  },
  variants: {
    variant: {
      solid: {
        control: {
          bg: "gray.50",
          bgColor: "gray.50",
          background: "gray.50",
          _dark: {
            bg: "black",
            bgColor: "black",
            background: "black",
          },
        },
        root: {
          bg: "gray.50",
          bgColor: "gray.50",
          background: "gray.50",
          _dark: {
            bg: "black",
            bgColor: "black",
            background: "black",
          },
        },
        input: {
          bg: "gray.50",
          bgColor: "gray.50",
          background: "gray.50",
          _dark: {
            bg: "black",
            bgColor: "black",
            background: "black",
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: "solid",
  },
});
