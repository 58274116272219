import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Circle,
  Flex,
  Float,
  Icon,
  Portal,
  StackSeparator,
  VStack,
} from "@chakra-ui/react";
import { ReactComponent as ZapierIcon } from "assets/icons/zapier-icon-svgrepo-com.svg";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { useColorMode } from "components/ui/color-mode";
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import { toaster } from "components/ui/toaster";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { LuStepForward } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { getChannelIcon, getChannelIconElement } from "util/constants";

const ContactChannelsColumn = ({ item }: { item: ContactDomain }) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const { merchant } = useAppSelector((state) => state.merchant);

  const createConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const findConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.getConversationByChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    try {
      const existingConversation = await findConversationWithChannelId(chanId);

      if (existingConversation) {
        navigate(`/${merchant.id}/inbox/${existingConversation.id}`);

        return;
      }

      const newConversation = await createConversationWithChannelId(chanId);

      navigate(`/${merchant.id}/inbox/${newConversation.id}`);
    } catch (_e) {
      toaster.create({
        type: "error",
        title: "Error has happened while opening this conversation.",
        description: "Please try doing it from the inbox page.",
      });
    }
  };

  return (
    <Flex justifyContent="start" alignItems="center" gridGap={1}>
      {item.channels
        .filter(
          (chan, index, originalArray) =>
            originalArray.findIndex((c) => c.type === chan.type) === index
        )
        .sort((chan) => (chan.type === "whatsapp" ? -1 : 1))
        .map((chan) => {
          if (!chan.isActive) {
            return null;
          }

          const contactHasMultipleChannelsOfSameType =
            item.channels.filter(
              (channel: CustomerChannelDomain) => channel.type === chan.type
            ).length > 1;

          if (!contactHasMultipleChannelsOfSameType) {
            return (
              <Button
                unstyled={true}
                size="xs"
                _focus={{ outline: "none" }}
                onClick={() => {
                  handleCreate(chan.id!);
                }}
                _hover={{ opacity: "0.5" }}
                key={chan.id}
                {...(!merchant.isMerchantChannelEnabled(chan.type)
                  ? {
                      cursor: "not-allowed",
                      opacity: "0.5",
                    }
                  : {})}
                disabled={!merchant.isMerchantChannelEnabled(chan.type)}
              >
                <Icon
                  asChild={true}
                  css={{
                    "& svg": { height: "1rem", width: "1rem" },
                  }}
                >
                  <ReactSVG src={getChannelIcon(chan.type)} />
                </Icon>
              </Button>
            );
          }

          return (
            <PopoverRoot lazyMount={true} key={chan.id}>
              <PopoverTrigger asChild>
                <Button
                  unstyled={true}
                  height="auto"
                  padding={0}
                  size="sm"
                  _focus={{ outline: "none" }}
                  _hover={{ opacity: "0.5" }}
                  {...(!merchant.isMerchantChannelEnabled(chan.type)
                    ? {
                        cursor: "not-allowed",
                        opacity: "0.5",
                      }
                    : {})}
                  disabled={!merchant.isMerchantChannelEnabled(chan.type)}
                >
                  <Avatar.Root size="sm" w="auto" bg="transparent">
                    <Icon
                      as={getChannelIconElement(chan.type)}
                      boxSize="1rem"
                      css={
                        [
                          ConversationChannel.EMAIL,
                          ConversationChannel.SMS,
                        ].includes(chan.type as ConversationChannel)
                          ? {
                              color:
                                colorMode === "dark"
                                  ? `${colorScheme}.200`
                                  : `${colorScheme}.400`,
                            }
                          : {
                              "& path": {
                                fill:
                                  colorMode === "dark"
                                    ? `${colorScheme}.200`
                                    : `${colorScheme}.400`,
                              },
                            }
                      }
                    />
                    {chan.type === ConversationChannel.ZAPIER_FACEBOOK ? (
                      <Float placement="top-end" offsetX="0" offsetY="3">
                        <Icon as={ZapierIcon} boxSize={2} />
                      </Float>
                    ) : null}
                    <Float placement="bottom-end" offsetX="0" offsetY="3">
                      <Circle
                        bg={colorMode === "dark" ? "gray.700" : "gray.100"}
                        fontSize="2xs"
                        h={3}
                        w={3}
                      >
                        {
                          item.channels.filter((ch) => ch.type === chan.type)
                            .length
                        }
                      </Circle>
                    </Float>
                  </Avatar.Root>
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <VStack separator={<StackSeparator m={0} />} gap={0}>
                      {item.channels
                        .filter(
                          (ch: CustomerChannelDomain) => ch.type === chan.type
                        )
                        .map(
                          (
                            channelOfSameType: CustomerChannelDomain,
                            index,
                            fullArray
                          ) => (
                            <Button
                              key={channelOfSameType.id}
                              w="100%"
                              colorPalette={colorScheme}
                              unstyled={true}
                              borderTopRadius={index === 0 ? "md" : 0}
                              borderBottomRadius={
                                index === fullArray.length - 1 ? "md" : 0
                              }
                              onClick={() => {
                                handleCreate(channelOfSameType.id!);
                              }}
                              _hover={{
                                backgroundColor:
                                  colorMode === "dark" ? "gray.900" : "gray.50",
                              }}
                            >
                              {channelOfSameType.handle}
                              <Icon as={LuStepForward} ml={2} />
                            </Button>
                          )
                        )}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </PopoverRoot>
          );
        })}
    </Flex>
  );
};

export default ContactChannelsColumn;
