import {
  List,
  Text,
} from "@chakra-ui/react";
import { Alert } from "components/ui/alert";
import {
  WabaTemplateCategory,
  WhatsappButton,
} from "entities/domain/whatsapp-template";
import React, { useEffect, useState } from "react";
import { validateWhatsappTempalte } from "./utils";

interface ValidationErrorsProps {
  templateName: string;
  templateCategory: WabaTemplateCategory;
  headerText: string;
  bodyText: string;
  footerText: string;
  buttons: WhatsappButton[];
  file: File | null;
  onValidationComplete: (isValid: boolean) => void;
}

const ValidationErrors = ({
  templateName,
  templateCategory,
  headerText,
  bodyText,
  footerText,
  buttons,
  file,
  onValidationComplete,
}: ValidationErrorsProps) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    const newValidationErrors = validateWhatsappTempalte(
      templateName,
      templateCategory,
      headerText,
      bodyText,
      file,
      footerText,
      buttons
    );

    setValidationErrors(newValidationErrors);
    onValidationComplete(newValidationErrors.length === 0);
  }, [
    templateName,
    templateCategory,
    headerText,
    bodyText,
    footerText,
    buttons,
    file,
  ]);

  return validationErrors.length > 0 ? (
    <Alert
      status="warning"
      variant="subtle"
      w="100%"
      flexShrink={0}
      title="Make sure to address the following issues before trying to submit your template:"
    >
      <List.Root w="100%">
        {validationErrors.map((error, index) => (
          <List.Item key={index} w="100%">
            <Text>{error}</Text>
          </List.Item>
        ))}
      </List.Root>
    </Alert>
  ) : null;
};

export default ValidationErrors;
