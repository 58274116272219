import { useAuth0 } from "@auth0/auth0-react";
import {
  FlexProps,
  Icon,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import ChatBackgroundModal from "components/user-settings/TeamMemberSettings/ChatBackgroundModal";
import React, { useState } from "react";
import { LuSettings } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";

interface BackgroundSettingsProps {
  onBackgroundChange: (backgroundStyles: FlexProps) => void;
}

const BackgroundSettings: React.FC<BackgroundSettingsProps> = ({
  onBackgroundChange,
}) => {
  const auth0Context = useAuth0();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { colorMode } = useColorMode();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { open, onClose, onOpen } = useDisclosure();

  const handleChatBackgroundChangeCompleted = async (value: string) => {
    try {
      await AgentsService.updateAgent(
        auth0Context,
        {
          id: currentAgent!.id,
          name: currentAgent!.name,
          surname: currentAgent!.surname,
          email: currentAgent!.email,
          notification_handle: currentAgent!.notificationConfig?.handle || "",
          notification_channel:
            currentAgent!.notificationConfig?.contactChannel || "",
          notification_preference:
            currentAgent!.notificationConfig?.preference || "",
          notification_timezone:
            currentAgent!.notificationConfig?.timezone || "",
          role_id: currentAgent!.getRole(merchant.id).id,
          active: currentAgent!.isActive,
          profile_picture_url: currentAgent!.getPicture(),
          player_ids: currentAgent!.playerIds || null,
          style_preferences: {
            chat_background: value || null,
            color_scheme: currentAgent!.stylePreferences?.colorScheme || null,
          },
        },
        merchant.id
      );

      return null;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save a agent: ", err);
      toaster.create({
        type: "error",
        title: "Failed to save chat background settings!",
      });
      /* eslint-enable no-console */
      return null;
    }
  };

  return (
    <>
      <IconButton
        aria-label="Background settings"
        ml={isBaseSize ? 2 : 0}
        position="fixed"
        zIndex={999}
        colorPalette="gray"
        variant={isBaseSize || !isHovered ? "ghost" : "solid"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onOpen}
      >
        <Icon
          as={LuSettings}
          color={
            isHovered
              ? "white"
              : colorMode === "dark"
              ? "gray.200"
              : "gray.600"
          }
        />
      </IconButton>
      <ChatBackgroundModal
        isOpen={open}
        onClose={onClose}
        onChatBackgroundChangeCompleted={handleChatBackgroundChangeCompleted}
        onChatBackgroundChange={onBackgroundChange}
      />
    </>
  );
};

export default BackgroundSettings;
