import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import ReviewDomain, {
  ReviewStatus,
} from "entities/domain/reviews/review-domain";
import React from "react";
import { LuTrash2 } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import ReviewsService from "services/reviews";
import ReviewAvatar from "./ReviewAvatar";
import Rating from "./ReviewRatings";

interface ReviewProps {
  item: ReviewDomain;
}

const ReviewCard = ({ item: review }: ReviewProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState<boolean>(false);

  async function handleReviewDelete() {
    await ReviewsService.deleteReview(auth0Context, merchant.id, review.id);
  }

  return (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      px={isBaseSize ? 2 : 12}
    >
      <Flex
        flexShrink={1}
        h="100%"
        justifyContent="start"
        alignItems="center"
        gridGap={8}
      >
        <Box flexShrink={0}>
          <ReviewAvatar review={review} />
        </Box>
        <VStack
          h="100%"
          alignItems="start"
          justifyContent="space-between"
          overflow="hidden"
          py={4}
          flexShrink={1}
          gridGap={4}
        >
          <VStack
            alignItems="start"
            justifyContent={review.content ? "start" : "center"}
            h="100%"
          >
            <Text
              textAlign="left"
              fontSize="sm"
              color="gray.500"
              fontWeight="bold"
            >
              {review.reviewer.name || "Anonymous"}
            </Text>
            <Rating value={review.rating} />
          </VStack>
          {review.content && (
            <Text alignSelf="start" overflow="hidden" textAlign="left">
              {review.content}
            </Text>
          )}
        </VStack>
      </Flex>
      <VStack
        flexShrink={0}
        h="100%"
        justifyContent={
          review.status === ReviewStatus.PENDING ? "space-between" : "center"
        }
        alignItems="end"
        py={4}
      >
        {review.status === ReviewStatus.PENDING ? (
          <IconButton
            aria-label="Delete review"
            colorPalette="red"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <Icon as={LuTrash2} />
          </IconButton>
        ) : null}
        <Text color="gray.200" fontSize="xs">
          {review.displayDate}
        </Text>
      </VStack>
      <ConfirmationDialog
        headerText="Delete Review?"
        messageText={`Are you sure you want to delete pending review from ${
          review.reviewer.name || "Anonymous"
        }?`}
        buttonText="Yes"
        isOpen={showDeleteConfirmation}
        setIsOpen={setShowDeleteConfirmation}
        confirmationCallback={() => {
          setShowDeleteConfirmation(false);
          handleReviewDelete();
        }}
      />
    </Flex>
  );
};

export default ReviewCard;
