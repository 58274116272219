import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Fieldset,
  Grid,
  GridItem,
  Heading,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
import BackIconButton from "components/shared/BackButton";
import Topbar from "components/shared/topbar/TopBar";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogHeader, DialogRoot } from "components/ui/dialog";
import { Field } from "components/ui/field";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import ChannelService from "services/channel";
import ZapierChannel from "./ZapierChannel";

const ZapierIntegration = () => {
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { merchant } = useAppSelector((state) => state.merchant);

  const [isAssigningWebhook, setIsAssigningWebhook] = useState<boolean>(false);

  const [zapierChannels, setZapierChannels] = useState<ChannelDomain[]>(
    [] as ChannelDomain[]
  );
  const [channelInEdition, setChannelInEdition] =
    useState<ChannelDomain | null>(null);

  useEffect(() => {
    const existingZapierChannels = merchant.channels.filter((channel) => {
      return channel.channelName === "zapier_facebook";
    });
    setZapierChannels(existingZapierChannels);
  }, [merchant.id, merchant.channels]);

  const saveWebhookHandler = async () => {
    if (channelInEdition) {
      setIsAssigningWebhook(true);
      await ChannelService.assignCustomWebhook(
        auth0Context,
        channelInEdition,
        merchant.id
      );
      setChannelInEdition(null);
      setIsAssigningWebhook(false);
    }
  };

  return (
    <Box w="100%" h="100%" mb="24px">
      <Topbar
        title="Webhooks for Zapier"
        isFlex={true}
        leftChildrenMobile={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/integrations`);
            }}
          />
        }
        leftChildren={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/integrations`);
            }}
          />
        }
      />

      <Box mx="12" mt="8">
        {zapierChannels.length === 0 ? (
          <Heading size="md">No Zapier Channel has been created yet.</Heading>
        ) : (
          <Grid templateColumns="repeat(2, 1fr) 200px">
            <GridItem pl="8">Page</GridItem>
            <GridItem pl="4">URL</GridItem>
            <GridItem />

            {zapierChannels.map((channel) => {
              return (
                <ZapierChannel
                  key={channel.id}
                  channel={channel}
                  setChannelInEdition={setChannelInEdition}
                />
              );
            })}
          </Grid>
        )}
      </Box>
      <DialogRoot
        open={!!channelInEdition}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            setChannelInEdition(null);
          }
        }}
        size={isBaseSize ? "full" : "md"}
      >
        <DialogBackdrop  />
        <DialogContent>
          <DialogCloseTrigger />
          <DialogHeader>Assign Zapier Webhook</DialogHeader>
          <DialogBody>
            <Fieldset.Root justifyContent="flex-start">
              <Fieldset.Content>
                <Field label="Page">
                  <Input
                    colorPalette={colorScheme}
                    data-testid="zapier-page-id-edition"
                    borderRadius="full"
                    value={channelInEdition?.identifier}
                    disabled
                  />
                </Field>

                <Field label="URL">
                  <Input
                    colorPalette={colorScheme}
                    data-testid="zapier-custom-webhook-url-edition"
                    borderRadius="full"
                    value={channelInEdition?.customWebhook}
                    onChange={(e) => {
                      if (channelInEdition) {
                        setChannelInEdition(
                          Object.setPrototypeOf(
                            {
                              ...channelInEdition,
                              customWebhook: e.target.value,
                            },
                            ChannelDomain.prototype
                          ) as ChannelDomain
                        );
                      }
                    }}
                  />
                </Field>
              </Fieldset.Content>
              <Button
                colorPalette={colorScheme}
                data-testid="zapier-save-button-edition"
                borderRadius="full"
                fontSize="0.75rem"
                lineHeight="10px"
                w="full"
                fontWeight={700}
                _hover={{ backgroundColor: "#4fbeff" }}
                _focus={{ border: "none" }}
                onClick={saveWebhookHandler}
                size="md"
                marginTop="10px"
                loading={isAssigningWebhook}
              >
                Save
              </Button>
            </Fieldset.Root>
          </DialogBody>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};
export default ZapierIntegration;
