import { Flex, Icon, Image, Text, useBreakpointValue } from "@chakra-ui/react";

import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from "components/ui/dialog";
import React from "react";
import { ReactSVG } from "react-svg";
import { IntegrationOptionGroups } from "../../../Integrations/ConnectIntegrationTypes";
import StepsContent from "./StepsContent";

interface ConnectIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgWidth?: string;
  imgHeight?: string;
  displayError: boolean;
  integrationOption: IntegrationOptionGroups | undefined;
}

const InstagramSetUpSteps = ({
  isOpen,
  onClose,
  integrationOption,
  imgWidth,
  imgHeight,
  displayError,
}: ConnectIntegrationModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const steps = integrationOption?.extendedConnect?.steps;

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      size={isBaseSize ? "full" : "xl"}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle display="flex" alignItems="center">
            <Image
              justifyContent="center"
              mb={isBaseSize ? "0.5rem" : 0}
              mr={isBaseSize ? 0 : "0.5rem"}
              asChild={true}
              css={{
                "& svg": {
                  height: "30px",
                  width: "30px",
                },
              }}
            >
              <ReactSVG src={integrationOption?.mobileLogo || ""} />
            </Image>
            {integrationOption?.extendedConnect?.title}
          </DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          {displayError && (
            <Flex
              mt="35px"
              border="1px solid rgba(255, 0, 0, 0.13)"
              bgColor="rgba(255, 0, 0, 0.08)"
              alignItems="flex-start"
              py="0.75rem"
              px="0.5rem"
            >
              <Icon
                asChild={true}
                mr="30px"
                css={{ "& svg": { height: "30px", width: "30px" } }}
              >
                <ReactSVG src="/failed-status.svg" />
              </Icon>
              <Text>{integrationOption?.extendedConnect?.warning}</Text>
            </Flex>
          )}
          <StepsContent
            steps={steps}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
          />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

export default InstagramSetUpSteps;
