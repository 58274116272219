import {
  Button,
  ClipboardRoot,
  HStack,
  Icon,
  Image,
  Input,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { ReactComponent as CarGurusIcon } from "assets/icons/car-gurus.svg";
import CarZoneIcon from "assets/icons/car-zone.svg";
import CarsIrelandIcon from "assets/icons/cars-ireland.svg";
import { ReactComponent as DoneDealIcon } from "assets/icons/done-deal.svg";
import { ReactComponent as EbayIcon } from "assets/icons/ebay.svg";
import { ReactComponent as MotorsIcon } from "assets/icons/motors.svg";

import Integration from "components/user-settings/shared/Integration";
import React, { useEffect, useState } from "react";
import { LuChevronLeft, LuChevronRight, LuGlobe } from "react-icons/lu";

import { useAuth0 } from "@auth0/auth0-react";
import { ClipboardIconButton } from "components/ui/clipboard";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import { InputGroup } from "components/ui/input-group";
import LeadEmailHandlerDomain from "entities/domain/leads/lead-email-handler-domain";
import { useAppSelector } from "redux/hooks";
import LeadEmailHandlersService from "services/leadEmailHandlers";
import { isProductionEnvironment } from "util/methods";

type SvgIcon = React.FC<React.SVGProps<SVGSVGElement>>;

interface IconData {
  icon: SvgIcon | string;
  title: string;
  type: "svg" | "png";
  source: string;
}

const MarketPlacesIntegrationItem = () => {
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const { open, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();

  if (merchant.country !== "GB" && merchant.country !== "IE") {
    return null;
  }
  const EMAIL_DOMAIN = isProductionEnvironment()
    ? "@leads.getfuzey.com"
    : "@leads.tryfuzey.com";
  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+$/;

  const gbIcons: IconData[] = [
    { icon: EbayIcon, title: "eBay", type: "svg", source: "ebay" },
    { icon: MotorsIcon, title: "Motors", type: "svg", source: "motors" },
    { icon: CarGurusIcon, title: "CarGurus", type: "svg", source: "cargurus" },
    {
      icon: LuGlobe,
      title: "Your Website",
      type: "svg",
      source: "website",
    },
  ];

  const ieIcons: IconData[] = [
    { icon: CarZoneIcon, title: "CarZone", type: "svg", source: "carzone" },
    { icon: DoneDealIcon, title: "DoneDeal", type: "svg", source: "donedeal" },
    {
      icon: CarsIrelandIcon,
      title: "CarsIreland",
      type: "svg",
      source: "carsireland",
    },
    {
      icon: LuGlobe,
      title: "Your Website",
      type: "svg",
      source: "website",
    },
  ];
  const { colorScheme } = useAppSelector((state) => state.theme);

  const icons = merchant.country === "IE" ? ieIcons : gbIcons;

  const auth0Context = useAuth0();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [leadEmailHandlers, setLeadEmailHandlers] = useState<
    LeadEmailHandlerDomain[]
  >([]);

  const [emailPrefix, setEmailPrefix] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const currentHandler = leadEmailHandlers.find(
    (handler) => handler.source === icons[carouselIndex].source
  );

  const generateEmailPrefix = (merchantName: string, source: string) => {
    const normalizedMerchantName = merchantName
      .toLowerCase()
      .replace(/[^a-z0-9]/g, ""); // Remove special chars except letters and numbers
    return `${normalizedMerchantName}_${source.toLowerCase()}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [icons.length]);

  useEffect(() => {
    if (!currentHandler) {
      const generatedPrefix = generateEmailPrefix(
        merchant.name,
        icons[carouselIndex].source
      );
      setEmailPrefix(generatedPrefix);
      validateEmailPrefix(generatedPrefix); // Validate the generated prefix
    }
  }, [carouselIndex, merchant.name]);

  useEffect(() => {
    LeadEmailHandlersService.getAllLeadEmailHandlers(
      auth0Context,
      merchant.id
    ).then((handlers) => {
      setLeadEmailHandlers(handlers);
    });
  }, [auth0Context, merchant.id]);

  const currentIcon = icons[currentIndex];

  const nextCarouselItem = () => {
    setCarouselIndex((prevIndex) => (prevIndex + 1) % icons.length);
  };

  const prevCarouselItem = () => {
    setCarouselIndex(
      (prevIndex) => (prevIndex - 1 + icons.length) % icons.length
    );
  };

  const validateEmailPrefix = (value: string) => {
    if (!value) {
      setErrorMessage("Email prefix is required");
      return false;
    }
    if (!EMAIL_REGEX.test(value)) {
      setErrorMessage(
        "Only letters, numbers, dots, underscores and hyphens are allowed"
      );
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const createLeadEmailHandler = (email: string, source: string) => {
    LeadEmailHandlersService.createLeadEmailHandler(
      auth0Context,
      merchant.id,
      email,
      source
    ).then((newHandler) => {
      setLeadEmailHandlers((prevHandlers) => [...prevHandlers, newHandler]);
    });
  };

  const renderIcon = (
    iconData: IconData,
    height: string = "3rem",
    width: string = "8rem"
  ) => {
    if (iconData.type === "svg") {
      return (
        <Icon as={iconData.icon as SvgIcon} height={height} width={width} />
      );
    } else {
      return (
        <Image
          src={iconData.icon as string}
          alt={iconData.title}
          height={height}
          width={width}
        />
      );
    }
  };

  const getConnectionStatus = (source: string) => {
    const handler = leadEmailHandlers.find((h) => h.source === source);
    return handler ? "Connected" : "Not connected";
  };

  return (
    <>
      <Integration
        icon={renderIcon(currentIcon)}
        primaryButton={
          <Button onClick={onOpen} colorPalette={colorScheme}>
            Connect
          </Button>
        }
      >
        <Text mt={2}>
          Automatically collect and forward new customer leads from{" "}
          <b>{currentIcon.title}</b> to our system.
        </Text>
      </Integration>

      <DialogRoot
        open={open}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
          }
        }}
        size="md"
      >
        <DialogBackdrop />
        <DialogContent borderRadius="xl">
          <DialogHeader textAlign="center">
            Connect to {icons[carouselIndex].title}
          </DialogHeader>
          <DialogCloseTrigger />
          <DialogBody p={8}>
            <VStack gap={8} align="stretch">
              <VStack gap={4}>
                {renderIcon(icons[carouselIndex])}
                <Text
                  fontSize="sm"
                  color={currentHandler ? "green.500" : "gray.500"}
                >
                  {getConnectionStatus(icons[carouselIndex].source)}
                </Text>
              </VStack>

              <VStack gap={2} align="stretch">
                <Text fontWeight="bold">
                  {currentHandler
                    ? "Please forward the emails to us at:"
                    : "Set up your email address for receiving leads:"}
                </Text>
                {currentHandler ? (
                  <ClipboardRoot value={currentHandler?.email || ""}>
                    <InputGroup endElement={<ClipboardIconButton />}>
                      <Input
                        pr="4.5rem"
                        type="text"
                        size="sm"
                        value={currentHandler.email}
                        readOnly
                        bg={colorMode === "dark" ? "black" : "gray.100"}
                        fontSize="xs"
                        css={{
                          direction: "rtl",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          "&::placeholder": {
                            direction: "ltr",
                          },
                        }}
                      />
                    </InputGroup>
                  </ClipboardRoot>
                ) : (
                  <Field errorText={errorMessage} invalid={!!errorMessage}>
                    <InputGroup
                      endElement={
                        <HStack gap={2} pr={2}>
                          <Text fontSize="xs" color="gray.500">
                            {EMAIL_DOMAIN}
                          </Text>
                          <Button
                            size="xs"
                            colorPalette={colorScheme}
                            onClick={() => {
                              if (validateEmailPrefix(emailPrefix)) {
                                createLeadEmailHandler(
                                  `${emailPrefix}${EMAIL_DOMAIN}`,
                                  icons[carouselIndex].source
                                );
                              }
                            }}
                            disabled={!emailPrefix || !!errorMessage}
                          >
                            Save
                          </Button>
                        </HStack>
                      }
                    >
                      <Input
                        pr="10rem"
                        size="sm"
                        type="text"
                        value={emailPrefix}
                        onChange={(e) => {
                          setEmailPrefix(e.target.value);
                          validateEmailPrefix(e.target.value);
                        }}
                        fontSize="xs"
                      />
                    </InputGroup>
                  </Field>
                )}
              </VStack>
            </VStack>
          </DialogBody>
          <DialogFooter justifyContent="space-between">
            <Button
              colorPalette={colorScheme}
              onClick={prevCarouselItem}
              variant="ghost"
            >
              <LuChevronLeft /> Prev
            </Button>
            <Text>
              {carouselIndex + 1} of {icons.length}
            </Text>
            <Button
              onClick={nextCarouselItem}
              colorPalette={colorScheme}
              variant="ghost"
            >
              Next <LuChevronRight />
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default MarketPlacesIntegrationItem;
