import { Button, List, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import React, { useEffect, useRef, useState } from "react";

interface CustomFieldMenuItemProps {
  onClick: () => void;
  customFieldKey: string;
}

export const humanizeCustomFieldKey = (key: string) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const CustomFieldMenuItem: React.FC<CustomFieldMenuItemProps> = ({
  onClick,
  customFieldKey,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [isOverflown, setIsOverflown] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const button = ref.current;
    setIsOverflown(button.scrollWidth > button.clientWidth);
  }, [ref.current]);

  return (
    <Tooltip
      content={humanizeCustomFieldKey(customFieldKey)}
      disabled={!isOverflown || isBaseSize}
      positioning={{ placement: "right" }}
    >
      <List.Item asChild={true}>
        <Button
          ref={ref}
          mt={2}
          px={2}
          py={1}
          onClick={onClick}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          w="100%"
          display="block"
          textAlign="left"
          verticalAlign="middle"
          colorPalette="gray"
          borderRadius={0}
          variant={colorMode === "dark" ? "subtle" : "ghost"}
          fontWeight="normal"
        >
          {humanizeCustomFieldKey(customFieldKey)}
        </Button>
      </List.Item>
    </Tooltip>
  );
};

export default CustomFieldMenuItem;
