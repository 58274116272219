import { Button, Heading, Icon } from "@chakra-ui/react";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import React, { useEffect, useState } from "react";
import { updateMerchantSuccess } from "redux/features/merchant";

import { useAuth0 } from "@auth0/auth0-react";
import { toaster } from "components/ui/toaster";
import InstagramSetUpSteps from "components/user-settings/Channels/items/extended-setup/ExtendedIntegrationSetup";
import { integrationOption } from "components/user-settings/Integrations/ConnectIntegrationTypes";
import { getInstagramRefreshEndpoint } from "components/user-settings/constants";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { FiRefreshCcw } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { isMobileApp } from "util/methods";

interface InstagramIntegrationItemProps {}

const InstagramIntegrationItem = (_props: InstagramIntegrationItemProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.INSTAGRAM);

  const redirect_url = new URLSearchParams(useLocation().search);
  const redirect_location = redirect_url.get("redirect_location");
  const facebook_error = redirect_url.get("error");

  const onDisconnectInstagram = async () => {
    try {
      await InboxService.removeInstagramIntegration(auth0Context, merchant.id);

      merchant.integrations[IntegrationName.INSTAGRAM].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not disconnect Instagram at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      icon={<Icon as={InstagramIcon} height="3rem" width="3rem" />}
      isConnected={isConnected}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectInstagram} />
        ) : null
      }
      primaryButton={
        isDisabled ? null : isConnected ? (
          <Button
            colorPalette={colorScheme}
            onClick={() => {
              const instagramRefreshEndpoint = getInstagramRefreshEndpoint(
                merchant.id
              );

              if (isMobileApp()) {
                window.natively.openExternalURL(instagramRefreshEndpoint);
              } else {
                window.open(instagramRefreshEndpoint, "_blank");
              }
            }}
          >
            <Icon as={FiRefreshCcw} /> Refresh
          </Button>
        ) : (
          <>
            <Button
              colorPalette={colorScheme}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <Icon as={LuPlus} /> Connect
            </Button>
            <InstagramSetUpSteps
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              integrationOption={integrationOption.INSTAGRAM(
                colorScheme,
                merchant.id
              )}
              imgWidth="240px"
              imgHeight="40px"
              displayError={
                redirect_location === "instagram" && !!facebook_error
              }
            />
          </>
        )
      }
    >
      <Heading as="h3" size="lg">
        Instagram
      </Heading>
      <IntegrationDescription text="Leverage visual marketing by integrating Instagram, offering direct photo sharing." />
    </Integration>
  );
};

export default InstagramIntegrationItem;
