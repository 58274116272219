import { Flex, Icon, IconButton, Input, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React, { memo, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { LuCheck, LuFilePen, LuX } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface MarketingPrefixProps {
  prefix: string;
  setPrefix: (newPrefix: string) => void;
  isDisabled?: boolean;
}

const MarketingPrefix: React.FC<MarketingPrefixProps> = ({
  prefix,
  setPrefix,
  isDisabled = false,
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [localPrefix, setLocalPrefix] = useState<string>(prefix);

  useEffect(() => {
    setLocalPrefix(prefix);
  }, [prefix]);

  return (
    <Flex
      alignItems="center"
      justifyContent="start"
      gridGap={2}
      borderRadius="md"
      borderColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      borderStyle="solid"
      borderWidth="1px"
      width="fit-content"
      p={2}
      mb={2}
    >
      {isEditing ? (
        <>
          <Input
            border="none"
            value={localPrefix}
            onChange={(e) => {
              setLocalPrefix(e.target.value);
            }}
            placeholder="Enter the name of your business"
          />
          <IconButton
            variant="ghost"
            aria-label="Cancel"
            colorPalette="red"
            size="xs"
            onClick={() => {
              setLocalPrefix(prefix);
              setIsEditing(false);
            }}
          >
            <Icon as={LuX} boxSize={4} />
          </IconButton>
          <IconButton
            variant="ghost"
            aria-label="Edit prefix"
            size="xs"
            colorPalette="green"
            onClick={() => {
              if (localPrefix.trim().length === 0) {
                setPrefix("");
                setIsEditing(false);

                return;
              }

              if (/.+:$/.test(localPrefix)) {
                setPrefix(localPrefix);
              } else {
                setPrefix(`${localPrefix}:`);
              }

              setIsEditing(false);
            }}
          >
            <Icon as={LuCheck} boxSize={4} />
          </IconButton>
        </>
      ) : (
        <>
          <Text color={colorMode === "dark" ? "gray.100" : "gray.600"}>
            {prefix || "Add company prefix"}
          </Text>
          <IconButton
            disabled={isDisabled}
            variant="ghost"
            aria-label="Edit prefix"
            size="xs"
            colorPalette={colorScheme}
            onClick={() => setIsEditing(true)}
          >
            <Icon as={LuFilePen} boxSize={4} />
          </IconButton>
          {!prefix ? null : (
            <IconButton
              variant="ghost"
              size="xs"
              aria-label="Remove prefix"
              colorPalette={colorScheme}
              disabled={isDisabled}
              onClick={() => {
                setPrefix("");
                setIsEditing(false);
              }}
            >
              <Icon as={FaTrash} boxSize={3} />
            </IconButton>
          )}
        </>
      )}
    </Flex>
  );
};

export default memo(MarketingPrefix);
