import { Button, Flex, Icon, Spinner, Text, VStack } from "@chakra-ui/react";
import {
  ActionBarContent,
  ActionBarRoot,
  ActionBarSelectionTrigger,
  ActionBarSeparator,
} from "components/ui/action-bar";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import {
  LuCopy,
  LuCopyCheck,
  LuMinus,
  LuPlus,
  LuUsersRound,
  LuX,
} from "react-icons/lu";
import { batch } from "react-redux";
import {
  clearSelectedAgents,
  disableTeamCreationTool,
  updateAgentSelection,
} from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface TeamCreationToolProps {
  openAddToTeamModal: () => void;
  openRemoveFromTeamModal: () => void;
}

const TeamCreationTool = ({
  openAddToTeamModal,
  openRemoveFromTeamModal,
}: TeamCreationToolProps) => {
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    isTeamCreationToolLoading,
    selectedAgentIds,
    isTeamCreationToolEnabled,
    agents,
  } = useAppSelector((state) => state.agents);

  return (
    <>
      <ActionBarRoot
        open={selectedAgentIds.length > 0}
        closeOnInteractOutside={false}
      >
        <ActionBarContent
          position="relative"
          bgColor={`${colorScheme}.solid`}
          py={0}
          pr={0}
          pl={3}
          overflow="hidden"
        >
          {!isTeamCreationToolLoading ? null : (
            <Flex
              alignItems="center"
              justifyContent="center"
              position="absolute"
              left={0}
              top={0}
              width="100%"
              height="100%"
              zIndex={2}
              bgColor={
                colorMode === "dark" ? "blackAlpha.600" : "whiteAlpha.600"
              }
            >
              <Spinner />
            </Flex>
          )}
          <ActionBarSelectionTrigger
            my={3}
            borderColor={colorMode === "dark" ? "black" : "white"}
          >
            <VStack height="100%" gap={0} p={2} justifyContent="center">
              <Text
                fontSize="xl"
                color={colorMode === "dark" ? "black" : "white"}
              >
                {selectedAgentIds.length}
              </Text>
              <Text
                fontSize="md"
                color={colorMode === "dark" ? "black" : "white"}
              >
                Selected
              </Text>
            </VStack>
          </ActionBarSelectionTrigger>
          <ActionBarSeparator bg={colorMode === "dark" ? "black" : "white"} />
          <Button
            height="100%"
            borderRadius={0}
            colorPalette={colorScheme}
            display="flex"
            flexDirection="column"
            py={3}
            className="dark"
            variant="ghost"
            color={colorMode === "dark" ? "black" : "white"}
            justifyContent="space-between"
            disabled={!isTeamCreationToolEnabled}
            gridGap={2}
            onClick={() => {
              if (selectedAgentIds.length === agents.length) {
                dispatch(clearSelectedAgents());

                return;
              }

              batch(() => {
                agents.forEach((c) => {
                  dispatch(
                    updateAgentSelection({
                      agentId: c.id,
                      isSelected: true,
                    })
                  );
                });
              });
            }}
          >
            <Icon
              as={
                selectedAgentIds.length === agents.length ? LuCopy : LuCopyCheck
              }
              w={6}
              h={6}
              color={colorMode === "dark" ? "gray.800" : "white"}
            />
            {selectedAgentIds.length === agents.length
              ? "Deselect All"
              : "Select All"}
          </Button>
          <Button
            height="100%"
            borderRadius={0}
            display="flex"
            colorPalette={colorScheme}
            flexDirection="column"
            justifyContent="space-between"
            py={3}
            className="dark"
            color={colorMode === "dark" ? "black" : "white"}
            variant="ghost"
            gridGap={2}
            disabled={!isTeamCreationToolEnabled}
            onClick={() => {
              dispatch(disableTeamCreationTool());
              openAddToTeamModal();
            }}
          >
            <VStack position="relative" justifyContent="start">
              <Icon
                as={LuUsersRound}
                w={6}
                h={6}
                mr="0.3rem"
                color={colorMode === "dark" ? "gray.800" : "white"}
              />
              <Icon
                as={LuPlus}
                w={3}
                h={3}
                position="absolute"
                top="-0.25rem"
                right="-0.25rem"
                color={colorMode === "dark" ? "gray.800" : "white"}
              />
            </VStack>
            Add to Team
          </Button>
          <Button
            height="100%"
            borderRadius={0}
            display="flex"
            colorPalette={colorScheme}
            flexDirection="column"
            color={colorMode === "dark" ? "black" : "white"}
            justifyContent="space-between"
            py={3}
            className="dark"
            variant="ghost"
            gridGap={2}
            disabled={!isTeamCreationToolEnabled}
            onClick={() => {
              dispatch(disableTeamCreationTool());
              openRemoveFromTeamModal();
            }}
          >
            <VStack position="relative" justifyContent="start">
              <Icon
                as={LuUsersRound}
                w={6}
                h={6}
                mr="0.3rem"
                color={colorMode === "dark" ? "gray.800" : "white"}
              />
              <Icon
                as={LuMinus}
                w={3}
                h={3}
                position="absolute"
                top="-0.25rem"
                right="-0.25rem"
                color={colorMode === "dark" ? "gray.800" : "white"}
              />
            </VStack>
            Remove from Team
          </Button>
          <Button
            borderLeftWidth="1px"
            borderLeftStyle="solid"
            colorPalette={colorScheme}
            borderLeftColor={colorMode === "dark" ? "gray.800" : "white"}
            height="100%"
            borderRadius={0}
            display="flex"
            py={3}
            color={colorMode === "dark" ? "black" : "white"}
            className="dark"
            variant="ghost"
            flexDirection="column"
            justifyContent="center"
            gridGap={2}
            disabled={!isTeamCreationToolEnabled}
            onClick={() => {
              dispatch(clearSelectedAgents());
            }}
          >
            <Icon as={LuX} w={4} h={4} mx={2} />
          </Button>
        </ActionBarContent>
      </ActionBarRoot>
    </>
  );
};

export default TeamCreationTool;
