import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  ButtonGroup,
  Card,
  Icon,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import { Tooltip } from "components/ui/tooltip";
import { ReplySuggestionStatus } from "entities/domain/reply_suggestion";
import TemplateDomain from "entities/domain/templates";
import useAnalytics from "hooks/use-analytics";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { LuPencil, LuSend, LuX } from "react-icons/lu";
import { useInView } from "react-intersection-observer";
import {
  MessageInputTab,
  setMessageInputReplySuggestionCustomFields,
  setMessageInputState,
  setMessageInputTemplate,
  updateMessageInputActiveTab,
} from "redux/features/conversations";
import { setAutoReplySuggestion } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import ReplySuggestionService from "services/replySuggestion";
import { calculateTypingTime } from "util/methods";
import FailedReplySuggestion from "./FailedReplySuggestion";
import GeneratedReplySuggestion from "./GeneratedReplySuggestion";
import LoadingReplySuggestion from "./LoadingReplySuggestion";

interface ReplySuggestionProps {}

const LOADING_MESSAGES = [
  "One moment, your AI assistant is thinking...",
  "One moment, your AI assistant is on it!",
  "Just a moment while your AI assistant writes your response.",
  "One second! Your AI assistant is thinking.",
  "Good things take a second - your AI assistant is working on it.",
  "Your AI assistant will have your response in just a moment.",
  "Working on it - great responses take a little thinking time!",
  "One moment, your AI assistant is working on your reply.",
  "Generating your reply, just a moment...",
  "Your AI assistant is typing… almost there!",
  "One moment, your AI assistant is thinking.",
  "One moment, your AI assistant is on it!",
  "Just a moment while we write your response.",
  "One second! We're thinking.",
  "Good things take a second - let us get that answer for you.",
  "We'll have your response in just a moment.",
  "Working on it - great responses take a little thinking time!",
  "One moment, we're working on your reply.",
  "Generating your reply, just a moment...",
  "We're on it! Just writing out your response.",
];

const ReplySuggestion = (_props: ReplySuggestionProps): React.ReactNode => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeConversation, activeConversationId, messageInput } =
    useAppSelector((state) => state.conversations);
  const { autoReplySuggestion: loadedReplySuggestion } = useAppSelector(
    (state) => state.messages
  );

  const { track } = useAnalytics();

  const [draftActionPressed, setDraftActionPressed] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { ref: suggestionRef, inView: isSuggestionInView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (
      loadedReplySuggestion &&
      isSuggestionInView &&
      activeConversationId &&
      loadedReplySuggestion.status === ReplySuggestionStatus.GENERATED
    ) {
      const timeoutId = setTimeout(() => {
        ReplySuggestionService.markReplySuggestionAsRead(
          auth0Context,
          merchant.id,
          activeConversationId,
          loadedReplySuggestion.id
        )
          .then((updatedSuggestion) => {
            dispatch(setAutoReplySuggestion(updatedSuggestion));
          })
          .catch((error) => {
            console.error("Failed to mark reply suggestion as read", error);
          });
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [
    isSuggestionInView,
    loadedReplySuggestion,
    auth0Context,
    merchant.id,
    activeConversationId,
    dispatch,
  ]);

  const removeReplySuggestion = useCallback(() => {
    dispatch(setAutoReplySuggestion(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (!loadedReplySuggestion) return;

    if (
      loadedReplySuggestion.status === ReplySuggestionStatus.IN_PROGRESS ||
      loadedReplySuggestion.status === ReplySuggestionStatus.FAILED
    ) {
      const timeoutId = setTimeout(() => {
        removeReplySuggestion();
      }, 30000); // 30 seconds

      return () => clearTimeout(timeoutId);
    }
  }, [loadedReplySuggestion?.status]);

  const deleteDraft = useCallback(async () => {
    if (!activeConversationId || !loadedReplySuggestion) {
      return;
    }

    setDraftActionPressed(true);

    if (loadedReplySuggestion.status === ReplySuggestionStatus.GENERATED) {
      track("delete_draft_message", {
        conversation_id: activeConversation?.id,
      });

      try {
        await ReplySuggestionService.deleteReplySuggestion(
          auth0Context,
          merchant.id,
          activeConversationId,
          loadedReplySuggestion.id
        );
      } catch (error) {
        console.error("Error deleting draft message", error);
      }
    }

    setDraftActionPressed(false);
    removeReplySuggestion();
  }, [
    activeConversationId,
    loadedReplySuggestion,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
  ]);

  const editDraft = useCallback(async () => {
    if (
      !activeConversationId ||
      !loadedReplySuggestion ||
      !loadedReplySuggestion.body
    ) {
      return;
    }

    track("edit_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);

    dispatch(
      setMessageInputState({
        ...messageInput,
        text: loadedReplySuggestion.body,
        replySuggestionId: loadedReplySuggestion.id,
      })
    );
    dispatch(updateMessageInputActiveTab(MessageInputTab.EDIT));
    dispatch(setMessageInputTemplate(null));
    dispatch(
      setMessageInputReplySuggestionCustomFields(
        loadedReplySuggestion.customFields
      )
    );
    removeReplySuggestion();

    setDraftActionPressed(false);
  }, [
    activeConversationId,
    loadedReplySuggestion,
    auth0Context,
    merchant.id,
    activeConversation,
  ]);

  const sendDraft = useCallback(async () => {
    if (
      !activeConversationId ||
      !loadedReplySuggestion ||
      !loadedReplySuggestion.replyToMessageId ||
      !loadedReplySuggestion.body
    ) {
      return;
    }

    track("send_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);

    try {
      await InboxService.sendMessage(
        auth0Context,
        {
          conversation_id: activeConversationId,
          body: loadedReplySuggestion.body,
          reply_to_message_id: loadedReplySuggestion.replyToMessageId,
          reply_suggestion_id: loadedReplySuggestion.id,
          custom_fields: loadedReplySuggestion.customFields,
        },
        merchant.id,
        activeConversationId
      );
      toaster.create({
        type: "success",
        title: `You've saved at least ${calculateTypingTime(
          loadedReplySuggestion.body ?? ""
        )}!`,
      });
    } finally {
      removeReplySuggestion();
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedReplySuggestion,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
  ]);

  const deleteDraftButton = useMemo(
    () => (
      <Tooltip
        content="Delete"
        aria-label="Delete"
        positioning={{ placement: "top" }}
      >
        <IconButton
          size="xs"
          colorPalette={colorScheme}
          variant="ghost"
          disabled={draftActionPressed}
          onClick={deleteDraft}
        >
          <Icon
            as={LuX}
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
        </IconButton>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, deleteDraft]
  );

  const editDraftButton = useMemo(
    () => (
      <Tooltip
        content="Edit message"
        aria-label="Edit message"
        positioning={{ placement: "top" }}
      >
        <IconButton
          size="xs"
          colorPalette={colorScheme}
          variant="ghost"
          disabled={draftActionPressed}
          onClick={editDraft}
        >
          <Icon
            as={LuPencil}
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
        </IconButton>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, editDraft]
  );

  const replySuggestionHasCustomFieldsWithoutValue = useMemo(
    () =>
      loadedReplySuggestion &&
      loadedReplySuggestion.body &&
      Object.keys(loadedReplySuggestion.customFields || {})
        ? TemplateDomain.containsCustomFieldsWithoutValue(
            loadedReplySuggestion.body,
            loadedReplySuggestion.customFields || {}
          )
        : false,
    [loadedReplySuggestion]
  );

  const sendDraftButton = useMemo(
    () => (
      <Tooltip
        content="Send message"
        aria-label="Send message"
        positioning={{ placement: "top" }}
      >
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorPalette={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.800` : `${colorScheme}.200`
          }
          py={3}
          height="auto"
          disabled={
            draftActionPressed || replySuggestionHasCustomFieldsWithoutValue
          }
          onClick={sendDraft}
        >
          <Icon
            as={LuSend}
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, sendDraft]
  );

  const loadingMessage = useMemo(() => {
    return LOADING_MESSAGES[
      Math.floor(Math.random() * LOADING_MESSAGES.length)
    ];
  }, [loadedReplySuggestion?.status]);

  if (
    loadedReplySuggestion === undefined ||
    loadedReplySuggestion.status === ReplySuggestionStatus.UNKNOWN
  ) {
    return null;
  }


  return (
    <Card.Root
      ref={suggestionRef}
      maxWidth="md"
      mx="auto"
      mb={4}
      backgroundColor={
        currentAgent!.stylePreferences?.chatBackground
          ? `${colorMode === "dark" ? "blackAlpha" : "whiteAlpha"}.800`
          : `${colorMode === "dark" ? "gray.600" : "white"}`
      }
    >
      {loadedReplySuggestion.status === ReplySuggestionStatus.IN_PROGRESS ? (
        <Card.Body
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={isBaseSize ? 1 : 3}
        >
          <LoadingReplySuggestion message={loadingMessage} />
        </Card.Body>
      ) : loadedReplySuggestion.status === ReplySuggestionStatus.FAILED ? (
        <Card.Body
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={isBaseSize ? 1 : 3}
        >
          <FailedReplySuggestion onDelete={deleteDraftButton} />
        </Card.Body>
      ) : (
        <>
          <Card.Body
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
            padding={isBaseSize ? 1 : 3}
          >
            <GeneratedReplySuggestion
              body={loadedReplySuggestion.body}
              editButton={editDraftButton}
              deleteButton={deleteDraftButton}
            />
          </Card.Body>
          <Card.Footer padding={0}>
            <ButtonGroup
              size="sm"
              attached
              variant="outline"
              w="100%"
              colorPalette={colorScheme}
            >
              {sendDraftButton}
            </ButtonGroup>
          </Card.Footer>
        </>
      )}
    </Card.Root>
  );
};

export default memo(ReplySuggestion);
