import { CloseButton, Flex, Text } from "@chakra-ui/react";
import FileDomain from "entities/domain/file";
import React from "react";
import FileThumbnail from "./FileThumbnail";

interface SubmittedFilesListProps {
  files: FileDomain[];
  size: "sm" | "md" | "lg";
  onClose?: () => void;
}

const SubmittedFilesList: React.FC<SubmittedFilesListProps> = ({
  files,
  size,
  onClose,
}) => {
  return (
    <Flex
      {...(size === "sm"
        ? {
            direction: "row",
            alignItems: "center",
            justifyContent: "center",
          }
        : {
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
            w: "100%",
          })}
    >
      {size === "sm" || files.length > 1 ? (
        <Text>{files.length} files selected</Text>
      ) : null}
      {size !== "sm" || files.length === 1
        ? files.map((file, index) => (
            <FileThumbnail
              key={index}
              size={size}
              fileUrl={file.url}
              fileName={file.name}
              fileType={file.type}
            />
          ))
        : null}
      {typeof onClose === "undefined" ? null : (
        <CloseButton
          onClick={onClose}
          size="lg"
          position="absolute"
          top={0}
          right={0}
          m={2}
        />
      )}
    </Flex>
  );
};

export default SubmittedFilesList;
