import {
  CheckboxGroup,
  Fieldset,
  Icon,
  Input,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import { Checkbox } from "components/ui/checkbox";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { InputGroup } from "components/ui/input-group";
import { Tooltip } from "components/ui/tooltip";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { TemplateCategory } from "entities/domain/templates/full_template";
import React, { memo, useEffect, useState } from "react";
import { LuInfo } from "react-icons/lu";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface SelectCategoryOption {
  value: TemplateCategory;
  label: string;
}

interface TemplateSettingsProps {
  isDisabled: boolean;
  templateTitle: string;
  setTemplateTitle: (title: string) => void;
  templateShortcut: string | null;
  setTemplateShortcut: (shortcut: string | null) => void;
  templateChannels: string[];
  setTemplateChannels: (channels: string[]) => void;
  templateSubject: string | null;
  setTemplateSubject: (subject: string | null) => void;
  templateCategory: TemplateCategory | null;
  setTemplateCategory: (category: TemplateCategory | null) => void;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const TemplateSettings = ({
  isDisabled,
  templateTitle,
  setTemplateTitle,
  templateShortcut,
  setTemplateShortcut,
  templateChannels,
  setTemplateChannels,
  templateSubject,
  setTemplateSubject,
  templateCategory,
  setTemplateCategory,
}: TemplateSettingsProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const categoryOptions: SelectCategoryOption[] = Object.values(
    TemplateCategory
  ).map((category: TemplateCategory) => ({
    label: capitalizeFirstLetter(category),
    value: category,
  }));

  const [selectedCategoryValue, setSelectedCategoryValue] = useState<
    SingleValue<SelectCategoryOption>
  >(categoryOptions.find(({ value }) => value === templateCategory) || null);

  const handleCategoryChange = (
    selectedOption: SingleValue<SelectCategoryOption>
  ) => {
    if (!selectedOption) {
      return;
    }

    setSelectedCategoryValue(selectedOption);
    setTemplateCategory(selectedOption.value);
  };

  useEffect(() => {
    setSelectedCategoryValue(
      categoryOptions.find(({ value }) => value === templateCategory) || null
    );
  }, [templateCategory]);

  return (
    <>
      <Field label="Title">
        <Input
          id="template-title-input"
          colorPalette={colorScheme}
          value={templateTitle}
          onChange={(e) => setTemplateTitle(e.target.value)}
          disabled={isDisabled}
        />
      </Field>
      <Field
        label={
          <Text>
            Shortcut{" "}
            <Tooltip content="Send templates by inserting / followed by your shortcut e.g. “/shortcut">
              <Icon
                as={LuInfo}
                ml={1}
                color={colorMode === "dark" ? "gray.200" : "gray.500"}
              />
            </Tooltip>
          </Text>
        }
      >
        <InputGroup startElement="/" w="100%">
          <Input
            id="template-shortcut-input"
            colorPalette={colorScheme}
            value={templateShortcut || ""}
            onChange={(e) => setTemplateShortcut(e.target.value)}
            disabled={isDisabled}
          />
        </InputGroup>
      </Field>
      <Fieldset.Root>
        <CheckboxGroup
          defaultValue={templateChannels}
          onValueChange={(values) => {
            setTemplateChannels(values);
          }}
          name="Channels"
        >
          <Fieldset.Legend fontSize="sm" mb="2">
            Channels
          </Fieldset.Legend>
          <Fieldset.Content ml={4}>
            {[...new Set(merchant?.channels)]
              .filter(
                (chan) => chan.channelName !== ConversationChannel.WHATSAPP
              )
              .map((chan) => {
                return (
                  <Tooltip
                    key={chan.id}
                    showArrow
                    content="Templates used with WhatsApp must be submitted to WhatsApp for approval. WhatsApp usually approve these within 48 hours, after which it will become available for use."
                    disabled={isBaseSize || chan.channelName !== "whatsapp"}
                  >
                    <Checkbox
                      key={chan.id}
                      id={`template-channel-${chan.channelName}`}
                      size="sm"
                      disabled={isDisabled}
                      value={chan.channelName}
                    >
                      {chan.getDisplayChannelName()}
                    </Checkbox>
                  </Tooltip>
                );
              })}
          </Fieldset.Content>
        </CheckboxGroup>
      </Fieldset.Root>

      {templateChannels.includes("email") && (
        <Field label="Email Subject">
          <Input
            colorPalette={colorScheme}
            disabled={isDisabled}
            value={templateSubject || ""}
            onChange={(e) => setTemplateSubject(e.target.value)}
          />
        </Field>
      )}
      <Field label="Choose category">
        <Select
          id="template-category-select"
          placeholder="Select category"
          isDisabled={isDisabled}
          onChange={handleCategoryChange}
          isClearable={false}
          options={categoryOptions}
          value={selectedCategoryValue}
          styles={{
            ...{
              ...getReactSelectStyles(colorMode || "light", colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: isBaseSize ? "100%" : "100%",
              }),
            },
          }}
        />
      </Field>
    </>
  );
};

export default memo(TemplateSettings);
