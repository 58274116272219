import { Box, HStack } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import ReplySuggestionDomain from "entities/domain/reply_suggestion";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { setAutoReplySuggestion } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const GeneratedReplySuggestion = ({
  body,
  editButton,
  deleteButton,
}: {
  body: string | undefined;
  editButton: React.ReactNode;
  deleteButton: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { autoReplySuggestion: loadedReplySuggestion } = useAppSelector(
    (state) => state.messages
  );
  const availableCustomFields = useAvailableCustomFields(null);
  const [localCustomFields, setLocalCustomFields] = useState<{
    [key: string]: string;
  }>(availableCustomFields);
  const editorText = useMemo(() => {
    return { value: body || "" };
  }, [
    body,
    localCustomFields, // This is a dependency because the editor text should be updated when the custom fields are updated
  ]);
  const channels = useMemo(
    () => (activeConversation ? [activeConversation.channel] : undefined),
    [activeConversation]
  );
  const enabledPlugins = useMemo(() => {
    if (channels && channels.includes(ConversationChannel.WHATSAPP)) {
      return [EditorUltraPlugin.RICHTEXT];
    }

    return [];
  }, [channels]);

  const addOrReplaceCustomField = useCallback((key: string, value: string) => {
    setLocalCustomFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (!loadedReplySuggestion) {
      return;
    }
    const newLoadedReplySuggestion = Object.setPrototypeOf(
      { ...loadedReplySuggestion },
      ReplySuggestionDomain.prototype
    );
    newLoadedReplySuggestion.setCustomFields(localCustomFields);

    dispatch(setAutoReplySuggestion(newLoadedReplySuggestion));
  }, [localCustomFields]);

  return (
    <>
      <Box pl={2}>
        <EditorUltra
          isPrivate={true}
          key={activeConversation?.id}
          defaultText={editorText}
          isEditable={false}
          isScrollable={false}
          placeholder=""
          isDisabled={false}
          customFields={localCustomFields}
          addOrReplaceCustomField={addOrReplaceCustomField}
          enabledPlugins={enabledPlugins}
          channels={channels}
        />
      </Box>
      <HStack>
        {editButton}
        {deleteButton}
      </HStack>
    </>
  );
};

export default GeneratedReplySuggestion;
