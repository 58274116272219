import {
  Box,
  Flex,
  Grid,
  Heading,
  Icon,
  IconButton,
  Portal,
  Text,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import {
  PopoverArrow,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import PaymentDomain from "entities/domain/payments/payment-domain";
import usePaymentsStore from "hooks/use-payments-store";
import React, { ReactNode } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { LuChevronLeft } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import MoreActions from "../payment-actions-modal";

interface MobileDetailsProps {
  onClick: () => void;
  statusColor: string;
  paymentMethod: ReactNode | undefined;
  payment: PaymentDomain;
}

const PaymentMobileDetails = ({
  onClick,
  statusColor,
  paymentMethod,
  payment,
}: MobileDetailsProps) => {
  const { displayPaymentGroup } = usePaymentsStore();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Flex padding="1rem 0" fontWeight="semibold" alignItems="center">
        {" "}
        <LuChevronLeft
          style={{
            color: "lightslategray",
            width: "1.5rem",
            height: "1.5rem",
          }}
          onClick={onClick}
        />
        <ProfileAvatar
          profilePicture=""
          name={payment.customerName}
          showInitials={false}
        />
        <Heading as="h3" size="sm" color={`${colorScheme}.600`} flexGrow={1}>
          {payment.customerName}
        </Heading>{" "}
        {payment.getAvailablePaymentActions().length !== 0 && (
          <PopoverRoot>
            <PopoverTrigger>
              <IconButton
                aria-label="More payment actions"
                mr={3}
                colorPalette={colorScheme}
              >
                <Icon
                  as={FiMoreHorizontal}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin="0 auto"
                />
              </IconButton>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <Box mb="1rem" borderRadius="10px" ml="17px" mr="13px">
                  <MoreActions />
                </Box>
              </PopoverContent>
            </Portal>
          </PopoverRoot>
        )}
      </Flex>
      <Grid
        ml="17px"
        mr="13px"
        borderRadius="15px"
        gridTemplateColumns="1fr 2fr"
        padding="32px 19px"
      >
        <Text pt="12px">Status</Text>
        <Heading
          textTransform="capitalize"
          pt="12px"
          size="sm"
          fontWeight="semibold"
          color={statusColor}
        >
          {displayPaymentGroup(payment)}
        </Heading>

        <Text pt="12px">Amount</Text>
        <Text pt="12px" color="gray.150">
          {payment.getDisplayAmt()}
        </Text>
        <Text pt="12px">Due</Text>
        <Text pt="12px" color="gray.150">
          {payment.getDateDescription()}
        </Text>
        <Text pt="12px">Method</Text>
        <Box pt="12px">{paymentMethod}</Box>
        <Text pt="12px">Description</Text>
        <Text pt="12px" color="gray.150">
          {payment.description}
        </Text>

        <Text pt="12px">Submitted</Text>
        <Text pt="12px" color="gray.150">
          {payment.getDisplayCreatedDate()}
        </Text>
      </Grid>
    </>
  );
};

export default PaymentMobileDetails;
