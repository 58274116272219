import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Spinner as ChakraSpinner,
  HStack,
  Icon,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Spinner from "components/spinner";
import { OtpDTO } from "entities/dto/AuthorizationDTO";
import React, { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import OtpInput from "react-otp-input";
import AuthorizationService from "services/authorization";
import { AcceptOtpCommand, SendOtpCommand } from "util/authorization";

import { useColorMode } from "components/ui/color-mode";
import {
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
} from "components/ui/drawer";
import { toaster } from "components/ui/toaster";
import { useAppSelector } from "redux/hooks";

interface PasswordChangeConfirmationProps {
  newPassword: string;
  isOpen: boolean;
  onClose: () => void;
}

const PasswordChangeConfirmation = ({
  newPassword,
  isOpen = false,
  onClose,
}: PasswordChangeConfirmationProps) => {
  const auth0 = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [resendCodeEnabled, setResendCodeEnabled] = useState(false);
  const [resendingOtp, setResendingOtp] = useState(true);

  const [otpChannel, setOtpChannel] = useState<"email" | "phone" | null>(null);
  const [otpCode, setOtpCode] = useState<string>("");
  const [otpId, setOtpId] = useState<string | null>(null);
  const [otpStatus, setOtpStatus] = useState<"active" | "accepted" | "invalid">(
    "active"
  );

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const changePassword = async () => {
    setSubmitting(true);
    const acceptOtpCommand = {
      referenceId: currentAgent!.id.toString(),
      flow: "password_change",
      code: otpCode,
    } as AcceptOtpCommand;

    let result = {} as ["success", OtpDTO] | ["error", string];
    if (otpStatus !== "accepted" && otpChannel === "email") {
      result = await AuthorizationService.acceptOtp(auth0, {
        ...acceptOtpCommand,
        channel: "email",
        handle: currentAgent!.email,
      });
    } else if (
      otpStatus !== "accepted" &&
      currentAgent!.notificationConfig?.handle
    ) {
      result = await AuthorizationService.acceptOtp(auth0, {
        ...acceptOtpCommand,
        channel: "sms",
        handle: currentAgent!.notificationConfig?.handle,
      });
    }

    if (result[0] === "error") {
      setOtpStatus("invalid");
      toaster.create({
        type: "error",
        title: "Invalid OTP code, please try again.",
      });
    } else {
      setOtpStatus("accepted");
      const changePasswordResult = await AuthorizationService.changePassword(
        auth0,
        { otpId: otpId!, newPassword }
      );
      if (changePasswordResult[0] === "error") {
        toaster.create({
          type: "error",
          title: "Could not change password. Please try again.",
        });
      } else {
        toaster.create({
          type: "success",
          title: "Password changed successfully.",
        });
        onClose();
      }
    }
    setSubmitting(false);
  };

  const sendOtp = async () => {
    setResendCodeEnabled(false);
    setResendingOtp(true);

    const sendOtpCommand = {
      referenceId: currentAgent!.id.toString(),
      flow: "password_change",
    } as SendOtpCommand;

    let result = {} as ["success", OtpDTO] | ["error", string];
    if (currentAgent!.email) {
      setOtpChannel("email");
      result = await AuthorizationService.sendOtp(auth0, {
        ...sendOtpCommand,
        channel: "email",
        handle: currentAgent!.email,
      });
    } else if (currentAgent!.notificationConfig?.handle) {
      setOtpChannel("phone");
      result = await AuthorizationService.sendOtp(auth0, {
        ...sendOtpCommand,
        channel: "sms",
        handle: currentAgent!.notificationConfig?.handle,
      });
    }

    if (result && result[0] === "error") {
      setOtpStatus("invalid");
      setResendCodeEnabled(true);
    } else if (result && result[0] === "success") {
      setOtpStatus("active");
      setOtpId(result[1].id);
    }

    setResendingOtp(false);
  };

  const initialisePasswordConfirmation = async () => {
    await sendOtp();

    setLoading(false);
  };

  useEffect(() => {
    if (!isOpen || resendCodeEnabled) return;

    const timeout = setTimeout(() => {
      setResendCodeEnabled(true);
    }, 30000);

    // eslint-disable-next-line consistent-return
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isOpen, resendCodeEnabled]);

  useEffect(() => {
    if (!isOpen) return;

    setLoading(true);
    setOtpCode("");
    initialisePasswordConfirmation();
  }, [isOpen]);

  return (
    <DrawerRoot
      open={isOpen}
      placement="bottom"
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
    >
      <DrawerBackdrop />
      <DrawerContent
        borderBottomRadius="0 !important"
        maxW="560px !important"
        mx="auto"
      >
        <DrawerCloseTrigger />
        <DrawerHeader>Confirm your identity</DrawerHeader>
        {loading && (
          <Box minH="160px" display="flex" alignItems="center">
            <Spinner />
          </Box>
        )}
        {!loading && (
          <>
            <DrawerBody>
              <VStack gap={4} display="flex">
                <Text> Please provide the code sent to your {otpChannel} </Text>
                <OtpInput
                  isInputNum
                  value={otpCode}
                  onChange={setOtpCode}
                  numInputs={6}
                  hasErrored={otpStatus === "invalid"}
                  shouldAutoFocus={true}
                  errorStyle={{
                    borderColor: colorMode === "dark" ? "lightcoral" : "red",
                  }}
                  containerStyle={{
                    gap: "1rem",
                  }}
                  inputStyle={{
                    outline: "none",
                    border: "1px solid",
                    borderColor:
                      colorMode === "dark" ? "deepskyblue" : "steelblue",
                    borderRadius: "0.25rem",
                    width: "2rem",
                    height: "3rem",
                  }}
                />
              </VStack>
            </DrawerBody>
            <DrawerFooter>
              <HStack gap={2}>
                <Button
                  colorPalette={colorScheme}
                  variant="ghost"
                  onClick={resendCodeEnabled ? () => sendOtp() : () => {}}
                  cursor={resendCodeEnabled ? "pointer" : "default"}
                  opacity={resendCodeEnabled ? 1 : 0}
                  transition="opacity 3s ease-in-out"
                >
                  <HStack w={isBaseSize ? "105px" : "220px"} justify="center">
                    <Text>
                      {isBaseSize
                        ? "Resend Code"
                        : "Haven't received the code?"}
                    </Text>
                    {resendingOtp && (
                      <ChakraSpinner
                        position="absolute"
                        right="10px"
                        width="10px"
                        height="10px"
                      />
                    )}
                    {!resendingOtp && !resendCodeEnabled && (
                      <Icon as={FiCheck} position="absolute" right="10px" />
                    )}
                  </HStack>
                </Button>
                <Button
                  colorPalette={colorScheme}
                  variant="outline"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorPalette={colorScheme}
                  onClick={changePassword}
                  loading={submitting}
                >
                  Save
                </Button>
              </HStack>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </DrawerRoot>
  );
};

export default PasswordChangeConfirmation;
