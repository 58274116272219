import { Flex, Icon, IconButton } from "@chakra-ui/react";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import { useColorMode } from "components/ui/color-mode";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";
import { LuArrowDownAZ, LuArrowDownZA } from "react-icons/lu";
import { CampaignCategory } from "redux/features/campaigns";
import { useAppSelector } from "redux/hooks";

interface FilterSortingAreaProps {
  direction: string;
  searchText: string;
  setDirection: (newDir: string) => void;
  setSearchText: (newText: string) => void;
}

const FilterSortingArea = ({
  direction,
  searchText,
  setDirection,
  setSearchText,
}: FilterSortingAreaProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { activeCampaignCategory } = useAppSelector((state) => state.campaigns);

  const [localSearchText, setLocalSearchText] = useState<string>(
    searchText || ""
  );

  const debouncedSearchText = useDebounce<string>(localSearchText, 300);

  useEffect(() => {
    if (searchText === debouncedSearchText) {
      return;
    }
    setSearchText(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <Flex flexGrow={1} justifyContent="start" alignItems="center" gridGap={1}>
      <ReviewsSearch
        placeholder="Search by name"
        value={localSearchText}
        onChange={(e) => setLocalSearchText(e.target.value)}
      />
      {activeCampaignCategory === CampaignCategory.Done && (
        <IconButton
          aria-label="Sort"
          variant="ghost"
          onClick={() => {
            const newDirection = direction === "desc" ? "asc" : "desc";

            setDirection(newDirection);
          }}
        >
          <Icon
            as={direction === "asc" ? LuArrowDownAZ : LuArrowDownZA}
            width="1.5rem"
            height="1.5rem"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
        </IconButton>
      )}
    </Flex>
  );
};

export default FilterSortingArea;
