import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $isParagraphNode,
} from "lexical";
import { useEffect } from "react";

import {
  $convertFromMarkdownString,
  ElementTransformer,
  TRANSFORMERS,
} from "@lexical/markdown";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { WHATSAPP_MARKDOWN_TRANSFORMERS } from "./whatsapp-markdown-transformers";

export const LINE_BREAK_FIX: ElementTransformer = {
  dependencies: [],
  export: (_node) => {
    return null;
  },
  regExp: /^$/,
  replace: (parentNode, childNodes, _, isImport) => {
    if (isImport && childNodes.length === 1) {
      if ($isParagraphNode(parentNode)) {
        childNodes[0].replace($createParagraphNode());
        return;
      } else {
        parentNode.replace($createLineBreakNode());
      }
    }
  },
  type: "element",
};

export default function DefaultTextPlugin({
  defaultText,
  channels,
  isRichText = false,
}: {
  defaultText?: {
    value: string;
  };
  channels: ConversationChannel[];
  isRichText?: boolean;
}) {
  if (!defaultText) {
    return null;
  }

  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    if (!defaultText || typeof defaultText.value !== "string") {
      return;
    }

    lexicalEditor.update(() => {
      if (isRichText && defaultText.value) {
        const root = $getRoot();

        root.clear();

        const p = $createParagraphNode();
        const textNode = $createTextNode("");

        p.append(textNode);
        root.append(p);

        if (channels.includes(ConversationChannel.WHATSAPP)) {
          $convertFromMarkdownString(
            defaultText.value,
            WHATSAPP_MARKDOWN_TRANSFORMERS,
            undefined,
            true
          );
        } else {
          $convertFromMarkdownString(defaultText.value, [
            ...TRANSFORMERS,
            LINE_BREAK_FIX,
          ]);
        }
        return;
      }

      const root = $getRoot();

      if (!root.getTextContent().trim() && !defaultText.value) {
        return;
      }

      root.clear();

      const p = $createParagraphNode();
      const textNode = $createTextNode(defaultText.value);

      p.append(textNode);
      root.append(p);
      p.selectEnd();
    });
  }, [lexicalEditor, defaultText, isRichText]);

  return null;
}
