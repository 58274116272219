import { Box, Link } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface FooterNavLinkProps {
  children: ReactNode;
  hasNotifications?: boolean;
  to: string;
}

const FooterNavLink = ({
  children,
  to,
  hasNotifications = false,
}: FooterNavLinkProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <Link
      asChild={true}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      _currentPage={{
        "& svg": {
          color: `${colorScheme}.400`,
        },
      }}
    >
      <NavLink to={to}>
        {children}
        {hasNotifications ? (
          <Box
            position="absolute"
            top="-0.25rem"
            right="-0.25rem"
            zIndex="1"
            borderRadius="full"
            height="1rem"
            width="1rem"
            bgColor={colorMode === "dark" ? "red.200" : "red.500"}
          />
        ) : null}
      </NavLink>
    </Link>
  );
};

export default FooterNavLink;
