import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import ReviewDomain, {
  ReviewStatus,
} from "entities/domain/reviews/review-domain";

import React, { useEffect, useState } from "react";
import { LuSend } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import ReviewsService from "services/reviews";
import { isMobileApp } from "util/methods";

interface SendReplyProps {
  review: ReviewDomain;
  sendReply: (content: string, reviewId: string) => void;
}

const SendReply = ({ review, sendReply }: SendReplyProps) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [isLoadingReply, setIsLoadingReply] = useState<boolean>(false);
  const [text, setText] = useState("");

  useEffect(() => {
    if (review.status === ReviewStatus.PENDING || !review.content) {
      return;
    }

    setIsLoadingReply(true);

    ReviewsService.getReplySuggestion(auth0Context, merchant.id, review.id)
      .then((replySuggestion) => {
        setText(replySuggestion);
      })
      .finally(() => {
        setIsLoadingReply(false);
      });
  }, []);

  return (
    <>
      <Box
        alignSelf="end"
        position="relative"
        m={0}
        p={0}
        w="100%"
        mb={isMobileApp() ? 12 : 0}
      >
        <Textarea
          w="100%"
          pr={isLoadingReply ? "4.5rem" : 0}
          colorPalette={colorScheme}
          placeholder={`Respond to ${review.reviewer.name || "Anonymous"}`}
          onChange={(e) => setText(e.target.value)}
          value={text}
          disabled={isLoadingReply}
        />
        {isLoadingReply ? (
          <Flex
            w="100%"
            h="100%"
            position="absolute"
            left={0}
            top={0}
            justifyContent="center"
            alignItems="center"
          >
            <Spinner />
          </Flex>
        ) : null}
      </Box>
      <IconButton
        alignSelf="end"
        aria-label="Send reply"
        disabled={!text}
        rounded="full"
        onClick={() => {
          if (!text) return;

          sendReply(text, review.id);
          setText("");
        }}
        ml={2}
        mb={isMobileApp() ? 12 : 0}
      >
        <Icon as={LuSend} color="white" />
      </IconButton>
    </>
  );
};

export default SendReply;
