import { Icon, IconButton, Input, Spinner } from "@chakra-ui/react";

import { useColorMode } from "components/ui/color-mode";
import { InputGroup } from "components/ui/input-group";
import useAnalytics from "hooks/use-analytics";
import useDebounce from "hooks/use-debounce";
import React, { ChangeEvent, useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { ReactSVG } from "react-svg";
import { setSearchText } from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface SearchBarProps {
  showFilterArea?: boolean;
  setShowFilterArea?: (val: boolean) => void;
}
const SearchBar = ({ setShowFilterArea, showFilterArea }: SearchBarProps) => {
  const dispatch = useAppDispatch();
  const { activeConversationId, loading } = useAppSelector(
    (state) => state.conversations
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { track } = useAnalytics();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [localSearchText, setLocalSearchText] = useState("");

  const debouncedSearchText = useDebounce<string>(localSearchText, 1000);

  useEffect(() => {
    dispatch(setSearchText(debouncedSearchText));
  }, [debouncedSearchText]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLocalSearchText(e.target.value);

  return (
    <>
      <InputGroup
        w="100%"
        startElement={
          loading ? (
            <Spinner size="sm" color="gray.300" />
          ) : (
            <Icon
              as={LuSearch}
              color={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          )
        }
        endElement={
          <IconButton
            aria-label="filter conversations"
            variant="ghost"
            size="xs"
            rounded="full"
            onClick={() => {
              if (setShowFilterArea) setShowFilterArea(!showFilterArea);
            }}
          >
            <Icon
              asChild={true}
              _hover={{ fill: "lightslategray" }}
              css={
                showFilterArea
                  ? {
                      "& path": {
                        fill: `${colorScheme}.400`,
                      },
                    }
                  : {
                      "& path": {
                        fill: "#A0AEC0",
                      },
                    }
              }
            >
              <ReactSVG src="/filter-light.svg" />
            </Icon>
          </IconButton>
        }
      >
        <Input
          colorPalette={colorScheme}
          id="search-bar"
          placeholder={`Search ${merchant.name}`}
          onClick={() => {
            track("conversations_search_bar_focused", {
              conversation_id: activeConversationId || null,
            });
          }}
          onChange={handleSearchChange}
        />
      </InputGroup>
    </>
  );
};

export default SearchBar;
