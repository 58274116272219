import BookingsOutcomesCountDomain from "entities/domain/calendar/bookings-analytics";
import { BookingsOutcomesCountDTO } from "entities/dto/BookingsOutcomesCountDTO";

const bookingsOutcomesCountTransformFromDtoToDomain = (
  b: BookingsOutcomesCountDTO
): BookingsOutcomesCountDomain =>
  new BookingsOutcomesCountDomain(b.merchant_id, b.date_from, b.date_to, {
    noOutcome: b.outcomes.no_outcome,
    notShowed: {
      contacted: b.outcomes.not_showed.contacted,
      needsToBeContacted: b.outcomes.not_showed.needs_to_be_contacted,
      rescheduled: b.outcomes.not_showed.rescheduled,
    },
    showed: {
      converted: b.outcomes.showed.converted,
      notConverted: b.outcomes.showed.not_converted,
    },
  });

export default bookingsOutcomesCountTransformFromDtoToDomain;
