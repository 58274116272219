import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset } from "@chakra-ui/react";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, { useState } from "react";
import AuthorizationService from "services/authorization";
import SelectMerchant from "../select-merchant";

const ResyncPermissions = () => {
  const auth0 = useAuth0();

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const resync = async () => {
    try {
      if (selectedMerchant?.id) {
        await AuthorizationService.resync(auth0, selectedMerchant.id);
        toaster.create({
          type: "success",
          title: "Resync triggered successfully.",
        });
      }
    } catch (err) {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    }
  };

  const handleResync = () => {
    resync();
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content>
        <Field label="Select Merchant" required>
          <SelectMerchant
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
          />
        </Field>
      </Fieldset.Content>
      <Button
        disabled={!selectedMerchant}
        mt={30}
        mb={30}
        size="lg"
        onClick={handleResync}
      >
        Resync
      </Button>
    </Fieldset.Root>
  );
};

export default ResyncPermissions;
