import NewAgentDomain, {
  AgentMerchantDomain,
  AgentMerchantRoleDomain,
} from "entities/domain/agents/new-agent-domain";
import AgentNotificationConfigDomain from "entities/domain/agents/notification-config-domain";
import {
  AgentMerchantDTO,
  AgentMerchantRoleDTO,
  AgentNotificationConfigDTO,
  NewAgentDTO,
} from "entities/dto/NewAgentDTO";

export const transformNotificationConfigfromDtoToDomain = (
  d: AgentNotificationConfigDTO
): AgentNotificationConfigDomain =>
  new AgentNotificationConfigDomain(
    d.handle,
    d.channel,
    d.preference,
    d.strategy,
    d.timezone
  );

export const agentRolesTransformFromDtoToDomain = (
  role: AgentMerchantRoleDTO
): AgentMerchantRoleDomain =>
  new AgentMerchantRoleDomain(role.id, role.name, role.permissions);

export const newAgentTransformFromDtoToDomain = (
  agent: NewAgentDTO,
  color: string
): NewAgentDomain =>
  new NewAgentDomain(
    agent.id,
    agent.name,
    agent.surname,
    agent.email,
    agent.phone,
    agent.contact_channel,
    agent.notification_config
      ? transformNotificationConfigfromDtoToDomain(agent.notification_config)
      : null,
    agent.merchants.map((merchant: AgentMerchantDTO): AgentMerchantDomain => {
      return new AgentMerchantDomain(
        merchant.id,
        merchant.name,
        merchant.logo,
        agentRolesTransformFromDtoToDomain(merchant.role)
      );
    }),
    agent.user_id,
    agent.profile_picture,
    agent.active,
    color,
    agent.player_ids,
    agent.style_preferences
      ? {
          chatBackground: agent.style_preferences.chat_background,
          colorScheme: agent.style_preferences.color_scheme,
        }
      : null
  );
