import { Input, useDisclosure } from "@chakra-ui/react";
import FileDomain from "entities/domain/file";
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import DefaultBrowseButton from "./DefaultBrowseButton";
import DragAndDropArea from "./DragAndDropArea";
import SubmitFile from "./SubmitFile";
import SubmittedFilesList from "./SubmittedFilesList";

interface SmartFileUploaderProps {
  size?: "sm" | "md" | "lg";
  allowDragAndDrop?: boolean;
  allowMultiple?: boolean;
  accept?: string;
  isDisabled?: boolean;
  files?: File[];
  uploadedFiles?: FileDomain[];
  useModalForSubmitting?: boolean;
  isDragAndDropAreaSelected?: boolean;
  dragAndDropAreaSelect?: () => void;
  submitButtonText?: string;
  onClose?: () => void;
  onSubmit?: (files: File[]) => Promise<void>;
  onFileChange?: (files: File[]) => void;
  browseButton?: React.ReactNode;
}

const shouldShowBrowseButton = (
  allowDragAndDrop: boolean,
  filesToUploadLength: number,
  uploadedFilesLength: number
) => {
  const filesWereDropped = filesToUploadLength > 0;
  const filesWereUploaded = uploadedFilesLength > 0;
  const shouldHideBrowseButton =
    allowDragAndDrop && (filesWereDropped || filesWereUploaded);

  return !shouldHideBrowseButton;
};

const SmartFileUploader = ({
  size = "sm",
  allowDragAndDrop = true,
  allowMultiple = false,
  accept = "video/mp4, image/*, application/pdf, audio/*",
  isDisabled = false,
  files,
  isDragAndDropAreaSelected = false,
  dragAndDropAreaSelect,
  uploadedFiles,
  submitButtonText,
  useModalForSubmitting = false,
  onClose,
  onSubmit,
  onFileChange,
  browseButton,
}: SmartFileUploaderProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const inputRef = useRef<any>(null);

  const [localFiles, setLocalFiles] = useState<File[]>(files || []);
  const submitModal = useDisclosure();

  const onSubmitModal = useCallback(
    async (filesToSubmit: File[]) => {
      if (onSubmit === undefined) {
        return;
      }

      await onSubmit(filesToSubmit);

      setLocalFiles([]);

      submitModal.onClose();
    },
    [onSubmit]
  );

  useEffect(() => {
    setLocalFiles(files || []);
  }, [files]);

  useEffect(() => {
    onFileChange?.(localFiles);

    // we need to clear the input value so that the same file(s) can be uploaded again
    if (inputRef?.current) {
      inputRef.current.value = "";
      inputRef.current.files = null;
    }
  }, [localFiles]);

  useEffect(() => {
    if (onSubmit === undefined) {
      return;
    }

    if (localFiles.length && !submitModal.open) {
      submitModal.onOpen();
    }
  }, [localFiles, onSubmit]);

  const chooseFiles = useCallback(() => {
    if (isDisabled) {
      return;
    }

    inputRef.current.click();
  }, [inputRef, isDisabled]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    if (!e.target.files || !e.target.files.length) {
      setLocalFiles([]);

      return;
    }

    const newFiles = Array.from(e.target.files);

    setLocalFiles(newFiles);
  }, []);

  const chooseFilesButton = useMemo(() => {
    return browseButton ? (
      React.cloneElement(browseButton as React.ReactElement, {
        onClick: allowDragAndDrop ? undefined : chooseFiles,
      })
    ) : (
      <DefaultBrowseButton
        size={size}
        onClick={allowDragAndDrop ? undefined : chooseFiles}
      />
    );
  }, [browseButton, chooseFiles]);

  const [shouldShowChooseFilesButton, setShouldShowChooseFilesButton] =
    useState(
      shouldShowBrowseButton(
        allowDragAndDrop,
        localFiles.length,
        uploadedFiles?.length || 0
      )
    );

  useEffect(() => {
    setShouldShowChooseFilesButton(
      shouldShowBrowseButton(
        allowDragAndDrop,
        localFiles.length,
        uploadedFiles?.length || 0
      )
    );
  }, [allowDragAndDrop, localFiles.length, uploadedFiles?.length]);

  return (
    <DragAndDropArea
      size={size}
      isEnabled={allowDragAndDrop}
      hasFiles={localFiles.length > 0}
      isSelected={isDragAndDropAreaSelected}
      select={dragAndDropAreaSelect}
      uploadedFiles={uploadedFiles || []}
      onFileChange={setLocalFiles}
      onClick={uploadedFiles?.length ? () => {} : chooseFiles}
    >
      {uploadedFiles?.length ? (
        <SubmittedFilesList
          files={uploadedFiles}
          size={size}
          onClose={onClose}
        />
      ) : (
        <SubmitFile
          submitButtonText={submitButtonText}
          disclosureProps={!useModalForSubmitting ? undefined : submitModal}
          size={size}
          files={localFiles}
          setFiles={setLocalFiles}
          onSubmit={onSubmitModal}
        />
      )}
      {shouldShowChooseFilesButton ? chooseFilesButton : null}
      <Input
        colorPalette={colorScheme}
        display="none"
        ref={inputRef}
        type="file"
        multiple={allowMultiple}
        onChange={onChange}
        disabled={isDisabled}
        accept={accept}
      />
    </DragAndDropArea>
  );
};

export default SmartFileUploader;
