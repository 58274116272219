import {
  Box,
  Button,
  Card,
  Fieldset,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import DatePickerWithSuspense from "components/shared/LazyDatePicker";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface ExportContactsModalProps {
  isOpen: boolean;
  isExportingCsv: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleExportAll: (from?: Date | null, to?: Date | null) => void;
  handleExportUnsub: (from?: Date | null, to?: Date | null) => void;
}

enum EXPORT_TYPE {
  ALL = "all",
  UNSUB = "unsub",
}

const ExportContactsModal: React.FC<ExportContactsModalProps> = ({
  isOpen,
  isExportingCsv,
  setIsOpen,
  handleExportAll,
  handleExportUnsub,
}) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [exportType, setExportType] = React.useState<EXPORT_TYPE | undefined>();
  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);

  const exportContacts = () => {
    switch (exportType) {
      case EXPORT_TYPE.ALL:
        handleExportAll(fromDate, toDate);
        break;
      case EXPORT_TYPE.UNSUB:
        handleExportUnsub(fromDate, toDate);
        break;
      default:
        break;
    }
  };

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => setIsOpen(newIsOpen)}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>Export Contacts</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody
          display="flex"
          gridGap={4}
          flexDirection="column"
          position="relative"
        >
          <Flex
            display="flex"
            gridGap={4}
            justifyContent="center"
            alignItems="center"
          >
            <Card.Root
              w="100%"
              onClick={() => {
                if (exportType === EXPORT_TYPE.ALL) {
                  setExportType(undefined);
                } else {
                  setExportType(EXPORT_TYPE.ALL);
                }
              }}
              {...(exportType === EXPORT_TYPE.ALL && {
                bg: colorMode === "dark" ? "gray.900" : "gray.50",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
                borderWidth: "3px",
                borderStyle: "solid",
                boxShadow: "md",
              })}
              cursor="pointer"
              p={4}
            >
              <Card.Body
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontWeight="bold">All</Text>
              </Card.Body>
            </Card.Root>
            <Card.Root
              w="100%"
              onClick={() => {
                if (exportType === EXPORT_TYPE.UNSUB) {
                  setExportType(undefined);
                } else {
                  setExportType(EXPORT_TYPE.UNSUB);
                }
              }}
              {...(exportType === EXPORT_TYPE.UNSUB && {
                bg: colorMode === "dark" ? "gray.900" : "gray.50",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
                borderWidth: "3px",
                borderStyle: "solid",
                boxShadow: "md",
              })}
              cursor="pointer"
              p={4}
            >
              <Card.Body
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontWeight="bold">Unsubscribed</Text>
              </Card.Body>
            </Card.Root>
          </Flex>
          {exportType && (
            <Fieldset.Root
              justifyContent="center"
              direction="column"
              alignItems="center"
              gridGap={2}
            >
              <Fieldset.Content>
                <Field mt={6} label="From">
                  <Box
                    rounded="full"
                    borderWidth="1px"
                    borderColor={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                    textAlign="left"
                    py={2}
                    px={3}
                    w="100%"
                    backgroundColor="black"
                    css={{
                      "& input": {
                        width: "100%",
                        backgroundColor: "black",
                      },
                      "& .react-datepicker-wrapper": {
                        width: "100%",
                      },
                    }}
                  >
                    <DatePickerWithSuspense
                      id="export-from-date"
                      selected={fromDate}
                      placeholderText="Starting point"
                      onChange={(date: Date | null) => {
                        if (date instanceof Date) {
                          setFromDate(date);
                        }
                      }}
                      startDate={new Date()}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, h:mm aa"
                    />
                  </Box>
                </Field>
                <Field label="To" mt={6}>
                  <Box
                    rounded="full"
                    borderWidth="1px"
                    borderColor={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                    backgroundColor="black"
                    textAlign="left"
                    w="100%"
                    py={2}
                    px={3}
                    css={{
                      "& input": {
                        width: "100%",
                        backgroundColor: "black",
                      },
                      "& .react-datepicker-wrapper": {
                        width: "100%",
                      },
                    }}
                  >
                    <DatePickerWithSuspense
                      id="export-to-date"
                      selected={toDate}
                      placeholderText="Ending point"
                      onChange={(date: Date | null) => {
                        if (date instanceof Date) {
                          setToDate(date);
                        }
                      }}
                      startDate={fromDate || new Date()}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, h:mm aa"
                    />
                  </Box>
                </Field>
              </Fieldset.Content>
            </Fieldset.Root>
          )}
          {isExportingCsv ? (
            <Flex
              position="absolute"
              zIndex={99999}
              top={0}
              left={0}
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              bgColor={
                colorMode === "dark" ? "blackAlpha.900" : "whiteAlpha.900"
              }
            >
              <Spinner />
            </Flex>
          ) : null}
        </DialogBody>
        <DialogFooter
          mt={2}
          display="flex"
          gridGap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="ghost"
            colorPalette={colorScheme}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => exportContacts()}
            colorPalette={colorScheme}
            disabled={isExportingCsv || !exportType}
          >
            Export
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default ExportContactsModal;
