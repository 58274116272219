import {
  defineConfig,
  RecipeDefinition,
  SlotRecipeDefinition,
  SystemConfig,
} from "@chakra-ui/react";
import AccordionStyles from "./components/Accordion";
import ActionBarStyles from "./components/ActionBar";
import ButtonStyles from "./components/Button";
import HeadingStyles from "./components/Heading";
import InputStyles from "./components/Input";
import LinkStyles from "./components/Link";
import MenuStyles from "./components/Menu";
import ModalStyles from "./components/Modal";
import NumberInputStyles from "./components/NumberInput";
import PopoverStyles from "./components/Popover";
import SwitchStyles from "./components/Switch";
import TabsStyles from "./components/Tabs";
import ToolTipStyles from "./components/ToolTip";

const recipes: Record<string, RecipeDefinition> = {
  link: LinkStyles,
  input: InputStyles,
  button: ButtonStyles,
  heading: HeadingStyles,
  tooltip: ToolTipStyles,
};

const slotRecipes: Record<string, SlotRecipeDefinition> = {
  dialog: ModalStyles,
  popover: PopoverStyles,
  numberInput: NumberInputStyles,
  menu: MenuStyles,
  tabs: TabsStyles,
  accordion: AccordionStyles,
  actionBar: ActionBarStyles,
  switch: SwitchStyles,
};

// Used this to generate palettes https://palette.saas-ui.dev/
const FuzeyTheme: SystemConfig = defineConfig({
  theme: {
    recipes,
    slotRecipes,
    tokens: {
      colors: {
        white: {
          value: "#F2F2F2",
        },
        black: {
          value: "#101010",
        },
        blue: {
          50: { value: "#D9E8FF" },
          100: { value: "#C2DAFF" },
          200: { value: "#A7CBFF" },
          300: { value: "#85B4FF" },
          400: { value: "#5893FF" },
          500: { value: "#007df2" },
          600: { value: "#0054a2" },
          700: { value: "#00376a" },
          800: { value: "#00213f" },
          900: { value: "#0a1546" },
          950: { value: "#080F32" },
        },
        gray: {
          50: { value: "#DADCE5" },
          100: { value: "#C5C7D0" },
          200: { value: "#ACADB8" },
          300: { value: "#9393A0" },
          400: { value: "#797B89" },
          500: { value: "#616272" },
          600: { value: "#4B4C58" },
          700: { value: "#363741" },
          800: { value: "#21222A" },
          900: { value: "#16171C" },
          950: { value: "#0F1013" },
        },
        green: {
          50: { value: "#DFF4EB" },
          100: { value: "#BCE8D6" },
          200: { value: "#98DBC1" },
          300: { value: "#75CEAC" },
          400: { value: "#52C097" },
          500: { value: "#3CA87F" },
          600: { value: "#2E8664" },
          700: { value: "#21644B" },
          800: { value: "#154332" },
          900: { value: "#0B261D" },
          950: { value: "#061510" },
        },
        purple: {
          50: { value: "#EFE8FF" },
          100: { value: "#D5C7FD" },
          200: { value: "#BBA7FC" },
          300: { value: "#A287FA" },
          400: { value: "#895FF8" },
          500: { value: "#7139F6" },
          600: { value: "#5B2DC5" },
          700: { value: "#452196" },
          800: { value: "#30156A" },
          900: { value: "#1F0E47" },
          950: { value: "#13092D" },
        },
        pink: {
          50: { value: "#FBE8F0" },
          100: { value: "#F5C5DC" },
          200: { value: "#EF9FC6" },
          300: { value: "#E978AE" },
          400: { value: "#E35197" },
          500: { value: "#D02676" },
          600: { value: "#A81E5E" },
          700: { value: "#82174A" },
          800: { value: "#5C1036" },
          900: { value: "#3D0B24" },
          950: { value: "#240617" },
        },
        orange: {
          50: { value: "#FFEFE0" },
          100: { value: "#FFD4AD" },
          200: { value: "#FFB87A" },
          300: { value: "#FF9B47" },
          400: { value: "#FF7E14" },
          500: { value: "#D36400" },
          600: { value: "#A94F00" },
          700: { value: "#813C00" },
          800: { value: "#5C2A00" },
          900: { value: "#3D1A00" },
          950: { value: "#260F00" },
        },
        yellow: {
          50: { value: "#FFF7DA" },
          100: { value: "#FDEAB0" },
          200: { value: "#FADD85" },
          300: { value: "#F7CF59" },
          400: { value: "#F5C12E" },
          500: { value: "#CFA000" },
          600: { value: "#A28000" },
          700: { value: "#796100" },
          800: { value: "#524200" },
          900: { value: "#382D00" },
          950: { value: "#241C00" },
        },
        teal: {
          50: { value: "#E2F9F8" },
          100: { value: "#A9F1EF" },
          200: { value: "#70E8E5" },
          300: { value: "#37DFDB" },
          400: { value: "#00D5D1" },
          500: { value: "#00B2AE" },
          600: { value: "#008D89" },
          700: { value: "#006865" },
          800: { value: "#004644" },
          900: { value: "#002E2D" },
          950: { value: "#001C1B" },
        },
        cyan: {
          50: { value: "#E4FBFF" },
          100: { value: "#B8F2FE" },
          200: { value: "#8CEAFC" },
          300: { value: "#60E1FB" },
          400: { value: "#34D7FA" },
          500: { value: "#00B7D7" },
          600: { value: "#0092AD" },
          700: { value: "#00718B" },
          800: { value: "#005669" },
          900: { value: "#003B47" },
          950: { value: "#00242C" },
        },
      },
      fonts: {
        heading: { value: "Roboto" },
        body: { value: "Roboto" },
      },
    },
    semanticTokens: {
      colors: {
        bg: {
          DEFAULT: {
            value: { _light: "{colors.white}", _dark: "{colors.gray.700}" },
          },
          subtle: {
            value: { _light: "{colors.gray.50}", _dark: "{colors.gray.700}" },
          },
          muted: {
            value: { _light: "{colors.gray.100}", _dark: "{colors.gray.700}" },
          },
          emphasized: {
            value: { _light: "{colors.gray.200}", _dark: "{colors.gray.700}" },
          },
          inverted: {
            value: { _light: "{colors.gray.700}", _dark: "{colors.white}" },
          },
          panel: {
            value: { _light: "{colors.white}", _dark: "{colors.gray.700}" },
          },
          error: {
            value: { _light: "{colors.red.50}", _dark: "{colors.red.950}" },
          },
          warning: {
            value: {
              _light: "{colors.orange.50}",
              _dark: "{colors.orange.950}",
            },
          },
          success: {
            value: { _light: "{colors.green.50}", _dark: "{colors.green.950}" },
          },
          info: {
            value: { _light: "{colors.blue.50}", _dark: "{colors.blue.950}" },
          },
        },
        blue: {
          solid: {
            value: {
              _light: "{colors.blue.500}",
              _dark: "{colors.blue.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.blue.100}",
              _dark: "{colors.blue.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.blue.700}",
              _dark: "{colors.blue.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.blue.100}",
              value: "{colors.blue.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.blue.200}",
              _dark: "{colors.blue.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.blue.300}",
              _dark: "{colors.blue.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.blue.500}",
              _dark: "{colors.blue.200}",
            },
          },
        },
        gray: {
          solid: {
            value: {
              _light: "{colors.gray.500}",
              _dark: "{colors.gray.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.gray.100}",
              _dark: "{colors.gray.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.gray.700}",
              _dark: "{colors.gray.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.gray.100}",
              _dark: "{colors.gray.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.gray.200}",
              _dark: "{colors.gray.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.gray.300}",
              _dark: "{colors.gray.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.gray.500}",
              _dark: "{colors.gray.200}",
            },
          },
        },
        green: {
          solid: {
            value: {
              _light: "{colors.green.500}",
              _dark: "{colors.green.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.green.100}",
              _dark: "{colors.green.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.green.700}",
              _dark: "{colors.green.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.green.100}",
              _dark: "{colors.green.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.green.200}",
              _dark: "{colors.green.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.green.300}",
              _dark: "{colors.green.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.green.500}",
              _dark: "{colors.green.200}",
            },
          },
        },
        purple: {
          solid: {
            value: {
              _light: "{colors.purple.500}",
              _dark: "{colors.purple.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.purple.100}",
              _dark: "{colors.purple.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.purple.700}",
              _dark: "{colors.purple.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.purple.100}",
              _dark: "{colors.purple.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.purple.200}",
              _dark: "{colors.purple.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.purple.300}",
              _dark: "{colors.purple.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.purple.500}",
              _dark: "{colors.purple.200}",
            },
          },
        },
        pink: {
          solid: {
            value: {
              _light: "{colors.pink.500}",
              _dark: "{colors.pink.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.pink.100}",
              _dark: "{colors.pink.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.pink.700}",
              _dark: "{colors.pink.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.pink.100}",
              _dark: "{colors.pink.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.pink.200}",
              _dark: "{colors.pink.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.pink.300}",
              _dark: "{colors.pink.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.pink.500}",
              _dark: "{colors.pink.200}",
            },
          },
        },
        orange: {
          solid: {
            value: {
              _light: "{colors.orange.500}",
              _dark: "{colors.orange.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.orange.100}",
              _dark: "{colors.orange.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.orange.700}",
              _dark: "{colors.orange.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.orange.100}",
              _dark: "{colors.orange.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.orange.200}",
              _dark: "{colors.orange.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.orange.300}",
              _dark: "{colors.orange.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.orange.500}",
              _dark: "{colors.orange.200}",
            },
          },
        },
        yellow: {
          solid: {
            value: {
              _light: "{colors.yellow.500}",
              _dark: "{colors.yellow.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.yellow.100}",
              _dark: "{colors.yellow.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.yellow.700}",
              _dark: "{colors.yellow.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.yellow.100}",
              _dark: "{colors.yellow.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.yellow.200}",
              _dark: "{colors.yellow.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.yellow.300}",
              _dark: "{colors.yellow.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.yellow.500}",
              _dark: "{colors.yellow.200}",
            },
          },
        },
        teal: {
          solid: {
            value: {
              _light: "{colors.teal.500}",
              _dark: "{colors.teal.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.teal.100}",
              _dark: "{colors.teal.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.teal.700}",
              _dark: "{colors.teal.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.teal.100}",
              _dark: "{colors.teal.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.teal.200}",
              _dark: "{colors.teal.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.teal.300}",
              _dark: "{colors.teal.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.teal.500}",
              _dark: "{colors.teal.200}",
            },
          },
        },
        cyan: {
          solid: {
            value: {
              _light: "{colors.cyan.500}",
              _dark: "{colors.cyan.200}",
            },
          },
          contrast: {
            value: {
              _light: "{colors.cyan.100}",
              _dark: "{colors.cyan.900}",
            },
          },
          fg: {
            value: {
              _light: "{colors.cyan.700}",
              _dark: "{colors.cyan.300}",
            },
          },
          muted: {
            value: {
              _light: "{colors.cyan.100}",
              _dark: "{colors.cyan.800}",
            },
          },
          subtle: {
            value: {
              _light: "{colors.cyan.200}",
              _dark: "{colors.cyan.700}",
            },
          },
          emphasized: {
            value: {
              _light: "{colors.cyan.300}",
              _dark: "{colors.cyan.600}",
            },
          },
          focusRing: {
            value: {
              _light: "{colors.cyan.500}",
              _dark: "{colors.cyan.200}",
            },
          },
        },
      },
    },
  },
});

export default FuzeyTheme;
