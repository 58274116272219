import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  HStack,
  Icon,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import FileDomain from "entities/domain/file";
import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { setFiles } from "redux/features/files";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FilesService from "services/files";
import FileThumbnail from "../SmartFileUploader/FileThumbnail";
import SelectableFile from "./SelectableFile";

interface RecentFilesProps {
  selectedFile: FileDomain | null;
  selectFile: (file: FileDomain | null) => void;
  openSearch: () => void;
}

const getFiveNewestFiles = (files: FileDomain[]): FileDomain[] => {
  return files
    .filter((file) => file.isReusable)
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    })
    .slice(0, 5);
};

const RecentFiles: React.FC<RecentFilesProps> = ({
  selectedFile,
  selectFile,
  openSearch,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { files } = useAppSelector((state) => state.files);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(files.length === 0);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [fiveNewestFiles, setFiveNewestFiles] = useState<FileDomain[]>(
    getFiveNewestFiles(files) || []
  );

  useEffect(() => {
    const newFiveNewestFiles = getFiveNewestFiles(files);

    if (!selectedFile || !selectedFile.isReusable) {
      setFiveNewestFiles(newFiveNewestFiles);
      return;
    }

    const selectedFileIsInFiveNewestFiles = newFiveNewestFiles.some(
      (file) => file.id === selectedFile.id
    );

    if (selectedFileIsInFiveNewestFiles) {
      setFiveNewestFiles(newFiveNewestFiles);
    } else {
      setFiveNewestFiles([selectedFile, ...newFiveNewestFiles.slice(0, 4)]);
    }
  }, [files, selectedFile]);

  const fetchFiles = async () => {
    if (files.length === 0) {
      setShowSkeleton(true);
    }

    try {
      const newFiles = await FilesService.getFiles(
        auth0Context,
        merchant.id,
        undefined,
        0
      );
      dispatch(setFiles(newFiles));
    } catch (error: unknown) {
      // eslint-disable-next-line
      console.error("Error fetching files", error);
    } finally {
      setShowSkeleton(false);
    }
  };

  useEffect(() => {
    return () => {
      setFiveNewestFiles(getFiveNewestFiles(files));
    };
  }, []);

  useEffect(() => {
    fetchFiles();
  }, []);

  return files.length === 0 && showSkeleton ? null : (
    <VStack
      gap={4}
      w="100%"
      borderRadius="lg"
      bgColor={
        colorMode === "dark" ? `${colorScheme}.900` : `${colorScheme}.50`
      }
      p={4}
    >
      <HStack justifyContent="space-between" w="100%">
        <Text fontSize="lg">Saved Files</Text>
        <Button
          variant="ghost"
          onClick={openSearch}
          colorPalette={colorScheme}
        >
          Show All <Icon as={LuSearch} />
        </Button>
      </HStack>
      <HStack
        justifyContent={isBaseSize ? "space-evenly" : "start"}
        gap={6}
        flexWrap="wrap"
      >
        {fiveNewestFiles.map((file) => (
          <SelectableFile
            key={file.id}
            file={file}
            isSelected={selectedFile?.id === file.id}
            select={selectFile}
          >
            <FileThumbnail
              size="md"
              showText={false}
              fileUrl={file.url}
              fileType={file.type}
              fileName={file.name}
            />
          </SelectableFile>
        ))}
      </HStack>
    </VStack>
  );
};

export default RecentFiles;
