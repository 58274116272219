import { Auth0ContextInterface } from "@auth0/auth0-react";
import { AttachmentDto } from "entities/dto/AttachmentDTO";
import { request } from "util/methods";
import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import { attachmentTransformFromDtoToDomain } from "entities/transformers/attachmentTransformer";

import { RequestType } from "./request-type";

export default class AttachmentsService {
  public static async getAttachments(
    { getAccessTokenSilently }: Auth0ContextInterface,
    contactId: number,
    offset: number,
    limit: number,
    merchantId: number
  ): Promise<AttachmentDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const attachmentResponse = await request<AttachmentDto[]>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers/${contactId}/attachments?offset=${offset}&limit=${limit}`
    );

    const attachments = attachmentResponse.data;

    return attachments.map(attachmentTransformFromDtoToDomain);
  }
}
