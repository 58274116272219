import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AccordionItemContent,
  AccordionItemTrigger,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useEffect, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import {
  LuFile,
  LuFileQuestion,
  LuImages,
  LuVideo,
  LuVolume2,
} from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import AttachmentsService from "services/attachments";
import { numberOfAttachmentsPerLoad } from "util/constants";
import { SUPPORTED_MEDIA_TYPES } from "../FilePreview";
import Gallery from "../Gallery";

interface AllAttachmentsProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
  onFirstLoad: (attachments: AttachmentDomain[]) => void;
}

const AllAttachments = ({
  contactToDisplay,
  isExpanded,
  onFirstLoad,
}: AllAttachmentsProps) => {
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [startIndex, setStartIndex] = useState<number>(0);
  const { open, onOpen, onClose } = useDisclosure();

  const [brokenMedia, setBrokenMedia] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<AttachmentDomain[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);

  const auth0Context = useAuth0();

  const hasMoreAttachments = (
    attachmentsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (attachmentsAmount < numberOfAttachmentsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfAttachmentsPerLoad) {
      return false;
    }

    return true;
  };

  const fetchAttachments = async () => {
    setIsLoadingNextPage(true);
    const nextPage = await AttachmentsService.getAttachments(
      auth0Context,
      contactToDisplay.id!,
      attachments.length,
      numberOfAttachmentsPerLoad,
      merchant.id
    );

    if (nextPage.length && !attachments.length) {
      onFirstLoad(nextPage);
    }

    const newAttachments: AttachmentDomain[] = attachments.length
      ? attachments.concat(nextPage)
      : nextPage;

    setAttachments(newAttachments);
    setHasNextPage(hasMoreAttachments(newAttachments.length, nextPage.length));
    setIsLoadingNextPage(false);
  };

  useEffect(() => {
    setBrokenMedia([]);
    fetchAttachments();
  }, []);

  const getIcon = (type: string) => {
    if (type.includes("image")) {
      return LuImages;
    }
    if (type.includes("video")) {
      return LuVideo;
    }
    if (type.includes("audio")) {
      return LuVolume2;
    }
    return LuFile;
  };

  return (
    <>
      <AccordionItemTrigger>
        <HStack>
          <Icon
            as={getIcon(contactToDisplay.type)}
            width="1.25rem"
            height="1.25rem"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ color: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
          <Text fontWeight="bold">File Attachments</Text>
        </HStack>
      </AccordionItemTrigger>
      <AccordionItemContent>
        <Grid
          justifyContent="center"
          alignItems="center"
          templateColumns="repeat(3, minmax(0, 1fr))"
          gap={3}
          overflowX="hidden"
        >
          {attachments
            .slice(0, 9)
            .filter((attachment) => !brokenMedia.includes(attachment.id))
            .map((attachment, index) => (
              <GridItem
                key={attachment.id}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStartIndex(index);
                  onOpen();
                }}
              >
                {attachment.type.includes("image") &&
                  SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                    <Image
                      src={attachment.url}
                      alt={`attachment-${attachment.id}`}
                      boxSize="100%"
                      objectFit="cover"
                      borderRadius="lg"
                      width="5rem"
                      height="5rem"
                      loading="lazy"
                      onError={() => {
                        setBrokenMedia([...brokenMedia, attachment.id]);
                      }}
                    />
                  )}
                {attachment.type.includes("video") &&
                  SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                    <Icon
                      as={getIcon(attachment.type)}
                      width="5rem"
                      height="5rem"
                      borderWidth="1px"
                      borderStyle="solid"
                      borderColor="gray.50"
                      borderRadius="lg"
                      fill={
                        colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.500`
                      }
                    />
                  )}
                {attachment.type.includes("audio") &&
                  SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                    <Icon
                      as={getIcon(attachment.type)}
                      width="5rem"
                      height="5rem"
                      borderWidth="1px"
                      borderStyle="solid"
                      borderColor="gray.50"
                      borderRadius="lg"
                      fill={
                        colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.500`
                      }
                    />
                  )}
                {attachment.type.includes("pdf") &&
                  SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                    <Icon
                      as={FaFilePdf}
                      width="5rem"
                      height="5rem"
                      borderWidth="1px"
                      borderStyle="solid"
                      borderColor="gray.50"
                      borderRadius="lg"
                      color={
                        colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.500`
                      }
                    />
                  )}
                {!SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                  <Icon
                    as={LuFileQuestion}
                    width="5rem"
                    height="5rem"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="gray.50"
                    borderRadius="lg"
                    fill={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                )}
              </GridItem>
            ))}
        </Grid>
        {attachments.length !== 0 && (
          <Button
            mt={2}
            colorPalette={colorScheme}
            w="100%"
            onClick={() => {
              setStartIndex(0);
              onOpen();
            }}
          >
            Show all
          </Button>
        )}
      </AccordionItemContent>
      <Gallery
        isOpen={open}
        onClose={onClose}
        startIndex={startIndex}
        setStartIndex={(index) => setStartIndex(index)}
        media={attachments}
        hasMore={hasNextPage}
        fetchMore={fetchAttachments}
        isLoadingMore={isLoadingNextPage}
      />
    </>
  );
};

export default AllAttachments;
