import { useAuth0 } from "@auth0/auth0-react";
import {
  HStack,
  Text,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { Slider } from "components/ui/slider";
import React, { useEffect, useState } from "react";
import {
  setFilterByYearMax,
  setFilterByYearMin,
} from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";

interface YearFilterProps {}

const MIN_YEAR_REALISTICALLY = 1900;

const YearFilter = (_props: YearFilterProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const {
    filterOptions,
    filterByYearMin,
    filterByYearMax,
    filterByCondition,
    filterByModel,
    filterByMake,
    filterByPriceMax,
    filterByPriceMin,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [rangeSliderValues, setRangeSliderValues] = useState<number[]>([
    filterByYearMin || filterOptions.minYear,
    filterByYearMax || filterOptions.maxYear,
  ]);

  useEffect(() => {
    if (
      !filterByModel &&
      !filterByMake &&
      !filterByPriceMin &&
      !filterByPriceMax &&
      !filterByCondition &&
      !filterByAvailability
    ) {
      setRangeSliderValues([
        filterByYearMin || filterOptions.minYear,
        filterByYearMax || filterOptions.maxYear,
      ]);
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      filterByModel,
      filterByMake,
      null,
      null,
      filterByPriceMin,
      filterByPriceMax,
      filterByCondition,
      filterByAvailability
    ).then((newFilterOptions) => {
      setRangeSliderValues([
        filterByYearMin || newFilterOptions.minYear,
        filterByYearMax || newFilterOptions.maxYear,
      ]);
    });
  }, [
    filterOptions,
    filterByCondition,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByMake,
    filterByAvailability,
  ]);

  return filterOptions.minYear === filterOptions.maxYear ? null : (
    <Field label={
      <HStack justifyContent="space-between">
        <Text>Year</Text>
        <Text>
          {(filterByYearMin || filterOptions.minYear) ===
          (filterByYearMax || filterOptions.maxYear)
            ? filterByYearMin || filterOptions.minYear
            : `${filterByYearMin || filterOptions.minYear} - ${
                filterByYearMax || filterOptions.maxYear
              }`}
        </Text>
      </HStack>
    } textAlign="center">
      <Slider
        w="90%"
        min={filterOptions.minYear}
        max={filterOptions.maxYear}
        disabled={filterOptions.minYear === filterOptions.maxYear}
        value={rangeSliderValues}
        onValueChange={({ value: newRangeSliderValues }) => {
          setRangeSliderValues(newRangeSliderValues);
        }}
        colorPalette={colorScheme}
        onValueChangeEnd={({ value: val }) => {
          const newMinValue = val[0];

          if (newMinValue === filterOptions.minYear) {
            dispatch(setFilterByYearMin(null));
          } else {
            dispatch(setFilterByYearMin(newMinValue));
          }

          const newMaxValue = val[1];

          if (newMaxValue === filterOptions.maxYear) {
            dispatch(setFilterByYearMax(null));
          } else {
            dispatch(setFilterByYearMax(newMaxValue));
          }
        }}
      />
    </Field>
  );
};

export default YearFilter;
