import { Button, Icon } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { LuDownload } from "react-icons/lu";

interface CSVExportButtonProps<T extends object> {
  data: T[];
  columns: Array<keyof T>;
  filename: string;
  isDisabled?: boolean;
}

const generateCSVString = <T extends object>(
  data: T[],
  columns: Array<keyof T>
): string => {
  const header = columns.join(",");
  const rows = data.map((row) =>
    columns.map((col) => `"${row[col] || ""}"`).join(",")
  );
  return [header, ...rows].join("\n");
};

const downloadCSV = (csvString: string, filename: string) => {
  const blob = new Blob([csvString], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CSVExportButton = <T extends object>({
  data,
  columns,
  filename,
  isDisabled = false,
}: CSVExportButtonProps<T>) => {
  const handleExport = useCallback(() => {
    const csvString = generateCSVString(data, columns);
    downloadCSV(csvString, filename);
  }, [filename, data, columns]);

  if (!data.length) return null;

  return (
    <Button
      size="md"
      onClick={handleExport}
      colorPalette="gray"
      disabled={isDisabled || !data.length}
    >
      <Icon as={LuDownload} />
      Download CSV
    </Button>
  );
};

export default CSVExportButton;
