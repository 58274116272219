import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import Select, { SingleValue } from "react-select";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AdminService, { UpdateMerchantPayload } from "services/admin";
import { getReactSelectStyles } from "util/methods";
import {
  MerchantCountryCodeOptionTypes,
  merchantCountryCodeOptions,
} from "./merchant-form";
import SelectMerchant from "./select-merchant";

const reader = new FileReader();

const AddMerchantForm = () => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const [merchantName, setMerchantName] = useState<string>("");
  const [countryCodeValue, setCountryCodeValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<MerchantCountryCodeOptionTypes>>(null);
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [googlePlaceIdValue, setGooglePlaceIdValue] = useState<string>("");
  const [googleAccountIdValue, setGoogleAccountIdValue] = useState<string>("");
  const [googleLocationIdValue, setGoogleLocationIdValue] =
    useState<string>("");
  const [stripeIdValue, setStripeIdValue] = useState<string>("");
  const [keyloopEnterpriseId, setKeyloopEnterpriseId] = useState<string>("");
  const [keyloopStoreId, setKeyloopStoreId] = useState<string>("");
  const [registeredCompanyIdValue, setRegisteredCompanyIdValue] =
    useState<string>("");
  const [googleReviewLink, setGoogleReviewLink] = useState<string>("");
  const [fbBusinessId, setFbBusinessId] = useState<string>("");

  const { colorMode } = useColorMode();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const fileInput = useRef<HTMLInputElement>(null);

  const { files, images } = useAppSelector((state) => state.attachments);

  const handleChangeStripeId = (event: ChangeEvent<HTMLInputElement>) =>
    setStripeIdValue(event.target.value);

  const handleChangeGoogleAccountId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleAccountIdValue(event.target.value);

  const handleChangeGoogleLocationId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleLocationIdValue(event.target.value);

  const handleChangePlaceId = (event: ChangeEvent<HTMLInputElement>) =>
    setGooglePlaceIdValue(event.target.value);

  const handleChangeCompanyId = (event: ChangeEvent<HTMLInputElement>) =>
    setRegisteredCompanyIdValue(event.target.value);

  const handleChangeGoogleReviewLink = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleReviewLink(event.target.value);

  const handleChangFbBusinessIdValue = (event: ChangeEvent<HTMLInputElement>) =>
    setFbBusinessId(event.target.value);

  const handleChangeMerchantName = (event: ChangeEvent<HTMLInputElement>) => {
    setMerchantName(event.target.value);
  };

  const handleChangeKeyloopEnterpriseId = (
    event: ChangeEvent<HTMLInputElement>
  ) => setKeyloopEnterpriseId(event.target.value);

  const handleChangeKeyloopStoreId = (event: ChangeEvent<HTMLInputElement>) =>
    setKeyloopStoreId(event.target.value);

  const handleChangeCountryCode = (
    e: SingleValue<MerchantCountryCodeOptionTypes>
  ) => {
    setCountryCodeValue(e!.value);
    setSelectedCountryCodeValue(e);
  };

  const resetValues = () => {
    setGooglePlaceIdValue("");
    setGoogleAccountIdValue("");
    setStripeIdValue("");
    setRegisteredCompanyIdValue("");
    setGoogleReviewLink("");
    setFbBusinessId("");
    setMerchantName("");
    setSelectedMerchant(null);
    dispatch(clearAttachments());
    setCountryCodeValue("");
    setSelectedCountryCodeValue(null);
    setKeyloopEnterpriseId("");
    setKeyloopStoreId("");
  };

  useEffect(() => {
    if (!selectedMerchant) {
      resetValues();

      return;
    }

    setGooglePlaceIdValue(selectedMerchant.externalMetadata.googlePlaceId);
    setGoogleAccountIdValue(selectedMerchant.externalMetadata.googleAccountId);
    setGoogleLocationIdValue(
      selectedMerchant.externalMetadata.googleLocationId
    );
    setStripeIdValue(selectedMerchant.externalMetadata.stripeAccountId);
    setRegisteredCompanyIdValue(selectedMerchant.registeredCompanyId);
    setGoogleReviewLink(selectedMerchant.externalMetadata.googleReviewLink);
    setFbBusinessId(selectedMerchant.externalMetadata.facebookBusinessId);
    setMerchantName(selectedMerchant.name);
    setCountryCodeValue(selectedMerchant.country);
    setSelectedCountryCodeValue(
      merchantCountryCodeOptions.find(
        (option) => option.value === selectedMerchant.country
      )!
    );
    setKeyloopEnterpriseId(
      selectedMerchant.externalMetadata.keyloop?.enterpriseId || ""
    );
    setKeyloopStoreId(selectedMerchant.externalMetadata.keyloop?.storeId || "");
  }, [selectedMerchant]);

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    };
  };

  const isDisabled = (): boolean => !selectedMerchant;

  const updateMerchant = async () => {
    const updateMerchantObj: UpdateMerchantPayload = {
      merchantId: selectedMerchant!.id,
    };
    if (merchantName) {
      updateMerchantObj.name = merchantName;
    }

    if (countryCodeValue) {
      updateMerchantObj.country = countryCodeValue;
    }

    if (googlePlaceIdValue) {
      updateMerchantObj.google_place_id = googlePlaceIdValue;
    }
    if (googleAccountIdValue) {
      updateMerchantObj.google_account_id = googleAccountIdValue;
    }
    if (googleLocationIdValue) {
      updateMerchantObj.google_location_id = googleLocationIdValue;
    }
    if (stripeIdValue) {
      updateMerchantObj.stripe_account_id = stripeIdValue;
    }
    if (registeredCompanyIdValue) {
      updateMerchantObj.registered_company_id = registeredCompanyIdValue;
    }
    if (googleReviewLink) {
      updateMerchantObj.google_review_link = googleReviewLink;
    }
    if (fbBusinessId) {
      updateMerchantObj.facebook_business_id = fbBusinessId;
    }

    if (keyloopEnterpriseId && keyloopStoreId) {
      updateMerchantObj.keyloop = {
        enterpriseId: keyloopEnterpriseId,
        storeId: keyloopStoreId,
      };
    }

    if (images.length > 0) {
      const file = files[0];
      updateMerchantObj.logo = file;
    }

    await AdminService.updateMerchant(auth0Context, updateMerchantObj);
  };

  const handleSaveMerchant = async () => {
    try {
      await updateMerchant();

      toaster.create({
        type: "success",
        title: "Merchant Updated Successfully",
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Error updating merchant",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <>
      <Fieldset.Root id="merchant-form-input">
        <Fieldset.Content>
          <Field label="Select Merchant" required>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            />
          </Field>
          <Field label="Name">
            <Input
              value={merchantName}
              onChange={handleChangeMerchantName}
              placeholder="Fuzey LTD"
            />
          </Field>
          <Field label="Country">
            <Select
              placeholder="Select Country Code"
              onChange={handleChangeCountryCode}
              options={merchantCountryCodeOptions}
              value={selectedCountryCodeValue}
              styles={{
                ...getReactSelectStyles(colorMode || "light", colorScheme),
                container: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </Field>
          <Field label="Registered Company ID">
            <Input
              value={registeredCompanyIdValue}
              onChange={handleChangeCompanyId}
              placeholder="0000000000"
            />
          </Field>
          <Field label="Google Review Link">
            <Input
              value={googleReviewLink}
              onChange={handleChangeGoogleReviewLink}
              placeholder="google.com/0000"
            />
          </Field>
          <Field label="Facebook Business ID">
            <Input
              value={fbBusinessId}
              onChange={handleChangFbBusinessIdValue}
              placeholder="0000"
            />
          </Field>
          <Field label="Google Place ID">
            <Input
              value={googlePlaceIdValue}
              onChange={handleChangePlaceId}
              placeholder="0000"
            />
          </Field>
          <Field label="Google Account ID">
            <Input
              value={googleAccountIdValue}
              onChange={handleChangeGoogleAccountId}
              placeholder="0000"
            />
          </Field>
          <Field label="Google Location ID">
            <Input
              value={googleLocationIdValue}
              onChange={handleChangeGoogleLocationId}
              placeholder="0000"
            />
          </Field>
          <Field label="Stripe Account ID">
            <Input
              value={stripeIdValue}
              onChange={handleChangeStripeId}
              placeholder="0000"
            />
          </Field>
          <Field label="Keyloop Enterprise ID">
            <Input
              value={keyloopEnterpriseId}
              onChange={handleChangeKeyloopEnterpriseId}
              placeholder="0000"
            />
          </Field>
          <Field label="Keyloop Store ID">
            <Input
              value={keyloopStoreId}
              onChange={handleChangeKeyloopStoreId}
              placeholder="0000"
            />
          </Field>
          <Field label="Merchant Logo">
            <input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept={"image/*"}
            />
          </Field>
        </Fieldset.Content>
        <Button
          onClick={handleSaveMerchant}
          disabled={isDisabled()}
          mt={30}
          mb={30}
          size="lg"
        >
          Update Merchant
        </Button>
      </Fieldset.Root>
    </>
  );
};

export default AddMerchantForm;
