import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { Checkbox } from "components/ui/checkbox";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import { Tooltip } from "components/ui/tooltip";
import {
  TeamCreatedMessage,
  TeamDeletedMessage,
  TeamUpdatedMessage,
} from "entities/ISocketArgs";
import AgentDomain from "entities/domain/agents/agent-domain";
import TeamDomain from "entities/domain/team";
import { teamTransformFromDtoToDomain } from "entities/transformers/team-transformer";
import useAgentsStore from "hooks/use-agents-store";
import useDebounce from "hooks/use-debounce";
import { useWebSocket } from "hooks/use-socket";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LuFilePen, LuMinus, LuPlus, LuUsersRound, LuX } from "react-icons/lu";
import { batch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import {
  addTeam,
  clearSelectedAgents,
  enableTeamCreationTool,
  propagateTeamUpdate,
  removeTeam,
  setTeams,
  updateAgentSelection,
} from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { getReactSelectStyles } from "util/methods";
import { canManageTeamMembers } from "util/permissions";
import AddToTeamModal from "./AddToTeamModal";
import EditTeamModal from "./EditTeamModal";
import RemoveFromTeamModal from "./RemoveFromTeamModal";
import TeamCreationTool from "./TeamCreationTool";

const AgentNameColumn = ({ item }: { item: AgentDomain }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  return (
    <Flex
      pl={canManageTeamMembers(merchant.id, currentAgent!) ? 0 : 4}
      justifyContent="start"
      alignItems="center"
      gridGap={2}
    >
      {!isBaseSize && (
        <ProfileAvatar
          profilePicture={item.getPicture()}
          name={item.getFullName()}
          avatarStyle={{
            colorPalette: item.color,
          }}
        />
      )}

      <Flex
        flexDirection="column"
        alignItems="flex-start"
        ml={isBaseSize ? 0 : 4}
        textAlign="left"
        w="100%"
      >
        <Text
          w="100%"
          fontWeight={700}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {" "}
          {item.getFullName()}
        </Text>
        <Text
          w="100%"
          display={isBaseSize ? "none" : "initial"}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {" "}
          {item.email}
        </Text>
      </Flex>
    </Flex>
  );
};

const AgentRoleColumn = ({ item }: { item: AgentDomain }) => (
  <Text>{item.role ? item.role.description.split(" ")[0] : ""}</Text>
);

const AgentPermissionsColumn = ({ item }: { item: AgentDomain }) => (
  <Tooltip content={item.displayPermissionsNameText()}>
    <Text textAlign="left">{item.role?.getRoleDescription()}</Text>
  </Tooltip>
);

const AgentStatusColumn = ({ item }: { item: AgentDomain }) => (
  <Text color={item.isActive ? "green.400" : "red.400"}>
    {item.isActive ? "Active" : "Deactivated"}
  </Text>
);

const AgentTeamColumn = ({ item }: { item: AgentDomain }) => {
  const { teams } = useAppSelector((state) => state.agents);

  const [agentTeams, setAgentTeams] = useState<TeamDomain[]>(
    teams.filter((t) => t.agentIds.includes(item.id))
  );

  useEffect(() => {
    setAgentTeams(teams.filter((t) => t.agentIds.includes(item.id)));
  }, [teams]);

  const DEFAULT_VISIBLE_TEAMS = 1;

  const teamsContainerRef = useRef<HTMLDivElement>(null);

  const [maxVisibleTeams, setMaxVisibleTeams] = useState<number>(
    DEFAULT_VISIBLE_TEAMS
  );
  const visibleTeams = useMemo(() => {
    return agentTeams.slice(0, maxVisibleTeams);
  }, [maxVisibleTeams, agentTeams]);
  const hiddenTeams = useMemo(() => {
    return agentTeams.slice(maxVisibleTeams);
  }, [maxVisibleTeams, agentTeams]);

  useEffect(() => {
    const { current } = teamsContainerRef;

    if (agentTeams.length === 0 || !current) {
      return;
    }

    const updateTeamsDisplay = () => {
      const availableWidth = teamsContainerRef.current?.offsetWidth || 0;
      const averageCharWidth = 8; // A conservative estimate for the widest character
      const tagPadding = 24; // Total horizontal padding and margins for a tag in pixels

      // Find the maximum width of a team
      const maxTeamWidth = agentTeams.reduce(
        (max, team) =>
          Math.max(max, team.name.length * averageCharWidth + tagPadding),
        1
      );

      // Calculate how many teams can fit
      setMaxVisibleTeams(Math.floor(availableWidth / maxTeamWidth) || 1);
    };

    if ("ResizeObserver" in window) {
      new ResizeObserver(updateTeamsDisplay).observe(current);
    }
  }, [teamsContainerRef, agentTeams]);

  return agentTeams.length === 0 ? null : (
    <HStack
      ref={teamsContainerRef}
      gap={2}
      overflow="hidden"
      alignItems="start"
    >
      {visibleTeams.map((team, index) => (
        <Tag.Root key={index} size="sm" variant="outline">
          <Tag.Label>{team.name}</Tag.Label>
        </Tag.Root>
      ))}
      {hiddenTeams.length > 0 && (
        <Tooltip
          content={hiddenTeams.map((team) => team.name).join(", ")}
          positioning={{ placement: "top" }}
        >
          <Tag.Root colorPalette="gray" size="sm">
            <Tag.Label>
              <Text lineClamp={1}>{`+${hiddenTeams.length}`}</Text>
            </Tag.Label>
          </Tag.Root>
        </Tooltip>
      )}
    </HStack>
  );
};

const AgentRoundRobinRotationColumn = ({ item }: { item: AgentDomain }) => {
  const { updateAgent } = useAgentsStore();
  const [rotationEnabled, setRotationEnabled] = useState<boolean>(
    item.rotationEnabled
  );

  useEffect(() => {
    setRotationEnabled(item.rotationEnabled);
  }, [item]);

  const handleCheckBoxChange = async (e: { checked: boolean }) => {
    try {
      updateAgent({
        id: item.id,
        name: item.name,
        surname: item.surname,
        email: item.email,
        notification_handle: item.notificationConfig?.handle
          ? item.notificationConfig?.handle
          : "",
        notification_channel: item.notificationConfig?.contactChannel
          ? item.notificationConfig?.contactChannel
          : "",
        notification_preference: item.notificationConfig?.preference
          ? item.notificationConfig?.preference
          : "",
        notification_timezone: item.notificationConfig?.timezone
          ? item.notificationConfig?.timezone
          : "",
        role_id: item.role ? item.role.id : 0,
        active: item.isActive,
        rotation_enabled: !!e.checked,
        profile_picture_url: item.profilePic,
        player_ids: item.playerIds,
        style_preferences: {
          chat_background: item.stylePreferences?.chatBackground || null,
          color_scheme: item.stylePreferences?.colorScheme || null,
        },
      });
      setRotationEnabled(!!e.checked);
    } catch (_error: unknown) {
      toaster.create({ type: "error", title: "Error updating agent" });
    }
  };
  return (
    <Checkbox
      checked={rotationEnabled}
      onCheckedChange={handleCheckBoxChange}
      disabled={!item.isActive}
    />
  );
};

interface TeamSelectOption {
  value: string;
  label: string;
}

const TeamMemberSettings = () => {
  const auth0Context = useAuth0();
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const navigate = useNavigate();
  const { currentAgent, agents, selectedAgentIds, teams } = useAppSelector(
    (state) => state.agents
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { updateAgent } = useAgentsStore();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const dispatch = useAppDispatch();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getIndividualActions = (
    agentItem: AgentDomain
  ): SmartListIndividualAction<AgentDomain>[] | undefined => {
    if (!agentItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<AgentDomain>[] = [];

    if (
      canManageTeamMembers(merchant.id, currentAgent!) ||
      agentItem.id === currentAgent!.id
    ) {
      individualActions.push({
        label: "Edit",
        execute: () => {
          navigate(`/${merchant.id}/settings/teammates/${agentItem.id}`);
        },
      });
    }

    if (canManageTeamMembers(merchant.id, currentAgent!)) {
      individualActions.push({
        label: agentItem.isActive ? "Deactivate" : "Activate",
        execute: async () => {
          try {
            updateAgent({
              id: agentItem.id,
              name: agentItem.name,
              surname: agentItem.surname,
              email: agentItem.email,
              notification_handle: agentItem.notificationConfig?.handle
                ? agentItem.notificationConfig?.handle
                : "",
              notification_channel: agentItem.notificationConfig?.contactChannel
                ? agentItem.notificationConfig?.contactChannel
                : "",
              notification_preference: agentItem.notificationConfig?.preference
                ? agentItem.notificationConfig?.preference
                : "",
              notification_timezone: agentItem.notificationConfig?.timezone
                ? agentItem.notificationConfig?.timezone
                : "",
              role_id: agentItem.role ? agentItem.role.id : 0,
              active: !agentItem.isActive,
              rotation_enabled: agentItem.rotationEnabled,
              profile_picture_url: agentItem.profilePic,
              player_ids: agentItem.playerIds,
              style_preferences: {
                chat_background:
                  agentItem.stylePreferences?.chatBackground || null,
                color_scheme: agentItem.stylePreferences?.colorScheme || null,
              },
            });
          } catch (_error: unknown) {
            toaster.create({
              type: "error",
              title: "Error deactivating agent",
            });
          }
        },
        shouldShowConfirmation: true,
        confirmationText: `Are you sure you want to ${
          agentItem.isActive ? "deactivate" : "reactivate"
        } this agent?`,
      });

      if (isBaseSize) {
        individualActions.push({
          label: agentItem.rotationEnabled
            ? "Remove from Lead Rotation"
            : "Add to Lead Rotation",
          execute: async () => {
            try {
              updateAgent({
                id: agentItem.id,
                name: agentItem.name,
                surname: agentItem.surname,
                email: agentItem.email,
                notification_handle: agentItem.notificationConfig?.handle
                  ? agentItem.notificationConfig?.handle
                  : "",
                notification_channel: agentItem.notificationConfig
                  ?.contactChannel
                  ? agentItem.notificationConfig?.contactChannel
                  : "",
                notification_preference: agentItem.notificationConfig
                  ?.preference
                  ? agentItem.notificationConfig?.preference
                  : "",
                notification_timezone: agentItem.notificationConfig?.timezone
                  ? agentItem.notificationConfig?.timezone
                  : "",
                role_id: agentItem.role ? agentItem.role.id : 0,
                active: agentItem.isActive,
                rotation_enabled: !agentItem.rotationEnabled,
                profile_picture_url: agentItem.profilePic,
                player_ids: agentItem.playerIds,
                style_preferences: {
                  chat_background:
                    agentItem.stylePreferences?.chatBackground || null,
                  color_scheme: agentItem.stylePreferences?.colorScheme || null,
                },
              });
            } catch (_error: unknown) {
              toaster.create({
                type: "error",
                title: "Error updating agent in rotation",
              });
            }
          },
          shouldShowConfirmation: true,
          confirmationText: `Are you sure you want to ${
            agentItem.rotationEnabled
              ? "remove from rotation"
              : "add in rotation"
          } this agent?`,
        });
      }
    }

    return individualActions;
  };

  const [showAddToTeamModal, setShowAddToTeamModal] = useState<boolean>(false);
  const [showRemoveFromTeamModal, setShowRemoveFromTeamModal] =
    useState<boolean>(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState<boolean>(false);

  useEffect(() => {
    AgentsService.getTeams(auth0Context, merchant.id)
      .then((allTeams) => {
        dispatch(setTeams(allTeams));
      })
      .catch(() => {});
  }, [merchant.id]);

  const [teamCreate, setTeamCreate] = useState<TeamCreatedMessage>(
    {} as TeamCreatedMessage
  );
  const [teamUpdate, setTeamUpdate] = useState<TeamUpdatedMessage>(
    {} as TeamUpdatedMessage
  );
  const [teamDelete, setTeamDelete] = useState<TeamDeletedMessage>(
    {} as TeamDeletedMessage
  );

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchText, 500);

  const handleTeamCreated = (args: TeamCreatedMessage) => {
    if (args.team.merchant_id !== merchant.id) {
      return;
    }

    setTeamCreate(args);
  };

  const handleTeamUpdated = (args: TeamUpdatedMessage) => {
    if (args.team.merchant_id !== merchant.id) {
      return;
    }

    setTeamUpdate(args);
  };

  const handleTeamDeleted = (args: TeamDeletedMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    setTeamDelete(args);
  };

  useEffect(() => {
    addEventHandler("team_created", handleTeamCreated);
    addEventHandler("team_updated", handleTeamUpdated);
    addEventHandler("team_deleted", handleTeamDeleted);

    return () => {
      removeEventHandler("team_created", handleTeamCreated);
      removeEventHandler("team_updated", handleTeamUpdated);
      removeEventHandler("team_deleted", handleTeamDeleted);
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    if (Object.keys(teamCreate).length !== 0) {
      const team: TeamDomain = teamTransformFromDtoToDomain(teamCreate.team);

      dispatch(addTeam(team));
    }
  }, [teamCreate]);

  useEffect(() => {
    if (Object.keys(teamUpdate).length !== 0) {
      const team: TeamDomain = teamTransformFromDtoToDomain(teamUpdate.team);

      dispatch(propagateTeamUpdate(team));
    }
  }, [teamUpdate]);

  useEffect(() => {
    if (Object.keys(teamDelete).length !== 0) {
      dispatch(removeTeam(teamDelete.team_id));
    }
  }, [teamDelete]);

  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>(
    teams.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  );
  const [selectedTeamValue, setSelectedTeamValue] =
    useState<SingleValue<TeamSelectOption>>(null);

  useEffect(() => {
    const newTeamOptions = teams.map((team) => ({
      label: team.name,
      value: team.id,
    }));

    if (selectedTeamValue) {
      const selectedTeamStillExists = newTeamOptions.find(
        (team) => team.value === selectedTeamValue.value
      );

      if (!selectedTeamStillExists) {
        setSelectedTeamValue(null);
      }
    }

    setTeamOptions(newTeamOptions);
  }, [teams]);

  const [filteredAgentsByTeam, setFilteredAgentsByTeam] =
    useState<AgentDomain[]>(agents);

  useEffect(() => {
    if (!selectedTeamValue) {
      setFilteredAgentsByTeam(agents);
      return;
    }

    const team = teams.find((t) => t.id === selectedTeamValue.value);

    if (!team) {
      return;
    }

    const filteredAgents = agents.filter((agent) =>
      team.agentIds.includes(agent.id)
    );

    setFilteredAgentsByTeam(filteredAgents);
  }, [selectedTeamValue, agents]);

  const [filteredAgentsByName, setFilteredAgentsByName] = useState<
    AgentDomain[]
  >([]);

  useEffect(() => {
    setFilteredAgentsByName(
      filteredAgentsByTeam.filter((a) =>
        a
          .getFullName()
          .toLowerCase()
          .includes(debouncedSearchText.toLowerCase())
      )
    );
  }, [debouncedSearchText, filteredAgentsByTeam]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedAgents());
    };
  }, []);

  return (
    <>
      <VStack w="100%" gap={0} h="100%" overflow="hidden">
        {!isBaseSize && (
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="start"
            py={4}
            gridGap={2}
          >
            <ReviewsSearch
              placeholder="John Doe"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Flex>
        )}
        <Box
          w="100%"
          h="100%"
          {...(isBaseSize
            ? { overflow: "hidden" }
            : { overflow: "hidden", borderTopRadius: "3xl" })}
        >
          <Box
            overflowY="auto"
            overflowX="hidden"
            w="100%"
            h="100%"
            {...(isBaseSize
              ? {}
              : {
                  borderTopRadius: "3xl",
                  bgColor: colorMode === "dark" ? "gray.800" : "white",
                })}
            justifyContent="center"
            alignItems="start"
            ref={scrollContainerRef}
          >
            {!teams.length ? null : (
              <Flex
                gridGap={2}
                justifyContent="start"
                alignItems="center"
                w="100%"
                p={4}
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor={
                  colorMode === "dark" ? "gray.700" : "gray.200"
                }
              >
                <Select
                  placeholder="All teams"
                  onChange={(newTeam) => setSelectedTeamValue(newTeam)}
                  isClearable={true}
                  options={teamOptions}
                  value={selectedTeamValue}
                  styles={{
                    ...{
                      ...getReactSelectStyles(
                        colorMode || "light",
                        colorScheme
                      ),
                      container: (provided: any) => ({
                        ...provided,
                        width: isBaseSize ? "100%" : "auto",
                      }),
                    },
                  }}
                />
                {selectedTeamValue &&
                canManageTeamMembers(merchant.id, currentAgent!) ? (
                  <>
                    {isBaseSize ? (
                      <IconButton
                        aria-label="Edit team"
                        colorPalette="gray"
                        onClick={() => setShowEditTeamModal(true)}
                      >
                        <LuFilePen />
                      </IconButton>
                    ) : (
                      <Button
                        size="md"
                        colorPalette="gray"
                        onClick={() => setShowEditTeamModal(true)}
                      >
                        <LuFilePen /> {isBaseSize ? "Edit" : "Edit Team"}
                      </Button>
                    )}
                  </>
                ) : null}
              </Flex>
            )}

            {selectedAgentIds.length > 0 && isBaseSize ? (
              <HStack
                px={4}
                py={2}
                bgColor={
                  colorMode === "dark"
                    ? `${colorScheme}.900`
                    : `${colorScheme}.200`
                }
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  aria-label="Clear selection"
                  variant="ghost"
                  onClick={() => {
                    dispatch(clearSelectedAgents());
                  }}
                >
                  <LuX />
                </IconButton>
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  color={colorMode === "dark" ? "white" : "black"}
                >
                  {`${selectedAgentIds.length} Selected`}
                </Text>
                <HStack>
                  <IconButton
                    aria-label="Add to Team"
                    p={0}
                    colorPalette={colorScheme}
                    onClick={() => {
                      setShowAddToTeamModal(true);
                    }}
                  >
                    <VStack
                      position="relative"
                      justifyContent="center"
                      h="100%"
                      w="100%"
                    >
                      <Icon
                        as={LuUsersRound}
                        w={4}
                        h={4}
                        mr="0.3rem"
                        color={colorMode === "dark" ? "gray.800" : "white"}
                      />
                      <Icon
                        as={LuPlus}
                        w={2}
                        h={2}
                        position="absolute"
                        top="0.45rem"
                        right="0.25rem"
                        color={colorMode === "dark" ? "gray.800" : "white"}
                      />
                    </VStack>
                  </IconButton>
                  <IconButton
                    aria-label="Remove from Team"
                    p={0}
                    colorPalette="red"
                    onClick={() => {
                      setShowRemoveFromTeamModal(true);
                    }}
                  >
                    <VStack
                      position="relative"
                      justifyContent="center"
                      h="100%"
                      w="100%"
                    >
                      <Icon
                        as={LuUsersRound}
                        w={4}
                        h={4}
                        mr="0.3rem"
                        color={colorMode === "dark" ? "gray.800" : "white"}
                      />
                      <Icon
                        as={LuMinus}
                        w={2}
                        h={2}
                        position="absolute"
                        top="0.45rem"
                        right="0.25rem"
                        color={colorMode === "dark" ? "gray.800" : "white"}
                      />
                    </VStack>
                  </IconButton>
                </HStack>
              </HStack>
            ) : null}

            <Box h="100%" w="100%" mx="auto">
              <SmartList
                hasBulkActions={canManageTeamMembers(
                  merchant.id,
                  currentAgent!
                )}
                selectedItemIds={
                  canManageTeamMembers(merchant.id, currentAgent!)
                    ? selectedAgentIds
                    : undefined
                }
                handleItemsSelected={
                  canManageTeamMembers(merchant.id, currentAgent!)
                    ? (selectedItemIds) => {
                        const agentIds = agents.map((agent) => agent.id);

                        batch(() => {
                          agentIds.forEach((agentId) => {
                            dispatch(
                              updateAgentSelection({
                                agentId,
                                isSelected: selectedItemIds.includes(agentId),
                              })
                            );
                          });
                        });
                      }
                    : undefined
                }
                items={filteredAgentsByName}
                itemIdentifier="id"
                containerRef={scrollContainerRef}
                columns={[
                  {
                    label: "Name",
                    component: AgentNameColumn,
                  },
                  {
                    label: "Teams",
                    component: AgentTeamColumn,
                  },
                  {
                    label: "Role",
                    component: AgentRoleColumn,
                  },
                  ...(isBaseSize
                    ? []
                    : [
                        {
                          label: "Permissions",
                          component: AgentPermissionsColumn,
                        },
                        {
                          label: "Status",
                          component: AgentStatusColumn,
                        },
                        {
                          label: "Auto Assign Leads",
                          component: AgentRoundRobinRotationColumn,
                          infoLabel:
                            "Automatically assigns leads to the selected agents, based on a standardised queue.",
                        },
                      ]),
                ]}
                getIndividualActions={getIndividualActions}
              />
            </Box>
          </Box>
        </Box>
      </VStack>
      <AddToTeamModal
        isOpen={showAddToTeamModal}
        onClose={() => {
          dispatch(enableTeamCreationTool());
          setShowAddToTeamModal(false);
        }}
      />
      <RemoveFromTeamModal
        isOpen={showRemoveFromTeamModal}
        onClose={() => {
          dispatch(enableTeamCreationTool());
          setShowRemoveFromTeamModal(false);
        }}
      />
      <EditTeamModal
        teamToEdit={
          selectedTeamValue
            ? teams.find((t) => t.id === selectedTeamValue.value) || null
            : null
        }
        isOpen={showEditTeamModal}
        onClose={() => {
          setShowEditTeamModal(false);
        }}
      />
      {isBaseSize ? null : (
        <TeamCreationTool
          openAddToTeamModal={() => {
            setShowAddToTeamModal(true);
          }}
          openRemoveFromTeamModal={() => {
            setShowRemoveFromTeamModal(true);
          }}
        />
      )}
    </>
  );
};

export default TeamMemberSettings;
