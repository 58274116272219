import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Fieldset,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";

import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import AdminService from "services/admin";
import SelectMerchant from "./select-merchant";

const AddMerchantAssistantForm = () => {
  const auth0 = useAuth0();

  const { colorScheme } = useAppSelector((state) => state.theme);

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [assistantExternalId, setAssistantExternalId] = useState<string>("");

  const resetValues = () => {
    setSelectedMerchant(null);
    setAssistantExternalId("");
  };

  const saveAssistant = async () => {
    try {
      await AdminService.addMerchantAssistant(auth0, {
        merchantId: selectedMerchant!.id,
        externalId: assistantExternalId,
      })
        .then(() => {
          toaster.create({
            title: "Assistant added successfully",
            type: "success",
          });
        })
        .catch((error) => {
          toaster.create({
            title: error.message,
            type: "error",
          });
        });
    } finally {
      resetValues();
    }
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content>
          <Field label="Assign Merchant" required>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            />
          </Field>
          <Field label="Assistant Id" required>
            <Input
              value={assistantExternalId}
              onChange={(e) => {
                setAssistantExternalId(e.target.value);
              }}
              placeholder="asst_123131"
            />
          </Field>
        </Fieldset.Content>
        <Button
          colorPalette={colorScheme}
          onClick={saveAssistant}
          disabled={!selectedMerchant}
          mt={8}
          mb={8}
          size="lg"
        >
          Add Assistant
        </Button>
    </Fieldset.Root>
  );
};

export default AddMerchantAssistantForm;
