import {
  Button,
  Input,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import React, { ReactElement, memo, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface EditCustomFieldModalProps {
  isOpen: boolean;
  field: {
    key: CustomFieldKey;
    setNewValue: (newValue: string) => void;
  } | null;
  customFields: CustomFields;
  setIsOpen: (isOpen: boolean) => void;
  addOrReplaceCustomField: (key: string, value: string) => void;
}

const EditCustomFieldModal = ({
  isOpen,
  field,
  customFields,
  setIsOpen,
  addOrReplaceCustomField,
}: EditCustomFieldModalProps) => {
  const [editor] = useLexicalComposerContext();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const [customFieldValueModal, setCustomFieldValueModal] = useState<string>(
    field?.key ? customFields[field.key] || "" : ""
  );
  const [customFieldKeyModal, setCustomFieldKeyModal] = useState<string>("");
  const [customFieldTipModal, setCustomFieldTipModal] =
    useState<ReactElement | null>(null);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onClose = () => {
    setCustomFieldValueModal("");
    setCustomFieldTipModal(null);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!field) {
      return;
    }

    if (customFields[field.key] === `{${field.key}}`) {
      setCustomFieldValueModal("");
      setCustomFieldKeyModal(field.key);
      return;
    }

    setCustomFieldValueModal(customFields[field.key] || "");
    setCustomFieldKeyModal(field.key);
  }, [field?.key, customFields, isOpen]);

  if (!field) {
    return null;
  }

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      size={isBaseSize ? "md" : "xl"}
    >
      <DialogBackdrop  />
      <DialogContent>
        <DialogHeader    textAlign="center">
          {customFieldTipModal || <Text mt="1rem">Insert Custom Text</Text>}
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody     mx={4}>
          <Input
            colorPalette={colorScheme}
            placeholder={customFieldKeyModal}
            type="text"
            value={customFieldValueModal}
            onChange={(e) => setCustomFieldValueModal(e.target.value)}
          />
        </DialogBody>
        <DialogFooter    display="flex" justifyContent="center" alignItems="center">
          <Button
            colorPalette={colorScheme}
            data-testid="submit_custom_field_value"
            onClick={() => {
              addOrReplaceCustomField(
                customFieldKeyModal,
                customFieldValueModal
              );
              editor.update(() => {
                field.setNewValue(customFieldValueModal);
              });
              onClose();
            }}
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default memo(EditCustomFieldModal);
