import { Button, useBreakpointValue, useDisclosure } from "@chakra-ui/react";

import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import {
  PasswordInput,
  PasswordStrengthMeter,
} from "components/ui/password-input";
import PasswordChangeConfirmation from "components/user-settings/AccountOverview/PasswordChange";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import * as yup from "yup";

interface PasswordChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getPasswordStrength = (password: string): number => {
  const strength = {
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    moreThan12: false,
  };

  if (password.length >= 12) {
    strength.moreThan12 = true;
  }

  for (const char of password) {
    if (char.match(/[a-z]/)) {
      strength.hasLowerCase = true;
    } else if (char.match(/[A-Z]/)) {
      strength.hasUpperCase = true;
    } else if (char.match(/[0-9]/)) {
      strength.hasNumber = true;
    } else if (char.match(/[^a-zA-Z0-9]/)) {
      strength.hasSpecialChar = true;
    }
  }
  return Object.values(strength).filter((value) => value).length;
};

const PasswordChangeModal = ({ isOpen, onClose }: PasswordChangeModalProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordChangeConfirmationDrawer = useDisclosure();

  const getValidationError = async (password: string): Promise<string> => {
    const schema = yup
      .string()
      .required("Required")
      .min(12, "Must be at least 12 characters")
      .matches(/^(?=.*[a-z])/, "Must contain one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must contain one uppercase character")
      .matches(/^(?=.*[0-9])/, "Must contain one numeric character");

    try {
      await schema.validate(password);
    } catch (err: unknown) {
      if (err instanceof yup.ValidationError) {
        if (err.errors[0]) {
          return err.errors[0];
        }
      }
    }
    return "";
  };

  useEffect(() => {
    setPasswordError("");
    setNewPassword("");
  }, [isOpen]);

  useEffect(() => {
    if (!newPassword) {
      setPasswordError("");
    } else {
      getValidationError(newPassword).then((error) => {
        setPasswordError(error);
      });
    }
  }, [newPassword]);

  return (
    <>
      <DialogRoot
        open={isOpen}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
          }
        }}
        size={isBaseSize ? "full" : "md"}
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>Change Password</DialogHeader>
          <DialogCloseTrigger />
          <DialogBody>
            <Field
              label="New Password"
              invalid={!!passwordError}
              errorText={passwordError}
            >
              <PasswordInput
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={passwordChangeConfirmationDrawer.open}
              />
              <PasswordStrengthMeter
                value={getPasswordStrength(newPassword)}
                max={5}
              />
            </Field>
          </DialogBody>
          <DialogFooter justifyContent="space-between">
            <Button
              colorPalette={colorScheme}
              variant="ghost"
              onClick={onClose}
              disabled={passwordChangeConfirmationDrawer.open}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !!passwordError ||
                !newPassword ||
                passwordChangeConfirmationDrawer.open
              }
              colorPalette={colorScheme}
              onClick={() => passwordChangeConfirmationDrawer.onOpen()}
            >
              Change
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
      <PasswordChangeConfirmation
        newPassword={newPassword}
        isOpen={passwordChangeConfirmationDrawer.open}
        onClose={() => {
          passwordChangeConfirmationDrawer.onClose();
          onClose();
        }}
      />
    </>
  );
};

export default PasswordChangeModal;
