import { Input } from "@chakra-ui/react";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import React from "react";

const TextFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  return (
    <Input
      id={id}
      width="12rem"
      unstyled={true}
      borderRadius={0}
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      _focus={{
        borderBottomColor: "blue.500",
      }}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
  );
};

export default TextFieldInput;
