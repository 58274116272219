import { defineSlotRecipe } from "@chakra-ui/react";
import { actionBarAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: actionBarAnatomy.keys(),
  base: {
    positioner: {
      zIndex: 99999,
    },
  },
});
