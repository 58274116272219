import { Icon, Tabs, useBreakpointValue } from "@chakra-ui/react";
import { CampaignStatus } from "entities/domain/campaign";
import useAudiencesStore from "hooks/use-audiences-store";
import React, { useEffect, useState } from "react";
import { LuScanSearch, LuUpload } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

import { toaster } from "components/ui/toaster";
import AudienceDomain, { AudienceCriteria } from "entities/domain/audience";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { stringifyCriteria } from "entities/domain/criteria";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ExistingAudienceTab from "./ExistingAudienceTab";
import NewAudienceTab from "./NewAdienceTab";
import UploadAudienceTab from "./UploadAudienceTab";

interface AudienceProps {
  campaignId: string | null;
  campaignAudienceId: string | null;
  campaignStatus: CampaignStatus;
  campaignChannel: ConversationChannel;
  setCampaignAudienceId: (id: string | null) => void;
  onValidationSuccess: () => void;
  onValidationFailure: () => void;
}

const Audience = ({
  campaignId,
  campaignAudienceId,
  campaignStatus,
  campaignChannel,
  setCampaignAudienceId,
  onValidationFailure,
  onValidationSuccess,
}: AudienceProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { merchant } = useAppSelector((state) => state.merchant);
  const { fetchAudiences, editAudience, createAudience } = useAudiencesStore();
  const { audiences } = useAppSelector((state) => state.audiences);
  const [activeTab, setActiveTab] = useState<number>(
    campaignAudienceId ? (isBaseSize ? 1 : 2) : 0
  );

  const [audience, setAudience] = useState<AudienceDomain | null>(null);
  const [audienceName, setAudienceName] = useState<string | null>("");
  const [audienceCriteria, setAudienceCriteria] =
    useState<AudienceCriteria | null>(null);
  const [isSavingAudience, setIsSavingAudience] = useState<boolean>(false);
  const [isInitialLoadCompleted, setIsInitialLoadCompleted] =
    useState<boolean>(false);
  const isUpdate = !!campaignId;

  useEffect(() => {
    if (!audiences.length) {
      fetchAudiences();
    }

    setIsInitialLoadCompleted(true);
  }, []);

  const handleAudienceSave = async (
    next: (newAudience?: AudienceDomain) => void,
    audienceDraft?: AudienceDomain,
    uploadTagId?: string
  ) => {
    if (!audience && !audienceDraft) {
      next();

      return;
    }

    setIsSavingAudience(true);

    try {
      const saveResponse =
        isUpdate && audience
          ? await editAudience({
              id: audience.id as string,
              name: audience.name.trim(),
              criteria: stringifyCriteria(audience.criteria),
              merchantId: audience.merchantId,
            })
          : await createAudience({
              name: audience
                ? audience.name.trim()
                : audienceDraft!.name.trim(),
              criteria: audience
                ? stringifyCriteria(audience.criteria)
                : stringifyCriteria(audienceDraft!.criteria),
              merchantId: audience
                ? audience.merchantId
                : audienceDraft!.merchantId,
            });

      if (!saveResponse) {
        toaster.create({
          type: "error",
          title: "An error occurred while creating an audience.",
          description: "Try uploading a CSV instead!",
        });

        return;
      }

      setAudience(null);

      next(saveResponse);

      return;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save an Audience: ", err);
      /* eslint-enable no-console */
      toaster.create({
        type: "error",
        title: "An error occurred while creating an audience.",
        description: "Try uploading a CSV instead!",
      });

      return;
    } finally {
      setIsSavingAudience(false);
    }
  };

  useEffect(() => {
    const audienceWasCreated = !!audience && audienceName && audienceCriteria;

    if (audienceWasCreated && activeTab !== 0) {
      setAudience(null);
    }
  }, [activeTab]);

  useEffect(() => {
    const hasCsvFile = activeTab === 1 && !isBaseSize;
    const audienceWasSelected = !!campaignAudienceId;
    const audienceWasCreated =
      !!audience && audienceName && audienceCriteria && !hasCsvFile;

    if (audienceWasSelected || audienceWasCreated) {
      onValidationSuccess();

      if (audienceWasSelected && activeTab === 1 && hasCsvFile) {
        setActiveTab(2);
      } else if (
        audienceWasCreated &&
        audienceWasSelected &&
        activeTab === 0 &&
        !hasCsvFile
      ) {
        setActiveTab(isBaseSize ? 1 : 2);
      }
    } else {
      onValidationFailure();
    }
  }, [campaignAudienceId, audience, audienceCriteria, audienceName]);

  return (
    <Tabs.Root
      colorPalette={colorScheme}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      value={activeTab.toString()}
      onValueChange={({ value }) => {
        setActiveTab(parseInt(value, 10));
      }}
      fitted
    >
      <Tabs.List pt={isBaseSize ? 0 : 4}>
        <Tabs.Trigger
          disabled={campaignStatus === CampaignStatus.DONE}
          value="0"
        >
          <Icon
            as={AiOutlineUsergroupAdd}
            mr={1}
            boxSize="2rem"
            color="currentColor"
          />
          Create
        </Tabs.Trigger>
        {isBaseSize ? null : (
          <Tabs.Trigger
            disabled={campaignStatus === CampaignStatus.DONE}
            value="1"
          >
            <Icon
              as={LuUpload}
              mr={1}
              css={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
            Upload
          </Tabs.Trigger>
        )}
        <Tabs.Trigger value="2">
          <Icon as={LuScanSearch} mr={1} />
          Select
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content
        value="0"
        display="flex"
        flexDirection="column"
        w="100%"
        mx="auto"
        h="100%"
        overflowY="auto"
        my={0}
      >
        <NewAudienceTab
          isSavingAudience={isSavingAudience}
          audienceName={audienceName}
          campaignChannel={campaignChannel}
          audienceCriteria={audienceCriteria || null}
          onAudienceSelect={(createdAudience: AudienceDomain) => {
            setCampaignAudienceId(createdAudience.id);
            setAudience(createdAudience);
          }}
          setAudienceCriteria={(newAudienceCriteria: AudienceCriteria) => {
            setAudienceCriteria(newAudienceCriteria);
          }}
          setAudienceName={setAudienceName}
        />
      </Tabs.Content>
      {isBaseSize ? null : (
        <Tabs.Content
          value="1"
          display="flex"
          flexDirection="column"
          w={isBaseSize ? "100%" : "60%"}
          mx="auto"
          h="100%"
          overflowY="auto"
          my={0}
        >
          <UploadAudienceTab
            isSavingAudience={isSavingAudience}
            campaignId={campaignId}
            setCampaignAudienceId={setCampaignAudienceId}
            handleAudienceSave={handleAudienceSave}
          />
        </Tabs.Content>
      )}
      <Tabs.Content
        value="2"
        display="flex"
        flexDirection="column"
        w={isBaseSize ? "100%" : "60%"}
        mx="auto"
        my={0}
        h="100%"
        overflowY="hidden"
      >
        <ExistingAudienceTab
          audienceId={campaignAudienceId}
          campaignStatus={campaignStatus}
          setAudienceId={setCampaignAudienceId}
        />
      </Tabs.Content>
    </Tabs.Root>
  );
};

export default Audience;
