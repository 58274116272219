export const isAccordionItemExpanded = (
  index: number,
  expandedIndices: number[]
) => {
  return expandedIndices.includes(index);
};

export const collapseAccordionItem = (
  index: number,
  expandedIndices: number[],
  setExpandedIndices: (expandedIndices: number[]) => void
) => {
  const newExpandedIndices = expandedIndices.filter((i) => i !== index);
  setExpandedIndices(newExpandedIndices);
};
