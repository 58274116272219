import { useAuth0 } from "@auth0/auth0-react";
import {
  AvatarGroup,
  Box,
  Avatar as ChakraAvatar,
  HStack,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { InteractOutsideEvent } from "@zag-js/interact-outside";
import ProfileAvatar from "components/profile/profile-avatar";
import VehicleInfo from "components/shared/contact-details/VehicleInfo";
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from "components/ui/accordion";
import { Avatar } from "components/ui/avatar";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverRoot,
  PopoverTitle,
  PopoverTrigger,
} from "components/ui/popover";
import { toaster } from "components/ui/toaster";
import { format, parseISO } from "date-fns";
import CalendarBookingDomain, {
  BookingOutcome,
} from "entities/domain/calendar/calendar-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import InventoryVehicleDomain from "entities/domain/inventory-vehicle";
import VehicleDomain from "entities/domain/vehicle";
import useCalendarStore from "hooks/use-calendar-store";
import React, { useEffect, useState } from "react";
import { LuCar, LuPencil, LuTrash, LuX } from "react-icons/lu";
import Select, { SingleValue } from "react-select";
import { updateCalendarEventSuccess } from "redux/features/calendar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CalendarsService from "services/calendar";
import ContactsService from "services/contacts";
import InventoryService from "services/inventory";
import MerchantService from "services/merchant";
import { getReactSelectStyles } from "util/methods";

interface SelectOption {
  readonly value: BookingOutcome;
  readonly label: string;
}

interface CalendarEventInfoProps {
  eventTitle: string;
  bookingId: string;
  onEditClick: () => void;
}

interface GroupedOption {
  readonly label: string;
  readonly options: readonly SelectOption[];
}

const groupedOptions: readonly GroupedOption[] = [
  {
    label: "Showed Up",
    options: [
      {
        label: "Bought vehicle",
        value: BookingOutcome.CONVERTED,
      },
      {
        label: "Didn't purchase vehicle",
        value: BookingOutcome.NOT_CONVERTED,
      },
    ],
  },
  {
    label: "No Show",
    options: [
      {
        label: "Rescheduled",
        value: BookingOutcome.RESCHEDULED,
      },
      {
        label: "Needs to be contacted",
        value: BookingOutcome.NEEDS_TO_BE_CONTACTED,
      },
      {
        label: "Contacted, not rescheduled",
        value: BookingOutcome.CONTACTED,
      },
    ],
  },
];

const EventInfoPopover = ({
  eventTitle,
  bookingId,
  onEditClick,
}: CalendarEventInfoProps) => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { agents } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();
  const { deleteEvent } = useCalendarStore();
  const { errors } = useAppSelector((state) => state.calendar);
  const [customer, setCustomer] = useState<ContactDomain | null>(null);
  const { open, onClose, onOpen } = useDisclosure();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { events } = useAppSelector((state) => state.calendar);
  const [currentEvent, setCurrentEvent] =
    useState<CalendarBookingDomain | null>(null);

  useEffect(() => {
    setCurrentEvent(events.find((event) => event.id === bookingId) || null);
  }, [bookingId, events]);

  const [selectedOutcome, setSelectedOutcome] = useState<SelectOption | null>(
    currentEvent
      ? groupedOptions
          .flatMap((group) => group.options)
          .find((option) => option.value === currentEvent.outcome) || null
      : null
  );

  const [currentVehicle, setCurrentVehicle] = useState<null | VehicleDomain>(
    null
  );

  useEffect(() => {
    if (!currentEvent || !currentEvent.vehicleId || !open) {
      setCurrentVehicle(null);
      return;
    }

    MerchantService.getVehicle(
      auth0Context,
      merchant.groupId,
      currentEvent.vehicleId
    ).then((v) => setCurrentVehicle(v));
  }, [currentEvent?.vehicleId, open]);

  useEffect(() => {
    if (currentEvent?.customerId && open) {
      ContactsService.getContact(
        auth0Context,
        currentEvent.customerId,
        merchant.groupId
      ).then((contact) => {
        setCustomer(contact || null);
      });
    }

    setSelectedOutcome(
      currentEvent
        ? groupedOptions
            .flatMap((group) => group.options)
            .find((option) => option.value === currentEvent.outcome) || null
        : null
    );
  }, [currentEvent, open]);

  const [isSavingEvent, setIsSavingEvent] = useState<boolean>(false);

  const updateBooking = async () => {
    if (!currentEvent) {
      return;
    }

    setIsSavingEvent(true);

    try {
      const calendarResponse = await CalendarsService.updateCalendarEvent(
        auth0Context,
        {
          bookingId: currentEvent.id,
          startAt: currentEvent.startAt,
          endAt: currentEvent.endAt,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          title: currentEvent.title,
          description: currentEvent.description,
          vehicleId: currentEvent.vehicleId,
          agentIds: currentEvent.agentIds,
          customerId: currentEvent.customerId,
          outcome: selectedOutcome?.value || null,
        },
        merchant.id
      );

      setCurrentEvent(calendarResponse);
      setSelectedOutcome(
        groupedOptions
          .flatMap((group) => group.options)
          .find((option) => option.value === calendarResponse.outcome) || null
      );
      toaster.create({
        type: "success",
        title: "Booking updated successfully!",
      });
    } catch (err: any) {
      toaster.create({
        type: "error",
        title: "Oops. We couldn't update this event. Please try again!",
      });
      setSelectedOutcome(
        groupedOptions
          .flatMap((group) => group.options)
          .find((option) => option.value === currentEvent.outcome) || null
      );
    }

    setIsSavingEvent(false);
  };

  useEffect(() => {
    if (!currentEvent) {
      return;
    }

    if (selectedOutcome?.value === currentEvent.outcome) {
      return;
    }

    updateBooking();
  }, [selectedOutcome]);

  useEffect(() => {
    if (open) {
      return;
    }

    if (currentEvent) {
      dispatch(updateCalendarEventSuccess(currentEvent));
    }
  }, [open]);

  return (
    <PopoverRoot
      closeOnInteractOutside={true}
      closeOnEscape={true}
      onInteractOutside={(event: InteractOutsideEvent) => {
        const eventTarget = event.detail.originalEvent.target;

        if (eventTarget && eventTarget instanceof HTMLElement) {
          const isPartOfOutcomeSelect = eventTarget.closest(
            ".select-outcome-menu-list"
          );

          if (isPartOfOutcomeSelect) {
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }}
      open={open}
      lazyMount={true}
      unmountOnExit={false}
      positioning={{
        placement: isBaseSize ? "top" : "right",
        flip: true,
      }}
      modal={false}
      onOpenChange={({ open: newOpen }) => {
        if (newOpen) {
          onOpen();
        } else {
          onClose();
        }
      }}
    >
      <PopoverTrigger
        asChild // necessary to work with full Calendar
      >
        <VStack alignItems="start" justifyContent="start" w="100%" h="100%">
          <Text>{eventTitle}</Text>
        </VStack>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader
          display="flex"
          justifyContent="space-between"
          w="100%"
          border="none"
        >
          <HStack alignItems="center" justifyContent="start">
            <IconButton
              aria-label="Remove event"
              colorPalette="red"
              variant="ghost"
              size="xs"
              onClick={async () => {
                if (!currentEvent) {
                  return;
                }
                await deleteEvent({ bookingId: currentEvent.id });
                onClose();
                if (errors.length) {
                  toaster.create({ type: "error", title: errors[0] });
                } else {
                  toaster.create({
                    type: "success",
                    title: "Event deleted successfully!",
                  });
                }
              }}
            >
              <Icon as={LuTrash} boxSize={4} />
            </IconButton>
            <IconButton
              id="edit-event-button"
              variant="ghost"
              size="xs"
              aria-label="Edit event"
              colorPalette="gray"
              onClick={() => {
                onEditClick();
                onClose();
              }}
            >
              <Icon as={LuPencil} boxSize={4} />
            </IconButton>
          </HStack>
          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Close event popover"
            colorPalette="gray"
            onClick={onClose}
          >
            <Icon as={LuX} boxSize={4} />
          </IconButton>
        </PopoverHeader>
        <PopoverBody px={0} overflowY="auto">
          <PopoverTitle fontSize="2xl" fontWeight="bold" px={4}>
            {currentEvent?.title}
          </PopoverTitle>
          <VStack gap={2} alignItems="start" justifyContent="start">
            <Box px={2} w="100%">
              {!currentEvent ? null : (
                <Box w="100%" px={4} py={2}>
                  <Text
                    fontSize="xs"
                    fontWeight="hairline"
                    color={colorMode === "dark" ? "gray.500" : "gray.200"}
                  >
                    {format(parseISO(currentEvent.startAt), "d MMMM, h:mm a")} -{" "}
                    {format(parseISO(currentEvent.endAt), "d MMMM, h:mm a")}
                  </Text>
                  {currentEvent.startTimezone && (
                    <Text
                      color={colorMode === "dark" ? "gray.500" : "gray.200"}
                      fontSize="xs"
                      fontWeight="hairline"
                    >
                      Timezone: {currentEvent.startTimezone}
                    </Text>
                  )}
                  {currentEvent.description && (
                    <Text>{currentEvent.description}</Text>
                  )}
                </Box>
              )}
            </Box>

            {customer && (
              <Box px={4} w="100%">
                <Text fontWeight="bold">Customer</Text>
                <HStack mt={2} ml={4}>
                  <ProfileAvatar
                    profilePicture={customer.getPicture()}
                    name={customer.fullName}
                    showInitials={false}
                  />
                  <VStack alignItems="start" gap={0}>
                    <Text>{customer.getDisplayName()}</Text>
                    <Text>
                      {customer.getPhoneNum()
                        ? customer.getPhoneNum()
                        : customer.getEmailAddress()}
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            )}

            <Box w="100%">
              <AccordionRoot collapsible={true}>
                <AccordionItem value="Assigned Agents">
                  <AccordionItemTrigger px={4}>
                    <HStack justifyContent="space-between" w="100%">
                      <Text textAlign="left" fontWeight="bold">
                        Assigned{" "}
                        {(currentEvent?.agentIds.length || 0) === 1
                          ? "Agent"
                          : "Agents"}
                      </Text>
                      <AvatarGroup ml={4} size="sm" spaceX={-3}>
                        {currentEvent?.agentIds
                          .slice(0, 3)
                          .map((agent: number) => (
                            <Avatar
                              borderColor={
                                colorMode === "light" ? "white" : "gray.700"
                              }
                              src={agents
                                .find((a) => a.id === agent)!
                                .getPicture()}
                              name={agents
                                .find((a) => a.id === agent)!
                                .getFullName()}
                            />
                          ))}
                        {(currentEvent?.agentIds.length || 0) > 3 ? (
                          <ChakraAvatar.Root
                            variant="solid"
                            colorPalette="gray"
                            borderColor={
                              colorMode === "light" ? "white" : "gray.700"
                            }
                          >
                            <ChakraAvatar.Fallback>
                              {`+${(currentEvent?.agentIds.length || 0) - 3}`}
                            </ChakraAvatar.Fallback>
                          </ChakraAvatar.Root>
                        ) : null}
                      </AvatarGroup>
                    </HStack>
                  </AccordionItemTrigger>
                  <AccordionItemContent
                    pb={4}
                    display="flex"
                    flexDirection="column"
                    gridGap={2}
                  >
                    {currentEvent?.agentIds.map((agent: number) => (
                      <HStack key={agent} ml={4}>
                        <Avatar
                          size="sm"
                          src={agents.find((a) => a.id === agent)!.getPicture()}
                          name={agents
                            .find((a) => a.id === agent)!
                            .getFullName()}
                        />
                        <Text>
                          {agents.find((a) => a.id === agent)!.getFullName()}
                        </Text>
                      </HStack>
                    ))}
                  </AccordionItemContent>
                </AccordionItem>
                {currentVehicle ? (
                  <AccordionItem value="Vehicle">
                    <AccordionItemTrigger px={4}>
                      <HStack justifyContent="space-between" w="100%">
                        <Text textAlign="left" fontWeight="bold">
                          Vehicle
                        </Text>
                        <Avatar
                          size="sm"
                          borderColor={
                            colorMode === "light" ? "white" : "gray.700"
                          }
                          src={currentVehicle.getIconSource() || undefined}
                          fallback={<Icon as={LuCar} boxSize={4} />}
                        />
                      </HStack>
                    </AccordionItemTrigger>
                    <AccordionItemContent
                      pb={4}
                      display="flex"
                      flexDirection="column"
                      gridGap={2}
                    >
                      <VehicleInfo vehicle={currentVehicle} />
                    </AccordionItemContent>
                  </AccordionItem>
                ) : null}
              </AccordionRoot>
            </Box>

            <VStack alignItems="start" gap={2} px={4} w="100%">
              <Text fontWeight="bold">Outcome</Text>
              <Select
                id="event-outcome-selector"
                placeholder="Select Outcome"
                isClearable={true}
                value={selectedOutcome}
                isLoading={isSavingEvent}
                isMulti={false}
                menuPlacement="auto"
                menuPosition="absolute"
                menuPortalTarget={document.body}
                isDisabled={isSavingEvent}
                onChange={(newValue) =>
                  setSelectedOutcome(newValue as SingleValue<SelectOption>)
                }
                options={groupedOptions}
                menuShouldScrollIntoView={false}
                classNames={{
                  menuList: (_props) => "select-outcome-menu-list",
                }}
                styles={{
                  ...{
                    ...getReactSelectStyles(
                      (colorMode as "dark" | "light") || "light",
                      colorScheme
                    ),
                    container: (provided: any) => ({
                      ...provided,
                      width: "100%",
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 99999,
                      pointerEvents: "all",
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 99999,
                      pointerEvents: "all",
                    }),
                  },
                }}
              />
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default EventInfoPopover;
