import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Tooltip } from "components/ui/tooltip";
import ReviewDomain, {
  PrettyReviewStatus,
  ReviewStatus,
} from "entities/domain/reviews/review-domain";
import React, { useEffect, useState } from "react";
import { LuSend } from "react-icons/lu";
import { useOutletContext } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AssignToCustomerForm from "./AssignToCustomerForm";
import ReviewAvatar from "./ReviewAvatar";
import Rating from "./ReviewRatings";
import ReviewReplyCard from "./ReviewReplyCard";
import SendReply from "./SendReply";

interface ReviewPageProps {
  isOpen: boolean;
  onClose: () => void;
  review: ReviewDomain | undefined;
  sendReply: (content: string, reviewId: string) => void;
}

const ReviewPage = () => {
  const context = useOutletContext<ReviewPageProps>();
  const { isOpen, onClose, review, sendReply } = context;
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [showAssignToCustomerForm, setShowAssignToCustomerForm] =
    useState<boolean>(false);

  useEffect(() => {
    setShowAssignToCustomerForm(false);
  }, [review?.id]);

  return !review ? null : (
    <DialogRoot
      size={isBaseSize ? "full" : "xl"}
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader
          display="flex"
          alignItems="center"
          justifyContent="start"
          gridGap={6}
        >
          <ReviewAvatar isBig={true} review={review} />
          <VStack alignItems="start" gridGap={2} w="100%">
            {review.reviewer.customerId ? (
              <Link
                href={`/${merchant.id}/contacts/${review.reviewer.customerId}`}
                target="_blank"
              >
                <Heading display="block">
                  {review.reviewer.name || "Anonymous"}
                </Heading>
              </Link>
            ) : (
              <Heading display="block">
                {review.reviewer.name || "Anonymous"}
              </Heading>
            )}
            {showAssignToCustomerForm ||
            review.status === ReviewStatus.PENDING ? null : (
              <Button
                variant="subtle"
                colorPalette={colorScheme}
                size="xs"
                onClick={() => setShowAssignToCustomerForm(true)}
              >
                {!review.reviewer.customerId
                  ? "Assign Customer"
                  : "Reassign Customer"}
              </Button>
            )}
            {showAssignToCustomerForm ? (
              <AssignToCustomerForm
                review={review}
                onClose={() => setShowAssignToCustomerForm(false)}
              />
            ) : null}
          </VStack>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <Flex alignItems="center" justifyContent="space-between" gridGap={4}>
            <Flex alignItems="center" gridGap={4}>
              <Rating value={review.rating} />
              <Text fontSize="xs" color="gray.500">
                {review.displayDate}
              </Text>
            </Flex>
          </Flex>
          {review.content ? (
            <Box
              textAlign="center"
              overflow="hidden"
              my={4}
              bgColor={colorMode === "dark" ? "black" : "gray.50"}
              borderRadius="lg"
              p={8}
            >
              <Text overflowY="auto" maxHeight="200px" fontSize="lg">
                {review.content}
              </Text>
            </Box>
          ) : null}
          {review.replies.length > 0
            ? review.replies.map((r, i) => (
                <ReviewReplyCard reply={r} key={i.toString()} />
              ))
            : null}
        </DialogBody>
        <DialogFooter>
          {review.replies.length === 0 ? (
            <SendReply review={review} sendReply={sendReply} />
          ) : null}
          {review.reviewer.conversationId && review.replies.length > 0 ? (
            <Tooltip disabled={isBaseSize} content="Jump to conversation">
              <Link
                href={`/${merchant.id}/inbox/${review.reviewer.conversationId}`}
                target="_blank"
              >
                <IconButton
                  variant="solid"
                  size="md"
                  aria-label="Start conversation"
                  rounded="full"
                >
                  <Icon
                    as={LuSend}
                    color={colorMode === "dark" ? "black" : "white"}
                  />
                </IconButton>
              </Link>
            </Tooltip>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default ReviewPage;
