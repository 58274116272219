import { Box, useBreakpointValue } from "@chakra-ui/react";
import type {
  DatesSetArg,
  EventChangeArg,
  EventContentArg,
  PluginDef,
} from "@fullcalendar/core";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import { format, parseISO, subHours } from "date-fns";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import useCalendarStore from "hooks/use-calendar-store";
import React, {
  lazy,
  startTransition,
  Suspense,
  useEffect,
  useState,
} from "react";
import { UpdateCalendarEventPayload } from "redux/features/calendar";
import { cleanContactsToastMessages } from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import EditEventModal from "./EditEventModal";
import EventInfoPopover from "./EventInfoPopover";
import NewEventModal from "./NewEventModal";
import NotifyGuestModal from "./NotifyGuestModal";

const FullCalendar = lazy(() => import("@fullcalendar/react"));

const loadPlugins = async () => {
  const { default: dayGridPlugin } = await import("@fullcalendar/daygrid");
  const { default: interactionPlugin } = await import(
    "@fullcalendar/interaction"
  );
  const { default: timeGridPlugin } = await import("@fullcalendar/timegrid");

  return [dayGridPlugin, interactionPlugin, timeGridPlugin];
};

interface FullCalendarProps {}

export interface AgentOptionTypes {
  value: number;
  label: string;
  color: string;
}

interface ExtendedProps {
  booking: CalendarBookingDomain;
  agentId: number | null;
}
interface BookingEvent {
  title: string;
  start: string;
  end: string;
  extendedProps: ExtendedProps;
  backgroundColor: string;
}

const FullCalendarComponent = (_props: FullCalendarProps) => {
  const { fetchEvents, updateEvent } = useCalendarStore();
  const { events, errors, selectedAgentIds } = useAppSelector(
    (state) => state.calendar
  );

  const [fullCalendarPlugins, setFullCalendarPlugins] = useState<
    Array<PluginDef>
  >([]);

  useEffect(() => {
    startTransition(() => {
      loadPlugins().then(setFullCalendarPlugins);
    });
  }, []);
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent, agents } = useAppSelector((state) => state.agents);

  const { toastMessage: contactToastMessage, contacts } = useAppSelector(
    (state) => state.contacts
  );

  const [currentExtendedProps, setCurrentExtendedProps] =
    useState<ExtendedProps>();

  const [openEventForm, setOpenEventForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [showNotifyModal, setShowNotifyModal] = useState<boolean>(false);
  const [newEventStartDate, setNewEventStartDate] = useState<Date | null>(
    new Date()
  );
  const [newEventEndDate, setNewEventEndDate] = useState<Date | null>(
    new Date()
  );
  const [changedEventInfo, setChangedEventInfo] = useState<EventChangeArg>();
  const [highlightColor, setHighlightColor] = useState<string>(
    `var(--chakra-colors-${currentAgent!.color}-400)`
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const [dateInfo, setDateInfo] = useState<DatesSetArg>();

  const isBookingEventSelectedByAgent = (booking: BookingEvent): boolean => {
    if (selectedAgentIds.length === 0) {
      return true;
    }

    return (
      selectedAgentIds.filter(
        (a: number) => a === booking.extendedProps.agentId
      ).length !== 0
    );
  };

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const transformToEventsPerAgentId = (
    booking: CalendarBookingDomain,
    agentId: number | null
  ): BookingEvent => {
    const customer = contacts.find((c) => c.id === booking.customerId);
    return {
      title: `${booking.formatTitle()}${
        customer ? `, ${customer.getDisplayName()}` : ""
      }`,
      start: booking.startAt,
      end: booking.endAt,
      extendedProps: {
        booking,
        agentId,
      },
      backgroundColor: agentId
        ? `var(--chakra-colors-${
            agents.find((agent) => agent.id === agentId)?.color || colorScheme
          }-400)`
        : `var(--chakra-colors-${colorScheme}-400)`,
    };
  };

  const transformToEvents = (
    booking: CalendarBookingDomain
  ): BookingEvent[] => {
    if (booking.agentIds.length === 0) {
      return [transformToEventsPerAgentId(booking, null)];
    }

    return booking.agentIds.map((aId) =>
      transformToEventsPerAgentId(booking, aId)
    );
  };

  const showOpenNewEventForm = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setNewEventStartDate(startDate);
    setNewEventEndDate(endDate);
    setOpenEventForm(true);
  };

  const showEventDetails = (e: EventContentArg) => {
    if (
      currentExtendedProps?.agentId === e.event.extendedProps.agentId &&
      currentExtendedProps?.booking?.id === e.event.extendedProps.booking.id
    ) {
      setCurrentExtendedProps(undefined);
    } else {
      setCurrentExtendedProps(e.event.extendedProps as ExtendedProps);
    }
  };

  const changeBookingTime = (
    newBooking: CalendarBookingDomain,
    newStart: Date | null,
    newEnd: Date | null,
    notifyCustomer: boolean
  ) => {
    const startAt = newStart || parseISO(newBooking.startAt);
    const endAt = newEnd || parseISO(newBooking.endAt);
    const payload: UpdateCalendarEventPayload = {
      bookingId: newBooking.id,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title: newBooking.formatTitle(),
      vehicleId: newBooking.vehicleId,
      description: newBooking.description,
      customerId: newBooking.customerId,
      agentIds: newBooking.agentIds,
      notifyCustomer,
      outcome: newBooking.outcome,
    };
    updateEvent(payload);
    if (errors.length) {
      toaster.create({ type: "error", title: errors[0] });
    } else {
      toaster.create({ type: "success", title: "Event updated successfully!" });
    }
    setShowNotifyModal(false);
  };

  const notifyCustomerSubmit = () => {
    setShowNotifyModal(false);
    changeBookingTime(
      changedEventInfo?.event.extendedProps.booking,
      changedEventInfo?.event.start || null,
      changedEventInfo?.event.end || null,
      true
    );
  };

  const dontNotifyCustomerSubmit = (eventInfo?: EventChangeArg) => {
    setShowNotifyModal(false);
    const event = eventInfo?.event || changedEventInfo?.event;
    changeBookingTime(
      event?.extendedProps.booking,
      event?.start || null,
      event?.end || null,
      false
    );
  };

  // rempove this shit??
  useEffect(() => {
    if (contactToastMessage.new) {
      if (contactToastMessage.success) {
        toaster.create({ type: "success", title: contactToastMessage.success });
      } else if (contactToastMessage.errors) {
        toaster.create({ type: "error", title: contactToastMessage.errors[0] });
      }
      dispatch(cleanContactsToastMessages());
    }
  }, [contactToastMessage]);

  useEffect(() => {
    if (!dateInfo) {
      return;
    }

    fetchEvents({
      startAt: dateInfo.start.toISOString(),
      endAt: dateInfo.end.toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    setHighlightColor(
      dateInfo.view.type === "dayGridMonth"
        ? "var(--chakra-colors-white)"
        : currentAgent!.color
    );
  }, [merchant.id]);

  return (
    <Box
      css={{
        pt: isBaseSize ? 0 : "1rem",
        "& .chakra-popover__popper": {
          marginTop: "250px",
          marginLeft: "400px",
        },
        "& .fc-toolbar": {
          px: "1rem",
        },
        "& .fc-event-main": {
          overflow: "hidden",
        },
        "& .fc-toolbar-title": {
          marginLeft: "0!important",
        },
        "& .fc-day-today": {
          backgroundColor: isBaseSize
            ? "transparent!important"
            : `var(--chakra-colors-${colorScheme}-${
                colorMode === "dark" ? "900" : "100"
              })!important`,
        },
        "& button.fc-today-button": {
          borderRadius: 9999,
          borderWidth: 0,
          backgroundColor: `var(--chakra-colors-green-${
            colorMode === "dark" ? "200" : "500"
          })!important`,
          color: `${colorMode === "dark" ? "black" : "white"}!important`,
          textTransform: "capitalize",
          fontSize: "sm",
          padding: "0.5rem 1rem",
        },
        "& button.fc-prev-button, button.fc-next-button": {
          background: "none !important",
          border: "none",
          "& .fc-icon": {
            color: `${colorMode === "dark" ? "white" : "black"}!important`,
          },
        },
        "& .fc-next-button": {
          marginLeft: "0!important",
        },
        "& .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-timeGridFourDay-button":
          {
            borderRadius: 9999,
            borderWidth: 0,
            backgroundColor: `var(--chakra-colors-gray-${
              colorMode === "dark" ? "300" : "500"
            })!important`,
            color: `${colorMode === "dark" ? "black" : "white"}!important`,
            textTransform: "capitalize",
            fontSize: "sm",
            padding: "0.5rem 1rem",
          },
        "& .fc-button-active": {
          color: `${colorMode === "dark" ? "black" : "white"}!important`,
          backgroundColor: `var(--chakra-colors-${colorScheme}-${
            colorMode === "dark" ? "200" : "500"
          })!important`,
        },
        "& .fc-toolbar-chunk": {
          display: "flex",
          alignItems: "center",
        },
        "& button.fc-prev-button .fc-icon, button.fc-next-button .fc-icon": {
          color: "darkgrey",
        },
        "& .fc-scrollgrid": {
          border: "none",
          borderTop: `1px solid ${
            colorMode === "dark" ? "white" : "var(--chakra-colors-gray-200)"
          }`,
          overflow: "hidden",
        },
        "& span.fc-scrollgrid-sync-inner": {
          textTransform: "capitalize",
        },
        "& .fc-col-header-cell-cushion": {
          fontSize: "sm",
          color: "darkgrey",
          textTransform: "uppercase",
        },
        "& .fc .fc-timegrid-slot-minor": {
          border: "none",
        },
        "& .fc-timegrid-slot-label-cushion, span.fc-timegrid-axis-cushion": {
          color: "darkgrey",
          fontSize: "sm",
          fontWeight: "bold",
        },
        "& .fc-timegrid-event, .fc-daygrid-event": {
          border: "none",
        },
        "& .fc-event": {
          fontWeight: "semibold",
        },
        "& .fc-highlight": {
          backgroundColor: `${highlightColor}!important`,
          opacity: 0.5,
          borderRadius: "0.5rem",
        },
        "& .fc-media-screen": {
          height: "100%",
          display: "flex",
        },
        "@media screen and (max-width: 1024px)": {
          "& .fc-header-toolbar": {
            flexWrap: "wrap",
            justifyContent: "center",
            gridGap: "1rem",
          },
          "& .fc-toolbar-chunk:nth-child(2)": {
            display: "none",
          },
          "& .fc .fc-toolbar-title": {
            fontSize: "md!important",
          },
          "& button.fc-today-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-timeGridFourDay-button":
            {
              padding: "0.25rem 0.5rem",
              fontSize: "xs",
            },
          "& .fc-col-header-cell-cushion, .fc-timegrid-slot-label-cushion, .fc-daygrid-day-number":
            {
              fontSize: "xs",
            },
          "& .fc-daygrid-event": {
            fontSize: "xs",
          },
          "& .fc-event-title-container": {
            fontSize: "xs",
          },
          "& .chakra-popover__popper": {
            marginTop: "250px",
            marginLeft: "55px",
          },
        },
      }}
      h="100%"
      w="100%"
      display="flex"
      flexDir="column"
    >
      <Box h="100%" w="100%" overflowY="auto">
        <Box h="100%" w="100%">
          {openEventForm && (
            <NewEventModal
              startDate={newEventStartDate}
              endDate={newEventEndDate}
              openEventForm={openEventForm}
              closeNewEventForm={() => setOpenEventForm(false)}
            />
          )}
          {currentExtendedProps && openEditForm && (
            <EditEventModal
              closeNewEventForm={() => {
                setOpenEditForm(false);
                setCurrentExtendedProps(undefined);
              }}
              currentEvent={
                events.find((e) => e.id === currentExtendedProps?.booking.id)!
              }
            />
          )}
          {showNotifyModal && (
            <NotifyGuestModal
              showNotifyModal={showNotifyModal}
              closeNotifyModal={() => setShowNotifyModal(false)}
              onDontNotifyCustomer={dontNotifyCustomerSubmit}
              onNotifyCustomer={notifyCustomerSubmit}
            />
          )}
          <Suspense fallback={null}>
            {fullCalendarPlugins.length === 0 ? null : (
              <FullCalendar
                headerToolbar={{
                  left: isBaseSize
                    ? "prev title next"
                    : "today prev title next",
                  right: isBaseSize
                    ? "today timeGridDay timeGridFourDay timeGridWeek dayGridMonth"
                    : "timeGridDay timeGridFourDay timeGridWeek dayGridMonth",
                }}
                views={{
                  dayGridMonth: {
                    dayHeaderFormat: { weekday: "short" },
                    eventTimeFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                  },
                  timeGridFourDay: {
                    eventTimeFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                    type: "timeGrid",
                    duration: { days: 7 },
                    buttonText: "Weekday",
                    weekends: false,
                    dateAlignment: "week",
                    titleFormat: {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    },
                    slotLabelFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                    dayHeaderFormat: { weekday: "short", day: "numeric" },
                  },
                  timeGridWeek: {
                    titleFormat: {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    },
                    eventTimeFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                    slotLabelFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                    dayHeaderFormat: { day: "numeric", weekday: "short" },
                  },
                  timeGridDay: {
                    eventTimeFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                    dayHeaders: false,
                    slotLabelFormat: {
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: true,
                    },
                  },
                }}
                contentHeight="100%"
                plugins={fullCalendarPlugins}
                datesSet={(newDateInfo) => {
                  setDateInfo(newDateInfo);
                  fetchEvents({
                    startAt: newDateInfo.start.toISOString(),
                    endAt: newDateInfo.end.toISOString(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  });
                  setHighlightColor(
                    newDateInfo.view.type === "dayGridMonth"
                      ? "var(--chakra-colors-white)"
                      : `var(--chakra-colors-${currentAgent!.color}-400)`
                  );
                }}
                initialView={isBaseSize ? "timeGridDay" : "timeGridWeek"}
                nowIndicator={true}
                editable={true}
                select={(e) => {
                  showOpenNewEventForm(e.start, e.end);
                }}
                eventContent={(e) => {
                  return (
                    <EventInfoPopover
                      key={e.event.extendedProps.booking.id}
                      eventTitle={`${e.timeText} - ${e.event.title}`}
                      bookingId={e.event.extendedProps.booking.id}
                      onEditClick={() => {
                        showEventDetails(e);
                        setOpenEditForm(true);
                      }}
                    />
                  );
                }}
                locale="en"
                selectable={true}
                events={events
                  .flatMap(transformToEvents)
                  .filter(isBookingEventSelectedByAgent)}
                scrollTime={format(subHours(new Date(), 1), "HH:mm")}
                eventChange={(e) => {
                  if (e.oldEvent.extendedProps.booking.notifyChannelSelected) {
                    setChangedEventInfo(e);
                    setShowNotifyModal(true);
                  } else {
                    dontNotifyCustomerSubmit(e);
                  }
                }}
              />
            )}
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default FullCalendarComponent;
