import { Button, ButtonGroup, Icon, VStack } from "@chakra-ui/react";
import { AudienceCriteria } from "entities/domain/audience";
import {
  Condition,
  LogicalOperation,
  LogicalOperationType,
} from "entities/domain/criteria";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { LuEraser } from "react-icons/lu";
import { GroupConfig } from "./QueryCondition";
import QueryOperation, {
  canAddMoreGroups,
  getOperationGroup,
} from "./QueryOperation";

interface QueryBuilderProps {
  entityNamePlural: string;
  criteria: AudienceCriteria | null;
  size?: "sm" | "md";
  groups: GroupConfig[];
  setCriteria: (criteria: AudienceCriteria) => void;
  isDisabled?: boolean;
  disabledFields?: string[];
}

const QueryBuilder = ({
  entityNamePlural,
  disabledFields = [],
  criteria,
  groups,
  size = "md",
  isDisabled = false,
  setCriteria,
}: QueryBuilderProps) => {
  const defaultOperations = [
    {
      operation: LogicalOperationType.AND,
      conditions: [
        {
          key: groups[0].fields[0].value,
          type: groups[0].fields[0].possibleConditions[0],
          value: "",
        } as Condition,
      ],
    },
  ] as LogicalOperation[];

  const [operations, setOperations] = useState<LogicalOperation[]>(
    criteria
      ? JSON.parse(JSON.stringify(criteria.conditions))
      : JSON.parse(JSON.stringify(defaultOperations))
  );

  useEffect(() => {
    if (!criteria) {
      setOperations(JSON.parse(JSON.stringify(defaultOperations)));

      return;
    }

    if (JSON.stringify(criteria.conditions) === JSON.stringify(operations)) {
      return;
    }

    setOperations(JSON.parse(JSON.stringify(criteria.conditions)));
  }, [criteria]);

  const [topLevelOperation, setTopLevelOperation] =
    useState<LogicalOperationType>(
      criteria?.operation || LogicalOperationType.AND
    );

  useEffect(() => {
    const newOperations: LogicalOperation[] = [];

    groups.forEach((group) => {
      const groupOperations = operations.filter(
        (operation) => getOperationGroup(operation).name === group.name
      );

      const groupLimit = group.limits[topLevelOperation];

      if (groupOperations.length > groupLimit) {
        newOperations.push(...groupOperations.slice(0, groupLimit));
      } else {
        newOperations.push(...groupOperations);
      }
    });

    if (JSON.stringify(newOperations) !== JSON.stringify(operations)) {
      setOperations(newOperations);
    }

    setCriteria({
      operation: topLevelOperation,
      conditions: newOperations as unknown as Array<{
        operation: LogicalOperationType;
        conditions: Array<Condition>;
      }>,
    });
  }, [operations, topLevelOperation]);

  return (
    <VStack alignItems="start" width="100%" gap={size === "sm" ? 0 : 2}>
      {isDisabled ? null : (
        <Button
          size="xs"
          colorPalette="gray"
          variant="outline"
          disabled={
            JSON.stringify(operations) === JSON.stringify(defaultOperations)
          }
          onClick={() =>
            setOperations(JSON.parse(JSON.stringify(defaultOperations)))
          }
        >
          <Icon as={LuEraser} /> Reset
        </Button>
      )}
      {operations.map((operation, index) => (
        <QueryOperation
          key={JSON.stringify(operation)}
          disabledFields={disabledFields}
          size={size}
          operation={operation}
          index={index}
          entityNamePlural={entityNamePlural}
          isDisabled={isDisabled}
          groups={groups}
          topLevelOperation={topLevelOperation}
          setTopLevelOperation={setTopLevelOperation}
          setOperations={setOperations}
          operations={operations}
        />
      ))}
      {isDisabled ? null : (
        <ButtonGroup mt={4} size="xs" attached colorPalette="gray">
          {groups.map((group) => (
            <Button
              id={`add-${group.name}-group`}
              key={JSON.stringify(group)}
              css={{
                "> span": {
                  marginRight: "0.25rem",
                },
              }}
              onClick={() => {
                setOperations([
                  ...operations,
                  {
                    operation: LogicalOperationType.AND,
                    conditions: [
                      {
                        key: group.fields[0].value,
                        type: group.fields[0].possibleConditions[0],
                        value: "",
                      } as Condition,
                    ],
                  },
                ]);
              }}
              disabled={
                // by default top level operation is AND but it shouldn't matter
                // if we have only 1 operation
                operations.length === 1
                  ? !(
                      canAddMoreGroups(
                        operations,
                        group,
                        LogicalOperationType.AND
                      ) ||
                      canAddMoreGroups(
                        operations,
                        group,
                        LogicalOperationType.OR
                      )
                    )
                  : !canAddMoreGroups(operations, group, topLevelOperation)
              }
            >
              <Icon as={FiPlus} /> {group.name}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </VStack>
  );
};

export default QueryBuilder;
