import { Link, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import FullTemplateDomain from "entities/domain/templates/full_template";
import WhatsappTemplate, {
  WabaTemplateStatus,
} from "entities/domain/whatsapp-template";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface ChooseTemplateProps {
  channel: ConversationChannel;
  addEmptyOption?: boolean;
  selectedTemplateId: string | null;
  onTemplateSelect: (t: RegularOrWhatsappTemplate | null) => void;
}

interface TemplateOptionType {
  value: string;
  label: string;
}

const ChooseTemplate = ({
  channel,
  addEmptyOption = false,
  selectedTemplateId,
  onTemplateSelect,
}: ChooseTemplateProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { fetchTemplates } = useTemplatesStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { templates } = useAppSelector((state) => state.templates);

  useEffect(() => {
    fetchTemplates(undefined, channel !== ConversationChannel.WHATSAPP);
  }, [channel]);

  const templateOptions: TemplateOptionType[] = templates
    .filter((t) => {
      if (t instanceof WhatsappTemplate) {
        return t.status === WabaTemplateStatus.APPROVED;
      }

      return t;
    })
    .map((t) => {
      if (t instanceof WhatsappTemplate) {
        return {
          value: t.id,
          label: t.name,
        };
      }
      return {
        value: t.id,
        label: t.title,
      } as TemplateOptionType;
    });

  const getTemplateOption = (
    templateId: string | null
  ): TemplateOptionType | undefined => {
    if (!templateId) {
      return;
    }

    const template = templates.find((t) => t.id === templateId);

    if (!template) {
      return;
    }

    if (template instanceof WhatsappTemplate) {
      return {
        value: template.id,
        label: template.name,
      };
    }

    return {
      value: template.id,
      label: template.title,
    };
  };

  const [chosenTemplateValue, setChosenTemplateValue] = useState<
    SingleValue<TemplateOptionType> | undefined
  >(getTemplateOption(selectedTemplateId));

  const handleChosenTemplateValueChange = (
    e: SingleValue<TemplateOptionType>
  ) => {
    onTemplateSelect(templates.find((t) => t.id === e?.value) || null);
  };

  const [chosenTemplate, setChosenTemplate] = useState<
    RegularOrWhatsappTemplate | undefined
  >(templates.find((t) => t.id === selectedTemplateId));

  useEffect(() => {
    setChosenTemplateValue(getTemplateOption(selectedTemplateId));

    const template = templates.find((t) => t.id === selectedTemplateId);

    setChosenTemplate(template);
  }, [selectedTemplateId, templates]);

  return (
    <Field
      label="Choose one of your pre-approved templates"
      helperText={
        chosenTemplate && chosenTemplate instanceof FullTemplateDomain ? (
          <Text>
            To change the text of the template please press
            <Link
              ml={1}
              color={colorMode === "dark" ? "blue.200" : "blue.500"}
              href={`/${merchant.id}/settings/templates/${chosenTemplate.id}`}
            >
              here
            </Link>
          </Text>
        ) : (
          <Text>
            Can&apos;t find a suitable template? Create one by pressing
            <Link
              ml={1}
              color={colorMode === "dark" ? "blue.200" : "blue.500"}
              href={`/${merchant.id}/settings/templates/`}
            >
              here
            </Link>
          </Text>
        )
      }
    >
      <Select
        id="choose-template-select"
        placeholder="Choose template"
        value={chosenTemplateValue}
        onChange={handleChosenTemplateValueChange}
        isClearable={addEmptyOption}
        options={templateOptions}
        styles={{
          ...{
            ...getReactSelectStyles(colorMode || "light", colorScheme),
            container: (provided: any) => ({
              ...provided,
              width: "100%",
            }),
          },
        }}
      />
    </Field>
  );
};

export default ChooseTemplate;
