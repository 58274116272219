import { Button, Fieldset, Flex, HStack, Input, Text } from "@chakra-ui/react";
import PhoneInputWithSuspense from "components/shared/LazyPhoneInput";
import { MultipleWarnings } from "components/shared/WarningTextComponent";
import FuzeyDropdown from "components/shared/dropdown";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { Radio, RadioGroup } from "components/ui/radio";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface ChannelFormProps {
  isNew: boolean;
  existingChannels: CustomerChannelDomain[];
  onCancel: () => void;
  warnings?: MultipleWarnings;
  channel?: CustomerChannelDomain;
  enabledChannelTypes: ConversationChannel[];
  setChannel: (updatedChannel: CustomerChannelDomain) => void;
}

interface ChannelHandleByTypeProps {
  isActive: boolean;
  type: string;
  handle: string;
  autoSuggestion: string | undefined;
  warnings?: MultipleWarnings;
  setHandle: (updatedHandle: string) => void;
}

const ChannelHandleByType = ({
  isActive,
  autoSuggestion,
  type,
  handle,
  warnings,
  setHandle,
}: ChannelHandleByTypeProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);

  useEffect(() => {
    if (autoSuggestion && !handle) {
      setHandle(autoSuggestion);
    }
  }, [autoSuggestion]);

  if (type === "email") {
    return (
      <Input
        disabled={!isActive}
        colorPalette={colorScheme}
        data-testid="contact-page-email-input"
        borderRadius="full"
        placeholder="Email address"
        value={handle}
        borderColor={
          warnings?.invalid_email || !handle
            ? colorMode === "dark"
              ? "pink.200"
              : "pink.500"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.500`
        }
        onChange={(e) => {
          setHandle(e.target.value);
        }}
      />
    );
  }

  if (type === "sms" || type === "whatsapp") {
    return (
      <PhoneInputWithSuspense
        id="contact-page-phone-input"
        placeholder="Phone number"
        defaultCountry={merchant.getMerchantLocation()}
        value={handle || ""}
        label=""
        disabled={!isActive}
        onChange={(newNumber: string | undefined) => {
          setHandle(newNumber || "");
        }}
        borderColor={
          warnings?.invalid_phone_number || !handle
            ? colorMode === "dark"
              ? "pink.200"
              : "pink.500"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.500`
        }
      />
    );
  }

  return <Text>{handle}</Text>;
};

const hasMarketingStatus = (channelType: string) => {
  return channelType === "sms" || channelType === "whatsapp";
};

const ChannelForm = ({
  isNew,
  warnings,
  channel,
  existingChannels,
  enabledChannelTypes,
  setChannel,
  onCancel,
}: ChannelFormProps) => {
  if (!channel) {
    return null;
  }

  const { merchant } = useAppSelector((state) => state.merchant);

  const MARKETING_STATUS_DESCRIPTIONS: { [key: string]: string } = {
    Subscribed: `By selecting "Subscribed", you confirm that there is lawful basis for sending marketing related messages to this contact from ${
      merchant.name || "you"
    }`,
    Unsubscribed: `By selecting "Unsubscribed", you are indicating that this contact have explicitly unsubscribed from receiving marketing related messages from ${
      merchant.name || "you"
    }`,
  };

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const channelTypeOptions: { value: string; label: string }[] = [
    { value: "sms", label: "SMS" },
    { value: "whatsapp", label: "WhatsApp" },
    { value: "email", label: "Email" },
  ].filter((option) =>
    enabledChannelTypes.includes(option.value as ConversationChannel)
  );

  const [channelToEdit, setChannelToEdit] =
    useState<CustomerChannelDomain>(channel);

  useEffect(() => {
    setChannelToEdit(channel);
  }, [channel]);

  const [autoSuggestion, setAutoSuggestion] = useState<string | undefined>();

  useEffect(() => {
    setAutoSuggestion(
      [...existingChannels].reverse().find((c) => c.type === channelToEdit.type)
        ?.displayHandle
    );
  }, [existingChannels, channelToEdit.type]);

  return (
    <Fieldset.Root
      w="100%"
      gap={4}
      p={4}
      backgroundColor={colorMode === "dark" ? "#000" : "#fff"}
      borderRadius="lg"
    >
      <Fieldset.Content>
        <Field>
          <FuzeyDropdown
            placeholder="Choose Channel Type"
            isDisabled={!isNew}
            setSelectedValue={(channelType) => {
              setChannelToEdit(
                Object.setPrototypeOf(
                  {
                    ...channelToEdit,
                    type: channelType,
                  },
                  CustomerChannelDomain.prototype
                )
              );
            }}
            width="100%"
            isMulti={false}
            styles={{
              ...getReactSelectStyles(colorMode || "light", colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: "100%",
              }),
            }}
            controlShouldRenderValue={true}
            isClearable
            closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
            isSetOnSelect
            borderColor={`${colorScheme}.400`}
            optionFontColor="var(--chakra-colors-gray-700)"
            options={channelTypeOptions}
            value={channelTypeOptions.filter(
              (option) => option.value === channelToEdit.type
            )}
            control={{ height: "40px" }}
            id="contact-page-channel-type-dropdown"
            zIndex={3}
          />
        </Field>
        {channelToEdit.type && (
          <Field>
            <ChannelHandleByType
              isActive={channelToEdit.isActive}
              autoSuggestion={autoSuggestion}
              warnings={warnings}
              type={channelToEdit.type}
              handle={channelToEdit.handle}
              setHandle={(updatedHandle) => {
                setChannelToEdit(
                  Object.setPrototypeOf(
                    {
                      ...channelToEdit,
                      handle: updatedHandle,
                      displayHandle: updatedHandle,
                    },
                    CustomerChannelDomain.prototype
                  )
                );
              }}
            />
          </Field>
        )}
        {hasMarketingStatus(channelToEdit.type) && (
          <Field>
            <RadioGroup
              onValueChange={({ value: newValue }) => {
                setChannelToEdit(
                  Object.setPrototypeOf(
                    {
                      ...channelToEdit,
                      isSubscribed: newValue === "subscribed",
                    },
                    CustomerChannelDomain.prototype
                  )
                );
              }}
              value={channelToEdit.isSubscribed ? "subscribed" : "unsubscribed"}
            >
              <HStack px={2}>
                <Radio value="subscribed">Subscribed</Radio>
                <Radio value="unsubscribed">Unsubscribed</Radio>
              </HStack>
            </RadioGroup>
            {MARKETING_STATUS_DESCRIPTIONS[
              channelToEdit.isSubscribed ? "Subscribed" : "Unsubscribed"
            ] && (
              <Text my={3} px={3} fontSize="12px">
                {
                  MARKETING_STATUS_DESCRIPTIONS[
                    channelToEdit.isSubscribed ? "Subscribed" : "Unsubscribed"
                  ]
                }
              </Text>
            )}
          </Field>
        )}
      </Fieldset.Content>
      <Flex mt={4} w="100%" justifyContent="space-between" alignItems="center">
        <Button
          colorPalette={colorScheme}
          variant="ghost"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          colorPalette={colorScheme}
          onClick={() => {
            setChannel(channelToEdit);
          }}
          disabled={
            !channelToEdit.handle || !channelToEdit.displayHandle || false
          }
        >
          Save
        </Button>
      </Flex>
    </Fieldset.Root>
  );
};

export default ChannelForm;
