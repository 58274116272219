import { Icon, IconButton, Spinner, SpinnerProps } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { LuSmile } from "react-icons/lu";

const LazyPicker = React.lazy(() => import("@emoji-mart/react"));

interface EmojiPickerProps extends React.ComponentProps<typeof LazyPicker> {
  spinnerSize?: SpinnerProps["size"];
}

const EmojiPickerWithSuspense: React.FC<EmojiPickerProps> = ({
  spinnerSize = "sm",
  ...props
}) => {
  return (
    <Suspense
      fallback={
        <IconButton
          disabled={true}
          colorScheme="yellow"
          variant="ghost"
          size="xs"
        >
          <Icon as={LuSmile} width="1rem" height="1rem" />
        </IconButton>
      }
    >
      <LazyPicker {...props} />
    </Suspense>
  );
};

export default EmojiPickerWithSuspense;
