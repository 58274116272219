import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Box, Button, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import TeamDomain from "entities/domain/team";
import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import useConversationsStore from "hooks/use-conversations-store";
import React, { useEffect, useState } from "react";
import {
  LuMessageSquare,
  LuSquarePlus,
  LuUserRoundCheck,
  LuUserRoundX,
} from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { setTeams } from "redux/features/agents";
import {
  ConversationTab,
  clearSelectedConversations,
  fetchConversations,
  setActiveConversationTab,
  setSearchText,
  setSelectedInbox,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { canManageTeamInbox, canManageUnassignedInbox } from "util/permissions";
import AddTeamModal from "../shared/AddTeamModal";
import Conversations from "./ConversationsArea";
import useRedirectUnauthorized from "hooks/use-redirect-unauthorized";
import { AxiosError } from "axios";

const getActiveTabIndex = (
  tabName: ConversationTab | undefined
): number | undefined => {
  switch (tabName) {
    case ConversationTab.Personal:
      return 1;
    case ConversationTab.Team:
      return 0;
    case ConversationTab.Unassigned:
      return 2;
    default:
      return undefined;
  }
};

const getTabName = (index: number): ConversationTab => {
  switch (index) {
    case 0:
      return ConversationTab.Team;
    case 1:
      return ConversationTab.Personal;
    case 2:
      return ConversationTab.Unassigned;
    default:
      throw new Error(`Invalid tab (index: ${index}) has been chosen`);
  }
};

const getUnreadTotalForTeam = (
  teamId: string,
  unreadCount?: UnreadCountDTO
): number => {
  if (!unreadCount || !unreadCount.teams || !unreadCount.teams[teamId]) {
    return 0;
  }

  const unreadCountTotal =
    unreadCount.teams[teamId].personal +
    unreadCount.teams[teamId].shared +
    unreadCount.teams[teamId].unassigned;

  return unreadCountTotal;
};

interface DesktopTabsAreaProps {}

const DesktopTabsArea = (_props: DesktopTabsAreaProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { getConversations, getConversation } = useConversationsStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { agents, currentAgent, teams, unreadCounts } = useAppSelector(
    (state) => state.agents
  );
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);
  const [currentSearchParameters, setSearchParams] = useSearchParams();
  const {
    activeTab,
    searchText,
    filterAgents,
    filterChannels,
    activeConversationId: conversationId,
    filterCustomerTagIds,
    isOpenOrClosed,
    selectedInbox,
  } = useAppSelector((state) => state.conversations);
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { redirectToInbox } = useRedirectUnauthorized();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [showAddTeamModal, setShowAddTeamModal] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(""));
      dispatch(setSelectedInbox(null));
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const tab = queryParams.get("tab") as ConversationTab | null;
    const teamInbox = queryParams.get("team") as string | null;

    if (!tab) {
      const isSingleAgent = agents.length === 1;
      const isManager = canManageTeamInbox(merchant.id, currentAgent!);
      const shouldShowAllInOneTab = isManager && isSingleAgent;

      if (shouldShowAllInOneTab) {
        dispatch(setActiveConversationTab(ConversationTab.Team));
      } else {
        dispatch(setActiveConversationTab(ConversationTab.Personal));
      }
    } else if (tab !== activeTab) {
      dispatch(setActiveConversationTab(tab || undefined));
    }

    if (teamInbox !== selectedInbox) {
      dispatch(setSelectedInbox(teamInbox));
    }
  }, [
    agents,
    currentAgent,
    merchant,
    currentSearchParameters,
    activeConversationId,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const tab = queryParams.get("tab") as ConversationTab | null;

    if (activeTab && !tab) {
      setSearchParams({
        ...Object.fromEntries(currentSearchParameters.entries()),
        tab: activeTab,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const teamInbox = queryParams.get("team") as string | null;

    if (selectedInbox && !teamInbox) {
      setSearchParams({
        ...Object.fromEntries(currentSearchParameters.entries()),
        team: selectedInbox,
      });
    }
  }, [selectedInbox]);

  const fetchFirstPageOfConversations = async () => {
    try {
      const newFirstPage = await getConversations(0);
      const filterResult = newFirstPage?.filter(
        (conv) => conv.id === conversationId
      );

      if (filterResult?.length === 0 && conversationId) {
        const newActiveConversation = await getConversation(
          conversationId,
          currentAgent!.id
        );

        if (!newActiveConversation) {
          redirectToInbox("You are not authorized to view this conversation");
        }
      }
    } catch (error: unknown) {
      let errorMessage: string | undefined;

      if (error instanceof AxiosError) {
        if (error.response?.status !== 403) {
          errorMessage = `Failed to fetch conversations: ${
            error.response?.data.message ||
            error.response?.data.code ||
            error.message ||
            "Unknown error"
          }`;
        }
      } else {
        errorMessage = `Failed to fetch conversations: ${error}`;
      }

      redirectToInbox(errorMessage);
    }
  };

  useEffect(() => {
    if (activeTab) {
      dispatch(clearSelectedConversations());
      fetchFirstPageOfConversations();
    }
  }, [
    searchText,
    filterChannels,
    filterAgents,
    filterCustomerTagIds,
    isOpenOrClosed,
    activeTab,
    selectedInbox,
  ]);

  const [expandedItems, setExpandedItems] = useState<string[]>(["0", "1"]);

  useEffect(() => {
    AgentsService.getTeams(auth0Context, merchant.id)
      .then((allTeams) => {
        dispatch(setTeams(allTeams));
      })
      .catch(() => {});
  }, [merchant.id]);

  if (!activeTab) {
    return null;
  }

  const activeTabIndex = getActiveTabIndex(activeTab);

  if (agents.length > 1) {
    return (
      <Flex
        direction="row"
        overflow="hidden"
        height="100%"
        width="100%"
        minWidth="100%"
        alignItems="start"
      >
        <VStack
          width="12rem"
          minWidth="12rem"
          maxWidth="12rem"
          alignItems="start"
          height="100%"
          overflowY="auto"
        >
          <AccordionRoot
            collapsible={true}
            w="100%"
            multiple={true}
            value={expandedItems}
            onValueChange={({ value: newValue }) => {
              setExpandedItems(newValue);
            }}
          >
            <AccordionItem value="0" border="none">
              <AccordionItemTrigger
                pl={2}
                alignItems="center"
                justifyContent="start"
                _hover={{
                  bg: "inherit",
                }}
                gridGap={1}
                flexDirection="row-reverse"
              >
                <Text
                  color={colorMode === "light" ? "gray.600" : "gray.300"}
                  textAlign="left"
                  w="100%"
                  fontWeight="bold"
                >
                  General
                </Text>
              </AccordionItemTrigger>
              <AccordionItemContent
                p={0}
                pr={2}
                display="flex"
                flexDirection="column"
                gridGap={1}
              >
                {canManageTeamInbox(merchant.id, currentAgent!) ? (
                  <Button
                    pl={7}
                    colorPalette={colorScheme}
                    w="100%"
                    borderLeftRadius={0}
                    variant={
                      activeTabIndex === 0 && !selectedInbox
                        ? "subtle"
                        : "ghost"
                    }
                    justifyContent="start"
                    alignItems="center"
                    onClick={() => {
                      if (
                        !selectedInbox &&
                        activeTab === ConversationTab.Team
                      ) {
                        return;
                      }

                      currentSearchParameters.delete("team");
                      dispatch(fetchConversations());
                      setSearchParams({
                        ...Object.fromEntries(
                          currentSearchParameters.entries()
                        ),
                        tab: getTabName(0),
                      });
                    }}
                  >
                    <Icon
                      as={LuMessageSquare}
                      mr={2}
                      width="1.35rem"
                      height="1.35rem"
                      color={
                        activeTabIndex === 0 && !selectedInbox
                          ? colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.600`
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.600"
                      }
                    />
                    <Text
                      textAlign="left"
                      w="100%"
                      color={
                        activeTabIndex === 0 && !selectedInbox
                          ? colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.600`
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.600"
                      }
                    >
                      All
                    </Text>
                    {unreadCount?.shared ? (
                      <Badge ml={1} colorPalette="red">
                        {unreadCount.shared > 100 ? "100+" : unreadCount.shared}
                      </Badge>
                    ) : null}
                  </Button>
                ) : null}
                <Button
                  pl={7}
                  variant={
                    activeTabIndex === 1 && !selectedInbox ? "subtle" : "ghost"
                  }
                  colorPalette={colorScheme}
                  borderLeftRadius={0}
                  justifyContent="start"
                  alignItems="center"
                  w="100%"
                  onClick={() => {
                    if (
                      !selectedInbox &&
                      activeTab === ConversationTab.Personal
                    ) {
                      return;
                    }

                    currentSearchParameters.delete("team");
                    dispatch(fetchConversations());
                    setSearchParams({
                      ...Object.fromEntries(currentSearchParameters.entries()),
                      tab: getTabName(1),
                    });
                  }}
                  color={
                    activeTabIndex === 1 && !selectedInbox
                      ? colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                      : colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                  }
                >
                  <Icon
                    as={LuUserRoundCheck}
                    mr={2}
                    width="1.5rem"
                    height="1.5rem"
                    color={
                      activeTabIndex === 1 && !selectedInbox
                        ? colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.600`
                        : colorMode === "dark"
                        ? "gray.200"
                        : "gray.600"
                    }
                  />
                  <Text
                    textAlign="left"
                    w="100%"
                    color={
                      activeTabIndex === 1 && !selectedInbox
                        ? colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.600`
                        : colorMode === "dark"
                        ? "gray.200"
                        : "gray.600"
                    }
                  >
                    Personal
                  </Text>
                  {unreadCount?.personal ? (
                    <Badge ml={1} colorPalette="red">
                      {unreadCount.personal > 100
                        ? "100+"
                        : unreadCount.personal}
                    </Badge>
                  ) : null}
                </Button>
                {canManageUnassignedInbox(merchant.id, currentAgent!) ? (
                  <Button
                    pl={7}
                    variant={
                      activeTabIndex === 2 && !selectedInbox
                        ? "subtle"
                        : "ghost"
                    }
                    colorPalette={colorScheme}
                    borderLeftRadius={0}
                    justifyContent="start"
                    alignItems="center"
                    w="100%"
                    onClick={() => {
                      if (
                        !selectedInbox &&
                        activeTab === ConversationTab.Unassigned
                      ) {
                        return;
                      }

                      currentSearchParameters.delete("team");
                      dispatch(fetchConversations());
                      setSearchParams({
                        ...Object.fromEntries(
                          currentSearchParameters.entries()
                        ),
                        tab: getTabName(2),
                      });
                    }}
                    color={
                      activeTabIndex === 2 && !selectedInbox
                        ? colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.600`
                        : colorMode === "dark"
                        ? "gray.200"
                        : "gray.600"
                    }
                  >
                    <Icon
                      as={LuUserRoundX}
                      mr={2}
                      width="1.5rem"
                      height="1.5rem"
                      color={
                        activeTabIndex === 2 && !selectedInbox
                          ? colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.600`
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.600"
                      }
                    />
                    <Text
                      textAlign="left"
                      w="100%"
                      color={
                        activeTabIndex === 2 && !selectedInbox
                          ? colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.600`
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.600"
                      }
                    >
                      Unassigned
                    </Text>
                    {unreadCount?.unassigned ? (
                      <Badge ml={1} colorPalette="red">
                        {unreadCount.unassigned > 100
                          ? "100+"
                          : unreadCount.unassigned}
                      </Badge>
                    ) : null}
                  </Button>
                ) : null}
              </AccordionItemContent>
            </AccordionItem>
            {teams.length > 0 ? (
              <AccordionItem value="1" mt={0} border="none">
                <AccordionItemTrigger
                  pl={2}
                  alignItems="center"
                  justifyContent="start"
                  _hover={{
                    bg: "inherit",
                  }}
                  gridGap={1}
                  flexDirection="row-reverse"
                >
                  <Text
                    color={colorMode === "light" ? "gray.600" : "gray.300"}
                    textAlign="left"
                    w="100%"
                    fontWeight="bold"
                  >
                    Teams
                  </Text>
                </AccordionItemTrigger>
                <AccordionItemContent p={0}>
                  <AccordionRoot
                    collapsible={true}
                    border="none"
                    multiple={true}
                    w="100%"
                    defaultValue={teams.reduce((acc, t) => {
                      if (t.id === selectedInbox) {
                        acc.push(t.id);
                      }

                      return acc;
                    }, [] as string[])}
                  >
                    {teams.map((t: TeamDomain, i) => (
                      <AccordionItem
                        value={t.id}
                        border="none"
                        w="100%"
                        key={t.id}
                      >
                        <AccordionItemTrigger
                          borderLeftRadius={0}
                          justifyContent="start"
                          alignItems="center"
                          w="100%"
                          pl={2}
                          color={
                            selectedInbox === t.id
                              ? colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.600`
                              : colorMode === "dark"
                              ? "gray.200"
                              : "gray.600"
                          }
                          gridGap={1}
                          flexDirection="row-reverse"
                        >
                          <Tooltip content={t.name}>
                            <Text
                              textAlign="left"
                              flex={1}
                              textOverflow="ellipsis"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              fontWeight={
                                getUnreadTotalForTeam(t.id, unreadCount)
                                  ? "extrabold"
                                  : "normal"
                              }
                            >
                              {t.name}
                            </Text>
                          </Tooltip>
                          {getUnreadTotalForTeam(t.id, unreadCount) ? (
                            <Badge colorPalette="red" mr={2}>
                              {getUnreadTotalForTeam(t.id, unreadCount) > 100
                                ? "100+"
                                : getUnreadTotalForTeam(t.id, unreadCount)}
                            </Badge>
                          ) : null}
                        </AccordionItemTrigger>
                        <AccordionItemContent
                          p={0}
                          pr={2}
                          display="flex"
                          flexDirection="column"
                          gridGap={1}
                        >
                          {canManageTeamInbox(merchant.id, currentAgent!) ? (
                            <Button
                              pl={7}
                              variant={
                                activeTabIndex === 0 && selectedInbox === t.id
                                  ? "subtle"
                                  : "ghost"
                              }
                              colorPalette={colorScheme}
                              justifyContent="start"
                              w="100%"
                              borderLeftRadius={0}
                              onClick={() => {
                                if (
                                  selectedInbox === t.id &&
                                  activeTab === ConversationTab.Team
                                ) {
                                  return;
                                }

                                dispatch(fetchConversations());
                                setSearchParams({
                                  ...Object.fromEntries(
                                    currentSearchParameters.entries()
                                  ),
                                  team: t.id,
                                  tab: getTabName(0),
                                });
                              }}
                            >
                              <Icon
                                as={LuMessageSquare}
                                mr={1}
                                width="1.35rem"
                                height="1.35rem"
                                color={
                                  activeTabIndex === 0 && selectedInbox === t.id
                                    ? colorMode === "dark"
                                      ? `${colorScheme}.200`
                                      : `${colorScheme}.600`
                                    : colorMode === "dark"
                                    ? "gray.200"
                                    : "gray.600"
                                }
                              />
                              <Text
                                textAlign="left"
                                w="100%"
                                color={
                                  activeTabIndex === 0 && selectedInbox === t.id
                                    ? colorMode === "dark"
                                      ? `${colorScheme}.200`
                                      : `${colorScheme}.600`
                                    : colorMode === "dark"
                                    ? "gray.200"
                                    : "gray.600"
                                }
                              >
                                All
                              </Text>
                              {unreadCount?.teams &&
                              unreadCount.teams[t.id]?.shared ? (
                                <Badge ml={1} colorPalette="red">
                                  {unreadCount.teams[t.id]?.shared > 100
                                    ? "100+"
                                    : unreadCount.teams[t.id]?.shared}
                                </Badge>
                              ) : null}
                            </Button>
                          ) : null}
                          <Button
                            pl={7}
                            variant={
                              activeTabIndex === 1 && selectedInbox === t.id
                                ? "subtle"
                                : "ghost"
                            }
                            colorPalette={colorScheme}
                            justifyContent="start"
                            w="100%"
                            borderLeftRadius={0}
                            onClick={() => {
                              if (
                                selectedInbox === t.id &&
                                activeTab === ConversationTab.Personal
                              ) {
                                return;
                              }

                              dispatch(fetchConversations());
                              setSearchParams({
                                ...Object.fromEntries(
                                  currentSearchParameters.entries()
                                ),
                                team: t.id,
                                tab: getTabName(1),
                              });
                            }}
                          >
                            <Icon
                              as={LuUserRoundCheck}
                              mr={1}
                              width="1.5rem"
                              height="1.5rem"
                              color={
                                activeTabIndex === 1 && selectedInbox === t.id
                                  ? colorMode === "dark"
                                    ? `${colorScheme}.200`
                                    : `${colorScheme}.600`
                                  : colorMode === "dark"
                                  ? "gray.200"
                                  : "gray.600"
                              }
                            />
                            <Text
                              textAlign="left"
                              w="100%"
                              color={
                                activeTabIndex === 1 && selectedInbox === t.id
                                  ? colorMode === "dark"
                                    ? `${colorScheme}.200`
                                    : `${colorScheme}.600`
                                  : colorMode === "dark"
                                  ? "gray.200"
                                  : "gray.600"
                              }
                            >
                              Personal
                            </Text>
                            {unreadCount?.teams &&
                            unreadCount.teams[t.id]?.personal ? (
                              <Badge ml={1} colorPalette="red">
                                {unreadCount.teams[t.id]?.personal > 100
                                  ? "100+"
                                  : unreadCount.teams[t.id]?.personal}
                              </Badge>
                            ) : null}
                          </Button>
                          {canManageUnassignedInbox(
                            merchant.id,
                            currentAgent!
                          ) ? (
                            <Button
                              pl={7}
                              justifyContent="start"
                              colorPalette={colorScheme}
                              variant={
                                activeTabIndex === 2 && selectedInbox === t.id
                                  ? "subtle"
                                  : "ghost"
                              }
                              w="100%"
                              borderLeftRadius={0}
                              onClick={() => {
                                if (
                                  selectedInbox === t.id &&
                                  activeTab === ConversationTab.Unassigned
                                ) {
                                  return;
                                }

                                dispatch(fetchConversations());
                                setSearchParams({
                                  ...Object.fromEntries(
                                    currentSearchParameters.entries()
                                  ),
                                  team: t.id,
                                  tab: getTabName(2),
                                });
                              }}
                            >
                              <Icon
                                as={LuUserRoundX}
                                mr={1}
                                width="1.5rem"
                                height="1.5rem"
                                color={
                                  activeTabIndex === 2 && selectedInbox === t.id
                                    ? colorMode === "dark"
                                      ? `${colorScheme}.200`
                                      : `${colorScheme}.600`
                                    : colorMode === "dark"
                                    ? "gray.200"
                                    : "gray.600"
                                }
                              />
                              <Text
                                textAlign="left"
                                w="100%"
                                color={
                                  activeTabIndex === 2 && selectedInbox === t.id
                                    ? colorMode === "dark"
                                      ? `${colorScheme}.200`
                                      : `${colorScheme}.600`
                                    : colorMode === "dark"
                                    ? "gray.200"
                                    : "gray.600"
                                }
                              >
                                Unassigned
                              </Text>
                              {unreadCount?.teams &&
                              unreadCount.teams[t.id]?.unassigned ? (
                                <Badge ml={1} colorPalette="red">
                                  {unreadCount.teams[t.id]?.unassigned > 100
                                    ? "100+"
                                    : unreadCount.teams[t.id]?.unassigned}
                                </Badge>
                              ) : null}
                            </Button>
                          ) : null}
                        </AccordionItemContent>
                      </AccordionItem>
                    ))}
                  </AccordionRoot>
                </AccordionItemContent>
              </AccordionItem>
            ) : null}
          </AccordionRoot>
          {canManageTeamInbox(merchant.id, currentAgent!) ? (
            <Box w="100%" pr={2}>
              <Button
                w="100%"
                borderLeftRadius={0}
                colorPalette={colorScheme}
                variant="ghost"
                justifyContent="start"
                onClick={() => {
                  setShowAddTeamModal(true);
                }}
              >
                <Icon as={LuSquarePlus} />
                Add Team
              </Button>
              <AddTeamModal
                isOpen={showAddTeamModal}
                onClose={() => setShowAddTeamModal(false)}
              />
            </Box>
          ) : null}
        </VStack>
        <Flex
          w="100%"
          h="100%"
          overflowY="hidden"
          borderTopRadius="3xl"
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          flexDirection="column"
        >
          <Conversations />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      direction="row"
      overflow="hidden"
      height="100%"
      width="100%"
      minWidth="100%"
      alignItems="start"
      pl={4}
    >
      <Flex
        w="100%"
        h="100%"
        overflowY="hidden"
        borderTopRadius="3xl"
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        flexDirection="column"
      >
        <Conversations />
      </Flex>
    </Flex>
  );
};

export default DesktopTabsArea;
