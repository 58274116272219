import {
  Button,
  Icon,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import React from "react";
import { LuInfo } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface InfoModalWhatsappProps {}

const InfoModalWhatsapp: React.FC<InfoModalWhatsappProps> = () => {
  const { open, onOpen, onClose } = useDisclosure();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
        variant="ghost"
        colorPalette="gray"
        borderRadius="full"
        h="auto"
        w="auto"
        minW="auto"
        p={0}
        zIndex={101}
      >
        <Icon as={LuInfo} boxSize={5} />
      </Button>

      <DialogRoot
        size={isBaseSize ? "full" : "md"}
        open={open}
        onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }}
      >
        <DialogBackdrop  />
        <DialogContent>
          <DialogHeader>It’s time to use a template!</DialogHeader>
          <DialogCloseTrigger />
          <DialogBody>
            <Text>
              It’s been 24 hours since this contact last messaged you and the
              free text window is now closed. To reopen the conversation, please
              send them an approved WhatsApp template. Once they’ve replied, the
              free text window will reopen.
            </Text>
            <Text fontSize="sm" mt={4}>
              For more information, please see the 📄💼 WhatsApp for Business
              messaging policy
              <Link
                ml={1}
                href="https://business.whatsapp.com/policy"
                target="_blank" rel="noopener noreferrer"
              >
                here.
              </Link>
            </Text>
          </DialogBody>

          <DialogFooter>
            <Button colorPalette={colorScheme} onClick={onClose}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default InfoModalWhatsapp;
