import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spinner,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogRoot } from "components/ui/dialog";
import React, { useEffect, useState } from "react";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import { useAppSelector } from "redux/hooks";
import { numberOfAttachmentsPerLoad } from "util/constants";
import ClickableMedia from "./ClickableMedia";
import MediaType from "./MediaType";
import SmallMediaType from "./SmallMediaType";

export interface Media {
  id: string;
  type?: string;
  url?: string;
  element?: React.ReactNode;
  smallElement?: React.ReactNode;
}

interface GalleryProps {
  isOpen: boolean;
  onClose: () => void;
  startIndex?: number;
  setStartIndex?: (index: number) => void;
  media: Media[];
  hasMore?: boolean;
  fetchMore?: () => Promise<void>;
  isLoadingMore?: boolean;
  navigationOrientation?: "horizontal" | "vertical";
}

const Gallery = ({
  isOpen,
  onClose,
  media,
  startIndex = 0,
  setStartIndex = () => {},
  hasMore = false,
  fetchMore,
  isLoadingMore = false,
  navigationOrientation = "horizontal",
}: GalleryProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
  const listRef = React.useRef<ViewportListRef | null>(null);

  const [brokenMedia, setBrokenMedia] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loadMore, setLoadMore] = useState<undefined | (() => Promise<void>)>();

  useEffect(() => {
    if (typeof fetchMore === "undefined") {
      return;
    }

    setLoadMore(() => async () => {
      setIsLoading(true);
      await fetchMore();
      setIsLoading(false);
    });
  }, [fetchMore]);

  useEffect(() => {
    listRef?.current?.scrollToIndex({
      index: startIndex,
      // unfortunately no ability to center the item
    });
  }, [startIndex]);

  const [localMedia, setLocalMedia] = useState<Media[]>([]);

  useEffect(() => {
    setLocalMedia(media.filter((item) => !brokenMedia.includes(item.id)));
    if (brokenMedia.includes(media[startIndex]?.id)) {
      setStartIndex(startIndex + 1);
    }
  }, [media, brokenMedia]);

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }} size="full">
      <DialogBackdrop          bgColor="blackAlpha.800"
        {...(isLoading ? { zIndex: 9999 } : {})}
      />
      {isLoading ? (
        <Spinner
          size="xl"
          mx="auto"
          position="absolute"
          left="50%"
          top="50%"
          zIndex={10000}
        />
      ) : null}
      <DialogContent           bg="transparent"
        display="flex"
        onKeyDown={(e) => {
          if (
            (e.key === "ArrowLeft" || e.key === "ArrowUp") &&
            startIndex > 0
          ) {
            setStartIndex(startIndex - 1);
          }
          if (
            (e.key === "ArrowRight" || e.key === "ArrowDown") &&
            startIndex < localMedia.length - 1
          ) {
            setStartIndex(startIndex + 1);
          }
        }}
      >
        <DialogCloseTrigger
          bg="whiteAlpha.600"
          colorPalette="whiteAlpha"
          zIndex={99999}
        />
        <DialogBody               flexGrow={2}
          flexShrink={0}
          flexBasis="1px"
          display="flex"
          alignItems="start"
          gridGap={4}
          justifyContent="center"
          overflowX="hidden"
          p={0}
        >
          {!isBaseSize && navigationOrientation === "vertical" ? (
            <VStack
              bgColor="blackAlpha.600"
              alignItems="center"
              justifyContent="center"
              minWidth="10rem"
              overflowY="auto"
              height="100%"
              gap={4}
              ref={scrollContainerRef}
            >
              <ViewportList
                ref={listRef}
                viewportRef={scrollContainerRef}
                items={localMedia}
                initialIndex={startIndex}
                onViewportIndexesChange={([_start, end]) => {
                  if (!media.length || !hasMore || isLoadingMore) {
                    return;
                  }

                  if (
                    media.length >= numberOfAttachmentsPerLoad &&
                    end === localMedia.length - 1 &&
                    hasMore &&
                    typeof loadMore !== "undefined"
                  ) {
                    loadMore();
                  }
                }}
              >
                {(item, index) => (
                  <Box
                    flexShrink={0}
                    borderRadius="lg"
                    key={item.id}
                    p={0}
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setStartIndex(index);
                    }}
                  >
                    {item.smallElement || (
                      <SmallMediaType
                        url={item.url!}
                        type={item.type!}
                        id={item.id!}
                        index={index}
                        startIndex={startIndex}
                        brokenMedia={brokenMedia}
                        setBrokenMedia={setBrokenMedia}
                      />
                    )}
                  </Box>
                )}
              </ViewportList>
            </VStack>
          ) : null}
          <Flex
            height="100%"
            width="100%"
            py={8}
            alignItems="center"
            justifyContent="space-between"
            position="relative"
          >
            <IconButton
              disabled={startIndex === 0}
              aria-label="previous"
              unstyled={true}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg={isBaseSize ? "gray.100" : "whiteAlpha.600"}
              color={isBaseSize ? "gray.900" : "white"}
              ml={isBaseSize ? "0.25rem" : "1rem"}
              {...(isBaseSize
                ? {
                    position: "absolute",
                    left: 0,
                  }
                : {})}
              size="lg"
              onClick={() => {
                if (startIndex > 0) {
                  setStartIndex(startIndex - 1);
                }
              }}
            >
              <Icon as={LuChevronLeft} width="1.5rem" height="1.5rem" />
            </IconButton>
            <Box mx="auto">
              {localMedia[startIndex]?.element || (
                <MediaType
                  url={localMedia[startIndex]?.url!}
                  type={localMedia[startIndex]?.type!}
                  id={localMedia[startIndex]?.id!}
                />
              )}
            </Box>
            <IconButton
              aria-label="next"
              unstyled={true}
              display="flex"
              alignItems="center"
              justifyContent="center"
              disabled={startIndex === localMedia.length - 1}
              bg={isBaseSize ? "gray.100" : "whiteAlpha.600"}
              color={isBaseSize ? "gray.900" : "white"}
              onClick={() => {
                if (startIndex < localMedia.length - 1) {
                  setStartIndex(startIndex + 1);
                }

                if (
                  isBaseSize &&
                  !isLoadingMore &&
                  media.length >= numberOfAttachmentsPerLoad &&
                  startIndex === localMedia.length - 2 &&
                  hasMore &&
                  typeof loadMore !== "undefined"
                ) {
                  loadMore();
                }
              }}
              mr={isBaseSize ? "0.25rem" : "1rem"}
              {...(isBaseSize
                ? {
                    position: "absolute",
                    right: 0,
                  }
                : {})}
              size="lg"
            >
              <Icon as={LuChevronRight} width="1.5rem" height="1.5rem" />
            </IconButton>
          </Flex>
        </DialogBody>
        {!isBaseSize && navigationOrientation === "horizontal" ? (
          <DialogFooter                flexGrow={1}
            flexShrink={0}
            flexBasis="1px"
            bgColor="blackAlpha.600"
            alignItems="center"
            justifyContent="start"
            flexWrap="nowrap"
            overflowX="auto"
            gridGap={4}
            maxHeight="15rem"
            w="100%"
            ref={scrollContainerRef}
          >
            <ViewportList
              ref={listRef}
              viewportRef={scrollContainerRef}
              items={localMedia}
              axis="x"
              initialIndex={startIndex}
              onViewportIndexesChange={([_start, end]) => {
                if (!media.length || !hasMore || isLoadingMore) {
                  return;
                }

                if (
                  media.length >= numberOfAttachmentsPerLoad &&
                  end === localMedia.length - 1 &&
                  hasMore &&
                  typeof loadMore !== "undefined"
                ) {
                  loadMore();
                }
              }}
            >
              {(item, index) => (
                <ClickableMedia
                  onClick={() => {
                    setStartIndex(index);
                  }}
                >
                  {item.smallElement || (
                    <SmallMediaType
                      url={item.url!}
                      type={item.type!}
                      id={item.id!}
                      index={index}
                      startIndex={startIndex}
                      brokenMedia={brokenMedia}
                      setBrokenMedia={setBrokenMedia}
                    />
                  )}
                </ClickableMedia>
              )}
            </ViewportList>
          </DialogFooter>
        ) : null}
      </DialogContent>
    </DialogRoot>
  );
};

export default Gallery;
