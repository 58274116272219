import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Box, Flex, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as FuzeyTarget } from "assets/icons/Fuzey_Logomark_Dark.svg";
import { useColorMode } from "components/ui/color-mode";
import AutomationDomain from "entities/domain/automation";
import React, { useEffect, useState } from "react";
import { LuCalendar, LuCreditCard, LuMail } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import { canSendPayments } from "util/permissions";

interface AutomationCardProps {
  automation: AutomationDomain;
  onSelect: () => void;
}

export const ICON_BY_TEMPLATE_ID: {
  [key: string]: React.ComponentType;
} = {
  booking_reminder: LuCalendar,
  payment_reminder: LuCreditCard,
  auto_reply: LuMail,
};

const AutomationCard = ({ automation, onSelect }: AutomationCardProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isEditable, setIsEditable] = useState<boolean>(true);

  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { user } = useAuth0();

  useEffect(() => {
    if (automation.templateId === "payment_reminder") {
      setIsEditable(canSendPayments(merchant.id, currentAgent!));
    }
  }, [user, merchant]);

  const getIcon = () => {
    switch (automation.templateId) {
      case "booking_reminder":
        return LuCalendar;
      case "payment_reminder":
        return LuCreditCard;
      case "auto_reply":
        return LuMail;
      default:
        return null;
    }
  };

  return (
    <Flex
      borderWidth="1px"
      borderStyle="solid"
      borderColor={colorMode === "dark" ? "gray.700" : "gray.50"}
      borderRadius="2xl"
      px="1rem"
      py={0}
      height="300px"
      width="320px"
      mb="2rem"
      flexDirection="column"
      onClick={isEditable ? onSelect : undefined}
      p="1rem"
      opacity={isEditable ? 1 : 0.5}
      _hover={{
        cursor: isEditable ? "pointer" : "not-allowed",
        boxShadow: `0 0 1rem ${colorMode === "light" ? "lightgray" : "black"}`,
      }}
    >
      <Icon
        flexGrow={1}
        flexShrink={0}
        as={getIcon() || FuzeyTarget}
        {...(getIcon()
          ? {
              color:
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`,
            }
          : {
              "& path": {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            })}
        width="40px"
      />
      <Text
        my="1rem"
        textAlign="center"
        fontWeight={600}
        fontSize="lg"
        flexGrow={2}
        flexShrink={0}
        textOverflow="ellipsis"
        overflow="hidden"
        maxHeight="30px"
      >
        {automation.name}
      </Text>
      <Text
        mb="1rem"
        textAlign="center"
        fontSize="md"
        flexShrink={0}
        flexGrow={2}
        textOverflow="ellipsis"
        overflow="hidden"
        maxHeight="100px"
      >
        {automation.description}
      </Text>
      <Box pt={3}>
        <Badge
          colorPalette={automation.enabled ? "green" : "red"}
          flexGrow={0}
          flexBasis={0}
        >
          {automation.enabled ? "Enabled" : "Disabled"}
        </Badge>
      </Box>
    </Flex>
  );
};

export default AutomationCard;
