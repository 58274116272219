import {
  HStack,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  Condition,
  ConditionType,
  LogicalOperationType,
  getConditionLabel,
} from "entities/domain/criteria";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";

import { IoCheckmarkCircle } from "react-icons/io5";
import { LuTrash2 } from "react-icons/lu";
import Select from "react-select";
import { useAppSelector } from "redux/hooks";
import { getSelectStylesForQueryBuilder } from "util/methods";

export interface FieldComponentProps {
  id: string;
  value: string;
  onChange: (newValue: string) => void;
}

export interface PreviewComponentProps {
  value: string;
}

export enum GroupName {
  CUSTOMER = "customer",
  VEHICLE = "vehicle",
}

export type GroupLimits = {
  [key in LogicalOperationType]: number;
};

export interface GroupConfig {
  name: GroupName;
  limits: GroupLimits;
  fields: Field[];
}

export interface Field {
  value: string;
  label: string;
  wordOrder: "reversed" | "direct";
  possibleConditions: ConditionType[];
  inputComponent: (props: FieldComponentProps) => JSX.Element;
  previewComponent: (props: PreviewComponentProps) => JSX.Element;
}

interface QueryConditionProps {
  size: "sm" | "md";
  isDisabled: boolean;
  condition: Condition;
  isRemovable: boolean;
  groups: GroupConfig[];
  conditionIndex: number;
  operationIndex: number;
  disabledFields: string[];
  fields: Field[];
  editCondition: (updatedCondition: Condition) => void;
  removeCondition: () => void;
}

const QueryCondition = ({
  disabledFields,
  size,
  condition,
  fields,
  isDisabled,
  conditionIndex,
  operationIndex,
  groups,
  isRemovable,
  editCondition,
  removeCondition,
}: QueryConditionProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [selectedField, setSelectedField] = useState<Field>(
    fields.find((f) => f.value === condition.key) || fields[0]
  );
  const [actualFieldValue, setActualFieldValue] = useState<string>(
    condition.value
  );
  const debouncedActualFieldValue = useDebounce<string>(actualFieldValue, 500);
  const [shouldShowRemoveButton, setShouldShowRemoveButton] = useState<boolean>(
    !!isBaseSize && isRemovable
  );
  const [fieldConditionOptions, setFieldConditionOptions] = useState<
    Array<{
      value: ConditionType;
      label: string;
    }>
  >(
    selectedField.possibleConditions.map((c) => ({
      value: c,
      label: getConditionLabel(c),
    }))
  );

  useEffect(() => {
    editCondition({
      key: condition.key,
      type: condition.type,
      value: actualFieldValue,
    });
  }, [debouncedActualFieldValue]);

  useEffect(() => {
    if (condition.key === selectedField.value) {
      return;
    }

    const newSelectedField =
      fields.find((f) => f.value === condition.key) || fields[0];

    setSelectedField(newSelectedField);
    setFieldConditionOptions(
      newSelectedField.possibleConditions.map((c) => ({
        value: c,
        label: getConditionLabel(c),
      }))
    );
  }, [condition, fields]);

  return (
    <HStack
      ml={isBaseSize ? 2 : 8}
      pr={isBaseSize ? 0 : 8}
      onMouseEnter={() => {
        if (isBaseSize || !isRemovable) {
          return;
        }

        setShouldShowRemoveButton(true);
      }}
      onMouseLeave={() => {
        if (isBaseSize || !isRemovable) {
          return;
        }

        setShouldShowRemoveButton(false);
      }}
      gap={size === "sm" ? 0 : 1}
    >
      <Icon
        as={IoCheckmarkCircle}
        color="gray.500"
        mr={size === "sm" ? 1 : 0}
        boxSize={size === "sm" ? 4 : 6}
      />
      <Text>
        {groups.find(({ fields: groupFields }) => {
          return groupFields.map((gf) => gf.value).includes(fields[0].value);
        })?.name || null}
      </Text>
      <HStack
        flexDirection={
          selectedField.wordOrder === "reversed" ? "row-reverse" : "row"
        }
        gap={size === "sm" ? 0 : 1}
      >
        <Select
          isDisabled={isDisabled}
          defaultValue={{
            label: selectedField.label,
            value: selectedField.value,
          }}
          id={`condition-field-${operationIndex}-${conditionIndex}`}
          isClearable={false}
          onChange={(v) => {
            editCondition({
              key: v?.value || selectedField.value,
              type: (fields.find((f) => f.value === v?.value) || fields[0])
                .possibleConditions[0],
              value: "",
            });
          }}
          options={fields.filter((f) => !disabledFields.includes(f.value))}
          isSearchable={false}
          value={fields
            .map((o) => ({ value: o.value, label: o.label }))
            .find((o) => o.value === condition.key)}
          styles={getSelectStylesForQueryBuilder(
            colorScheme,
            (colorMode as "dark" | "light") || "light"
          )}
        />
        <Select
          isDisabled={isDisabled}
          defaultValue={fieldConditionOptions[0]}
          isClearable={false}
          onChange={(v) => {
            editCondition({
              key: selectedField.value,
              type: v?.value || fieldConditionOptions[0].value,
              value: condition.value,
            });
          }}
          options={fieldConditionOptions}
          isSearchable={false}
          value={
            fieldConditionOptions.find((o) => o.value === condition.type) ||
            fieldConditionOptions[0]
          }
          styles={getSelectStylesForQueryBuilder(
            colorScheme,
            (colorMode as "light" | "dark") || "light"
          )}
        />
      </HStack>
      {isDisabled ? (
        <selectedField.previewComponent value={condition.value} />
      ) : (
        <selectedField.inputComponent
          id={`condition-value-${operationIndex}-${conditionIndex}`}
          value={actualFieldValue}
          onChange={(v) => {
            setActualFieldValue(v);
          }}
        />
      )}
      {shouldShowRemoveButton && !isDisabled ? (
        <IconButton
          variant="ghost"
          size="2xs"
          aria-label="Remove condition"
          onClick={() => {
            removeCondition();
          }}
        >
          <Icon as={LuTrash2} color="gray.500" />
        </IconButton>
      ) : null}
    </HStack>
  );
};

export default QueryCondition;
