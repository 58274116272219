import { useAuth0 } from "@auth0/auth0-react";
import { useBreakpointValue } from "@chakra-ui/react";
import { WarningTextProps } from "components/shared/WarningTextComponent";
import {
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import MessageDomain from "entities/domain/conversations/message-domain";
import React, { useEffect, useRef, useState } from "react";
import { appendMessage } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PaymentsService, { SendMessagePayload } from "services/payments";
import PaymentsStepOne from "./PaymentsStepOne";
import PaymentsStepTwo from "./PaymentsStepTwo";
import { PAYMENTS_DONT_SHOW_AGAIN_TOKEN } from "./shared";

interface PaymentsPopoverProps {
  onClose: () => void;
  isOpen: boolean;
  id?: string;
}

const PaymentsPopover = ({
  isOpen,
  onClose,
  id = "",
}: PaymentsPopoverProps) => {
  const dispatch = useAppDispatch();
  const regexp = new RegExp("^[0-9a-zA-Z./&-\\s]*$");
  const [step, setStep] = useState<number>(1);
  const [numberAmount, setNumberAmount] = useState<number>(0);
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );
  const [servicesRendered, setServicesRendered] = useState<string>("");
  const [proceedWithoutPreviewActive, setProceedWithoutPreviewActive] =
    useState<boolean>(false);

  const { activeConversationId, activeConversation } = useAppSelector(
    (state) => state.conversations
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const [proceedWithInvoice, setProceedWithInvoice] = useState<boolean>(
    merchant.hasInvoiceAccount
  );
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const handleOnAmountChange = (value: number): void => {
    setNumberAmount(value || 0);
  };

  const reset = () => {
    setStep(1);
    setNumberAmount(0);
    setServicesRendered("");
    setWarning(undefined);
    setProceedWithInvoice(merchant.hasInvoiceAccount);
  };

  useEffect(() => {
    const val = localStorage.getItem(PAYMENTS_DONT_SHOW_AGAIN_TOKEN);

    setProceedWithoutPreviewActive(val === "true");
  }, []);

  useEffect(() => {
    if (numberAmount < 0.3) {
      setWarning({
        key: "invalid_payment_amount",
        description: "* must be at least £0.30",
      });
    } else if (servicesRendered.length < 3) {
      setWarning({
        key: "invalid_description",
        description: "* must be at least 3 characters",
      });
    } else if (servicesRendered.length > 255) {
      setWarning({
        key: "invalid_description",
        description: "* must be less than 255 characters",
      });
    } else if (!regexp.test(servicesRendered)) {
      setWarning({
        key: "invalid_description",
        description: "Characters allowed: A to Z, a to z, 0 to 9, &, -, ., /",
      });
    } else {
      setWarning(undefined);
    }
  }, [numberAmount, servicesRendered]);

  useEffect(() => {}, [servicesRendered]);

  const sendPaymentMessage = async () => {
    try {
      const payload: SendMessagePayload = {
        conversationId: activeConversationId!,
        reason: servicesRendered,
        amount: numberAmount.toString(),
        currency: merchant.getCurrency(),
        withInvoice: proceedWithInvoice,
      };

      if (activeConversation?.isEmailChannel()) {
        payload.title = activeConversation?.lastMessageTitle;
        payload.replyToMessageId = activeConversation?.messageId;
      }

      await PaymentsService.sendPaymentMessage(
        auth0Context,
        payload,
        merchant.id,
        payload.conversationId
      ).then((message: MessageDomain) => {
        dispatch(
          appendMessage({ message, conversationId: message.conversationId })
        );
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("[sendPaymentMessage] err: ", err);
    }
  };

  const submit = () => {
    sendPaymentMessage();
    reset();
    onClose();
  };

  const stepOneProceed = () => {
    if (proceedWithoutPreviewActive) {
      submit();
    } else {
      setStep(step + 1);
    }
  };

  const formatAmount = (am: number): string =>
    `${merchant.getCurrencySymbol()}${am.toFixed(2)}`;

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <PaymentsStepOne
            warning={warning}
            handleOnAmountChange={(newAmount) => {
              handleOnAmountChange(newAmount);
            }}
            servicesRendered={servicesRendered}
            setServicesRendered={(newServicesRendered: string) => {
              setServicesRendered(newServicesRendered);
            }}
            proceed={stepOneProceed}
            setProceedWithInvoice={setProceedWithInvoice}
            proceedWithInvoice={proceedWithInvoice}
            showInvoice={merchant.hasInvoiceAccount}
          />
        );
      case 2:
        return (
          <PaymentsStepTwo
            goBack={() => setStep(1)}
            amount={formatAmount(numberAmount)}
            servicesRendered={servicesRendered}
            proceed={submit}
            proceedWithInvoice={proceedWithInvoice}
            showInvoice={merchant.hasInvoiceAccount}
          />
        );
      default:
        return null;
    }
  };

  const getStepTitle = (): string => {
    switch (step) {
      case 1:
        return "Request payment";
      case 2:
        return "Request overview";
      default:
        return "";
    }
  };

  return (
    <DialogRoot
      size={isBaseSize ? "full" : "md"}
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      placement="center"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>{getStepTitle()}</DialogHeader>
        {renderStep()}
      </DialogContent>
    </DialogRoot>
  );
};

export default PaymentsPopover;
