import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  Spinner,
} from "@chakra-ui/react";
import PrivateRoot from "PrivateRoot";
import PublicRoot from "PublicRoot";
import ScreenSettingsProvider from "ScreenSettingsProvider";
import CallbackFail from "components/CallbackFail";
import CallbackSuccess from "components/CallbackSuccess";
import AbstractPrivateRoute from "components/Routes/AbstractPrivateRoute";
import MerchantPrivateRoute from "components/Routes/MerchantPrivateRoute";
import Admin from "components/admin";
import AdminNavigation from "components/admin/AdminNavigation";
import Agents from "components/admin/agents";
import AI from "components/admin/ai";
import Broadcast from "components/admin/broadcast";
import Merchants from "components/admin/merchants";
import AnalyticsPage from "components/analytics";
import Calendar from "components/calendar";
import NewCampaignForm from "components/campaigns/form";
import NewCampaignsList from "components/campaigns/list";
import Chat from "components/chat";
import MessagesArea from "components/chat/shared/messages-area";
import ContactForm from "components/contact-form";
import ContactFormSuccess from "components/contact-form/ContactFormSuccess";
import ContactsPage from "components/contacts";
import CreateContactPage from "components/contacts/contact-card/createContact";
import EditContactPage from "components/contacts/contact-card/editContact";
import InstagramCallback from "components/facebook/callbackInstagram";
import FacebookMessengerCallback from "components/facebook/callbackMessenger";
import GoogleReviewsCallback from "components/google/callback";
import GoogleGmailCallback from "components/google/callbackGmail";
import GoogleLocationsOptions from "components/google/locations";
import KeyloopRepairOrdersPage from "components/keyloop/repair-orders";
import MicrosoftOutlookCallback from "components/microsoft/callbackOutlook";
import StaleDataManager from "components/modals/stale-data-manager";
import PaymentsPage from "components/payments";
import InvoicingOptions from "components/payments/invoicing-options";
import QuickbooksPage from "components/payments/quickbooks";
import XeroPage from "components/payments/xero";
import ExternalPayment from "components/public-pages/payments";
import ExternalPaymentCallback from "components/public-pages/payments/callback";
import ReviewsPage from "components/reviews";
import ReviewPage from "components/reviews/ReviewPage";
import ContactDetails from "components/shared/contact-details";
import StripeCallback from "components/stripe/callback";
import { Provider as ChakraProvider } from "components/ui/provider";
import UserSettingsWithProvider from "components/user-settings";
import AccountOverview from "components/user-settings/AccountOverview/AccountOverview";
import AutomationSettings from "components/user-settings/Automations";
import Channels from "components/user-settings/Channels";
import { ContactAttributeSettings } from "components/user-settings/ContactAttributes";
import CreateContactAttribute from "components/user-settings/ContactAttributes/create-contact-attribute";
import EditContactAttribute from "components/user-settings/ContactAttributes/edit-contact-attribute";
import Files from "components/user-settings/Files";
import NewIntegrations from "components/user-settings/Integrations";
import WhatsappIntegration from "components/user-settings/Integrations/WhatsappIntegration";
import ZapierIntegration from "components/user-settings/Integrations/items/zapier/index";
import Keyloop from "components/user-settings/Integrations/keyloop";
import RolePermissions from "components/user-settings/RolePermissions";
import TeamMemberSettings from "components/user-settings/TeamMemberSettings";
import TeamMemberForm from "components/user-settings/TeamMemberSettings/TeamMemberForm";
import TemplateSettings from "components/user-settings/TemplateSettings/TemplateSettings";
import TemplateView from "components/user-settings/TemplateSettings/TemplateView";
import CreateWhatsappTemplate from "components/user-settings/TemplateSettings/create-whatsapp-template";
import ViewWhatsappTemplate from "components/user-settings/TemplateSettings/view-whatsapp-template";
import useAnalytics from "hooks/use-analytics";
import { WebSocketProvider } from "hooks/use-socket";
import React, { useEffect, useMemo, useState } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";

function App() {
  const auth0Context = useAuth0();
  const {
    isLoading: authLoading,
    isAuthenticated,
    loginWithRedirect,
  } = auth0Context;
  const [isLoading, setIsLoading] = useState(true);
  const { init: analyticsInit } = useAnalytics();

  analyticsInit();

  const isAuthorizationRequired = () => {
    if (window.location.pathname.includes("public")) {
      return false;
    }

    if (
      !authLoading &&
      (window.location.pathname.includes("public") || isAuthenticated)
    ) {
      return false;
    }

    if (!authLoading && !isAuthenticated) {
      return true;
    }

    return null;
  };

  useEffect(() => {
    if (!isAuthorizationRequired()) {
      setIsLoading(false);
      return;
    }

    (async () => {
      // If user is not authenticated and all app data is loaded
      if (isAuthorizationRequired()) {
        await loginWithRedirect();

        return;
      }

      // If user is authenticated and all app data is loaded
      setIsLoading(false);
    })();
  }, [authLoading, isAuthenticated]);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/",
          Component: PrivateRoot,
          children: [
            {
              path: ":merchantId",
              element: <MerchantPrivateRoute />,
              children: [
                {
                  path: "inbox",
                  element: <Chat />,
                  children: [
                    {
                      path: ":conversationId",
                      element: <MessagesArea />,
                    },
                  ],
                },
                {
                  path: "reviews",
                  element: <ReviewsPage />,
                  children: [
                    {
                      path: ":reviewId",
                      element: <ReviewPage />,
                    },
                  ],
                },
                {
                  path: "payments",
                  element: <PaymentsPage />,
                },
                {
                  path: "keyloop/repair-orders",
                  element: <KeyloopRepairOrdersPage />,
                },
                {
                  path: "calendar",
                  element: <Calendar />,
                },
                {
                  path: "analytics",
                  element: <AnalyticsPage />,
                },
                {
                  path: "invoicing",
                  element: <InvoicingOptions />,
                },
                {
                  path: "campaigns/new",
                  element: <NewCampaignForm />,
                },
                {
                  path: "campaigns/edit/:campaignId",
                  element: <NewCampaignForm />,
                },
                {
                  path: "campaigns/details/:campaignId",
                  element: <NewCampaignForm />,
                },
                {
                  path: "campaigns",
                  element: <NewCampaignsList />,
                },
                {
                  path: "settings",
                  element: <UserSettingsWithProvider />,
                  children: [
                    {
                      index: true,
                      element: <AccountOverview />,
                    },
                    {
                      path: "channels",
                      element: <Channels />,
                    },
                    {
                      path: "integrations",
                      element: <NewIntegrations />,
                      children: [
                        {
                          path: "keyloop",
                          element: <Keyloop />,
                        },
                      ],
                    },
                    {
                      path: "account",
                      element: <AccountOverview />,
                    },
                    {
                      path: "files",
                      element: <Files />,
                    },
                    {
                      path: "templates",
                      element: <TemplateSettings />,
                      children: [
                        {
                          path: "whatsapp/:templateId",
                          element: <ViewWhatsappTemplate />,
                        },
                      ],
                    },
                    {
                      path: "automations",
                      element: <AutomationSettings />,
                    },
                    {
                      path: "teammates",
                      element: <TeamMemberSettings />,
                    },
                    {
                      path: "permissions",
                      element: <RolePermissions />,
                    },
                    {
                      path: "attributes",
                      element: <ContactAttributeSettings />,
                      children: [
                        {
                          path: "create",
                          element: <CreateContactAttribute />,
                        },
                        {
                          path: ":attributeId",
                          element: <EditContactAttribute />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "settings/integrations/zapier",
                  element: <ZapierIntegration />,
                },
                {
                  path: "settings/integrations/whatsapp",
                  element: <WhatsappIntegration />,
                },
                {
                  path: "settings/templates/create",
                  element: <TemplateView />,
                },
                {
                  path: "settings/templates/whatsapp/create",
                  element: <CreateWhatsappTemplate />,
                },
                {
                  path: "settings/templates/:templateId",
                  element: <TemplateView />,
                },
                {
                  path: "settings/teammates/:memberId",
                  element: <TeamMemberForm />,
                },
                {
                  path: "contacts",
                  element: <ContactsPage />,
                  children: [
                    {
                      path: ":contactId",
                      element: <ContactDetails />,
                    },
                  ],
                },
                {
                  path: "contacts/edit/:contactId",
                  element: <EditContactPage />,
                },
                {
                  path: "contacts/create",
                  element: <CreateContactPage />,
                },
                {
                  path: "admin",
                  element: <Admin />,
                  children: [
                    {
                      index: true,
                      element: <AdminNavigation />,
                    },
                    {
                      path: "merchants",
                      element: <Merchants />,
                    },
                    {
                      path: "agents",
                      element: <Agents />,
                    },
                    {
                      path: "ai",
                      element: <AI />,
                    },
                    {
                      path: "broadcast",
                      element: <Broadcast />,
                    },
                  ],
                },
                {
                  index: true,
                  element: <Chat />,
                },
              ],
            },
            {
              index: true,
              element: (
                <AbstractPrivateRoute>
                  <Chat />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "reviews/google/locations",
              element: (
                <AbstractPrivateRoute>
                  <GoogleLocationsOptions />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "settings/integrations/keyloop",
              element: (
                <AbstractPrivateRoute>
                  <Keyloop />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "reviews/google/callback",
              element: (
                <AbstractPrivateRoute>
                  <GoogleReviewsCallback />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "google/gmail/callback",
              element: (
                <AbstractPrivateRoute>
                  <GoogleGmailCallback />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "microsoft/callback",
              element: (
                <AbstractPrivateRoute>
                  <MicrosoftOutlookCallback />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "stripe/callback",
              element: (
                <AbstractPrivateRoute>
                  <StripeCallback />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "facebook/callback/messenger",
              element: (
                <AbstractPrivateRoute>
                  <FacebookMessengerCallback />
                </AbstractPrivateRoute>
              ),
            },
            {
              path: "facebook/callback/instagram",
              element: (
                <AbstractPrivateRoute>
                  <InstagramCallback />
                </AbstractPrivateRoute>
              ),
            },
          ],
        },
        {
          path: "/xero",
          element: (
            <WebSocketProvider>
              <AbstractPrivateRoute>
                <XeroPage />
              </AbstractPrivateRoute>
            </WebSocketProvider>
          ),
        },
        {
          path: "/quickbooks",
          element: (
            <WebSocketProvider>
              <AbstractPrivateRoute>
                <QuickbooksPage />
              </AbstractPrivateRoute>
            </WebSocketProvider>
          ),
        },
        {
          path: "/public",
          Component: PublicRoot,
          children: [
            {
              path: "pay/:payment_id",
              element: <ExternalPayment />,
            },
            {
              path: "payments/callback",
              element: <ExternalPaymentCallback />,
            },
            {
              path: "contact-form",
              element: <ContactForm />,
            },
            {
              path: "contact-form/success",
              element: <ContactFormSuccess />,
            },
            {
              path: "callback/success",
              element: <CallbackSuccess />,
            },
            {
              path: "callback/fail",
              element: <CallbackFail />,
            },
          ],
        },
        {
          path: "*",
          element: <AbstractPrivateRoute />,
          errorElement: <Navigate to="/inbox" replace={true} />,
        },
      ]),
    []
  );

  if (isLoading || authLoading) {
    return (
      <ChakraProvider>
        <Flex alignItems="center" justifyContent="center" h="100%" w="100%">
          <Spinner size="xl" />
        </Flex>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider>
      <ScreenSettingsProvider>
        <RouterProvider router={router} />
        <StaleDataManager />
      </ScreenSettingsProvider>
    </ChakraProvider>
  );
}

export default App;
