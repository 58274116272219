import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Separator,
  Tabs,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import useCampaignsStore from "hooks/use-campaigns-store";
import React, { useEffect, useRef, useState } from "react";
import CampaignsService from "services/campaigns";

import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import {
  CampaignProgressUpdatedMessage,
  CampaignUpdatedMessage,
} from "entities/ISocketArgs";
import { campaignTransformFromDtoToDomain } from "entities/transformers/campaignTransformer";
import useDebounce from "hooks/use-debounce";
import { useWebSocket } from "hooks/use-socket";
import { FaPencilAlt } from "react-icons/fa";
import { IoAnalytics } from "react-icons/io5";
import { LuPlus } from "react-icons/lu";
import { Link, useSearchParams } from "react-router-dom";
import {
  CampaignCategory,
  propagateCampaignUpdateAction,
  setActiveCampaignCategory,
  setCampaigns,
  updateCampaignProgress,
} from "redux/features/campaigns";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { numberOfCampaignsPerLoad } from "util/constants";
import FilterSortingArea from "./FilterSortingArea";
import InfinityList from "./InifinityList";
import SkeletonOverlay from "./SkeletonOverlay";
import StatsSection from "./StatsSection";

const getActiveCampaignCategoryIndex = (
  categoryName: CampaignCategory | undefined
): number | undefined => {
  switch (categoryName) {
    case CampaignCategory.DraftScheduled:
      return 0;
    case CampaignCategory.Done:
      return 1;
    default:
      return undefined;
  }
};

const getCategoryName = (index: number): CampaignCategory => {
  switch (index) {
    case 0:
      return CampaignCategory.DraftScheduled;
    case 1:
      return CampaignCategory.Done;
    default:
      throw new Error(`Invalid category (index: ${index}) has been chosen`);
  }
};

const getStatusesByCategory = (
  category: CampaignCategory | undefined
): CampaignStatus[] => {
  switch (category) {
    case CampaignCategory.DraftScheduled:
      return [CampaignStatus.DRAFT, CampaignStatus.PENDING];
    case CampaignCategory.Done:
      return [
        CampaignStatus.DONE,
        CampaignStatus.PAUSED,
        CampaignStatus.CANCELLED,
      ];
    default:
      return [];
  }
};

const NewCampaignsList = () => {
  const auth0Context = useAuth0();
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [currentSearchParameters, setSearchParams] = useSearchParams();
  const { deleteCampaign, pauseCampaign, resumeCampaign, cancelCampaign } =
    useCampaignsStore();
  const { campaigns, toastMessage, activeCampaignCategory } = useAppSelector(
    (state) => state.campaigns
  );
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const debouncedIsInitialLoading = useDebounce(isInitialLoading, 50);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [campaignUpdate, setCampaignUpdate] = useState<CampaignUpdatedMessage>(
    {} as CampaignUpdatedMessage
  );
  const [direction, setDirection] = useState<string>("desc");
  const [searchText, setSearchText] = useState<string>("");
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleCampaignDelete = async (cam: CampaignDomain): Promise<void> => {
    try {
      await deleteCampaign({
        id: cam.id as string,
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to delete a Campaign: ", err);
      /* eslint-enable no-console */
    }
  };

  const handleCampaignPause = async (cam: CampaignDomain): Promise<void> => {
    try {
      await pauseCampaign({
        id: cam.id as string,
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to pause a Campaign: ", err);
      /* eslint-enable no-console */
    }
  };

  const handleCampaignResume = async (cam: CampaignDomain): Promise<void> => {
    try {
      await resumeCampaign({
        id: cam.id as string,
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to resume a Campaign: ", err);
      /* eslint-enable no-console */
    }
  };

  const handleCampaignCancel = async (cam: CampaignDomain): Promise<void> => {
    try {
      await cancelCampaign({
        id: cam.id as string,
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to cancel a Campaign: ", err);
      /* eslint-enable no-console */
    }
  };

  const hasMoreCampaigns = (
    campaignsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (campaignsAmount < numberOfCampaignsPerLoad) {
      return false;
    }

    if (!currentPageAmount) {
      return false;
    }

    return true;
  };

  const getCampaignsLastRequest = useRef<object>({});

  const fetchMoreCampaigns = async (isReset: boolean = false) => {
    let currentRequest: object | undefined;

    if (isReset) {
      getCampaignsLastRequest.current = {};
      currentRequest = getCampaignsLastRequest.current;
    }

    const nextPage = await CampaignsService.getCampaigns(
      auth0Context,
      direction,
      searchText,
      getStatusesByCategory(activeCampaignCategory),
      isReset ? 0 : campaigns?.length || 0,
      merchant.id
    );

    if (isReset && getCampaignsLastRequest.current !== currentRequest) {
      return;
    }

    const newCampaigns: CampaignDomain[] =
      campaigns.length && !isReset ? campaigns.concat(nextPage) : nextPage;

    dispatch(setCampaigns(newCampaigns));
    setHasNextPage(
      hasMoreCampaigns(newCampaigns.length, nextPage?.length || 0)
    );
    setIsInitialLoading(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const category = queryParams.get("category") as CampaignCategory | null;

    if (!category) {
      dispatch(setActiveCampaignCategory(CampaignCategory.DraftScheduled));
      setSearchParams({
        ...currentSearchParameters,
        category: CampaignCategory.DraftScheduled,
      });
      setIsInitialLoading(true);

      return;
    }

    dispatch(setActiveCampaignCategory(category || undefined));
    setIsInitialLoading(true);
  }, [currentSearchParameters]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const category = queryParams.get("category") as CampaignCategory | null;

    if (activeCampaignCategory && !category) {
      setSearchParams({
        ...currentSearchParameters,
        category: activeCampaignCategory,
      });
    }
  }, [activeCampaignCategory]);

  const actionsArea = (
    <Flex justifyContent="end" alignItems="center">
      <Link to={`/${merchant.id}/campaigns/new`}>
        <Button colorPalette={colorScheme}>Create</Button>
      </Link>
    </Flex>
  );

  const handleCampaignUpdated = (args: CampaignUpdatedMessage) => {
    if (args.campaign.merchant_id !== merchant.id) {
      return;
    }

    setCampaignUpdate(args);
  };

  const handleCampaignProgressUpdated = (
    args: CampaignProgressUpdatedMessage
  ) => {
    const campaign = campaigns.find((c) => c.id === args.campaign_id);

    if (!campaign) {
      return;
    }

    dispatch(
      updateCampaignProgress({
        id: args.campaign_id,
        totalRecipients: args.total_recipients,
        totalProcessed: args.total_processed,
      })
    );
  };

  useEffect(() => {
    addEventHandler("campaign_updated", handleCampaignUpdated);
    addEventHandler("campaign_progress_updated", handleCampaignProgressUpdated);

    return () => {
      removeEventHandler("campaign_updated", handleCampaignUpdated);
      removeEventHandler(
        "campaign_progress_updated",
        handleCampaignProgressUpdated
      );
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    if (Object.keys(campaignUpdate).length !== 0) {
      const campaign: CampaignDomain = campaignTransformFromDtoToDomain(
        campaignUpdate.campaign
      );

      const statuses = getStatusesByCategory(activeCampaignCategory);

      if (!statuses.includes(campaign.status)) {
        return;
      }

      dispatch(propagateCampaignUpdateAction({ campaign }));
    }
  }, [campaignUpdate]);

  // remove this shit?
  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toaster.create({
          type: "success",
          title: toastMessage.success,
        });
      } else if (toastMessage.errors) {
        toaster.create({
          type: "error",
          title: toastMessage.errors[0],
        });
      }
    }
  }, [toastMessage]);

  useEffect(() => {
    if (!activeCampaignCategory) {
      return;
    }

    fetchMoreCampaigns(true);
  }, [searchText, direction, activeCampaignCategory, merchant.id]);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
          })}
    >
      {isBaseSize ? (
        <>
          <Topbar
            title="Campaigns"
            rightChildren={actionsArea}
            rightChildrenMobile={actionsArea}
          />
          <Box
            w="100%"
            h="100%"
            overflowY="auto"
            position="relative"
            ref={scrollContainerRef}
          >
            {isInitialLoading && <SkeletonOverlay />}
            <Box p={2}>
              <FilterSortingArea
                searchText={searchText}
                setSearchText={(newText: string) => {
                  dispatch(setCampaigns([]));
                  setSearchText(newText);
                }}
                direction={direction}
                setDirection={(newDirection: string) => {
                  dispatch(setCampaigns([]));
                  setDirection(newDirection);
                }}
              />
            </Box>
            <Tabs.Root
              lazyMount={true}
              unmountOnExit={true}
              onValueChange={({ value }) => {
                setSearchParams({
                  ...currentSearchParameters,
                  category: getCategoryName(parseInt(value, 10)),
                });
              }}
              value={
                getActiveCampaignCategoryIndex(
                  activeCampaignCategory
                )?.toString() || "0"
              }
              height="100%"
              colorPalette={colorScheme}
              variant="subtle"
              size="lg"
              w="100%"
              fitted
            >
              <Tabs.List justifyContent="center" mx={2}>
                <Tabs.Trigger value="0" fontSize="sm">
                  ✏️ Draft & Scheduled
                </Tabs.Trigger>
                <Tabs.Trigger value="1" fontSize="sm">
                  ✅ Completed
                </Tabs.Trigger>
              </Tabs.List>

              <Tabs.Content value="0" h="100%" w="100%" p={0}>
                <InfinityList
                  scrollContainerRef={scrollContainerRef}
                  campaigns={campaigns}
                  hasNextPage={hasNextPage}
                  fetchMoreCampaigns={fetchMoreCampaigns}
                  handleCampaignDelete={handleCampaignDelete}
                  handleCampaignPause={handleCampaignPause}
                  handleCampaignResume={handleCampaignResume}
                  handleCampaignCancel={handleCampaignCancel}
                />
              </Tabs.Content>
              <Tabs.Content value="1" h="100%" w="100%" p={0}>
                <InfinityList
                  scrollContainerRef={scrollContainerRef}
                  campaigns={campaigns}
                  hasNextPage={hasNextPage}
                  fetchMoreCampaigns={fetchMoreCampaigns}
                  handleCampaignDelete={handleCampaignDelete}
                  handleCampaignPause={handleCampaignPause}
                  handleCampaignResume={handleCampaignResume}
                  handleCampaignCancel={handleCampaignCancel}
                />
              </Tabs.Content>
            </Tabs.Root>
          </Box>
        </>
      ) : (
        <>
          <Flex
            py={4}
            pl="10rem"
            pr={4}
            justifyContent="start"
            flexWrap="wrap"
            gridGap={4}
            alignItems="center"
            width="100%"
          >
            <Link
              to={`/${merchant.id}/campaigns/new`}
              aria-label="Create campaign"
              id="create-campaign-button"
            >
              <Button size="md" colorPalette={colorScheme}>
                <Icon as={LuPlus} />
                Create Campaign
              </Button>
            </Link>
            <FilterSortingArea
              searchText={searchText}
              setSearchText={(newText: string) => {
                dispatch(setCampaigns([]));
                setSearchText(newText);
              }}
              direction={direction}
              setDirection={(newDirection: string) => {
                dispatch(setCampaigns([]));
                setDirection(newDirection);
              }}
            />
          </Flex>
          <Flex
            direction="row"
            overflow="hidden"
            height="100%"
            width="100%"
            minWidth="100%"
            alignItems="start"
          >
            <VStack
              gap={2}
              width="10rem"
              minWidth="10rem"
              maxWidth="10rem"
              pr={2}
            >
              <Button
                pl={8}
                variant={tabIndex === 0 ? "subtle" : "ghost"}
                colorPalette={colorScheme}
                borderLeftRadius={0}
                justifyContent="start"
                alignItems="center"
                w="100%"
                onClick={() => {
                  setTabIndex(0);
                }}
                color={
                  tabIndex !== 0
                    ? colorMode === "dark"
                      ? "gray.200"
                      : "gray.600"
                    : colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`
                }
              >
                <Icon
                  as={FaPencilAlt}
                  mr={2}
                  width="1rem"
                  height="1rem"
                  fill={
                    tabIndex !== 0
                      ? colorMode === "dark"
                        ? "gray.200"
                        : "gray.600"
                      : colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                />
                <Text>Campaigns</Text>
              </Button>
              <Button
                pl={8}
                w="100%"
                borderLeftRadius={0}
                variant={tabIndex === 1 ? "subtle" : "ghost"}
                colorPalette={colorScheme}
                justifyContent="start"
                alignItems="center"
                onClick={() => {
                  setTabIndex(1);
                }}
              >
                <Icon
                  as={IoAnalytics}
                  mr={2}
                  width="1rem"
                  height="1rem"
                  fill={
                    tabIndex !== 1
                      ? colorMode === "dark"
                        ? "gray.200"
                        : "gray.600"
                      : colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                />
                <Text
                  color={
                    tabIndex !== 1
                      ? colorMode === "dark"
                        ? "gray.200"
                        : "gray.600"
                      : colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                >
                  Analytics
                </Text>
              </Button>
            </VStack>
            <VStack
              gap={0}
              separator={<Separator />}
              borderTopRadius="3xl"
              bgColor={colorMode === "dark" ? "gray.800" : "white"}
              w="100%"
              h="100%"
              overflowY="hidden"
              mr={4}
            >
              {tabIndex === 0 ? (
                <>
                  <Flex
                    gridGap={2}
                    justifyContent="start"
                    alignItems="center"
                    w="100%"
                    p={4}
                    borderBottomWidth="1px"
                    borderBottomStyle="solid"
                    borderBottomColor={
                      colorMode === "dark" ? "gray.700" : "gray.200"
                    }
                  >
                    <Button
                      variant={
                        activeCampaignCategory ===
                        CampaignCategory.DraftScheduled
                          ? "subtle"
                          : "ghost"
                      }
                      onClick={() => {
                        setSearchParams({
                          ...currentSearchParameters,
                          category: getCategoryName(0),
                        });
                      }}
                      colorPalette={colorScheme}
                    >
                      Draft & Scheduled ✏️
                    </Button>
                    <Button
                      variant={
                        activeCampaignCategory === CampaignCategory.Done
                          ? "subtle"
                          : "ghost"
                      }
                      onClick={() => {
                        setSearchParams({
                          ...currentSearchParameters,
                          category: getCategoryName(1),
                        });
                      }}
                      colorPalette={colorScheme}
                    >
                      Done ✅
                    </Button>
                  </Flex>
                  <Flex
                    w="100%"
                    h="100%"
                    direction="column"
                    position="relative"
                  >
                    {isInitialLoading && <SkeletonOverlay />}
                    <Box
                      w="100%"
                      h="100%"
                      mb={16}
                      ref={scrollContainerRef}
                      overflowY="auto"
                    >
                      <InfinityList
                        scrollContainerRef={scrollContainerRef}
                        campaigns={campaigns}
                        hasNextPage={hasNextPage}
                        fetchMoreCampaigns={fetchMoreCampaigns}
                        handleCampaignDelete={handleCampaignDelete}
                        handleCampaignPause={handleCampaignPause}
                        handleCampaignResume={handleCampaignResume}
                        handleCampaignCancel={handleCampaignCancel}
                      />
                    </Box>
                  </Flex>
                </>
              ) : (
                <Box w="100%" h="100%" mb={16} overflowY="auto">
                  <StatsSection />
                </Box>
              )}
            </VStack>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default NewCampaignsList;
