import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Separator,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import Topbar from "components/shared/topbar/TopBar";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BackIconButton from "components/shared/BackButton";
import TiersBar from "components/shared/TiersBar";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import WhatsappProfile, {
  MessagingLimit,
  WhatsappBusinessVertical,
} from "entities/domain/whatsapp";
import { FaLink, FaTag } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import Select, { components, OptionProps, SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import IntegrationService from "services/integrations";
import { getReactSelectStyles } from "util/methods";
import UploadComponent from "../TeamMemberSettings/UploadComponent";

interface WhatsappIntegrationProps {}

interface BusinessVerticalOption {
  value: WhatsappBusinessVertical;
  label: string;
}

type EmojiByVertical = {
  [key in WhatsappBusinessVertical]: string;
};

type LabelByVertical = {
  [key in WhatsappBusinessVertical]: string;
};

const VERTICAL_LABEL: LabelByVertical = {
  UNDEFINED: "Undefined",
  AUTO: "Auto",
  BEAUTY: "Beauty",
  APPAREL: "Apparel",
  EDU: "Education",
  ENTERTAIN: "Entertainment",
  EVENT_PLAN: "Event Planning",
  FINANCE: "Finance",
  GROCERY: "Grocery",
  GOVT: "Government",
  HOTEL: "Hotel",
  HEALTH: "Health",
  NONPROFIT: "Nonprofit",
  PROF_SERVICES: "Professional Services",
  RETAIL: "Retail",
  TRAVEL: "Travel",
  RESTAURANT: "Restaurant",
  NOT_A_BIZ: "Not a business",
  OTHER: "Other",
};

const MAX_ADDRESS_LENGTH = 256;
const MAX_DESCRIPTION_LENGTH = 512;
const MAX_EMAIL_LENGTH = 128;
const MAX_WEBSITE_LENGTH = 256;
const MAX_ABOUT_LENGTH = 139;

const VerticalColumn = ({ item }: { item: BusinessVerticalOption }) => {
  const emojiByVertical: EmojiByVertical = {
    UNDEFINED: "❓",
    AUTO: "🚗",
    BEAUTY: "💅",
    APPAREL: "👗",
    EDU: "📚",
    ENTERTAIN: "🎭",
    EVENT_PLAN: "🎉",
    FINANCE: "💰",
    GROCERY: "🛒",
    GOVT: "🏛️",
    HOTEL: "🏨",
    HEALTH: "🏥",
    NONPROFIT: "🤝",
    PROF_SERVICES: "👨‍💼",
    RETAIL: "🛍️",
    TRAVEL: "✈️",
    RESTAURANT: "🍽️",
    NOT_A_BIZ: "🚫",
    OTHER: "🌐",
  };

  return (
    <HStack
      justifyContent="start"
      alignItems="center"
      gap={4}
      px={2}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Text>{emojiByVertical[item.value]}</Text>
      <Text>{item.label}</Text>
    </HStack>
  );
};

const VerticalOption = function (props: OptionProps<BusinessVerticalOption>) {
  return (
    <components.Option {...props}>
      <VerticalColumn item={props.data} />
    </components.Option>
  );
};

const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

const WhatsappIntegration = (_props: WhatsappIntegrationProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const { images } = useAppSelector((state) => state.attachments);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [vertical, setVertical] = useState<
    WhatsappBusinessVertical | undefined
  >();
  const [selectedVertical, setSelectedVertical] =
    useState<SingleValue<BusinessVerticalOption> | null>(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    undefined
  );
  const [aboutText, setAboutText] = useState<string>("");
  const [currentLimit, setCurrentLimit] = useState<
    MessagingLimit | undefined
  >();
  const [currentQualityRating, setCurrentQualityRating] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [websites, setWebsites] = useState<string[]>([]);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(
    !email || isValidEmail(email)
  );

  const verticalOptions = Object.values(WhatsappBusinessVertical).map((v) => ({
    value: v,
    label: VERTICAL_LABEL[v],
  }));

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsEmailValid(!email || isValidEmail(email));
  }, [email]);

  useEffect(() => {
    if (!aboutText || !isEmailValid) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [aboutText, isEmailValid]);

  useEffect(() => {
    if (vertical) {
      setSelectedVertical(
        verticalOptions.find((v) => v.value === vertical) || null
      );
    }
  }, [vertical]);

  const fetchWhatsappInfo = async () => {
    try {
      const fetchedWhatsappInfo = await IntegrationService.getWhatsappInfo(
        auth0Context,
        merchant.id
      );

      setPhoneNumber(fetchedWhatsappInfo.phoneNumber);
      setCurrentLimit(fetchedWhatsappInfo.currentLimit);
      setCurrentQualityRating(fetchedWhatsappInfo.currentQualityRating);
      setEmail(fetchedWhatsappInfo.email);
      setVertical(fetchedWhatsappInfo.vertical);
      setProfilePictureUrl(fetchedWhatsappInfo.profilePictureUrl);
      setAboutText(fetchedWhatsappInfo.aboutText);
      setAddress(fetchedWhatsappInfo.address);
      setDescription(fetchedWhatsappInfo.description);
      setWebsites(fetchedWhatsappInfo.websites);
    } catch (error: unknown) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not fetch WhatsApp info at this time. Please try again later or contact our customer support!",
      });
      setIsDisabled(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsDisabled(true);

    try {
      await IntegrationService.updateWhatsappInfo(auth0Context, merchant.id, {
        email,
        vertical,
        profilePicture,
        aboutText,
        address,
        description,
        websites,
      });
      toaster.create({
        type: "success",
        title: "WhatsApp information updated successfully!",
      });
      navigate(`/${merchant.id}/settings/channels`);
    } catch (error: unknown) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not update WhatsApp information at this time. Please try again later or contact our customer support!",
      });
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (!merchant.isWhatsappInfoEnabled()) {
      navigate(`/${merchant.id}/settings/channels`);

      return;
    }

    fetchWhatsappInfo();
  }, [merchant.id, merchant.integrations]);

  return (
    <Flex direction="column" w="100%" h="100%">
      <Topbar
        title="WhatsApp for Business Profile"
        isFlex={true}
        leftChildrenMobile={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/channels`);
            }}
          />
        }
        leftChildren={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate(`/${merchant.id}/settings/channels`);
            }}
          />
        }
      />

      <HStack
        width="100%"
        flexGrow={1}
        alignItems="start"
        overflowY="auto"
        gap={16}
        px={isBaseSize ? 0 : 8}
        justifyContent="center"
      >
        <VStack
          alignItems="start"
          justifyContent="start"
          gap={4}
          width={isBaseSize ? "90%" : "60%"}
          mx={isBaseSize ? "auto" : 0}
          py={8}
        >
          <Field label="Phone Number">
            <Text fontWeight="bold">{phoneNumber || "N/A"}</Text>
          </Field>
          <Field label="Quality Rating">
            <Text
              fontWeight="bold"
              color={
                colorMode === "dark"
                  ? `${WhatsappProfile.getQualityRatingColorScheme(
                      currentQualityRating
                    )}.200`
                  : `${WhatsappProfile.getQualityRatingColorScheme(
                      currentQualityRating
                    )}.500`
              }
            >
              {currentQualityRating || "N/A"}
            </Text>
          </Field>
          <Field label="Limit per 24 hours">
            <TiersBar
              tiers={[
                {
                  description:
                    "Allows you to send WhatsApp Broadcasts to 250 unique users in a rolling 24h period.",
                  name: "Tier 1",
                  isActive:
                    currentLimit === MessagingLimit.TIER_50 ||
                    currentLimit === MessagingLimit.TIER_250,
                },
                {
                  description:
                    "Allows you to send WhatsApp Broadcasts to 1000 unique users in a rolling 24h period.",
                  name: "Tier 2",
                  isActive: currentLimit === MessagingLimit.TIER_1K,
                },
                {
                  description:
                    "Allows you to send WhatsApp Broadcasts to 10,000 unique users in a rolling 24h period.",
                  name: "Tier 3",
                  isActive: currentLimit === MessagingLimit.TIER_10K,
                },
                {
                  description:
                    "Allows you to send WhatsApp Broadcasts to 100,000 unique users in a rolling 24h period.",
                  name: "Tier 4",
                  isActive:
                    currentLimit === MessagingLimit.TIER_100K ||
                    currentLimit === MessagingLimit.TIER_UNLIMITED,
                },
              ]}
            />
          </Field>
          <Field label="Business Logo">
            <UploadComponent
              profilePicSrc={
                profilePicture ? images[0] : profilePictureUrl || ""
              }
              onButtonClick={() => {
                inputRef?.current?.click();
              }}
              setProfilePicture={setProfilePicture}
              inputRef={inputRef}
            />
          </Field>
          <Field label={`About ${merchant.name}`} required={true}>
            <Input
              placeholder={`One sentence about ${merchant.name}`}
              value={aboutText}
              disabled={isDisabled}
              onChange={(e) =>
                setAboutText(e.target.value.slice(0, MAX_ABOUT_LENGTH))
              }
            />
          </Field>
          <Heading as="h3" size="md">
            Business Details
          </Heading>
          <Field label="Email" invalid={!isEmailValid}>
            <Input
              type="email"
              placeholder="example@example.com"
              value={email}
              disabled={isDisabled}
              onChange={(e) =>
                setEmail(e.target.value.slice(0, MAX_EMAIL_LENGTH))
              }
            />
          </Field>
          <Field label="Business Vertical">
            <Select
              isMulti={false}
              placeholder="Select Vertical"
              onChange={(verticalOption) =>
                setVertical(verticalOption ? verticalOption.value : undefined)
              }
              options={verticalOptions}
              value={selectedVertical}
              styles={{
                ...{
                  ...getReactSelectStyles(colorMode || "light", colorScheme),
                  container: (provided: any) => ({
                    ...provided,
                    width: "100%",
                  }),
                },
              }}
              isDisabled={isDisabled}
              components={{
                Option: VerticalOption,
              }}
            />
          </Field>
          <Field label="Description">
            <Input
              placeholder="A brief description of your business"
              value={description}
              disabled={isDisabled}
              onChange={(e) =>
                setDescription(e.target.value.slice(0, MAX_DESCRIPTION_LENGTH))
              }
            />
          </Field>
          <Field label="Address">
            <Input
              placeholder="123 High Street London W1 4AZ"
              value={address}
              disabled={isDisabled}
              onChange={(e) =>
                setAddress(e.target.value.slice(0, MAX_ADDRESS_LENGTH))
              }
            />
          </Field>
          <Field label="Website (Primary)">
            <Input
              placeholder="https://yourwebsite.com"
              value={websites[0]}
              disabled={isDisabled}
              onChange={(e) =>
                setWebsites([
                  e.target.value.slice(0, MAX_WEBSITE_LENGTH),
                  websites[1],
                ])
              }
            />
          </Field>
          <Field label="Website (Secondary)">
            <Input
              placeholder="https://yoursecondwebsite.com"
              value={websites[1]}
              disabled={isDisabled}
              onChange={(e) =>
                setWebsites([
                  websites[0],
                  e.target.value.slice(0, MAX_WEBSITE_LENGTH),
                ])
              }
            />
          </Field>
        </VStack>
        {isBaseSize ? null : (
          <VStack mt={8} gap={4} alignItems="center" justifyContent="start">
            <Heading as="h3" size="md">
              Profile Preview
            </Heading>
            <VStack
              gap={4}
              borderRadius="xl"
              overflow="hidden"
              borderWidth="1px"
              borderStyle="solid"
              borderColor={colorMode === "dark" ? "gray.700" : "gray.50"}
              maxWidth="300px"
              pb={4}
            >
              <Image
                src={profilePictureUrl}
                objectFit="cover"
                width="300px"
                height="200px"
                // fallback={<Box width="300px" height="200px" bg="gray.200" />}
              />
              <Text px={4} w="100%" textAlign="left">
                Business account
              </Text>
              <Separator />
              <HStack px={4} w="100%" justifyContent="start" alignItems="start">
                <Icon
                  as={IoDocument}
                  fill={colorMode === "dark" ? "gray.600" : "gray.200"}
                  mt={1} // Hate this but can't come up with better solution
                  // We need to allow the whole section to go beyond 1 line
                />
                <Text>{description || "N/A"}</Text>
              </HStack>
              <HStack
                px={4}
                w="100%"
                justifyContent="start"
                alignItems="center"
              >
                <Icon
                  as={FaTag}
                  fill={colorMode === "dark" ? "gray.600" : "gray.200"}
                />
                <Text>{vertical ? VERTICAL_LABEL[vertical] : "N/A"}</Text>
              </HStack>
              {websites.map((website, index) => (
                <HStack
                  px={4}
                  key={`${website}-${index}`}
                  w="100%"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Icon
                    as={FaLink}
                    fill={colorMode === "dark" ? "gray.600" : "gray.200"}
                  />
                  <Link href={website} target="_blank">
                    {website}
                  </Link>
                </HStack>
              ))}
            </VStack>
          </VStack>
        )}
      </HStack>
      <Separator />
      <Flex
        w={{ base: "90%", xl: "60%" }}
        mx="auto"
        justifyContent="end"
        py={6}
      >
        <Button
          colorPalette={colorScheme}
          onClick={handleSave}
          disabled={isDisabled || isLoading || !isValid}
          width={{ base: "full", md: "fit-content" }}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default WhatsappIntegration;
