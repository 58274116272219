import { Input } from "@chakra-ui/react";

import { ClipboardIconButton, ClipboardRoot } from "components/ui/clipboard";
import { InputGroup } from "components/ui/input-group";
import FileDomain from "entities/domain/file";
import React from "react";

const FileUrlColumn = ({ item }: { item: FileDomain }) => {
  return (
    <ClipboardRoot value={item.url}>
      <InputGroup endElement={<ClipboardIconButton />}>
        <Input
          value={item.url}
          disabled={true}
          size="sm"
          pr="2rem"
          textOverflow="ellipsis"
        />
      </InputGroup>
    </ClipboardRoot>
  );
};

export default FileUrlColumn;
