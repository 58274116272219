import type { VictoryThemeDefinition } from "victory";

const customTheme: VictoryThemeDefinition = {
  candlestick: {
    style: {
      data: {
        stroke: "#455A64",
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 5,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    candleColors: {
      positive: "#ffffff",
      negative: "#455A64",
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  errorbar: {
    borderWidth: 8,
    style: {
      data: {
        fill: "transparent",
        opacity: 1,
        stroke: "#455A64",
        strokeWidth: 2,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  palette: {
    colors: {
      blue: "#4F7DA1",
      pink: "#E2A37F",
      teal: "#00796B",
      purple: "#DF948A",
      green: "#8BC34A",
      orange: "#F4511E",
      cyan: "#006064",
      red: "#DF5A49",
      yellow: "#FFF59D",
    },
    grayscale: ["#ECEFF1", "#90A4AE", "#455A64", "#212121"],
    qualitative: [
      "#334D5C",
      "#45B29D",
      "#EFC94C",
      "#E27A3F",
      "#DF5A49",
      "#4F7DA1",
      "#55DBC1",
      "#EFDA97",
      "#E2A37F",
      "#DF948A",
    ],
    heatmap: ["#428517", "#77D200", "#D6D305", "#EC8E19", "#C92B05"],
    warm: ["#940031", "#C43343", "#DC5429", "#FF821D", "#FFAF55"],
    cool: ["#2746B9", "#0B69D4", "#2794DB", "#31BB76", "#60E83B"],
    red: ["#FCAE91", "#FB6A4A", "#DE2D26", "#A50F15", "#750B0E"],
    green: ["#354722", "#466631", "#649146", "#8AB25C", "#A9C97E"],
    blue: ["#002C61", "#004B8F", "#006BC9", "#3795E5", "#65B4F4"],
  },
  area: {
    style: {
      data: {
        fill: "#212121",
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: "#90A4AE",
        strokeWidth: 0,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      axisLabel: {
        textAnchor: "middle",
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
      grid: {
        fill: "none",
        stroke: "#ECEFF1",
        strokeWidth: 0,
        strokeDasharray: "10, 5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 5,
        stroke: "#90A4AE",
        strokeWidth: 0,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      tickLabels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        display: "none",
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  polarDependentAxis: {
    style: {
      ticks: {
        fill: "transparent",
        size: 0,
        stroke: "transparent",
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: "#455A64",
        padding: 8,
        strokeWidth: 0,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  boxplot: {
    style: {
      max: {
        padding: 8,
        stroke: "#455A64",
        strokeWidth: 0,
      },
      maxLabels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 3,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
      median: {
        padding: 8,
        stroke: "#455A64",
        strokeWidth: 0,
      },
      medianLabels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 3,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
      min: {
        padding: 8,
        stroke: "#455A64",
        strokeWidth: 0,
      },
      minLabels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 3,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
      q1: {
        padding: 8,
        fill: "#455A64",
      },
      q1Labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 3,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
      q3: {
        padding: 8,
        fill: "#455A64",
      },
      q3Labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 3,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    boxWidth: 20,
    width: 350,
    height: 350,
    padding: 50,
  },
  chart: {
    width: 350,
    height: 350,
    padding: 50,
  },
  group: {
    colorScale: [
      "#F4511E",
      "#FFF59D",
      "#DCE775",
      "#8BC34A",
      "#00796B",
      "#006064",
    ],
    width: 350,
    height: 350,
    padding: 50,
  },
  histogram: {
    style: {
      data: {
        fill: "#455A64",
        stroke: "#212121",
        strokeWidth: 2,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  legend: {
    colorScale: [
      "#F4511E",
      "#FFF59D",
      "#DCE775",
      "#8BC34A",
      "#00796B",
      "#006064",
    ],
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "circle",
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 8,
        letterSpacing: "normal",
        padding: 0,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
      title: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 5,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
  },
  line: {
    style: {
      data: {
        fill: "transparent",
        opacity: 1,
        stroke: "#455A64",
        strokeWidth: 2,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  pie: {
    colorScale: [
      "#F4511E",
      "#FFF59D",
      "#DCE775",
      "#8BC34A",
      "#00796B",
      "#006064",
    ],
    style: {
      data: {
        padding: 0,
        stroke: "#ECEFF1",
        strokeWidth: 0,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 16,
        fontWeight: "bold",
        letterSpacing: "normal",
        padding: 16,
        fill: "white",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  scatter: {
    style: {
      data: {
        fill: "#455A64",
        opacity: 1,
        stroke: "transparent",
        strokeWidth: 0,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 8,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
  stack: {
    colorScale: [
      "#F4511E",
      "#FFF59D",
      "#DCE775",
      "#8BC34A",
      "#00796B",
      "#006064",
    ],
    width: 350,
    height: 350,
    padding: 50,
  },
  tooltip: {
    style: {
      fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
      fontSize: 12,
      letterSpacing: "normal",
      padding: 0,
      fill: "#455A64",
      stroke: "transparent",
      strokeWidth: 0,
      pointerEvents: "none",
    },
    flyoutStyle: {
      stroke: "#212121",
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none",
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: {
    style: {
      data: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 0,
      },
      labels: {
        fontFamily: "'Helvetica Neue', 'Helvetica', sans-serif",
        fontSize: 12,
        letterSpacing: "normal",
        padding: 5,
        fill: "#455A64",
        stroke: "transparent",
        strokeWidth: 0,
        pointerEvents: "none",
      },
      flyout: {
        stroke: "#212121",
        strokeWidth: 1,
        fill: "#f0f0f0",
        pointerEvents: "none",
      },
    },
    width: 350,
    height: 350,
    padding: 50,
  },
};

export default customTheme;
