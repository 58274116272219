import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import TemplateDomain from "entities/domain/templates";
import { StringArraySupportOption } from "prettier";
import { RootState } from "redux/store";
import { sortConversations } from "util/conversations";

export const TEXT_LIMITS_PER_CHANNEL = {
  whatsapp: 1600,
};

export interface AssignAgentPayload {
  agentId?: number | null;
  teamId?: string | null;
  conversationId: number;
}

export enum ConversationTab {
  Personal = "personal",
  Team = "team",
  Unassigned = "unassigned",
}

export enum OpenClosedFilter {
  Open = "open",
  Closed = "closed",
}

interface CreateConversationState {
  isVisible: boolean;
  loading: boolean;
  errors: string[];
}

export interface MessageInputAttachment {
  id: string;
  url: string;
  type: string;
}

export enum MessageInputTab {
  EDIT = 0,
  PREVIEW = 1,
}

export interface MessageInputState {
  text: string;
  attachments: MessageInputAttachment[];
  template: TemplateDomain | null;
  subject: string;
  activeTab: MessageInputTab;
  isPreparingInput: boolean;
  replySuggestionId?: string;
  replySuggestionCustomFields?: Record<string, string>;
}

export const isMessageInputState = (state: any): state is MessageInputState => {
  return (
    state &&
    typeof state.text === "string" &&
    Array.isArray(state.attachments) &&
    (state.template === null || typeof state.template === "object") &&
    (state.subject === null || typeof state.subject === "string") &&
    (state.activeTab === MessageInputTab.EDIT ||
      state.activeTab === MessageInputTab.PREVIEW)
  );
};

export const isMessageInputStateEmpty = (state: MessageInputState) => {
  return (
    state.text === "" &&
    state.attachments.length === 0 &&
    state.template === null &&
    !state.subject
  );
};

export const getMessageInputStateFromLocalStorage = (
  id: string
): MessageInputState | null => {
  if (!id) {
    return null;
  }

  try {
    const draftState = localStorage.getItem(id);

    if (draftState) {
      const messageInputState = JSON.parse(draftState);

      if (!isMessageInputState(messageInputState)) {
        localStorage.removeItem(id);

        return null;
      }

      return {
        ...messageInputState,
        template: messageInputState.template
          ? Object.setPrototypeOf(
              messageInputState.template,
              TemplateDomain.prototype
            )
          : null,
      };
    }
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error("Couldn't restore draft state", error);
  }

  return null;
};

interface ConversationsState {
  conversations: ConversationDomain[];
  selectedConversationIds: number[];
  loading: boolean;
  errors: string[];
  activeConversationId: number | undefined;
  activeConversation: ConversationDomain | undefined;
  isLoadingActiveConversation: boolean;
  templates: { [key: number]: TemplateDomain[] };
  searchText: string;
  activeTab: ConversationTab | undefined;
  new: CreateConversationState;
  isOpenOrClosed: OpenClosedFilter;
  messageInput: MessageInputState;
  filterChannels: string[];
  filterAgents: string[];
  filterCustomerTagIds: string[];
  bulkActionsToolbarEnabled: boolean;
  selectedInbox: string | null;
  bulkActionsToolbarLoading: boolean;
}

const initialState: ConversationsState = {
  new: {
    isVisible: false,
    loading: false,
    errors: [],
  },
  messageInput: {
    text: "",
    attachments: [],
    subject: "",
    template: null,
    activeTab: MessageInputTab.EDIT,
    isPreparingInput: false,
  },
  conversations: [],
  selectedConversationIds: [],
  loading: true,
  searchText: "",
  selectedInbox: null,
  errors: [],
  activeConversationId: undefined,
  activeConversation: undefined,
  isLoadingActiveConversation: false,
  templates: {},
  activeTab: undefined,
  isOpenOrClosed: OpenClosedFilter.Open,
  filterChannels: [],
  filterAgents: [],
  filterCustomerTagIds: [],
  bulkActionsToolbarEnabled: true,
  bulkActionsToolbarLoading: false,
};

const getRefreshedConversations = (
  updatedConversation: ConversationDomain,
  currentAgentId: number,
  {
    conversations,
    isOpenOrClosed,
    activeTab,
    searchText,
    filterChannels,
    filterAgents,
    filterCustomerTagIds,
    selectedInbox,
  }: Draft<ConversationsState>
) => {
  const alreadyExists = conversations.find(
    (c) => c.id === updatedConversation.id
  );

  if (alreadyExists) {
    conversations = conversations.map((c) =>
      c.id === updatedConversation.id ? updatedConversation : c
    );
  } else {
    conversations = [updatedConversation, ...conversations];
  }

  return conversations
    .filter((c) =>
      isOpenOrClosed === OpenClosedFilter.Open ? c.isOpen : !c.isOpen
    )
    .filter((c) => {
      if (activeTab === undefined) {
        return true;
      }

      if (activeTab === ConversationTab.Personal) {
        return c.assignedAgentId === currentAgentId;
      }

      if (activeTab === ConversationTab.Unassigned) {
        return c.assignedAgentId === null;
      }

      return true;
    })
    .filter((c) =>
      searchText
        ? c.displayName.toLowerCase().includes(searchText.toLowerCase())
        : true
    )
    .filter((c) =>
      filterChannels.length ? filterChannels.includes(c.channel) : true
    )
    .filter((c) => (selectedInbox ? c.assignedTeamId === selectedInbox : true))
    .filter((c) =>
      filterAgents.length
        ? filterAgents.includes((c.assignedAgentId || "unassigned").toString())
        : true
    )
    .filter((c) =>
      filterCustomerTagIds.length && c.tagIds.length
        ? c.tagIds.some((tagId) => filterCustomerTagIds.includes(tagId))
        : true
    )
    .sort(sortConversations);
};

const sortTemplatesAlphabetically = (
  templateA: TemplateDomain,
  templateB: TemplateDomain
) => {
  return (templateA.name || templateA.title).localeCompare(
    templateB.name || templateB.title
  );
};

export const appendOrUpdateTemplate = (
  template: TemplateDomain,
  templates: TemplateDomain[]
): TemplateDomain[] => {
  if (!templates) {
    return [];
  }

  let newTemplates = [...templates];

  if (templates.find((t) => t.id === template.id)) {
    newTemplates = newTemplates.map((t) =>
      template.id === t.id ? template : t
    );
  } else {
    newTemplates = [...newTemplates, template];
  }

  return [
    ...newTemplates
      .filter((t) => t.favourite)
      .sort(sortTemplatesAlphabetically),
    ...newTemplates
      .filter((t) => !t.favourite)
      .sort(sortTemplatesAlphabetically),
  ];
};

const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    fetchConversations: (state) => {
      state.loading = true;
    },
    fetchConversationsSuccess: (state) => {
      state.loading = false;
    },
    fetchConversationsFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.errors = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload.trim().replace(/\s{2,}/g, " ");
    },
    clearMessageInput: (state) => {
      state.messageInput.text = "";
      state.messageInput.attachments = [];
      state.messageInput.template = null;
      state.messageInput.subject = "";
      state.messageInput.activeTab = MessageInputTab.EDIT;
      state.messageInput.replySuggestionId = undefined;
      state.messageInput.replySuggestionCustomFields = undefined;
    },
    setMessageInputState: (state, action: PayloadAction<MessageInputState>) => {
      state.messageInput = action.payload;
    },
    setMessageInputReplySuggestionCustomFields: (
      state,
      action: PayloadAction<Record<string, string> | undefined>
    ) => {
      state.messageInput.replySuggestionCustomFields = action.payload;
    },
    setMessageInputIsPreparing: (state, action: PayloadAction<boolean>) => {
      state.messageInput.isPreparingInput = action.payload;
    },
    setMessageInputTemplate: (
      state,
      action: PayloadAction<TemplateDomain | null>
    ) => {
      state.messageInput.template = action.payload;
    },
    clearAttahmentsFromMessageInput: (state) => {
      state.messageInput.attachments = [];
    },
    changeMessageInputText: (state, action: PayloadAction<string>) => {
      state.messageInput.text = action.payload;
    },
    changeMessageInputSubject: (state, action: PayloadAction<string>) => {
      state.messageInput.subject = action.payload;
    },
    updateMessageInputActiveTab: (
      state,
      action: PayloadAction<MessageInputTab>
    ) => {
      state.messageInput.activeTab = action.payload;
    },
    addAttachmentToMessageInput: (
      state,
      action: PayloadAction<MessageInputAttachment>
    ) => {
      state.messageInput.attachments = [action.payload]; // Because we don't allow more than 1 at the moment
    },
    removeAttachmentFromMessageInput: (
      state,
      action: PayloadAction<number>
    ) => {
      state.messageInput.attachments = state.messageInput.attachments.filter(
        (_, index) => index !== action.payload
      );
    },
    setActiveConversation: (
      state,
      action: PayloadAction<{
        conversationId: number | undefined;
        isPreloaded?: boolean;
      }>
    ) => {
      if (action.payload.conversationId && action.payload.isPreloaded) {
        state.activeConversationId = action.payload.conversationId;
        state.activeConversation = state.conversations.find(
          (c) => c.id === action.payload.conversationId
        );
        state.isLoadingActiveConversation = false;
      } else {
        state.activeConversationId = action.payload.conversationId;
        state.activeConversation = state.conversations.find(
          (c) => c.id === action.payload.conversationId
        );
        state.isLoadingActiveConversation = true;
      }
    },
    setSelectedInbox: (state, action: PayloadAction<string | null>) => {
      state.selectedInbox = action.payload;
    },
    setConversations: (state, action: PayloadAction<ConversationDomain[]>) => {
      state.conversations = action.payload;
    },
    bulkAppendOrReplaceConversations: (
      state,
      action: PayloadAction<{
        conversations: ConversationDomain[];
        currentAgentId: number;
      }>
    ) => {
      const currentConversationsLength =
        state.conversations.length !== 0
          ? state.conversations.length
          : action.payload.conversations.length;

      action.payload.conversations.forEach((conversation) => {
        state.conversations = getRefreshedConversations(
          conversation,
          action.payload.currentAgentId,
          state
        );
      });

      state.conversations = state.conversations.slice(
        0,
        currentConversationsLength
      );
    },
    appendOrReplaceConversation: (
      state,
      action: PayloadAction<{
        conversation: ConversationDomain;
        currentAgentId: number;
      }>
    ) => {
      state.conversations = getRefreshedConversations(
        action.payload.conversation,
        action.payload.currentAgentId,
        state
      );

      if (action.payload.conversation.id === state.activeConversationId) {
        state.isLoadingActiveConversation = false;
        state.activeConversation = action.payload.conversation;
      }
    },
    updateConversationIsSubscribed: (
      state,
      action: PayloadAction<{
        conversationId: number;
        isSubscribed: boolean;
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.conversationId) {
          c.isSubscribed = action.payload.isSubscribed;
        }
        return c;
      });
    },
    updateConversationIsOpen: (
      state,
      action: PayloadAction<{
        conversationId: number;
        isOpen: boolean;
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.conversationId) {
          c.isOpen = action.payload.isOpen;
        }
        return c;
      });
    },
    updateConversationTagIds: (
      state,
      action: PayloadAction<{
        conversationId: number;
        tagIds: string[];
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.conversationId) {
          c.tagIds = action.payload.tagIds;
        }
        return c;
      });
    },
    updateConversationIsRead: (
      state,
      action: PayloadAction<{
        conversationId: number;
        isRead: boolean;
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.conversationId) {
          c.unreadCount = action.payload.isRead ? 0 : 1;
        }
        return c;
      });
    },
    updateConversationAgentAndTeamIds: (
      state,
      action: PayloadAction<{
        conversationId: number;
        agentId: number | null;
        teamId: string | null;
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.conversationId) {
          c.assignedAgentId = action.payload.agentId;
          c.assignedTeamId = action.payload.teamId;
        }
        return c;
      });
    },
    clearTemplates: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload) {
        state.templates[action.payload] = [];
      } else if (Object.keys(state.templates).length > 20) {
        state.templates = {};
      }
    },
    setTemplates: (
      state,
      action: PayloadAction<{
        templates: TemplateDomain[];
        conversationId: number;
      }>
    ) => {
      state.templates[action.payload.conversationId] = action.payload.templates;
    },
    propagateTemplateUpdate: (
      state,
      action: PayloadAction<{
        template: TemplateDomain;
        conversationId: number;
      }>
    ) => {
      state.templates[action.payload.conversationId] = appendOrUpdateTemplate(
        action.payload.template,
        state.templates[action.payload.conversationId]
      );
    },
    setActiveConversationTab: (
      state,
      action: PayloadAction<ConversationTab | undefined>
    ) => {
      state.activeTab = action.payload;
    },
    setActiveConversationOpenClosedFilter: (
      state,
      action: PayloadAction<OpenClosedFilter>
    ) => {
      state.isOpenOrClosed = action.payload;
    },
    setFilterChannels: (state, action: PayloadAction<string[]>) => {
      state.filterChannels = action.payload;
    },
    setFilterAgents: (state, action: PayloadAction<string[]>) => {
      state.filterAgents = action.payload;
    },
    setFilterCustomerTagIds: (state, action: PayloadAction<string[]>) => {
      state.filterCustomerTagIds = action.payload;
    },
    updateConversationSelection: (
      state,
      action: PayloadAction<{ conversationId: number; isSelected: boolean }>
    ) => {
      if (
        action.payload.isSelected &&
        !state.selectedConversationIds.includes(action.payload.conversationId)
      ) {
        state.selectedConversationIds.push(action.payload.conversationId);
      } else if (!action.payload.isSelected) {
        state.selectedConversationIds = state.selectedConversationIds.filter(
          (id) => id !== action.payload.conversationId
        );
      }
    },
    clearSelectedConversations: (state) => {
      state.selectedConversationIds = [];
    },
    markConversationsAsRead: (state, action: PayloadAction<number[]>) => {
      state.conversations = state.conversations.map((c) => {
        if (action.payload.includes(c.id)) {
          c.unreadCount = 0;
        }
        return c;
      });
    },
    markConversationsAsUnread: (state, action: PayloadAction<number[]>) => {
      state.conversations = state.conversations.map((c) => {
        if (action.payload.includes(c.id) && c.unreadCount === 0) {
          c.unreadCount = 1;
        }
        return c;
      });
    },
    markConversationsAsOpenedOrClosed: (
      state,
      action: PayloadAction<number[]>
    ) => {
      state.conversations = state.conversations.filter((c) => {
        return !action.payload.includes(c.id);
      });
    },
    assignAgentToConversations: (
      state,
      action: PayloadAction<{
        agentId: number | null;
        conversationIds: number[];
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (action.payload.conversationIds.includes(c.id)) {
          c.assignedAgentId = action.payload.agentId;
        }
        return c;
      });
    },
    enableBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarEnabled = true;
    },
    disableBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarEnabled = false;
    },
    startLoadingBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarLoading = true;
    },
    stopLoadingBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarLoading = false;
    },
    resetStore: (state) => {
      state.new = {
        isVisible: false,
        loading: false,
        errors: [],
      };
      state.conversations = [];
      state.selectedConversationIds = [];
      state.loading = true;
      state.searchText = "";
      state.selectedInbox = null;
      state.errors = [];
      state.activeConversationId = undefined;
      state.activeConversation = undefined;
      state.isLoadingActiveConversation = false;
      state.templates = [];
      state.activeTab = undefined;
      state.isOpenOrClosed = OpenClosedFilter.Open;
      state.filterChannels = [];
      state.filterAgents = [];
      state.filterCustomerTagIds = [];
      state.bulkActionsToolbarEnabled = true;
      state.bulkActionsToolbarLoading = false;
      state.messageInput = {
        text: "",
        attachments: [],
        subject: "",
        template: null,
        activeTab: MessageInputTab.EDIT,
        isPreparingInput: false,
        replySuggestionId: undefined,
        replySuggestionCustomFields: undefined,
      };
    },
  },
});

export const conversationsSelector = (state: RootState) =>
  state.conversations.conversations;

export const activeConversationIdSelector = (state: RootState) =>
  state.conversations.activeConversationId;

export const activeConversationSelector = (state: RootState) =>
  state.conversations.activeConversation;

export const searchTextSelector = (state: RootState) =>
  state.conversations.searchText;

export const activeConversationTemplatesSelector = (state: RootState) =>
  state.conversations.activeConversationId
    ? state.conversations.templates[state.conversations.activeConversationId] ||
      []
    : [];

export const activeTabSelector = (state: RootState) =>
  state.conversations.activeTab;

export const openClosedSelector = (state: RootState) =>
  state.conversations.isOpenOrClosed;

export const filterChannelsSelector = (state: RootState) =>
  state.conversations.filterChannels;

export const filterAgentsSelector = (state: RootState) =>
  state.conversations.filterAgents;

export const filterCustomerTagIdsSelector = (state: RootState) =>
  state.conversations.filterCustomerTagIds;

export const loadingSelector = (state: RootState) =>
  state.conversations.loading;

export const isLoadingActiveConversationSelector = (state: RootState) =>
  state.conversations.isLoadingActiveConversation;

export const errorsSelector = (state: RootState) => state.conversations.errors;

export const {
  fetchConversations,
  fetchConversationsSuccess,
  fetchConversationsFail,
  setConversations,
  appendOrReplaceConversation,
  updateConversationTagIds,
  setActiveConversation,
  setTemplates,
  setActiveConversationTab,
  setActiveConversationOpenClosedFilter,
  setFilterChannels,
  updateMessageInputActiveTab,
  setFilterAgents,
  setFilterCustomerTagIds,
  setSearchText,
  updateConversationSelection,
  setMessageInputReplySuggestionCustomFields,
  updateConversationIsOpen,
  updateConversationIsRead,
  clearSelectedConversations,
  enableBulkActionsToolbar,
  disableBulkActionsToolbar,
  addAttachmentToMessageInput,
  setMessageInputIsPreparing,
  changeMessageInputText,
  setMessageInputState,
  changeMessageInputSubject,
  clearMessageInput,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  bulkAppendOrReplaceConversations,
  propagateTemplateUpdate,
  setMessageInputTemplate,
  updateConversationAgentAndTeamIds,
  setSelectedInbox,
  removeAttachmentFromMessageInput,
  clearAttahmentsFromMessageInput,
  resetStore,
  updateConversationIsSubscribed,
  clearTemplates,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
