import { Box, Button, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import Tag from "components/tags";
import {
  AccordionItemContent,
  AccordionItemTrigger,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { FaTags } from "react-icons/fa";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";
import { matchTagColorToMerchantTagColor } from "util/constants";

interface CustomerTagsProps {
  contactTagIds: string[];
  contactId: number;
  onCreateNewTag: () => void;
  setTagIdToBeDeleted: (tag: string) => void;
  isExpanded: boolean;
  isArchived: boolean;
}

const ContactTagsArea = ({
  isArchived,
  contactTagIds,
  contactId,
  onCreateNewTag,
  setTagIdToBeDeleted,
  isExpanded,
}: CustomerTagsProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { deleteContactTag } = useContactsStore();
  const [tagsToDisplay, setTagsToDisplay] = useState<Array<TagsDomain>>([]);
  const { tags } = useAppSelector((state) => state.tags);

  useEffect(() => {
    setTagsToDisplay(tags.filter((tag) => contactTagIds.includes(tag.id)));
  }, [contactTagIds]);

  const deleteTag = (tagToDelete: TagsDomain) =>
    deleteContactTag(tagToDelete, contactId).then(() => {
      setTagsToDisplay(
        tagsToDisplay.filter((tag) => tag.id !== tagToDelete.id)
      );
      setTagIdToBeDeleted(tagToDelete.id);
    });

  return tagsToDisplay.length === 0 && isArchived ? null : (
    <>
      <AccordionItemTrigger>
        <HStack>
          <Icon
            as={FaTags}
            width="1.15rem"
            height="1.15rem"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
          />
          <Text fontWeight="bold">Tags</Text>
        </HStack>
      </AccordionItemTrigger>
      <AccordionItemContent>
        {tagsToDisplay.length === 0 ? (
          <Flex justifyContent="center">
            <Button
              colorPalette={colorScheme}
              variant="ghost"
              color="gray.400"
              size="sm"
              onClick={onCreateNewTag}
            >
              {" "}
              Click here to add tags
            </Button>
          </Flex>
        ) : (
          <HStack mt={3.5} mx={1.5} gap={2} wrap="wrap" justifyContent="center">
            {tagsToDisplay.map((tag) => (
              <Box key={tag.tag}>
                <Tag
                  label={tag.tag}
                  color={matchTagColorToMerchantTagColor(tags, tag)}
                  my={1}
                  onCloseTag={isArchived ? undefined : () => deleteTag(tag)}
                />
              </Box>
            ))}
            {isArchived ? null : (
              <Icon
                asChild={true}
                mx={5}
                cursor="pointer"
                css={{
                  height: "1rem",
                  width: "1rem",
                }}
                _hover={{ opacity: 0.5 }}
                onClick={onCreateNewTag}
              >
                <ReactSVG src="/gradient-plus.svg" />
              </Icon>
            )}
          </HStack>
        )}
      </AccordionItemContent>
    </>
  );
};

export default ContactTagsArea;
