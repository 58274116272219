import {
  Box,
  IconButton,
} from "@chakra-ui/react";
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from "components/ui/menu";
import React from "react";
import { LuMenu } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const TopbarMenuBtn = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <Box w="25%">
      <MenuRoot>
        <MenuTrigger
          asChild={true}
        >
          <IconButton
            aria-label="Options"
            variant="outline"
          >
            <LuMenu />
          </IconButton>
        </MenuTrigger>
        <MenuContent>
          <Link to={`/${merchant.id}/admin`}>
            <MenuItem value="Admin Overview">Admin Overview</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/merchants`}>
            <MenuItem value="Merchants">Merchants</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/agents`}>
            <MenuItem value="Agents">Agents</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/ai`}>
            <MenuItem value="AI">AI</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/broadcast`}>
            <MenuItem value="Broadcast">Broadcast</MenuItem>
          </Link>
        </MenuContent>
      </MenuRoot>
    </Box>
  );
};

export default TopbarMenuBtn;
