import { HStack, VStack, useBreakpointValue } from "@chakra-ui/react";

import { useColorMode } from "components/ui/color-mode";
import { SkeletonText } from "components/ui/skeleton";
import React from "react";
import { TemplateFilter } from "redux/features/templates";
import { useAppSelector } from "redux/hooks";

const SkeletonOverlay = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { activeTemplateFilter } = useAppSelector((state) => state.templates);

  return (
    <VStack
      h="100%"
      w="100%"
      position="absolute"
      top={0}
      left={0}
      gap={8}
      overflow="hidden"
      zIndex={50}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
      p={4}
    >
      {isBaseSize ? null : (
        <SkeletonText
          mt={4}
          mb={8}
          maxW="100%!important"
          w="100%!important"
          noOfLines={1}
          height={4}
        />
      )}
      {[...Array(5).keys()].map((i) => (
        <HStack key={i} w="100%" gap={4}>
          {activeTemplateFilter === TemplateFilter.WHATSAPP ? (
            <>
              <SkeletonText noOfLines={1} height={4} />
              {isBaseSize ? null : <SkeletonText noOfLines={1} height={10} />}
              <SkeletonText noOfLines={1} height={4} />
            </>
          ) : (
            <>
              <SkeletonText noOfLines={1} height={4} />
              {isBaseSize ? null : (
                <>
                  <SkeletonText noOfLines={1} height={4} />
                  <SkeletonText noOfLines={1} height={4} />
                  <SkeletonText noOfLines={1} height={10} />
                </>
              )}
            </>
          )}
        </HStack>
      ))}
    </VStack>
  );
};

export default SkeletonOverlay;
