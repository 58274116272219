import {
  Flex,
  Icon,
  Image,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import MessageDomain, {
  MessageSubType,
} from "entities/domain/conversations/message-domain";
import React, { ForwardRefRenderFunction, forwardRef } from "react";
import {
  LuBellRing,
  LuDollarSign,
  LuMail,
  LuMessageSquarePlus,
  LuUserPlus,
  LuUserRoundCheck,
  LuUserRoundX,
} from "react-icons/lu";

import { useAppSelector } from "redux/hooks";

interface NotificationProps {
  message: MessageDomain;
}

const getIconByType = (messageSubType: MessageSubType) => {
  switch (messageSubType) {
    case MessageSubType.NEW_LEAD:
      return LuUserPlus;
    case MessageSubType.EXISTING_LEAD_EXTRA_CHANNEL:
      return LuMessageSquarePlus;
    case MessageSubType.CAMPAIGN_MESSAGE:
      return LuBellRing;
    case MessageSubType.PAYMENT_RECEIVED:
      return LuDollarSign;
    case MessageSubType.NEW_SUBJECT_LINE:
    case MessageSubType.REGULAR:
    case MessageSubType.FINISHED_CALL:
    case MessageSubType.MISSED_CALL:
    case MessageSubType.PAYMENT_REQUEST:
    case MessageSubType.REVIEW_REQUEST:
    case MessageSubType.NEW_AGENT:
    default:
      return LuMail;
  }
};

const Notification: ForwardRefRenderFunction<
  HTMLDivElement,
  NotificationProps
> = ({ message }, ref) => {
  const { agents, currentAgent } = useAppSelector((state) => state.agents);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const icon = getIconByType(message.subType);

  const currentAgentBackground = currentAgent!.getChatBackgroundStyles(
    (colorMode as "light" | "dark") || "light"
  );
  let shouldGuaranteeReadability = false;

  if (
    currentAgentBackground &&
    currentAgentBackground.background &&
    typeof currentAgentBackground.background === "string" &&
    !currentAgentBackground.background.includes("gray")
  ) {
    shouldGuaranteeReadability = true;
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      ref={ref}
      width={isBaseSize ? "80%" : "30%"}
      mx="auto"
      my={4}
    >
      <Flex
        {...(shouldGuaranteeReadability
          ? {
              mb: "-0.75rem",
              zIndex: 1,
              p: 1,
              borderRadius: "full",
              backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
            }
          : {})}
      >
        {message.subType === MessageSubType.NEW_AGENT ? (
          <Image
            src={
              message.agentId
                ? undefined
                : agents.find((a) => a.id === message.agentId)?.getPicture()
            }
            // fallback={
            //   <Icon
            //     as={message.agentId ? AccountCheck : AccountRemove}
            //     fill={colorMode === "dark" ? "gray.50" : "gray.700"}
            //     boxSize="1.5rem"
            //   />
            // }
          />
        ) : (
          <Icon
            as={message.agentId ? LuUserRoundCheck : LuUserRoundX}
            fill={colorMode === "dark" ? "gray.50" : "gray.700"}
            boxSize="1.5rem"
          />
        )}
      </Flex>
      <VStack textAlign="center" gap={0}>
        <Text
          fontWeight={700}
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          color={colorMode === "dark" ? "gray.50" : "gray.700"}
          {...(shouldGuaranteeReadability
            ? {
                mb: -2,
                p: 2,
                borderRadius: "xl",
                backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
                width: "fit-content",
              }
            : {})}
        >
          {message.title}
        </Text>
        <Text
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          fontSize="sm"
          textAlign="left"
          color={colorMode === "dark" ? "gray.50" : "gray.700"}
          {...(shouldGuaranteeReadability
            ? {
                mb: -2,
                p: 2,
                borderRadius: "xl",
                backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
                width: "fit-content",
              }
            : {})}
        >
          {message.body}
        </Text>
        <Text
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          color={colorMode === "dark" ? "gray.400" : "gray.500"}
          fontSize="xs"
          {...(shouldGuaranteeReadability
            ? {
                p: 2,
                borderRadius: "xl",
                backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
                width: "fit-content",
              }
            : {})}
        >
          {message.getTimeStamp()}
        </Text>
      </VStack>
    </Flex>
  );
};

export default forwardRef(Notification);
