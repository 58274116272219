import {
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import React, { useEffect, useState } from "react";
import { LuInfo } from "react-icons/lu";
import { SegmentedMessage } from "sms-segments-calculator";

interface SmsMaxLengthProps {
  text: string;
  setIsLimitExceeded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproachingLimit: React.Dispatch<React.SetStateAction<boolean>>;
}

const SEGMENT_MAX_EXCEEDED_TEXT =
  "Message exceeds the maximum number of segments allowed for SMS";

export const MAX_SMS_SEGMENTS = 10;

export const getSegmentsBreakdown = (text: string) => {
  const segmentedMessage = new SegmentedMessage(text, "auto", true);

  const hasExceeded = segmentedMessage.segments.length > MAX_SMS_SEGMENTS;
  const isApproaching =
    MAX_SMS_SEGMENTS - segmentedMessage.segments.length <= 2;

  return {
    hasExceeded,
    isApproaching,
    segments: segmentedMessage.segments,
  };
};

const SmsMaxLength: React.FC<SmsMaxLengthProps> = ({
  text,
  setIsLimitExceeded,
  setIsApproachingLimit,
}: SmsMaxLengthProps) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [currentSegmentCount, setCurrentSegmentCount] = useState<number>(
    getSegmentsBreakdown(text).segments.length
  );
  const [isSegmentMaxExceeded, setIsSegmentMaxExceeded] = useState<boolean>(
    getSegmentsBreakdown(text).hasExceeded
  );

  const [isApproachingSegmentMaxLimit, setIsApproachingSegmentMaxLimit] =
    useState<boolean>(getSegmentsBreakdown(text).isApproaching);

  useEffect(() => {
    const { hasExceeded, isApproaching, segments } = getSegmentsBreakdown(text);

    setIsApproachingSegmentMaxLimit(isApproaching);
    setIsSegmentMaxExceeded(hasExceeded);

    if (hasExceeded || isApproaching) {
      setCurrentSegmentCount(segments.length);
    }
  }, [text]);

  useEffect(() => {
    setIsLimitExceeded(isSegmentMaxExceeded);
  }, [isSegmentMaxExceeded]);

  useEffect(() => {
    setIsApproachingLimit(isApproachingSegmentMaxLimit);
  }, [isApproachingSegmentMaxLimit]);

  return !isApproachingSegmentMaxLimit ? null : (
    <HStack
      w="100%"
      h="100%"
      wrap="wrap"
      pl={16}
      pr={8}
      position="absolute"
      alignItems="center"
      justifyContent="center"
    >
      <Text
        alignSelf="center"
        color={`${isSegmentMaxExceeded ? "red" : "orange"}.${
          colorMode === "dark" ? "200" : "500"
        }`}
        fontWeight="bold"
      >
        {currentSegmentCount}/10 Segments
      </Text>
      {!isBaseSize ? (
        <Tooltip
          content="SMS standards have barely changed since the days of the brickphone. Messages are still sent in 140 byte chunks known as message segments. Most SMS providers can send only a limited amount of segments so that modern phones could interpret those as one continuous message."
          positioning={{ placement: "top" }}
        >
          <Icon
            as={LuInfo}
            color={`${colorMode === "dark" ? "red.200" : "red.500"}`}
          />
        </Tooltip>
      ) : null}
      {isSegmentMaxExceeded && !isBaseSize ? (
        <Text
          alignSelf="center"
          color={colorMode === "dark" ? "red.200" : "red.500"}
          fontSize="sm"
        >
          {SEGMENT_MAX_EXCEEDED_TEXT}
        </Text>
      ) : null}
    </HStack>
  );
};

export default SmsMaxLength;
