import { Text } from "@chakra-ui/react";

import React from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";

const TemplateShortcutColumn = ({
  item,
}: {
  item: RegularOrWhatsappTemplate;
}): JSX.Element | null => {
  if (item.isRegularTemplate()) {
    return item.shortcut ? <Text>/{item.shortcut}</Text> : null;
  }

  return null;
};

export default TemplateShortcutColumn;
