import {
  Button,
  Icon,
  IconButton,
  Portal,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverCloseTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import React, { memo, useCallback, useState } from "react";
import { LuCopy, LuSettings } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import TemplatesPopover from "../templates-popover";

interface TemplatesShortcutProps {
  isDisabled?: boolean;
  invisibleTrigger?: boolean;
}

const TemplatesShortcut = ({
  isDisabled = false,
  invisibleTrigger = false,
}: TemplatesShortcutProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const [open, setOpen] = useState<boolean>(false);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <PopoverRoot
      closeOnInteractOutside={true}
      positioning={{ placement: invisibleTrigger ? "top-start" : "top" }}
      open={open}
      onOpenChange={(e) => setOpen(e.open)}
    >
      <Portal>
        <PopoverContent>
          <PopoverHeader display="flex" alignItems="center" gap={1}>
            Templates
            {!isBaseSize && (
              <IconButton
                colorPalette="gray"
                size="2xs"
                variant="ghost"
                asChild
              >
                <Link to={`/${merchant.id}/settings/templates`}>
                  <Icon as={LuSettings} />
                </Link>
              </IconButton>
            )}
          </PopoverHeader>
          <PopoverCloseTrigger />
          <TemplatesPopover
            isOpen={open}
            id="templates-popover"
            onClose={onClose}
          />
        </PopoverContent>
      </Portal>
      <PopoverTrigger>
        {invisibleTrigger ? (
          <Button
            unstyled={true}
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            borderRadius="xl"
          />
        ) : (
          <Button
            data-testid="select-template"
            variant="ghost"
            size="xs"
            colorPalette={colorScheme}
            p={0}
            disabled={isDisabled}
          >
            <Icon
              as={LuCopy}
              width="1rem"
              height="1rem"
              color={
                colorMode === "light"
                  ? `${colorScheme}.500`
                  : `${colorScheme}.200`
              }
            />
          </Button>
        )}
      </PopoverTrigger>
    </PopoverRoot>
  );
};

export default memo(TemplatesShortcut);
