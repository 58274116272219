import { HStack, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import TemplateDomain from "entities/domain/templates";
import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import TemplatesShortcut from "../../input-actions/TemplatesShortcut";
import InfoModalWhatsapp from "./InfoModalWhatsapp";

interface FirstOutreachMessageProps {}

const shouldShowOverlayForWhatsappConversation = (
  conversation: ConversationDomain | null | undefined,
  template: TemplateDomain | null
) => {
  if (!conversation) {
    return false;
  }

  return conversation.isTemplatesOnly() && !template;
};

const FirstOutreachMessage = (_props: FirstOutreachMessageProps) => {
  const {
    activeConversation,
    messageInput: { template },
  } = useAppSelector((state) => state.conversations);
  const { colorMode } = useColorMode();
  const isShown = useMemo(
    () =>
      shouldShowOverlayForWhatsappConversation(activeConversation, template),
    [activeConversation, template]
  );

  return isShown ? (
    <HStack
      position="absolute"
      left={0}
      top={0}
      w="100%"
      h="100%"
      zIndex={1}
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      cursor="pointer"
      justifyContent="start"
      px={4}
      pt={4}
      pb={8}
      alignItems="start"
    >
      <HStack alignItems="center">
        <Text>Click here to choose an approved WhatsApp template</Text>
        <InfoModalWhatsapp />
      </HStack>
      <TemplatesShortcut invisibleTrigger={true} />
    </HStack>
  ) : null;
};

export default FirstOutreachMessage;
