import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogRoot,
} from "components/ui/dialog";
import {
  PaymentMethod,
  PaymentStatus,
} from "entities/domain/payments/payment-domain";
import usePaymentsStore from "hooks/use-payments-store";
import React, { useState } from "react";
import { selectPayment } from "redux/features/payments";
import { useAppSelector } from "redux/hooks";
import { getPaymentMethodIcon } from "..";

interface EditPaymentProps {
  isEditPaymentsOpen: boolean;
  onClose: () => void;
}

const EditPaymentModal = ({
  isEditPaymentsOpen,
  onClose,
}: EditPaymentProps) => {
  const { editPayment } = usePaymentsStore();
  const { payments, selectedPaymentId } = useAppSelector(
    (state) => state.payments
  );
  const selectedPayment = selectPayment(selectedPaymentId, payments);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const onEditPayment = () => {
    editPayment({
      id: selectedPayment?.id,
      status: PaymentStatus.PAID,
      payment_method: selectedOption,
    });
    onClose();
  };

  const PaymentOptions: {
    value: string;
    label: string;
  }[] = [
    { value: PaymentMethod.CARD_PAYMENT, label: "Card" },
    { value: PaymentMethod.BANK_TRANSFER, label: "Bank Transfer" },
    { value: PaymentMethod.CASH, label: "Cash" },
    { value: PaymentMethod.CHECK, label: "Cheque" },
  ];

  return (
    <Box
      css={{
        "& .chakra-radio__control": {
          marginLeft: "20px",
        },
      }}
    >
      <DialogRoot
        size={isBaseSize ? "full" : "lg"}
        open={isEditPaymentsOpen}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
            setSelectedOption("");
          }
        }}
      >
        <DialogBackdrop />
        <DialogContent>
          <Heading as="h3" margin="20px 40px 8px" size="md">
            {" "}
            Choose method of payment
          </Heading>
          <DialogCloseTrigger />
          {PaymentOptions.map((option) => (
            <Flex
              key={`payment-option-${option.value}`}
              background={
                selectedOption === option.value
                  ? colorMode === "dark"
                    ? "gray.900"
                    : "gray.100"
                  : colorMode === "dark"
                  ? "gray.600"
                  : "gray.50"
              }
              boxShadow={
                selectedOption === option.value
                  ? "0px 3px 33px rgba(137, 156, 197, 0.393111)"
                  : ""
              }
              margin="16px 32px 0"
              borderRadius="12px"
              padding="17px 24px"
              justifyContent="space-between"
              cursor="pointer"
              _hover={{
                boxShadow: "0px 3px 33px rgba(137, 156, 197, 0.393111)",
              }}
              css={{
                "& .styled-react-icon div svg path, .styled-bank-icon": {
                  fill: `${colorScheme}.600`,
                },
              }}
              onClick={() => setSelectedOption(option.value)}
            >
              <Text fontWeight="bold">{option.label}</Text>
              {getPaymentMethodIcon(option.value, colorScheme)}
            </Flex>
          ))}
          <Box margin="24px 32px">
            <Button
              colorPalette={colorScheme}
              width="100%"
              padding="10px 32px"
              size="sm"
              mb="16px"
              onClick={onEditPayment}
            >
              {" "}
              Confirm
            </Button>
          </Box>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};

export default EditPaymentModal;
