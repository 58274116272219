import {
  Clipboard,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  SystemStyleObject,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogRoot,
} from "components/ui/dialog";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import React, { ReactNode, memo, useState } from "react";
import { FaUserClock } from "react-icons/fa";
import { useAppSelector } from "redux/hooks";
import DisplayStyledMessage from "./DisplayStyledMessage";
import MessageButtonsPreview from "./MessageButtonsPreview";

interface BorderStyles {
  borderRadius: string;
  borderWidth: string;
  borderStyle: string;
  borderColor: string;
}

const getMessageStyles = (
  isReceiving: boolean,
  colorMode: "light" | "dark",
  colorScheme: string
) => {
  const messageStyles: {
    border: BorderStyles | null;
    background: string;
    mr: string | number;
    ml: string | number;
  } = {
    border: {
      borderRadius: "xl",
      borderWidth: "0",
      borderStyle: "none",
      borderColor: "transparent",
    },
    background: colorMode === "dark" ? "gray.700" : "gray.50",
    mr: "auto",
    ml: "auto",
  };

  messageStyles.ml = isReceiving ? 8 : 0;
  messageStyles.mr = isReceiving ? 0 : 8;

  if (isReceiving) {
    if (colorMode === "light") {
      messageStyles.background = "white";
    } else {
      messageStyles.background = "gray.600";
    }

    return messageStyles;
  }

  if (colorMode === "light") {
    messageStyles.background = `${colorScheme}.500`;
  } else {
    messageStyles.background = `${colorScheme}.400`;
  }

  return messageStyles;
};

const getTailStyles = (
  isIncoming: boolean,
  messageBackgroundColor: string,
  showTail: boolean
): SystemStyleObject => {
  if (!showTail) {
    return {};
  }

  return isIncoming
    ? {
        _after: {
          content: "''",
          position: "absolute",
          bottom: 0,
          zIndex: 4,
          left: "-1.5rem",
          width: "2.25rem",
          height: "1.5rem",
          background: `radial-gradient(1.5rem at top left, #0000 99%, var(--chakra-colors-${messageBackgroundColor
            .split(".")
            .join("-")}) 102%)`,
        },
      }
    : {
        _after: {
          content: "''",
          position: "absolute",
          zIndex: 4,
          bottom: 0,
          right: "-1.5rem",
          width: "2.25rem",
          height: "1.5rem",
          background: `radial-gradient(1.5rem at top right, #0000 99%, var(--chakra-colors-${messageBackgroundColor
            .split(".")
            .join("-")}) 102%)`,
        },
      };
};

export default memo(
  ({
    children,
    message,
    showTail,
    activeConversation,
    agent,
    isOpen,
    setIsOpen,
    modalContent,
  }: React.PropsWithChildren<{
    isOpen?: boolean;
    setIsOpen?: (open: boolean) => void;
    message: MessageDomain;
    activeConversation: ConversationDomain | undefined;
    agent: AgentDomain | undefined;
    modalContent?: ReactNode;
    showTail: boolean;
  }>) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const { colorMode } = useColorMode();
    const { colorScheme } = useAppSelector((state) => state.theme);
    const [shouldShowClipboard, setShouldShowClipboard] =
      useState<boolean>(false);
    const {
      background: messageBackgroundColor,
      border: borderStyles,
      ml,
      mr,
    } = getMessageStyles(
      message.isIncoming,
      (colorMode as "dark" | "light") || "light",
      colorScheme
    );
    const maxWidth = isBaseSize ? "75%" : "45%";
    const minWidth = isBaseSize ? "35%" : "25%";

    return (
      <>
        <VStack
          background={messageBackgroundColor}
          {...(borderStyles || {})}
          maxWidth={maxWidth}
          minWidth={minWidth}
          position="relative"
          ml={ml}
          mr={mr}
          onMouseEnter={() => setShouldShowClipboard(true)}
          onMouseLeave={() => setShouldShowClipboard(false)}
          zIndex={5}
          css={getTailStyles(
            message.isIncoming,
            messageBackgroundColor,
            showTail
          )}
        >
          {shouldShowClipboard ? (
            <Clipboard.Root
              value={message.body}
              position="absolute"
              top={-2}
              right={-2}
            >
              <Clipboard.Trigger asChild>
                <IconButton variant="subtle" size="xs">
                  <Clipboard.Indicator />
                </IconButton>
              </Clipboard.Trigger>
            </Clipboard.Root>
          ) : null}
          {message.isCustomerLeadMessage() ? (
            <HStack gap={0} px={3} pt={2}>
              <Icon
                as={FaUserClock}
                boxSize={10}
                color={colorMode === "dark" ? "gray.500" : "gray.400"}
              />
              <Heading
                as="h3"
                size="md"
                color={colorMode === "dark" ? "gray.500" : "gray.400"}
              >
                Message left on marketplace ✍️
              </Heading>
            </HStack>
          ) : null}
          <Flex w="100%" py={2} px={3} wordBreak="break-word">
            <DisplayStyledMessage
              message={message}
              agent={agent}
              activeConversation={activeConversation}
            >
              {children}
            </DisplayStyledMessage>
          </Flex>
          {message.buttons ? (
            <>
              {message.isIncoming ? (
                <MessageButtonsPreview buttons={message.buttons} />
              ) : (
                <MessageButtonsPreview
                  buttons={message.buttons}
                  forceColorMode={message.isIncoming ? "light" : "dark"}
                />
              )}
            </>
          ) : null}
        </VStack>
        <DialogRoot
          open={isOpen || false}
          onOpenChange={({ open: newIsOpen }) => {
            if (!newIsOpen && typeof setIsOpen !== "undefined") {
              setIsOpen(false);
            }
          }}
          size={isBaseSize ? "full" : "md"}
        >
          <DialogBackdrop />
          <DialogContent
            {...(isBaseSize
              ? { alignItems: "center", justifyContent: "center" }
              : {})}
          >
            <DialogCloseTrigger />
            {modalContent}
          </DialogContent>
        </DialogRoot>
      </>
    );
  }
);
