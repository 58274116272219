import { Box } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { Outlet } from "react-router-dom";
import AdminGrid from "./AdminGrid";
import TopbarMenuBtn from "./TopbarMenuBtn";

const Admin = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      width="100%"
      maxHeight="100%"
      overflowY="auto"
      boxShadow="0 2px 11px 0 rgba(5, 59, 113, 0.5)"
      pb="20px"
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      <Topbar
        rightChildrenMobile={<TopbarMenuBtn />}
        leftChildren={<TopbarMenuBtn />}
      />
      <AdminGrid>
        <Outlet />
      </AdminGrid>
    </Box>
  );
};

export default Admin;
