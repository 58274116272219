import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  Flex,
  Icon,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import StylePreferences from "components/style-preferences";
import { useColorMode } from "components/ui/color-mode";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from "components/ui/menu";
import useNotificationsStore from "hooks/use-notifications-store";
import React, { useEffect, useState } from "react";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { LuBellDot, LuCircleHelp, LuPaintbrush } from "react-icons/lu";
import { MdPassword } from "react-icons/md";
import { setIsSelectorOpened } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isMobileApp } from "util/methods";
import NotificationsModal from "./NotificationsModal";
import PasswordChangeModal from "./PasswordChangeModal";

interface ProfileSettingsProps {
  avatarOnly?: boolean;
}

const ProfileSettings = ({ avatarOnly = false }: ProfileSettingsProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { isAuthenticated, logout } = useAuth0();
  const { fetchNotifications } = useNotificationsStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const { loading: isLoadingMerchant } = useAppSelector(
    (state) => state.merchant
  );
  const { loading: isLoadingNotifications, notifications } = useAppSelector(
    (state) => state.notifications
  );
  const passwordChangeModal = useDisclosure();
  const notificationsModal = useDisclosure();

  useEffect(() => {
    if (loading && !isLoadingMerchant && !isLoadingNotifications) {
      setLoading(false);
    }

    if (isLoadingNotifications && !isLoadingMerchant) {
      setLoading(true);
    }
  }, [isLoadingMerchant, isLoadingNotifications]);

  useEffect(() => {
    setUnreadCount(
      notifications.filter((notification) => !notification.read).length
    );
  }, [notifications]);

  useEffect(() => {
    fetchNotifications();
  }, [isLoadingMerchant]);

  const [isStyleModalOpen, setIsStyleModalOpen] = useState<boolean>(false);

  const hasMultipleMerchants = currentAgent!.merchants.length > 1;

  return (
    <>
      <MenuRoot>
        <MenuTrigger>
          <Flex alignItems="center" direction="column" cursor="pointer">
            <Box position="relative" display="inline-block">
              <ProfileAvatar
                profilePicture={currentAgent!.getPicture()}
                name={currentAgent!.getFullName()}
                avatarStyle={{
                  colorPalette: currentAgent!.color,
                }}
              />
              {unreadCount > 0 && (
                <Badge
                  position="absolute"
                  top="-1px"
                  right="-1px"
                  transform="translate(50%, -50%)"
                  borderRadius="full"
                  width="1.5em"
                  height="1.5em"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="xs"
                  lineHeight={5}
                  background={colorMode === "dark" ? "red.400" : "red.700"}
                  color="white"
                  textAlign="center"
                >
                  {unreadCount}
                </Badge>
              )}
            </Box>

            {!avatarOnly ? (
              <>
                <Text
                  mt={2}
                  as="p"
                  textTransform="capitalize"
                  wordBreak="break-all"
                  color="white"
                >
                  {currentAgent ? currentAgent.name : null}
                </Text>
                <Text fontSize="xs" fontWeight="light" color="white">
                  {merchant.name}
                </Text>
              </>
            ) : null}
          </Flex>
        </MenuTrigger>
        <MenuContent zIndex={2}>
          <VStack my={2} alignItems="start">
            <Text
              pl={4}
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.500" : "gray.200"}
            >
              {currentAgent ? currentAgent.getFullName() : null}
            </Text>
            <Text pl={4} color={colorMode === "dark" ? "gray.500" : "gray.200"}>
              {currentAgent ? currentAgent.email : null}
            </Text>
            {hasMultipleMerchants && isBaseSize ? (
              <MenuItem
                value="Switch Account"
                onClick={() => {
                  dispatch(setIsSelectorOpened(true));
                }}
              >
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={AiOutlineUserSwitch}
                  color={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                />{" "}
                Switch Account
              </MenuItem>
            ) : null}
            <MenuItem value="Notifications" onClick={notificationsModal.onOpen}>
              <Icon
                width="1.5rem"
                height="1.5rem"
                as={LuBellDot}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`
                }
              />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Text>Notifications</Text>
                {unreadCount > 0 && (
                  <Badge
                    ml="2"
                    background={
                      colorMode === "dark"
                        ? `${colorScheme}.400`
                        : `${colorScheme}.700`
                    }
                    color="white"
                    textAlign="center"
                    borderRadius="full"
                    width="1.5em"
                    height="1.5em"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {unreadCount}
                  </Badge>
                )}
              </Flex>
            </MenuItem>
            <MenuItem
              value="style"
              onClick={() => {
                setIsStyleModalOpen(true);
              }}
            >
              <Icon
                width="1.5rem"
                height="1.5rem"
                as={LuPaintbrush}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`
                }
              />{" "}
              Style
            </MenuItem>
            <MenuItem
              value="FAQ"
              onClick={() => {
                const faqUrl = "https://www.getfuzey.com/resources/faq";

                if (isMobileApp()) {
                  window.natively.openExternalURL(faqUrl);
                } else {
                  window.open(faqUrl, "_blank");
                }
              }}
            >
              <Icon
                width="1.5rem"
                height="1.5rem"
                as={LuCircleHelp}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`
                }
              />{" "}
              FAQ
            </MenuItem>
            <MenuItem
              value="Change Password"
              onClick={() => {
                passwordChangeModal.onOpen();
              }}
            >
              <Icon
                width="1.5rem"
                height="1.5rem"
                as={MdPassword}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`
                }
              />{" "}
              Change Password
            </MenuItem>
            {isAuthenticated ? (
              <MenuItem
                value="Log out"
                onClick={() => {
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  });
                }}
              >
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={FiLogOut}
                  color={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`
                  }
                  css={{
                    "& path": {
                      fill: "transparent",
                    },
                  }}
                />{" "}
                Log out
              </MenuItem>
            ) : null}
          </VStack>
        </MenuContent>
      </MenuRoot>
      <StylePreferences
        isOpen={isStyleModalOpen}
        setIsOpen={setIsStyleModalOpen}
      />
      <NotificationsModal
        isOpen={notificationsModal.open}
        onClose={notificationsModal.onClose}
      />
      <PasswordChangeModal
        isOpen={passwordChangeModal.open}
        onClose={passwordChangeModal.onClose}
      />
    </>
  );
};

export default ProfileSettings;
