import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { Field } from "components/ui/field";
import React, { ForwardRefRenderFunction, forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { useAppSelector } from "redux/hooks";
import { ContactFormInputConfigProps } from "./ContactFormConfig";

export type InputProps = {
  label: string;
  id: string;
  borderColor?: string;
  error?: FieldError;
} & ChakraInputProps &
  ContactFormInputConfigProps;

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    id,
    borderColor,
    usePlaceholders,
    placeholderColor,
    error = undefined,
    ...otherProps
  }: InputProps,
  ref
) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Field
      invalid={!!error}
      errorText={error?.message}
      label={!usePlaceholders ? label : undefined}
    >
      <ChakraInput
        name={id}
        id={id}
        borderRadius="full"
        borderColor={borderColor || `${colorScheme}.400`}
        _focus={{ borderColor: `${borderColor || "inherit"}` }}
        placeholder={usePlaceholders ? label : ""}
        _placeholder={
          placeholderColor
            ? {
                color: `${placeholderColor}`,
              }
            : {}
        }
        ref={ref}
        {...otherProps}
      />
    </Field>
  );
};

export default forwardRef(Input);
