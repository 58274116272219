import { Icon } from "@chakra-ui/react";
import MessageDomain, {
  MessageSubType,
} from "entities/domain/conversations/message-domain";
import React from "react";
import {
  LuPhoneIncoming,
  LuPhoneMissed,
  LuPhoneOutgoing,
} from "react-icons/lu";

const PhoneIcon = ({
  message,
  colorMode,
}: {
  message: MessageDomain;
  colorMode: "dark" | "light";
}) => {
  if (message.subType === MessageSubType.MISSED_CALL) {
    return (
      <Icon
        as={LuPhoneMissed}
        color={colorMode === "dark" ? "red.200" : "red.500"}
      />
    );
  }

  if (message.subType === MessageSubType.FINISHED_CALL) {
    return (
      <Icon
        as={message.isIncoming ? LuPhoneIncoming : LuPhoneOutgoing}
        color={colorMode === "dark" ? "green.200" : "green.500"}
      />
    );
  }

  return (
    <Icon
      as={LuPhoneOutgoing}
      color={colorMode === "dark" ? "blue.200" : "blue.500"}
    />
  );
};

export default PhoneIcon;
