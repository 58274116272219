import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { AxiosError } from "axios";
import FuzeyDropdown from "components/shared/dropdown";
import { OptionTypes } from "components/shared/filter";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import useYupValidationResolver from "hooks/use-validation-resolver";
import React, { useState } from "react";
import { FieldName, SubmitHandler, useForm } from "react-hook-form";
import { updateMerchantSuccess } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import MerchantService, {
  UpdateMerchantBankDetailsPayload,
} from "services/merchant";
import { getReactSelectStyles } from "util/methods";
import * as yup from "yup";

function camelize(text: string): string {
  const a = text
    .toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
  return a.substring(0, 1).toLowerCase() + a.substring(1);
}

interface FormValues {
  beneficiaryName: string;
  bic?: string;
  iban?: string;
  sortCode?: string;
  accountNumber?: string;
}

const BankAccountForm: React.FC = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("GB");
  const { colorMode } = useColorMode();

  const countryOptions: OptionTypes[] = [
    { value: "GB", label: "United Kingdom" },
    { value: "IE", label: "Ireland" },
  ];

  const handleCountryChange = (selectedValue: string | undefined) => {
    setCountry(selectedValue!);
  };

  const validationSchema: yup.Schema<FormValues> = yup.object({
    beneficiaryName: yup.string().required(),
    bic:
      country === "GB"
        ? yup.string().min(8).max(11)
        : yup.string().required().min(8).max(11),
    iban:
      country === "GB" ? yup.string().max(34) : yup.string().required().max(34),
    sortCode:
      country !== "GB"
        ? yup.string().length(6)
        : yup.string().required().length(6),
    accountNumber:
      country !== "GB"
        ? yup.string().min(7).max(8)
        : yup.string().required().min(7).max(8),
  });

  yup.setLocale({
    mixed: {
      default: "Field is invalid",
      required: "Field is required",
    },
    string: {
      min: "Should contain at least ${min} characters",
      max: "Should contain no more than ${max} characters",
      length: "Should contain exactly ${length} characters",
    },
  });

  const resolver = useYupValidationResolver<FormValues>(validationSchema);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    getValues,
  } = useForm<FormValues>({ resolver: resolver as any });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);

    try {
      const updateMerchantObj: UpdateMerchantBankDetailsPayload = {
        bank_account: {
          country,
          beneficiary_name: getValues("beneficiaryName"),
          details: {
            account_number: getValues("accountNumber"),
            sort_code: getValues("sortCode"),
            bic: getValues("bic"),
            iban: getValues("iban"),
          },
        },
      };

      await MerchantService.updateMerchantBankDetails(
        auth0Context,
        updateMerchantObj,
        merchant.id
      );

      dispatch(
        updateMerchantSuccess(
          Object.setPrototypeOf(
            {
              ...merchant,
              hasOpenBanking: true,
            },
            MerchantDomainBase.prototype
          )
        )
      );
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 422 || e.response?.status === 409) {
          const fieldErrors = e.response.data.field_errors as Array<{
            field: string;
            error_code: string;
          }>;

          fieldErrors.forEach((fieldError) => {
            setError(camelize(fieldError.field) as FieldName<FormValues>, {
              type: "server",
              message: fieldError.error_code,
            });
          });

          return;
        }
      }

      toaster.create({
        type: "error",
        title: "We were unable to update your bank details.",
        description: "Please try again or contact us if the issue persists.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fieldset.Root asChild={true}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Fieldset.Content width="100%">
          <Field label="Country" disabled={isLoading}>
            <FuzeyDropdown
              isDisabled={isLoading}
              width="100%"
              placeholder="Select a country"
              controlShouldRenderValue={true}
              isSetOnSelect={true}
              closeMenuOnSelect={true as (() => void) & boolean}
              setSelectedValue={handleCountryChange}
              options={countryOptions}
              styles={{
                ...getReactSelectStyles(colorMode || "light", colorScheme),
                container: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
              defaultValue={
                countryOptions.find(({ value }) => value === country) || null
              }
              isMulti={false}
            />
          </Field>
          <Field
            label="Beneficiary name"
            errorText={errors?.beneficiaryName?.message}
            invalid={!(errors?.beneficiaryName == null)}
            disabled={isLoading}
          >
            <Input
              colorPalette={colorScheme}
              placeholder="John Doe"
              type="text"
              {...register("beneficiaryName")}
            />
          </Field>
          {country === "GB" ? (
            <>
              <Field
                label="Bank Account Number"
                errorText={errors?.accountNumber?.message}
                invalid={!(errors?.accountNumber == null)}
                disabled={isLoading}
              >
                <Input
                  colorPalette={colorScheme}
                  placeholder="31926819"
                  type="text"
                  {...register("accountNumber")}
                />
              </Field>
              <Field
                label="Bank Sort Code"
                errorText={errors?.sortCode?.message}
                invalid={!(errors?.sortCode == null)}
                disabled={isLoading}
              >
                <Input
                  colorPalette={colorScheme}
                  placeholder="123456"
                  type="text"
                  {...register("sortCode")}
                />
              </Field>
            </>
          ) : (
            <>
              <Field
                invalid={!(errors?.iban == null)}
                disabled={isLoading}
                label="IBAN"
                errorText={errors?.iban?.message}
              >
                <Input
                  colorPalette={colorScheme}
                  placeholder="IE64IRCE92050112345678"
                  type="text"
                  {...register("iban")}
                />
              </Field>
              <Field
                invalid={!(errors?.bic == null)}
                disabled={isLoading}
                label="BIC"
                errorText={errors?.bic?.message}
              >
                <Input
                  colorPalette={colorScheme}
                  placeholder="BICIE"
                  type="text"
                  {...register("bic")}
                />
              </Field>
            </>
          )}
        </Fieldset.Content>
        <Button colorPalette={colorScheme} type="submit" mt="2rem!important">
          Confirm
        </Button>
      </form>
    </Fieldset.Root>
  );
};

export default BankAccountForm;
