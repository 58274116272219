import { AgentOptionTypes } from "components/calendar/FullCalendar";
import { OptionTypes } from "components/shared/filter";
import AgentDomain from "entities/domain/agents/agent-domain";
import AgentPermissionsDomain from "entities/domain/agents/agent-permissions-domain";
import AgentRolesDomain, {
  AgentRole,
} from "entities/domain/agents/agent-roles-domain";
import AgentNotificationConfigDomain from "entities/domain/agents/notification-config-domain";
import {
  AgentDTO,
  AgentNotificationConfigDTO,
  AgentPermissionsDTO,
  AgentRoleDTO,
} from "entities/dto/AgentDTO";
import { colorList } from "util/constants";

interface ChannelOptionTypes {
  value: string;
  label: string;
  icon?: string;
  color?: string;
}

export const transformFromAvailableRolesToOptions = (
  r: AgentRolesDomain
): OptionTypes => ({
  value: r.id.toString(),
  label: r.name,
});

export const transformFromAgentRoleToOptions = (
  role: AgentRoleDTO
): ChannelOptionTypes => ({
  value: role.id.toString(),
  label: role.name,
});

export const transformFromAgentDomainToOptionType = (
  agent: AgentDomain
): AgentOptionTypes => ({
  value: agent.id,
  label: agent.getFullName(),
  color: agent.color,
});

export const transformNotificationConfigfromDtoToDomain = (
  d: AgentNotificationConfigDTO
): AgentNotificationConfigDomain =>
  new AgentNotificationConfigDomain(
    d.handle,
    d.channel,
    d.preference,
    d.strategy,
    d.timezone
  );

export const agentRolesTransformFromDtoToDomain = (
  role: AgentRoleDTO
): AgentRolesDomain =>
  new AgentRolesDomain(
    role.id,
    role.name as AgentRole,
    role.description,
    role.permissions
  );

export const agentPermissionsTransformFromDtoToDomain = (
  p: AgentPermissionsDTO
): AgentPermissionsDomain =>
  new AgentPermissionsDomain(p.id, p.name, p.description, p.group);

export const agentTransformFromDtoToDomain = (
  agent: AgentDTO,
  color: string
): AgentDomain =>
  new AgentDomain(
    agent.id,
    agent.name,
    agent.surname,
    agent.email,
    agent.contact_channel,
    agent.notification_config
      ? transformNotificationConfigfromDtoToDomain(agent.notification_config)
      : null,
    agent.merchant_id,
    agent.user_id,
    agent.profile_picture,
    agent.role ? agentRolesTransformFromDtoToDomain(agent.role) : null,
    agent.permissions.map(
      (perm: AgentPermissionsDTO): AgentPermissionsDomain =>
        agentPermissionsTransformFromDtoToDomain(perm)
    ),
    agent.active,
    agent.rotation_enabled,
    color,
    agent.player_ids,
    agent.style_preferences
      ? {
          chatBackground: agent.style_preferences.chat_background,
          colorScheme: agent.style_preferences.color_scheme,
        }
      : null
  );

export const agentArrayTransformFromDtoToDomain = (agents: AgentDTO[]) =>
  agents.map((agent, i) => agentTransformFromDtoToDomain(agent, colorList[i]));
