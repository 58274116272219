import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  VStack,
} from "@chakra-ui/react";
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { Radio, RadioGroup } from "components/ui/radio";
import { GoogleLocationDTO } from "entities/dto/ReviewsDTO";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import ReviewsService from "services/reviews";

function GoogleLocationsOptions() {
  const reviewsRedirectUri = `https://${window.location.hostname}/reviews`;
  const reviewsLocalRedirectUri = reviewsRedirectUri;

  const redirectToReviews = () => {
    window.location.assign(reviewsLocalRedirectUri);
  };

  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const selectOption = ({ value }: { value: string; }) => {
    setSelectedOption(value);
  };

  const [locations, setLocations] = useState<GoogleLocationDTO[]>([]);

  useEffect(() => {
    ReviewsService.getGoogleLocations(auth0Context, merchant.id).then(
      (response) => {
        setLocations(response);
      }
    );
  }, [merchant.id]);

  const onContinue = async () => {
    await ReviewsService.selectGoogleLocation(
      auth0Context,
      merchant.id,
      selectedOption
    );

    return redirectToReviews();
  };

  return (
    <DialogRoot open={true} closeOnEscape={false}>
      <DialogBackdrop  />
      <DialogContent>
        {locations.length ? (
          <>
            <DialogHeader>Choose a Google Location</DialogHeader>
            <DialogBody>
              <RadioGroup
                display="flex"
                defaultValue={selectedOption}
                onValueChange={selectOption}
              >
                <VStack
                  gap={4}
                  mx="auto"
                  justifyContent="start"
                  alignItems="start"
                >
                  {locations.map((location) => (
                    <Radio key={location.id} value={location.place_id}>
                      {location.name} ({location.address})
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </DialogBody>

            <DialogFooter>
              <Button colorPalette={colorScheme} onClick={onContinue}>
                Submit
              </Button>
            </DialogFooter>
          </>
        ) : (
          <DialogHeader>
            We could not find any Google locations connected to your account
          </DialogHeader>
        )}
      </DialogContent>
    </DialogRoot>
  );
}

export default GoogleLocationsOptions;
