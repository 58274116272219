import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  Heading,
  Separator,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import AssignAgentModal from "components/modals/AssignAgent";
import UpdateTags from "components/modals/tags/UpdateTags";
import { useColorMode } from "components/ui/color-mode";
import { SkeletonCircle, SkeletonText } from "components/ui/skeleton";
import { toaster } from "components/ui/toaster";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import useAnalytics from "hooks/use-analytics";
import useContactsStore from "hooks/use-contacts-store";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { batch } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import {
  ConversationTab,
  OpenClosedFilter,
  appendOrReplaceConversation,
  clearSelectedConversations,
  enableBulkActionsToolbar,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  updateConversationSelection,
  updateConversationTagIds,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import InboxService from "services/inbox";
import {
  MAX_ALLOWED_BULK_SELECTION,
  numberOfConversationsPerLoad,
} from "util/constants";
import BulkActions from "../../desktop/BulkActions";
import ConversationSnippet from "./conversation-snippet";
import useRedirectUnauthorized from "hooks/use-redirect-unauthorized";

const ConversationSnippetList = () => {
  const auth0Context = useAuth0();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { tags } = useAppSelector((state) => state.tags);
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const {
    conversations,
    loading,
    activeTab,
    isOpenOrClosed,
    searchText,
    filterAgents,
    filterChannels,
    filterCustomerTagIds,
    activeConversationId,
    selectedConversationIds,
    selectedInbox,
  } = useAppSelector((state) => state.conversations);
  const assignModal = useDisclosure();
  const { track } = useAnalytics();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { updateContactTags } = useContactsStore();
  const { redirectToInbox } = useRedirectUnauthorized();

  const memoizedOnAssignClose = useCallback(() => {
    dispatch(enableBulkActionsToolbar());
    assignModal.onClose();
  }, [selectedConversationIds.length]);

  const [shouldShowTagsModal, setShouldShowTagsModal] =
    useState<boolean>(false);
  const [isRemovingTag, setIsRemovingTag] = useState<boolean>(false);
  const [lastFetchedAt, setLastFetchedAt] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const previousOffset = useRef<number>(0);

  const [selectedConversationId, setSelectedConversationId] = useState<
    number | undefined
  >(undefined);

  const newConversationsFetched = useRef<ConversationDomain[]>();

  const fetchConversations = useCallback(async () => {
    if (conversations.length === previousOffset.current) {
      return;
    }

    previousOffset.current += numberOfConversationsPerLoad;

    setIsLoading(true);

    let nextPage: ConversationDomain[] = [];

    try {
      if (activeTab === ConversationTab.Team) {
        nextPage = await InboxService.getConversations(
          auth0Context,
          isOpenOrClosed === OpenClosedFilter.Open ? "open" : "closed",
          searchText,
          conversations.length,
          merchant.id,
          filterChannels,
          filterAgents,
          filterCustomerTagIds,
          selectedInbox
        );
      } else if (activeTab === ConversationTab.Personal) {
        nextPage = await InboxService.getPersonalConversations(
          auth0Context,
          isOpenOrClosed === OpenClosedFilter.Open ? "open" : "closed",
          searchText,
          conversations.length,
          merchant.id,
          filterChannels,
          filterCustomerTagIds,
          selectedInbox
        );
      } else if (activeTab === ConversationTab.Unassigned) {
        nextPage = await InboxService.getUnassignedConversations(
          auth0Context,
          isOpenOrClosed === OpenClosedFilter.Open ? "open" : "closed",
          searchText,
          conversations.length,
          merchant.id,
          filterChannels,
          filterCustomerTagIds,
          selectedInbox
        );
      }
    } catch (error: unknown) {
      let errorMessage: string | undefined;

      if (error instanceof AxiosError) {
        if (error.response?.status !== 403) {
          errorMessage = `Failed to fetch conversations: ${error.response?.data.message || error.response?.data.code || error.message || "Unknown error"}`;
        }
      } else {
        errorMessage = `Failed to fetch conversations: ${error}`;
      }

      redirectToInbox(errorMessage);
      return;
    } finally {
      setIsLoading(false);
    }

    setHasNextPage(hasMoreData([...conversations, ...nextPage], nextPage));

    newConversationsFetched.current = nextPage;

    nextPage.forEach((conversation) => {
      dispatch(
        appendOrReplaceConversation({
          conversation,
          currentAgentId: currentAgent!.id,
        })
      );
    });
  }, [
    conversations,
    isOpenOrClosed,
    searchText,
    filterCustomerTagIds,
    filterChannels,
    filterAgents,
    activeTab,
    selectedInbox,
    merchant.id,
  ]);

  useEffect(() => {
    setHasNextPage(true);
  }, [
    isOpenOrClosed,
    searchText,
    filterCustomerTagIds,
    filterChannels,
    filterAgents,
    activeTab,
    merchant.id,
  ]);

  const hasMoreData = (
    currentPage: ConversationDomain[],
    nextPage: ConversationDomain[] | undefined
  ): boolean => {
    if (currentPage.length < numberOfConversationsPerLoad) return false;

    if (Array.isArray(nextPage) && !nextPage.length) {
      return false;
    }

    return true;
  };

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const conversationsWrapperRef = useRef<HTMLDivElement>(null);

  const selectedConversation = useMemo(() => {
    return conversations.find((c) => c.id === selectedConversationId);
  }, [selectedConversationId, conversations]);

  const [isListOverflown, setIsListOverflown] = useState<boolean>(false);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedConversations());
    };
  }, [merchant.id]);

  useEffect(() => {
    if (!shouldShowTagsModal) {
      setIsRemovingTag(false);
    }
  }, [shouldShowTagsModal]);

  useEffect(() => {
    if (!conversationsWrapperRef.current) {
      setIsListOverflown(false);

      return;
    }

    setIsListOverflown(
      conversationsWrapperRef.current.scrollHeight >
        conversationsWrapperRef.current.clientHeight
    );
  }, [
    conversationsWrapperRef.current?.scrollHeight,
    conversationsWrapperRef.current?.clientHeight,
  ]);

  const onAssignOpen = (conversationId: number) => {
    setSelectedConversationId(conversationId);
    assignModal.onOpen();
  };

  const onUpdateTagsOpen = (conversationId: number) => {
    setSelectedConversationId(conversationId);
    setShouldShowTagsModal(true);
  };

  const onScroll = useCallback(
    (indexes: number[]) => {
      const [startIndex, endIndex] = indexes;

      if (!conversations.length && isLoading) {
        return;
      }

      if (isLoading) {
        return;
      }

      if (endIndex === conversations.length - 1 && hasNextPage) {
        const timestamp = Date.now();

        if (timestamp - lastFetchedAt < 500) {
          // eslint-disable-next-line
          console.error("Infinite scroll throttled", {
            startIndex,
            endIndex,
            timestamp,
            lastFetchedAt,
            conversationsLength: conversations.length,
            hasNextPage,
          });
          return;
        }

        setLastFetchedAt(timestamp);
        fetchConversations();
      }
    },
    [
      isLoading,
      conversations.length,
      hasNextPage,
      lastFetchedAt,
      fetchConversations,
    ]
  );

  const listRef = useRef<ViewportListRef | null>(null);

  const getTypeOfConversation = () => {
    if (isOpenOrClosed === OpenClosedFilter.Open) {
      return "Open";
    }

    if (isOpenOrClosed === OpenClosedFilter.Closed) {
      return "Closed";
    }

    return "";
  };

  if (loading) {
    return (
      <Box h="100%" w="100%" overflowY="auto" overflowX="hidden">
        <VStack separator={<Separator />}>
          {[...Array(5).keys()].map((i) => (
            <Flex
              key={`skeleton-conversation-${activeTab}-${i}`}
              padding="6"
              w="100%"
              h="fit-content"
              gridGap={4}
            >
              <SkeletonCircle size="10" flexShrink={0} flexGrow={0} />
              <SkeletonText
                noOfLines={2}
                gap={4}
                height={2}
                flexShrink={1}
                flexGrow={1}
              />
            </Flex>
          ))}
        </VStack>
      </Box>
    );
  }

  if (!loading && !conversations.length) {
    return (
      <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
        <Heading
          as="h2"
          fontWeight="bold"
          color={colorMode === "dark" ? "gray.300" : "gray.600"}
        >
          No {getTypeOfConversation()} Conversations
        </Heading>
      </Flex>
    );
  }

  return (
    <>
      <Box
        id={`conversations-wrapper-${activeTab}`}
        ref={conversationsWrapperRef}
        height="100%"
        minHeight="100%"
        maxHeight="100%"
        userSelect={selectedConversationIds.length ? "none" : "auto"}
        w="100%"
        minWidth="100%"
        overflowY="auto"
        overflowX="hidden"
        onClick={(event) => {
          const currentElement = conversationsWrapperRef.current!;

          const target = event.target as HTMLElement;
          const closestCheckbox = target.closest("[data-scope=checkbox]");

          if (!isBaseSize && closestCheckbox) {
            return;
          }

          if (target === currentElement || currentElement.contains(target)) {
            let currentTarget = event.target as HTMLElement | null;

            while (currentTarget !== null) {
              if (currentTarget.id.includes("conversation-")) {
                const conversationId = parseInt(
                  currentTarget.id.split("-")[1],
                  10
                );

                if (selectedConversationIds.length) {
                  if (event.shiftKey) {
                    const firstSelectedIndex = conversations.findIndex(
                      (c) => c.id === selectedConversationIds[0]
                    );
                    const clickedIndex = conversations.findIndex(
                      (c) => c.id === conversationId
                    );

                    if (firstSelectedIndex !== clickedIndex) {
                      const selectedConversations = conversations.slice(
                        Math.min(firstSelectedIndex, clickedIndex),
                        Math.max(firstSelectedIndex, clickedIndex) + 1
                      );

                      if (
                        selectedConversations.length >
                        MAX_ALLOWED_BULK_SELECTION
                      ) {
                        toaster.create({
                          type: "error",
                          title: `You can only select up to ${MAX_ALLOWED_BULK_SELECTION} conversations`,
                        });
                        return;
                      }

                      selectedConversations.forEach(({ id }) => {
                        if (!selectedConversationIds.includes(id)) {
                          dispatch(
                            updateConversationSelection({
                              conversationId: id,
                              isSelected: !selectedConversationIds.includes(id),
                            })
                          );
                        }
                      });
                    }
                  }

                  dispatch(
                    updateConversationSelection({
                      conversationId,
                      isSelected:
                        !selectedConversationIds.includes(conversationId),
                    })
                  );
                  return;
                }

                if (activeConversationId === conversationId) {
                  navigate({
                    pathname: `/${merchant.id}/inbox/`,
                    search: createSearchParams(search).toString(),
                  });
                  return;
                }

                const foundConversation = conversations.find((c) => {
                  return c.id === conversationId;
                });

                track("choose_conversation", {
                  conversation_id: conversationId,
                  channel: foundConversation!.channel,
                  unread_count: foundConversation!.unreadCount,
                });

                navigate({
                  pathname: `/${merchant.id}/inbox/${conversationId}`,
                  search: createSearchParams(search).toString(),
                });

                break;
              }

              currentTarget = currentTarget.parentElement;
            }
          }
        }}
      >
        <ViewportList
          viewportRef={conversationsWrapperRef}
          items={conversations}
          ref={listRef}
          itemSize={94}
          scrollThreshold={1000}
          onViewportIndexesChange={onScroll}
        >
          {(conversation) => (
            <ConversationSnippet
              elementId={`conversation-${conversation.id}-${activeTab}`}
              key={`${conversation.id}-${activeTab}-${conversation.tagIds.join(
                ","
              )}`}
              customerPicture={conversation.picture}
              isSubscribed={conversation.isSubscribed}
              conversationChannel={conversation.channel}
              conversationId={conversation.id}
              customerName={conversation.displayName}
              customerId={conversation.customerId}
              customerChannelId={conversation.customerChannelId}
              conversationDate={conversation.getDisplayDate()}
              lastMessageId={conversation.messageId}
              isConversationOpen={conversation.isOpen}
              previewText={conversation.previewText}
              isLastMessageUndelivered={conversation.isLastMessageUndelivered()}
              isAnyUnread={conversation.isAnyUnread()}
              tagIds={conversation.tagIds}
              unreadCount={conversation.unreadCount}
              assignedAgentId={conversation.assignedAgentId}
              assignedTeamId={conversation.assignedTeamId}
              onAssignOpen={onAssignOpen}
              onUpdateTagsOpen={onUpdateTagsOpen}
            />
          )}
        </ViewportList>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" w="100%">
            <Spinner mb={8} />
          </Flex>
        ) : null}
        {!isLoading && !hasNextPage && isListOverflown ? (
          <>
            <Separator mt={8} />
            <Flex alignItems="center" justifyContent="center" w="100%" py={8}>
              <Text>✅ No more conversations found</Text>
            </Flex>
          </>
        ) : null}
      </Box>
      <AssignAgentModal
        isOpen={assignModal.open}
        onClose={memoizedOnAssignClose}
        assignedAgentId={selectedConversation?.assignedAgentId}
        assignedTeamId={selectedConversation?.assignedTeamId}
        customerId={selectedConversation?.customerId}
        conversationId={selectedConversation?.id}
        conversationChannel={selectedConversation?.channel}
      />
      <UpdateTags
        isOpen={shouldShowTagsModal}
        onClose={() => {
          dispatch(enableBulkActionsToolbar());
          setShouldShowTagsModal(false);
        }}
        isRemoval={isRemovingTag}
        tagIds={selectedConversation?.tagIds || []}
        onSubmit={async (tagIds) => {
          if (
            !selectedConversation?.customerId &&
            !selectedConversationIds.length
          ) {
            toaster.create({
              type: "error",
              title: "Can't add tags to contact without an id",
            });

            return;
          }

          const selectedTags = tags.filter((tag) => tagIds.includes(tag.id));

          if (selectedConversationIds.length) {
            dispatch(startLoadingBulkActionsToolbar());

            const selectedContactIds: number[] = [];

            selectedConversationIds.forEach((conversationId) => {
              const conversation = conversations.find(
                (c) => c.id === conversationId
              );

              if (
                conversation &&
                selectedContactIds.includes(conversation.customerId) === false
              ) {
                selectedContactIds.push(conversation.customerId);
              }
            });

            try {
              let updatedContactIds: number[] = [];

              if (isRemovingTag) {
                updatedContactIds = await ContactsService.bulkRemoveTags(
                  auth0Context,
                  merchant.groupId,
                  selectedContactIds,
                  selectedTags.map((tag) => tag.tag),
                  selectedTags.map((tag) => tag.id)
                );
              } else {
                updatedContactIds = await ContactsService.bulkAddTags(
                  auth0Context,
                  merchant.groupId,
                  selectedContactIds,
                  selectedTags.map((tag) => tag.tag),
                  selectedTags.map((tag) => tag.id)
                );
              }

              if (updatedContactIds.length === 0) {
                toaster.create({
                  type: "error",
                  title: "Failed to perform bulk action.",
                  description: "Please try again later.",
                });

                return;
              }

              batch(() => {
                updatedContactIds.forEach((contactId) => {
                  conversations.forEach((conversation) => {
                    if (conversation.customerId === contactId) {
                      dispatch(
                        updateConversationTagIds({
                          conversationId: conversation.id,
                          tagIds: isRemovingTag
                            ? conversation.tagIds.filter(
                                (tagId) =>
                                  !selectedTags
                                    .map((tag) => tag.id)
                                    .includes(tagId)
                              )
                            : [
                                ...new Set([
                                  ...conversation.tagIds,
                                  ...selectedTags.map((tag) => tag.id),
                                ]),
                              ],
                        })
                      );
                    }
                  });
                });
                dispatch(clearSelectedConversations());
              });
            } catch (_error: unknown) {
              toaster.create({
                type: "error",
                title: "Failed to perform bulk action.",
                description: "Please try again later.",
              });
            } finally {
              dispatch(enableBulkActionsToolbar());
              dispatch(stopLoadingBulkActionsToolbar());
              setShouldShowTagsModal(false);
            }

            return;
          }

          await updateContactTags(
            selectedTags,
            selectedConversation!.customerId
          );
          setShouldShowTagsModal(false);
        }}
      />
      {isBaseSize ? null : (
        <BulkActions
          openAssignModal={() => {
            assignModal.onOpen();
          }}
          openUpdateTagsModal={(willRemoveTag) => {
            if (willRemoveTag) {
              setIsRemovingTag(true);
            }
            setShouldShowTagsModal(true);
          }}
        />
      )}
    </>
  );
};

export default ConversationSnippetList;
