import { Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";
import { getChannelIcon } from "util/constants";

interface selectedContactChannelsProps {
  selectedContact: ContactDomain;
  setChannelId: (id: string) => void;
  channelId: string | undefined;
}

const SelectedContactChannels = ({
  selectedContact,
  setChannelId,
  channelId,
}: selectedContactChannelsProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <VStack gap={2} w="100%">
      {selectedContact.channels
        .filter((chan) => merchant.isMerchantChannelEnabled(chan.type))
        .map((chan) => {
          return (
            <Flex
              key={chan.id}
              id={chan.id}
              width="100%"
              py={4}
              px={8}
              gridGap={4}
              bgColor={
                channelId === chan.id
                  ? colorMode === "dark"
                    ? `${colorScheme}.400`
                    : `${colorScheme}.500`
                  : "inherit"
              }
              _hover={{
                bgColor:
                  channelId === chan.id
                    ? colorMode === "dark"
                      ? `${colorScheme}.500`
                      : `${colorScheme}.400`
                    : colorMode === "dark"
                    ? "gray.600"
                    : "gray.50",
              }}
              mx={4}
              alignItems="center"
              cursor="pointer"
              onClick={() => setChannelId(chan.id!)}
              data-testid="select-contact-channel"
            >
              <Icon
                asChild={true}
                width="1rem"
                height="1rem"
              >
                <ReactSVG src={getChannelIcon(chan.type)} />
              </Icon>
              <Text color={channelId === chan.id ? "white" : "inherit"}>
                {chan.getDisplayChannelName()}
              </Text>
              <Text color={channelId === chan.id ? "white" : "inherit"}>
                {chan.handle}
              </Text>
            </Flex>
          );
        })}
    </VStack>
  );
};

export default SelectedContactChannels;
