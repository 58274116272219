import { Button, Icon, Portal } from "@chakra-ui/react";
import data from "@emoji-mart/data";
import EmojiPickerWithSuspense from "components/shared/LazyEmojiPicker";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import React, { memo, useCallback, useState } from "react";
import { LuSmile } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface EmojiShortcutProps {
  onEmojiClick: (emoji: { native: string }) => void;
}

const EmojiShortcut = ({ onEmojiClick }: EmojiShortcutProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [open, setOpen] = useState<boolean>(false);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <PopoverRoot
      closeOnInteractOutside={true}
      positioning={{ placement: "top" }}
      open={open}
      onOpenChange={(e) => setOpen(e.open)}
    >
      <Portal>
        <PopoverContent width="auto">
          <EmojiPickerWithSuspense
            data={data}
            theme={colorMode}
            onEmojiSelect={(emojiData: { native: string }) => {
              onEmojiClick(emojiData);
              onClose();
            }}
          />
        </PopoverContent>
      </Portal>

      <PopoverTrigger>
        <Button variant="ghost" size="xs" p={0} colorPalette={colorScheme}>
          <Icon as={LuSmile} width="1rem" height="1rem" />
        </Button>
      </PopoverTrigger>
    </PopoverRoot>
  );
};

export default memo(EmojiShortcut);
