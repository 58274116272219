import { Collapsible, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
  TagMultiSelectOption,
  TagsMenuList,
} from "components/modals/tags/UpdateTags";
import AgentOption, {
  AvailableAgentOption,
  sortOptions,
} from "components/shared/AgentOption";
import ChannelOption, {
  ChannelOptionTypes,
} from "components/shared/ChannelOption";
import { useColorMode } from "components/ui/color-mode";
import AgentDomain from "entities/domain/agents/agent-domain";
import { transformFromAvailableChannelsToOptions } from "entities/transformers/admin-merchant-transformer";
import { tagToTagOption } from "entities/transformers/tags-transformer";
import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import {
  ConversationTab,
  setFilterAgents,
  setFilterChannels,
  setFilterCustomerTagIds,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import { canManageTeamInbox, canManageUnassignedInbox } from "util/permissions";

interface ConversationFiltersProps {
  showFilters: boolean | undefined;
}

interface AvailableTagOption {
  value: string;
  label: string;
}

const ConversationFilters = ({ showFilters }: ConversationFiltersProps) => {
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { activeTab, filterAgents, filterChannels, filterCustomerTagIds } =
    useAppSelector((state) => state.conversations);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { tags } = useAppSelector((state) => state.tags);
  const { agents, currentAgent } = useAppSelector((state) => state.agents);

  const resetFilterArea = () => {
    dispatch(setFilterChannels([]));
    dispatch(setFilterAgents([]));
    dispatch(setFilterCustomerTagIds([]));
    setMenuIsOpen(false);
  };

  useEffect(() => {
    if (
      (!showFilters && filterChannels.length !== 0) ||
      filterAgents.length !== 0 ||
      filterCustomerTagIds.length !== 0
    ) {
      resetFilterArea();
    }
  }, [showFilters]);

  const handleTagsChange = (e: MultiValue<AvailableTagOption>) => {
    const newAudienceTagIds = e.map(
      (tagOption: AvailableTagOption) => tagOption.value
    );

    dispatch(setFilterCustomerTagIds(newAudienceTagIds));
    setSelectedTagOptionsValue(e);
    if (e.length > 0) {
      setMenuIsOpen(true);
    }
  };

  const [availableTagOptions, setAvailableTagOptions] = useState<
    MultiValue<AvailableTagOption>
  >([]);

  const [selectedTagOptionsValue, setSelectedTagOptionsValue] = useState<
    MultiValue<AvailableTagOption>
  >([]);

  const agentOptions: AvailableAgentOption[] | undefined = sortOptions(
    agents.map((a: AgentDomain) => ({
      value: a.id.toString(),
      label: a.getFullName(),
      avatarUrl: a.getPicture() || undefined,
    }))
  );

  if (currentAgent && canManageUnassignedInbox(merchant.id, currentAgent!)) {
    agentOptions?.unshift({
      value: "unassigned",
      label: "Unassigned",
    });
  }

  const [selectedAgentOptionsValue, setSelectedAgentOptionsValue] = useState<
    MultiValue<AvailableAgentOption>
  >([]);

  const handleAgentsChange = (e: MultiValue<AvailableAgentOption>) => {
    setSelectedAgentOptionsValue(e);

    const newAgents = e.map((agentOption) => agentOption.value);

    dispatch(setFilterAgents(newAgents));
  };

  const channelOptions: ChannelOptionTypes[] | undefined = [
    ...new Map(
      merchant.channels.map((channel) => [channel.channelName, channel])
    ).values(),
  ].map(transformFromAvailableChannelsToOptions);

  const [selectedChannelOptionsValue, setSelectedChannelOptionsValue] =
    useState<MultiValue<ChannelOptionTypes>>([]);

  const handleChannelsChange = (e: MultiValue<ChannelOptionTypes>) => {
    setSelectedChannelOptionsValue(e);

    const newChannels = e.map((channelOption) => channelOption.value);

    dispatch(setFilterChannels(newChannels));
  };

  useEffect(() => {
    if (!tags.length) {
      return;
    }

    setAvailableTagOptions(tags.map(tagToTagOption));
  }, [tags]);

  return (
    <Collapsible.Root open={showFilters} width="100%">
      <Collapsible.Content
        style={{
          overflow: "initial",
          width: "100%",
        }}
      >
        <Flex
          alignItems={isBaseSize ? "start" : "center"}
          px={isBaseSize ? 2 : 0}
          gridGap={3}
          direction={isBaseSize ? "column" : "row"}
          width="100%"
          {...(isBaseSize
            ? {}
            : {
                maxWidth: "600px",
              })}
          justifyContent="start"
        >
          <Select
            isMulti
            placeholder="Filter by Channels"
            value={selectedChannelOptionsValue}
            onChange={handleChannelsChange}
            components={{
              Option: ChannelOption,
            }}
            options={channelOptions}
            closeMenuOnSelect={false}
            styles={{
              ...{
                ...getReactSelectStyles(colorMode || "light", colorScheme),
                container: (provided: any) => ({
                  ...getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  ).container(provided),
                  width: "100%",
                  minWidth: "150px",
                }),
                valueContainer: (provided: any) => ({
                  ...provided,
                  flexWrap: "nowrap",
                  overflow: "hidden",
                }),
                multiValue: (provided: any, state: any) => ({
                  ...getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  ).multiValue(provided, state),
                  minWidth: "50px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }),
                placeholder: (provided: any) => ({
                  ...provided,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }),
              },
            }}
          />
          {canManageTeamInbox(merchant.id, currentAgent!) &&
          activeTab === ConversationTab.Team ? (
            <Select
              isMulti
              placeholder="Filter by Agents"
              value={selectedAgentOptionsValue}
              onChange={handleAgentsChange}
              components={{
                Option: AgentOption,
              }}
              options={agentOptions}
              closeMenuOnSelect={false}
              styles={{
                ...{
                  ...getReactSelectStyles(colorMode || "light", colorScheme),
                  container: (provided: any) => ({
                    ...getReactSelectStyles(
                      colorMode || "light",
                      colorScheme
                    ).container(provided),
                    width: "100%",
                    minWidth: "150px",
                  }),
                  valueContainer: (provided: any) => ({
                    ...provided,
                    flexWrap: "nowrap",
                    overflow: "hidden",
                  }),
                  multiValue: (provided: any, state: any) => ({
                    ...getReactSelectStyles(
                      colorMode || "light",
                      colorScheme
                    ).multiValue(provided, state),
                    minWidth: "50px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }),
                },
              }}
            />
          ) : null}
          <Select
            isMulti
            placeholder="Filter by Tags"
            isSearchable
            value={selectedTagOptionsValue || []}
            onChange={handleTagsChange}
            options={availableTagOptions}
            components={{
              MenuList: TagsMenuList as any,
              Option: TagMultiSelectOption,
            }}
            styles={{
              ...{
                ...getReactSelectStyles(colorMode || "light", colorScheme),
                container: (provided: any) => ({
                  ...getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  ).container(provided),
                  width: "100%",
                  minWidth: "150px",
                }),
                valueContainer: (provided: any) => ({
                  ...provided,
                  flexWrap: "nowrap",
                  overflow: "hidden",
                }),
                multiValue: (provided: any, state: any) => ({
                  ...getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  ).multiValue(provided, state),
                  minWidth: "50px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }),
                placeholder: (provided: any) => ({
                  ...provided,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }),
              },
            }}
            menuIsOpen={menuIsOpen}
            onMenuClose={() => setMenuIsOpen(false)}
            onMenuOpen={() => setMenuIsOpen(true)}
          />
        </Flex>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default ConversationFilters;
