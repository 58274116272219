import { Button, Icon, VStack, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import { MessageButtonType } from "entities/domain/conversations/message-domain";

import React from "react";
import { LuExternalLink, LuPhone, LuReply } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface AcitonButtonsProps {}

const ActionButtons: React.FC<AcitonButtonsProps> = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    messageInput: { template },
  } = useAppSelector((state) => state.conversations);
  return !template ||
    !template.buttons ||
    template.buttons.length === 0 ? null : (
    <VStack my={2} pl={2} width="100%" alignItems="start" flexGrow={0}>
      {template.buttons.map((button, index) => (
        <Tooltip
          key={index}
          content={
            button.type === MessageButtonType.PHONE_NUMBER
              ? button.phoneNumber
              : button.type === MessageButtonType.URL
              ? button.url
              : ""
          }
          disabled={button.type === MessageButtonType.QUICK_REPLY}
        >
          <Button
            zIndex={5} // To prevent the button from being covered by the tail
            width={isBaseSize ? "100%" : "auto"}
            variant={colorMode === "light" ? "ghost" : "solid"}
            display="flex"
            size="xs"
            textTransform="none"
            alignItems="center"
            justifyContent="start"
            colorPalette={colorMode === "light" ? "blackAlpha" : "whiteAlpha"}
          >
            <Icon
              as={
                button.type === MessageButtonType.URL
                  ? LuExternalLink
                  : button.type === MessageButtonType.PHONE_NUMBER
                  ? LuPhone
                  : LuReply
              }
              color="currentColor"
              mr={2}
            />
            {button.text}
          </Button>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default ActionButtons;
