import {
  Button,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React, { memo } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useAppSelector } from "redux/hooks";

interface StartConversationButtonProps {
  onClick: () => void;
}

const StartConversationButton = ({ onClick }: StartConversationButtonProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Button
      colorPalette={colorScheme}
      aria-label="Start a new conversation"
      gridGap={2}
      {...(isBaseSize
        ? {
            borderRadius: "full",
            size: "sm",
          }
        : {
            size: "md",
          })}
      onClick={onClick}
    >
      {isBaseSize ? null : (
        <Icon
          as={FaPencilAlt}
          fill={colorMode === "dark" ? `${colorScheme}.800` : "white"}
        />
      )}
      {isBaseSize ? "Write" : "New Message"}
    </Button>
  );
};

export default memo(StartConversationButton);
