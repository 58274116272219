import { Icon, IconButton } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { LuTrash } from "react-icons/lu";
import { clearMessageInput } from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface ClearInputButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

const ClearInputButton: React.FC<ClearInputButtonProps> = ({
  onClick,
  isDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <IconButton
      aria-label="Clear input"
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "> svg": {
          transition: "width 0.2s, height 0.2s",
          height: "1rem",
          width: "1rem",
        },
        ":hover > svg": {
          width: isDisabled ? "1rem" : "0.8rem",
          height: isDisabled ? "1rem" : "0.8rem",
        },
      }}
      onClick={() => {
        dispatch(clearMessageInput());
        onClick();
      }}
      size="xs"
      colorPalette={colorScheme}
      variant="ghost"
    >
      <Icon
        as={LuTrash}
        color={
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.400`
        }
      />
    </IconButton>
  );
};

export default ClearInputButton;
