import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as StripeIcon } from "assets/icons/stripe.svg";
import BankAccountForm from "components/bank-account-form";
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import { SocketOnboardingAction } from "entities/ISocketArgs";
import UserGuideActionsDomain from "entities/domain/userGuides/user-guide-actions";
import { UserGuideStatus } from "entities/domain/userGuides/user-guides";
import { useWebSocket } from "hooks/use-socket";
import React, { ReactNode, useEffect, useState } from "react";
import { LuCreditCard } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import PaymentsService from "services/payments";
import { isMobileApp } from "util/methods";
import {
  collapseAccordionItem,
  isAccordionItemExpanded,
} from "./AccordionHelpers";

interface StepThreeProps {
  actions?: UserGuideActionsDomain[];
}

interface BankingPlatform {
  name: string;
  icon: ReactNode;
  isCompleted: boolean;
  setupDetails: any;
}

const StepThree: React.FC<StepThreeProps> = ({ actions }) => {
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const defaultBankingPlatforms: BankingPlatform[] = [
    {
      name: "Open Banking",
      icon: (
        <Icon
          as={LuCreditCard}
          width={6}
          height={6}
          color={colorMode === "dark" ? "white" : "black"}
        />
      ),
      isCompleted: false,
      setupDetails: {
        title: "Provide Bank Account Details",
        body: "This allows you to request payments through Fuzey.",
        form: <BankAccountForm />,
      },
    },
    {
      name: "Stripe",
      icon: <Icon as={StripeIcon} />,
      isCompleted: false,
      setupDetails: {
        title: "Connect with Stripe",
        body: "This allows you to request card payments through Fuzey.",
        openInNewTab: async () => {
          try {
            await PaymentsService.createStripeAccountLink(
              auth0Context,
              merchant.id
            ).then((response) => {
              if (isMobileApp()) {
                window.natively.openExternalURL(response.url);
              } else {
                window.open(response.url, "_blank");
              }
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        },
      },
    },
  ].filter((pl) => {
    if (merchant.country === "US") {
      return pl.name !== "Open Banking";
    }

    return true;
  });

  const [bankingPlatforms, setBankingPlatforms] = useState(
    defaultBankingPlatforms
  );

  const [bankingEvent, setBankingEvent] = useState(
    {} as SocketOnboardingAction
  );

  useEffect(() => {
    const actionByName = actions?.reduce((map, action) => {
      return { ...map, [action.name]: action };
    }, {} as { [key: string]: UserGuideActionsDomain });

    const newBankingPlatforms = bankingPlatforms.map((bankingPlatform) => {
      if (bankingPlatform.name === "Open Banking") {
        return {
          ...bankingPlatform,
          isCompleted:
            actionByName?.provide_bank_details?.status ===
            UserGuideStatus.COMPLETED,
        };
      }

      if (bankingPlatform.name === "Stripe") {
        return {
          ...bankingPlatform,
          isCompleted:
            actionByName?.connect_stripe?.status === UserGuideStatus.COMPLETED,
        };
      }

      return bankingPlatform;
    });

    setBankingPlatforms(newBankingPlatforms);
  }, []);

  const handleGuideActionCompleted = (args: SocketOnboardingAction) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    const { guide_name: guideName, action_name: actionName } = args;

    const isOnboarding = guideName === "onboarding";
    const isOpenBankingAction = actionName === "provide_bank_details";
    const isStripeAction = actionName === "connect_stripe";
    const isBankingGroupAction = isOpenBankingAction || isStripeAction;

    if (isOnboarding && isBankingGroupAction) {
      setBankingEvent(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
    }
  };

  useEffect(() => {
    addEventHandler("user_guide_action_completed", handleGuideActionCompleted);
    return () => {
      removeEventHandler(
        "user_guide_action_completed",
        handleGuideActionCompleted
      );
    };
  }, [addEventHandler, removeEventHandler]);

  const handleBankingWS = (bankingEv: SocketOnboardingAction) => {
    const isOpenBankingAction =
      bankingEv.action_name === "provide_bank_details";
    const isStripeAction = bankingEv.action_name === "connect_stripe";

    const newBankingPlatforms = bankingPlatforms.map((bankingPlatform) => {
      if (isOpenBankingAction && bankingPlatform.name === "Open Banking") {
        if (isAccordionItemExpanded(0, expandedIndices)) {
          collapseAccordionItem(0, expandedIndices, setExpandedIndices);
        }

        return {
          ...bankingPlatform,
          isCompleted: true,
        };
      }

      if (isStripeAction && bankingPlatform.name === "Stripe") {
        if (isAccordionItemExpanded(1, expandedIndices)) {
          collapseAccordionItem(1, expandedIndices, setExpandedIndices);
        }

        return {
          ...bankingPlatform,
          isCompleted: true,
        };
      }

      return bankingPlatform;
    });
    setBankingPlatforms(newBankingPlatforms);
  };

  useEffect(() => {
    if (Object.keys(bankingEvent).length > 0) {
      handleBankingWS(bankingEvent);
    }
  }, [bankingEvent]);

  const getBadge = (bankingPlatform: BankingPlatform, isExpanded: boolean) => {
    if (bankingPlatform.isCompleted) {
      return <Badge colorPalette="green">Connected</Badge>;
    }

    if (isExpanded) {
      return <Badge colorPalette={colorScheme}>Click to collapse</Badge>;
    }

    return <Badge colorPalette="purple">Click to set up</Badge>;
  };

  return (
    <AccordionRoot
      collapsible={true}
      borderColor="gray.50"
      value={expandedIndices.map((index) => index.toString())}
      onValueChange={({ value: indices }) =>
        setExpandedIndices(indices.map((i) => parseInt(i, 10)))
      }
    >
      {bankingPlatforms.map((platform, index) => (
        <AccordionItem value={index.toString()} disabled={platform.isCompleted}>
          <h2>
            <AccordionItemTrigger
              opacity="1!important"
              bg={
                platform.isCompleted
                  ? colorMode === "dark"
                    ? "green.800"
                    : "green.50"
                  : "inherit"
              }
              _hover={{
                bg: platform.isCompleted
                  ? colorMode === "dark"
                    ? "green.700"
                    : "green.50"
                  : colorMode === "dark"
                  ? "gray.800"
                  : "gray.50",
              }}
              px={4}
              _expanded={{
                bg:
                  colorMode === "dark"
                    ? `${colorScheme}.800`
                    : `${colorScheme}.50`,
                borderBottomLeftRadius: "1rem",
                borderBottomRightRadius: "1rem",
              }}
            >
              <Flex flex="1" textAlign="left" gridGap={2} alignItems="center">
                {platform.icon}
                <Text>{`Enable payments via ${platform.name}`}</Text>
              </Flex>
              {getBadge(platform, expandedIndices.includes(index))}
            </AccordionItemTrigger>
          </h2>
          <AccordionItemContent pb={4} px="2.5rem">
            <Heading>{platform.setupDetails?.title}</Heading>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              my={4}
              gridGap={4}
            >
              <Text>{platform.setupDetails?.body}</Text>
              {platform.setupDetails?.form || (
                <Button
                  colorPalette={colorScheme}
                  alignSelf="end"
                  onClick={() => platform.setupDetails?.openInNewTab()}
                  aria-label={`Connect with ${platform.name}`}
                >
                  Connect with {platform.name}
                </Button>
              )}
            </Flex>
          </AccordionItemContent>
        </AccordionItem>
      ))}
    </AccordionRoot>
  );
};

export default StepThree;
