import { Box, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LuTrash2 } from "react-icons/lu";

interface FileThumbnailWrapperProps {
  fileName: string;
  children: React.ReactNode;
  showText: boolean;
  size: "sm" | "md" | "lg";
  onDelete?: () => void;
}

const FileThumbnailWrapper = ({
  fileName,
  children,
  showText,
  size,
  onDelete,
}: FileThumbnailWrapperProps) => {
  const [boxSize, setBoxSize] = useState<number>(4);
  const [buttonSize, setButtonSize] = useState<"xs" | "sm" | "md">("xs");

  useEffect(() => {
    switch (size) {
      case "sm":
        setBoxSize(4);
        setButtonSize("xs");
        break;
      case "md":
        setBoxSize(4);
        setButtonSize("sm");
        break;
      case "lg":
        setBoxSize(6);
        setButtonSize("md");
        break;
      default:
        setBoxSize(4);
        setButtonSize("xs");
        break;
    }
  }, [size]);

  return (
    <HStack>
      <Box position="relative" width="fit-content">
        <Box borderRadius="lg" overflow="hidden" width="fit-content">
          {children}
        </Box>
        {typeof onDelete === "undefined" ? null : (
          <IconButton
            aria-label="Remove file"
            size={buttonSize}
            colorPalette="red"
            position="absolute"
            top={0}
            right={0}
            mt={boxSize / -2}
            mr={boxSize / -2}
            onClick={onDelete}
          >
            <Icon as={LuTrash2} boxSize={boxSize} />
          </IconButton>
        )}
      </Box>
      {size !== "sm" && showText ? <Text>{fileName}</Text> : null}
    </HStack>
  );
};

export default FileThumbnailWrapper;
