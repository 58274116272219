import { AvatarRootProps, Float, Icon } from "@chakra-ui/react";
import { Avatar } from "components/ui/avatar";
import { useColorMode } from "components/ui/color-mode";
import React, { memo, useEffect, useState } from "react";
import { LuUserRound } from "react-icons/lu";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";
import { isIconFillable } from "util/constants";

type ProfileAvatarProps = {
  name: string;
  channelIcon?: string;
  assignedPerson?: any;
  showInitials?: boolean;
  profilePicture: string;
  channelPlacement?: string;
  onClick?: () => void;
  avatarStyle?: AvatarRootProps;
};

const ProfileAvatar = ({
  channelIcon,
  channelPlacement = "bottom-end",
  name,
  showInitials,
  profilePicture,
  assignedPerson,
  onClick,
  avatarStyle,
}: ProfileAvatarProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [channelColor, setChannelColor] = useState<string>(
    colorMode === "light" ? `${colorScheme}.500` : `${colorScheme}.200`
  );

  useEffect(() => {
    setChannelColor(
      colorMode === "light" ? `${colorScheme}.500` : `${colorScheme}.200`
    );
  }, [colorMode, colorScheme]);

  return (
    <Avatar
      key={`${name}-${channelIcon}`}
      size="sm"
      src={profilePicture}
      name={name?.startsWith("+") ? undefined : name}
      onClick={onClick}
      fallback={showInitials ? undefined : <Icon as={LuUserRound} />}
      showInitials={showInitials}
      {...avatarStyle}
    >
      {channelIcon ? (
        <Float placement={channelPlacement as any} offsetX="1" offsetY="1">
          <Icon
            asChild={true}
            boxSize={assignedPerson ? "1.25rem" : "1rem"}
            css={{
              "& path": isIconFillable(channelIcon)
                ? {
                    fill: channelColor,
                  }
                : {},
            }}
            background="transparent"
            border="none"
          >
            <ReactSVG src={channelIcon} />
          </Icon>
        </Float>
      ) : null}
      {assignedPerson ? (
        <Float
          placement={
            channelPlacement === "bottom-end" ? "top-end" : "bottom-end"
          }
          cursor="pointer"
          border="none"
        >
          {assignedPerson}
        </Float>
      ) : null}
    </Avatar>
  );
};

export default memo(ProfileAvatar);
