import {
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Alert } from "components/ui/alert";
import { CloseButton } from "components/ui/close-button";
import React from "react";

interface WhatsappDocumentsModalProps {
  isShown: boolean;
  onClose: () => void;
}

const WhatsappDocumentsModal = ({
  isShown,
  onClose,
}: WhatsappDocumentsModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return !isShown ? null : (
    <Alert
      status="info"
      variant="subtle"
      position="relative"
      title="Make sure you are aware of WhatsApp Messaging best practices"
    >
      <CloseButton
        position="absolute"
        right={isBaseSize ? 0 : 4}
        top={isBaseSize ? 0 : 4}
        onClick={onClose}
      />
      <VStack alignItems="start" justifyContent="start" gap={1}>
        <Text fontSize="sm">
          <Link href="https://business.whatsapp.com/policy" target="_blank" rel="noopener noreferrer">
            📄💼 WhatsApp business messaging policy
          </Link>
        </Text>
        <Text fontSize="sm">
          <Link
            href="https://business.whatsapp.com/policy#how_we_enforce_our_policies"
            target="_blank" rel="noopener noreferrer"
          >
            🔍⚖️ How WhatsApp enforces their policy
          </Link>
        </Text>
        <Text fontSize="sm">
          <Link
            href="https://business.whatsapp.com/policy#best_practices"
            target="_blank" rel="noopener noreferrer"
          >
            🌟📊 Best practices
          </Link>
        </Text>
      </VStack>
    </Alert>
  );
};

export default WhatsappDocumentsModal;
