import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import MessageDomain from "entities/domain/conversations/message-domain";
import React from "react";
import ExpandableText from "./ExpandableText";
import PhoneIcon from "./PhoneIcon";

interface PhoneCallWithAudioProps {
  message: MessageDomain;
}

const PhoneCallWithAudio: React.FC<PhoneCallWithAudioProps> = ({ message }) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      flexDirection="column"
      w={isBaseSize ? "80%" : "30%"}
      position="relative"
      alignItems="center"
      mx="auto"
      my={2}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        mb="-1rem"
        zIndex={1}
        p={2}
        borderRadius="full"
        backgroundColor={colorMode === "dark" ? "gray.700" : "gray.50"}
      >
        <PhoneIcon message={message} colorMode={colorMode as "dark" | "light" || "light"} />
      </Flex>

      <Flex
        borderRadius="xl"
        pt={4}
        px={4}
        direction="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor={colorMode === "dark" ? "gray.700" : "gray.50"}
        width="fit-content"
      >
        <ExpandableText message={message} shouldLinkify={message.hasURLs()} />
        <Box my="1rem">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            data-private
            style={{
              width: "250px",
              height: "2rem",
              margin: "0.25rem",
            }}
            onError={(err) => {
              // eslint-disable-next-line no-console
              console.error("Failed to load audio", err);
            }}
            controls
          >
            <source
              src={message.attachments[0].url}
              type={message.attachments[0].type}
            />
          </audio>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PhoneCallWithAudio;
