import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from "components/ui/accordion";
import MessageDomain from "entities/domain/conversations/message-domain";
import React, { useEffect } from "react";
import { humanizeCamelCase } from "util/methods";

interface MessageAttributesProps {
  message: MessageDomain;
  isOpened?: boolean;
  rejectAttributes?: string[];
}

const MessageAttributes: React.FC<MessageAttributesProps> = ({
  message,
  isOpened = false,
  rejectAttributes = [],
}) => {
  if (!message.metadata || message.metadata.attributes === undefined) {
    return null;
  }

  const rejectEmptyAttributes = (attributeKey: string) => {
    const attributeValue = message.metadata!.attributes![attributeKey];

    if (!attributeValue) {
      return false;
    }

    return true;
  };

  const rejectSpecificAttributes = (attributeKey: string) => {
    return !rejectAttributes.includes(attributeKey);
  };

  const [expandedItems, setExpandedItems] = React.useState<string[]>(
    isOpened ? ["0"] : []
  );

  useEffect(() => {
    if (isOpened) {
      setExpandedItems(["0"]);
    }
  }, [isOpened]);

  return (
    <AccordionRoot
      collapsible={true}
      multiple={true}
      value={expandedItems}
      onValueChange={({ value: newValue }) => setExpandedItems(newValue)}
    >
      <AccordionItem value="0">
        <AccordionItemTrigger px={4}>
          <Box as="span" flex="1" textAlign="left">
            All Details
          </Box>
        </AccordionItemTrigger>
        <AccordionItemContent w="inherit" pb={4}>
          <VStack gap={4} px={4} alignItems="flex-start">
            {Object.keys(message.metadata.attributes)
              .filter(rejectEmptyAttributes)
              .filter(rejectSpecificAttributes)
              .map((key) => (
                <Flex
                  key={key}
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">{humanizeCamelCase(key)}</Text>
                  <Text>{message.metadata!.attributes![key]}</Text>
                </Flex>
              ))}
          </VStack>
        </AccordionItemContent>
      </AccordionItem>
    </AccordionRoot>
  );
};

export default MessageAttributes;
