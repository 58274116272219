import { Flex } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";

interface RatingProps {
  value: number;
  className?: string;
}

const Rating = ({ value, className = "" }: RatingProps) => {
  const { colorMode } = useColorMode();

  const wholeStars = Math.floor(value);
  const halfStars = value % 1;
  const emptyStars = 5 - (wholeStars + Math.ceil(halfStars));

  return (
    <Flex
      color={colorMode === "dark" ? "yellow.200" : "yellow.500"}
      className={className}
      alignItems="center"
      justifyContent="center"
    >
      {[...Array(wholeStars).keys()].map((i: number) => (
        <FaStar key={i} />
      ))}
      {halfStars ? <FaStarHalfAlt /> : null}
      {[...Array(emptyStars).keys()].map((i: number) => (
        <FaRegStar key={i} />
      ))}
    </Flex>
  );
};

export default Rating;
