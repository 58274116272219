import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { InputGroup } from "components/ui/input-group";
import useDebounce from "hooks/use-debounce";
import React from "react";
import { LuSearch } from "react-icons/lu";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AutoTraderIntegrationItem from "./items/AutoTrader";
import GoogleReviewsIntegrationItem from "./items/GoogleReviews";
import KeyloopIntegrationItem from "./items/Keyloop";
import MarketPlacesIntegrationItem from "./items/MarketPlaces";
import QuickbooksIntegrationItem from "./items/Quickbooks";
import StripeIntegrationItem from "./items/Stripe";
import XeroIntegrationItem from "./items/Xero";
import ZapierIntegrationItem from "./items/Zapier";

const NewIntegrations: React.FC = () => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const integrations = [
    {
      name: "keyloop",
      element: <KeyloopIntegrationItem />,
    },
    {
      name: "autotrader",
      element: <AutoTraderIntegrationItem />,
    },
    {
      name: "marketplaces",
      element: <MarketPlacesIntegrationItem />,
    },
    {
      name: "zapier",
      element: <ZapierIntegrationItem />,
    },
    {
      name: "stripe",
      element: <StripeIntegrationItem />,
    },
    {
      name: "quickbooks",
      element: <QuickbooksIntegrationItem />,
    },
    {
      name: "xero",
      element: <XeroIntegrationItem />,
    },
    {
      name: "google",
      element: <GoogleReviewsIntegrationItem />,
    },
  ];

  const [searchText, setSearchText] = React.useState<string>("");
  const debouncedSearchedText = useDebounce(searchText, 500);

  return (
    <VStack w="100%" gap={0} overflow="hidden">
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="start"
        py={4}
        px={isBaseSize ? 4 : 0}
        {...(isBaseSize
          ? {
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: colorMode === "dark" ? "gray.600" : "gray.200",
            }
          : {})}
      >
        <InputGroup maxWidth="500px" startElement={<Icon as={LuSearch} />}>
          <Input
            colorPalette={colorScheme}
            id="search-bar"
            placeholder="Search"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </InputGroup>
      </Flex>
      <Box
        w="100%"
        h="100%"
        overflow="hidden"
        borderTopRadius={isBaseSize ? 0 : "3xl"}
      >
        <HStack
          w="100%"
          h="100%"
          borderTopRadius={isBaseSize ? 0 : "3xl"}
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          justifyContent="start"
          alignItems="start"
          overflowY="auto"
          flexWrap="wrap"
          gap={6}
          p={8}
        >
          {integrations
            .filter((i) => i.name.includes(debouncedSearchedText))
            .map((i) => (
              <React.Fragment key={i.name}>{i.element}</React.Fragment>
            ))}
        </HStack>
        <Outlet />
      </Box>
    </VStack>
  );
};

export default NewIntegrations;
