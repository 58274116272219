import {
  Box,
  Button,
  CheckboxGroup,
  Fieldset,
  HStack,
  Input,
  Link,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";

import { OptionTypes } from "components/shared/filter";
import PhoneInputWithSuspense, {
  isValidPhoneNumber,
  type Country,
} from "components/shared/LazyPhoneInput";
import WarningTextComponent, {
  MultipleWarnings,
} from "components/shared/WarningTextComponent";
import { Checkbox } from "components/ui/checkbox";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import React, { ChangeEvent, useEffect, useState } from "react";
import { LuX } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import * as yup from "yup";

interface YupValidationError {
  message: string;
}

export interface TagOptionLabelTypes extends OptionTypes {
  shouldRenderClose?: boolean;
}

interface ContactFormProps {
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  modalHeader: string;
  onFirstNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  firstName: string;
  onLastnameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  lastName: string;
  fullNameRequired?: boolean;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  isPhoneDisabled: boolean;
  isPhoneNullable: boolean;
  isEmailNullable: boolean;
  phoneNumber: string;
  onPhoneNumChange: (value: any) => void;
  onCheckChan: (c: string[]) => void;
  checkedChannels: string[];
  handleSave: () => void;
  defaultCheckedChans?: string[];
  defaultHiddenChans?: string[];
  disabledChans: ConversationChannel[];
}

const ContactForm = ({
  isOpen,
  onClose,
  modalHeader,
  onFirstNameChange,
  firstName,
  onLastnameChange,
  lastName,
  fullNameRequired = false,
  onEmailChange,
  isEmailNullable,
  email,
  isPhoneDisabled,
  isPhoneNullable,
  phoneNumber,
  onPhoneNumChange,
  onCheckChan,
  checkedChannels,
  handleSave,
  defaultCheckedChans,
  defaultHiddenChans,
  disabledChans,
  isSaving,
}: ContactFormProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { modalLoading } = useAppSelector((state) => state.contacts);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [warnings, setWarnings] = useState<MultipleWarnings | undefined>(
    undefined
  );

  useEffect(() => {
    setWarnings(undefined);
  }, []);

  useEffect(() => {
    const newWarnings: MultipleWarnings = {};

    if (phoneNumber && phoneNumber !== "" && !isValidPhoneNumber(phoneNumber)) {
      newWarnings.invalid_phone_number = "* Invalid phone number format";
    }

    let emailError;

    try {
      yup.string().email().validateSync(email);
    } catch (error: any) {
      const { message } = error as YupValidationError;
      emailError = message[0].toUpperCase() + message.slice(1);
    }
    if (email && emailError) {
      newWarnings.invalid_email = `* ${emailError}`;
    }

    if (Object.keys(newWarnings).length === 0) {
      setWarnings(undefined);
      return;
    }

    setWarnings(newWarnings);
  }, [phoneNumber, email]);

  const formatChannelName = (channelName: ConversationChannel) => {
    switch (channelName) {
      case ConversationChannel.SMS:
        return "SMS";
      case ConversationChannel.WHATSAPP:
        return "WhatsApp";
      case ConversationChannel.FACEBOOK:
        return "Facebook Messenger";
      case ConversationChannel.INSTAGRAM:
        return "Instagram";
      case ConversationChannel.ZAPIER_FACEBOOK:
        return "Zapier Facebook";
      default:
        return "";
    }
  };

  const isFullNameValid = (): boolean =>
    (fullNameRequired && !!firstName && !!lastName) ||
    (!fullNameRequired && !!firstName === !!lastName);

  const isPhoneNumberRequired = (): boolean => {
    return (
      (!isPhoneNullable &&
        (checkedChannels.includes("sms") ||
          checkedChannels.includes("whatsapp"))) ||
      !email
    );
  };

  const hasWarnings = warnings && !!Object.keys(warnings).length;
  const hasPhoneWithoutChannel = !checkedChannels.length && !!phoneNumber;
  const isPhoneUnexpectedlyEmpty =
    !checkedChannels.length && !phoneNumber && !isPhoneNullable && !email;
  const isEmailUnexpectedlyEmpty = !email && !isEmailNullable;
  const isPhoneRequiredButEmpty = isPhoneNumberRequired() && !phoneNumber;

  const isDisabled = (): boolean => {
    return (
      isSaving ||
      hasWarnings ||
      modalLoading ||
      hasPhoneWithoutChannel ||
      isPhoneUnexpectedlyEmpty ||
      isPhoneRequiredButEmpty ||
      isEmailUnexpectedlyEmpty ||
      !isFullNameValid()
    );
  };

  const formContent = (
    <>
      <Fieldset.Root>
        <Fieldset.Content>
          <HStack>
            <Field
              label="First name"
              required={fullNameRequired || !!firstName !== !!lastName}
            >
              <Input
                colorPalette={colorScheme}
                borderRadius="63px"
                borderColor={`${colorScheme}.400`}
                onChange={onFirstNameChange}
                value={firstName}
                placeholder="First name"
                id="contact-form-first-name-input"
              />
            </Field>
            <Field
              label="Last name"
              required={fullNameRequired || !!firstName !== !!lastName}
            >
              <Input
                colorPalette={colorScheme}
                borderRadius="63px"
                borderColor={`${colorScheme}.400`}
                onChange={onLastnameChange}
                value={lastName}
                placeholder="Last name"
                id="contact-form-last-name-input"
              />
            </Field>
          </HStack>
          <Field
            label="Phone number"
            required={isPhoneNumberRequired()}
            width="100%"
            css={{
              "& .PhoneInput": {
                width: "100%",
              },
              "& .PhoneInputInput": {
                borderRadius: "63px",
                width: "100%",
                borderColor: `${colorScheme}.400`,
                backgroundColor: isPhoneDisabled ? "lightslategray" : "inherit",
                opacity: isPhoneDisabled ? "0.5" : "1",
              },
            }}
          >
            {warnings && warnings.invalid_phone_number && (
              <WarningTextComponent
                id="phone-input-warning-text"
                isValid={false}
                text={warnings.invalid_phone_number}
              />
            )}

            <PhoneInputWithSuspense
              id="phoneInput"
              placeholder="Enter customer phone number"
              onChange={onPhoneNumChange}
              defaultCountry={merchant.getMerchantLocation() as Country}
              value={phoneNumber}
              autoFocus={false}
              disabled={isPhoneDisabled}
              loadStyles={true}
            />
          </Field>
          <Fieldset.Root>
            <CheckboxGroup
              defaultValue={defaultCheckedChans}
              onValueChange={onCheckChan}
            >
              <Fieldset.Content
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="start"
              >
                {Object.values(ConversationChannel)
                  .filter((val) => !["no_channel", "email"].includes(val))
                  .filter((val) => !defaultHiddenChans?.includes(val))
                  .map((chan) => {
                    return (
                      <Checkbox
                        id={`channels-checkbox-${chan}`}
                        key={chan}
                        size="sm"
                        value={chan}
                        disabled={disabledChans.includes(chan)}
                      >
                        {formatChannelName(chan)}
                      </Checkbox>
                    );
                  })}
              </Fieldset.Content>
            </CheckboxGroup>
          </Fieldset.Root>
          <Field label="Email address" required={!isEmailNullable}>
            {warnings && warnings.invalid_email && (
              <WarningTextComponent
                id="email-input-warning-text"
                isValid={false}
                text={warnings.invalid_email}
              />
            )}
            <Input
              colorPalette={colorScheme}
              id="channels-email-input"
              borderRadius="63px"
              borderColor={`${colorScheme}.400`}
              onChange={onEmailChange}
              value={email}
              placeholder="john@doe.com"
            />
          </Field>
        </Fieldset.Content>
        <Box pt={6}>
          <Link
            color={`${colorScheme}.400`}
            href={`/${merchant.id}/contacts/create?name=${firstName}&last=${lastName}&email=${email}&phone=${phoneNumber}&channels=${checkedChannels}`}
            textDecoration="underline"
            fontSize="sm"
            id="contact-form-full-contact-card-link"
          >
            Click here to open a full contact card with to add additional info
          </Link>
          <Button
            mt={4}
            w="100%"
            onClick={handleSave}
            size="lg"
            disabled={isDisabled()}
            id="contact-form-save-button"
          >
            {modalLoading ? <Spinner /> : "Save"}
          </Button>
        </Box>
      </Fieldset.Root>
    </>
  );

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      size={isBaseSize ? "full" : "md"}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>{modalHeader}</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>{formContent}</DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

export default ContactForm;
