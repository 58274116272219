import {
  Flex,
  Grid,
  GridItem,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import MobileBrowserNavigation from "components/navigation-bar/MobileBrowser";
import ProfileSettings from "components/navigation-bar/ProfileSettings";
import { useColorMode } from "components/ui/color-mode";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { isMobileApp } from "util/methods";

interface DesktopTopbarProps {
  className?: string;
  title: string | ReactNode;
  titleSize?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
  leftChildren: ReactNode;
  rightChildren: ReactNode;
}

const DesktopTopbar = ({
  className = "",
  title,
  titleSize = "3xl",
  leftChildren,
  rightChildren,
}: DesktopTopbarProps) => {
  const { colorMode } = useColorMode();

  return (
    <Grid
      className={className}
      templateColumns="0.5fr 2fr 0.5fr"
      w="100%"
      py={5}
      px={6}
      borderBottomStyle="solid"
      borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
      borderBottomWidth="1px"
    >
      <GridItem>
        <Flex h="100%" alignItems="center">
          {leftChildren}
        </Flex>
      </GridItem>
      <GridItem textAlign="center">
        {title && typeof title === "string" ? (
          <Heading fontSize={titleSize} id="topbar-title">
            {title}
          </Heading>
        ) : (
          title
        )}
      </GridItem>
      <GridItem>
        <Flex justifyContent="flex-end">{rightChildren}</Flex>
      </GridItem>
    </Grid>
  );
};

interface MobileTopbarProps {
  title: string | ReactNode;
  className?: string;
  titleSize?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
  leftChildrenMobile: ReactNode;
  rightChildrenMobile: ReactNode;
  showBorder?: boolean;
  isFlex?: boolean;
}

const MobileTopbar = ({
  className = "",
  title,
  titleSize = "2xl",
  leftChildrenMobile,
  rightChildrenMobile,
  showBorder = true,
  isFlex = false,
}: MobileTopbarProps) => {
  const { colorMode } = useColorMode();

  return (
    <>
      <Grid
        className={className}
        templateColumns="0.5fr 2fr 0.5fr"
        w="100%"
        py="0.75rem"
        px="1.5rem"
        borderBottomStyle="solid"
        borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
        borderBottomWidth={showBorder ? "1px" : 0}
        display={isFlex ? "flex" : "grid"}
        justifyContent={isFlex ? "space-between" : "initial"}
        alignItems={isFlex ? "center" : "initial"}
        gridGap={2}
      >
        <GridItem alignSelf="center">
          {leftChildrenMobile ? (
            <>{leftChildrenMobile} </>
          ) : (
            <ProfileSettings avatarOnly={true} />
          )}
        </GridItem>

        <GridItem alignSelf="center" textAlign="center">
          {title && typeof title === "string" ? (
            <Heading fontSize={titleSize}>{title}</Heading>
          ) : (
            title
          )}
        </GridItem>
        <GridItem alignSelf="center">
          <Flex justifyContent="flex-end">{rightChildrenMobile}</Flex>
        </GridItem>
      </Grid>
    </>
  );
};

interface TopbarProps {
  className?: string;
  title?: string | ReactNode;
  titleSize?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  leftChildrenMobile?: ReactNode;
  rightChildrenMobile?: ReactNode;
  showBorder?: boolean;
  isFlex?: boolean;
}
const Topbar = ({
  rightChildren,
  leftChildren,
  rightChildrenMobile,
  leftChildrenMobile,
  title,
  titleSize,
  className = "",
  showBorder = true,
  isFlex = false,
}: TopbarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const location = useLocation();

  const route =
    location.pathname
      .split("/")
      .filter((part) => !!part && Number.isNaN(parseInt(part, 10)))
      .reverse()[0]
      ?.replace(/[-_]/g, " ") || "";
  const routeName = route.charAt(0).toUpperCase() + route.slice(1);

  const extendedMobileLeftChildren = (
    <Flex alignItems="center" justifyContent="start" gridGap={2}>
      <MobileBrowserNavigation />
      {leftChildrenMobile}
    </Flex>
  );

  return (
    <>
      {isBaseSize ? (
        <MobileTopbar
          className={className}
          title={title || routeName}
          leftChildrenMobile={
            isMobileApp() ? leftChildrenMobile : extendedMobileLeftChildren
          }
          titleSize={titleSize}
          rightChildrenMobile={rightChildrenMobile}
          showBorder={showBorder}
          isFlex={isFlex}
        />
      ) : (
        <DesktopTopbar
          leftChildren={leftChildren}
          rightChildren={rightChildren}
          titleSize={titleSize}
          className={className}
          title={title || routeName}
        />
      )}
    </>
  );
};

export default Topbar;
