export enum ReplySuggestionStatus {
  IN_PROGRESS = "IN_PROGRESS",
  GENERATED = "GENERATED",
  SEEN = "SEEN",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN",
}

export default class ReplySuggestionDomain {
  id: string;

  conversationId: number;

  status: ReplySuggestionStatus;

  replyToMessageId: number | undefined;

  body: string | undefined;

  customFields?: { [key: string]: string }; // doesn't exist in the DTO and shouldn't

  createdAt: string;

  constructor(
    id: string,
    conversationId: number,
    status: ReplySuggestionStatus,
    replyToMessageId: number | undefined,
    body: string | undefined,
    created_at: string,
    customFields?: { [key: string]: string }
  ) {
    this.id = id;
    this.conversationId = conversationId;
    this.status = status;
    this.body = body;
    this.replyToMessageId = replyToMessageId;
    this.createdAt = created_at;
    this.customFields = customFields;
  }

  setCustomFields(customFields: { [key: string]: string }) {
    this.customFields = customFields;
  }
}
