import { defineRecipe } from "@chakra-ui/react";

export default defineRecipe({
  base: {
    color: "blue.400",
    _dark: {
      color: "blue.200",
    },
  }
});
