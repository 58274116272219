import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminKeyloopAccountDomain from "entities/domain/admin/merchants/admin-keyloop-account-domain";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import SelectMerchant from "./select-merchant";

interface KeyloopAccountSelectOption {
  value: string;
  label: string;
}

const UpdateKeyloopAccount = () => {
  const auth0Context = useAuth0();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [cache, setCache] = useState<object>({});
  const [accountName, setAccountName] = useState("");
  const [accounts, setAccounts] = useState<AdminKeyloopAccountDomain[]>([]);
  const [accountOptions, setAccountOptions] = useState<
    KeyloopAccountSelectOption[]
  >([]);
  const [selectedKeyloopAccountValue, setSelectedKeyloopAccountValue] =
    useState<KeyloopAccountSelectOption | null>(null);
  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);
  const [serviceMerchant, setServiceMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const resetCache = () => {
    setCache({});
  };

  const resetValues = () => {
    setSelectedKeyloopAccountValue(null);
    resetCache();
  };

  const updateKeyloopAccount = async () => {
    if (!selectedKeyloopAccountValue) {
      toaster.create({
        type: "error",
        title:
          "Couldn't update keyloop account. Can't find enterprise id and store id",
      });
      return;
    }

    try {
      await AdminService.updateKeyloopAccount(auth0Context, {
        accountName,
        enterpriseId: selectedKeyloopAccountValue.value.split("::")[0],
        storeId: selectedKeyloopAccountValue.value.split("::")[1],
        merchantIds: selectedMerchants.map((m) => m.id),
        serviceMerchantId: serviceMerchant?.id,
      });
      toaster.create({
        type: "success",
        title: "Keyloop account updated successfully!",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't update keyloop account", e);
      toaster.create({
        type: "error",
        title: "Couldn't update keyloop account!",
      });
    } finally {
      resetValues();
    }
  };

  useEffect(() => {
    setAccountOptions(
      accounts.map((a) => ({
        value: `${a.enterpriseId}::${a.storeId}`,
        label: a.accountName || `${a.enterpriseId} - ${a.storeId}`,
      }))
    );
  }, [accounts]);

  useEffect(() => {
    AdminService.fetchKeyloopAccounts(auth0Context).then((newAccounts) => {
      setAccounts(newAccounts);
    });
  }, [cache]);

  useEffect(() => {
    if (selectedKeyloopAccountValue) {
      const account = accounts.find((a) => {
        return (
          `${a.enterpriseId}::${a.storeId}` ===
          selectedKeyloopAccountValue.value
        );
      })!;
      setAccountName(account.accountName || "");
      AdminService.getAllMerchants(
        auth0Context,
        "",
        0,
        account.merchantIds
      ).then((merchants) => {
        setSelectedMerchants(merchants);
        setServiceMerchant(
          merchants.find((m) => m.id === account.serviceMerchantId) || null
        );
      });
    } else {
      setAccountName("");
      setSelectedMerchants([]);
    }
  }, [selectedKeyloopAccountValue]);

  return (
    <Fieldset.Root>
      <Fieldset.Content>
        <Field label="Choose Account" required>
          <Select
            placeholder="Select Account"
            onChange={(v) => setSelectedKeyloopAccountValue(v || null)}
            options={accountOptions}
            value={selectedKeyloopAccountValue}
            styles={{
              ...getReactSelectStyles(colorMode || "light", colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: "100%",
              }),
            }}
            onMenuOpen={() => {
              resetCache();
            }}
          />
        </Field>
        <Field label="Account Name" required>
          <Input
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            disabled={!selectedKeyloopAccountValue}
          />
        </Field>
        <Field label="Choose Merchants">
          <SelectMerchant
            isDisabled={!selectedKeyloopAccountValue}
            isMulti={true}
            selectedMerchants={selectedMerchants}
            setSelectedMerchants={setSelectedMerchants}
          />
        </Field>
        <Field label="Choose Service Merchant">
          <SelectMerchant
            selectedMerchant={serviceMerchant}
            setSelectedMerchant={setServiceMerchant}
            filterCallback={(id) =>
              selectedMerchants.map((m) => m.id).includes(id)
            }
            isDisabled={!selectedMerchants.length}
            isClearable={true}
          />
        </Field>
      </Fieldset.Content>
      <Button
        colorPalette={colorScheme}
        onClick={updateKeyloopAccount}
        disabled={!accountName || !selectedKeyloopAccountValue}
        mt={8}
        mb={8}
        size="lg"
      >
        Update Account
      </Button>
    </Fieldset.Root>
  );
};

export default UpdateKeyloopAccount;
