import { Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";

interface IntegrationDescriptionProps {
  text: string | React.ReactNode;
}

const IntegrationDescription = ({ text }: IntegrationDescriptionProps) => {
  const { colorMode } = useColorMode();

  return typeof text === "string" ? (
    <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>{text}</Text>
  ) : (
    text
  );
};

export default IntegrationDescription;
