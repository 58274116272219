import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface MessagePreviewSnippetProps {
  newMessage?: string | React.ReactNode;
  AutoSuggestionReply?: string;
}

const MessagePreviewSnippet = ({
  newMessage,
  AutoSuggestionReply,
}: MessagePreviewSnippetProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Flex
        bgColor={`${colorScheme}.50`}
        my={4}
        w="100%"
        mx="auto"
        p={4}
        flexDirection="column"
        borderRadius="md"
      >
        <Box
          bgColor="white"
          color={`${colorScheme}.900`}
          borderRadius="md"
          p={4}
          maxW="50%"
        >
          <Text
            fontWeight={400}
            fontSize="sm"
            as="pre"
            fontFamily="inherit"
            whiteSpace="pre-wrap"
          >
            {newMessage}
          </Text>
        </Box>

        {AutoSuggestionReply && (
          <Flex
            w="100%"
            justifyContent="flex-end"
            data-state={AutoSuggestionReply ? "open" : "closed"}
            _open={{
              animationName: "fade-in, scale-in",
              animationDuration: "300ms",
            }}
            _closed={{
              animationName: "fade-out, scale-out",
              animationDuration: "120ms",
            }}
          >
            <Box
              bgColor="#E8EAFF"
              color={`${colorScheme}.900`}
              borderRadius="md"
              p={4}
              maxW="50%"
            >
              <Text
                fontWeight={400}
                fontSize="sm"
                as="pre"
                fontFamily="inherit"
                whiteSpace="pre-wrap"
              >
                {AutoSuggestionReply}
              </Text>
            </Box>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default MessagePreviewSnippet;
