import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AccordionItemContent,
  AccordionItemTrigger,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import ContactDomain from "entities/domain/customers/contact-domain";
import VehicleDomain from "entities/domain/vehicle";
import React, { useEffect, useState } from "react";
import { LuCar } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import Gallery from "../Gallery";
import CarThumbnailContent from "./CarThumbnailContent";
import VehicleInfo from "./VehicleInfo";

interface VehicleInfoProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
  onFirstLoad: (vehicles: VehicleDomain[]) => void;
}

const VehicleInfoArea = ({
  contactToDisplay,
  isExpanded,
  onFirstLoad,
}: VehicleInfoProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [startIndex, setStartIndex] = useState<number>(0);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const { open, onOpen, onClose } = useDisclosure();

  const [vehicles, setVehicles] = useState<VehicleDomain[]>([]);

  const fetchVehicles = async () => {
    const newVehicles = await ContactsService.getVehicles(
      auth0Context,
      contactToDisplay.id!,
      merchant.groupId
    );

    setVehicles(newVehicles);
    onFirstLoad(newVehicles);
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <>
      <AccordionItemTrigger>
        <HStack>
          <Icon
            as={LuCar}
            width="1.25rem"
            height="1.25rem"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
          />
          <Text fontWeight="bold">Vehicle Info</Text>
        </HStack>
      </AccordionItemTrigger>
      <AccordionItemContent>
        <Flex
          justifyContent="start"
          alignItems="start"
          gridGap={3}
          flexWrap="wrap"
        >
          {vehicles.map((vehicle, index) => (
            <Box
              borderWidth="1px"
              borderRadius="full"
              borderStyle="solid"
              borderColor={colorMode === "dark" ? "gray.200" : "gray.50"}
              px={2}
              py={1}
              key={vehicle.id}
              _hover={{
                cursor: "pointer",
                bgColor: colorMode === "dark" ? "gray.900" : "gray.50",
              }}
              onClick={() => {
                onOpen();
                setStartIndex(index);
              }}
            >
              <CarThumbnailContent vehicle={vehicle} />
            </Box>
          ))}
        </Flex>
      </AccordionItemContent>
      <Gallery
        isOpen={open}
        onClose={onClose}
        navigationOrientation={isBaseSize ? "horizontal" : "vertical"}
        startIndex={startIndex}
        setStartIndex={(index) => setStartIndex(index)}
        media={vehicles.map((vehicle) => ({
          id: vehicle.id,
          element: <VehicleInfo vehicle={vehicle} />,
          smallElement: (
            <Box
              borderWidth="1px"
              borderRadius="2xl"
              borderStyle="solid"
              borderColor={colorMode === "dark" ? "gray.700" : "gray.50"}
              p={4}
              height="6rem"
              width="6rem"
              color="white"
              key={vehicle.id}
              bgColor={
                startIndex === vehicles.indexOf(vehicle)
                  ? "gray.700"
                  : "transparent"
              }
              _hover={{
                cursor: "pointer",
                bgColor: colorMode === "dark" ? "gray.700" : "gray.800",
              }}
            >
              <CarThumbnailContent
                vehicle={vehicle}
                colorMode="dark"
                showOpenIcon={false}
                orientation="vertical"
              />
            </Box>
          ),
        }))}
      />
    </>
  );
};

export default VehicleInfoArea;
