import { Box, Flex, Icon, useBreakpointValue } from "@chakra-ui/react";
import { ReactComponent as FuzeyIcon } from "assets/icons/fuzey-target.svg";
import { motion } from "framer-motion";
import React from "react";
import { LuSmartphone } from "react-icons/lu";
import { ReactSVG } from "react-svg";

interface MessageAnimationProps {
  animation: string;
}

const MessageAnimation = ({ animation }: MessageAnimationProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      justifyContent="space-between"
      position="relative"
      w={isBaseSize ? "100%" : "75%"}
      mx="auto"
      alignItems="center"
    >
      <Icon as={LuSmartphone} boxSize={12} color="#090C9B" />

      <Box
        as={motion.div}
        animation={animation}
        display="flex"
        position="absolute"
        left="10%"
        height="12"
      >
        <Icon
          asChild={true}
          animation={animation}
          boxSize={8}
          css={{
            "& svg": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <ReactSVG src="/email.svg" />
        </Icon>
      </Box>

      <Icon as={FuzeyIcon} boxSize={14} />
    </Flex>
  );
};

export default MessageAnimation;
