import {
  Box,
  Separator,
  Text,
} from "@chakra-ui/react";
import EditorUltra from "components/editor-ultra";
import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import AutomationDomain from "entities/domain/automation";
import TemplateDomain, { CustomFields } from "entities/domain/templates";
import FullTemplateDomain from "entities/domain/templates/full_template";
import useTemplatesStore from "hooks/use-templates-store";
import { LexicalEditor } from "lexical";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useStateWithCallback } from "util/methods";

interface ExecuteProps {
  automation: AutomationDomain;
  setUpdatedAutomation: (automation: AutomationDomain) => void;
}

const Execute = ({
  automation,
  setUpdatedAutomation: _setUpdatedAutomation,
}: ExecuteProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const customFields: CustomFields = {
    contact_first_name: "Contact First Name",
    contact_last_name: "Contact Last Name",
    merchant_name: "Merchant Name",
    review_link: "Review Link",
    email_address: "Email Address",
    phone_number: "Phone Number",
    agent_first_name: "Agent First Name",
    agent_last_name: "Agent Last Name",
    address: "Address",
  };

  const draftTemplate = Object.setPrototypeOf(
    {
      customFields,
      name: "",
      text: "",
      id: "",
      title: "",
      shortcut: null,
      subject: "",
      channels: ["sms"],
      favourite: false,
      mediaType: null,
      mediaUrl: null,
    },
    TemplateDomain.prototype
  );

  const [currentTemplate, setCurrentTemplate] =
    useStateWithCallback<TemplateDomain>(draftTemplate);
  const [templateNotFound, setTemplateNotFound] = useState(false);
  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: currentTemplate.text,
  });

  const editorReference = useRef<LexicalEditor | undefined>();

  const { fetchTemplates } = useTemplatesStore();
  const { templates } = useAppSelector((state) => state.templates);

  useEffect(() => {
    fetchTemplates(undefined, true);
  }, []);

  useEffect(() => {
    if (!templates.length || !automation.templateId) {
      setCurrentTemplate(draftTemplate);
      return;
    }

    const foundTemplate = templates?.find(
      (t) => t.name === automation.templateId
    );

    if (foundTemplate instanceof FullTemplateDomain) {
      setCurrentTemplate(
        Object.setPrototypeOf(
          {
            customFields,
            name: foundTemplate.name,
            text: foundTemplate.text,
            id: foundTemplate.id,
            title: foundTemplate.title,
            shortcut: foundTemplate.shortcut,
            channels: foundTemplate.channels,
            subject: foundTemplate.subject,
            favourite: foundTemplate.favourite,
            mediaType: foundTemplate.mediaType,
            mediaUrl: foundTemplate.mediaUrl,
          },
          TemplateDomain.prototype
        )
      );
      setEditorText({
        value: foundTemplate.text,
      });
    } else {
      setTemplateNotFound(true);
    }
  }, [templates.length, automation.templateId]);

  return (
    <>
      <Box px={6} my={5}>
        <Text fontWeight={700} pb="1rem">
          Send this message:
        </Text>
        {currentTemplate instanceof FullTemplateDomain ? (
          <Text pb="1rem">
            To change the text of the template please press{" "}
            <a
              href={`/${merchant.id}/settings/templates/${automation.templateId}`}
            >
              <Text color={`${colorScheme}.400`}>here</Text>
            </a>
          </Text>
        ) : null}
        {templateNotFound ? (
          <Alert status="error" borderRadius="xl" title="There is no associated template with this automation. Please, contact Fuzey support!" />
        ) : (
          <Box
            bgColor={colorMode === "dark" ? "gray.700" : "white"}
            borderRadius="1rem"
            borderWidth="1px"
            borderColor={colorMode === "dark" ? "gray.900" : "gray.50"}
            py="1rem"
            px="1.5rem"
            h="125px"
            fontSize="14px"
            overflowY="auto"
          >
            <EditorUltra
              isEditable={false}
              highlightUnknownCustomFields={false}
              defaultText={editorText}
              editorReference={editorReference}
              customFields={customFields}
            />
          </Box>
        )}
      </Box>
      <Separator />
    </>
  );
};

export default Execute;
