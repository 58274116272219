import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Text } from "@chakra-ui/react";
import SmsMaxLength from "components/chat/shared/messages-area/typing-zone/channels/sms/SmsMaxLength";
import EditorUltra from "components/editor-ultra";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import MarketingPrefix from "components/user-settings/shared/MarketingPrefix";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import { UnsubscribeSuffix } from "components/user-settings/shared/UnsubscribeSuffix";
import { CampaignStatus } from "entities/domain/campaign";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import FileDomain from "entities/domain/file";
import FullTemplateDomain from "entities/domain/templates/full_template";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { SegmentedMessage } from "sms-segments-calculator";
import TestMessageSender from "../TestMessageSender";
import CampaignIdeas from "./CampaignIdeas";
import ChooseTemplate from "./ChooseTemplate";
import MessageInputCoWriter from "./MessageInputCoWriter";
import MessageInputOverlay from "./MessageInputOverlay";
import MessageInputToolbar from "./MessageInputToolbar";

interface SmsMessageTabPanelProps {
  message: string;
  isIncludingSuffix: boolean;
  templateName: string | null;
  campaignStatus: CampaignStatus;
  customFields: { [key: string]: string };
  campaignMediaType: string | null;
  campaignMediaUrl: string | null;
  prefix: string;
  setPrefix: (newPrefix: string) => void;
  setCampaignTemplateName: (tid: string | null) => void;
  setIsIncludingSuffix: (isIncluding: boolean) => void;
  setMessage: (newMessage: string) => void;
  attachedFile: FileDomain | null;
  onAttachmentRemove: () => void;
  setAttachedFile: (file: FileDomain | null) => void;
}

const IS_AI_TEMPLATE_SUGGESTION_ENABLED = false;

const SmsMessageTabPanel = ({
  message,
  isIncludingSuffix,
  customFields,
  campaignStatus,
  campaignMediaType,
  campaignMediaUrl,
  attachedFile,
  templateName,
  prefix,
  setPrefix,
  onAttachmentRemove,
  setAttachedFile,
  setCampaignTemplateName,
  setMessage,
  setIsIncludingSuffix,
}: SmsMessageTabPanelProps) => {
  const { colorMode } = useColorMode();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { templates } = useAppSelector((state) => state.templates);
  const auth0Context = useAuth0();
  const channelForEditor = useMemo(() => [ConversationChannel.SMS], []);
  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: templateName
      ? templates.find((t) => t.name === templateName)?.getWholeText() || ""
      : message || "",
  });
  const [isSendingTestMessage, setIsSendingTestMessage] =
    useState<boolean>(false);
  const [isAskingSuggestion, setIsAskingSuggestion] = useState<boolean>(false);
  const [shouldShowStartAlert, setShouldShowStartAlert] =
    useState<boolean>(false);
  const [messagesCount, setMessagesCount] = useState<number>(0);
  const [chosenTemplate, setChosenTemplate] =
    useState<FullTemplateDomain | null>(
      (templates.find((t) => t.name === templateName) as FullTemplateDomain) ||
        null
    );

  useEffect(() => {
    if (message || shouldShowStartAlert) {
      setShouldShowStartAlert(false);
    } else if (IS_AI_TEMPLATE_SUGGESTION_ENABLED) {
      setShouldShowStartAlert(true);
    }
  }, [message]);

  useEffect(() => {
    const newMessagesCount = 0;

    if (!message && !chosenTemplate) {
      setMessagesCount(newMessagesCount);
      return;
    }

    if (message) {
      const segmentedMessage = new SegmentedMessage(message, "auto", true);

      setMessagesCount(segmentedMessage.segmentsCount);
      return;
    }

    if (chosenTemplate) {
      const segmentedMessage = new SegmentedMessage(
        chosenTemplate.text,
        "auto",
        true
      );

      setMessagesCount(segmentedMessage.segmentsCount);
      return;
    }
  }, [message, chosenTemplate]);

  const editorReference = useRef<LexicalEditor | undefined>();

  const askAiForHelp = useCallback(async (aiPrompt: string) => {
    setIsAskingSuggestion(true);

    let response: { suggestion: string } | null = null;

    try {
      response = await InboxService.getSuggestedCampaign(
        auth0Context,
        merchant.id,
        aiPrompt,
        ConversationChannel.SMS
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Could not get AI suggestion for campaigns", e);
    } finally {
      setIsAskingSuggestion(false);
    }

    if (response === null) {
      return;
    }

    setMessage(response!.suggestion);
    setEditorText({
      value: response!.suggestion,
    });
  }, []);

  const insertEmoji = useCallback(
    ({ native }: { native: string }) => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        selection.insertText(` ${native} `);

        setMessage($getRoot().getTextContent());
      });

      editorReference.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    },
    [editorReference]
  );

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        selection.insertText(` ${someText} `);

        setMessage($getRoot().getTextContent());
      });

      editorReference.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    },
    [editorReference]
  );

  const addAttachment = useCallback(async (file: FileDomain) => {
    setAttachedFile(file);
  }, []);

  const onSendTestMessage = useCallback(
    async (messageBody: string, handle: string) => {
      setIsSendingTestMessage(true);

      try {
        await InboxService.sendTestMessage(
          auth0Context,
          {
            channel: "sms",
            handle,
            messageBody,
          },
          merchant.id
        );

        toaster.create({
          type: "success",
          title: `Test message has been sent to ${handle}`,
        });
      } catch (e) {
        toaster.create({
          type: "error",
          title: `Could not send a test message to ${handle}`,
        });
      }
      setIsSendingTestMessage(false);
    },
    []
  );

  return (
    <>
      <CampaignIdeas
        campaignStatus={campaignStatus}
        isShown={shouldShowStartAlert}
        useIdea={(idea: string) => {
          setMessage(idea);
          setEditorText({
            value: idea,
          });
          setShouldShowStartAlert(false);
        }}
      />
      <ChooseTemplate
        addEmptyOption={true}
        onTemplateSelect={(
          newSelectedTemplate: RegularOrWhatsappTemplate | null
        ) => {
          if (newSelectedTemplate instanceof WhatsappTemplate) {
            return;
          }

          setMessage("");
          setCampaignTemplateName(newSelectedTemplate?.name || null);
          setChosenTemplate(newSelectedTemplate);
          setEditorText({
            value: newSelectedTemplate?.text || "",
          });
          setAttachedFile(null);
        }}
        channel={ConversationChannel.SMS}
        selectedTemplateId={
          templates.find((t) => t.name === templateName)?.id || null
        }
      />
      <Box
        mt={8}
        position="relative"
        background={
          templateName && chosenTemplate
            ? colorMode === "dark"
              ? "black"
              : "gray.50"
            : "inherit"
        }
        id="message-box"
        borderRadius="lg"
        borderStyle="solid"
        borderWidth="2px"
        borderColor={
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
        }
        maxWidth="100%"
        p="1rem"
        px="1.5rem"
      >
        {templateName && chosenTemplate ? (
          <EditorUltra
            isEditable={false}
            highlightUnknownCustomFields={false}
            defaultText={editorText}
            customFields={chosenTemplate?.customFields}
            placeholder="No template selected"
          />
        ) : (
          <>
            <MessageInputOverlay isShown={isAskingSuggestion} />
            <MarketingPrefix
              prefix={prefix}
              setPrefix={setPrefix}
              isDisabled={campaignStatus === CampaignStatus.DONE}
            />
            <EditorUltra
              isDisabled={campaignStatus === CampaignStatus.DONE}
              isEditable={campaignStatus !== CampaignStatus.DONE}
              defaultText={editorText}
              placeholder="Write your campaign message here..."
              editorReference={editorReference}
              setText={setMessage}
              channels={channelForEditor}
              customFields={customFields}
            />
            <SmsMaxLength
              text={message}
              setIsApproachingLimit={() => {}}
              setIsLimitExceeded={() => {}}
            />
            <UnsubscribeSuffix
              isIncluded={isIncludingSuffix}
              setIsIncluded={setIsIncludingSuffix}
              isDisabled={campaignStatus === CampaignStatus.DONE}
            />
          </>
        )}
        <TemplateAttachment
          mediaType={
            attachedFile
              ? attachedFile.type
              : templateName
              ? chosenTemplate?.mediaType || null
              : campaignMediaType || null
          }
          mediaUrl={
            attachedFile
              ? attachedFile.url
              : templateName
              ? chosenTemplate?.mediaUrl || null
              : campaignMediaUrl || null
          }
          onAttachmentRemove={
            templateName
              ? undefined
              : () => {
                  setAttachedFile(null);
                  onAttachmentRemove();
                }
          }
        />
        {campaignStatus !== CampaignStatus.DONE &&
          !templateName &&
          !chosenTemplate && (
            <MessageInputToolbar
              insertEmoji={insertEmoji}
              insertIntoText={insertIntoText}
              addAttachment={addAttachment}
            />
          )}
      </Box>
      {messagesCount > 1 && (
        <Flex
          p={4}
          color={colorMode === "dark" ? "red.200" : "red.500"}
          direction="column"
          alignItems="start"
        >
          <Text textAlign="right" maxWidth="70%">
            {`This message will count as ${messagesCount} SMS messages`}
          </Text>
        </Flex>
      )}
      {campaignStatus !== CampaignStatus.DONE &&
        !templateName &&
        !chosenTemplate && (
          <Field mt={6}>
            <MessageInputCoWriter
              motive="campaign"
              askAiForHelp={askAiForHelp}
              isDisabled={isAskingSuggestion || isSendingTestMessage}
            />
          </Field>
        )}
      {campaignStatus !== CampaignStatus.DONE && (
        <Field label="Send test message" mt={6} pb={6}>
          <TestMessageSender
            campaignMessage={message}
            prefix={prefix}
            campaignTemplateName={chosenTemplate?.name || null}
            onSendTestMessage={onSendTestMessage}
            isSendingTestMessage={isSendingTestMessage}
          />
        </Field>
      )}
    </>
  );
};

export default SmsMessageTabPanel;
