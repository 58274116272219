import {
  AbsoluteCenter,
  Box,
  Icon,
  ProgressCircle,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import chroma from "chroma-js";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { useColorMode } from "components/ui/color-mode";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { CampaignCategory } from "redux/features/campaigns";
import { useAppSelector } from "redux/hooks";
import { getChannelIcon } from "util/constants";

interface InfinityListProps {
  campaigns: CampaignDomain[];
  hasNextPage: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  fetchMoreCampaigns: () => Promise<void>;
  handleCampaignDelete: (c: CampaignDomain) => Promise<void>;
  handleCampaignPause: (c: CampaignDomain) => Promise<void>;
  handleCampaignResume: (c: CampaignDomain) => Promise<void>;
  handleCampaignCancel: (c: CampaignDomain) => Promise<void>;
}

const CampaignNameColumn = ({ item }: { item: CampaignDomain }) => (
  <Text>{item.name}</Text>
);

const RecipientsCountColumn = ({ item }: { item: CampaignDomain }) => {
  const total = item.analytics?.totalDelivered;

  if (
    item.status === CampaignStatus.DRAFT ||
    item.status === CampaignStatus.PENDING
  ) {
    return null;
  }

  return <Text>{total}</Text>;
};

const MessagesCountColumn = ({ item }: { item: CampaignDomain }) => {
  const total = item.analytics?.totalSegments;

  if (
    item.status === CampaignStatus.DRAFT ||
    item.status === CampaignStatus.PENDING
  ) {
    return null;
  }

  return <Text>{total}</Text>;
};

const ChannelColumn = ({ item }: { item: CampaignDomain }) => {
  return (
    <Icon
      asChild={true}
      css={{
        height: "1.5rem",
        width: "1.5rem",
      }}
    >
      <ReactSVG src={getChannelIcon(item.channel)} />
    </Icon>
  );
};

const LastEditedColumn = ({ item }: { item: CampaignDomain }) => {
  const { colorMode } = useColorMode();
  const [orange400, gray400, green400, purple400] = useToken("colors", [
    "orange.400",
    "gray.400",
    "green.400",
    "purple.400",
  ]);
  const getHexColorFromToken = (token: string): string => {
    switch (token) {
      case "orange.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(orange400)).brighten(1).hex()
          : orange400;
      case "gray.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(gray400)).brighten(1).hex()
          : gray400;
      case "green.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(green400)).brighten(1).hex()
          : green400;
      case "purple.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(purple400)).brighten(1).hex()
          : purple400;
      default:
        return token;
    }
  };

  const campaignDescription = CampaignDomain.getDescriptionForStatus(item);
  const textColor = getHexColorFromToken(campaignDescription.color);

  return (
    <Text color={textColor}>
      {campaignDescription.description}
      {item.status === CampaignStatus.PENDING && !item.progress && (
        <ProgressCircle.Root value={null} size="xl" ml={2}>
          <ProgressCircle.Circle>
            <ProgressCircle.Track />
            <ProgressCircle.Range strokeLinecap="round" />
          </ProgressCircle.Circle>
        </ProgressCircle.Root>
      )}
      {item.status === CampaignStatus.PENDING && item.progress && (
        <ProgressCircle.Root
          value={item.progress || 0}
          size="xl"
          max={100}
          min={0}
          ml={2}
        >
          <ProgressCircle.Circle>
            <ProgressCircle.Track />
            <ProgressCircle.Range strokeLinecap="round" />
          </ProgressCircle.Circle>
          <AbsoluteCenter>
            <ProgressCircle.ValueText />
          </AbsoluteCenter>
        </ProgressCircle.Root>
      )}
    </Text>
  );
};

const getReplyRate = (
  c: CampaignDomain,
  colorMode: "light" | "dark"
): {
  color: string;
  description: string;
  value: number;
} => {
  const replyRateValue = CampaignDomain.getReplyRateNumber(c);

  let color = "green";

  if (replyRateValue < 5) {
    color = "red";
  } else if (replyRateValue < 30) {
    color = "yellow";
  }

  return {
    color,
    description: CampaignDomain.getReplyRate(c),
    value: replyRateValue,
  };
};

const getOpenRate = (
  c: CampaignDomain,
  colorMode: "light" | "dark"
): {
  color: string;
  description: string;
  value: number;
} => {
  // open rate = total read / total delivered
  const openRateValue = CampaignDomain.getOpenRateNumber(c);

  let color = "green";

  if (openRateValue < 5) {
    color = "red";
  } else if (openRateValue < 30) {
    color = "yellow";
  }

  return {
    color,
    description: CampaignDomain.getOpenRate(c),
    value: openRateValue,
  };
};

const ReplyRateColumn = ({ item }: { item: CampaignDomain }) => {
  const { colorMode } = useColorMode();
  const replyRate = getReplyRate(
    item,
    (colorMode as "light" | "dark") || "light"
  );

  if (
    item.status === CampaignStatus.DRAFT ||
    item.status === CampaignStatus.PENDING
  ) {
    return null;
  }

  return (
    <Text>
      <ProgressCircle.Root
        value={replyRate.value}
        colorPalette={replyRate.color}
        size="xl"
      >
        <ProgressCircle.Circle>
          <ProgressCircle.Track />
          <ProgressCircle.Range strokeLinecap="round" />
        </ProgressCircle.Circle>
        <AbsoluteCenter>
          <ProgressCircle.ValueText />
        </AbsoluteCenter>
      </ProgressCircle.Root>
    </Text>
  );
};

const OpenRateColumn = ({ item }: { item: CampaignDomain }) => {
  const { colorMode } = useColorMode();

  if (
    item.channel !== ConversationChannel.WHATSAPP ||
    item.status === CampaignStatus.DRAFT ||
    item.status === CampaignStatus.PENDING
  ) {
    return null;
  }

  const openRate = getOpenRate(
    item,
    (colorMode as "light" | "dark") || "light"
  );

  return (
    <Text>
      <ProgressCircle.Root
        value={openRate.value}
        colorPalette={openRate.color}
        size="xl"
        ml={2}
      >
        <ProgressCircle.Circle>
          <ProgressCircle.Track />
          <ProgressCircle.Range strokeLinecap="round" />
        </ProgressCircle.Circle>
        <AbsoluteCenter>
          <ProgressCircle.ValueText />
        </AbsoluteCenter>
      </ProgressCircle.Root>
    </Text>
  );
};

interface Action {
  name: string;
  isDisabled: boolean;
  callback: () => void;
}

const InfinityList = ({
  campaigns,
  hasNextPage,
  scrollContainerRef,
  fetchMoreCampaigns,
  handleCampaignDelete,
  handleCampaignPause,
  handleCampaignResume,
  handleCampaignCancel,
}: InfinityListProps) => {
  const navigate = useNavigate();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeCampaignCategory } = useAppSelector((state) => state.campaigns);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);

  const loadMoreItems = isNextPageLoading
    ? () => Promise.resolve()
    : async () => {
        setIsNextPageLoading(true);
        await fetchMoreCampaigns();
        setIsNextPageLoading(false);
      };

  const getIndividualActions = (
    item: CampaignDomain
  ): SmartListIndividualAction<CampaignDomain>[] | undefined => {
    if (!item) {
      return;
    }

    const getCampaignActions = (
      campaignStatus: CampaignStatus
    ): SmartListIndividualAction<CampaignDomain>[] => {
      switch (campaignStatus) {
        case CampaignStatus.DONE:
          return [
            {
              label: "Details",
              execute: () => {
                navigate(`/${merchant.id}/campaigns/details/${item.id}`);
              },
            },
          ];
        case CampaignStatus.DRAFT:
          return [
            {
              label: "Edit",
              execute: () => {
                navigate(`/${merchant.id}/campaigns/edit/${item.id}`);
              },
            },
            {
              label: "Delete",
              execute: () => {
                handleCampaignDelete(item);
              },
              shouldShowConfirmation: true,
              confirmationText:
                "Are you sure you want to delete this campaign?",
            },
          ];
        case CampaignStatus.PENDING:
          return [
            {
              label: "Pause",
              execute: () => {
                handleCampaignPause(item);
              },
              shouldShowConfirmation: true,
              confirmationText: "Are you sure you want to pause this campaign?",
            },
            {
              label: "Cancel",
              execute: () => {
                handleCampaignCancel(item);
              },
              shouldShowConfirmation: true,
              confirmationText:
                "Are you sure you want to cancel this campaign?",
            },
          ];
        case CampaignStatus.PAUSED:
          return [
            {
              label: "Resume",
              execute: () => {
                handleCampaignResume(item);
              },
              shouldShowConfirmation: true,
              confirmationText:
                "Are you sure you want to resume this campaign?",
            },
            {
              label: "Cancel",
              execute: () => {
                handleCampaignCancel(item);
              },
              shouldShowConfirmation: true,
              confirmationText:
                "Are you sure you want to cancel this campaign?",
            },
          ];
        default:
          return [];
      }
    };

    return getCampaignActions(item.status);
  };

  return campaigns.length === 0 && !isNextPageLoading ? null : (
    <Box height="100%" w="100%" mx="auto">
      <SmartList
        items={campaigns}
        itemIdentifier="id"
        canFetchMore={true}
        hasNextPage={hasNextPage}
        fetchMore={loadMoreItems}
        containerRef={scrollContainerRef}
        initialScroll={false}
        columns={[
          {
            label: "Name",
            component: CampaignNameColumn,
          },
          ...(activeCampaignCategory === CampaignCategory.Done
            ? [
                {
                  label: "Reply Rate",
                  component: ReplyRateColumn,
                },
                ...(merchant.isMerchantChannelEnabled(
                  ConversationChannel.WHATSAPP
                )
                  ? [
                      {
                        label: "Open Rate",
                        component: OpenRateColumn,
                      },
                    ]
                  : []),
                ...(isBaseSize
                  ? []
                  : [
                      {
                        label: "Recipients Total",
                        component: RecipientsCountColumn,
                      },
                      {
                        label: "Messages Total",
                        component: MessagesCountColumn,
                      },
                      {
                        label: "Channel",
                        component: ChannelColumn,
                      },
                      {
                        label: "Completed At",
                        component: LastEditedColumn,
                      },
                    ]),
              ]
            : [
                ...(isBaseSize
                  ? []
                  : [
                      {
                        label: "Channel",
                        component: ChannelColumn,
                      },
                      {
                        label: "Last edited",
                        component: LastEditedColumn,
                      },
                    ]),
              ]),
        ]}
        getIndividualActions={getIndividualActions}
      />
    </Box>
  );
};

export default InfinityList;
