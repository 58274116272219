import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import PaymentDomain, {
  PaymentMethod,
  PaymentStatus,
} from "entities/domain/payments/payment-domain";
import usePaymentsStore from "hooks/use-payments-store";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { RiBankLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { canSendPayments, canViewPayments } from "util/permissions";

import ProfileAvatar from "components/profile/profile-avatar";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogRoot,
} from "components/ui/dialog";
import { InputGroup } from "components/ui/input-group";
import { toaster } from "components/ui/toaster";
import useDebounce from "hooks/use-debounce";
import { LuSearch } from "react-icons/lu";
import {
  resetLoadingState,
  selectPayment,
  setSelectedPayment,
} from "redux/features/payments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SkeletonOverlay from "./SkeletonOverlay";
import CancelPaymentModal from "./payment-actions-modal/cancelPayment";
import EditPaymentModal from "./payment-actions-modal/editPayment";
import PaymentMobileDetails from "./payment-mobile-details";

export const getPaymentMethodIcon = (
  method: string,
  colorScheme: string
): ReactNode | undefined => {
  switch (method) {
    case PaymentMethod.CARD_PAYMENT:
      return (
        <Icon
          asChild={true}
          css={{
            width: "1.5em",
            height: "1.5em",
            "& path": {
              fill: `${colorScheme}.400`,
            },
          }}
        >
          <ReactSVG src="/credit-card-ds.svg" />
        </Icon>
      );
    case PaymentMethod.BANK_TRANSFER:
      return (
        <Icon
          css={{
            width: "1.5em",
            height: "1.5em",
            color: `${colorScheme}.400`,
          }}
          className="styled-bank-icon "
        >
          <RiBankLine />
        </Icon>
      );
    case PaymentMethod.CASH:
      return (
        <Icon
          asChild={true}
          css={{
            width: "1.5em",
            height: "1.5em",
            "& path": {
              fill: `${colorScheme}.400`,
            },
          }}
        >
          <ReactSVG src="/money.svg" />
        </Icon>
      );
    case PaymentMethod.CHECK:
      return (
        <Icon
          asChild={true}
          css={{
            width: "1.5em",
            height: "1.5em",
            "& path": {
              fill: `${colorScheme}.400`,
            },
          }}
        >
          <ReactSVG src="/edit.svg" />
        </Icon>
      );
    default:
      return undefined;
  }
};

const CustomerColumn = ({ item }: { item: PaymentDomain }) => {
  const { displayPaymentGroup } = usePaymentsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  return (
    <Flex alignItems="center" gridGap={4}>
      {isBaseSize ? null : (
        <ProfileAvatar
          profilePicture={item.getPicture()}
          name={item.customerName}
          showInitials={false}
        />
      )}
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        <Text>{item.customerName}</Text>
        <Text
          textTransform="capitalize"
          color={`${item.getStatusColor()}.${colorMode === "dark" ? 200 : 500}`}
        >
          {displayPaymentGroup(item)}
        </Text>
      </Flex>
    </Flex>
  );
};

const AmountColumn = ({ item }: { item: PaymentDomain }) => {
  return <Text>{item.getDisplayAmt()}</Text>;
};

const CreatedDateColumn = ({ item }: { item: PaymentDomain }) => {
  return <Text>{item.getDisplayCreatedDate()}</Text>;
};

const MethodColumn = ({ item }: { item: PaymentDomain }) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return <Text>{getPaymentMethodIcon(item.method, colorScheme)}</Text>;
};

const DescriptionColumn = ({ item }: { item: PaymentDomain }) => {
  return <Text>{item.description}</Text>;
};

const PaymentsPage = () => {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { fetchPayments, editPayment } = usePaymentsStore();
  const {
    errors,
    loading: isLoadingPayments,
    payments,
    selectedPaymentId,
  } = useAppSelector((state) => state.payments);
  const selectedPayment = selectPayment(selectedPaymentId, payments);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  useEffect(() => {
    return () => {
      dispatch(resetLoadingState());
    };
  }, []);
  const debouncedIsLoadingPayments = useDebounce(isLoadingPayments, 50);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const { merchant, loading: isLoadingMerchant } = useAppSelector(
    (state) => state.merchant
  );

  const [loading, setLoading] = useState(false);
  const [currentPayments, setCurrentPayments] =
    useState<PaymentDomain[]>(payments);
  const [cancelPaymentOpen, setCancelPaymentOpen] = useState<boolean>(false);
  const [editPaymentOpen, setEditPaymentOpen] = useState<boolean>(false);
  const [mobileDetailsOpen, setMobileDetailsOpen] = useState<boolean>(false);

  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce<string>(searchText, 500);

  useEffect(() => {
    setCurrentPayments(payments);
  }, [payments]);

  useEffect(() => {
    if (!payments.length) {
      return;
    }

    const filteredPayments = payments.filter((payment) => {
      if (payment.customerName === null || payment.customerName === undefined) {
        return false;
      }

      return payment.customerName
        .toLowerCase()
        .includes(debouncedSearchText.toLowerCase());
    });

    setCurrentPayments(filteredPayments);
  }, [debouncedSearchText]);

  const cancelPayment = () => {
    editPayment({
      id: selectedPayment?.id,
      status: PaymentStatus.CANCELED,
      payment_method: undefined,
    });
    setCancelPaymentOpen(false);
  };

  const getIndividualActions = (
    payment: PaymentDomain
  ): SmartListIndividualAction<PaymentDomain>[] | undefined => {
    if (!payment) {
      return;
    }

    const individualActions: SmartListIndividualAction<PaymentDomain>[] = [];

    if (payment.canBeMarkedAsPaid()) {
      individualActions.push({
        label: "Mark as paid",
        execute: () => {
          dispatch(setSelectedPayment(payment.id));
          setEditPaymentOpen(true);
        },
      });
    }

    if (
      payment.status === PaymentStatus.OUTSTANDING &&
      canSendPayments(merchant.id, currentAgent!)
    ) {
      individualActions.push({
        label: "Copy payment link",
        execute: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/public/pay/${payment.id}`
          );
          toaster.create({
            type: "success",
            title: "Payment link has been copied!",
          });
        },
      });
    }

    if (payment.canBeCancelled()) {
      individualActions.push({
        label: "Cancel payment request",
        execute: () => {
          dispatch(setSelectedPayment(payment.id));
          setCancelPaymentOpen(true);
        },
      });
    }

    if (payment.canDownloadAnInvoice()) {
      individualActions.push({
        label: "Download invoice",
        execute: () => {
          const link = document.createElement("a");
          link.setAttribute("download", "");
          link.href = payment.invoiceUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
      });
    }

    return individualActions;
  };

  useEffect(() => {
    if (!canViewPayments(merchant.id, currentAgent!)) {
      navigate(`/${merchant.id}/inbox`);
    }
  }, [merchant.id, currentAgent]);

  useEffect(() => {
    if (loading && !isLoadingMerchant && !isLoadingPayments) {
      setLoading(false);
    }

    if (isLoadingPayments && !isLoadingMerchant) {
      setLoading(true);
    }
  }, [isLoadingMerchant, isLoadingPayments]);

  useEffect(() => {
    fetchPayments();
  }, [isLoadingMerchant]);

  useEffect(
    () => () => {
      dispatch(setSelectedPayment(undefined));
    },
    [merchant.id]
  );

  useEffect(() => {
    if (errors.length) {
      toaster.create({ type: "error", title: errors[0] });
    }
  }, [errors]);

  return (
    <Flex
      direction="column"
      position="relative"
      flex={1}
      width="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
            pl: "2rem",
            pr: "1rem",
          })}
    >
      {isBaseSize ? (
        <Topbar title="Payments" />
      ) : (
        <Flex
          py={4}
          pr={4}
          justifyContent="start"
          flexWrap="wrap"
          gridGap={4}
          alignItems="center"
        >
          <InputGroup
            w="100%"
            startElement={
              loading ? (
                <Spinner size="sm" color="gray.300" />
              ) : (
                <Icon as={LuSearch} />
              )
            }
          >
            <Input
              colorPalette={colorScheme}
              id="search-bar"
              placeholder="Search by customer name"
              onChange={(e) => setSearchText(e.target.value.trim())}
            />
          </InputGroup>
        </Flex>
      )}
      <Box
        borderTopRadius={isBaseSize ? 0 : "3xl"}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        w="100%"
        h="100%"
        overflowY="hidden"
        position="relative"
      >
        {debouncedIsLoadingPayments ? <SkeletonOverlay /> : null}
        <Box
          py={isBaseSize ? 2 : 0}
          height="100%"
          overflowY="auto"
          ref={listContainerRef}
        >
          <SmartList
            containerRef={listContainerRef}
            columns={[
              {
                label: "Customer",
                component: CustomerColumn,
              },
              {
                label: "Amount",
                component: AmountColumn,
              },
              ...(isBaseSize
                ? []
                : [
                    {
                      label: "Submitted",
                      component: CreatedDateColumn,
                    },
                    {
                      label: "Method",
                      component: MethodColumn,
                    },
                    {
                      label: "Description",
                      component: DescriptionColumn,
                    },
                  ]),
            ]}
            items={currentPayments}
            itemIdentifier="id"
            {...(isBaseSize
              ? {
                  onItemClick: (item: PaymentDomain) => {
                    dispatch(setSelectedPayment(item.id));
                    setMobileDetailsOpen(true);
                  },
                }
              : {})}
            getIndividualActions={getIndividualActions}
            getDifferentiator={(p: PaymentDomain | undefined) =>
              p?.getMonthlyKey() || ""
            }
          />
        </Box>
      </Box>
      <CancelPaymentModal
        isCancelPaymentsOpen={cancelPaymentOpen}
        onCancelPayment={cancelPayment}
        onClose={() => setCancelPaymentOpen(false)}
      />
      <EditPaymentModal
        isEditPaymentsOpen={editPaymentOpen}
        onClose={() => setEditPaymentOpen(false)}
      />
      <DialogRoot
        open={mobileDetailsOpen}
        size="full"
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            setMobileDetailsOpen(false);
            dispatch(setSelectedPayment(undefined));
          }
        }}
      >
        <DialogContent>
          <DialogCloseTrigger />
          <DialogBody>
            {selectedPayment !== undefined &&
            Object.keys(selectedPayment).length > 0 ? (
              <PaymentMobileDetails
                onClick={() => {
                  setMobileDetailsOpen(false);
                  dispatch(setSelectedPayment(undefined));
                }}
                statusColor={selectedPayment!.getStatusColor()}
                paymentMethod={getPaymentMethodIcon(
                  selectedPayment!.method,
                  colorScheme
                )}
                payment={selectedPayment}
              />
            ) : null}
          </DialogBody>

          <DialogFooter>
            <Button
              colorPalette={colorScheme}
              mr={3}
              onClick={() => {
                setMobileDetailsOpen(false);
                dispatch(setSelectedPayment(undefined));
              }}
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </Flex>
  );
};

export default PaymentsPage;
