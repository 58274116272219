import { Flex, Separator, VStack, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { SkeletonCircle, SkeletonText } from "components/ui/skeleton";
import React from "react";

const SkeletonOverlay = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <VStack
      h="100%"
      w="100%"
      position="absolute"
      top={6}
      left={0}
      overflow="hidden"
      zIndex={50}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      {[...Array(5).keys()].map((i) => (
        <React.Fragment key={`skeleton-contact-${i}`}>
          {i === 0 ? null : <Separator />}
          <Flex
            py={2}
            px={isBaseSize ? 2 : 4}
            w="100%"
            h="fit-content"
            gridGap={8}
            alignItems="center"
          >
            {i === 0 ? null : (
              <SkeletonCircle size="8" flexShrink={0} flexGrow={0} />
            )}
            <SkeletonText noOfLines={1} gap={4} flexShrink={1} flexGrow={1} />
          </Flex>
        </React.Fragment>
      ))}
    </VStack>
  );
};

export default SkeletonOverlay;
