import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Spinner from "components/spinner";
import { Alert } from "components/ui/alert";
import { Avatar } from "components/ui/avatar";
import { Checkbox } from "components/ui/checkbox";
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { getFacebookMessengerOauthEndpoint } from "components/user-settings/constants";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingStatus } from "redux/features/onboarding";
import { useAppSelector } from "redux/hooks";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import InboxService from "services/inbox";

const FacebookMessengerCallback = () => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { status } = useAppSelector((state) => state.onboarding);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMessengerConnectionCompleted, setIsMessengerConnectionCompleted] =
    useState<boolean>(false);
  const [availableAccounts, setAvailableAccounts] = useState<
    {
      id: string;
      name: string;
      logo?: string;
    }[]
  >([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);

  const auth0Context = useAuth0();
  const facebook_redirect_url = new URLSearchParams(useLocation().search);
  const state = facebook_redirect_url.get("state");
  const merchantIdFromState = state ? parseInt(state, 10) : null;
  const facebook_authorization_code = facebook_redirect_url.get("code");
  const facebook_error = facebook_redirect_url.get("error");
  const facebook_error_description =
    facebook_redirect_url.get("error_description");

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (!merchantIdFromState || typeof merchantIdFromState !== "number") {
      // eslint-disable-next-line
      console.error(
        "Merchant ID didn't come with the sate to Messenger callback",
        {
          url: facebook_redirect_url,
          passedMerchantId: state,
        }
      );
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Messenger account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);

      return;
    }

    const merchantIsAvailable = currentAgent!.merchants
      .map((m: AgentMerchantDomain) => m.id)
      .includes(merchantIdFromState);

    if (merchantIdFromState !== merchant.id) {
      if (!merchantIsAvailable) {
        // eslint-disable-next-line
        console.error(
          "Merchant ID is not available to agent during Messenger callback processing",
          {
            url: facebook_redirect_url,
            passedMerchantId: state,
            agentId: currentAgent!.id,
          }
        );
        localStorage.setItem(
          "callback_fail",
          JSON.stringify({
            title: "We couldn't connect your Messenger account...",
            description: "Please try again later or contact our support team.",
          })
        );
        window.location.assign(`/public/callback/fail`);

        return;
      }

      localStorage.setItem("fuzey:merchant:id", merchantIdFromState.toString());
      window.location.assign(window.location.href);

      return;
    }

    if (facebook_authorization_code) {
      InboxService.addFacebookMessengerIntegration(
        auth0Context,
        facebook_authorization_code,
        getFacebookMessengerOauthEndpoint(merchant.id),
        merchant.id
      )
        .then(() => {
          InboxService.getFacebookPages(auth0Context, merchant.id).then(
            (accounts) => {
              setAvailableAccounts(accounts);
              setIsLoading(false);
            }
          );
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);

          if (
            merchant.userGuides.pending.includes(
              MerchantUserGuides.ONBOARDING
            ) &&
            status === OnboardingStatus.IN_PROGRESS
          ) {
            localStorage.setItem(
              "callback_fail",
              JSON.stringify({
                title: "We couldn't connect your Facebook Messenger account...",
                description:
                  "Please try again later or contact our support team.",
              })
            );
            window.location.assign(`/public/callback/fail`);

            return;
          }

          window.location.assign(
            `/${merchant.id}/settings?redirect_location=messenger&error=${
              e.response.data.code
            }&error_description=${getErrorDescriptionOrDefault(
              e.response.data.code,
              e.response.data.message
            )}`
          );
        });
    } else {
      window.location.assign(
        `/${merchant.id}/settings?redirect_location=messenger&error=${facebook_error}&error_description=${facebook_error_description}`
      );
      setIsLoading(false);
    }
  }, [merchant.id, currentAgent?.merchants]);

  useEffect(() => {
    if (!isMessengerConnectionCompleted) {
      return;
    }

    InboxService.submitSelectedFacebookPages(
      auth0Context,
      selectedAccounts,
      merchant.id
    )
      .then(() => {
        if (
          merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING) &&
          status === OnboardingStatus.IN_PROGRESS
        ) {
          localStorage.setItem(
            "callback_success",
            JSON.stringify({
              title: "Facebook Messenger was successfully connected!",
              description:
                "You can disconnect any time from your settings page.",
            })
          );
          window.location.assign(`/public/callback/success`);

          return;
        }

        window.location.assign(`/${merchant.id}/settings`);
      })
      .catch(() => {
        localStorage.setItem(
          "callback_fail",
          JSON.stringify({
            title: "We couldn't save your selected accounts...",
            description:
              "Please try disconnecting and reconnecting your Instagram account.",
          })
        );
        window.location.assign(`/public/callback/fail`);
      });
  }, [isMessengerConnectionCompleted, merchant.id]);

  if (isLoading) {
    return <Spinner />;
  }

  return isMessengerConnectionCompleted ? (
    <Flex
      w={isBaseSize ? "90%" : "60%"}
      mx="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Alert
        status="success"
        variant="subtle"
        title="Integration was successfully connected!"
      >
        Redirecting, please wait...
      </Alert>
    </Flex>
  ) : (
    <DialogRoot open={true} closeOnEscape={false}>
      <DialogBackdrop  />
      <DialogContent>
        {availableAccounts.length ? (
          <>
            <DialogHeader>
              Choose Facebook pages this account should have access to
            </DialogHeader>
            <DialogBody>
              <VStack
                gap={4}
                mx="auto"
                justifyContent="start"
                alignItems="start"
              >
                {availableAccounts.map((account) => (
                  <Checkbox
                    key={account.id}
                    checked={selectedAccounts.includes(account.id)}
                    onCheckedChange={(e) => {
                      const isChecked = !!e.checked;
                      const isAlreadyChecked = selectedAccounts.includes(
                        account.id
                      );

                      if (isChecked && isAlreadyChecked) {
                        return;
                      }

                      if (!isChecked && !isAlreadyChecked) {
                        return;
                      }

                      const reject = (id: string) =>
                        selectedAccounts.filter(
                          (selectedId) => selectedId !== id
                        );
                      const accept = (id: string) => [...selectedAccounts, id];

                      setSelectedAccounts(
                        isChecked ? accept(account.id) : reject(account.id)
                      );
                    }}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      gridGap={4}
                    >
                      <Avatar
                        key={account.id}
                        size="sm"
                        src={account.logo}
                        name={account.name}
                      />
                      <Text>{account.name}</Text>
                    </Flex>
                  </Checkbox>
                ))}
              </VStack>
            </DialogBody>

            <DialogFooter>
              <Button
                colorPalette={colorScheme}
                onClick={() => setIsMessengerConnectionCompleted(true)}
              >
                Submit
              </Button>
            </DialogFooter>
          </>
        ) : (
          <DialogHeader>
            We could not find any Instagram pages connected to your account
          </DialogHeader>
        )}
      </DialogContent>
    </DialogRoot>
  );
};

export default FacebookMessengerCallback;
