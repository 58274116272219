import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import SmartList from "components/shared/SmartList";
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import {
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerRoot,
  DrawerTrigger,
} from "components/ui/drawer";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import InventoryVehicleDomain from "entities/domain/inventory-vehicle";
import React, { useEffect, useRef, useState } from "react";
import { LuArrowLeft, LuCar } from "react-icons/lu";
import {
  setInventoryVehicleFilterOptions,
  setInventoryVehicles,
  setSelectedVehicle,
} from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";
import { numberOfInventoryVehiclesPerLoad } from "util/constants";
import AvailabilityFilter from "./AvailabilityFilter";
import ConditionsFilter, {
  DEFAULT_VEHICLE_CONDITION,
} from "./ConditionsFilter";
import MakeFilter from "./MakeFilter";
import ModelFilter from "./ModelFilter";
import PriceFilter from "./PriceFilter";
import VehicleItem from "./VehicleItem";
import YearFilter from "./YearFilter";

interface InventoryProps {}

const hasMoreVehicles = (
  vehiclesAmount: number,
  currentPageAmount: number
): boolean => {
  if (vehiclesAmount < numberOfInventoryVehiclesPerLoad) {
    return false;
  }

  if (!currentPageAmount) {
    return false;
  }

  return true;
};

const Inventory = (_props: InventoryProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();
  const {
    inventoryVehicles,
    filterByCondition,
    filterByMake,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    selectedVehicle,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { open: isOpen, onOpen, onClose } = useDisclosure();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const [numberOfHiddenFiltersUsed, setNumberOfHiddenFiltersUsed] =
    useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const updateHiddenFiltersUsage = () => {
    let newNumberOfHiddenFiltersUsed = 0;

    if (filterByModel) {
      newNumberOfHiddenFiltersUsed++;
    }

    if (filterByCondition && filterByCondition !== DEFAULT_VEHICLE_CONDITION) {
      newNumberOfHiddenFiltersUsed++;
    }

    if (filterByPriceMin || filterByPriceMax) {
      newNumberOfHiddenFiltersUsed++;
    }

    if (filterByYearMin || filterByYearMax) {
      newNumberOfHiddenFiltersUsed++;
    }

    setNumberOfHiddenFiltersUsed(newNumberOfHiddenFiltersUsed);
  };

  const fetchVehicles = async (isFirstPage: boolean = false) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const vehiclesNextPage = await InventoryService.getVehicles(
        auth0Context,
        merchant.id,
        isFirstPage ? 0 : inventoryVehicles.length,
        "desc",
        filterByModel,
        filterByMake,
        filterByYearMin,
        filterByYearMax,
        filterByPriceMin,
        filterByPriceMax,
        filterByCondition,
        filterByAvailability
      );

      const newVehicles: InventoryVehicleDomain[] = !isFirstPage
        ? [...inventoryVehicles].concat(vehiclesNextPage)
        : vehiclesNextPage;

      dispatch(setInventoryVehicles(newVehicles));
      setHasNextPage(
        hasMoreVehicles(newVehicles.length, vehiclesNextPage?.length || 0)
      );
    } finally {
      setIsLoading(false);
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ).then((filterOptions) => {
      dispatch(setInventoryVehicleFilterOptions(filterOptions));
    });
  }, [merchant.id]);

  useEffect(() => {
    updateHiddenFiltersUsage();
    fetchVehicles(true);
  }, [
    filterByCondition,
    filterByMake,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
    merchant.id,
  ]);

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  return !merchant.isIntegrationEnabled(IntegrationName.KEYLOOP) ||
    !inventoryVehicles.length ? null : (
    <DrawerRoot
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      open={isOpen}
      size={isBaseSize ? "full" : "sm"}
    >
      <DrawerTrigger asChild>
        <IconButton
          justifySelf="end"
          aria-label="Inventory"
          colorPalette="gray"
          id="inventory-button"
          borderRadius="full"
          onClick={() => onOpen()}
        >
          <Icon
            as={LuCar}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
        </IconButton>
      </DrawerTrigger>
      <DrawerBackdrop />
      <DrawerContent borderRadius="0 !important">
        <DrawerCloseTrigger />
        {selectedVehicle ? (
          <>
            <DrawerHeader>
              <IconButton
                aria-label="Back"
                colorPalette="gray"
                id="inventory-back-button"
                borderRadius="full"
                onClick={() => dispatch(setSelectedVehicle(null))}
              >
                <Icon
                  as={LuArrowLeft}
                  color={colorMode === "dark" ? "gray.800" : "white"}
                />
              </IconButton>
            </DrawerHeader>
            <DrawerBody py={0} px={4}>
              {selectedVehicle.make || selectedVehicle.model ? (
                <HStack alignItems="center" justifyContent="center" w="100%">
                  <Text fontWeight="bold">
                    {selectedVehicle.make} {selectedVehicle.model}
                  </Text>
                </HStack>
              ) : null}
              {selectedVehicle.description ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="center"
                  w="100%"
                >
                  <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>
                    {selectedVehicle.description}
                  </Text>
                </HStack>
              ) : null}
              {selectedVehicle.grossPrice ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Price</Text>
                  <Text>
                    {merchant.getCurrencySymbol()} {selectedVehicle.grossPrice}
                  </Text>
                </HStack>
              ) : null}
              {selectedVehicle.year ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Year</Text>
                  <Text>{selectedVehicle.year}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.exteriorColor ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Exterior Colour</Text>
                  <Text>{selectedVehicle.exteriorColor}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.interiorColor ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Interior Colour</Text>
                  <Text>{selectedVehicle.interiorColor}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.availabilityStatus ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Availability Status</Text>
                  <Text>{selectedVehicle.availabilityStatus}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.condition ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Condition</Text>
                  <Text>{selectedVehicle.condition}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.vin ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">VIN</Text>
                  <Text>{selectedVehicle.vin}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.licensePlate ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">License Plate</Text>
                  <Text>{selectedVehicle.licensePlate}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.chassis ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Chassis</Text>
                  <Text>{selectedVehicle.chassis}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.engineNumber ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Engine Number</Text>
                  <Text>{selectedVehicle.engineNumber}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.vehicleClass ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Vehicle Class</Text>
                  <Text>{selectedVehicle.vehicleClass}</Text>
                </HStack>
              ) : null}
              {selectedVehicle.variant ? (
                <HStack
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">Variant</Text>
                  <Text>{selectedVehicle.variant}</Text>
                </HStack>
              ) : null}
            </DrawerBody>
          </>
        ) : (
          <>
            <DrawerHeader>Inventory</DrawerHeader>
            <DrawerBody padding={0} ref={listContainerRef}>
              {isInitialLoading ? (
                <Flex
                  h="100%"
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner />
                </Flex>
              ) : (
                <>
                  <Box px={4} py={1}>
                    <MakeFilter />
                  </Box>
                  <AccordionRoot
                    collapsible={true}
                    width="100%"
                    value={expandedItems}
                    onValueChange={({ value: indices }) =>
                      setExpandedItems(indices)
                    }
                  >
                    <AccordionItem
                      value="0"
                      border="none"
                      css={{
                        "& .chakra-accordion__itemContent": {
                          overflow: "initial !important",
                        },
                      }}
                    >
                      <AccordionItemTrigger
                        width="100%"
                        mt={1}
                        px={4}
                        justifyContent="center"
                        bg={colorMode === "dark" ? "gray.900" : "gray.50"}
                      >
                        {expandedItems.includes("0")
                          ? "Hide filters"
                          : "Show filters"}
                        <Badge ml={1} colorPalette={colorScheme}>
                          {numberOfHiddenFiltersUsed || ""}
                        </Badge>
                      </AccordionItemTrigger>
                      <AccordionItemContent p={4}>
                        <VStack gap={4}>
                          <ModelFilter />
                          <AvailabilityFilter />
                          <PriceFilter />
                          <YearFilter />
                          <ConditionsFilter />
                        </VStack>
                      </AccordionItemContent>
                    </AccordionItem>
                  </AccordionRoot>
                  <Box py={4}>
                    <SmartList
                      containerRef={listContainerRef}
                      hasHeader={false}
                      columns={[
                        {
                          label: "Vehicle",
                          component: VehicleItem,
                        },
                      ]}
                      items={inventoryVehicles}
                      itemIdentifier="id"
                      hasDivider={false}
                      hasNextPage={hasNextPage}
                      canFetchMore={true}
                      fetchMore={fetchVehicles}
                    />
                  </Box>
                </>
              )}
            </DrawerBody>
          </>
        )}
      </DrawerContent>
    </DrawerRoot>
  );
};

export default Inventory;
