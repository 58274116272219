import { useAuth0 } from "@auth0/auth0-react";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { toaster } from "components/ui/toaster";
import {
  ExtendedLeadCountBySourceDomain,
} from "entities/domain/leads/lead-count-by-source-domain";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import LeadsService from "services/leads";
import List from "./List";

interface LeadAnalyticsPageProps {
  fromDate: Date | null;
  toDate: Date | null;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

const LeadAnalyticsPage = ({
  fromDate,
  toDate,
  scrollContainerRef,
}: LeadAnalyticsPageProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  const [reports, setReports] = useState<ExtendedLeadCountBySourceDomain[]>([]);

  const loadLeads = async () => {
    setIsLoading(true);

    try {
      const newReports = await LeadsService.getLeadsCountBySource(
        auth0Context,
        merchant.id,
        fromDate!.toISOString(),
        toDate!.toISOString()
      );

      setReports(newReports.map((r, i) => ({ ...r, index: i })));
    } catch (error) {
      toaster.create({
        type: "error",
        title: "We were unable to retrieve analytics data at this time",
        description: "Please try again or contact us if the issue persists.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLeads();
  }, [toDate, fromDate, merchant.id]);

  return (
    <Flex
      gridGap={2}
      justifyContent="start"
      alignItems="center"
      w="100%"
      h="100%"
      p={4}
      pt={isBaseSize ? 12 : 4}
      ref={scrollContainerRef}
      overflowY="auto"
    >
      <List
        reports={reports}
        isLoading={isLoading}
        scrollContainerRef={scrollContainerRef}
      />
    </Flex>
  );
};

export default LeadAnalyticsPage;
