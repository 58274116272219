import { useAuth0 } from "@auth0/auth0-react";
import { OptionTypes } from "components/shared/filter";
import DropdownOptionLabel from "components/shared/filter/OptionLabel";
import { toaster } from "components/ui/toaster";
import ContactDomain from "entities/domain/customers/contact-domain";
import VehicleDomain from "entities/domain/vehicle";
import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import useCalendarStore from "hooks/use-calendar-store";
import React, { ChangeEvent, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { SingleValue } from "react-select";
import { setSelectedCalendarAgents } from "redux/features/calendar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import EventForm from "./EventForm";

interface ChannelOptionTypes {
  value: string;
  label?: string;
}

interface NewEventFormProps {
  startDate?: Date | null;
  endDate?: Date | null;
  openEventForm: boolean;
  closeNewEventForm: () => void;
  customerId?: number;
  defaultChannelId?: string;
}

const NewEventModal = ({
  startDate,
  endDate,
  openEventForm,
  closeNewEventForm,
  customerId,
  defaultChannelId,
}: NewEventFormProps) => {
  const { currentAgent, agents } = useAppSelector((state) => state.agents);
  const { createEvent } = useCalendarStore();
  const { errors, selectedAgentIds } = useAppSelector(
    (state) => state.calendar
  );

  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    startDate || new Date(Date.now())
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    endDate || new Date(Date.now() + 60 * 60 * 1000)
  );
  const [selectedCustomer, setSelectedCustomer] =
    useState<ContactDomain | null>(null);
  const [selectedChannel, setSelectedChannel] =
    useState<SingleValue<ChannelOptionTypes>>(null);
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(true);
  const [customerVehicles, setCustomerVehicles] = useState<VehicleDomain[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleDomain | null>(
    null
  );

  const resetValues = () => {
    setTitle("");
    setDescription("");
    setSelectedCustomer(null);
    setSelectedChannel(null);
    setSelectedVehicle(null);
  };

  useEffect(() => {
    resetValues();
    if (selectedAgentIds.length === 0) {
      dispatch(setSelectedCalendarAgents([currentAgent!.id]));
    }
    if (customerId) {
      ContactsService.getContact(
        auth0Context,
        customerId,
        merchant.groupId
      ).then((fetchedContact) => {
        if (!fetchedContact) {
          setSelectedCustomer(null);

          return;
        }

        setSelectedCustomer(fetchedContact);
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedCustomer || !selectedCustomer.id) {
      setCustomerVehicles([]);
      setSelectedVehicle(null);
      return;
    }

    ContactsService.getVehicles(
      auth0Context,
      selectedCustomer.id,
      merchant.groupId
    ).then((vehicles) => {
      setCustomerVehicles(vehicles);
      setSelectedVehicle(null);
    });
  }, [selectedCustomer]);

  const setNewCustomerAsSelected = (newCustomer: ContactDomain) => {
    setSelectedCustomer(newCustomer);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSelectedChannel = (e: SingleValue<ChannelOptionTypes>) => {
    setSelectedChannel(e);
  };

  const createBooking = async () => {
    const startAt = startDateTime || new Date();
    const endAt = endDateTime || new Date();
    return createEvent({
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title,
      description,
      vehicleId: selectedVehicle ? selectedVehicle.id! : null,
      agentIds: selectedAgentIds,
      customerId: selectedCustomer ? selectedCustomer.id! : null,
      notificationCustomerChannelId:
        selectedChannel && selectedChannel?.value ? selectedChannel.value : "",
    });
  };

  const createCalendarEvent = async () => {
    await createBooking();
    resetValues();
    closeNewEventForm();
    if (errors.length) {
      toaster.create({ type: "error", title: errors[0] });
    } else {
      toaster.create({ type: "success", title: "Event created successfully!" });
    }
  };

  return (
    <EventForm
      openEventForm={openEventForm}
      closeNewEventForm={closeNewEventForm}
      handleTitleChange={(e) => handleTitleChange(e)}
      selectedCustomer={selectedCustomer}
      customerId={customerId}
      defaultChannelId={defaultChannelId}
      handleSelectedCustomer={setSelectedCustomer}
      customerVehicles={customerVehicles}
      selectedVehicle={selectedVehicle}
      handleSelectedVehicle={setSelectedVehicle}
      selectedChannel={selectedChannel}
      handleSelectedChannel={(e) => handleSelectedChannel(e)}
      agentValue={selectedAgentIds
        .flatMap((id: number) => agents.filter((ag) => ag.id === id))
        .map(transformFromAgentDomainToOptionType)}
      handleSelectedAgents={(e) =>
        dispatch(setSelectedCalendarAgents(e.map((ag) => ag.value)))
      }
      handleDescChange={(e) => handleDescChange(e)}
      onFormSubmit={() => createCalendarEvent()}
      SubmitButtonText="Send Invitation"
      startDateTime={startDateTime}
      onStartDateChange={(date) => {
        setStartDateTime(date);
        setEndDateTime(date ? new Date(date.getTime() + 60 * 60 * 1000) : null);
      }}
      endDateTime={endDateTime}
      onEndDateChange={(date) => setEndDateTime(date)}
      notifyCustomer={notifyCustomer}
      handleNotifyCustomer={(e) => {
        return e.target.checked
          ? setNotifyCustomer(true)
          : setNotifyCustomer(false);
      }}
      setNewCustomer={setNewCustomerAsSelected}
      getOptionLabels={(e: OptionTypes) => <DropdownOptionLabel option={e} />}
      isDisabled={!title}
    />
  );
};

export default NewEventModal;
