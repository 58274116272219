import { Flex, Spinner } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";

interface MessageInputOverlayProps {
  isShown: boolean;
}

const MessageInputOverlay = ({ isShown }: MessageInputOverlayProps) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      transition="all 0.5s ease-in-out 0.5s"
      borderRadius="32px"
      position="absolute"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      width="100%"
      height="100%"
      opacity={isShown ? 0.8 : 0}
      zIndex={isShown ? 2 : -1}
      top={0}
      left={0}
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Flex>
  );
};

export default MessageInputOverlay;
