import { Flex, Text } from "@chakra-ui/react";
import { Checkbox } from "components/ui/checkbox";
import { useColorMode } from "components/ui/color-mode";
import React from "react";

interface UnsubscribeSuffixProps {
  isIncluded: boolean;
  isDisabled?: boolean;
  setIsIncluded: (newIsIncluded: boolean) => void;
}

export const UnsubscribeSuffix: React.FC<UnsubscribeSuffixProps> = ({
  isIncluded,
  isDisabled = false,
  setIsIncluded,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      alignItems="center"
      justifyContent="start"
      gridGap={2}
      borderRadius="md"
      borderColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      borderStyle="solid"
      borderWidth="1px"
      width="fit-content"
      p={2}
      mb={2}
    >
      <Checkbox
        disabled={isDisabled}
        colorPalette="green"
        checked={isIncluded}
        onCheckedChange={(e) => setIsIncluded(!!e.checked)}
      >
        <Text
          color={
            colorMode === "dark"
              ? isIncluded
                ? "gray.200"
                : "gray.400"
              : isIncluded
              ? "gray.400"
              : "gray.200"
          }
        >
          Reply UNSUB to unsubscribe
        </Text>
      </Checkbox>
    </Flex>
  );
};
