import { Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { Outlet, useLocation, useParams } from "react-router";
import Onboarding from "../shared/onboarding";
import MobileConversationsListArea from "./MobileConversationListArea";
import MobileTopBar from "./MobileTopBar";

interface MobileChatProps {}

const MobileChat: React.FC<MobileChatProps> = () => {
  const { conversationId: idParam } = useParams();
  const conversationId =
    idParam !== undefined ? parseInt(idParam, 10) : undefined;
  const location = useLocation();

  return (
    <VStack w="100%" h="100%" gap={0}>
      <MobileTopBar />
      <Flex w="100%" h="100%" overflow="hidden" pr={0}>
        {conversationId &&
        location.pathname.includes(conversationId.toString()) ? (
          <Outlet />
        ) : (
          <MobileConversationsListArea />
        )}
      </Flex>
      <Onboarding />
    </VStack>
  );
};

export default MobileChat;
