import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Spinner, useBreakpointValue } from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import WhatsappTemplate, {
  WhatsappButton,
  WhatsappTemplateComponentFormat,
  WhatsappTemplateComponentType,
} from "entities/domain/whatsapp-template";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { useAppSelector } from "redux/hooks";
import Preview from "../create-whatsapp-template/Preview";

interface ViewWhatsappTemplateProps {}

const ViewWhatsappTemplate = (_props: ViewWhatsappTemplateProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { templateId: templateIdParam } = useParams<{ templateId: string }>();
  const auth0Context = useAuth0();
  const { fetchTemplates } = useTemplatesStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { templates, isLoading } = useAppSelector((state) => state.templates);
  const navigate = useNavigate();
  const { search } = useLocation();

  const [templateToView, setTemplateToView] =
    useState<RegularOrWhatsappTemplate | null>(
      templates.find((t) => t.id === templateIdParam) || null
    );

  const [headerText, setHeaderText] = useState<string>("");
  const [bodyText, setBodyText] = useState<string>("");
  const [footerText, setFooterText] = useState<string>("");
  const [headerMediaUrl, setHeaderMediaUrl] = useState<string>("");
  const [headerMediaType, setHeaderMediaType] = useState<string>("");
  const [buttons, setButtons] = useState<WhatsappButton[]>([]);

  const availableCustomFields = useAvailableCustomFields();

  useEffect(() => {
    if (
      !templates ||
      !templates.length ||
      !templates.every((t) => t instanceof WhatsappTemplate)
    ) {
      fetchTemplates(undefined, false);
    }
  }, []);

  useEffect(() => {
    if (!templates || !templates.length || !!templateToView) {
      return;
    }

    const template = templates.find((t) => t.id === templateIdParam);

    if (template) {
      setTemplateToView(template as WhatsappTemplate);
    } else {
      setTemplateToView(null);
    }
  }, [templates]);

  useEffect(() => {
    if (!templateToView) {
      return;
    }

    const isWhatsapp = templateToView instanceof WhatsappTemplate;

    if (!isWhatsapp) {
      navigate(`/${merchant.id}/settings/templates/${templateIdParam}`);

      return;
    }

    const whatsappTemplate = templateToView as WhatsappTemplate;

    const headerComponent = whatsappTemplate.components.find(
      (c) => c.type === WhatsappTemplateComponentType.HEADER
    );

    if (headerComponent) {
      const isText = ![
        WhatsappTemplateComponentFormat.DOCUMENT,
        WhatsappTemplateComponentFormat.IMAGE,
        WhatsappTemplateComponentFormat.VIDEO,
        // We don't specify Location yet
      ].includes(headerComponent.format);

      if (isText) {
        setHeaderText(headerComponent.text);
      } else {
        setHeaderMediaUrl(headerComponent.getMedia()?.url || "");
        setHeaderMediaType(headerComponent.getMedia()?.type || "");
      }
    }

    const bodyComponent = whatsappTemplate.components.find(
      (c) => c.type === WhatsappTemplateComponentType.BODY
    );

    if (bodyComponent) {
      setBodyText(bodyComponent.text);
    }

    const footerComponent = whatsappTemplate.components.find(
      (c) => c.type === WhatsappTemplateComponentType.FOOTER
    );

    if (footerComponent) {
      setFooterText(footerComponent.text);
    }

    const buttonsComponent = whatsappTemplate.components.find(
      (c) => c.type === WhatsappTemplateComponentType.BUTTONS
    );

    if (buttonsComponent && buttonsComponent.buttons) {
      setButtons(buttonsComponent.buttons);
    }
  }, [templateToView]);

  if (
    isLoading ||
    !(templateToView instanceof WhatsappTemplate) ||
    !templateToView
  ) {
    return <Spinner />;
  }

  return (
    <DialogRoot
      size={isBaseSize ? "full" : "xl"}
      onInteractOutside={() => {
        navigate({
          pathname: `/${merchant.id}/settings/templates`,
          search: createSearchParams(search).toString(),
        });
      }}
      onEscapeKeyDown={() => {
        navigate({
          pathname: `/${merchant.id}/settings/templates`,
          search: createSearchParams(search).toString(),
        });
      }}
      open={true}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>{templateToView.name}</DialogHeader>
        <DialogCloseTrigger
          onClick={() => {
            navigate({
              pathname: `/${merchant.id}/settings/templates`,
              search: createSearchParams(search).toString(),
            });
          }}
        />
        <DialogBody>
          <Box>
            <Preview
              customFields={
                templateToView.customFields || availableCustomFields
              }
              headerText={headerText}
              bodyText={bodyText}
              footerText={footerText}
              file={null}
              mediaUrl={headerMediaUrl}
              mediaType={headerMediaType}
              buttons={buttons}
            />
          </Box>
        </DialogBody>
        <DialogFooter justifyContent="end">
          <Button
            onClick={() => {
              navigate({
                pathname: `/${merchant.id}/settings/templates`,
                search: createSearchParams(search).toString(),
              });
            }}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default ViewWhatsappTemplate;
