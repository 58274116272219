import { Box, Flex } from "@chakra-ui/react";
import FuzeyLogo from "assets/icons/Fuzey.svg";
import Spinner from "components/spinner";
import PublicPaymentDomain from "entities/domain/public/payments/payments";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PublicPagesService from "services/publicPages";
import FinalPaymentPage from "./finalPage/finalPage";

const ExternalPaymentCallback = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPayment, setCurrentPayment] = useState<
    PublicPaymentDomain | undefined
  >(undefined);

  const callbackUrl = new URLSearchParams(useLocation().search);
  const paymentId = callbackUrl.get("paymentId") || "";
  const paymentIdempotencyId = callbackUrl.get("paymentIdempotencyId") || "";
  const consentToken = callbackUrl.get("consent") || "";

  useEffect(() => {
    if (!consentToken) {
      window.location.assign(`/public/pay/${paymentId!}`);
    } else {
      PublicPagesService.postYapilyPayment(
        paymentId,
        consentToken,
        paymentIdempotencyId
      ).then((response) => {
        setCurrentPayment(response);
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box height="100%" width="100%" overflowY="auto">
      <Box
        minHeight="660px"
        width="792px"
        margin="30px auto 0"
        pb="30px"
        css={{
          "@media screen and (max-width: 1030px)": {
            height: "100%",
            width: "100%",
            margin: 0,
            paddingTop: "30px",
            overflowY: "auto",
          },
        }}
      >
        <Flex justifyContent="center" pt="30px" mb="30px">
          <img
            src={FuzeyLogo}
            alt="Logo"
            style={{
              cursor: "pointer",
            }}
            width={140}
            height={150}
          />
        </Flex>
        <FinalPaymentPage
          onClick={() => window.close()}
          amount={currentPayment?.amount}
          currency={currentPayment?.currency.toUpperCase()}
          status={currentPayment?.status}
          merchantName={currentPayment?.merchantName}
          invoiceUrl={currentPayment?.invoice?.url}
        />
      </Box>
    </Box>
  );
};

export default ExternalPaymentCallback;
