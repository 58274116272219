import { Button, Icon, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import {
  WhatsappButton,
  WhatsappButtonType,
} from "entities/domain/whatsapp-template";
import React from "react";
import { LuExternalLink, LuPhone, LuReply } from "react-icons/lu";

interface ButtonsPreviewProps {
  buttons: WhatsappButton[];
  forceColorMode?: "light" | "dark";
}

const ButtonsPreview = ({ buttons, forceColorMode }: ButtonsPreviewProps) => {
  const { colorMode } = useColorMode();

  return buttons.length === 0 ? null : (
    <VStack width="100%" gap={0} flexGrow={0}>
      {buttons.map((button, index) => (
        <Tooltip
          key={index}
          content={
            button.type === WhatsappButtonType.PHONE_NUMBER
              ? button.phoneNumber
              : button.type === WhatsappButtonType.URL
              ? button.url
              : ""
          }
          disabled={button.type === WhatsappButtonType.QUICK_REPLY}
        >
          <Button
            zIndex={5} // To prevent the button from being covered by the tail
            width="100%"
            variant="ghost"
            display="flex"
            textTransform="none"
            alignItems="center"
            colorPalette="gray" // Adequately changes color text
            borderTopRadius={0}
            borderBottomRadius={index === buttons.length - 1 ? "xl" : 0}
            borderColor={
              (forceColorMode || colorMode) === "dark" ? "white" : "gray.200"
            }
            borderTopWidth={index === 0 ? "1px" : 0}
            borderBottomWidth={index === buttons.length - 1 ? 0 : "1px"}
          >
            <Icon
              as={
                button.type === WhatsappButtonType.QUICK_REPLY
                  ? LuReply
                  : button.type === WhatsappButtonType.PHONE_NUMBER
                  ? LuPhone
                  : LuExternalLink
              }
              {...(button.type === WhatsappButtonType.QUICK_REPLY
                ? {
                    color:
                      (forceColorMode || colorMode) === "dark"
                        ? "white"
                        : "black",
                  }
                : {})}
            />{" "}
            {button.text}
          </Button>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default ButtonsPreview;
