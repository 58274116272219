import { Auth0ContextInterface } from "@auth0/auth0-react";
import ReplySuggestionDomain from "entities/domain/reply_suggestion";
import { ReplySuggestionDTO } from "entities/dto/ReplySuggestionDTO";
import { deleteRequest, patchRequest, request } from "util/methods";
import { RequestType } from "./request-type";
import { replySuggestionTransformFromDtoToDomain } from "entities/transformers/reply-suggestion-transformer";

class ReplySuggestionService {
  public static async getReplySuggestion(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number
  ): Promise<ReplySuggestionDomain | null> {
    const accessToken = await getAccessTokenSilently();

    const replySuggestionResponse = (
      await request<{ reply_suggestion: ReplySuggestionDTO | null }>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/reply-suggestions`
      )
    ).data.reply_suggestion;

    return replySuggestionResponse
      ? replySuggestionTransformFromDtoToDomain(replySuggestionResponse)
      : null;
  }

  public static async markReplySuggestionAsRead(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number,
    replySuggestionId: string
  ): Promise<ReplySuggestionDomain> {
    const accessToken = await getAccessTokenSilently();

    const replySuggestionResponse = (
      await patchRequest<{ replySuggestion: ReplySuggestionDTO }>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/reply-suggestions/${replySuggestionId}/seen`
      )
    ).data.replySuggestion;

    return replySuggestionTransformFromDtoToDomain(replySuggestionResponse);
  }

  public static async deleteReplySuggestion(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number,
    replySuggestionId: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/conversations/${conversationId}/reply-suggestions/${replySuggestionId}`
    );
  }
}

export default ReplySuggestionService;
