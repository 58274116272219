import { Button, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { LuX } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

const AttachmentWrapper = ({
    children,
    onRemove,
}: {
    children: React.ReactNode;
    onRemove?: () => void;
}) => {
    const { colorScheme } = useAppSelector((state) => state.theme);

    return (
      <Flex
        borderRadius="lg"
        pr={typeof onRemove === "undefined" ? 0 : 5}
        pt={typeof onRemove === "undefined" ? 0 : 5}
        position="relative"
        width="fit-content"
        alignSelf="start"
        className="message-attachment-wrapper"
        _hover={{
          opacity: 0.8,
        }}
      >
        {typeof onRemove !== "undefined" && (
          <Button
            position="absolute"
            size="xs"
            top={0}
            right={0}
            variant="ghost"
            colorPalette={colorScheme}
            onClick={() => {
              if (typeof onRemove === "undefined") {
                return;
              }

              onRemove();
            }}
          >
            <Icon as={LuX} />
          </Button>
        )}
        {children}
      </Flex>
    );
};

export default AttachmentWrapper;
