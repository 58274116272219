import { Icon, Input, List, VStack } from "@chakra-ui/react";
import { InputGroup } from "components/ui/input-group";
import { toaster } from "components/ui/toaster";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TemplateDomain from "entities/domain/templates";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { LuSearch } from "react-icons/lu";
import {
  activeConversationTemplatesSelector,
  clearMessageInput,
  propagateTemplateUpdate,
  setMessageInputIsPreparing,
  setMessageInputTemplate,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TemplateItem from "./TemplateItem";

interface TemplatesPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

const TemplatesPopover = ({
  onClose,
  isOpen,
  ...rest
}: TemplatesPopoverProps) => {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [searchValue, setSearchValue] = useState<string>("");
  const { markTemplateAsFavouriteOrGeneral } = useTemplatesStore();
  const { errors } = useAppSelector((state) => state.templates);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const popoverContent = useRef<HTMLDivElement>(null);
  const conversationTemplates = useAppSelector(
    activeConversationTemplatesSelector
  );
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const [filteredTemplates, setFilteredTemplates] = useState<TemplateDomain[]>(
    conversationTemplates.filter((t: TemplateDomain) =>
      t.title.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  useEffect(() => {
    return () => {
      setSearchValue("");
    };
  }, []);

  useEffect(() => {
    if (!popoverContent || !popoverContent.current) {
      return;
    }

    popoverContent.current.scrollTop = 0;
    setSearchValue("");
  }, [isOpen, popoverContent]);

  useEffect(() => {
    if (errors.length) {
      toaster.create({ type: "error", title: errors[0] });
    }
  }, [errors]);

  useEffect(() => {
    setFilteredTemplates(
      conversationTemplates.filter((t: TemplateDomain) =>
        t.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [conversationTemplates, searchValue]);

  const findTemplateById = (templateId: string) => {
    return conversationTemplates.find(
      (t: TemplateDomain) => t.id === templateId
    );
  };

  const onTemplateClick = (templateId: string) => {
    const template = findTemplateById(templateId);

    onClose();

    if (!template) {
      return;
    }

    flushSync(() => {
      dispatch(clearMessageInput());
    });
    dispatch(setMessageInputIsPreparing(true));
    dispatch(setMessageInputTemplate(template));
  };

  const onMakeFavouriteClick = useCallback(
    async (templateId: string) => {
      const updatedTemplate = await markTemplateAsFavouriteOrGeneral(
        conversationTemplates.find((t) => t.id === templateId),
        true
      );

      if (!updatedTemplate) return;

      if (activeConversationId) {
        dispatch(
          propagateTemplateUpdate({
            template: updatedTemplate as TemplateDomain,
            conversationId: activeConversationId,
          })
        );
      }
    },
    [
      conversationTemplates,
      markTemplateAsFavouriteOrGeneral,
      activeConversationId,
    ]
  );

  const onMakeGeneralClick = useCallback(
    async (templateId: string) => {
      const updatedTemplate = await markTemplateAsFavouriteOrGeneral(
        conversationTemplates.find((t) => t.id === templateId),
        false
      );

      if (!updatedTemplate) return;

      if (activeConversationId) {
        dispatch(
          propagateTemplateUpdate({
            template: updatedTemplate as TemplateDomain,
            conversationId: activeConversationId,
          })
        );
      }
    },
    [
      conversationTemplates,
      markTemplateAsFavouriteOrGeneral,
      activeConversationId,
    ]
  );

  return (
    <VStack
      w="100%"
      mt={2}
      maxHeight="50vh"
      overflowY="auto"
      ref={popoverContent}
      {...rest}
    >
      <InputGroup w="90%" endElement={<Icon as={LuSearch} />}>
        <Input
          colorPalette={colorScheme}
          id="templates-search-input"
          value={searchValue}
          // @ts-ignore
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
        />
      </InputGroup>

      <List.Root
        width="100%"
        display="flex"
        flexFlow="column nowrap"
        listStyleType="none"
      >
        {filteredTemplates.map(
          ({ id, title, text, favourite, channels }: TemplateDomain, i) => (
            <TemplateItem
              id={id}
              isFavourite={favourite}
              title={title}
              text={text}
              key={i.toString()}
              onClick={onTemplateClick}
              onMakeFavourite={
                channels.length === 1 &&
                channels.includes(ConversationChannel.WHATSAPP)
                  ? undefined
                  : onMakeFavouriteClick
              }
              onMakeGeneral={
                channels.length === 1 &&
                channels.includes(ConversationChannel.WHATSAPP)
                  ? undefined
                  : onMakeGeneralClick
              }
            />
          )
        )}
      </List.Root>
    </VStack>
  );
};

export default TemplatesPopover;
