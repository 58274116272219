import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  HStack,
  Icon,
  Separator,
  useDisclosure,
} from "@chakra-ui/react";

import { ReactComponent as TagRemoveIcon } from "assets/icons/tag-minus-outline.svg";
import { ReactComponent as TagAddIcon } from "assets/icons/tag-plus-outline.svg";
import AssignAgentModal from "components/modals/AssignAgent";
import UpdateTags from "components/modals/tags/UpdateTags";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useColorMode } from "components/ui/color-mode";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from "components/ui/menu";
import { toaster } from "components/ui/toaster";
import React, { useEffect, useState } from "react";
import {
  LuCheck,
  LuChevronDown,
  LuCopy,
  LuCopyCheck,
  LuEye,
  LuEyeOff,
  LuLock,
  LuLockOpen,
  LuPenLine,
  LuPointer,
  LuPointerOff,
  LuSquare,
  LuUserRoundCheck,
} from "react-icons/lu";

import { batch } from "react-redux";
import {
  OpenClosedFilter,
  clearSelectedConversations,
  disableBulkActionsToolbar,
  enableBulkActionsToolbar,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  updateConversationIsOpen,
  updateConversationIsRead,
  updateConversationIsSubscribed,
  updateConversationSelection,
  updateConversationTagIds,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import InboxService from "services/inbox";
import {
  MAX_ALLOWED_BULK_SELECTION,
  MAX_SAFE_SELECTION_AMOUNT,
} from "util/constants";
import { getChannelsFromConversations } from "util/methods";

const MobileBulkActions = () => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const assignModal = useDisclosure();
  const { tags } = useAppSelector((state) => state.tags);
  const { merchant } = useAppSelector((state) => state.merchant);
  const {
    selectedConversationIds,
    isOpenOrClosed,
    conversations,
    bulkActionsToolbarEnabled,
  } = useAppSelector((state) => state.conversations);

  const [selectedConversationsChannels, setSelectedConversationsChannels] =
    useState<string[]>(
      getChannelsFromConversations(conversations, selectedConversationIds)
    );

  useEffect(() => {
    setSelectedConversationsChannels(
      getChannelsFromConversations(conversations, selectedConversationIds)
    );
  }, [selectedConversationIds, conversations]);
  const [shouldShowConfirmation, setShouldShowConfirmation] =
    useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] = useState<() => void>(
    () => () => {}
  );
  const [shouldShowTagsModal, setShouldShowTagsModal] =
    useState<boolean>(false);
  const [isRemovingTag, setIsRemovingTag] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldShowTagsModal) {
      setIsRemovingTag(false);
    }
  }, [shouldShowTagsModal]);

  const openOrCloseAll = async () => {
    try {
      const shouldOpen = isOpenOrClosed === OpenClosedFilter.Closed;
      const updatedConversationIds =
        await InboxService.bulkOpenOrCloseConversations(
          auth0Context,
          merchant.id,
          selectedConversationIds,
          shouldOpen
        );

      if (updatedConversationIds.length === 0) {
        toaster.create({
          type: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      batch(() => {
        updatedConversationIds.forEach((conversationId) => {
          dispatch(
            updateConversationIsOpen({
              conversationId,
              isOpen: shouldOpen,
            })
          );
        });
        dispatch(clearSelectedConversations());
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const markAllAsReadOrUnread = async (shouldMarkRead: boolean) => {
    try {
      const updatedConversationIds = await InboxService.bulkMarkReadOrUnread(
        auth0Context,
        merchant.id,
        selectedConversationIds,
        shouldMarkRead
      );

      if (updatedConversationIds.length === 0) {
        toaster.create({
          type: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      batch(() => {
        updatedConversationIds.forEach((conversationId) => {
          dispatch(
            updateConversationIsRead({
              conversationId,
              isRead: shouldMarkRead,
            })
          );
        });
        dispatch(clearSelectedConversations());
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const bulkSubscribeOrUnsubscribe = async (shouldSubscribe: boolean) => {
    try {
      const updatedChannelIds =
        await ContactsService.bulkUpdateCustomerChannels(
          auth0Context,
          merchant.groupId,
          selectedConversationsChannels,
          shouldSubscribe
        );

      if (updatedChannelIds.length === 0) {
        toaster.create({
          type: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      if (updatedChannelIds.length === selectedConversationIds.length) {
        batch(() => {
          selectedConversationIds.forEach((conversationId) => {
            dispatch(
              updateConversationIsSubscribed({
                conversationId,
                isSubscribed: shouldSubscribe,
              })
            );
          });
          dispatch(clearSelectedConversations());
        });
      }
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const handleBulkReadOrUnread = (shouldMarkRead: boolean) => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    markAllAsReadOrUnread(shouldMarkRead).finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  const handleBulkOpenOrClose = () => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    openOrCloseAll().finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between" w="100%" p={2}>
        <Button
          colorPalette={colorScheme}
          size="xs"
          onClick={() => {
            if (
              selectedConversationIds.length ===
              conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
            ) {
              dispatch(clearSelectedConversations());

              return;
            }

            batch(() => {
              conversations
                .slice(0, MAX_ALLOWED_BULK_SELECTION)
                .forEach((c) => {
                  dispatch(
                    updateConversationSelection({
                      conversationId: c.id,
                      isSelected: true,
                    })
                  );
                });
            });
          }}
        >
          <Icon
            as={
              selectedConversationIds.length ===
              conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
                ? LuCopyCheck
                : LuCopy
            }
            w={4}
            h={4}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          {conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length ===
          selectedConversationIds.length
            ? "Deselect All"
            : "Select All"}
        </Button>
        <MenuRoot>
          <MenuTrigger asChild={true}>
            <Button
              width="auto"
              aria-label="Bulk Actions"
              size="xs"
              variant="solid"
              disabled={!bulkActionsToolbarEnabled}
              colorPalette={colorScheme}
            >
              <Icon
                as={LuPenLine}
                height="1rem"
                width="1rem"
                color={colorMode === "dark" ? "gray.800" : "white"}
              />{" "}
              Bulk actions <LuChevronDown />
            </Button>
          </MenuTrigger>
          <MenuContent>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => {
                    setShouldShowTagsModal(true);
                  });
                  setShouldShowConfirmation(true);
                  return;
                }
                setShouldShowTagsModal(true);
              }}
              value="Add Tag"
            >
              <Icon
                as={TagAddIcon}
                fill={colorMode === "dark" ? "white" : "gray.800"}
                w={4}
                h={4}
              />{" "}
              Add Tag
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => {
                    setIsRemovingTag(true);
                    setShouldShowTagsModal(true);
                  });
                  setShouldShowConfirmation(true);
                  return;
                }
                setIsRemovingTag(true);
                setShouldShowTagsModal(true);
              }}
              value="Remove Tag"
            >
              <Icon
                as={TagRemoveIcon}
                fill={colorMode === "dark" ? "white" : "gray.800"}
                w={4}
                h={4}
              />{" "}
              Remove Tag
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => assignModal.onOpen());
                  setShouldShowConfirmation(true);
                  return;
                }
                assignModal.onOpen();
              }}
              value="Assign to"
            >
              <Icon
                as={LuUserRoundCheck}
                color={colorMode === "dark" ? "white" : "gray.800"}
                w={4}
                h={4}
              />{" "}
              Assign to
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => handleBulkOpenOrClose());
                  setShouldShowConfirmation(true);
                  return;
                }
                handleBulkOpenOrClose();
              }}
              value={
                isOpenOrClosed === OpenClosedFilter.Open ? "Close" : "Open"
              }
            >
              {isOpenOrClosed === OpenClosedFilter.Open ? (
                <Icon
                  as={LuLock}
                  color={colorMode === "dark" ? "white" : "gray.800"}
                  w={4}
                  h={4}
                />
              ) : (
                <Icon
                  as={LuLockOpen}
                  color={colorMode === "dark" ? "white" : "gray.800"}
                  w={4}
                  h={4}
                />
              )}{" "}
              {isOpenOrClosed === OpenClosedFilter.Open ? "Close" : "Open"}
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(
                    () => () => handleBulkReadOrUnread(true)
                  );
                  setShouldShowConfirmation(true);
                  return;
                }
                handleBulkReadOrUnread(true);
              }}
              value="Mark Read"
            >
              <Icon
                as={LuEye}
                color={colorMode === "dark" ? "white" : "gray.800"}
                w={4}
                h={4}
              />{" "}
              Mark Read
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(
                    () => () => handleBulkReadOrUnread(false)
                  );
                  setShouldShowConfirmation(true);
                  return;
                }
                handleBulkReadOrUnread(false);
              }}
              value="Mark Unread"
            >
              <Icon
                as={LuEyeOff}
                color={colorMode === "dark" ? "white" : "gray.800"}
                w={4}
                h={4}
              />{" "}
              Mark Unread
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                bulkSubscribeOrUnsubscribe(true);
              }}
              value="Subscribe"
            >
              <Icon
                as={LuPointer}
                boxSize={4}
                color={colorMode === "dark" ? "white" : "gray.800"}
              />
              Subscribe
            </MenuItem>
            <MenuItem
              disabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(
                    () => () => bulkSubscribeOrUnsubscribe(false)
                  );
                  setShouldShowConfirmation(true);
                  return;
                }
                bulkSubscribeOrUnsubscribe(false);
              }}
              value="Unsubscribe"
            >
              <Icon
                as={LuPointerOff}
                boxSize={4}
                color={colorMode === "dark" ? "white" : "gray.800"}
              />
              Unsubscribe
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      </HStack>
      <Separator />
      <AssignAgentModal
        isOpen={assignModal.open}
        onClose={() => {
          dispatch(enableBulkActionsToolbar());
          assignModal.onClose();
        }}
        assignedAgentId={undefined}
        assignedTeamId={undefined}
        customerId={undefined}
        conversationId={undefined}
        conversationChannel={undefined}
      />
      {!selectedConversationIds.length ? null : (
        <UpdateTags
          isOpen={shouldShowTagsModal}
          isRemoval={isRemovingTag}
          onClose={() => {
            dispatch(enableBulkActionsToolbar());
            setShouldShowTagsModal(false);
          }}
          tagIds={[]}
          onSubmit={async (tagIds) => {
            dispatch(startLoadingBulkActionsToolbar());

            const selectedTags = tags.filter((t) => tagIds.includes(t.id));
            const selectedContactIds: number[] = [];

            selectedConversationIds.forEach((conversationId) => {
              const conversation = conversations.find(
                (c) => c.id === conversationId
              );

              if (
                conversation &&
                selectedContactIds.includes(conversation.customerId) === false
              ) {
                selectedContactIds.push(conversation.customerId);
              }
            });

            try {
              let updatedContactIds: number[] = [];

              if (isRemovingTag) {
                updatedContactIds = await ContactsService.bulkRemoveTags(
                  auth0Context,
                  merchant.groupId,
                  selectedContactIds,
                  selectedTags.map((tag) => tag.tag),
                  selectedTags.map((tag) => tag.id)
                );
              } else {
                updatedContactIds = await ContactsService.bulkAddTags(
                  auth0Context,
                  merchant.groupId,
                  selectedContactIds,
                  selectedTags.map((tag) => tag.tag),
                  selectedTags.map((tag) => tag.id)
                );
              }

              if (updatedContactIds.length === 0) {
                toaster.create({
                  type: "error",
                  title: "Failed to perform bulk action.",
                  description: "Please try again later.",
                });

                return;
              }

              batch(() => {
                updatedContactIds.forEach((contactId) => {
                  conversations.forEach((conversation) => {
                    if (conversation.customerId === contactId) {
                      dispatch(
                        updateConversationTagIds({
                          conversationId: conversation.id,
                          tagIds: isRemovingTag
                            ? conversation.tagIds.filter(
                                (tagId) =>
                                  !selectedTags
                                    .map((tag) => tag.id)
                                    .includes(tagId)
                              )
                            : [
                                ...new Set([
                                  ...conversation.tagIds,
                                  ...selectedTags.map((tag) => tag.id),
                                ]),
                              ],
                        })
                      );
                    }
                  });
                });
                dispatch(clearSelectedConversations());
              });
            } catch (_error: unknown) {
              toaster.create({
                type: "error",
                title: "Failed to perform bulk action.",
                description: "Please try again later.",
              });
            } finally {
              dispatch(enableBulkActionsToolbar());
              dispatch(stopLoadingBulkActionsToolbar());
              setShouldShowTagsModal(false);
            }
          }}
        />
      )}
      <ConfirmationDialog
        isOpen={shouldShowConfirmation}
        setIsOpen={setShouldShowConfirmation}
        headerText="Are you sure about this?"
        messageText={`You're about to do a bulk action over ${selectedConversationIds.length} conversations.`}
        buttonText="Yes"
        confirmationCallback={() => {
          confirmationAction();
          setShouldShowConfirmation(false);
          setConfirmationAction(() => () => {});
        }}
      />
    </>
  );
};

export default MobileBulkActions;
