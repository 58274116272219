import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { ReactSVG } from "react-svg";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { getChannelIcon } from "util/constants";

const TemplateChannelsColumn = ({
  item,
}: {
  item: RegularOrWhatsappTemplate;
}) => {
  if (item.isRegularTemplate()) {
    return (
      <Flex justifyContent="start" alignItems="center" gridGap={2}>
        {item.channels.map((chan) => (
          <Icon
            mr={2}
            key={chan}
            asChild={true}
            css={{ "& svg": { height: "1rem", width: "1rem" } }}
          >
            <ReactSVG src={getChannelIcon(chan)} />
          </Icon>
        ))}
      </Flex>
    );
  }

  return null;
};

export default TemplateChannelsColumn;
