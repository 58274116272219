import {
  Flex,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import FileDomain from "entities/domain/file";
import React, { useCallback, useMemo, useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import { useAppSelector } from "redux/hooks";

interface DragAndDropAreaProps {
  size: "sm" | "md" | "lg";
  isEnabled?: boolean;
  uploadedFiles: FileDomain[];
  isSelected?: boolean;
  select?: () => void;
  onFileChange: (files: File[]) => void;
  children: React.ReactNode;
  onClick: () => void;
  hasFiles: boolean;
}

const DragAndDropArea = ({
  size,
  isSelected,
  hasFiles,
  onClick,
  select,
  uploadedFiles,
  isEnabled = true,
  onFileChange,
  children,
}: DragAndDropAreaProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const files = Array.from(e.dataTransfer.files);

      onFileChange(files);
      setIsDragActive(false);
    },
    [onFileChange, setIsDragActive]
  );

  const handleDrag = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === "dragenter" || e.type === "dragover") {
        setIsDragActive(true);
      } else if (e.type === "dragleave") {
        setIsDragActive(false);
      }
    },
    [setIsDragActive]
  );

  const areaProps = useMemo(() => {
    const defaultStyles = {
      position: "relative",
      borderWidth: "3px",
      borderRadius: "lg",
    };

    const disabledStyles = {
      borderColor: "transparent",
    };

    const canChooseFilesProps = {
      onDragEnter: handleDrag,
      onDragLeave: handleDrag,
      onDragOver: handleDrag,
      onDrop: handleDrop,
      onClick: (e: Event) => {
        const target = e.target as HTMLElement;

        if (
          target.id === "submit-files" ||
          target.id === "clear-files" ||
          target.closest("#submit-files") ||
          target.closest("#clear-files")
        ) {
          return;
        }

        onClick();
      },
    };

    const enabledStyles = {
      px: 4,
      py: 8,
      w: "100%",
      backgroundColor: colorMode === "dark" ? "black" : "gray.50",
      cursor: "pointer",
    };

    const enabledButEmptyStyles = {
      ...enabledStyles,
      borderColor: isDragActive
        ? colorMode === "dark"
          ? `${colorScheme}.200`
          : `${colorScheme}.500`
        : colorMode === "dark"
        ? "gray.700"
        : "gray.100",
      borderStyle: "dashed",
    };

    const enabledAndNotEmptyStyles = {
      ...enabledStyles,
      borderStyle: "dashed",
      borderColor:
        colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
    };

    const enabledAndIsDragActiveStyles = {
      ...enabledStyles,
      borderColor:
        colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
      borderStyle: "dashed",
    };

    if (!isEnabled) {
      return {
        ...defaultStyles,
        ...disabledStyles,
      };
    }

    if (isSelected) {
      return {
        ...defaultStyles,
        ...enabledStyles,
        borderColor:
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
        borderStyle: "solid",
        borderWidth: "3px",
        onClick: select,
      };
    } else if (uploadedFiles.length) {
      return {
        ...defaultStyles,
        ...enabledStyles,
        borderColor: colorMode === "dark" ? "gray.700" : "gray.100",
        borderStyle: "solid",
        borderWidth: "3px",
        onClick: select,
      };
    }

    if (hasFiles) {
      return {
        ...defaultStyles,
        ...enabledAndNotEmptyStyles,
        ...canChooseFilesProps,
      };
    }

    if (isDragActive) {
      return {
        ...defaultStyles,
        ...enabledAndIsDragActiveStyles,
        ...canChooseFilesProps,
      };
    }

    return {
      ...defaultStyles,
      ...enabledButEmptyStyles,
      ...canChooseFilesProps,
    };
  }, [
    hasFiles,
    isDragActive,
    uploadedFiles.length,
    isSelected,
    select,
    colorMode,
    colorScheme,
  ]);

  return (
    <Flex flexDirection="column" alignItems="center" {...(areaProps as any)}>
      {isEnabled && !hasFiles ? (
        <VStack mb={4}>
          {isSelected || uploadedFiles.length ? null : (
            <>
              <Icon
                as={FaFileUpload}
                boxSize={16}
                color={colorMode === "dark" ? "gray.700" : "gray.100"}
              />
              <Text
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.700" : "gray.100"}
              >
                Drag & Drop here
              </Text>
            </>
          )}
        </VStack>
      ) : null}
      {children}
    </Flex>
  );
};

export default DragAndDropArea;
