import AdminKeyloopAccountDomain from "entities/domain/admin/merchants/admin-keyloop-account-domain";
import { AdminKeyloopAccountDTO } from "entities/dto/AdminKeyloopAccountDTO";

export const adminKeyloopAccountTransformFromDtoToDomain = (
  adminKeyloopAccount: AdminKeyloopAccountDTO
): AdminKeyloopAccountDomain =>
  new AdminKeyloopAccountDomain(
    adminKeyloopAccount.enterprise_id,
    adminKeyloopAccount.store_id,
    adminKeyloopAccount.account_name,
    adminKeyloopAccount.merchant_ids,
    adminKeyloopAccount.service_merchant_id
  );
