import { ConnectButton } from "360dialog-connect-button";
import { Button, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { SocketMessage } from "entities/ISocketArgs";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

import { Alert } from "components/ui/alert";
import { Checkbox } from "components/ui/checkbox";
import { toaster } from "components/ui/toaster";
import { useWebSocket } from "hooks/use-socket";
import { MdContentCopy } from "react-icons/md";
import MessagePreviewSnippet from "./MessagePreviewSnippet";

type Pre360DialogRedirectProps = {
  setConsentGiven: (param: true) => void;
};

export const Pre360DialogRedirect = ({
  setConsentGiven,
}: Pre360DialogRedirectProps) => {
  const partnerId = process.env.REACT_APP_360_PARTNER_ID;

  const { merchant } = useAppSelector((state) => state.merchant);
  const [consent, setConsent] = useState({
    billing: false,
    otp: false,
    freshFacebookAccount: false,
  });

  return (
    <VStack w="full" alignItems="flex-start">
      <Heading fontSize="xl" alignSelf="center">
        Before we begin, be aware that:
      </Heading>
      <Checkbox
        checked={consent.freshFacebookAccount}
        onCheckedChange={(e) =>
          setConsent({ ...consent, freshFacebookAccount: !!e.checked })
        }
      >
        <Text fontSize="xs">
          We suggest using an existing Facebook account for WhatsApp sign up (as
          new profiles are sometimes delayed by Meta);
        </Text>
      </Checkbox>
      <Checkbox
        checked={consent.billing}
        onCheckedChange={(e) =>
          setConsent({ ...consent, billing: !!e.checked })
        }
      >
        <Text fontSize="xs">
          As you register with 360Dialog, rest assured that all billing will go
          via Fuzey as per your existing agreement; and
        </Text>
      </Checkbox>
      <Checkbox
        checked={consent.otp}
        onCheckedChange={(e) => setConsent({ ...consent, otp: !!e.checked })}
      >
        <Text fontSize="xs">
          When registering for WhatsApp activation, please use your new Fuzey
          number and opt for verification via text as the code will be shown
          here.
        </Text>
      </Checkbox>
      <Button
        asChild={true}
        colorPalette="green"
        alignSelf="center"
        disabled={
          !consent.billing || !consent.freshFacebookAccount || !consent.otp
        }
      >
        <ConnectButton
          label="Connect"
          callback={() => {}}
          queryParameters={{
            partner: merchant.public_id,
            name: merchant.name,
            redirect_url: `${window.location.origin}/public/callback/success?integration=whatsapp`,
          }}
          partnerId={partnerId!}
          onAfterClick={() => {
            setConsentGiven(true);
          }}
        />
      </Button>
    </VStack>
  );
};

export const Post360DialogRedirect = () => {
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const [newMessage, setNewMessage] = useState<string | React.ReactNode>("");
  const { merchant } = useAppSelector((state) => state.merchant);

  const regex = new RegExp("(\\d\\d\\d\\d\\d\\d)|(\\d\\d\\d-\\d\\d\\d)");

  const copyToClipboard = (otp: string) => {
    navigator.clipboard.writeText(otp);
  };

  const handleNewMessageEvent = (args: SocketMessage) => {
    if (args.message.is_incoming && regex.test(args.message.body)) {
      const message = args.message.body;
      const regExpExec = regex.exec(message)!;
      const otp = regExpExec[0];

      setNewMessage(
        <Text>
          {message.substring(0, regExpExec.index)}
          <Text
            as={Link}
            onClick={() => copyToClipboard(otp.replace("-", ""))}
            position="relative"
          >
            {otp}
            <Text
              as={MdContentCopy}
              display="inline-block"
              ml={0.5}
              css={{ "& svg": { height: "0.8rem", width: "0.8rem" } }}
            />
          </Text>
          {message.substring(regExpExec.index + otp.length, message.length)}
        </Text>
      );
    }
  };

  const inboundMessageListener = (args: SocketMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    if (args.message) {
      handleNewMessageEvent(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
      toaster.create({
        type: "error",
        title: "Couldn't receive a message.",
        description: "Feel free to skip this step for now then.",
      });
    }
  };

  useEffect(() => {
    addEventHandler("inbound_message", inboundMessageListener);

    return () => {
      removeEventHandler("inbound_message", inboundMessageListener);
    };
  }, [addEventHandler, removeEventHandler]);

  return !newMessage ? (
    <Alert status="info">
      Please use your new number{" "}
      {merchant.channels.find((c) => c.channelName === "sms")?.identifier} for
      WhatsApp sign up and opt for verification via text. We&apos;ll show you
      the code here when it arrives!
    </Alert>
  ) : (
    <MessagePreviewSnippet newMessage={newMessage} />
  );
};
