import { Icon, Input, useBreakpointValue } from "@chakra-ui/react";
import { InputGroup } from "components/ui/input-group";
import useAnalytics from "hooks/use-analytics";
import React, { ChangeEvent, LegacyRef, useEffect, useRef } from "react";
import { LuSearch } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface ContactsSearchProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isDisabled: boolean;
}

const ContactsSearch = ({
  onChange,
  value,
  isDisabled,
}: ContactsSearchProps) => {
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { track } = useAnalytics();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!isDisabled && !isBaseSize) {
      inputRef?.current?.focus();
    }
  }, [isDisabled]);

  return (
    <InputGroup
      flex="0 0 1px"
      startElement={<Icon boxSize={4} as={LuSearch} color="currentColor" />}
    >
      <Input
        id="search-contact"
        colorPalette={colorScheme}
        ref={inputRef as LegacyRef<HTMLInputElement> | undefined}
        disabled={isDisabled}
        placeholder="John Doe"
        onChange={onChange}
        onClick={() => {
          track("customers_search_bar_focused", {
            conversation_id: activeConversationId || null,
          });
        }}
        value={value}
        borderRadius="63px"
      />
    </InputGroup>
  );
};

export default ContactsSearch;
