import { Flex, Icon, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import VehicleDomain from "entities/domain/vehicle";
import React, { useCallback, useEffect, useState } from "react";
import { LuArrowUpRight, LuCar } from "react-icons/lu";
import { ReactSVG } from "react-svg";

interface CarThumbnailProps {
  vehicle: VehicleDomain;
  colorMode?: "dark" | "light";
  orientation?: "horizontal" | "vertical";
  showOpenIcon?: boolean;
  noIcon?: boolean;
}

const AVERAGE_CAR_MODEL_LENGTH = 13; // According to ChatGPT

const CarThumbnailContent = ({
  vehicle,
  colorMode: forcedColorMode,
  orientation = "horizontal",
  showOpenIcon = true,
  noIcon = false,
}: CarThumbnailProps) => {
  const { colorMode } = useColorMode();
  const [usedColorMode, setUsedColorMode] = useState<"dark" | "light">(
    forcedColorMode || (colorMode as "dark" | "light") || "light"
  );

  const fallbackIcon = useCallback(() => <Icon as={LuCar} />, []);

  useEffect(() => {
    setUsedColorMode(
      forcedColorMode || (colorMode as "dark" | "light") || "light"
    );
  }, [colorMode]);

  return (
    <Flex
      gridGap={1}
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="start"
    >
      {noIcon ? null : (
        <Icon
          asChild={true}
          boxSize={6}
          css={{
            "& svg": {
              width: 6,
              height: 6,
            },
            "& path": {
              fill: usedColorMode === "dark" ? "gray.50" : "gray.800",
            },
          }}
          // fallback={fallbackIcon}
        >
          <ReactSVG src={vehicle.getIconSource() || ""} />
        </Icon>
      )}
      <Text
        fontWeight="hairline"
        fontSize="xs"
        whiteSpace="nowrap"
        overflow="hidden"
        width="100%"
        textAlign="center"
        textOverflow="ellipsis"
      >
        {vehicle.model.slice(0, AVERAGE_CAR_MODEL_LENGTH).trim() ||
          vehicle.year}
      </Text>
      {showOpenIcon ? (
        <Icon
          as={LuArrowUpRight}
          boxSize={4}
          color={usedColorMode === "dark" ? "gray.50" : "gray.800"}
        />
      ) : null}
    </Flex>
  );
};

export default CarThumbnailContent;
