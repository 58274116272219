import {
  Button as ChakraButton,
  Flex,
  Icon,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { PaymentStatus } from "entities/domain/payments/payment-domain";
import React from "react";
import { LuDownload } from "react-icons/lu";

interface FinalPaymentProps {
  onClick: () => void;
  amount?: number | undefined;
  currency?: string | undefined;
  status?: PaymentStatus | undefined;
  merchantName?: string | undefined;
  invoiceUrl?: string | undefined;
}

const FinalPaymentPage = ({
  onClick,
  amount,
  currency,
  status,
  merchantName,
  invoiceUrl,
}: FinalPaymentProps) => {
  const hasInvoice = invoiceUrl !== undefined;

  switch (status) {
    case PaymentStatus.PAID:
      return (
        <>
          <Flex justify="center" pt={10}>
            <Image src="/paid-payment.svg" alt="paid-icon" />
          </Flex>

          <Text fontSize="20px" fontWeight={700} textAlign="center" mt="15px">
            Thank you!
          </Text>

          <Text
            bgGradient="linear(to-t, #3559FF 10.6%, #36B2FA 88.1%)"
            bgClip="text"
            fontSize="lg"
            fontWeight="extrabold"
            textAlign="center"
          >
            {getSymbolFromCurrency(currency!)}
            {amount?.toFixed(2)}
          </Text>

          <Text fontSize="14px" textAlign="center" mt="15px">
            {" "}
            Your payment has been made successfully.
          </Text>
          <Link
            href={invoiceUrl}
            download
            display={hasInvoice ? "flex" : "none"}
            justifyContent="center"
            pt="5px"
          >
            <Text color="black">Download invoice</Text>
            <Icon as={LuDownload} ml="12px" />
          </Link>
          <Flex justifyContent="center" mt="20px">
            <ChakraButton
              w="289px"
              color="#fff"
              bgGradient="linear(306.44deg, #3559FF 10.6%, #36B2FA 88.1%)"
              borderRadius="75px"
              _hover={{ opacity: 0.5 }}
              _focus={{ outline: "none" }}
              onClick={onClick}
            >
              <Text color="white" fontWeight="bold">
                Close
              </Text>
            </ChakraButton>
          </Flex>
        </>
      );
    case PaymentStatus.CANCELED:
      return (
        <>
          <Flex justify="center" pt={10}>
            <Image
              src="/failed-payment.svg"
              alt="failed icon"
              height="50px"
              width="50px"
            />
          </Flex>
          <Text fontSize="20px" fontWeight={700} textAlign="center" mt="15px">
            Payment Declined
          </Text>

          <Text
            bgGradient="linear(to-t, #3559FF 10.6%, #36B2FA 88.1%)"
            bgClip="text"
            fontSize="lg"
            fontWeight="extrabold"
            textAlign="center"
          >
            {getSymbolFromCurrency(currency!)}
            {amount?.toFixed(2)}
          </Text>
          <Text fontSize="14px" textAlign="center" mt="15px" mx={5}>
            {" "}
            Your payment to {merchantName} was declined.
          </Text>
          <Flex justifyContent="center" mt="20px">
            <ChakraButton
              w="289px"
              color="#fff"
              bgGradient="linear(306.44deg, #3559FF 10.6%, #36B2FA 88.1%)"
              borderRadius="75px"
              _hover={{ opacity: 0.5 }}
              _focus={{ outline: "none" }}
              onClick={onClick}
            >
              <Text color="white" fontWeight="bold">
                Close
              </Text>
            </ChakraButton>
          </Flex>
        </>
      );
    default:
      return (
        <>
          <Flex justify="center" pt={10}>
            <Image
              src="/question-payment.svg"
              alt="unknown icon"
              height="50px"
              width="50px"
            />
          </Flex>
          <Text
            fontSize="20px"
            fontWeight={700}
            textAlign="center"
            mt="15px"
            mx={5}
          >
            Payment Issue
          </Text>
          <Text
            bgGradient="linear(to-t, #3559FF 10.6%, #36B2FA 88.1%)"
            bgClip="text"
            fontSize="lg"
            fontWeight="extrabold"
            textAlign="center"
          >
            {getSymbolFromCurrency(currency!)}
            {amount?.toFixed(2)}
          </Text>

          <Text fontSize="14px" textAlign="center" mt="15px" mx={5}>
            {" "}
            There was an issue with your payment to {merchantName}.
          </Text>

          <Flex justifyContent="center" mt="20px">
            <ChakraButton
              w="289px"
              color="#fff"
              bgGradient="linear(306.44deg, #3559FF 10.6%, #36B2FA 88.1%)"
              borderRadius="75px"
              _hover={{ opacity: 0.5 }}
              _focus={{ outline: "none" }}
              onClick={onClick}
            >
              <Text color="white" fontWeight="bold">
                Close
              </Text>
            </ChakraButton>
          </Flex>
        </>
      );
  }
};

export default FinalPaymentPage;
