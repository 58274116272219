import {
  Box,
  Button,
  Icon,
  IconButton,
  Portal,
  Separator,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";

import React, { ReactElement, memo, useEffect, useState } from "react";
import { LuChevronDown } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import { isIOSPlatform } from "util/methods";
import ConfirmationDialog from "./ConfirmationDialog";

export interface PopoverAction {
  name: string;
  callback: () => Promise<void> | void;
  icon?: React.FC;
  iconStroke?: boolean;
  shouldShowConfirmation?: boolean;
  confirmationText?: string;
  confirmationSubmitText?: string;
}

interface FuzeyPopoverProps {
  triggerIcon?: ReactElement;
  triggerSize?: "lg" | "md" | "sm" | "xs";
  dropShadow?: boolean;
  actions: PopoverAction[];
  isHidden?: boolean;
}

const FuzeyPopover = memo(
  ({
    triggerIcon,
    actions,
    triggerSize,
    dropShadow = false,
    isHidden = false,
  }: FuzeyPopoverProps) => {
    if (actions.length === 0) {
      return null;
    }

    const defaultConfirmationText =
      "Are you sure you want to perform this action?";
    const defaultConfirmationSubmitText = "Yes";
    const [showConfirmationModal, setShowConfirmationModal] =
      useState<boolean>(false);
    const [confirmationCallback, setConfirmationCallback] = useState<
      () => Promise<void> | void
    >(() => () => {});
    const [confirmationText, setConfirmationText] = useState<string>(
      defaultConfirmationText
    );
    const { colorMode } = useColorMode();
    const [confirmationSubmitText, setConfirmationSubmitText] =
      useState<string>(defaultConfirmationSubmitText);
    const { open, onToggle, onClose } = useDisclosure();
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const { colorScheme } = useAppSelector((state) => state.theme);
    const [touchTimeout, setTouchTimeout] = useState<number | undefined>();
    const [isOverlayShown, setIsOverlayShown] = useState<boolean>(false);

    useEffect(() => {
      const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
        setIsOverlayShown(open);
      }, 200);
      return () => {
        clearTimeout(timeout);
      };
    }, [open]);

    return (
      <>
        <PopoverRoot
          open={open}
          onOpenChange={({ open: newIsOpen }) => {
            if (!newIsOpen) {
              onClose();
            }
          }}
          closeOnInteractOutside={true}
          lazyMount={true}
        >
          <PopoverTrigger asChild={true}>
            {isBaseSize && dropShadow && isIOSPlatform() ? (
              <Button
                position="absolute"
                width="100%"
                height="100%"
                bgColor="transparent"
                borderRadius="none"
                unstyled={true}
                top={0}
                left={0}
                onTouchStart={(e) => {
                  setTouchTimeout(
                    window.setTimeout(() => {
                      e.stopPropagation();
                      onToggle();
                    }, 500)
                  );
                }}
                onClick={(e) => e.preventDefault()}
                onContextMenu={(e) => e.preventDefault()}
                onTouchEnd={() => {
                  clearTimeout(touchTimeout);
                }}
                onTouchMove={() => clearTimeout(touchTimeout)}
              />
            ) : (
              <IconButton
                aria-label="Dropdown Trigger"
                borderRadius="full"
                pointerEvents={isHidden ? "none" : "initial"}
                opacity={isHidden ? 0 : 1}
                variant="ghost"
                colorPalette={colorScheme}
                size={triggerSize || "xs"}
                onClick={(e) => {
                  e.stopPropagation();
                  onToggle();
                }}
              >
                {triggerIcon || <LuChevronDown />}
              </IconButton>
            )}
          </PopoverTrigger>
          <Portal>
            {isBaseSize && dropShadow ? (
              <Box
                display={isOverlayShown ? "initial" : "none"}
                position="absolute"
                width="100%"
                height="100%"
                left={0}
                top={0}
                bgColor="blackAlpha.500"
                opacity={1}
                onTouchStart={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                onContextMenu={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
              />
            ) : null}
            <PopoverContent width="fit-content">
              <PopoverBody zIndex={999} px={0}>
                {actions.map((a, index) => (
                  <div key={a.name}>
                    <Button
                      colorPalette={colorScheme}
                      px={5}
                      variant="ghost"
                      fontWeight="normal"
                      w="100%"
                      borderRadius="none"
                      justifyContent="start"
                      mx={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (a.shouldShowConfirmation) {
                          setConfirmationCallback(() => {
                            return () => {
                              a.callback();
                              onClose();
                              setConfirmationCallback(() => () => {});
                              setConfirmationText(defaultConfirmationText);
                              setConfirmationSubmitText(
                                defaultConfirmationSubmitText
                              );
                            };
                          });

                          if (a.confirmationText) {
                            setConfirmationText(a.confirmationText);
                          }

                          if (a.confirmationSubmitText) {
                            setConfirmationSubmitText(a.confirmationSubmitText);
                          }

                          setShowConfirmationModal(true);

                          return;
                        }

                        a.callback();
                        onClose();
                      }}
                    >
                      {a.icon ? (
                        <Icon
                          as={a.icon}
                          color={
                            !a.iconStroke
                              ? colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.600`
                              : colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.600`
                          }
                        />
                      ) : null}{" "}
                      {a.name}
                    </Button>
                    {index === actions.length - 1 ? null : <Separator />}
                  </div>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </PopoverRoot>
        <ConfirmationDialog
          isOpen={showConfirmationModal}
          setIsOpen={setShowConfirmationModal}
          messageText={confirmationText}
          buttonText={confirmationSubmitText}
          confirmationCallback={confirmationCallback}
        />
      </>
    );
  }
);

export default FuzeyPopover;
