export default class AdminKeyloopAccountDomain {
  enterpriseId: string;

  storeId: string;

  accountName: string | null;

  merchantIds: number[];

  serviceMerchantId: number | null;

  constructor(
    enterpriseId: string,
    storeId: string,
    accountName: string | null,
    merchantIds: number[],
    serviceMerchantId: number | null
  ) {
    this.enterpriseId = enterpriseId;
    this.storeId = storeId;
    this.accountName = accountName;
    this.merchantIds = merchantIds;
    this.serviceMerchantId = serviceMerchantId;
  }
}
