import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import Input, { InputProps } from "./Input";
import LazyPhoneInput, {
  DefaultInputComponentProps,
  FeatureProps as PhoneInputPropsLib,
} from "components/shared/LazyPhoneInput";

type PhoneInputProps = {
  setPhoneNumber?: (value: string) => void;
} & PhoneInputPropsLib<DefaultInputComponentProps> &
  InputProps;

const PhoneInput: ForwardRefRenderFunction<any, any> = (
  { setPhoneNumber, onChange, error, ...rest },
  ref
) => {
  return (
    <Input
      asChild={true}
      css={{
        "& .PhoneInputInput": {
          backgroundColor: "transparent",
          border: 0,
          _autofill: {
            boxShadow: `0 0 0px 20px transparent inset`,
            transition: "background-color 5000s ease-in-out 0s",
          },
        },
        "& .PhoneInputInput::placeholder": {
          color: rest.placeholderColor,
        },
      }}
      ref={ref}
      {...rest}
    >
      <LazyPhoneInput
        international={true}
        defaultCountry="GB"
        error={error}
        style={
          error && {
            borderColor: "#E53E3E",
            boxShadow: "0 0 0 1px #e53e3e",
          }
        }
        onChange={
          ((e: any) => {
            if (setPhoneNumber) {
              setPhoneNumber(e?.toString());
            }
            // thanks to react-phone-number-input that changed the default onChange event type to string
            // we need to work around it to have Chakra + react hook form working as expected
            if (onChange) {
              onChange({
                target: {
                  name: rest.name,
                  type: rest.type,
                  value: e?.toString(),
                },
              } as ChangeEvent<HTMLInputElement>);
            }
          }) as any
        }
      />
    </Input>
  );
};

export default forwardRef(PhoneInput);
