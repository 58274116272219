import React, { useEffect, useState } from "react";

import {
  TagSelectOption,
  TagsMenuList,
} from "components/modals/tags/UpdateTags";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import { useColorMode } from "components/ui/color-mode";
import { tagToTagOption } from "entities/transformers/tags-transformer";
import Select, { MultiValue, SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getSelectStylesForQueryBuilder } from "util/methods";

interface AvailableTagOption {
  value: string;
  label: string;
}

const TagFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { tags } = useAppSelector((state) => state.tags);

  const [availableTagOptions, setAvailableTagOptions] = useState<
    MultiValue<AvailableTagOption>
  >(tags.map(tagToTagOption));

  const getSelectedTag = (tagId: string): AvailableTagOption | null => {
    if (!tagId) {
      return null;
    }

    const selectedTag = tags.find((tag) => tag.id === tagId);

    if (!selectedTag) {
      return null;
    }

    return tagToTagOption(selectedTag);
  };

  const [selectedTagOptionsValue, setSelectedTagOptionsValue] = useState<
    SingleValue<AvailableTagOption>
  >(getSelectedTag(value));

  useEffect(() => {
    if (value === selectedTagOptionsValue?.value) {
      return;
    }

    setSelectedTagOptionsValue(getSelectedTag(value));
  }, [value]);

  useEffect(() => {
    if (!tags.length) {
      return;
    }

    setAvailableTagOptions(tags.map(tagToTagOption));
  }, [tags]);

  return (
    <Select
      id={id}
      isMulti={false}
      placeholder="select tag"
      isClearable={false}
      isSearchable={true}
      value={selectedTagOptionsValue}
      onChange={(newValue) => {
        if (newValue && !("value" in newValue)) {
          return;
        }

        onChange(newValue?.value || "");
      }}
      options={availableTagOptions}
      components={{
        MenuList: TagsMenuList as any,
        Option: TagSelectOption,
      }}
      styles={getSelectStylesForQueryBuilder(
        colorScheme,
        (colorMode as "light" | "dark") || "light"
      )}
    />
  );
};

export default TagFieldInput;
