import {
  Button,
  Flex,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { Switch } from "components/ui/switch";
import NotificationDomain from "entities/domain/notifications/domain";
import useNotificationsStore from "hooks/use-notifications-store";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import NotificationItem from "./NotificationItem";

interface NotificationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationsModal = ({ isOpen, onClose }: NotificationsModalProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { loading: isLoadingMerchant } = useAppSelector(
    (state) => state.merchant
  );
  const { loading: isLoadingNotifications, notifications } = useAppSelector(
    (state) => state.notifications
  );
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { markAllNotificationsAsRead } = useNotificationsStore();

  const [isUnreadOnly, setIsUnreadOnly] = useState<boolean>(false);
  const [filterNotifications, setFilterNotifications] = useState<
    NotificationDomain[]
  >([]);

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  useEffect(() => {
    if (isUnreadOnly) {
      setFilterNotifications(
        notifications.filter((notification) => !notification.read)
      );
    } else {
      setFilterNotifications(notifications);
    }
  }, [notifications, isUnreadOnly]);

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }} size={isBaseSize ? "full" : "md"}>
      <DialogBackdrop  />
      <DialogContent>
        <DialogHeader>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Text fontSize="lg" fontWeight="bold">
              Notifications
            </Text>
            <Spacer />
            <Text fontSize="sm" mr={1}>
              Unreads
            </Text>
            <Switch
              size="sm"
              checked={isUnreadOnly}
              colorPalette={colorScheme}
              onCheckedChange={(e) => setIsUnreadOnly(e.checked)}
            />
          </Flex>
        </DialogHeader>
        <DialogBody     maxH="60vh" overflowY="auto">
          {filterNotifications.length === 0 ? (
            <Text>No new notifications</Text>
          ) : (
            filterNotifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))
          )}
        </DialogBody>
        <DialogFooter    display="flex" w="100%" justifyContent="space-between">
          <Button colorPalette={colorScheme} variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button
            mr={1}
            colorPalette={colorScheme}
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default NotificationsModal;
