import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Text } from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { useColorMode } from "components/ui/color-mode";
import debounce from "debounce-promise";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import React, { useEffect, useRef, useState } from "react";
import {
  GroupBase,
  MultiValue,
  OptionProps,
  Props as SelectProps,
  SingleValue,
  components,
} from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { numberOfAllAgentsPerLoad } from "util/constants";
import { getReactSelectStyles, getUniqueListBy } from "util/methods";

type SetSelectedAgent = React.Dispatch<
  React.SetStateAction<NewAgentDomain | null>
>;

type SetSelectedAgents = React.Dispatch<React.SetStateAction<NewAgentDomain[]>>;

interface SelectAgentProps {
  isMulti?: false;
  selectedAgent: NewAgentDomain | null;
  setSelectedAgent: SetSelectedAgent;
}

interface SelectAgentsProps {
  isMulti: true;
  selectedAgents: NewAgentDomain[];
  setSelectedAgents: SetSelectedAgents;
}

interface AgentOptionTypes {
  value: number;
  label: string;
  avatar: string;
  fullName: string;
}

type SelectAgentType =
  | (SelectAgentProps & SelectProps<AgentOptionTypes>)
  | (SelectAgentsProps & SelectProps<AgentOptionTypes>);

const transformAgentToAgentOption = (agent: NewAgentDomain) => ({
  value: agent.id,
  label: `${agent.getFullName()} (${agent.email})`,
  avatar: agent.getPicture(),
  fullName: agent.getFullName(),
});

const AgentColumn = ({ item }: { item: AgentOptionTypes }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      px={8}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <ProfileAvatar profilePicture={item.avatar} name={item.fullName} />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const AgentOption = function (props: OptionProps<AgentOptionTypes>) {
  return (
    <components.Option {...props}>
      <AgentColumn item={props.data} />
    </components.Option>
  );
};

const SelectAgent = (props: SelectAgentType) => {
  let selectedAgents: NewAgentDomain[] | null = null;
  let setSelectedAgents: SetSelectedAgents | null = null;
  let selectedAgent: NewAgentDomain | null = null;
  let setSelectedAgent: SetSelectedAgent | null = null;

  if (props.isMulti === true) {
    selectedAgents = props.selectedAgents;
    setSelectedAgents = props.setSelectedAgents;
  } else {
    selectedAgent = props.selectedAgent;
    setSelectedAgent = props.setSelectedAgent;
  }

  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [cache, clearCache] = useState<object>({});
  const [previousSearchText, setPreviousSearchText] = useState<string>("");
  const [agents, setAgents] = useState<NewAgentDomain[]>([]);
  const [agentOptions, setAgentOptions] = useState<AgentOptionTypes[]>([]);

  const lastRequest = useRef<object>({});

  const hasMoreAgents = (
    agentsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (agentsAmount < numberOfAllAgentsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfAllAgentsPerLoad) {
      return false;
    }

    return true;
  };

  const fetchMoreAgents: LoadOptions<
    AgentOptionTypes,
    GroupBase<AgentOptionTypes>,
    unknown
  > = async (searchText = "") => {
    lastRequest.current = {};
    const currentRequest = lastRequest.current;

    let offset = agentOptions?.length || 0;

    if (searchText !== previousSearchText) {
      offset = 0;
    }

    setPreviousSearchText(searchText);

    const nextAgents = await AdminService.getAllAgents(
      auth0Context,
      searchText,
      offset
    );

    if (currentRequest !== lastRequest.current) {
      return {
        options: agentOptions,
        hasMore: hasMoreAgents(agentOptions.length, 0),
      };
    }

    const nextOptions = nextAgents.map<AgentOptionTypes>(
      transformAgentToAgentOption
    );
    const newAgents: NewAgentDomain[] =
      offset !== 0 ? agents.concat(nextAgents) : nextAgents;

    const newAgentOptions: AgentOptionTypes[] =
      offset !== 0 ? agentOptions.concat(nextOptions) : nextOptions;

    setAgentOptions(newAgentOptions);
    setAgents(newAgents);

    return {
      options: nextOptions,
      hasMore: hasMoreAgents(newAgents.length, nextAgents?.length || 0),
    };
  };

  const debouncedFetchMoreAgents = debounce(
    fetchMoreAgents,
    200
  ) as LoadOptions<AgentOptionTypes, GroupBase<AgentOptionTypes>, unknown>;

  return (
    <AsyncPaginate
      cacheUniqs={[cache]}
      isClearable={true}
      loadOptions={debouncedFetchMoreAgents}
      placeholder="Select Agents"
      onChange={(
        a: SingleValue<AgentOptionTypes> | MultiValue<AgentOptionTypes>
      ) => {
        if (setSelectedAgents) {
          const agentIds = (a as AgentOptionTypes[])?.map((o) => o.value) || [];

          setSelectedAgents((prev: NewAgentDomain[]) => {
            const previouslySelectedAndAllCurrentlySearchedMerchants = [
              ...prev, // because it may be so that previously selected agent doesn't exist in current merchants array anymore
              ...agents,
            ];

            const agentsWithoutDuplicates = getUniqueListBy<NewAgentDomain>(
              previouslySelectedAndAllCurrentlySearchedMerchants,
              "id"
            );

            return (
              agentsWithoutDuplicates.filter((agent) =>
                agentIds.includes(agent.id)
              ) || null
            );
          });
        } else if (setSelectedAgent) {
          setSelectedAgent(
            agents.find(
              (agent) => (a as AgentOptionTypes)?.value === agent.id
            ) || null
          );
        }
      }}
      onMenuClose={() => {
        clearCache({});
        setAgentOptions([]);
        setAgents([]);
        setPreviousSearchText("");
      }}
      value={
        selectedAgents
          ? selectedAgents.map(transformAgentToAgentOption)
          : selectedAgent
          ? transformAgentToAgentOption(selectedAgent)
          : undefined
      }
      components={{
        Option: AgentOption,
      }}
      styles={{
        ...getReactSelectStyles(colorMode || "light", colorScheme),
        container: (provided: any) => ({
          ...provided,
          width: "100%",
        }),
      }}
      {...props}
    />
  );
};

export default SelectAgent;
