import { IconButton, VStack } from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import KeyloopAccount from "entities/domain/keyloop-account";
import React from "react";
import { LuArrowLeft } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import EditAccount from "./EditAccount";
import SelectAccount from "./SelectAccount";

interface KeyloopProps {}

const Keyloop = (_props: KeyloopProps) => {
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const navigate = useNavigate();
  const [selectedKeyloopAccount, setSelectedKeyloopAccount] =
    React.useState<KeyloopAccount | null>(null);

  return (
    <DialogRoot
      open={true}
      closeOnEscape={true}
      closeOnInteractOutside={true}
      size="lg"
      onOpenChange={({ open: newIsOpen }) => {
        if (newIsOpen) {
          return;
        }
        navigate(`/${merchant.id}/settings/integrations`);
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        {selectedKeyloopAccount ? (
          <IconButton
            position="absolute"
            left={2}
            top={2}
            borderRadius="full"
            variant="ghost"
            aria-label="Back"
            colorPalette="gray"
            onClick={() => setSelectedKeyloopAccount(null)}
          >
            <LuArrowLeft />
          </IconButton>
        ) : null}
        <DialogHeader textAlign="center">
          {selectedKeyloopAccount ? "Edit Account" : "Select Account"}
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <VStack gap={2} w="100%">
            {selectedKeyloopAccount ? (
              <EditAccount selectedKeyloopAccount={selectedKeyloopAccount} />
            ) : (
              <SelectAccount
                setSelectedKeyloopAccount={setSelectedKeyloopAccount}
              />
            )}
          </VStack>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

export default Keyloop;
