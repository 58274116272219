import { defineRecipe } from "@chakra-ui/react";

export default defineRecipe({

});
// const ToolTipStyles = {
//   baseStyle: {
//     display: isMobileApp() || isMobile() ? "none" : "initial",
//   },
// } as StyleConfig;
