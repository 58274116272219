import React, { useEffect, useState } from "react";

import ChannelOption from "components/shared/ChannelOption";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import { useColorMode } from "components/ui/color-mode";
import { transformFromAvailableChannelsToOptions } from "entities/transformers/admin-merchant-transformer";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getSelectStylesForQueryBuilder } from "util/methods";

interface AvailableChannelOption {
  value: string;
  label: string;
}

const ChannelFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const channelOptions: AvailableChannelOption[] | undefined = [
    ...new Map(
      merchant.channels.map((channel) => [channel.channelName, channel])
    ).values(),
  ].map(transformFromAvailableChannelsToOptions);

  const getSelectedChannel = (
    channelName: string
  ): AvailableChannelOption | null => {
    if (!channelName) {
      return null;
    }

    const selectedChannel = merchant.channels.find(
      (channel) => channel.channelName === channelName
    );

    if (!selectedChannel) {
      return null;
    }

    return transformFromAvailableChannelsToOptions(selectedChannel);
  };

  const [selectedChannelOptionsValue, setSelectedChannelOptionsValue] =
    useState<SingleValue<AvailableChannelOption>>(getSelectedChannel(value));

  useEffect(() => {
    setSelectedChannelOptionsValue(getSelectedChannel(value));
  }, [value]);

  return (
    <Select
      id={id}
      isMulti={false}
      placeholder="select channel"
      isClearable={false}
      isSearchable={true}
      value={selectedChannelOptionsValue}
      onChange={(newValue) => {
        if (newValue && !("value" in newValue)) {
          return;
        }

        onChange(newValue?.value || "");
      }}
      options={channelOptions}
      components={{
        Option: ChannelOption,
      }}
      styles={getSelectStylesForQueryBuilder(colorScheme, colorMode || "light")}
    />
  );
};

export default ChannelFieldInput;
