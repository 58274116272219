import { Box, IconButton } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { MenuContent, MenuRoot, MenuTrigger } from "components/ui/menu";
import React, { ReactNode } from "react";

interface FooterNestedNavLinksProps {
  children: ReactNode; // MobileNavButton
  hasNotifications?: boolean;
  mainIcon: any;
}

const FooterNestedNavLinks = ({
  children,
  mainIcon,
  hasNotifications = false,
}: FooterNestedNavLinksProps) => {
  const { colorMode } = useColorMode();
  return (
    <MenuRoot>
      <MenuTrigger asChild={true}>
        <IconButton unstyled={true} position="relative">
          {mainIcon}
          {hasNotifications ? (
            <Box
              position="absolute"
              top="-0.25rem"
              right="-0.25rem"
              zIndex="1"
              borderRadius="full"
              height="1rem"
              width="1rem"
              bgColor={colorMode === "dark" ? "red.200" : "red.500"}
            />
          ) : null}
        </IconButton>
      </MenuTrigger>
      <MenuContent zIndex={999999} opacity={1}>
        {children}
      </MenuContent>
    </MenuRoot>
  );
};

export default FooterNestedNavLinks;
