import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Flex, Icon, Text } from "@chakra-ui/react";

import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import AlertIcon from "components/shared/AlertIcon";
import FuzeyPopover, { PopoverAction } from "components/shared/FuzeyPopover";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useConversationsStore from "hooks/use-conversations-store";
import useMessagesStore from "hooks/use-messages-store";
import React, { memo, useMemo } from "react";
import {
  LuCircleCheck,
  LuEye,
  LuEyeOff,
  LuLock,
  LuLockOpen,
  LuPointer,
  LuPointerOff,
  LuTags,
  LuUserRoundCheck,
} from "react-icons/lu";

import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  updateConversationIsSubscribed,
  updateConversationSelection,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";

interface SnippetBodyProps {
  lastMessageId: number | null;
  unreadCount: number;
  conversationId: number;
  isConversationOpen: boolean;
  conversationChannel: ConversationChannel;
  assignedAgentId: number | null;
  customerId: number;
  customerChannelId: string;
  previewText: string;
  isSubscribed: boolean;
  isLastMessageUndelivered: boolean;
  isAnyUnread: boolean;
  onAssignOpen: (conversationId: number) => void;
  onUpdateTagsOpen: (conversationId: number) => void;
}

const getUnreadCountForDisplay = (unreadCount: number): string => {
  return unreadCount > 5 ? "5+" : `${unreadCount}`;
};

const SnippetBody = ({
  conversationId,
  isConversationOpen,
  conversationChannel,
  assignedAgentId,
  lastMessageId,
  unreadCount,
  isLastMessageUndelivered,
  isAnyUnread,
  isSubscribed,
  customerId,
  customerChannelId,
  previewText,
  onAssignOpen,
  onUpdateTagsOpen,
}: SnippetBodyProps) => {
  const dispatch = useAppDispatch();

  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { selectedConversationIds, activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { closeOrOpenConversation } = useConversationsStore();
  const { markConversationAsUnread, markConversationAsRead } =
    useMessagesStore();

  const popoverActions = useMemo<PopoverAction[]>(() => {
    const newPopoverActions: PopoverAction[] = [];

    if (lastMessageId) {
      newPopoverActions.push(
        unreadCount
          ? {
              icon: LuEye,
              name: "Mark Read",
              callback: () => {
                markConversationAsRead(
                  conversationId,
                  lastMessageId,
                  currentAgent!.id
                );
              },
            }
          : {
              icon: LuEyeOff,
              name: "Mark Unread",
              callback: () => {
                if (activeConversationId) {
                  navigate({
                    pathname: `/${merchant.id}/inbox/`,
                    search: createSearchParams(search).toString(),
                  });
                }
                setTimeout(() => {
                  markConversationAsUnread(
                    conversationId,
                    lastMessageId,
                    currentAgent!.id
                  );
                }, 250);
              },
            }
      );
    }

    newPopoverActions.push({
      icon: isConversationOpen ? LuLock : LuLockOpen,
      name: `Mark ${isConversationOpen ? "Closed" : "Open"}`,
      callback: async () => {
        const updatedConversation = await closeOrOpenConversation(
          conversationId,
          !isConversationOpen,
          currentAgent!.id
        );

        if (!updatedConversation) {
          toaster.create({
            type: "error",
            title: `Failed to mark conversation as ${
              isConversationOpen ? "closed" : "open"
            }`,
          });

          return;
        }
      },
    });

    newPopoverActions.push({
      icon: LuUserRoundCheck,
      name: `Assign to`,
      callback: () => {
        onAssignOpen(conversationId);
      },
    });

    newPopoverActions.push({
      icon: LuTags,
      name: `Update Tags`,
      callback: () => {
        onUpdateTagsOpen(conversationId);
      },
    });

    newPopoverActions.push({
      icon: () => (
        <Icon
          as={!isSubscribed ? LuPointer : LuPointerOff}
          boxSize="1rem"
          color={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.600`
          }
        />
      ),
      name: `Mark ${isSubscribed ? "unsubscribed" : "subscribed"}`,
      callback: async () => {
        try {
          await ContactsService.updateContactChannel(
            auth0Context,
            merchant.groupId,
            customerId,
            customerChannelId,
            {
              is_subscribed: !isSubscribed,
            }
          );

          dispatch(
            updateConversationIsSubscribed({
              conversationId,
              isSubscribed: !isSubscribed,
            })
          );

          toaster.create({
            type: "success",
            title: `Successfully ${
              isSubscribed ? "unsubscribed from" : "subscribed to"
            } marketing!`,
          });
        } catch (_error: unknown) {
          toaster.create({
            type: "error",
            title: `Failed to ${
              isSubscribed ? "unsubscribe from" : "subscribe to"
            } marketing`,
          });
        }
      },
    });

    newPopoverActions.push({
      icon: LuCircleCheck,
      name: `Select`,
      callback: () => {
        dispatch(
          updateConversationSelection({ conversationId, isSelected: true })
        );
      },
    });

    return newPopoverActions;
  }, [
    isConversationOpen,
    assignedAgentId,
    unreadCount,
    lastMessageId,
    selectedConversationIds.length,
    merchant.groupId,
    customerId,
    isSubscribed,
  ]);

  return (
    <Flex
      display="flex"
      justifyContent="space-between"
      alignItems="start"
      height="100%"
      wrap="nowrap"
      css={{
        maskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
      }}
    >
      {conversationChannel === ConversationChannel.WHATSAPP &&
      !isLastMessageUndelivered &&
      previewText ? (
        <EditorUltra
          fontColor={colorMode === "dark" ? "gray.200" : "gray.500"}
          isScrollable={false}
          isEditable={false}
          maxHeight="1.5rem"
          backgroundColor="inherit"
          highlightUnknownCustomFields={false}
          defaultText={{
            value: previewText,
          }}
          channels={[ConversationChannel.WHATSAPP]}
          enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
        />
      ) : (
        <Text
          maxWidth="90%"
          lineClamp={2}
          color={colorMode === "dark" ? "gray.200" : "gray.500"}
        >
          {isLastMessageUndelivered
            ? "This message has failed to deliver"
            : previewText}
        </Text>
      )}
      <Flex alignItems="center" gridGap={1}>
        {isLastMessageUndelivered ? <AlertIcon /> : null}
        {isAnyUnread && !isLastMessageUndelivered ? (
          <Badge
            colorPalette={colorScheme}
            variant="solid"
            borderRadius="full"
            size="sm"
          >
            {getUnreadCountForDisplay(unreadCount)}
          </Badge>
        ) : null}
        <FuzeyPopover
          dropShadow={true}
          actions={popoverActions}
          isHidden={!!selectedConversationIds.length}
        />
      </Flex>
    </Flex>
  );
};

export default memo(SnippetBody);
