import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  FlexProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Alert } from "components/ui/alert";
import { CloseButton } from "components/ui/close-button";
import {
  SocketDeletedMessage,
  SocketMessage,
  SocketReplySuggestionConcluded,
  SocketReplySuggestionFailed,
  SocketReplySuggestionGenerated,
  SocketReplySuggestionQueued,
} from "entities/ISocketArgs";
import MessageDomain, {
  MessageDirection,
} from "entities/domain/conversations/message-domain";
import ReplySuggestionDomain, {
  ReplySuggestionStatus,
} from "entities/domain/reply_suggestion";
import { messageTransformFromDtoToDomain } from "entities/transformers/conversationTransformers";
import useDebounce from "hooks/use-debounce";
import useMessagesStore from "hooks/use-messages-store";
import { useWebSocket } from "hooks/use-socket";
import React, {
  Ref,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import {
  appendMessage,
  deleteMessage,
  messagesSelector,
  setAutoReplySuggestion,
} from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReplySuggestionService from "services/replySuggestion";
import useDeepCompareEffect from "use-deep-compare-effect";
import { numberOfMessagesPerLoad } from "util/constants";
import TypingZone from "../typing-zone";
import BackgroundSettings from "./BackgroundSettings";
import MessageRow from "./MessageRow";
import SkeletonOverlay from "./SkeletonOverlay";
import Unread from "./Unread";
import MessageComponent from "./message";
import EmailRaw from "./message/EmailRaw";
import Notification from "./notification";
import ReplySuggestion from "./reply_suggestion";

const shouldShowTail = (
  message: MessageDomain,
  index: number,
  currentMessagesArray: MessageDomain[]
) => {
  if (message.direction === MessageDirection.UNSPECIFIED) {
    return false;
  }

  const nextMessage = currentMessagesArray[index + 1];

  if (!nextMessage) {
    return true;
  }

  return (
    nextMessage.isIncoming !== message.isIncoming ||
    nextMessage.direction === MessageDirection.UNSPECIFIED
  );
};

const isFirstUnread = (
  index: number,
  messages: MessageDomain[] | undefined
): boolean => {
  if (!messages || !messages.length) {
    return false;
  }

  const message = messages[index];
  const previousIncomingMessage = messages
    .slice(0, index)
    .reverse()
    .filter((m) => m.isIncoming)[0];

  return (
    (message.isRead === false && !messages[index - 1]) ||
    (message.isRead === false &&
      (previousIncomingMessage?.isRead || true) === true)
  );
};

interface MessageListProps {
  isFirstLoad: boolean;
  onBackgroundChange: (backgroundStyles: FlexProps) => void;
}

const MessageList = ({ isFirstLoad, onBackgroundChange }: MessageListProps) => {
  const auth0Context = useAuth0();
  const emailMessageToShow = useAppSelector(
    (state) => state.messages.emailMessageToShow
  );
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeConversation, activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { markConversationAsRead, fetchMoreMessages } = useMessagesStore();
  const [inViewRef] = useInView({
    threshold: 0,
    onChange(inView: boolean) {
      if (inView) {
        setTimeout(() => {
          markConversationAsRead(
            activeConversation!.id,
            activeConversation!.messageId,
            currentAgent!.id
          );
        }, 500);
      }
    },
  });
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const mobileUnsubscribedAlert = useDisclosure({
    defaultOpen:
      isBaseSize && activeConversation && !activeConversation.isSubscribed,
  });
  const { isLoadingMessages, autoReplySuggestion: loadedReplySuggestion } =
    useAppSelector((state) => state.messages);
  const messages = useAppSelector(messagesSelector);
  const [lastActiveConversationId, setLastActiveConversationId] = useState<
    number | undefined
  >();
  const [previousMessagesLength, setPreviousMessagesLength] = useState<number>(
    (messages && messages.length) || 0
  );
  const listRef = useRef<ViewportListRef | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const debouncedIsInitialLoad = useDebounce(isInitialLoad, 50);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const { addEventHandler, removeEventHandler } = useWebSocket();

  const [newInboundMessage, setNewInboundMessage] = useState(
    {} as SocketMessage
  );
  const [newUpdateMessage, setNewUpdateMessage] = useState({} as SocketMessage);
  const [newDeleteMessage, setNewDeleteMessage] = useState(
    {} as SocketDeletedMessage
  );
  const [emailMessageIndex, setEmailMessageIndex] = useState<number | null>(
    null
  );
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);

  const rowRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const unreadRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [newConcludedReplySuggestion, setNewConcludedReplySuggestion] =
    useState({} as SocketReplySuggestionConcluded);
  const [newGeneratedReplySuggestion, setNewGeneratedReplySuggestion] =
    useState({} as SocketReplySuggestionGenerated);
  const [newQueuedReplySuggestion, setNewQueuedReplySuggestion] = useState(
    {} as SocketReplySuggestionQueued
  );
  const [newFailedReplySuggestion, setNewFailedReplySuggestion] = useState(
    {} as SocketReplySuggestionFailed
  );

  useEffect(() => {
    if (isLoadingMessages) {
      return;
    }

    setIsInitialLoad(false);
  }, [isLoadingMessages]);

  useEffect(() => {
    if (debouncedIsInitialLoad) {
      return;
    }

    setShouldShowSkeleton(false);
  }, [debouncedIsInitialLoad]);

  useEffect(() => {
    setIsInitialLoad(true);
    setShouldShowSkeleton(true);
  }, [activeConversationId]);

  const fetchMore = () => {
    if (!hasMoreMessages || !activeConversationId) {
      return;
    }

    fetchMoreMessages(
      activeConversationId,
      messages?.length || 0,
      merchant.id
    ).then((newMessages) => {
      setHasMoreMessages(newMessages.length === numberOfMessagesPerLoad);
    });
  };

  useEffect(() => {
    if (isLoadingMessages) {
      setPreviousMessagesLength(0);
      return;
    }

    if (!messages || !messages.length) {
      setPreviousMessagesLength(0);
      return;
    }

    setPreviousMessagesLength(messages.length);

    if (emailMessageToShow || emailMessageIndex !== null) {
      return;
    }

    const shiftLength = Math.abs(messages.length - previousMessagesLength);

    const scrollIndexPosition =
      listRef?.current?.getScrollPosition().index || messages.length; // default to bottom

    const hasScrolledTooFar =
      messages.length <= 20
        ? false
        : scrollIndexPosition < messages.length - 20;

    if (
      (shiftLength === 1 && !hasScrolledTooFar) ||
      (shiftLength > 1 && messages.length <= numberOfMessagesPerLoad)
    ) {
      scrollToBottom();
    } else if (shiftLength > 1 && emailMessageIndex === null) {
      listRef?.current?.scrollToIndex({
        index: messages.length - previousMessagesLength - 1,
        prerender: 20,
      });
    }
  }, [messages?.length]);

  useEffect(() => {
    if (emailMessageToShow) {
      const index = messages.findIndex((m) => m.id === emailMessageToShow.id);

      setEmailMessageIndex(index);

      return;
    } else if (
      !emailMessageToShow &&
      emailMessageIndex !== null &&
      listRef?.current
    ) {
      listRef?.current?.scrollToIndex({
        index: emailMessageIndex,
        prerender: 20,
      });

      setEmailMessageIndex(null);

      return;
    }
  }, [emailMessageToShow, listRef?.current]);

  useEffect(() => {
    if (!activeConversationId) {
      return;
    }

    fetchMoreMessages(activeConversationId, 0, merchant.id).then(
      (newMessages) => {
        setHasMoreMessages(newMessages.length === numberOfMessagesPerLoad);
      }
    );
  }, [activeConversationId, merchant.id]);

  useEffect(() => {
    if (!activeConversation) {
      return;
    }

    if (!messages || !messages.length) {
      return;
    }

    if (!listRef || !listRef.current) {
      return;
    }

    const newFirstUnreadMessage = messages.filter(
      (msg) => msg.isRead === false
    )[0];

    if (newFirstUnreadMessage && !newFirstUnreadMessage.isIncoming) {
      setTimeout(() => {
        markConversationAsRead(
          activeConversation!.id,
          activeConversation!.messageId,
          currentAgent!.id
        );
      }, 250);
    } else if (
      activeConversation.unreadCount &&
      !newFirstUnreadMessage &&
      hasMoreMessages
    ) {
      fetchMoreMessages(
        activeConversation!.id,
        messages?.length || 0,
        merchant.id
      ).then((newMessages) => {
        setHasMoreMessages(newMessages.length === numberOfMessagesPerLoad);
      });
    }
  }, [activeConversationId, activeConversation?.unreadCount, messages]);

  useEffect(() => {
    if (lastActiveConversationId !== activeConversationId) {
      setLastActiveConversationId(activeConversationId || undefined);
      setPreviousMessagesLength(0);
    }
  }, [activeConversationId]);

  function scrollToBottom(): void {
    if (!listRef || !listRef.current || !messages || !messages.length) {
      return;
    }

    listRef.current.scrollToIndex({
      index: messages.length - 1,
      prerender: 20,
    });
  }

  const [isListOverflown, setIsListOverflown] = useState<boolean>(false);

  useEffect(() => {
    if (!activeConversationId) {
      dispatch(setAutoReplySuggestion(undefined));
      return;
    }

    ReplySuggestionService.getReplySuggestion(
      auth0Context,
      merchant.id,
      activeConversationId
    ).then((suggestion) => {
      dispatch(setAutoReplySuggestion(suggestion || undefined));
    });
  }, [activeConversationId]);

  useLayoutEffect(() => {
    const { current } = scrollContainerRef;

    if (!current) {
      setIsListOverflown(false);
      return;
    }

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsListOverflown(hasOverflow);
    };

    if ("ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(current);
    }

    trigger();
  }, [scrollContainerRef]);

  const handleMessageUpdate = (args: SocketMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    setNewUpdateMessage(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newUpdateMessage).length === 0) {
      return;
    }

    const message: MessageDomain = messageTransformFromDtoToDomain(
      newUpdateMessage.message
    );

    if (
      activeConversationId === undefined ||
      activeConversationId !== message.conversationId
    ) {
      return;
    }

    dispatch(
      appendMessage({ conversationId: message.conversationId, message })
    );
  }, [newUpdateMessage]);

  const handleMessageDeleted = (args: SocketDeletedMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    setNewDeleteMessage(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newDeleteMessage).length === 0) {
      return;
    }

    dispatch(
      deleteMessage({
        messageId: newDeleteMessage.messageId,
        conversationId: newDeleteMessage.conversation_id,
      })
    );
  }, [newDeleteMessage]);

  useEffect(() => {
    if (Object.keys(newConcludedReplySuggestion).length === 0) {
      return;
    }

    if (!loadedReplySuggestion) {
      return;
    }

    const suggestion = newConcludedReplySuggestion.reply_suggestion;

    if (suggestion.conversation_id !== activeConversationId) {
      return;
    }

    if (suggestion.id !== loadedReplySuggestion.id) {
      return;
    }

    dispatch(setAutoReplySuggestion(undefined));
  }, [newConcludedReplySuggestion]);

  useEffect(() => {
    if (Object.keys(newGeneratedReplySuggestion).length === 0) {
      return;
    }

    const generatedSuggestion = newGeneratedReplySuggestion.reply_suggestion;

    if (
      loadedReplySuggestion &&
      loadedReplySuggestion.createdAt > generatedSuggestion.created_at
    ) {
      return;
    }

    if (activeConversationId === generatedSuggestion.conversation_id) {
      dispatch(
        setAutoReplySuggestion(
          new ReplySuggestionDomain(
            generatedSuggestion.id,
            generatedSuggestion.conversation_id,
            ReplySuggestionStatus.GENERATED,
            generatedSuggestion.reply_to_message_id,
            generatedSuggestion.body,
            generatedSuggestion.created_at
          )
        )
      );
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
  }, [newGeneratedReplySuggestion]);

  useEffect(() => {
    if (Object.keys(newQueuedReplySuggestion).length === 0) {
      return;
    }

    const queuedSuggestion = newQueuedReplySuggestion.reply_suggestion;

    if (
      loadedReplySuggestion &&
      loadedReplySuggestion.id === queuedSuggestion.id
    ) {
      return;
    }

    if (activeConversationId === queuedSuggestion.conversation_id) {
      dispatch(
        setAutoReplySuggestion(
          new ReplySuggestionDomain(
            queuedSuggestion.id,
            queuedSuggestion.conversation_id,
            ReplySuggestionStatus.IN_PROGRESS,
            undefined,
            undefined,
            queuedSuggestion.created_at
          )
        )
      );
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
  }, [newQueuedReplySuggestion]);

  useEffect(() => {
    if (Object.keys(newFailedReplySuggestion).length === 0) {
      return;
    }

    if (!loadedReplySuggestion || !newFailedReplySuggestion.reply_suggestion) {
      return;
    }

    const failedSuggestion = newFailedReplySuggestion.reply_suggestion;

    if (failedSuggestion.conversation_id !== activeConversationId) {
      return;
    }

    if (failedSuggestion.id !== loadedReplySuggestion.id) {
      return;
    }

    dispatch(
      setAutoReplySuggestion(
        new ReplySuggestionDomain(
          failedSuggestion.id,
          failedSuggestion.conversation_id,
          ReplySuggestionStatus.FAILED,
          failedSuggestion.reply_to_message_id,
          undefined,
          failedSuggestion.created_at
        )
      )
    );
  }, [newFailedReplySuggestion]);

  useEffect(() => {
    addEventHandler("deleted_message", handleMessageDeleted);
    addEventHandler(
      "reply_suggestion_concluded",
      setNewConcludedReplySuggestion
    );
    addEventHandler(
      "reply_suggestion_generated",
      setNewGeneratedReplySuggestion
    );
    addEventHandler("reply_suggestion_queued", setNewQueuedReplySuggestion);
    addEventHandler("reply_suggestion_failed", setNewFailedReplySuggestion);
    addEventHandler("message_update", handleMessageUpdate);
    addEventHandler("inbound_message", handleInboundMessage);

    return () => {
      removeEventHandler("deleted_message", handleMessageDeleted);
      removeEventHandler(
        "reply_suggestion_concluded",
        setNewConcludedReplySuggestion
      );
      removeEventHandler(
        "reply_suggestion_generated",
        setNewGeneratedReplySuggestion
      );
      removeEventHandler(
        "reply_suggestion_queued",
        setNewQueuedReplySuggestion
      );
      removeEventHandler(
        "reply_suggestion_failed",
        setNewFailedReplySuggestion
      );
      removeEventHandler("message_update", handleMessageUpdate);
      removeEventHandler("inbound_message", handleInboundMessage);
    };
  }, [addEventHandler, removeEventHandler]);

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    setNewInboundMessage(args);
  };

  useDeepCompareEffect(() => {
    if (Object.keys(newInboundMessage).length === 0) {
      return;
    }

    const message: MessageDomain = messageTransformFromDtoToDomain(
      newInboundMessage.message
    );

    if (activeConversationId === message.conversationId) {
      dispatch(
        appendMessage({
          conversationId: message.conversationId,
          message,
        })
      );
    }
  }, [newInboundMessage]);

  return (
    <Flex
      h="100%"
      w="100%"
      direction="column"
      flexWrap="nowrap"
      alignItems="stretch"
      justifyContent="space-between"
      overflow="hidden"
    >
      <Box
        px={{ base: 0, lg: 4 }}
        pt={4}
        pb={0}
        ref={scrollContainerRef}
        overflowY="auto"
        data-scroll-lock-scrollable
        id="messages-list"
      >
        {mobileUnsubscribedAlert.open ? (
          <Alert
            zIndex={9999}
            status="warning"
            position="fixed"
            top={0}
            left={0}
            title="Unsubscribed customer!"
          >
            <Text>
              This is a conversation with a customer who has unsubscribed from
              marketing messages.
            </Text>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={mobileUnsubscribedAlert.onClose}
            />
          </Alert>
        ) : null}
        <BackgroundSettings onBackgroundChange={onBackgroundChange} />
        {emailMessageToShow ? (
          <EmailRaw message={emailMessageToShow} />
        ) : (
          <>
            {!isLoadingMessages && !hasMoreMessages && isListOverflown ? (
              <Flex alignItems="center" justifyContent="center" w="100%" py={8}>
                <Text>✅ No more messages found</Text>
              </Flex>
            ) : null}
            {shouldShowSkeleton ? (
              <SkeletonOverlay isInitialLoad={isFirstLoad} />
            ) : null}
            <ViewportList
              viewportRef={scrollContainerRef}
              items={messages || []}
              itemSize={60}
              itemMargin={0}
              overscan={10}
              withCache={false}
              ref={listRef}
              onViewportIndexesChange={([startIndex, endIndex]) => {
                if (
                  !messages ||
                  !messages.length ||
                  isLoadingMessages ||
                  emailMessageToShow ||
                  emailMessageIndex !== null
                ) {
                  return;
                }

                if (startIndex === 0 && endIndex !== messages.length - 1) {
                  fetchMore();
                }
              }}
            >
              {(message, index, currentMessagesArray) => (
                <MessageRow
                  ref={isFirstUnread(index, messages) ? inViewRef : undefined}
                  key={message.id}
                  isFirstUnread={isFirstUnread(index, messages)}
                  messageId={message.id}
                >
                  {isFirstUnread(index, messages) && (
                    <Unread
                      ref={unreadRef as Ref<HTMLButtonElement>}
                      showGoToRecent={false}
                    />
                  )}

                  {message.isNotification() ? (
                    <Notification
                      ref={rowRef as Ref<HTMLDivElement>}
                      message={message}
                    />
                  ) : (
                    <MessageComponent
                      ref={rowRef as Ref<HTMLDivElement>}
                      value={message}
                      showTail={shouldShowTail(
                        message,
                        index,
                        currentMessagesArray
                      )}
                    />
                  )}
                </MessageRow>
              )}
            </ViewportList>
          </>
        )}
        <ReplySuggestion />
      </Box>

      <Box
        mb={{ base: 0, lg: 4 }}
        py={{ base: 0, lg: 0 }}
        px={{ base: 0, lg: 4 }}
        maxHeight="90%" // to allow background settings button to be visible
        mt="auto"
        ref={inputRef}
        zIndex={50}
      >
        <TypingZone />
      </Box>
    </Flex>
  );
};

export default MessageList;
