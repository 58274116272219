import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { NumberInputField, NumberInputRoot } from "components/ui/number-input";
import AutomationDomain, { TimeUnit } from "entities/domain/automation";
import React, { ChangeEvent, useEffect, useState } from "react";
import { LuCheck, LuRepeat, LuTrash } from "react-icons/lu";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface SelectOption {
  value: string;
  label: string;
}

interface RepeatProps {
  automation: AutomationDomain;
  setUpdatedAutomation: (automation: AutomationDomain) => void;
}

const Repeat = ({ automation, setUpdatedAutomation }: RepeatProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [timeAmount, setTimeAmount] = useState<number | undefined>(
    automation?.frequency?.amount
  );
  const [timeUnit, setTimeUnit] = useState<TimeUnit | undefined>(
    automation?.frequency?.unit
  );

  let text = "Never";

  if (timeAmount && timeUnit) {
    text = `Every ${timeAmount} ${timeAmount > 1 ? `${timeUnit}s` : timeUnit}`;
  }

  const [isEditingExecutionFrequency, setIsEditingExecutionFrequency] =
    useState<boolean>(false);

  const timeUnitOptions: SelectOption[] = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
  ];

  const [selectedTimeUnitValue, setSelectedTimeUnitValue] = useState<
    SingleValue<SelectOption>
  >(
    !timeUnit
      ? null
      : timeUnitOptions.find(({ value }) => value === timeUnit) || null
  );

  const handleTimeAmountChange = ({
    valueAsNumber,
  }: {
    valueAsNumber: number;
  }) => {
    setTimeAmount(valueAsNumber);
  };

  const handleTimeUnitChange = (e: SingleValue<SelectOption>) => {
    setTimeUnit(e?.value as TimeUnit);
    setSelectedTimeUnitValue(e);
  };

  useEffect(() => {
    if (timeAmount && timeUnit) {
      setUpdatedAutomation(
        new AutomationDomain(
          automation.id,
          automation.name,
          automation.description,
          automation.merchantId,
          automation.triggerType,
          automation.time,
          {
            ...automation.frequency,
            amount: timeAmount,
            unit: timeUnit,
          },
          automation.task,
          automation.templateId,
          automation.enabled
        )
      );
    }
  }, [timeAmount, timeUnit]);

  return (
    <>
      <Box px={6} mt={5}>
        <Text fontWeight={700} pb="1rem">
          Repeat:
        </Text>
        <Flex alignItems="center">
          <Icon
            as={LuRepeat}
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
          {!isEditingExecutionFrequency ? (
            <Flex
              borderRadius="full"
              border="1px solid gray"
              height="2.5rem"
              px="1rem"
              onClick={() => setIsEditingExecutionFrequency(true)}
              _hover={{
                cursor: "pointer",
                boxShadow: "0 0 1rem gray",
                border: "none",
                color: "white",
                backgroundColor: `${colorScheme}.600`,
              }}
              alignItems="center"
            >
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                textAlign="center"
              >
                {text}
              </Text>
            </Flex>
          ) : (
            <Flex
              wrap="nowrap"
              justifyContent="space-evenly"
              alignItems="center"
              width="100%"
            >
              <Flex direction="column" gridGap={3}>
                <NumberInputRoot
                  step={1}
                  defaultValue={timeAmount?.toString() || "0"}
                  min={0}
                  max={30}
                  onValueChange={handleTimeAmountChange}
                >
                  <NumberInputField />
                </NumberInputRoot>
                <Select
                  placeholder="Select time unit"
                  onChange={handleTimeUnitChange}
                  options={timeUnitOptions}
                  value={selectedTimeUnitValue}
                  styles={getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  )}
                />
              </Flex>
              <Icon
                as={LuTrash}
                onClick={() => {
                  setTimeAmount(0);
                  setTimeUnit(undefined);
                  setIsEditingExecutionFrequency(false);
                  setUpdatedAutomation(
                    new AutomationDomain(
                      automation.id,
                      automation.name,
                      automation.description,
                      automation.merchantId,
                      automation.triggerType,
                      automation.time,
                      null,
                      automation.task,
                      automation.templateId,
                      automation.enabled
                    )
                  );
                }}
                cursor="pointer"
                height="20px"
                width="20px"
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />
              <Icon
                as={LuCheck}
                onClick={() => setIsEditingExecutionFrequency(false)}
                cursor="pointer"
                height="20px"
                width="20px"
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default Repeat;
