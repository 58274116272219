import { Badge, Tabs, VStack, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import TemplateDomain from "entities/domain/templates";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import { useScrollWithShadow } from "hooks/use-scroll-with-shadows";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  MessageInputTab,
  updateMessageInputActiveTab,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import MessagePreview from "./MessagePreview";

interface ContentProps {
  children: React.ReactNode;
  topRightElement?: React.ReactNode;
  showHeader?: boolean;
  isEditable?: boolean;
  scrollHelper?: React.ReactNode;
}

export const getScrollShadowStyles = (
  colorMode: string,
  template: boolean,
  showHeader: boolean
) => {
  if (template || showHeader) {
    return {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: "-0.5rem",
      width: "100%",
      height: "0.5rem",
      background: `linear-gradient(to bottom, ${
        colorMode === "dark"
          ? "var(--chakra-colors-blackAlpha-100)"
          : "var(--chakra-colors-gray-50)"
      }, transparent)`,
    };
  }

  return {
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "0.5rem",
    background: `linear-gradient(to bottom, ${
      colorMode === "dark"
        ? "var(--chakra-colors-blackAlpha-100)"
        : "var(--chakra-colors-gray-50)"
    }, transparent)`,
  };
};

const Content: React.FC<ContentProps> = ({
  scrollHelper,
  children,
  topRightElement,
  showHeader = false,
  isEditable = true,
}) => {
  const {
    messageInput: {
      template,
      activeTab,
      text,
      replySuggestionId,
      replySuggestionCustomFields,
    },
  } = useAppSelector((state) => state.conversations);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();

  const shouldHavePreview = useMemo(() => {
    return template || replySuggestionId;
  }, [template, replySuggestionId]);

  const scrollShadowTopStyles = useMemo(() => {
    return getScrollShadowStyles(
      (colorMode as "dark" | "light") || "light",
      !!shouldHavePreview,
      showHeader
    );
  }, [colorMode, shouldHavePreview, showHeader]);

  const availableCustomFields = useAvailableCustomFields(template);

  const localCustomFields = useMemo(() => {
    if (replySuggestionId && replySuggestionCustomFields) {
      return replySuggestionCustomFields;
    }

    return availableCustomFields;
  }, [availableCustomFields, replySuggestionCustomFields, replySuggestionId]);

  const scrollShadowBottomStyles = useMemo(() => {
    return {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "0.5rem",
      background: `linear-gradient(to top, ${
        colorMode === "dark"
          ? "var(--chakra-colors-blackAlpha-100)"
          : "var(--chakra-colors-gray-50)"
      }, transparent)`,
    };
  }, [colorMode]);

  const [tabIndex, setTabIndex] = useState<MessageInputTab>(
    template ? MessageInputTab.PREVIEW : MessageInputTab.EDIT
  );

  useEffect(() => {
    const newTabIndex = shouldHavePreview
      ? MessageInputTab.PREVIEW
      : MessageInputTab.EDIT;

    setTabIndex(newTabIndex);
    dispatch(updateMessageInputActiveTab(newTabIndex));
  }, [shouldHavePreview]);

  const missingCustomFieldsTotal = useMemo(() => {
    if (!shouldHavePreview) {
      return 0;
    }

    return TemplateDomain.getCustomFieldsWithoutValue(text, localCustomFields)
      .length;
  }, [text, localCustomFields, shouldHavePreview]);

  useEffect(() => {
    if (activeTab === tabIndex) {
      return;
    }

    setTabIndex(activeTab);
  }, [activeTab]);

  const { scrollHelper: previewScrollHelper, onScrollHandler } =
    useScrollWithShadow();

  return (
    <VStack
      overflowY="auto"
      data-scroll-lock-scrollable
      id="message-input-content"
      w="100%"
      h="100%"
      position="relative"
      gap={0}
      _before={
        !shouldHavePreview && !showHeader && !scrollHelper
          ? scrollShadowTopStyles
          : {}
      }
      _after={!scrollHelper ? scrollShadowBottomStyles : {}}
      lineHeight="1.25rem"
    >
      {!shouldHavePreview && !showHeader ? (
        <>
          {scrollHelper}
          {children}
        </>
      ) : (
        <Tabs.Root
          variant="subtle"
          size="sm"
          colorPalette="gray"
          w="100%"
          h="100%"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          value={tabIndex.toString()}
          onValueChange={({ value }) => {
            setTabIndex(parseInt(value, 10));
            dispatch(updateMessageInputActiveTab(parseInt(value, 10)));
          }}
        >
          <Tabs.List py={2} position="relative" _after={scrollShadowTopStyles}>
            <Tabs.Trigger
              value="0"
              ml={2}
              disabled={!isEditable}
              data-testid="message-edit-tab"
            >
              Edit
            </Tabs.Trigger>
            <Tabs.Trigger value="1" mr={2} data-testid="message-preview-tab">
              Preview
              {missingCustomFieldsTotal > 0 ? (
                <Badge colorPalette="red" variant="subtle" size="xs" ml={1}>
                  {missingCustomFieldsTotal}
                </Badge>
              ) : null}
            </Tabs.Trigger>
            {topRightElement}
          </Tabs.List>
          <Tabs.Content
            value="0"
            display="flex"
            flexDirection="column"
            w="100%"
            h="100%"
            overflow="hidden"
            p={0}
            position="relative"
          >
            {scrollHelper}
            {children}
          </Tabs.Content>
          <Tabs.Content
            value="1"
            display="flex"
            flexDirection="column"
            w="100%"
            h="100%"
            overflow="hidden"
            p={0}
            position="relative"
          >
            {isBaseSize ? previewScrollHelper : null}
            <MessagePreview
              onScrollHandler={isBaseSize ? onScrollHandler : undefined}
            />
          </Tabs.Content>
        </Tabs.Root>
      )}
    </VStack>
  );
};

export default memo(Content);
