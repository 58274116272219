import { Input, SpinnerProps } from "@chakra-ui/react";
import React, { Suspense, useEffect } from "react";
import type { Country } from "react-phone-number-input";

// Only import types, which will be removed during compilation
type DefaultInputComponentProps = any;
type FeatureProps = any;

const LazyPhoneInput = React.lazy(
  () =>
    import(
      /* webpackChunkName: "react-phone-number-input" */ "react-phone-number-input"
    )
);

// Module-level cache but only for essential functions
let validateFn: ((value: string) => boolean) | null = null;
let getCodeFn: ((country: Country) => string) | null = null;

// Synchronous version that falls back to basic validation if module isn't loaded
export const isValidPhoneNumber = (value: string): boolean => {
  if (!validateFn) {
    // Basic validation until the real validation function is loaded
    const basicValidation = (v: string) =>
      Boolean(v && v.length > 8 && v.startsWith("+"));

    // Load the real validation function in the background
    import(
      /* webpackChunkName: "react-phone-number-input" */ "react-phone-number-input"
    )
      .then((module) => {
        validateFn = module.isValidPhoneNumber;
      })
      .catch(console.error);

    return basicValidation(value);
  }
  return validateFn(value);
};

export const getCountryCallingCode = (country: Country): string => {
  if (!getCodeFn) {
    // Load the real function in the background
    import(
      /* webpackChunkName: "react-phone-number-input" */ "react-phone-number-input"
    )
      .then((module) => {
        getCodeFn = module.getCountryCallingCode;
      })
      .catch(console.error);

    return ""; // Return empty string as fallback until the real function is loaded
  }
  return getCodeFn(country);
};

export const isValidPhoneNumberAsync = async (
  value: string
): Promise<boolean> => {
  const module = await import(
    /* webpackChunkName: "react-phone-number-input" */ "react-phone-number-input"
  );
  return module.isValidPhoneNumber(value);
};

export const getCountryCallingCodeAsync = async (
  country: Country
): Promise<string> => {
  const module = await import(
    /* webpackChunkName: "react-phone-number-input" */ "react-phone-number-input"
  );
  return module.getCountryCallingCode(country);
};

// Re-export types
export type {
  Country,
  DefaultInputComponentProps,
  FeatureProps,
} from "react-phone-number-input";

interface PhoneInputProps extends React.ComponentProps<typeof LazyPhoneInput> {
  spinnerSize?: SpinnerProps["size"];
  loadStyles?: boolean;
}

const PhoneInputWithSuspense: React.FC<PhoneInputProps> = ({
  spinnerSize = "sm",
  loadStyles = true,
  ...props
}) => {
  useEffect(() => {
    if (loadStyles) {
      import(
        /* webpackChunkName: "phone-input-styles" */ "react-phone-number-input/style.css"
      ).catch((error) =>
        console.error("Failed to load phone input styles:", error)
      );
    }
  }, [loadStyles]);

  return (
    <Suspense fallback={<Input disabled={true} />}>
      <LazyPhoneInput {...props} />
    </Suspense>
  );
};

export default PhoneInputWithSuspense;
