import {
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp-new.svg";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import React from "react";
import { FaSms } from "react-icons/fa";
import { LuMail, LuPlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface ChooseTemplateTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChooseTemplateTypeModal = ({
  isOpen,
  onClose,
}: ChooseTemplateTypeModalProps) => {
  const navigate = useNavigate();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      size={isBaseSize ? "full" : "xl"}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>Create Template</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <HStack gap={4} justifyContent="space-between" pb={6}>
            <Button
              colorPalette={colorScheme}
              variant="outline"
              borderRadius="xl"
              py={8}
              px={6}
              flexDirection="column"
              gridGap={4}
              height="15rem"
              // w="100%"
              justifyContent="space-between"
              id="choose-template-for-whatsapp"
              flexGrow={1}
              position="relative"
              onClick={() => {
                navigate(`/${merchant.id}/settings/templates/whatsapp/create`);
              }}
            >
              <Flex
                flexGrow={1}
                flexShrink={0}
                flexBasis="auto"
                alignItems="center"
              >
                <Icon as={WhatsappIcon} boxSize={12} />
              </Flex>
              <Text flexGrow={0} flexShrink={0} flexBasis="auto">
                WhatsApp
              </Text>
            </Button>
            <Button
              colorPalette={colorScheme}
              variant="outline"
              borderRadius="xl"
              py={8}
              px={6}
              flexDirection="column"
              gridGap={4}
              height="15rem"
              id="choose-template-for-everything-else"
              justifyContent="space-between"
              flexGrow={1}
              position="relative"
              onClick={() => {
                navigate(`/${merchant.id}/settings/templates/create/`);
              }}
            >
              <VStack gap={2}>
                <HStack>
                  <Icon
                    as={MessengerIcon}
                    boxSize={8}
                    opacity={
                      merchant.isIntegrationEnabled(IntegrationName.MESSENGER)
                        ? 1
                        : 0.25
                    }
                  />
                </HStack>
                <HStack gap={2}>
                  <Icon
                    as={LuMail}
                    boxSize={8}
                    opacity={
                      merchant.isIntegrationEnabled(IntegrationName.GMAIL) ||
                      merchant.isIntegrationEnabled(IntegrationName.OUTLOOK)
                        ? 1
                        : 0.25
                    }
                  />
                  <Icon
                    as={LuPlus}
                    boxSize={6}
                    color={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                  <Icon
                    as={FaSms}
                    boxSize={8}
                    opacity={
                      merchant.isMerchantChannelEnabled(ConversationChannel.SMS)
                        ? 1
                        : 0.25
                    }
                    color={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                </HStack>
                <HStack>
                  <Icon
                    as={InstagramIcon}
                    boxSize={8}
                    opacity={
                      merchant.isIntegrationEnabled(IntegrationName.INSTAGRAM)
                        ? 1
                        : 0.25
                    }
                  />
                </HStack>
              </VStack>
              <Text>Everything else</Text>
            </Button>
          </HStack>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

export default ChooseTemplateTypeModal;
