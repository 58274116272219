import {
  Box,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import DOMPurify from "dompurify";
import MessageDomain from "entities/domain/conversations/message-domain";
import React from "react";
import { LuX } from "react-icons/lu";
import { closeEmailMessage } from "redux/features/messages";
import { useAppDispatch } from "redux/hooks";

interface EmailRawProps {
  message: MessageDomain;
}

const EmailRaw = ({ message }: EmailRawProps) => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Box
      bgColor={colorMode === "dark" ? "gray.800" : "whiteAlpha.900"}
      borderRadius="3xl"
      p={4}
      position="relative"
      mb={4}
      mx={isBaseSize ? 2 : 0}
    >
      <IconButton
        aria-label="Close email message"
        position="absolute"
        top={2}
        right={2}
        colorPalette="gray"
        onClick={() => {
          dispatch(closeEmailMessage());
        }}
      >
        <LuX />
      </IconButton>
      <div
        data-private
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(message.getHtml() || ""),
        }}
      />
    </Box>
  );
};

export default EmailRaw;
