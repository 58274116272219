import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "components/ui/hover-card";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaBullhorn, FaChartArea } from "react-icons/fa";
import {
  LuCalendar,
  LuCreditCard,
  LuMail,
  LuSettings,
  LuStar,
} from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface DesktopNavButtonProps {
  icon: React.ReactNode;
  name: string;
  link: string;
  isDisabled?: boolean;
}

const DesktopNavButton = ({
  icon,
  name,
  link,
  isDisabled,
}: DesktopNavButtonProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <HoverCardRoot positioning={{ placement: "right" }}>
      <HoverCardTrigger asChild>
        <Button
          aria-label={`${name}-navigation-button`}
          disabled={isDisabled}
          colorPalette={colorScheme}
          variant="ghost"
          className="dark"
          borderRadius="lg"
          width="3.5rem"
          height="3.5rem"
          m={2}
          asChild
          _currentPage={{
            bgColor: `${colorScheme}.700`,
          }}
        >
          <NavLink
            to={link}
            style={{
              padding: "1rem",
            }}
          >
            {icon}
          </NavLink>
        </Button>
      </HoverCardTrigger>
      <HoverCardContent>
        <HoverCardArrow />
        {name}
      </HoverCardContent>
    </HoverCardRoot>
  );
};

export const InboxLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { unreadCounts } = useAppSelector((state) => state.agents);
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);
  const { colorMode } = useColorMode();

  return (
    <DesktopNavButton
      icon={
        <Flex position="relative" alignItems="center" justifyContent="center">
          <Icon as={LuMail} width="2rem" height="2rem" color="white" />
          {unreadCount?.personal || unreadCount?.shared ? (
            <Box
              position="absolute"
              top="-0.75rem"
              right="-0.75rem"
              zIndex="1"
              borderRadius="full"
              height="1rem"
              width="1rem"
              bgColor={colorMode === "dark" ? "red.200" : "red.500"}
            />
          ) : null}
        </Flex>
      }
      name="Inbox"
      link={`/${merchant.id}/inbox`}
    />
  );
};

export const ContactLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon as={AiOutlineUser} width="2rem" height="2rem" color="white" />
      }
      name="Contacts"
      link={`/${merchant.id}/contacts`}
    />
  );
};

export const CampaignsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Flex position="relative">
          <Icon as={FaBullhorn} width="2rem" height="2rem" color="white" />
        </Flex>
      }
      name="Campaigns"
      link={`/${merchant.id}/campaigns`}
    />
  );
};

export const CalendarLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={<Icon as={LuCalendar} width="2rem" height="2rem" color="white" />}
      name="Calendar"
      link={`/${merchant.id}/calendar`}
    />
  );
};

export const PaymentsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={<Icon as={LuCreditCard} width="2rem" height="2rem" color="white" />}
      name="Payments"
      link={`/${merchant.id}/payments`}
    />
  );
};

export const ReviewsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={<Icon as={LuStar} width="2rem" height="2rem" color="white" />}
      name="Reviews"
      link={`/${merchant.id}/reviews`}
    />
  );
};

export const AnalyticsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={<Icon as={FaChartArea} width="2rem" height="2rem" color="white" />}
      name="Analytics"
      link={`/${merchant.id}/analytics`}
    />
  );
};

export const SettingsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={<Icon as={LuSettings} width="2rem" height="2rem" color="white" />}
      name="Settings"
      link={`/${merchant.id}/settings`}
    />
  );
};

export default DesktopNavButton;
