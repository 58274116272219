import { Box, HStack, Icon, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";

import { useAuth0 } from "@auth0/auth0-react";
import { toaster } from "components/ui/toaster";
import ContactDomain from "entities/domain/customers/contact-domain";
import ReviewDomain from "entities/domain/reviews/review-domain";
import { LuCheck, LuX } from "react-icons/lu";
import ReviewsService from "services/reviews";
import SelectCustomer from "./SelectCustomer";

interface AssignToCustomerFormProps {
  review: ReviewDomain;
  onClose: () => void;
}

const AssignToCustomerForm = ({
  review,
  onClose,
}: AssignToCustomerFormProps) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);


  const [selectedCustomer, setSelectedCustomer] =
    useState<ContactDomain | null>(null);

  const resetValues = () => {
    setSelectedCustomer(null);
  };

  const saveReview = async () => {
    try {
      await ReviewsService.assignCustomerToReview(
        auth0Context,
        merchant.id,
        review.id,
        selectedCustomer!.id!
      );
      toaster.create({
        type: "success",
        title: "Customer was successfully assigned to review",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't assign customer to review", e);
      toaster.create({ type: "error", title: "Couldn't assign customer to review" });
    } finally {
      resetValues();
      onClose();
    }
  };

  return (
    <Box w="100%">
      <HStack w="100%" gap={3}>
        <SelectCustomer
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
        <IconButton
          aria-label="Cancel Assigning Customer to Review"
          colorPalette={colorScheme}
          onClick={() => onClose()}
        >
          <Icon as={LuX} />
        </IconButton>
        <IconButton
          aria-label="Select Customer for Review"
          colorPalette={colorScheme}
          onClick={saveReview}
          disabled={!selectedCustomer}
        >
          <Icon as={LuCheck} />
        </IconButton>
      </HStack>
    </Box>
  );
};

export default AssignToCustomerForm;
