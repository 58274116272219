import { Box, useBreakpointValue } from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import MergeContact from "components/modals/merge-contact/MergeContact";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import {
  DrawerBackdrop,
  DrawerContent,
  DrawerRoot,
} from "components/ui/drawer";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { ContactsSorting } from "util/ContactsFilter";
import { canDeleteContacts } from "util/permissions";
import ContactChannelsColumn from "./columns/ContactChannelsColumn";
import ContactNameColumn from "./columns/ContactNameColumn";
import ContactTagsColumn from "./columns/ContactTagsColumn";

type ContactsListTableProps = {
  contacts: ContactDomain[];
  openContactDetails: (contact: ContactDomain) => void;
  handleOpenEditTags: (val: ContactDomain) => void;
  handleDeleteContact: (id: number) => void;
  fetchMore: () => Promise<void>;
  sorting: ContactsSorting | undefined;
  setSorting: (sorting: ContactsSorting | undefined) => void;
  hasNextPage: boolean;
};

export default function ContactsListTable({
  fetchMore,
  hasNextPage,
  contacts,
  sorting,
  openContactDetails,
  setSorting,
  handleDeleteContact,
  handleOpenEditTags,
}: ContactsListTableProps) {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  const navigate = useNavigate();
  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = () => {
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getIndividualActions = useCallback(
    (
      contactItem: ContactDomain
    ): SmartListIndividualAction<ContactDomain>[] | undefined => {
      if (!contactItem) {
        return;
      }

      const individualActions: SmartListIndividualAction<ContactDomain>[] = [];

      individualActions.push({
        label: "Edit",
        execute: () => {
          navigate(`/${merchant.id}/contacts/edit/${contactItem.id}`);
        },
      });

      individualActions.push({
        label: "Merge Contact",
        execute: () => {
          handleOpenMergeModal(contactItem);
        },
      });

      if (canDeleteContacts(merchant.id, currentAgent!)) {
        individualActions.push({
          label: "Delete",
          execute: () => {
            handleDeleteContact(contactItem.id!);
          },
          shouldShowConfirmation: true,
          confirmationText: "Are you sure you want to delete this contact?",
        });
      }

      return individualActions;
    },
    []
  );

  const memoizedColumns = useMemo(() => {
    return [
      {
        label: "Name",
        field: "name",
        currentSorting:
          sorting && sorting.field === "name" ? sorting.direction : undefined,
        setCurrentSorting: (direction: "asc" | "desc" | undefined) => {
          setSorting(direction ? { direction, field: "name" } : undefined);
        },
        component: ContactNameColumn,
      },
      ...(isBaseSize
        ? []
        : [
            {
              label: "Channels",
              component: ContactChannelsColumn,
            },
            {
              label: "Tags",
              component: ContactTagsColumn,
              additionalProps: {
                handleOpenEditTags,
              },
            },
          ]),
    ];
  }, [sorting, setSorting]);

  return (
    <>
      <Box
        h="100%"
        w="100%"
        overflowY="auto"
        mx="auto"
        ref={scrollContainerRef}
      >
        <SmartList
          items={contacts}
          itemIdentifier="id"
          canFetchMore={true}
          initialScroll={false}
          fetchMore={fetchMore}
          hasNextPage={hasNextPage}
          containerRef={scrollContainerRef}
          columns={memoizedColumns}
          getIndividualActions={getIndividualActions}
          onItemClick={openContactDetails}
        />
      </Box>
      <MergeContact
        contactToMerge={contactToMerge}
        isOpen={openMergeForm}
        onClose={handleCloseMergeModal}
      />
      {openNewContactForm && (
        <DrawerRoot
          open={openNewContactForm}
          onOpenChange={({ open: newIsOpen }) => {
            if (!newIsOpen) {
              setOpenNewContactForm(false);
            }
          }}
          placement="top"
        >
          <DrawerBackdrop />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              isOpen={openNewContactForm}
              onClose={() => setOpenNewContactForm(false)}
              setRecentlyCreatedContact={openContactDetails}
            />
          </DrawerContent>
        </DrawerRoot>
      )}
    </>
  );
}
