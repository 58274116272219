import { Button, Flex, Icon, Text, useBreakpointValue } from "@chakra-ui/react";

import { useColorMode } from "components/ui/color-mode";
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import React, { useState } from "react";
import { LuArrowDown, LuArrowDownAZ, LuArrowUp, LuCheck } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import { ContactsSorting } from "util/ContactsFilter";

interface SortingProps {
  setSorting: (sorting: ContactsSorting | undefined) => void;
}

const convertFieldToText = (field: string) => {
  const fieldText = field.split("_").join(" ");
  return fieldText.charAt(0).toUpperCase() + fieldText.slice(1);
};

const Sorting = ({ setSorting }: SortingProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const [lastSorting, setLastSorting] = useState<{
    field: string;
    direction: string;
  }>({ field: "", direction: "" });

  const handleSortingSelected = (field: "name", direction: "asc" | "desc") => {
    if (lastSorting.field === field && lastSorting.direction === direction) {
      setLastSorting({ field: "", direction: "" });
      setSorting(undefined);
      return;
    }

    setLastSorting({ field, direction });
    setSorting({ field, direction });
  };

  return (
    <PopoverRoot
      positioning={{ placement: isBaseSize ? "bottom" : "right-end" }}
    >
      <PopoverTrigger>
        <Button
          w={isBaseSize ? "auto" : "100%"}
          gridGap={2}
          colorPalette={colorScheme}
          pl={isBaseSize ? 0 : 8}
          ml={isBaseSize ? 0 : 2}
          justifyContent={isBaseSize ? "center" : "start"}
          variant={
            !!lastSorting.field && isBaseSize
              ? "subtle"
              : lastSorting.field
              ? "solid"
              : "ghost"
          }
          {...(isBaseSize
            ? {
                borderRadius: "full",
                p: 0,
              }
            : {})}
        >
          {lastSorting.direction === "asc" && !isBaseSize && (
            <Icon
              as={LuArrowUp}
              width={isBaseSize ? "1rem" : "1.25rem"}
              height={isBaseSize ? "1rem" : "1.25rem"}
            />
          )}
          {lastSorting.direction === "desc" && !isBaseSize && (
            <Icon
              as={LuArrowDown}
              width={isBaseSize ? "1rem" : "1.25rem"}
              height={isBaseSize ? "1rem" : "1.25rem"}
            />
          )}
          {(!lastSorting.direction || isBaseSize) && (
            <Icon
              as={LuArrowDownAZ}
              width={isBaseSize ? "1rem" : "1.25rem"}
              height={isBaseSize ? "1rem" : "1.25rem"}
              color={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          )}
          {isBaseSize ? null : (
            <Text overflow="hidden" textOverflow="ellipsis">
              {lastSorting.field
                ? `By ${convertFieldToText(lastSorting.field)}`
                : "Sort"}
            </Text>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ outline: "none" }}
        boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
        w="189px"
        borderRadius="lg"
        overflow="hidden"
        style={{
          transition: "all 0.1s",
          outline: "none",
        }}
      >
        <PopoverArrow />
        <PopoverBody p={0}>
          <Flex
            justify="space-between"
            align="center"
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() => handleSortingSelected("name", "asc")}
            _hover={{
              background: "gray.200",
            }}
          >
            <Flex
              w="100%"
              gridGap={1}
              alignItems="center"
              justifyContent="start"
            >
              <Icon as={LuArrowUp} />
              <Text>First Name</Text>
            </Flex>
            {lastSorting.field === "name" &&
              lastSorting.direction === "asc" && (
                <LuCheck
                  style={{
                    color: `${colorScheme}.400`,
                    marginLeft: 3,
                  }}
                />
              )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() => handleSortingSelected("name", "desc")}
            _hover={{
              background: "gray.200",
            }}
          >
            <Flex
              w="100%"
              gridGap={1}
              alignItems="center"
              justifyContent="start"
            >
              <Icon as={LuArrowDown} />
              <Text>First Name</Text>
            </Flex>
            {lastSorting.field === "name" &&
              lastSorting.direction === "desc" && (
                <LuCheck
                  style={{
                    color: `${colorScheme}.400`,
                    marginLeft: 3,
                  }}
                />
              )}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default Sorting;
