import { Button, Icon, Text, VStack } from "@chakra-ui/react";
import { MultipleWarnings } from "components/shared/WarningTextComponent";
import { useColorMode } from "components/ui/color-mode";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import ChannelForm from "./ChannelForm";
import ChannelRow from "./ChannelRow";

interface ChannelsAreaProps {
  warnings?: MultipleWarnings;
  channels: CustomerChannelDomain[];
  isCreatingContact: boolean;
  setChannels: (channels: CustomerChannelDomain[]) => void;
}

const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 15);
};

const shouldShowFormForChannel = (
  channelId: string | undefined,
  editingChannelWithId: string | undefined
) => {
  return channelId && channelId === editingChannelWithId;
};

const ChannelsArea = ({
  warnings,
  channels,
  isCreatingContact,
  setChannels,
}: ChannelsAreaProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [editingChannelWithId, setEditingChannelWithId] = useState<
    string | undefined
  >(undefined);
  const [newChannelId, setNewChannelId] = useState<string | undefined>(
    undefined
  );
  const [enabledChannelTypes, setEnabledChannelTypes] = useState<
    ConversationChannel[]
  >([]);
  const [localChannels, setLocalChannels] =
    useState<CustomerChannelDomain[]>(channels);

  useEffect(() => {
    setLocalChannels(channels);
  }, [channels]);

  useEffect(() => {
    const hasSmsChannel = localChannels.some(
      (channel) => channel.type === ConversationChannel.SMS
    );
    const hasWhatsappChannel = localChannels.some(
      (channel) => channel.type === ConversationChannel.WHATSAPP
    );

    const newEnabledChannelTypes: ConversationChannel[] = [
      ConversationChannel.EMAIL,
    ];

    if (!hasSmsChannel || isCreatingContact) {
      newEnabledChannelTypes.push(ConversationChannel.SMS);
    }

    if (!hasWhatsappChannel || isCreatingContact) {
      newEnabledChannelTypes.push(ConversationChannel.WHATSAPP);
    }

    setEnabledChannelTypes(newEnabledChannelTypes);
  }, [localChannels, isCreatingContact]);

  const editChannel = (updatedChannel: CustomerChannelDomain) => {
    setChannels(
      localChannels.map((c) => {
        if (c.id === updatedChannel.id) {
          return updatedChannel;
        }
        return c;
      })
    );
    setEditingChannelWithId(undefined);
  };

  const cancelEditingChannel = () => setEditingChannelWithId(undefined);

  const onChannelRemove = (indexToRemove: number) => {
    const newChannels = [...localChannels];

    newChannels.splice(indexToRemove, 1);
    setChannels(newChannels);
  };

  const onChannelEdit = (idToEdit: string | undefined) => {
    setEditingChannelWithId(idToEdit);
  };

  const rejectNewChannels = (channel: CustomerChannelDomain) => {
    if (newChannelId === undefined) {
      return true;
    }

    if (channel.id === undefined) {
      return true;
    }

    return channel.id !== newChannelId;
  };

  const addNewChannel = (newChannel: CustomerChannelDomain) => {
    const newChannels = localChannels.filter(rejectNewChannels);
    newChannels.push(
      new CustomerChannelDomain(
        undefined,
        newChannel.type,
        newChannel.handle,
        newChannel.displayHandle,
        true,
        newChannel.isSubscribed
      )
    );
    setChannels(newChannels);
    setNewChannelId(undefined);
  };

  const onCancelAddingNewChannel = () => {
    setChannels(localChannels.filter((c) => c.id !== newChannelId));
    setNewChannelId(undefined);
  };

  const openAddNewChannelForm = () => {
    const randomId = generateRandomString();
    setLocalChannels([
      ...localChannels,
      new CustomerChannelDomain(randomId, "", "", "", true, true),
    ]);
    setNewChannelId(randomId);
  };

  const canAddNewChannel = editingChannelWithId === undefined && !newChannelId;
  const shouldShowFormForNewChannel = !!newChannelId;

  return (
    <VStack alignItems="start">
      {localChannels
        .filter(rejectNewChannels)
        .map((channel: CustomerChannelDomain, index) =>
          shouldShowFormForChannel(channel.id, editingChannelWithId) ? (
            <ChannelForm
              key={channel.id}
              isNew={false}
              existingChannels={localChannels.filter(rejectNewChannels)}
              enabledChannelTypes={enabledChannelTypes}
              warnings={warnings}
              channel={channel}
              setChannel={editChannel}
              onCancel={cancelEditingChannel}
            />
          ) : (
            <ChannelRow
              key={channel.id || `new-channel-${index}`}
              channel={channel}
              index={index}
              onRemove={onChannelRemove}
              onEdit={onChannelEdit}
            />
          )
        )}
      {canAddNewChannel && (
        <Button
          key="adding-new-channel"
          width="100%"
          display="flex"
          justifyContent="space-between"
          colorPalette={colorScheme}
          variant="ghost"
          alignItems="center"
          gridGap={2}
          _hover={{
            backgroundColor: colorMode === "dark" ? "#000" : "#fff",
          }}
          size="sm"
          borderRadius="lg"
          onClick={openAddNewChannelForm}
        >
          <Text>Add new channel</Text>
          <Icon as={LuPlus} />
        </Button>
      )}
      {shouldShowFormForNewChannel && (
        <ChannelForm
          isNew={true}
          existingChannels={localChannels.filter(rejectNewChannels)}
          warnings={warnings}
          enabledChannelTypes={enabledChannelTypes}
          channel={
            newChannelId
              ? localChannels.find((c) => c.id === newChannelId)!
              : undefined
          }
          setChannel={addNewChannel}
          onCancel={onCancelAddingNewChannel}
        />
      )}
    </VStack>
  );
};

export default ChannelsArea;
