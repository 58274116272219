import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import SelectMerchant from "./select-merchant";

interface DmsOptionTypes {
  value: string;
  label: string;
}

const AddKeyloopAccount = () => {
  const auth0Context = useAuth0();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [accountName, setAccountName] = useState("");
  const [enterpriseId, setEnterpriseId] = useState("");
  const dmsOptions: DmsOptionTypes[] = [
    { value: "rev8", label: "Rev8" },
    { value: "drive", label: "Drive" },
  ];
  const [selectedDmsType, setSelectedDmsType] = useState<
    SingleValue<DmsOptionTypes>
  >(dmsOptions[0]);
  const [storeId, setStoreId] = useState("");

  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);

  const [serviceMerchant, setServiceMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const resetValues = () => {
    setAccountName("");
    setEnterpriseId("");
    setStoreId("");
    setSelectedDmsType(dmsOptions[0]);
    setSelectedMerchants([]);
  };

  const handleDmsChange = (e: SingleValue<any>) => {
    setSelectedDmsType(e);
  };

  const addKeyloopAccount = async () => {
    try {
      await AdminService.addKeyloopAccount(auth0Context, {
        accountName,
        enterpriseId,
        storeId,
        dmsType: selectedDmsType!.value,
        merchantIds: selectedMerchants.map((m) => m.id),
        serviceMerchantId: serviceMerchant?.id,
      });
      toaster.create({
        type: "success",
        title: "Keyloop account added successfully!",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't add keyloop account", e);
      toaster.create({
        type: "error",
        title: "Couldn't add keyloop account",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content>
        <Field label="Account Name" required>
          <Input
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </Field>
        <Field label="Enterprise ID" required>
          <Input
            value={enterpriseId}
            onChange={(e) => setEnterpriseId(e.target.value)}
          />
        </Field>
        <Field label="Store ID" required>
          <Input value={storeId} onChange={(e) => setStoreId(e.target.value)} />
        </Field>
        <Field label="DMS Type" required>
          <Select
            placeholder="Select DMS"
            onChange={handleDmsChange}
            options={dmsOptions}
            value={selectedDmsType}
            styles={{
              ...getReactSelectStyles(colorMode || "light", colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: "100%",
              }),
            }}
          />
        </Field>
        <Field label="Choose Merchants">
          <SelectMerchant
            isMulti={true}
            selectedMerchants={selectedMerchants}
            setSelectedMerchants={setSelectedMerchants}
          />
        </Field>
        <Field label="Choose Service Merchant">
          <SelectMerchant
            selectedMerchant={serviceMerchant}
            setSelectedMerchant={setServiceMerchant}
            filterCallback={(id) =>
              selectedMerchants.map((m) => m.id).includes(id)
            }
            isDisabled={!selectedMerchants.length}
            isClearable={true}
          />
        </Field>
      </Fieldset.Content>
      <Button
        colorPalette={colorScheme}
        onClick={addKeyloopAccount}
        disabled={!storeId || !enterpriseId || !accountName}
        mt={8}
        mb={8}
        size="lg"
      >
        Add Account
      </Button>
    </Fieldset.Root>
  );
};

export default AddKeyloopAccount;
