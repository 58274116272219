import { Flex, Icon, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import React from "react";
import { LuLock, LuLockOpen } from "react-icons/lu";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const OpenClosedToggle = () => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { isOpenOrClosed } = useAppSelector((state) => state.conversations);
  const dispatch = useAppDispatch();

  return (
    <Tooltip
      content={
        isOpenOrClosed === OpenClosedFilter.Open
          ? "Press to show closed conversations only"
          : "Press to show open conversations only"
      }
    >
      <Flex
        _hover={{
          cursor: "pointer",
        }}
        pr={isOpenOrClosed === OpenClosedFilter.Open ? 3 : 0}
        pl={isOpenOrClosed === OpenClosedFilter.Closed ? 3 : 0}
        transition="all 0.25s"
        height="2rem"
        alignItems="center"
        justifyContent={
          isOpenOrClosed === OpenClosedFilter.Open ? "flex-start" : "flex-end"
        }
        borderRadius="full"
        backgroundColor={
          isOpenOrClosed === OpenClosedFilter.Open
            ? colorMode === "dark"
              ? "green.400"
              : "green.700"
            : colorMode === "dark"
            ? "orange.400"
            : "orange.700"
        }
        onClick={() => {
          dispatch(
            setActiveConversationOpenClosedFilter(
              isOpenOrClosed === OpenClosedFilter.Open
                ? OpenClosedFilter.Closed
                : OpenClosedFilter.Open
            )
          );
        }}
        _after={
          isOpenOrClosed === OpenClosedFilter.Open && !isBaseSize
            ? {
                content: "'Open'",
                color: "white",
                ml: 2,
                width: "100%",
                justifySelf: "flex-end",
              }
            : {}
        }
        _before={
          isOpenOrClosed === OpenClosedFilter.Closed && !isBaseSize
            ? {
                content: "'Closed'",
                color: "white",
                mr: 2,
                width: "100%",
                justifySelf: "flex-start",
              }
            : {}
        }
      >
        <IconButton
          aria-label="lock"
          borderRadius="full"
          variant={colorMode === "dark" ? "subtle" : "solid"}
          size="sm"
          colorPalette={
            isOpenOrClosed === OpenClosedFilter.Open ? "green" : "orange"
          }
        >
          <Icon
            as={isOpenOrClosed === OpenClosedFilter.Open ? LuLockOpen : LuLock}
          />
        </IconButton>
      </Flex>
    </Tooltip>
  );
};

export default OpenClosedToggle;
