import { Button, Icon } from "@chakra-ui/react";
import { ReactComponent as KeyloopIcon } from "assets/icons/Keyloop-Logo-RGB-Carbon.svg";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import React from "react";
import { LuSettings } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface KeyloopIntegrationItemProps {}

const KeyloopIntegrationItem = (_props: KeyloopIntegrationItemProps) => {
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const navigate = useNavigate();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Integration
        icon={<Icon as={KeyloopIcon} height="3rem" width="8rem" />}
        primaryButton={
          merchant.isIntegrationEnabled(IntegrationName.KEYLOOP) ? (
            <Button
              colorPalette={colorScheme}
              onClick={() => {
                navigate(`/${merchant.id}/settings/integrations/keyloop`);
              }}
            >
              <Icon as={LuSettings} /> Set up
            </Button>
          ) : (
            <Button disabled={true} colorPalette={colorScheme}>
              Coming soon
            </Button>
          )
        }
      >
        <IntegrationDescription text="Seamlessly connect with clients via the worlds most popular messaging app." />
      </Integration>
    </>
  );
};

export default KeyloopIntegrationItem;
