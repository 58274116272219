import { defineRecipe } from "@chakra-ui/react";

export default defineRecipe({
  base: {
    borderRadius: "full",
  },
  variants: {
    variant: {
      solid: {
        borderColor: "transparent",
        borderBlockColor: "transparent",
        borderInlineColor: "transparent",
        background: "gray.50",
        backgroundColor: "gray.50",
        bg: "gray.50",
        _dark: {
          background: "black",
          backgroundColor: "black",
          bg: "black",
        },
      },
    },
  },
  defaultVariants: {
    variant: "solid",
  },
});
