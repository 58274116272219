import {
  Button,
  HStack,
  Table,
  Text,
} from "@chakra-ui/react";
import { Checkbox } from "components/ui/checkbox";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { WidgetCustomChannelDomain } from "entities/domain/widget";
import React, { useEffect, useState } from "react";

type CustomChannelsEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (customChannels: WidgetCustomChannelDomain[]) => void;
  widgetCustomChannels?: WidgetCustomChannelDomain[];
  channelsAvailable?: ChannelDomain[];
};

const CustomChannelsEdit = ({
  isOpen,
  onClose,
  onSave,
  widgetCustomChannels,
  channelsAvailable,
}: CustomChannelsEditProps) => {
  const [editingCustomChannels, setEditingCustomChannels] = useState(
    new Map<string, WidgetCustomChannelDomain>()
  );
  const [availableChannelsByType, setAvailableChannelsByType] = useState(
    new Map<string, ChannelDomain[]>()
  );

  useEffect(() => {
    if (channelsAvailable) {
      const availableChannelsMapping = channelsAvailable.reduce(
        (acc, channel) => {
          if (acc.get(channel.channelName)) {
            acc.set(channel.channelName, [
              ...acc.get(channel.channelName)!,
              channel,
            ]);
          } else {
            acc.set(channel.channelName, [channel]);
          }
          return acc;
        },
        new Map<string, ChannelDomain[]>()
      );

      const existingConfigByType =
        widgetCustomChannels?.reduce((acc, channel) => {
          if (!acc.get(channel.type)) {
            acc.set(channel.type, { ...channel });
          }
          return acc;
        }, new Map<string, WidgetCustomChannelDomain>()) ||
        new Map<string, WidgetCustomChannelDomain>();

      availableChannelsMapping.forEach((channel, type) => {
        if (!existingConfigByType?.get(type)) {
          existingConfigByType.set(type, { type, disabled: false });
        }
      });

      setEditingCustomChannels(existingConfigByType);
      setAvailableChannelsByType(availableChannelsMapping);
    }
  }, [channelsAvailable, widgetCustomChannels]);

  const handleChannelStatusChange = (channelName: string, checked: boolean) => {
    if (editingCustomChannels.get(channelName)) {
      const updatedMap = new Map(editingCustomChannels);

      updatedMap.set(channelName, {
        type: channelName,
        disabled: checked,
        handle: undefined,
      });

      setEditingCustomChannels(updatedMap);
    }
  };

  const handleChannelHandleChange = (
    channelName: string,
    handle: string,
    checked: boolean
  ) => {
    if (editingCustomChannels.get(channelName)) {
      const updatedMap = new Map(editingCustomChannels);

      updatedMap.set(channelName, {
        type: channelName,
        disabled: false,
        handle: checked ? handle : undefined,
      });
      setEditingCustomChannels(updatedMap);
    }
  };

  const handleSave = () => {
    const filteredCustomChannel = Array.from(
      editingCustomChannels.values()
    ).filter((channel) => channel.disabled || channel.handle);

    onSave(filteredCustomChannel);
    onClose();
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }} size="lg">
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>Widget Custom Channels</DialogHeader>
        <DialogBody>
          {availableChannelsByType && editingCustomChannels && (
            <Table.Root variant="line">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeader>Channel</Table.ColumnHeader>
                  <Table.ColumnHeader>Disabled</Table.ColumnHeader>
                  <Table.ColumnHeader>Handle / Default</Table.ColumnHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.from(availableChannelsByType).map(([key, channels]) => (
                  <Table.Row key={key}>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        value="true"
                        defaultChecked={
                          editingCustomChannels.get(key)?.disabled === true
                        }
                        checked={
                          editingCustomChannels.get(key)?.disabled === true
                        }
                        onCheckedChange={(e) =>
                          handleChannelStatusChange(key, !!e.checked)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {channels.map((channel) => (
                        <HStack justifyContent="space-between" key={channel.id}>
                          <Text truncate maxWidth="200px">
                            {channel.identifier}
                          </Text>
                          <Checkbox
                            value="true"
                            defaultChecked={
                              editingCustomChannels.get(key)?.handle ===
                              channel.identifier
                            }
                            disabled={editingCustomChannels.get(key)?.disabled}
                            checked={
                              editingCustomChannels.get(key)?.handle ===
                              channel.identifier
                            }
                            onCheckedChange={(e) =>
                              handleChannelHandleChange(
                                key,
                                channel.identifier,
                                !!e.checked
                              )
                            }
                          />
                        </HStack>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          )}
        </DialogBody>

        <DialogFooter>
          <Button colorPalette="blue" mr={3} onClick={() => handleSave()}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default CustomChannelsEdit;
