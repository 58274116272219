import { Button } from "@chakra-ui/react";
import PhoneInputWithSuspense, {
  Country,
} from "components/shared/LazyPhoneInput";
import { useColorMode } from "components/ui/color-mode";
import { InputGroup } from "components/ui/input-group";
import React, { memo, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface TestMessageSenderProps {
  campaignMessage: string | null;
  campaignTemplateName: string | null;
  prefix?: string;
  onSendTestMessage: (handle: string, text: string) => void;
  isSendingTestMessage: boolean;
}

const TestMessageSender = ({
  campaignMessage,
  campaignTemplateName,
  isSendingTestMessage,
  prefix,
  onSendTestMessage,
}: TestMessageSenderProps) => {
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { templates } = useAppSelector((state) => state.templates);
  const [testPhoneNumber, setTestPhoneNumber] = useState<string>(
    currentAgent!.notificationConfig?.handle || ""
  );

  const getMessageWithoutPrefix = (m: string) => m.trim().split(":").pop();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const newMessage = getMessageWithoutPrefix(campaignMessage || "");
    const newMessageFromTemplate =
      templates.find((t) => t.name === campaignTemplateName)?.getWholeText() ||
      "";

    setMessage(newMessage || newMessageFromTemplate || "");
  }, [campaignMessage, campaignTemplateName, templates]);

  return (
    <InputGroup
      css={{
        width: "100%",
        lineHeight: "1rem",
        "& .PhoneInput": {
          width: "100%",
        },
        "& .PhoneInputInput": {
          borderRadius: "999px",
          width: "100%",
          paddingRight: "4.5rem",
          borderColor: `${colorScheme}.400`,
          backgroundColor: colorMode === "dark" ? "black" : "gray.50",
          opacity: isSendingTestMessage ? "0.5" : "1",
          _placeholder: {
            fontStyle: "italic",
          },
        },
      }}
      endElement={
        <Button
          size="xs"
          colorPalette="green"
          loading={isSendingTestMessage}
          disabled={
            !getMessageWithoutPrefix(message) ||
            isSendingTestMessage ||
            !testPhoneNumber
          }
          onClick={() => {
            onSendTestMessage(
              `${
                campaignTemplateName && !campaignMessage ? "" : `${prefix} `
              }${message}`,
              testPhoneNumber
            );
          }}
        >
          {isSendingTestMessage ? "Sending..." : "Send"}
        </Button>
      }
    >
      <PhoneInputWithSuspense
        id="phoneInput"
        placeholder="Enter phone number"
        onChange={(newNumber: string | undefined) =>
          setTestPhoneNumber(newNumber as string)
        }
        defaultCountry={merchant.getMerchantLocation() as Country}
        value={testPhoneNumber}
        autoFocus={false}
        disabled={isSendingTestMessage}
      />
    </InputGroup>
  );
};

export default memo(TestMessageSender);
