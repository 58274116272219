import { useBreakpointValue } from "@chakra-ui/react";
import { ExtendedImportOptions } from "components/modals/import-contacts/ExtendedImportContactsTypes";
import UploadViaCsvOrIcloud from "components/modals/import-contacts/UploadViaFile";
import {
  DialogBackdrop,
  DialogContent,
  DialogRoot,
} from "components/ui/dialog";
import { toaster } from "components/ui/toaster";
import AudienceDomain from "entities/domain/audience";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useAnalytics from "hooks/use-analytics";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface UploadAudienceModalProps {
  isOpen: boolean;
  campaignId: string | null;
  defaultChannels: ConversationChannel[];
  setIsOpen: (isOpen: boolean) => void;
  setCampaignAudienceId: (id: string | null) => void;
  handleAudienceSave: (
    callback: (audience: AudienceDomain | undefined) => void,
    audience?: AudienceDomain,
    uploadTagId?: string
  ) => void;
}

const UploadAudienceModal = ({
  campaignId,
  isOpen,
  defaultChannels,
  setCampaignAudienceId,
  handleAudienceSave,
  setIsOpen,
}: UploadAudienceModalProps) => {
  const { track } = useAnalytics();
  const { merchant } = useAppSelector((state) => state.merchant);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => setIsOpen(newIsOpen)}
      size={isBaseSize ? "full" : "lg"}
    >
      <DialogBackdrop />
      <DialogContent>
        <UploadViaCsvOrIcloud
          defaultChannels={defaultChannels}
          afterUploadAsyncCallback={async (
            uploadTagId: string | undefined,
            uploadTag: string | undefined
          ) => {
            track("new_auto_generated_audience_attempt", {
              campaign_id: campaignId,
            });

            if (!uploadTagId || !uploadTag) {
              toaster.create({
                type: "error",
                title: "There was an error uploading this document.",
                description:
                  "Please, try again later or if the problem persists - contact us for support",
              });

              return;
            }

            const freshAudience = new AudienceDomain(
              null,
              AudienceDomain.generateName(),
              merchant.id,
              0,
              null
            );

            freshAudience.addIncludedTagId(uploadTagId);

            handleAudienceSave(
              (createdAudience: AudienceDomain | undefined) => {
                track("new_auto_generated_audience_attempt_succeeded", {
                  campaign_id: campaignId,
                  audience_id: createdAudience?.id || null,
                });

                if (createdAudience && createdAudience.id) {
                  setCampaignAudienceId(createdAudience.id);
                  toaster.create({
                    type: "success",
                    id: createdAudience.id,
                    title: "Audience created successfully!",
                  });
                } else {
                  toaster.create({
                    type: "error",
                    title: "There was an error uploading this document.",
                    description:
                      "Please, try again later or if the problem persists - contact us for support",
                  });
                }

                setIsOpen(false);
              },
              freshAudience,
              uploadTagId
            );
          }}
          uploadMethod={ExtendedImportOptions.CSV}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogContent>
    </DialogRoot>
  );
};

export default UploadAudienceModal;
