import { Flex, HStack, Link } from "@chakra-ui/react";
import AdvancedFilterModal from "components/contacts/top-area/AdvancedFilterModal";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { useAppSelector } from "redux/hooks";
import AddNewContact from "../AddNew";
import ImportExportButtons from "./ImportExportButtons";

interface DesktopTopbarProps {
  hideFilters?: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
  prefilledName?: string;
}
const DesktopTopbar = ({
  hideFilters = false,
  searchText,
  prefilledName,
  setSearchText,
  setRecentlyCreatedContact,
}: DesktopTopbarProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <HStack gap={4} p={4}>
      {hideFilters ? (
        <Link href={`/${merchant.id}/contacts`} color={`${colorScheme}.400`}>
          Load all contacts
        </Link>
      ) : (
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="space-between"
          gridGap={2}
        >
          <HStack flexGrow={1} flexShrink={0} flexBasis="auto">
            <AddNewContact
              prefilledName={prefilledName}
              setRecentlyCreatedContact={setRecentlyCreatedContact}
            />
            <ReviewsSearch
              placeholder="John Doe"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <AdvancedFilterModal />
          </HStack>
          <ImportExportButtons />
        </Flex>
      )}
    </HStack>
  );
};

export default DesktopTopbar;
