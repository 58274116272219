import { Icon } from "@chakra-ui/react";
import React from "react";
import { ReactSVG } from "react-svg";

interface FooterIconProps {
  asChild?: boolean;
  src?: string;
  w?: number;
  h?: number;
  fill?: string;
  width?: string;
  height?: string;
  children?: React.ReactNode;
}

const FooterIcon = ({
  asChild = true,
  src,
  w = 8,
  h = 8,
  fill,
  width = "2rem",
  height = "2rem",
  children,
}: FooterIconProps) => {
  const additionalProps: { [key: string]: string } = {};

  if (src) {
    additionalProps.src = src;
  }

  return asChild && src ? (
    <Icon
      asChild={true}
      w={w}
      h={h}
      {...additionalProps}
      css={{
        "& path": {
          fill: fill || "white",
        },
        "& rect": {
          fill: "none",
        },
        "& svg": {
          width,
          height,
        },
      }}
    >
      <ReactSVG src={src} />
    </Icon>
  ) : (
    <Icon w={w} h={h} {...additionalProps} color={fill || "white"}>
      {children}
    </Icon>
  );
};

export default FooterIcon;
