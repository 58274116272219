import { Flex, Spinner } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface TypingZoneOverlayProps {
  isShown: boolean;
  borderRadius: string;
}

const TypingZoneOverlay = ({
  isShown,
  borderRadius,
}: TypingZoneOverlayProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex
      transition="all 0.5s ease-in-out 0.5s"
      borderRadius={borderRadius}
      position="absolute"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      width="100%"
      height="100%"
      opacity={isShown ? 0.8 : 0}
      zIndex={isShown ? 100 : -1}
      top={0}
      left={0}
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Flex>
  );
};

export default TypingZoneOverlay;
