import { defineSlotRecipe } from "@chakra-ui/react";
import { dialogAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: dialogAnatomy.keys(),
  base: {
    content: {
      borderRadius: "3xl",
    },
    header: {
      fontWeight: "bold",
      fontSize: "lg",
    },
    backdrop: {
      bg: "blackAlpha.500",
      _dark: {
        bg: "blackAlpha.300",
      },
    },
  },
  variants: {
    size: {
      full: {
        content: {
          borderRadius: "0!important",
        },
      },
    },
  },
});
