import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";

interface NoChatProps {}

const NoChat = (_props: NoChatProps) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  if (isBaseSize || !window.location.pathname.includes("/inbox")) {
    return null;
  }

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      backgroundColor={colorMode === "dark" ? "gray.800" : "white"}
      borderTopRightRadius="3xl"
      gridGap={3}
    />
  );
};

export default NoChat;
