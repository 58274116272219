import { Box, Flex, Icon, MenuItem } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaBullhorn, FaChartArea } from "react-icons/fa";
import {
  LuCalendar,
  LuCreditCard,
  LuMail,
  LuSettings,
  LuStar,
} from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface MobileBrowserNavigationButtonProps {
  icon: any;
  name: string;
  link: string;
  hasNotifications?: boolean;
  isDisabled?: boolean;
}

const MobileBrowserNavigationButton = ({
  icon,
  hasNotifications = false,
  name,
  link,
  isDisabled,
}: MobileBrowserNavigationButtonProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Box
      css={{
        "& a.selected > div": {
          bgColor: colorMode === "dark" ? `gray.800` : `${colorScheme}.400`,
        },
        "& > a.selected svg": {
          color: "white",
        },
      }}
    >
      <NavLink
        to={link}
        className={({ isActive }) => (isActive ? "selected" : "")}
      >
        <MenuItem value={name} disabled={isDisabled}>
          <Flex position="relative" alignItems="center" justifyContent="center">
            <Icon
              as={icon}
              width="2rem"
              height="2rem"
              color={colorMode === "dark" ? "white" : "gray.300"}
            />
            {hasNotifications ? (
              <Box
                position="absolute"
                top="-0.25rem"
                right="-0.25rem"
                zIndex="1"
                borderRadius="full"
                height="1rem"
                width="1rem"
                bgColor={colorMode === "dark" ? "red.200" : "red.500"}
              />
            ) : null}
          </Flex>{" "}
          {name}
        </MenuItem>
      </NavLink>
    </Box>
  );
};

export const InboxLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { unreadCounts } = useAppSelector((state) => state.agents);
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);

  return (
    <MobileBrowserNavigationButton
      hasNotifications={!!unreadCount?.personal || !!unreadCount?.shared}
      icon={LuMail}
      name="Inbox"
      link={`/${merchant.id}/inbox`}
    />
  );
};

export const ContactsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={AiOutlineUser}
      name="Contacts"
      link={`/${merchant.id}/contacts`}
    />
  );
};

export const CalendarLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={LuCalendar}
      name="Calendar"
      link={`/${merchant.id}/calendar`}
    />
  );
};

export const PaymentsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={LuCreditCard}
      name="Payments"
      link={`/${merchant.id}/payments`}
    />
  );
};

export const CampaignsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={FaBullhorn}
      name="Campaigns"
      link={`/${merchant.id}/campaigns`}
    />
  );
};

export const ReviewsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={LuStar}
      name="Reviews"
      link={`/${merchant.id}/reviews`}
    />
  );
};

export const AnalyticsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={FaChartArea}
      name="Analytics"
      link={`/${merchant.id}/analytics`}
    />
  );
};

export const SettingsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={LuSettings}
      name="Settings"
      link={`/${merchant.id}/settings`}
    />
  );
};

export default MobileBrowserNavigationButton;
