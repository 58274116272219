import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Button as ChakraButton,
  Fieldset,
  HStack,
  Icon,
  Image,
  Input,
  List,
  Table,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Alert } from "components/ui/alert";
import { Checkbox } from "components/ui/checkbox";
import {
  ClipboardButton,
  ClipboardInput,
  ClipboardRoot,
} from "components/ui/clipboard";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { InputGroup } from "components/ui/input-group";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import { WidgetConfigDomain } from "entities/domain/widget";
import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { LuArrowRight, LuFilePen } from "react-icons/lu";

import Select, { SingleValue } from "react-select";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import WidgetService from "services/widget";
import { getReactSelectStyles } from "util/methods";
import SelectMerchant from "../select-merchant";
import CustomChannelsEdit from "./custom-channels-edit";

type WidgetOptionTypes = {
  value: string;
  id: string;
  label: string;
};

type WidgetConfigEdit = {
  removeLogo?: boolean;
  removeButtonImage?: boolean;
} & WidgetConfigDomain;

const createWidgetScript = (widgetId: string | undefined): string => {
  if (widgetId) {
    return `<script src="https://widget.fuzey.io/static/js/widget.js" id="fuzey-widget" widget-id="${widgetId}"></script>`;
  }
  return "";
};

const WidgetConfig = () => {
  const reader = new FileReader();

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const [merchantWidgets, setMerchantWidgets] = useState<
    WidgetConfigDomain[] | null
  >(null);

  const [widgetOptions, setWidgetOptions] = useState<
    WidgetOptionTypes[] | null
  >(null);

  const [selectedWidget, setSelectedWidget] =
    useState<SingleValue<WidgetOptionTypes>>(null);

  const [currentWidgetConfig, setCurrentWidgetConfig] =
    useState<WidgetConfigEdit | null>(null);

  const [customChannelsEditOpen, setCustomChannelsEditOpen] = useState(false);

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const dispatch = useAppDispatch();

  const { files, images } = useAppSelector((state) => state.attachments);
  const auth0Context = useAuth0();
  const fileInput = useRef<HTMLInputElement>(null);
  const buttonImageInput = useRef<HTMLInputElement>(null);

  const loadWidgets = async () => {
    setSelectedWidget(null);

    if (selectedMerchant) {
      const returnedWidgets = await WidgetService.fetchWidgets(
        selectedMerchant.id,
        auth0Context
      );
      setMerchantWidgets(returnedWidgets);

      const widgets =
        returnedWidgets?.map((widget) => ({
          value: widget.id,
          id: widget.id,
          label: widget.alias,
        })) || [];

      setWidgetOptions([
        {
          value: "new",
          id: "new",
          label: "+ Create new widget",
        },
        ...widgets,
      ]);
    } else {
      setWidgetOptions(null);
    }
  };

  const removePreview = () => {
    document.getElementById("fuzey-widget")?.remove();
    document.getElementById("fuzey-widget-root")?.remove();
    if (selectedWidget) {
      localStorage.removeItem(selectedWidget.id);
    }
  };

  const resetFiles = () => {
    dispatch(clearAttachments());
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  useEffect(() => {
    resetFiles();
    removePreview();
    loadWidgets();
  }, [selectedMerchant]);

  useEffect(() => {
    if (selectedWidget?.id === "new") {
      setCurrentWidgetConfig({ id: "new" } as WidgetConfigEdit);
    } else if (selectedWidget) {
      const selectedWidgetConfig = merchantWidgets?.find(
        (widget) => widget.id === selectedWidget.id
      );

      if (selectedWidgetConfig) {
        setCurrentWidgetConfig({
          ...selectedWidgetConfig,
          removeLogo: false,
          removeButtonImage: false,
        });
      }
    } else {
      setCurrentWidgetConfig(null);
    }
  }, [selectedWidget]);

  const handleWidgetIdChange = (e: SingleValue<WidgetOptionTypes>) => {
    setSelectedWidget(e);
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    };
  };

  const onButtonImageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    };
  };

  const saveConfig = async () => {
    try {
      const isLogoSelected = fileInput.current?.files?.length === 1;
      const isButtonImageSelected =
        buttonImageInput.current?.files?.length === 1;

      if (selectedMerchant && currentWidgetConfig) {
        const command = {
          alias: currentWidgetConfig?.alias,
          primary_colour: currentWidgetConfig?.primaryColour,
          secondary_colour: currentWidgetConfig?.secondaryColour,
          delete_logo: currentWidgetConfig?.removeLogo,
          delete_button_image: currentWidgetConfig?.removeButtonImage,
          ...(files.length === 2
            ? {
                logo: files[0],
                button_image: files[1],
              }
            : {
                ...(isLogoSelected
                  ? {
                      logo: files[0],
                    }
                  : {}),
                ...(isButtonImageSelected
                  ? {
                      button_image: files[0],
                    }
                  : {}),
              }),
          contact_form_url: currentWidgetConfig?.externalContactFormUrl,
          hide_powered_by: currentWidgetConfig?.hidePoweredBy,
          hide_contact_form: currentWidgetConfig?.hideContactForm,
          prevent_auto_open: currentWidgetConfig?.preventAutoOpen,
          auto_open_delay: currentWidgetConfig?.autoOpenDelay,
          location_name: currentWidgetConfig?.locationName,
          button_title: currentWidgetConfig?.buttonTitle,
          default_text: currentWidgetConfig?.defaultText,
          success_title: currentWidgetConfig?.successTitle,
          success_subtitle: currentWidgetConfig?.successSubtitle,
          custom_channels: currentWidgetConfig?.customChannels
            ? JSON.stringify(
                currentWidgetConfig?.customChannels?.map((channel) => ({
                  type: channel.type,
                  handle: channel.handle,
                  disabled: channel.disabled,
                }))
              )
            : null,
        };

        if (currentWidgetConfig.id === "new") {
          await WidgetService.createWidgetConfig(
            selectedMerchant.id,
            command,
            auth0Context
          );
        } else {
          await WidgetService.updateWidgetConfig(
            selectedMerchant.id,
            currentWidgetConfig.id,
            command,
            auth0Context
          );
        }

        resetFiles();
        removePreview();
        loadWidgets();

        toaster.create({
          type: "success",
          title: "Widget Config has been saved successfully.",
        });
      }
    } catch (err) {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    }
  };

  const handleSaveConfig = () => {
    saveConfig();
  };

  const handlePreview = () => {
    removePreview();

    const script = document.createElement("script");
    script.setAttribute(
      "src",
      `${process.env.REACT_APP_WIDGET_URL}/static/js/widget.js`
    );
    script.setAttribute("id", "fuzey-widget");
    script.setAttribute("widget-id", selectedWidget!.id);

    if (currentWidgetConfig) {
      const {
        primaryColour,
        secondaryColour,
        externalContactFormUrl,
        buttonTitle,
      } = currentWidgetConfig;

      if (buttonTitle) {
        script.setAttribute("button-title", buttonTitle);
      }

      if (primaryColour) {
        script.setAttribute("primary-colour", primaryColour);
      }

      if (secondaryColour) {
        script.setAttribute("secondary-colour", secondaryColour);
      }

      if (externalContactFormUrl) {
        script.setAttribute("external-contact-form", externalContactFormUrl);
      }
    }

    if (images && images.length) {
      if (images.length === 2) {
        script.setAttribute("logo-base64", images[0]);
        script.setAttribute("button-image-url", images[1]);
      } else if (images.length === 1) {
        const isLogoSelected = fileInput.current?.files?.length === 1;
        const isButtonImageSelected =
          buttonImageInput.current?.files?.length === 1;

        if (isLogoSelected) {
          script.setAttribute("logo-base64", images[0]);
        } else if (isButtonImageSelected) {
          script.setAttribute("button-image-url", images[0]);
        }
      }
    }

    document.head.appendChild(script);
  };

  return (
    <>
      <Fieldset.Root>
        <Fieldset.Content>
          <Field label="Select Merchant" required>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            />
          </Field>
          {widgetOptions && (
            <Field label="Select Widget">
              <Select
                placeholder="Select Widget"
                value={selectedWidget}
                onChange={handleWidgetIdChange}
                options={widgetOptions}
                styles={{
                  ...getReactSelectStyles(colorMode || "light", colorScheme),
                  menu: (provided: any) => ({
                    ...getReactSelectStyles(
                      colorMode || "light",
                      colorScheme
                    ).menu(provided),
                    position: "relative",
                  }),
                }}
              />
            </Field>
          )}
          {currentWidgetConfig && (
            <>
              <Field label="ID - Used on the widget script">
                <ClipboardRoot value={currentWidgetConfig?.id || ""}>
                  <InputGroup endElement={<ClipboardInput />}>
                    <Input
                      value={
                        currentWidgetConfig.id !== "new"
                          ? currentWidgetConfig.id
                          : ""
                      }
                      disabled={true}
                    />
                  </InputGroup>
                </ClipboardRoot>
                <ClipboardRoot
                  value={createWidgetScript(currentWidgetConfig?.id)}
                >
                  <ClipboardButton name="Copy Script" />
                </ClipboardRoot>
              </Field>
              <Field label="Alias">
                <Input
                  value={currentWidgetConfig.alias || "Home Page"}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      alias: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Location Name">
                <Alert
                  status="info"
                  title="Locations can be nested within departments!"
                >
                  <HStack w="100%" h="100%" alignItems="start" mt={4}>
                    <List.Root
                      w="100%"
                      p={4}
                      pl={6}
                      ml={0}
                      h="100%"
                      borderRadius="lg"
                      bgColor={colorMode === "dark" ? "gray.900" : "gray.100"}
                    >
                      <List.Item>Sales / Kia / New Cars</List.Item>
                      <List.Item>Sales / Kia / Used Cars</List.Item>
                      <List.Item>Sales / Honda / New Cars</List.Item>
                      <List.Item>Sales / Honda / Used Cars</List.Item>
                    </List.Root>
                    <Icon alignSelf="center" as={LuArrowRight} boxSize={10} />
                    <HStack
                      w="100%"
                      position="relative"
                      h="100%"
                      justifyContent="space-between"
                      p={4}
                      borderRadius="lg"
                      bgColor={colorMode === "dark" ? "gray.900" : "gray.100"}
                    >
                      <Text
                        _after={{
                          content: '""',
                          display: "block",
                          width: "10%",
                          height: "1px",
                          backgroundColor:
                            colorMode === "dark" ? "white" : "black",
                          position: "absolute",
                          top: "40%",
                          left: "30%",
                          transform: "translate(-50%, -50%) rotate(140deg)",
                        }}
                        _before={{
                          content: '""',
                          display: "block",
                          width: "10%",
                          height: "1px",
                          backgroundColor:
                            colorMode === "dark" ? "white" : "black",
                          position: "absolute",
                          top: "60%",
                          left: "30%",
                          transform: "translate(-50%, -50%) rotate(40deg)",
                        }}
                      >
                        Sales
                      </Text>
                      <VStack
                        w="100"
                        h="100%"
                        gap={2}
                        justifyContent="space-evenly"
                      >
                        <HStack
                          w="100%"
                          h="100%"
                          justifyContent="space-between"
                          position="relative"
                        >
                          <Text
                            _after={{
                              content: '""',
                              display: "block",
                              width: "30%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "30%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(165deg)",
                            }}
                            _before={{
                              content: '""',
                              display: "block",
                              width: "20%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "70%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(15deg)",
                            }}
                          >
                            Kia
                          </Text>
                          <VStack w="100%" h="100%" alignItems="end">
                            <Text>New Cars</Text>
                            <Text>Used Cars</Text>
                          </VStack>
                        </HStack>
                        <HStack
                          w="100%"
                          h="100%"
                          justifyContent="space-between"
                          position="relative"
                        >
                          <Text
                            _after={{
                              content: '""',
                              display: "block",
                              width: "30%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "30%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(165deg)",
                            }}
                            _before={{
                              content: '""',
                              display: "block",
                              width: "20%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "75%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(15deg)",
                            }}
                          >
                            Honda
                          </Text>
                          <VStack w="100%" h="100%" alignItems="end">
                            <Text>New Cars</Text>
                            <Text>Used Cars</Text>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                </Alert>
                <Input
                  value={currentWidgetConfig.locationName || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      locationName: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Primary Colour">
                <Input
                  value={currentWidgetConfig.primaryColour || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      primaryColour: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Secondary Colour">
                <Input
                  value={currentWidgetConfig.secondaryColour || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      secondaryColour: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Exernal Contact Form URL">
                <Input
                  value={currentWidgetConfig.externalContactFormUrl || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      externalContactFormUrl: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Button Title">
                <Input
                  value={currentWidgetConfig.buttonTitle || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      buttonTitle: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Default Text">
                <Input
                  value={currentWidgetConfig.defaultText || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      defaultText: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Successful Enquiry Title">
                <Input
                  value={currentWidgetConfig.successTitle || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      successTitle: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Successful Enquiry Subtitle">
                <Input
                  value={currentWidgetConfig.successSubtitle || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      successSubtitle: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Hide Powered By">
                <Checkbox
                  value="true"
                  defaultChecked={currentWidgetConfig.hidePoweredBy === true}
                  checked={currentWidgetConfig.hidePoweredBy === true}
                  onCheckedChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      hidePoweredBy: !!e.checked,
                    })
                  }
                />
              </Field>
              <Field label="Hide Contact Form">
                <Checkbox
                  value="true"
                  defaultChecked={currentWidgetConfig.hideContactForm === true}
                  checked={currentWidgetConfig.hideContactForm === true}
                  onCheckedChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      hideContactForm: !!e.checked,
                    })
                  }
                />
              </Field>
              <Field label="Prevent Auto Open">
                <Checkbox
                  value="true"
                  defaultChecked={currentWidgetConfig.preventAutoOpen === true}
                  checked={currentWidgetConfig.preventAutoOpen === true}
                  onCheckedChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      preventAutoOpen: !!e.checked,
                    })
                  }
                />
              </Field>
              <Field label="Auto Open Delay">
                <Input
                  type="number"
                  value={currentWidgetConfig.autoOpenDelay || ""}
                  onChange={(e) =>
                    setCurrentWidgetConfig({
                      ...currentWidgetConfig,
                      autoOpenDelay: +e.target.value,
                    })
                  }
                />
              </Field>
              <Field
                label={
                  <HStack>
                    <Text>Custom Channels</Text>
                    <ChakraButton
                      unstyled={true}
                      onClick={() => setCustomChannelsEditOpen(true)}
                    >
                      <LuFilePen />
                    </ChakraButton>
                  </HStack>
                }
              >
                {currentWidgetConfig.customChannels && (
                  <Table.Root variant="line">
                    <Table.Header>
                      <Table.Row>
                        <Table.ColumnHeader>Channel</Table.ColumnHeader>
                        <Table.ColumnHeader>Handle</Table.ColumnHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {currentWidgetConfig.customChannels?.map(
                        (customChannel) => (
                          <Table.Row>
                            <Table.Cell>{customChannel.type}</Table.Cell>
                            <Table.Cell>
                              {customChannel.disabled
                                ? "Disabled"
                                : customChannel.handle}
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table.Root>
                )}
                {!currentWidgetConfig.customChannels && (
                  <Text>No custom channels</Text>
                )}
              </Field>
              <Field label="Logo">
                {currentWidgetConfig.logoUrl && (
                  <>
                    <Box
                      backgroundColor={
                        currentWidgetConfig.primaryColour || "initial"
                      }
                    >
                      <Image src={currentWidgetConfig.logoUrl} maxH="100px" />
                    </Box>
                    <HStack gap={3}>
                      <Checkbox
                        checked={currentWidgetConfig.removeLogo}
                        onCheckedChange={(e) =>
                          setCurrentWidgetConfig({
                            ...currentWidgetConfig,
                            removeLogo: !!e.checked,
                          })
                        }
                      />
                      <Text as="span">Remove</Text>
                    </HStack>
                  </>
                )}
                <Box pt={5}>
                  <input
                    ref={fileInput}
                    type="file"
                    accept={"image/*"}
                    onChange={onFileChange}
                  />
                </Box>
              </Field>

              <Field label="Button Image">
                {currentWidgetConfig.buttonImageUrl && (
                  <>
                    <Box
                      backgroundColor={
                        currentWidgetConfig.primaryColour || "initial"
                      }
                    >
                      <Image
                        src={currentWidgetConfig.buttonImageUrl}
                        maxH="50px"
                      />
                    </Box>
                    <HStack gap={3}>
                      <Checkbox
                        checked={currentWidgetConfig.removeButtonImage}
                        onCheckedChange={(e) =>
                          setCurrentWidgetConfig({
                            ...currentWidgetConfig,
                            removeButtonImage: !!e.checked,
                          })
                        }
                      />
                      <Text as="span">Remove</Text>
                    </HStack>
                  </>
                )}
                <Box pt={5}>
                  <input
                    ref={buttonImageInput}
                    type="file"
                    accept={"image/*"}
                    onChange={onButtonImageChange}
                  />
                </Box>
              </Field>
            </>
          )}
        </Fieldset.Content>
        <HStack justify="center">
          <Button
            onClick={handlePreview}
            disabled={!selectedMerchant}
            mt={30}
            mb={30}
            size="lg"
          >
            Preview
          </Button>

          <Button
            onClick={handleSaveConfig}
            disabled={!selectedMerchant}
            mt={30}
            mb={30}
            size="lg"
          >
            {selectedWidget?.id === "new" ? "Create" : "Save"}
          </Button>
        </HStack>
      </Fieldset.Root>
      <CustomChannelsEdit
        isOpen={customChannelsEditOpen}
        onClose={() => {
          setCustomChannelsEditOpen(false);
        }}
        onSave={(customChannels) => {
          setCurrentWidgetConfig({
            ...currentWidgetConfig,
            customChannels,
          } as WidgetConfigDomain);
        }}
        widgetCustomChannels={currentWidgetConfig?.customChannels}
        channelsAvailable={selectedMerchant?.channels || []}
      />
    </>
  );
};

export default WidgetConfig;
