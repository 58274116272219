import {
  Button as ChakraButton,
  Spinner,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { OptionTypes } from "components/shared/filter";
import Tag from "components/tags";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { tagToTagOption } from "entities/transformers/tags-transformer";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useRef, useState } from "react";
import {
  components,
  IndicatorSeparatorProps,
  MenuListProps,
  MultiValue,
  OptionProps,
} from "react-select";
import Creatable from "react-select/creatable";
import { ViewportList } from "react-viewport-list";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface UpdateTagsInterface {
  isOpen: boolean;
  onClose: () => void;
  tagIds: string[];
  onSubmit: (selectedTagIds: string[]) => void;
  isRemoval?: boolean;
}

const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

const IndicatorSeparator = ({
  innerProps,
}: IndicatorSeparatorProps<OptionTypes, true>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

export const TagSelectOption: React.FC<OptionProps<OptionTypes, false>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <Tag label={props.label} color={props.data.color} my={1} />
    </components.Option>
  );
};

export const TagMultiSelectOption: React.FC<OptionProps<OptionTypes, true>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <Tag label={props.label} color={props.data.color} my={1} />
    </components.Option>
  );
};

export const TagsMenuList: React.FC<MenuListProps<OptionTypes, boolean>> = (
  props
) => {
  const { children, maxHeight } = props;
  const scrollableWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <VStack
      {...props}
      w="100%"
      gap={0}
      justifyContent="start"
      alignItems="start"
      overflowY="auto"
      overflowX="hidden"
      maxHeight={maxHeight}
      ref={scrollableWrapperRef}
    >
      <ViewportList
        viewportRef={scrollableWrapperRef}
        items={children as React.ReactNode[]}
        scrollThreshold={1000}
      >
        {(item) => item}
      </ViewportList>
    </VStack>
  );
};

const sortTagOptions = (a: OptionTypes, b: OptionTypes) => {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
};

const UpdateTags = ({
  isOpen,
  onClose,
  tagIds,
  onSubmit,
  isRemoval = false,
}: UpdateTagsInterface) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { tags } = useAppSelector((state) => state.tags);
  const { addMerchantTags } = useMerchantStore();

  const [newTag, setNewTag] = useState<OptionTypes | undefined>(undefined);
  const [availableTagOptions, setAvailableTagOptions] = useState<OptionTypes[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTagOptions, setSelectedTagOptions] = useState<OptionTypes[]>(
    []
  );

  const reset = () => {
    setIsLoading(false);
    setNewTag(undefined);
    setSelectedTagOptions(
      tags.filter((t) => tagIds.includes(t.id)).map(tagToTagOption)
    );
    setAvailableTagOptions(tags.map(tagToTagOption).sort(sortTagOptions));
  };

  const handleNewTagSubmit = (tagToAdd: string) => {
    addMerchantTags([tagToAdd]).then((res) => {
      const newTagOptionType = tagToTagOption(res![0]);
      setSelectedTagOptions([...selectedTagOptions, newTagOptionType]);
      setNewTag(undefined);
    });
  };

  const handleSelectChange = (selectedOptions: MultiValue<OptionTypes>) => {
    if (
      newTag &&
      selectedOptions.filter((o) => o.value === newTag.value).length > 0
    ) {
      handleNewTagSubmit(newTag.value);
    } else {
      setSelectedTagOptions(selectedOptions as OptionTypes[]);
    }
  };

  const handleInputChange = (tagValue: string) => {
    if (isRemoval) {
      return;
    }

    if (tagValue.length > 0) {
      setNewTag({
        label: `Add ${tagValue}`,
        value: tagValue.replace("  ", " ").toLocaleLowerCase(),
        color: "gray",
        icon: "plus",
      } as OptionTypes);
    } else {
      setNewTag(undefined);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (newTag && tags.filter((t) => t.tag === newTag.value).length === 0) {
      setAvailableTagOptions(
        [...tags.map(tagToTagOption), newTag].sort(sortTagOptions)
      );
    } else {
      setAvailableTagOptions(tags.map(tagToTagOption).sort(sortTagOptions));
    }
  }, [newTag, tags]);

  const btnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      size={isBaseSize ? "full" : "xl"}
    >
      <DialogBackdrop />
      <DialogContent zIndex={99999}>
        <DialogHeader>Update Tags</DialogHeader>
        <DialogCloseTrigger />

        <DialogBody>
          <Creatable
            isMulti
            isClearable
            isSearchable
            components={{
              IndicatorSeparator,
              MenuList: TagsMenuList as any,
              Option: TagMultiSelectOption,
            }}
            options={availableTagOptions}
            value={selectedTagOptions}
            onChange={handleSelectChange}
            onInputChange={handleInputChange}
            styles={getReactSelectStyles(colorMode || "light", colorScheme)}
          />
        </DialogBody>

        <DialogFooter>
          <ChakraButton
            ml="auto"
            colorPalette={colorScheme}
            ref={btnRef}
            onClick={() => {
              setIsLoading(true);
              onSubmit(selectedTagOptions.map((t) => t.value));
            }}
          >
            {(isLoading && <Spinner />) || "Save"}
          </ChakraButton>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default UpdateTags;
