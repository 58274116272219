import { useAuth0 } from "@auth0/auth0-react";
import { Button, VStack, useBreakpointValue } from "@chakra-ui/react";
import AgentOption, {
  AvailableAgentOption,
  sortOptions,
} from "components/shared/AgentOption";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { useColorMode } from "components/ui/color-mode";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import TeamDomain from "entities/domain/team";
import React, { useCallback, useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import { propagateTeamUpdate, removeTeam } from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { getReactSelectStyles } from "util/methods";
import TeamForm from "./TeamForm";

interface EditTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  teamToEdit: TeamDomain | null;
}

const EditTeamModal = ({ isOpen, onClose, teamToEdit }: EditTeamModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { agents } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const [newTeamName, setNewTeamName] = useState<string>(
    teamToEdit?.name || ""
  );
  const [newTeamPurpose, setNewTeamPurpose] = useState<string>(
    teamToEdit?.purpose || ""
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);



  const setNewTeamNameMemoized = useCallback(setNewTeamName, []);
  const setNewTeamPurposeMemoized = useCallback(setNewTeamPurpose, []);
  const agentOptions = sortOptions(
    agents.map((a) => ({
      value: a.id.toString(),
      label: a.getFullName(),
      avatarUrl: a.getPicture() || undefined,
    }))
  ) as AvailableAgentOption[];

  const [selectedAgentOptionsValue, setSelectedAgentOptionsValue] = useState<
    MultiValue<AvailableAgentOption>
  >(
    (teamToEdit?.agentIds || []).map((id) =>
      agentOptions.find((a) => a.value === id.toString())
    ) as MultiValue<AvailableAgentOption>
  );

  const handleAgentsChange = (e: MultiValue<AvailableAgentOption>) => {
    setSelectedAgentOptionsValue(e);
  };

  useEffect(() => {
    setNewTeamName(teamToEdit?.name || "");
    setNewTeamPurpose(teamToEdit?.purpose || "");
    setSelectedAgentOptionsValue(
      (teamToEdit?.agentIds || []).map((id) =>
        agentOptions.find((a) => a.value === id.toString())
      ) as MultiValue<AvailableAgentOption>
    );
  }, [teamToEdit, isOpen]);

  useEffect(() => {
    if (!teamToEdit) {
      return;
    }

    setSelectedAgentOptionsValue(
      teamToEdit.agentIds.map((id) =>
        agentOptions.find((a) => a.value === id.toString())
      ) as MultiValue<AvailableAgentOption>
    );
  }, [teamToEdit]);

  if (!teamToEdit) {
    return null;
  }

  const updateTeam = async () => {
    setIsLoading(true);

    try {
      const updatedTeam = await AgentsService.updateTeam(auth0Context, {
        merchant_id: merchant.id,
        id: teamToEdit.id,
        name: newTeamName,
        purpose: newTeamPurpose,
        agent_ids: selectedAgentOptionsValue.map((a) => parseInt(a.value, 10)),
      });

      dispatch(propagateTeamUpdate(updatedTeam));
      onClose();
      toaster.create({
        title: "Team updated successfully!",
        type: "success",
      });
    } catch (_e: unknown) {
      toaster.create({
        title: "Error updating team, try again later or contact support!",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTeam = async () => {
    setIsLoading(true);

    try {
      await AgentsService.deleteTeam(auth0Context, merchant.id, teamToEdit.id);

      onClose();
      dispatch(removeTeam(teamToEdit.id));
      toaster.create({
        title: "Team deleted successfully!",
        type: "success",
      });
    } catch (_e: unknown) {
      toaster.create({
        title: "Error deleting team, try again later or contact support!",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DialogRoot
        open={isOpen}
        onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }}
        size={isBaseSize ? "full" : "md"}
      >
        <DialogBackdrop  />
        <DialogContent>
          <DialogHeader>Edit team</DialogHeader>
          <DialogCloseTrigger />
          <DialogBody>
            <VStack>
              <TeamForm
                teamName={newTeamName}
                setTeamName={setNewTeamNameMemoized}
                teamPurpose={newTeamPurpose}
                setTeamPurpose={setNewTeamPurposeMemoized}
                isLoading={isLoading}
              />
              <Field
                label="Agents"
                mt={2}
                invalid={selectedAgentOptionsValue.length === 0}
                errorText="Please select at least one agent"
              >
                <Select
                  isMulti
                  placeholder="Select agents"
                  value={selectedAgentOptionsValue}
                  onChange={handleAgentsChange}
                  components={{
                    Option: AgentOption,
                  }}
                  options={agentOptions}
                  closeMenuOnSelect={false}
                  styles={{
                    ...{
                      ...getReactSelectStyles(colorMode || "light", colorScheme),
                      container: (provided: any) => ({
                        ...provided,
                        width: "100%",
                      }),
                    },
                  }}
                />
              </Field>
            </VStack>
          </DialogBody>
          <DialogFooter                display="flex"
            justifyContent="space-between"
            alignItems="end"
          >
            <Button
              onClick={() => setIsDeleteDialogOpen(true)}
              colorPalette="red"
              disabled={isLoading}
              variant="ghost"
            >
              Delete
            </Button>
            <Button
              onClick={updateTeam}
              colorPalette={colorScheme}
              disabled={isLoading || selectedAgentOptionsValue.length === 0}
            >
              Update
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
      <ConfirmationDialog
        headerText={`Delete team ${teamToEdit?.name || ""}?`}
        messageText="Are you sure you want to permanently delete this team?"
        cancelButtonText="No"
        buttonText="Yes"
        isDangerous={true}
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        confirmationCallback={() => {
          if (!teamToEdit) {
            return;
          }

          deleteTeam();
        }}
      />
    </>
  );
};

export default EditTeamModal;
