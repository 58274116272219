import { Fieldset, Input, useBreakpointValue } from "@chakra-ui/react";
import { Alert } from "components/ui/alert";
import { Checkbox } from "components/ui/checkbox";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { CampaignStatus } from "entities/domain/campaign";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import React, { memo, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface BroadcastingChannelsOptionTypes {
  value?: ConversationChannel;
  label?: string;
}

interface TuningProps {
  campaignName: string;
  campaignChannel: ConversationChannel;
  campaignStatus: CampaignStatus;
  setCampaignName: (name: string) => void;
  setCampaignChannel: (channel: ConversationChannel) => void;
  onValidationSuccess: () => void;
  onValidationFailure: () => void;
}

const Tuning = ({
  campaignName,
  campaignChannel,
  campaignStatus,
  setCampaignName,
  setCampaignChannel,
  onValidationSuccess,
  onValidationFailure,
}: TuningProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [useAllPossibleChannels, setUseAllPossibleChannels] =
    useState<boolean>(false);

  const channelOptions: BroadcastingChannelsOptionTypes[] = merchant.channels
    .map(
      (c: ChannelDomain) =>
        ({
          value: c.channelName,
          label: ChannelDomain.getChannelName(c.channelName),
        } as BroadcastingChannelsOptionTypes)
    )
    .filter((c: BroadcastingChannelsOptionTypes) => {
      return [ConversationChannel.SMS, ConversationChannel.WHATSAPP].includes(
        c.value as ConversationChannel
      );
    });

  const [broadcastingChannelsValue, setBroadcastingChannelsValue] = useState<
    SingleValue<BroadcastingChannelsOptionTypes>
  >(
    channelOptions.find((c) => {
      if (campaignChannel) {
        return c.value === campaignChannel;
      }

      return c.value === "sms";
    })!
  );

  const handleBroadcastingChannelsChange = (
    e: SingleValue<BroadcastingChannelsOptionTypes>
  ) => {
    setBroadcastingChannelsValue(e);
  };

  const hasManyChannels = false;

  useEffect(() => {
    setCampaignChannel(
      broadcastingChannelsValue?.value || ConversationChannel.SMS
    );
  }, [broadcastingChannelsValue]);

  useEffect(() => {
    if (campaignName) {
      onValidationSuccess();
    } else {
      onValidationFailure();
    }
  }, [campaignName]);

  return (
    <Fieldset.Root
      w={isBaseSize ? "100%" : "60%"}
      px={isBaseSize ? 2 : 0}
      pt={isBaseSize ? 2 : 4}
      mx="auto"
    >
      <Fieldset.Content>
        <Field
          label="Campaign Name"
          helperText="This is just for internal reference..."
          required
        >
          <Input
            type="text"
            id="campaign-name-input"
            colorPalette={colorScheme}
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            disabled={campaignStatus === CampaignStatus.DONE}
          />
        </Field>
        <Field
          mt={8}
          label="Messaging Channels"
          helperText="Your contacts will receive your message on all the messaging channels you include."
          required
        >
          <Select
            id="campaign-channel-select"
            placeholder="Enable channels"
            value={broadcastingChannelsValue}
            onChange={handleBroadcastingChannelsChange}
            isClearable={false}
            options={channelOptions}
            styles={{
              ...{
                ...getReactSelectStyles(colorMode || "light", colorScheme),
                container: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
              },
            }}
            isDisabled={campaignStatus === CampaignStatus.DONE}
          />
        </Field>
        {hasManyChannels && (
          <Field mt={8}>
            <Checkbox
              checked={useAllPossibleChannels}
              onCheckedChange={(e) => setUseAllPossibleChannels(!!e.checked)}
            >
              Use all possible channels
            </Checkbox>
            {useAllPossibleChannels ? (
              <Alert
                status="warning"
                variant="subtle"
                title="This will send the message to all possible channels"
              >
                This may result in a higher cost and may be perceived as spam by
                your customers.
              </Alert>
            ) : (
              <Alert
                status="info"
                variant="subtle"
                title="This will send the message to the channel with highest priority"
              >
                By default in case of multiple channels, the message will be
                sent to the channel with the highest priority. Our priority is:
                sms, whatsapp, email.
              </Alert>
            )}
          </Field>
        )}
      </Fieldset.Content>
    </Fieldset.Root>
  );
};

export default memo(Tuning);
