import { HStack, Text } from "@chakra-ui/react";
import React from "react";

export default () => {
  return (
    <HStack>
      <Text fontWeight="bold" fontSize="lg">
        Media has expired and no longer available.
      </Text>
    </HStack>
  );
};
