import { Box, Flex, Icon, Tabs, useBreakpointValue } from "@chakra-ui/react";
import { AppleIPhone } from "@kazimirchuk/react-device-preview";
import { useColorMode } from "components/ui/color-mode";
import Preview from "components/user-settings/TemplateSettings/create-whatsapp-template/Preview";
import { CampaignStatus } from "entities/domain/campaign";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import FileDomain from "entities/domain/file";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import useTemplatesStore from "hooks/use-templates-store";
import React, { memo, useCallback, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { useAppSelector } from "redux/hooks";
import { getChannelIcon } from "util/constants";
import {
  containsPrefix,
  containsSuffix,
  removePrefixAndSuffix,
  replaceCustomFields,
} from "util/methods";
import SmsMessageTabPanel from "./SmsMessageTabPanel";
import WhatsappMessageTabPanel from "./WhatsappMessageTabPanel";

interface MessageProps {
  attachedFile: FileDomain | null;
  prefix?: string;
  campaignChannel: ConversationChannel;
  campaignMessage: string | null;
  campaignStatus: CampaignStatus;
  campaignTemplateName: string | null;
  campaignMediaUrl: string | null;
  campaignMediaType: string | null;
  isIncludingSuffix?: boolean;
  onAttachmentRemove: () => void;
  setPrefix?: (newPrefix: string) => void;
  setCampaignTemplateName: (tid: string | null) => void;
  setAttachedFile: (file: FileDomain | null) => void;
  setIsValid: (isValid: boolean) => void;
  setIsIncludingSuffix?: (newIsIncluded: boolean) => void;
  setCampaignMessage: (message: string | null) => void;
}

const Message = ({
  attachedFile,
  campaignChannel,
  campaignMediaType,
  campaignMediaUrl,
  campaignStatus,
  campaignTemplateName,
  prefix,
  isIncludingSuffix,
  campaignMessage,
  setCampaignTemplateName,
  onAttachmentRemove,
  setCampaignMessage,
  setPrefix,
  setIsValid,
  setAttachedFile,
  setIsIncludingSuffix,
}: MessageProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { fetchTemplates } = useTemplatesStore();
  const { templates } = useAppSelector((state) => state.templates);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [messagingChannels, setMessagingChannels] = useState<
    ConversationChannel[]
  >([campaignChannel]);

  const optionalSuffix = `\nReply UNSUB to unsubscribe`;

  useEffect(() => {
    setMessagingChannels([campaignChannel]);
  }, [campaignChannel]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [localMessage, setLocalMessage] = useState<string>(
    campaignMessage
      ? removePrefixAndSuffix(campaignMessage, prefix!, optionalSuffix)
      : ""
  );

  const memoizedSetMessage = useCallback(
    (message: string) => {
      setLocalMessage(message);
    },
    [setLocalMessage]
  );

  const [templateAttachmentUrl, setTemplateAttachmentUrl] = useState<
    string | null
  >(null);
  const [templateAttachmentType, setTemplateAttachmentType] = useState<
    string | null
  >(null);

  const addPrefixIfNeeded = (text: string) =>
    containsPrefix(text, prefix!) ? text : `${prefix} ${text}`;
  const addSuffixIfNeeded = (text: string) =>
    containsSuffix(text, optionalSuffix) ? text : `${text}${optionalSuffix}`;

  const availableCustomFields = useAvailableCustomFields();

  useEffect(() => {
    if (
      (campaignMessage &&
        removePrefixAndSuffix(campaignMessage, prefix!, optionalSuffix)) ||
      campaignTemplateName
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [campaignMessage, campaignTemplateName, localMessage]);

  useEffect(() => {
    setCampaignMessage(
      isIncludingSuffix
        ? addSuffixIfNeeded(addPrefixIfNeeded(localMessage))
        : addPrefixIfNeeded(localMessage)
    );
  }, [localMessage, isIncludingSuffix, prefix]);

  useEffect(() => {
    fetchTemplates(undefined, campaignChannel === ConversationChannel.SMS);
  }, [campaignChannel]);

  const [previewText, setPreviewText] = useState<string>("");

  useEffect(() => {
    if (campaignTemplateName) {
      const template = templates.find((t) => t.name === campaignTemplateName);

      let endText =
        replaceCustomFields(
          template?.getWholeText() || "",
          template?.customFields || availableCustomFields
        ) || "";

      if (
        campaignChannel === ConversationChannel.SMS &&
        merchant.country !== "US"
      ) {
        if (template && template.getMedia()) {
          endText = `${endText}\nhttp://link.to/your/file`;
        }

        setLocalMessage("");
        setPreviewText(endText);
        setTemplateAttachmentType(null);
        setTemplateAttachmentUrl(null);
      } else {
        setPreviewText(endText);
        const media = template?.getMedia();
        setTemplateAttachmentType(media?.type || null);
        setTemplateAttachmentUrl(media?.url || null);
      }
    } else {
      const messageBodyWithoutPrefixAndSuffix = campaignMessage
        ? removePrefixAndSuffix(campaignMessage, prefix!, optionalSuffix)
        : "";
      const hasMessageBody = replaceCustomFields(
        messageBodyWithoutPrefixAndSuffix,
        availableCustomFields
      );
      let endText =
        replaceCustomFields(campaignMessage || "", availableCustomFields) || "";

      if (
        campaignChannel === ConversationChannel.SMS &&
        merchant.country !== "US" &&
        ((campaignMediaType && campaignMediaUrl) || attachedFile)
      ) {
        endText = `${endText}\nhttp://link.to/your/file`;
      }

      setPreviewText(hasMessageBody ? endText : "");
      setTemplateAttachmentType(null);
      setTemplateAttachmentUrl(null);
    }
  }, [campaignTemplateName, templates, campaignMessage, attachedFile]);

  const [currentTemplate, setCurrentTemplate] =
    useState<RegularOrWhatsappTemplate | null>(null);

  useEffect(() => {
    if (campaignTemplateName) {
      const template = templates.find((t) => t.name === campaignTemplateName);
      setCurrentTemplate(template || null);
    } else {
      setCurrentTemplate(null);
    }
  }, [campaignTemplateName]);

  return (
    <Tabs.Root
      colorPalette={colorScheme}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      value={activeTab.toString()}
      onValueChange={({ value }) => {
        setActiveTab(parseInt(value, 10));
      }}
      fitted
    >
      <Tabs.List
        {...(messagingChannels.length === 1
          ? {
              display: "none",
            }
          : {})}
      >
        {messagingChannels.map((c: ConversationChannel, index) => (
          <Tabs.Trigger key={c} value={index.toString()} asChild>
            <Icon
              asChild={true}
              css={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            >
              <ReactSVG src={getChannelIcon(c)} />
            </Icon>
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {messagingChannels.map((c: ConversationChannel, index: number) => (
        <Tabs.Content
          value={index.toString()}
          key={`${c}-${index}-tab-panel`}
          display="flex"
          w="100%"
          height="100%"
          mx="auto"
          my={0}
          py={4}
          h="100%"
          overflowY="auto"
          gridGap={8}
          px={isBaseSize ? 4 : 4}
        >
          <Box
            {...(c === ConversationChannel.SMS
              ? {
                  flexGrow: 5,
                  flexBasis: "auto",
                  flexShrink: 1,
                }
              : {
                  w: "100%",
                })}
          >
            {c === ConversationChannel.SMS && (
              <SmsMessageTabPanel
                setCampaignTemplateName={setCampaignTemplateName}
                onAttachmentRemove={onAttachmentRemove}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                templateName={campaignTemplateName}
                campaignMediaType={campaignMediaType}
                campaignMediaUrl={campaignMediaUrl}
                campaignStatus={campaignStatus}
                message={localMessage}
                setMessage={memoizedSetMessage}
                customFields={availableCustomFields}
                prefix={prefix!}
                setPrefix={setPrefix!}
                isIncludingSuffix={isIncludingSuffix!}
                setIsIncludingSuffix={setIsIncludingSuffix!}
              />
            )}
            {c === ConversationChannel.WHATSAPP && (
              <WhatsappMessageTabPanel
                campaignTemplateName={campaignTemplateName}
                campaignStatus={campaignStatus}
                setCampaignTemplateName={setCampaignTemplateName}
              />
            )}
          </Box>
          {isBaseSize ? null : (
            <Flex
              {...(c === ConversationChannel.SMS
                ? {
                    flexGrow: 1,
                    flexBasis: "auto",
                    flexShrink: 0,
                  }
                : {
                    w: "100%",
                  })}
              justifyContent="center"
              py={10}
              px={4}
            >
              {campaignChannel === ConversationChannel.SMS ? (
                <AppleIPhone
                  colorMode={(colorMode as "dark" | "light") || "light"}
                  size="sm"
                  app={{
                    name: "BuiltInMessenger",
                    options: {
                      messages: [
                        {
                          text: previewText,
                          mediaUrl:
                            campaignChannel === ConversationChannel.SMS &&
                            merchant.country === "US"
                              ? undefined
                              : templateAttachmentUrl || undefined,
                          mediaType:
                            campaignChannel === ConversationChannel.SMS &&
                            merchant.country === "US"
                              ? undefined
                              : templateAttachmentType || undefined,
                          isOutgoing: true,
                        },
                        ...[
                          (attachedFile ||
                            campaignMediaUrl ||
                            (templateAttachmentType &&
                              templateAttachmentUrl)) &&
                          campaignChannel === ConversationChannel.SMS &&
                          merchant.country === "US"
                            ? {
                                media: attachedFile || undefined,
                                mediaType: attachedFile
                                  ? attachedFile.type
                                  : templateAttachmentType || campaignMediaType,
                                mediaUrl: attachedFile
                                  ? undefined
                                  : templateAttachmentUrl ||
                                    campaignMediaUrl ||
                                    undefined,
                                isOutgoing: true,
                              }
                            : {},
                        ],
                      ],
                    },
                  }}
                />
              ) : (
                <Preview
                  customFields={
                    currentTemplate?.customFields || availableCustomFields
                  }
                  headerText={
                    (currentTemplate &&
                      currentTemplate.isWhatsappTemplate() &&
                      currentTemplate.getHeaderText()) ||
                    ""
                  }
                  bodyText={
                    (currentTemplate &&
                      currentTemplate.isWhatsappTemplate() &&
                      currentTemplate.getBodyText()) ||
                    ""
                  }
                  footerText={
                    (currentTemplate &&
                      currentTemplate.isWhatsappTemplate() &&
                      currentTemplate.getFooterText()) ||
                    ""
                  }
                  buttons={
                    (currentTemplate &&
                      currentTemplate.isWhatsappTemplate() &&
                      currentTemplate.getButtons()) ||
                    []
                  }
                  file={null}
                  mediaUrl={templateAttachmentUrl || undefined}
                  mediaType={templateAttachmentType || undefined}
                />
              )}
            </Flex>
          )}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
};

export default Message;
