import {
  Button,
  Icon,
  Portal,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  LuCalendar,
  LuCreditCard,
  LuPaperclip,
  LuPlus,
  LuStar,
  LuUndo,
  LuWand,
} from "react-icons/lu";

import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import NewEventModal from "components/calendar/NewEventModal";
import PaymentsPopover from "components/chat/shared/messages-area/typing-zone/payments-popover";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import FileSelector from "components/shared/FileSelector";
import { useColorMode } from "components/ui/color-mode";

import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from "components/ui/menu";
import { toaster } from "components/ui/toaster";
import { Tooltip } from "components/ui/tooltip";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { ReviewProvider } from "entities/domain/reviews/review-domain";
import TemplateDomain from "entities/domain/templates";
import useMagicWand from "hooks/use-magic-wand";
import {
  activeConversationTemplatesSelector,
  addAttachmentToMessageInput,
  changeMessageInputText,
  clearAttahmentsFromMessageInput,
} from "redux/features/conversations";
import { messagesSelector } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { REVIEW_INVITATION_TEMPLATE_IDS } from "util/constants";
import { isLondonLocksMerchant } from "util/methods";
import { canSendPayments } from "util/permissions";

interface MoreActionsProps {
  isDisabled?: boolean;
  setIsLoadingSuggestion: (loading: boolean) => void;
}

const MoreActions = ({
  isDisabled = false,
  setIsLoadingSuggestion,
}: MoreActionsProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const conversationTemplates = useAppSelector(
    activeConversationTemplatesSelector
  );
  const {
    isLoadingActiveConversation,
    activeConversation,
    activeConversationId,
    messageInput: { text },
  } = useAppSelector((state) => state.conversations);
  const conversationMessages = useAppSelector(messagesSelector);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const fileSelector = useDisclosure();

  const [reviewTemplate, setReviewTemplate] = useState<TemplateDomain>();
  const [openEventForm, setOpenEventForm] = useState<boolean>(false);

  useEffect(() => {
    setReviewTemplate(
      conversationTemplates.find((t) =>
        REVIEW_INVITATION_TEMPLATE_IDS.includes(t.id)
      )
    );
  }, [conversationTemplates]);

  const [showPaymentsPopover, setShowPaymentsPopover] =
    useState<boolean>(false);
  const [showPaymentWithoutInvoicePrompt, setShowPaymentWithoutInvoicePrompt] =
    useState<boolean>(false);

  const isSendImageAllowed = (): boolean =>
    !activeConversation?.isTemplatesOnly() &&
    activeConversation?.channel !== ConversationChannel.ZAPIER_FACEBOOK;

  const isDarkMode = colorMode === "dark";
  const activeIconColor = isDarkMode ? `${colorScheme}.200` : "gray.500";
  const disabledIconColor = isDarkMode ? `${colorScheme}.700` : "gray.100";

  const { abraKadabra, improvedMessage } = useMagicWand({
    lastMessage: conversationMessages
      ? [...conversationMessages].pop()
      : undefined,
    message: text,
    setMessage: (newMessageText) => {
      dispatch(changeMessageInputText(newMessageText));
    },
    setIsLoading: setIsLoadingSuggestion,
  });

  return (
    <>
      <Tooltip content="More actions" disabled={isBaseSize}>
        <MenuRoot>
          <MenuTrigger asChild={true}>
            <Button
              size="xs"
              variant="ghost"
              p={0}
              alignSelf="center"
              disabled={isDisabled}
              css={{
                "> span": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                },
              }}
            >
              <Icon
                as={LuPlus}
                width="1rem"
                height="1rem"
                color={
                  colorMode === "light"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.200`
                }
              />
            </Button>
          </MenuTrigger>
          <Portal>
            <MenuContent zIndex="99999">
              <MenuItem
                gridGap={3}
                disabled={
                  isDisabled ||
                  !isSendImageAllowed() ||
                  isLoadingActiveConversation
                }
                onClick={() => {
                  if (
                    isDisabled ||
                    !isSendImageAllowed() ||
                    isLoadingActiveConversation
                  ) {
                    return;
                  }

                  fileSelector.onOpen();
                }}
                value="Add attachment"
              >
                <Icon
                  as={LuPaperclip}
                  height="1.25rem"
                  width="1.25rem"
                  color={isDisabled ? disabledIconColor : activeIconColor}
                />
                Add attachment
              </MenuItem>
              <MenuItem
                gridGap={3}
                disabled={isLoadingActiveConversation}
                onClick={() => setOpenEventForm(true)}
                value="Create event"
              >
                <Icon
                  as={LuCalendar}
                  height="1.25rem"
                  width="1.25rem"
                  color={isDisabled ? disabledIconColor : activeIconColor}
                />
                Create event
              </MenuItem>
              <MenuItem
                _hover={{
                  bg: colorMode === "dark" ? "gray.600" : "gray.100",
                }}
                gridGap={3}
                onClick={() => {
                  if (
                    !merchant.isIntegrationEnabled(
                      IntegrationName.QUICKBOOKS
                    ) &&
                    !merchant.isIntegrationEnabled(IntegrationName.XERO) &&
                    isLondonLocksMerchant(merchant.id)
                  ) {
                    setShowPaymentWithoutInvoicePrompt(true);
                  } else {
                    setTimeout(() => {
                      setShowPaymentsPopover(true);
                    }, 0);
                  }
                }}
                disabled={
                  !merchant.isPaymentEnabled() ||
                  !canSendPayments(merchant.id, currentAgent!) ||
                  isLoadingActiveConversation
                }
                value="Request payment"
              >
                <Icon
                  as={LuCreditCard}
                  height="1.25rem"
                  width="1.25rem"
                  color={isDisabled ? disabledIconColor : activeIconColor}
                />
                Request payment
              </MenuItem>
              <MenuItem
                gridGap={3}
                onClick={() => {
                  InboxService.requestReview(
                    auth0Context,
                    merchant.id,
                    activeConversationId!,
                    ReviewProvider.GOOGLE
                  ).catch((error) => {
                    if (error instanceof AxiosError) {
                      if (error.response?.data?.code === "insufficient_limit") {
                        toaster.create({
                          title: "Can't request a review!",
                          description:
                            "You have reached the limit of WhatsApp initiated conversations. Please upgrade to continue messaging.",
                          type: "error",
                        });
                      } else {
                        toaster.create({
                          title: "Can't request a review!",
                          description:
                            "An error occurred while requesting a review.",
                          type: "error",
                        });
                      }
                    }
                  });
                }}
                disabled={!reviewTemplate || isLoadingActiveConversation}
                value="Request review"
              >
                <Icon
                  as={LuStar}
                  height="1.25rem"
                  width="1.25rem"
                  color={isDisabled ? disabledIconColor : activeIconColor}
                />
                Request review
              </MenuItem>
              <MenuItem
                gridGap={3}
                disabled={isDisabled || !text}
                onClick={(e) => {
                  abraKadabra();
                }}
                value="Rephrase with AI"
              >
                <Tooltip
                  content={
                    text && text !== improvedMessage
                      ? "Get help constructing a message from AI"
                      : "Undo"
                  }
                >
                  <Icon
                    as={
                      text && text !== improvedMessage
                        ? LuWand
                        : !text
                        ? LuWand
                        : LuUndo
                    }
                    height="1.25rem"
                    width="1.25rem"
                    color={isDisabled ? disabledIconColor : activeIconColor}
                  />
                </Tooltip>
                {text && text !== improvedMessage
                  ? "Rephrase with AI"
                  : !text
                  ? "Rephrase with AI"
                  : "Undo changes made by AI"}
              </MenuItem>
            </MenuContent>
          </Portal>
        </MenuRoot>
      </Tooltip>
      <ConfirmationDialog
        headerText="No invoice attached to payment"
        messageText="Please note that an invoice will not be attached to this payment. To enable invoicing, please reconnect your accounting software with Fuzey."
        buttonText="Proceed"
        isOpen={showPaymentWithoutInvoicePrompt}
        setIsOpen={setShowPaymentWithoutInvoicePrompt}
        confirmationCallback={() => {
          setShowPaymentWithoutInvoicePrompt(false);
          setShowPaymentsPopover(true);
        }}
      />
      <FileSelector
        fileTypeAccept={activeConversation?.getAcceptedFileFormat()}
        isOpen={fileSelector.open}
        enableUrlShortening={
          activeConversation?.channel === ConversationChannel.SMS
        }
        onClose={fileSelector.onClose}
        onFileSelect={async (selectedFile) => {
          if (!selectedFile) {
            dispatch(clearAttahmentsFromMessageInput());
            return;
          }

          dispatch(
            addAttachmentToMessageInput({
              id: selectedFile.id,
              url: selectedFile.url,
              type: selectedFile.type,
            })
          );

          fileSelector.onClose();
        }}
      />
      <PaymentsPopover
        id="payments-popover"
        onClose={() => setShowPaymentsPopover(false)}
        isOpen={showPaymentsPopover}
      />
      <NewEventModal
        openEventForm={openEventForm}
        closeNewEventForm={() => setOpenEventForm(false)}
        customerId={activeConversation?.customerId}
        defaultChannelId={activeConversation?.customerChannelId}
      />
    </>
  );
};

export default MoreActions;
