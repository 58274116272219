import { format, parseISO } from "date-fns";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

export enum CampaignStatus {
  DRAFT = "draft",
  PENDING = "pending",
  DONE = "done",
  PAUSED = "paused",
  CANCELLED = "cancelled",
}

export interface TemplateForCampaign {
  id: string | null;
  text: string;
}

export interface MessagesAnalytics {
  total: number;
  totalRead: number;
  totalDelivered: number;
  totalFailed: number;
  totalReplied: number;
  totalSegments: number;
}

export default class CampaignDomain {
  id: string | null;

  name: string;

  merchantId: number;

  status: CampaignStatus;

  audienceId: string | null;

  templateName: string | null;

  messageBody: string | null;

  sentAt: string | null;

  scheduledAt: string | null;

  scheduledFor: string | null;

  createdAt: string | null;

  updatedAt: string | null;

  analytics: MessagesAnalytics | null;

  channel: ConversationChannel;

  mediaUrl: string | null;

  mediaType: string | null;

  progress: number | null;

  constructor(
    id: string | null,
    name: string,
    merchantId: number,
    status: CampaignStatus,
    audienceId: string | null,
    templateName: string | null,
    messageBody: string | null,
    sentAt: string | null,
    scheduledAt: string | null,
    scheduledFor: string | null,
    createdAt: string | null,
    updatedAt: string | null,
    analytics: MessagesAnalytics | null,
    channel: ConversationChannel,
    mediaUrl: string | null,
    mediaType: string | null,
    progress: number | null
  ) {
    this.id = id;
    this.name = name;
    this.merchantId = merchantId;
    this.status = status;
    this.audienceId = audienceId;
    this.templateName = templateName;
    this.messageBody = messageBody;
    this.sentAt = sentAt;
    this.scheduledAt = scheduledAt;
    this.scheduledFor = scheduledFor;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.analytics = analytics;
    this.channel = channel;
    this.mediaUrl = mediaUrl;
    this.mediaType = mediaType;
    this.progress = progress;
  }

  public static getLabelForStatus(status: CampaignStatus): string {
    switch (status) {
      case CampaignStatus.PENDING:
        return "In Progress";
      case CampaignStatus.DRAFT:
        return "Draft / Scheduled";
      case CampaignStatus.DONE:
        return "Sent";
      case CampaignStatus.CANCELLED:
        return "Cancelled";
      case CampaignStatus.PAUSED:
        return "Paused";
      default:
        return status;
    }
  }

  public static getReplyRateDescription(
    campaign: CampaignDomain
  ): string | null {
    if (campaign.status !== CampaignStatus.DONE || !campaign.analytics) {
      return null;
    }

    return `${campaign.analytics.totalReplied} out of ${campaign.analytics.total}`;
  }

  public static getReplyRateNumber(campaign: CampaignDomain): number {
    if (campaign.status !== CampaignStatus.DONE) {
      return 0;
    }

    const newReplyRateNumber = Math.ceil(
      ((campaign.analytics?.totalReplied || 0) /
        (campaign.analytics?.total || 0) || 0) * 100
    );

    return newReplyRateNumber > 100 ? 100 : newReplyRateNumber;
  }

  public static getReplyRate(campaign: CampaignDomain): string {
    if (campaign.status !== CampaignStatus.DONE) {
      return "-";
    }

    return `${CampaignDomain.getReplyRateNumber(campaign)} %`;
  }

  public static getOpenRateNumber(campaign: CampaignDomain): number {
    if (campaign.channel !== ConversationChannel.WHATSAPP) {
      return 0;
    }

    if (campaign.status !== CampaignStatus.DONE) {
      return 0;
    }

    const openRateNumber = Math.ceil(
      ((campaign.analytics?.totalRead || 0) /
        (campaign.analytics?.total || 0) || 0) * 100
    );

    return openRateNumber > 100 ? 100 : openRateNumber;
  }

  public static getOpenRate(campaign: CampaignDomain): string {
    if (campaign.channel !== ConversationChannel.WHATSAPP) {
      return "-";
    }

    if (campaign.status !== CampaignStatus.DONE) {
      return "-";
    }

    return `${CampaignDomain.getOpenRateNumber(campaign)} %`;
  }

  public static getDescriptionForStatus(campaign: CampaignDomain): {
    description: string;
    color: string;
  } {
    const getDateTime = (dateString: string | null) => {
      if (!dateString) {
        return "";
      }
      return format(parseISO(dateString), "dd/MM/yy HH:mm");
    };
    switch (campaign.status) {
      case CampaignStatus.PENDING:
        return {
          description: "Sending",
          color: "orange.400",
        };
      case CampaignStatus.DRAFT:
        if (campaign.scheduledFor) {
          return {
            description: `Scheduled for ${getDateTime(campaign.scheduledFor)}`,
            color: "purple.400",
          };
        }

        return {
          description: `Last edited ${getDateTime(campaign.updatedAt)}`,
          color: "gray.400",
        };
      case CampaignStatus.PAUSED:
        return {
          description: "Paused",
          color: "gray.400",
        };
      case CampaignStatus.DONE:
        return {
          description: `Sent ${getDateTime(campaign.sentAt)}`,
          color: "green.400",
        };
      case CampaignStatus.CANCELLED:
        return {
          description: `Cancelled ${getDateTime(campaign.sentAt)}`,
          color: "gray.400",
        };
      default:
        return {
          description: "N/A",
          color: "gray.400",
        };
    }
  }
}
