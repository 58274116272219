import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import AssignAgentModal from "components/modals/AssignAgent";
import MergeContact from "components/modals/merge-contact/MergeContact";
import UpdateTags from "components/modals/tags/UpdateTags";
import ProfileAvatar from "components/profile/profile-avatar";
import ContactDetails from "components/shared/contact-details";
import AgentDomain from "entities/domain/agents/agent-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAnalytics from "hooks/use-analytics";
import useContactsStore from "hooks/use-contacts-store";
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  LuChevronLeft,
  LuPhoneOutgoing,
  LuUserRoundCheck,
} from "react-icons/lu";

import { batch } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import {
  DrawerBackdrop,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerRoot,
} from "components/ui/drawer";
import {
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";

import { toaster } from "components/ui/toaster";
import { FaTags } from "react-icons/fa";
import { clearAttachments } from "redux/features/attachments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import InboxService from "services/inbox";
import VoiceCallsService from "services/voicecalls";
import { getChannelIcon } from "util/constants";
import PopoverActions from "../popover-actions";

const TopArea: ForwardRefRenderFunction<HTMLDivElement, {}> = (_props, ref) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { updateContactTags } = useContactsStore();
  const { search } = useLocation();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { tags: allTags } = useAppSelector((state) => state.tags);
  const { agents } = useAppSelector((state) => state.agents);
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [contactToManipulate, setContactToManipulate] = useState<
    ContactDomain | undefined
  >(undefined);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [assignedAgentId, setAssignedAgentId] = useState<number | null>(null);
  const [assignedAgent, setAssignedAgent] = useState<AgentDomain | undefined>();

  const [openEditTags, setOpenEditTags] = useState<boolean>(false);
  const [selectedContactId, setSelectedContactId] = useState<
    number | undefined
  >(undefined);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const [tagIdToDelete, setTagIdToDelete] = useState<string>("");

  useEffect(() => {
    if (tagIdToDelete)
      setSelectedTagIds(
        selectedTagIds.filter((tagId) => tagId !== tagIdToDelete)
      );
  }, [tagIdToDelete]);

  useEffect(() => {
    setSelectedContactId(activeConversation!.customerId);
    setSelectedTagIds(activeConversation?.tagIds || []);
  }, [activeConversation]);

  const { open, onOpen, onClose } = useDisclosure();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    setAssignedAgentId(activeConversation!.assignedAgentId || null);
  }, [activeConversation]);

  useEffect(() => {
    setAssignedAgent(
      agents.filter((agent: AgentDomain) => agent.id === assignedAgentId).pop()
    );
  }, [assignedAgentId, agents]);

  const callCustomer = async () => {
    try {
      await VoiceCallsService.initiateCall(
        auth0Context,
        activeConversation!.id,
        merchant.id
      );

      toaster.create({ type: "success", title: "Call initiated" });
    } catch (err) {
      toaster.create({
        type: "error",
        title: "Could not call this customer.",
        description: "Please try again.",
      });
    }
  };

  const onUserDetailsOpen = () => {
    try {
      ContactsService.getContact(
        auth0Context,
        activeConversation!.customerId,
        merchant.groupId
      ).then((res) => setContactToManipulate(res));
    } catch (err) {
      toaster.create({
        type: "error",
        title: "Something went wrong.",
        description: "Please try again.",
      });
    }
  };

  const handleCloseContactDisplay = () => {
    setContactToManipulate(undefined);
  };

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      setContactToManipulate(updatedContactResponse);
    }
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const handleOpenEditTags = (contactId: number) => {
    setSelectedContactId(contactId);
    setOpenEditTags(true);
  };

  const updateTags = async (tagIds: string[]) => {
    if (!selectedContactId) {
      toaster.create({
        type: "error",
        title: "Can't add tags to contact without an id",
      });

      return;
    }

    try {
      const selectedTags = allTags.filter((tag) => tagIds.includes(tag.id));
      const tags = await updateContactTags(selectedTags, selectedContactId);

      if (contactToManipulate && selectedContactId === contactToManipulate.id) {
        setContactToManipulate(
          Object.setPrototypeOf(
            {
              ...contactToManipulate,
              tagIds: tags?.map((tag) => tag.id) || [],
            },
            ContactDomain.prototype
          )
        );
      }
    } catch (error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to update tags.",
        description: "Please try again.",
      });
    } finally {
      setOpenEditTags(false);
    }
  };

  const createConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const findConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.getConversationByChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    if (chanId !== activeConversation!.customerChannelId) {
      try {
        const conversation =
          (await findConversationWithChannelId(chanId)) ||
          (await createConversationWithChannelId(chanId));

        navigate({
          pathname: `/${merchant.id}/inbox/${conversation.id}`,
          search: createSearchParams(search).toString(),
        });

        if (isBaseSize) {
          handleCloseContactDisplay();
        }
      } catch (_e) {
        toaster.create({
          type: "error",
          title: "An error occurred while opening this conversation.",
        });
      }
    } else {
      handleCloseContactDisplay();
    }
  };

  return (
    <Flex
      ref={ref}
      alignItems="center"
      justifyContent="space-between"
      position="absolute"
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
      top="0"
      left="0"
      zIndex={100}
      py={2}
      px={3}
      width="100%"
      direction="column"
    >
      <HStack gap={0} w="100%">
        <Flex
          alignItems="center"
          maxWidth="50%"
          gridGap={isBaseSize ? 2 : 4}
          width="100%"
        >
          {isBaseSize && (
            <IconButton
              flexShrink={0}
              aria-label="Back to inbox"
              mr={0}
              variant="ghost"
              colorPalette={colorScheme}
              as={LuChevronLeft}
              onClick={() => {
                batch(() => {
                  navigate({
                    pathname: `/${merchant.id}/inbox`,
                    search: createSearchParams(search).toString(),
                  });
                  dispatch(clearAttachments());
                });
              }}
            />
          )}
          <Flex
            onClick={() => {
              track("show_customer_details", {
                conversation_id: activeConversation!.id || null,
                customer_id: activeConversation!.customerId,
                channel: activeConversation!.channel,
              });
              onUserDetailsOpen();
            }}
            cursor="pointer"
            alignItems="center"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            py={2}
          >
            {activeConversation && (
              <ProfileAvatar
                channelIcon={getChannelIcon(activeConversation.channel)}
                profilePicture={activeConversation.picture}
                name={activeConversation.displayName}
                showInitials={false}
              />
            )}
            <Text
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              px={2}
            >
              {activeConversation!.displayName}
            </Text>
          </Flex>
          {!isBaseSize &&
          activeConversation &&
          !activeConversation.isSubscribed ? (
            <Alert
              status="warning"
              borderRadius="full"
              justifySelf="start"
              width="fit-content"
              variant="solid"
              p={2}
              pr={3}
              title="Unsubscribed"
            />
          ) : null}
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="end"
          maxWidth="50%"
          flexGrow={1}
          flexShrink={0}
          gridGap={3}
        >
          <IconButton
            aria-label="Update Tags"
            onClick={() => handleOpenEditTags(activeConversation?.customerId!)}
            variant="outline"
            colorPalette={colorScheme}
            borderRadius="full"
          >
            <Icon
              as={FaTags}
              color={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          </IconButton>

          {assignedAgent ? (
            <ProfileAvatar
              profilePicture={assignedAgent.getPicture()}
              showInitials={true}
              name={assignedAgent.getFullName()}
              onClick={() => {
                track("assign_agent_attempt", {
                  conversation_id: activeConversation!.id || null,
                  customer_id: activeConversation!.customerId,
                  channel: activeConversation!.channel,
                });
                onOpen();
              }}
              avatarStyle={{
                cursor: "pointer",
                colorPalette: assignedAgent.color,
              }}
            />
          ) : (
            <IconButton
              aria-label="Assign to"
              onClick={() => {
                track("assign_agent_attempt", {
                  conversation_id: activeConversation!.id || null,
                  customer_id: activeConversation!.customerId,
                  channel: activeConversation!.channel,
                });
                onOpen();
              }}
              variant="outline"
              colorPalette={colorScheme}
              borderRadius="full"
            >
              <Icon
                as={LuUserRoundCheck}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />
            </IconButton>
          )}
          {activeConversation!.isPhoneBasedChannel() && (
            <IconButton
              aria-label="Call customer"
              onClick={callCustomer}
              variant="outline"
              colorPalette={colorScheme}
              borderRadius="full"
            >
              <Icon
                as={LuPhoneOutgoing}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />
            </IconButton>
          )}
          <PopoverActions />
        </Flex>
      </HStack>
      <AssignAgentModal
        isOpen={open}
        onClose={onClose}
        assignedAgentId={activeConversation!.assignedAgentId}
        assignedTeamId={activeConversation!.assignedTeamId}
        customerId={activeConversation!.customerId}
        conversationId={activeConversation!.id}
        conversationChannel={activeConversation!.channel}
      />

      {!isBaseSize && contactToManipulate && (
        <ContactDetails
          contactToDisplay={contactToManipulate}
          onClose={handleCloseContactDisplay}
          handleCloseContactDisplay={handleCloseContactDisplay}
          displayPopover={true}
          handleOpenMergeModal={handleOpenMergeModal}
          onCreateNewTag={() => handleOpenEditTags(contactToManipulate.id!)}
          onChannelClick={handleCreate}
          setTagIdToBeDeleted={setTagIdToDelete}
        />
      )}

      {isBaseSize && contactToManipulate && (
        <DrawerRoot
          open={!!contactToManipulate}
          onOpenChange={({ open: newIsOpen }) => {
            if (!newIsOpen) {
              handleCloseContactDisplay();
            }
          }}
          placement="top"
          size="full"
        >
          <DrawerBackdrop />
          <DrawerContent
            overflowY="auto"
            height="100%"
            borderRadius="0 !important"
            bgColor={colorMode === "dark" ? "gray.800" : "white"}
          >
            <DrawerCloseTrigger />
            <Flex justify="space-between" alignItems="center" p={2}>
              <PopoverRoot>
                <PopoverTrigger asChild>
                  <IconButton
                    aria-label="More actions"
                    variant="ghost"
                    colorPalette={colorScheme}
                  >
                    <Icon
                      as={FiMoreHorizontal}
                      fill={
                        colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.500`
                      }
                    />
                  </IconButton>
                </PopoverTrigger>
                <PopoverContent w="fit-content">
                  <PopoverBody p={0}>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() => {
                        navigate(
                          `/${merchant.id}/contacts/edit/${contactToManipulate.id}`
                        );
                      }}
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderTopRadius: "12px",
                      }}
                    >
                      Edit Contact
                    </Flex>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() => {
                        handleOpenMergeModal(contactToManipulate);
                      }}
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderBottomRadius: "12px",
                      }}
                    >
                      Merge Contact
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </PopoverRoot>
            </Flex>
            <ContactDetails
              contactToDisplay={contactToManipulate}
              onClose={handleCloseContactDisplay}
              handleCloseContactDisplay={handleCloseContactDisplay}
              onCreateNewTag={() => handleOpenEditTags(contactToManipulate.id!)}
              onChannelClick={handleCreate}
              setTagIdToBeDeleted={setTagIdToDelete}
            />
          </DrawerContent>
        </DrawerRoot>
      )}

      {contactToMerge && (
        <MergeContact
          contactToMerge={contactToMerge}
          isOpen={openMergeForm}
          onClose={handleCloseMergeModal}
        />
      )}
      {selectedContactId && (
        <UpdateTags
          isOpen={openEditTags}
          onClose={() => setOpenEditTags(false)}
          tagIds={selectedTagIds}
          onSubmit={updateTags}
        />
      )}
    </Flex>
  );
};

export default forwardRef(TopArea);
