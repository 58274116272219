import { Button, Text, useBreakpointValue } from "@chakra-ui/react";

import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import React, { useEffect, useRef } from "react";
import { useAppSelector } from "redux/hooks";

export interface ConfirmationDialogProps {
  headerText?: string;
  messageText: string;
  buttonText: string;
  cancelButtonText?: string;
  isOpen: boolean;
  isDangerous?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  confirmationCallback: () => void;
}

const ConfirmationDialog = ({
  headerText,
  messageText,
  buttonText,
  cancelButtonText,
  isOpen,
  isDangerous = false,
  setIsOpen,
  confirmationCallback,
}: ConfirmationDialogProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const wasClicked = useRef<boolean>(false);

  // once confirmation dialog is opened again, reset wasClicked
  useEffect(() => {
    if (isOpen) {
      wasClicked.current = false;
    }
  }, [isOpen]);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => setIsOpen(newIsOpen)}
      size={isBaseSize ? "full" : "xl"}
    >
      <DialogBackdrop />
      <DialogContent mx={isBaseSize ? "0" : "2rem"}>
        <DialogHeader>{headerText}</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody textAlign="left">
          <Text>{messageText}</Text>
        </DialogBody>
        <DialogFooter
          display="flex"
          justifyContent={cancelButtonText ? "space-between" : "end"}
          gridGap={8}
          alignItems="center"
        >
          {cancelButtonText && (
            <Button
              colorPalette={colorScheme}
              variant="ghost"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {cancelButtonText}
            </Button>
          )}
          <Button
            colorPalette={isDangerous ? "red" : colorScheme}
            type="submit"
            onClick={() => {
              if (wasClicked.current) {
                return;
              }

              wasClicked.current = true;
              setIsOpen(false);
              confirmationCallback();
            }}
          >
            {buttonText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default ConfirmationDialog;
