import { Button, Flex, Spinner, useBreakpointValue } from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from "components/ui/dialog";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";
import ChooseContactToMerge from "./ChooseContactToMerge";
import MergeSelectedContact from "./MergeSelectedContact";

interface MergeContactProps {
  isOpen: boolean;
  contactToMerge?: ContactDomain;
  onClose: (updatedContactResponse: ContactDomain | undefined) => void;
}

const MergeContact = ({
  contactToMerge,
  isOpen,
  onClose,
}: MergeContactProps) => {
  const [step, setStep] = useState<number>(1);
  const [contactToMergeInto, setContactToMergeInto] = useState<ContactDomain>();
  const [displayContinueMerge, setDisplayContinueMerge] =
    useState<boolean>(true);
  const { mergeContact } = useContactsStore();
  const { modalLoading } = useAppSelector((state) => state.contacts);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onMergeContacts = async () => {
    if (!contactToMerge) {
      return;
    }

    const result = await mergeContact({
      id: contactToMergeInto!.id!,
      contactIds: [contactToMerge.id!],
    });
    if (result) {
      onClose(result);
    }
  };

  const cleanUpBeforeClosing = () => {
    onClose(undefined);
  };

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          cleanUpBeforeClosing();
        }
      }}
      size={isBaseSize ? "full" : "md"}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Merge Contact</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {!contactToMerge ? (
            <Flex w="100%" h="100%" justify="center" align="center">
              <Spinner />
            </Flex>
          ) : step === 1 ? (
            <ChooseContactToMerge
              contactToMergeFrom={contactToMerge}
              contactToMergeInto={contactToMergeInto}
              setContactToMergeInto={setContactToMergeInto}
              setDisplayContinueMerge={setDisplayContinueMerge}
            />
          ) : (
            <MergeSelectedContact
              contactToMergeFrom={contactToMerge}
              contactToMergeInto={contactToMergeInto!}
            />
          )}
        </DialogBody>
        <DialogFooter justifyContent={step === 2 ? "space-between" : "end"}>
          {step === 2 && (
            <Button
              onClick={() => setStep(1)}
              variant="ghost"
              size="lg"
              mr={10}
            >
              Back
            </Button>
          )}
          {displayContinueMerge && (
            <Button
              disabled={!contactToMergeInto}
              onClick={() => (step === 1 ? setStep(2) : onMergeContacts())}
            >
              {(modalLoading && <Spinner />) ||
                (step === 1 && "Continue") ||
                (step === 2 && "Merge")}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default MergeContact;
