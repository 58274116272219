import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  List,
  Portal,
  Separator,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import React, { useCallback, useState } from "react";
import { LuBraces, LuChevronDown, LuPlus } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import AddCustomFieldModal from "./AddCustomFieldModal";
import CustomFieldMenuItem, {
  humanizeCustomFieldKey,
} from "./CustomFieldMenuItem";

interface AddCustomFieldProps {
  useSmallVersion?: boolean;
  isDisabled: boolean;
  customFields: { [key: string]: string };
  onCustomFieldSelect: (cf: string) => void;
  size?: "xs" | "sm" | "md" | "lg";
  addNewCustomField: (key: string, value: string) => void;
  shouldAskForExampleValues?: boolean;
}

const AddCustomField: React.FC<AddCustomFieldProps> = ({
  isDisabled,
  customFields,
  size = "sm",
  useSmallVersion = false,
  onCustomFieldSelect,
  addNewCustomField,
  shouldAskForExampleValues = false,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [newCustomFieldKey, setNewCustomFieldKey] = useState<string>("");

  const [isPersonalizedCustomField, setIsPersonalizedCustomField] =
    useState<boolean>(true);

  const createCustomFieldModal = useDisclosure();

  const onInitialSelect = useCallback(
    (key: string) => {
      if (customFields[key] === "" && shouldAskForExampleValues) {
        setIsPersonalizedCustomField(false);
        setNewCustomFieldKey(key);
        createCustomFieldModal.onOpen();
        return;
      }
      onCustomFieldSelect?.(`{${key}}`);
    },
    [customFields, onCustomFieldSelect, createCustomFieldModal]
  );

  const [searchValue, setSearchValue] = useState("");
  const popoverState = useDisclosure();

  return (
    <>
      <PopoverRoot
        closeOnInteractOutside={true}
        closeOnEscape={true}
        open={popoverState.open}
        onOpenChange={({ open: newOpen }) => {
          if (newOpen) {
            popoverState.onOpen();
          } else {
            popoverState.onClose();
          }
        }}
      >
        <PopoverTrigger disabled={isDisabled} asChild>
          {isBaseSize || useSmallVersion ? (
            <IconButton
              disabled={isDisabled}
              colorPalette={!isDisabled ? "green" : "gray"}
              p={0}
              size={size}
              zIndex={1}
            >
              <Icon
                as={LuBraces}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />
            </IconButton>
          ) : (
            <Button
              disabled={isDisabled}
              size={size}
              className="menu-custom-field-button"
              colorPalette={!isDisabled ? "green" : "gray"}
            >
              <Icon
                as={LuBraces}
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />{" "}
              Add Custom Field <LuChevronDown />
            </Button>
          )}
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={0}>
              <Box p={2}>
                <Input
                  placeholder="Search fields..."
                  size="sm"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  autoFocus
                />
              </Box>
              <Separator />
              <List.Root maxHeight="300px" overflowY="auto">
                {Object.entries(customFields)
                  .filter(([key, _value]) =>
                    humanizeCustomFieldKey(key)
                      .toLowerCase()
                      .includes(searchValue.toLowerCase().trim())
                  )
                  .map(([key, _value]) => (
                    <CustomFieldMenuItem
                      key={key}
                      customFieldKey={key}
                      onClick={() => {
                        if (isDisabled) {
                          return;
                        }
                        onInitialSelect(key);
                      }}
                    />
                  ))}
              </List.Root>
              <Separator />
              <Flex w="100%" p={2} justifyContent="end">
                <Button
                  onClick={() => {
                    setIsPersonalizedCustomField(true);
                    popoverState.onClose();
                    createCustomFieldModal.onOpen();
                  }}
                  size="xs"
                  colorPalette={colorScheme}
                >
                  Create <Icon as={LuPlus} />
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </PopoverRoot>
      <AddCustomFieldModal
        isOpen={createCustomFieldModal.open}
        onClose={createCustomFieldModal.onClose}
        newCustomFieldKey={newCustomFieldKey}
        setNewCustomFieldKey={setNewCustomFieldKey}
        isPersonalizedCustomField={isPersonalizedCustomField}
        addNewCustomField={addNewCustomField}
        onCustomFieldSelect={onCustomFieldSelect}
        customFields={customFields}
      />
    </>
  );
};

export default AddCustomField;
