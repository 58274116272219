import { SUPPORTED_MEDIA_TYPES } from "components/shared/FilePreview";
import { AbstractTemplate } from "../../../redux/features/templates";
import FullTemplateDomain from "../templates/full_template";

export enum WabaTemplateCategory {
  UTILITY = "UTILITY",
  MARKETING = "MARKETING",
  AUTHENTICATION = "AUTHENTICATION",
}

export enum WabaTemplateStatus {
  APPROVED = "approved",
  SUBMITTED = "submitted",
  DISABLED = "disabled",
  IN_APPEAL = "in_appeal",
  PENDING = "pending",
  REINSTATED = "reinstated",
  REJECTED = "rejected",
  FLAGGED = "flagged",
  PENDING_DELETION = "pending_deletion",
}

export enum WhatsappTemplateComponentType {
  BODY = "BODY",
  HEADER = "HEADER",
  FOOTER = "FOOTER",
  BUTTONS = "BUTTONS",
}

export enum WhatsappTemplateComponentFormat {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  LOCATION = "LOCATION",
  BUTTONS = "BUTTONS",
}

export type HeaderContent<F> = F extends
  | WhatsappTemplateComponentFormat.IMAGE
  | WhatsappTemplateComponentFormat.VIDEO
  | WhatsappTemplateComponentFormat.DOCUMENT
  ? { header_handle: string[]; header_text?: never }
  : { header_text: string[]; header_handle?: never };

export class WhatsappTemplateHeaderExample<
  F extends WhatsappTemplateComponentFormat
> {
  header_handle?: string[];
  header_text?: string[];

  constructor(content: HeaderContent<F>) {
    if ("header_handle" in content) {
      this.header_handle = content.header_handle;
    }
    if ("header_text" in content) {
      this.header_text = content.header_text;
    }
  }

  getVariables(): string[] {
    if (this.header_handle) {
      return [];
    }

    return this.header_text || [];
  }

  getUrl(): string | null {
    if (this.header_handle) {
      return this.header_handle[0];
    }

    return null;
  }
}

export class WhatsappTemplateBodyExample {
  body_text: string[][];

  constructor(body_text: string[][]) {
    this.body_text = body_text;
  }

  getVariables(): string[] {
    return this.body_text[0];
  }
}

export type WhatsappTemplateLocationParamters<
  T extends WhatsappTemplateComponentType,
  F extends WhatsappTemplateComponentFormat
> = T extends WhatsappTemplateComponentType.HEADER
  ? F extends WhatsappTemplateComponentFormat.LOCATION
    ? {
        type: string;
        location: {
          longitude: string;
          latitude: string;
          name: string;
          address: string;
        };
      }
    : never
  : never;

export type WhatsappTemplateComponentParameters<
  T extends WhatsappTemplateComponentType,
  F extends WhatsappTemplateComponentFormat
> = WhatsappTemplateLocationParamters<T, F>;

export type WhatsappTemplateExample<
  T extends WhatsappTemplateComponentType,
  F extends WhatsappTemplateComponentFormat
> = T extends WhatsappTemplateComponentType.HEADER
  ? WhatsappTemplateHeaderExample<F>
  : T extends WhatsappTemplateComponentType.BODY
  ? WhatsappTemplateBodyExample
  : never;

const getExtensionFromUrl = (url: string): string | null => {
  const urlWithoutQueryParams = url.split("?")[0];
  const parts = urlWithoutQueryParams.split(".");

  if (parts.length <= 1) {
    return null;
  }

  const potentialExtension = parts[parts.length - 1] || null;

  if (!potentialExtension) {
    return null;
  }

  if (
    !SUPPORTED_MEDIA_TYPES.find((type) => type.includes(potentialExtension))
  ) {
    return null;
  }

  return potentialExtension;
};

const guessExensionByMediaFormat = (
  format: WhatsappTemplateComponentFormat
): string => {
  switch (format) {
    case WhatsappTemplateComponentFormat.DOCUMENT:
      return "pdf";
    case WhatsappTemplateComponentFormat.IMAGE:
      return SUPPORTED_MEDIA_TYPES.filter((type) =>
        type.startsWith("image")
      )[0].split("/")[1];
    case WhatsappTemplateComponentFormat.VIDEO:
      return "mp4";
    default:
      return "";
  }
};

const calculateType = (
  format: WhatsappTemplateComponentFormat,
  url: string
): string => {
  let fileType = format.toLowerCase();
  let extension = getExtensionFromUrl(url);

  switch (format) {
    case WhatsappTemplateComponentFormat.DOCUMENT:
      fileType = "application";
      if (!extension) {
        extension = guessExensionByMediaFormat(format);
      }
      break;
    // fall through is mandatory here
    case WhatsappTemplateComponentFormat.VIDEO:
    case WhatsappTemplateComponentFormat.IMAGE:
    case WhatsappTemplateComponentFormat.LOCATION:
    default:
      if (!extension) {
        extension = guessExensionByMediaFormat(format);
      }
  }

  return `${fileType}/${extension}`;
};

export class WhatsappTemplateComponent<
  T extends WhatsappTemplateComponentType,
  F extends WhatsappTemplateComponentFormat
> {
  type: T;
  format: F;
  text: string;
  example: WhatsappTemplateExample<T, F> | null;
  parameters: WhatsappTemplateComponentParameters<T, F> | null;
  buttons: WhatsappButton[] | null;

  constructor(
    type: T,
    format: F,
    text: string,
    example: WhatsappTemplateExample<T, F> | null,
    parameters: WhatsappTemplateComponentParameters<T, F> | null,
    buttons: WhatsappButton[] | null
  ) {
    this.type = type;
    this.format = format;
    this.text = text;
    this.example = example;
    this.parameters = parameters;
    this.buttons = buttons;
  }

  getMedia(): {
    type: string;
    url: string;
  } | null {
    if (!this.example) {
      return null;
    }

    if (this.type === WhatsappTemplateComponentType.HEADER) {
      const headerExample = this.example as WhatsappTemplateHeaderExample<F>;

      if (["IMAGE", "VIDEO", "DOCUMENT"].includes(this.format as any)) {
        if (headerExample.header_handle) {
          const type = calculateType(
            this.format,
            headerExample.header_handle[0]
          );

          return {
            type,
            url: headerExample.header_handle[0], // Assuming first element is the URL
          };
        } else {
          // eslint-disable-next-line no-console
          console.error("header_handle is missing for IMAGE/VIDEO/DOCUMENT.");
          return null;
        }
      }

      if (headerExample.header_text) {
        return {
          type: this.format.toLowerCase(),
          url: headerExample.header_text[0], // Assuming first element is the URL
        };
      } else {
        throw new Error(
          "header_text is missing for non-IMAGE/VIDEO/DOCUMENT formats."
        );
      }
    }

    return null;
  }
}

export enum WhatsappButtonType {
  URL = "URL",
  QUICK_REPLY = "QUICK_REPLY",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export class WhatsappButton {
  type: WhatsappButtonType;
  text: string;
  url?: string;
  phoneNumber?: string;

  constructor(
    type: WhatsappButtonType,
    text: string,
    url?: string,
    phoneNumber?: string
  ) {
    this.type = type;
    this.text = text;
    this.url = url;
    this.phoneNumber = phoneNumber;
  }
}

export type AnyWhatsappTemplateComponent =
  | WhatsappTemplateComponent<
      WhatsappTemplateComponentType.HEADER,
      WhatsappTemplateComponentFormat
    >
  | WhatsappTemplateComponent<
      WhatsappTemplateComponentType.BODY,
      WhatsappTemplateComponentFormat
    >
  | WhatsappTemplateComponent<
      WhatsappTemplateComponentType.FOOTER,
      WhatsappTemplateComponentFormat
    >
  | WhatsappTemplateComponent<
      WhatsappTemplateComponentType.BUTTONS,
      WhatsappTemplateComponentFormat
    >;

export default class WhatsappTemplate implements AbstractTemplate {
  id: string;
  name: string;
  merchantId: number;
  category: WabaTemplateCategory;
  status: WabaTemplateStatus;
  rejectedReason: string;
  components: AnyWhatsappTemplateComponent[];
  customFields: { [key: string]: string } | null;
  namespace: string;

  constructor(
    id: string,
    name: string,
    merchantId: number,
    category: WabaTemplateCategory,
    status: WabaTemplateStatus,
    rejectedReason: string,
    components: AnyWhatsappTemplateComponent[],
    customFields: { [key: string]: string } | null,
    namespace: string
  ) {
    this.id = id;
    this.name = name;
    this.merchantId = merchantId;
    this.category = category;
    this.status = status;
    this.rejectedReason = rejectedReason;
    this.components = components;
    this.customFields = customFields;
    this.namespace = namespace;
  }

  canEdit(): boolean {
    return false;
  }

  isGeneric(): boolean {
    return false;
  }

  isCustom(): boolean {
    return true;
  }

  getWhatsAppStatusColor(): string {
    switch (this.status) {
      case WabaTemplateStatus.SUBMITTED:
        return "blue.400";
      case WabaTemplateStatus.APPROVED:
        return "green.400";
      case WabaTemplateStatus.DISABLED:
        return "red.400";
      case WabaTemplateStatus.IN_APPEAL:
        return "orange.400";
      case WabaTemplateStatus.PENDING:
        return "yellow.400";
      case WabaTemplateStatus.REINSTATED:
        return "green.400";
      case WabaTemplateStatus.REJECTED:
        return "red.400";
      case WabaTemplateStatus.FLAGGED:
        return "red.400";
      default:
        return "";
    }
  }

  getMedia(): {
    type: string;
    url: string;
  } | null {
    const headerComponent = this.components.find(
      (c) => c.type === WhatsappTemplateComponentType.HEADER
    );

    if (headerComponent) {
      return headerComponent.getMedia();
    }

    return null;
  }

  getWholeText(): string {
    return this.components.map((c) => c.text).join("\n");
  }

  isWhatsappTemplate(): this is WhatsappTemplate {
    return true;
  }

  isRegularTemplate(): this is FullTemplateDomain {
    return false;
  }

  isPendingDeletion(): boolean {
    return this.status === WabaTemplateStatus.PENDING_DELETION;
  }

  getHeaderText(): string {
    const headerComponent = this.components.find(
      (c) => c.type === WhatsappTemplateComponentType.HEADER
    );

    if (headerComponent) {
      return headerComponent.text || "";
    }

    return "";
  }

  getBodyText(): string {
    const bodyComponent = this.components.find(
      (c) => c.type === WhatsappTemplateComponentType.BODY
    );

    if (bodyComponent) {
      return bodyComponent.text || "";
    }

    return "";
  }

  getFooterText(): string {
    const footerComponent = this.components.find(
      (c) => c.type === WhatsappTemplateComponentType.FOOTER
    );

    if (footerComponent) {
      return footerComponent.text || "";
    }

    return "";
  }

  getButtons(): WhatsappButton[] {
    const buttonsComponent = this.components.find(
      (c) => c.type === WhatsappTemplateComponentType.BUTTONS
    );

    if (buttonsComponent) {
      return buttonsComponent.buttons || [];
    }

    return [];
  }
}
