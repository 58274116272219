import { Icon, Input } from "@chakra-ui/react";
import { InputGroup } from "components/ui/input-group";
import React, { ChangeEvent } from "react";
import { LuSearch } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface ReviewsSearchProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
}

const ReviewsSearch = ({
  onChange,
  value,
  placeholder,
}: ReviewsSearchProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <InputGroup w="100%" startElement={<Icon as={LuSearch} color="gray.500" />}>
      <Input
        colorPalette={colorScheme}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </InputGroup>
  );
};

export default ReviewsSearch;
