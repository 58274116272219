import { Box, Button, Fieldset } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { useAppSelector } from "redux/hooks";

import { useAuth0 } from "@auth0/auth0-react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, { useEffect, useState } from "react";
import AdminService from "services/admin";
import SelectMerchant from "./select-merchant";

const BusinessDescription = () => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [defaultDescriptionValue, setDefaultDescriptionValue] = useState<{
    value: string;
  }>({
    value: selectedMerchant?.businessDescription || "",
  });
  const [newDescriptionValue, setNewDescriptionValue] = useState<string>(
    defaultDescriptionValue.value
  );

  useEffect(() => {
    const newDefaultDescription = selectedMerchant?.businessDescription || "";

    setDefaultDescriptionValue({
      value: selectedMerchant?.businessDescription || "",
    });
    setNewDescriptionValue(newDefaultDescription);
  }, [selectedMerchant]);

  const updateMerchantDescription = async () => {
    if (!selectedMerchant) {
      return;
    }

    try {
      setIsSaving(true);

      await AdminService.updateMerchant(auth0Context, {
        merchantId: selectedMerchant.id,
        business_description: newDescriptionValue,
      });

      toaster.create({
        type: "success",
        title: "Merchant Description Updated Successfully",
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to update Merchant Description",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content>
        <Field label="Select Merchant" required>
          <SelectMerchant
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
          />
        </Field>
        <Field label="Describe Merchant's Business" required>
          <Box
            p={3}
            bgColor={colorMode === "dark" ? "gray.700" : "white"}
            borderColor={`${colorScheme}.400`}
            borderWidth="1px"
            borderStyle="solid"
            borderRadius="md"
            w="100%"
          >
            <EditorUltra
              isDisabled={isSaving || !selectedMerchant}
              defaultText={defaultDescriptionValue}
              setText={(currentText: string) => {
                setNewDescriptionValue(currentText);
              }}
              enabledPlugins={[
                EditorUltraPlugin.AUTOFOCUS,
                EditorUltraPlugin.RICHTEXT,
              ]}
            />
          </Box>
        </Field>
        <Button
          w="fit-content"
          size="lg"
          onClick={updateMerchantDescription}
          disabled={isSaving || !selectedMerchant}
        >
          Save Description
        </Button>
      </Fieldset.Content>
    </Fieldset.Root>
  );
};

export default BusinessDescription;
