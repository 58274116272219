import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import KeyloopAccount from "entities/domain/keyloop-account";
import React, { useEffect, useState } from "react";
import { LuCheck } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import IntegrationService from "services/integrations";

interface SelectAccountProps {
  setSelectedKeyloopAccount: React.Dispatch<
    React.SetStateAction<KeyloopAccount | null>
  >;
}

const SelectAccount = ({ setSelectedKeyloopAccount }: SelectAccountProps) => {
  const auth0Context = useAuth0();
  const [keyloopAccounts, setKeyloopAccounts] = useState<KeyloopAccount[]>([]);

  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState(true);
  const [almostSelectedKeyloopAccount, setAlmostSelectedKeyloopAccount] =
    useState<KeyloopAccount | null>(null);

  const fetchKeyloopAccounts = async () => {
    setIsLoading(true);

    try {
      const fetchedKeyloopAccounts =
        await IntegrationService.getKeyloopAccounts(auth0Context, merchant.id);

      setKeyloopAccounts(fetchedKeyloopAccounts);
    } catch (error: unknown) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not fetch Keyloop accounts at this time. Please try again later or contact our customer support!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchKeyloopAccounts();
  }, []);

  return (
    <>
      {isLoading ? <Spinner /> : null}
      {keyloopAccounts.map((keyloopAccount) => (
        <Button
          w="100%"
          key={`${keyloopAccount.enterpriseId}-${keyloopAccount.storeId}`}
          colorPalette={colorScheme}
          variant={almostSelectedKeyloopAccount?.enterpriseId ===
            keyloopAccount.enterpriseId &&
          almostSelectedKeyloopAccount?.storeId === keyloopAccount.storeId ? "subtle" : "ghost"}
          size="lg"
          borderRadius="2xl"
          borderStyle="solid"
          borderWidth="1px"
          borderColor={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
          p={8}
          onClick={() => {
            if (
              almostSelectedKeyloopAccount?.enterpriseId ===
                keyloopAccount.enterpriseId &&
              almostSelectedKeyloopAccount?.storeId === keyloopAccount.storeId
            ) {
              setAlmostSelectedKeyloopAccount(null);
              return;
            }

            setAlmostSelectedKeyloopAccount(keyloopAccount);
          }}
        >
          {keyloopAccount.accountName ||
            `${keyloopAccount.enterpriseId}-${keyloopAccount.storeId}`}
          {
            almostSelectedKeyloopAccount?.enterpriseId ===
              keyloopAccount.enterpriseId &&
            almostSelectedKeyloopAccount?.storeId === keyloopAccount.storeId ? (
              <LuCheck
                color={colorMode === "dark" ? "green.200" : "green.500"}
              />
            ) : undefined
          }
        </Button>
      ))}
      {!isLoading && keyloopAccounts.length === 0 ? (
        <Alert status="warning" my={4} title="No Keyloop Accounts Available" />
      ) : (
        <HStack w="100%" justifyContent="end" alignItems="center" mt={4}>
          <Button
            size="md"
            colorPalette={colorScheme}
            disabled={!almostSelectedKeyloopAccount}
            onClick={() => {
              setSelectedKeyloopAccount(almostSelectedKeyloopAccount);
            }}
          >
            Continue
          </Button>
        </HStack>
      )}
    </>
  );
};

export default SelectAccount;
