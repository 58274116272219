import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Separator,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Spinner from "components/spinner";
import { Checkbox } from "components/ui/checkbox";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import AgentPermissionsDomain from "entities/domain/agents/agent-permissions-domain";
import AgentRolesDomain, {
  AgentRole,
} from "entities/domain/agents/agent-roles-domain";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import PermissionsService from "services/permissions";
import RolesService from "services/roles";

export const RolePermissions = () => {
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const navigate = useNavigate();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();

  const [roles, setRoles] = useState<Array<AgentRolesDomain>>([]);
  const [permissionsByGroup, setPermissionsByGroup] = useState<
    Map<string, AgentPermissionsDomain[]>
  >(new Map());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const permissionsToMap = (
    permission: AgentPermissionsDomain[]
  ): Map<string, AgentPermissionsDomain[]> => {
    const result = new Map<string, AgentPermissionsDomain[]>();

    permission.forEach((p: AgentPermissionsDomain) => {
      const permissionByGroup = result.get(p.group);
      if (permissionByGroup) {
        permissionByGroup.push(p);
      } else {
        result.set(p.group, [p]);
      }
    });

    return result;
  };

  const sortRolesByName = (a: AgentRolesDomain, b: AgentRolesDomain) =>
    a.name.localeCompare(b.name);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        RolesService.getRoles(auth0Context, merchant.id).then((res) =>
          setRoles(
            res.filter((role) => role.name !== "fuzey").sort(sortRolesByName)
          )
        );
        PermissionsService.getPermissions(auth0Context).then((res) => {
          setPermissionsByGroup(permissionsToMap(res));
        });
      } catch (err) {
        /* eslint-disable no-console */
        console.error("Failed to load role permissions: ", err);
        /* eslint-enable no-console */
        toaster.create({
          type: "error",
          title: "Failed to load role permissions",
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [merchant.id]);

  const onCheckPermission = (
    permStatus: boolean,
    roleId: number,
    permission: AgentPermissionsDomain
  ) => {
    const role = roles.filter((r) => r.id === roleId)[0];

    const newSelectedRole = Object.setPrototypeOf(
      { ...role },
      AgentRolesDomain.prototype
    ) as AgentRolesDomain;

    if (permStatus) {
      newSelectedRole.assignedPermissions(permission);
    } else {
      newSelectedRole.unassignedPermissions(permission);
    }

    const newArr = [...roles].map((i) =>
      i.id === newSelectedRole.id ? newSelectedRole : i
    );

    setRoles(newArr.sort(sortRolesByName));
  };

  const saveRoles = async () => {
    try {
      await Promise.all(
        roles
          .filter(
            (role) =>
              role.name !== AgentRole.ADMIN && role.name !== AgentRole.FUZEY
          )
          .map((role) => {
            return RolesService.updateRole(auth0Context, {
              merchantId: merchant.id,
              roleId: role.id,
              name: role.name,
              description: role.description,
              permissions: [
                ...new Set(role.permissions.map((perm) => perm.id)),
              ],
            });
          })
      );

      toaster.create({
        type: "success",
        title: "Permissions successfully saved",
      });

      setTimeout(() => {
        navigate(`/${merchant.id}/settings/teammates`);
      }, 2000);
    } catch (error) {
      // eslint-disable-next-line
      console.error("Failed to update all roles", error);
      toaster.create({ type: "error", title: "An error occurred" });
    }
  };

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <>
      <Flex
        flexDirection="column"
        w="100%"
        overflow="hidden"
        {...(isBaseSize
          ? {}
          : {
              mt: "4.50rem",
              borderTopRadius: "3xl",
              bgColor: colorMode === "dark" ? "gray.800" : "white",
            })}
      >
        {isLoading || permissionsByGroup.size === 0 || roles.length === 0 ? (
          <Spinner />
        ) : (
          <Box w="100%" overflowY="scroll">
            <Box px={4}>
              <Box mt={6}>
                <Grid
                  templateColumns={`3fr repeat(${roles.length}, 0.5fr)`}
                  gap={1}
                >
                  <GridItem />
                  {roles.map((role) => (
                    <GridItem
                      key={role.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      <Text maxWidth={isBaseSize ? "1rem" : "initial"} truncate>
                        {role.name}
                      </Text>
                    </GridItem>
                  ))}
                </Grid>

                {Array.from(permissionsByGroup.keys()).map((group: string) => (
                  <Box key={group} my={5} id={`permission-group-${group}`}>
                    <Flex
                      justifyContent="start"
                      alignItems="center"
                      backgroundColor={
                        colorMode === "dark"
                          ? `${colorScheme}.700`
                          : `${colorScheme}.200`
                      }
                      borderRadius="full"
                      py={1}
                      px={3}
                    >
                      <Heading
                        size="md"
                        textTransform="capitalize"
                        color="white"
                      >
                        {group}
                      </Heading>
                    </Flex>
                    {permissionsByGroup
                      .get(group)
                      ?.map(
                        (permission: AgentPermissionsDomain, index: number) => {
                          return (
                            <>
                              {index === 0 ? null : (
                                <Separator w="95%" mx="auto" />
                              )}
                              <Grid
                                templateColumns={`3fr repeat(${roles.length}, 0.5fr)`}
                                key={permission.id}
                                data-testid={`${permission.name}-grid-item`}
                                py={3}
                                px={3}
                                borderRadius="lg"
                                _hover={{
                                  backgroundColor:
                                    colorMode === "dark"
                                      ? "gray.900"
                                      : "gray.50",
                                }}
                              >
                                <GridItem
                                  display="flex"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  pl={6}
                                  data-testid={`permission-description-${permission.name}`}
                                >
                                  {permission.description}
                                </GridItem>
                                {roles.map((r: AgentRolesDomain) => {
                                  return (
                                    <GridItem
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      key={r.id}
                                    >
                                      <Checkbox
                                        data-testid={`checkbox-${permission.name}-${r.name}`}
                                        id={`checkbox-${permission.name}-${r.name}-input`}
                                        checked={r.isPermissionEnabled(
                                          permission.id
                                        )}
                                        disabled={r.name === AgentRole.ADMIN}
                                        onCheckedChange={(e) =>
                                          onCheckPermission(
                                            !!e.checked,
                                            r.id,
                                            permission
                                          )
                                        }
                                      />
                                    </GridItem>
                                  );
                                })}
                              </Grid>
                            </>
                          );
                        }
                      )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
        <Separator />
        <Flex w="100%" px={4} justifyContent="end" py={6}>
          <Button
            data-testid="team-member-form-save-button"
            colorPalette={colorScheme}
            onClick={saveRoles}
            borderRadius="full"
            width={{ base: "full", md: "fit-content" }}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default RolePermissions;
