import { useAuth0 } from "@auth0/auth0-react";
import { Text, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React, { useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { setFilterByModel } from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";
import { getReactSelectStyles } from "util/methods";

interface ModelFilterProps {}

interface AvailableModelOption {
  value: string;
  label: string;
}

const ModelFilter = (_props: ModelFilterProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    filterByModel,
    filterByMake,
    filterOptions,
    filterByCondition,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [availableModelOptions, setAvailableModelOptions] = useState<
    MultiValue<AvailableModelOption>
  >(
    filterByMake
      ? filterOptions.allMakeModels
          .find((mm) => mm.make === filterByMake)!
          .models.map((model) => ({
            value: model,
            label: model,
          }))
      : filterOptions.allModels.map((model) => ({
          value: model,
          label: model,
        }))
  );

  const [selectedModelOptionValue, setSelectedModelOptionValue] = useState<
    SingleValue<AvailableModelOption>
  >(availableModelOptions.find((mo) => mo.value === filterByModel) || null);

  useEffect(() => {
    setSelectedModelOptionValue(
      availableModelOptions.find((mo) => mo.value === filterByModel) || null
    );
  }, [filterByModel]);

  useEffect(() => {
    if (
      !filterByMake &&
      !filterByYearMin &&
      !filterByYearMax &&
      !filterByPriceMin &&
      !filterByPriceMax &&
      !filterByCondition &&
      !filterByAvailability
    ) {
      setAvailableModelOptions(
        filterOptions.allModels.map((model) => ({
          value: model,
          label: model,
        }))
      );
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      null,
      filterByMake,
      filterByYearMin,
      filterByYearMax,
      filterByPriceMin,
      filterByPriceMax,
      filterByCondition,
      filterByAvailability
    ).then((newFilterOptions) => {
      setAvailableModelOptions(
        newFilterOptions.allModels.map((make) => ({
          value: make,
          label: make,
        }))
      );
    });
  }, [
    filterOptions,
    filterByCondition,
    filterByMake,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
  ]);

  return availableModelOptions.length <= 1 ? null : (
    <VStack w="100%">
      {filterByMake || filterOptions.allMakes.length === 1 ? null : (
        <Text
          alignSelf="start"
          fontSize="xs"
          color={colorMode === "dark" ? "red.200" : "red.500"}
        >
          Please select a vehicle make first
        </Text>
      )}
      <Select
        id="filter-by-model-select"
        isMulti={false}
        isDisabled={!filterByMake && filterOptions.allMakes.length > 1}
        placeholder="Filter by Model"
        isClearable={true}
        isSearchable={true}
        value={selectedModelOptionValue}
        onChange={(newValue) => {
          dispatch(setFilterByModel(newValue?.value || null));
        }}
        options={availableModelOptions}
        styles={{
          ...{
            ...getReactSelectStyles(colorMode || "light", colorScheme),
            container: (provided: any) => ({
              ...provided,
              width: "100%",
            }),
          },
        }}
      />
    </VStack>
  );
};

export default ModelFilter;
