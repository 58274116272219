export interface OutcomesCount {
  noOutcome: number;
  notShowed: {
    contacted: number;
    needsToBeContacted: number;
    rescheduled: number;
  };
  showed: {
    converted: number;
    notConverted: number;
  };
}

export default class BookingsOutcomesCountDomain {
  merchantId: number;
  dateFrom: string | null;
  dateTo: string | null;
  outcomes: OutcomesCount;

  constructor(
    merchantId: number,
    dateFrom: string | null,
    dateTo: string | null,
    outcomes: OutcomesCount
  ) {
    this.merchantId = merchantId;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.outcomes = outcomes;
  }
}
