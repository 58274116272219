import { Icon, IconButton, Input, Portal, Textarea } from "@chakra-ui/react";
import data from "@emoji-mart/data";
import EmojiPickerWithSuspense from "components/shared/LazyEmojiPicker";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { InputGroup } from "components/ui/input-group";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import React, { Dispatch, SetStateAction, memo, useState } from "react";
import { LuSmile } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface TeamFormProps {
  teamName: string;
  setTeamName: Dispatch<SetStateAction<string>>;
  teamPurpose: string;
  setTeamPurpose: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

const TeamForm = ({
  teamName,
  setTeamName,
  teamPurpose,
  setTeamPurpose,
  isLoading,
}: TeamFormProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);

  return (
    <>
      <Field label="Name">
        <InputGroup
          w="100%"
          endElement={
            <PopoverRoot
              closeOnInteractOutside={true}
              open={openEmojiPopover}
              onOpenChange={(e) => setOpenEmojiPopover(e.open)}
              lazyMount={true}
              unmountOnExit={true}
            >
              <Portal>
                <PopoverContent
                  width="100%"
                  onWheel={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <EmojiPickerWithSuspense
                    data={data}
                    theme={colorMode}
                    onEmojiSelect={({ native }: { native: string }) => {
                      setTeamName((prev: string) => {
                        if (prev.length === 0) {
                          return native;
                        }

                        return prev + native;
                      });
                    }}
                  />
                </PopoverContent>
              </Portal>

              <PopoverTrigger asChild>
                <IconButton
                  size="xs"
                  variant="ghost"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  colorPalette="yellow"
                  disabled={isLoading}
                  aria-label="Pick emoji"
                >
                  <Icon as={LuSmile} height="1.25rem" width="1.25rem" />
                </IconButton>
              </PopoverTrigger>
            </PopoverRoot>
          }
        >
          <Input
            type="text"
            value={teamName}
            placeholder="Sales 👨‍💼"
            onChange={(e) => setTeamName(e.target.value)}
            disabled={isLoading}
          />
        </InputGroup>
      </Field>
      <Field
        label="Purpose"
        helperText="Explain to us what kind of enquiries this team wants to handle"
        mt={2}
      >
        <Textarea
          bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
          value={teamPurpose}
          onChange={(e) => setTeamPurpose(e.target.value)}
          size="sm"
          borderRadius="lg"
          borderWidth={0}
          disabled={isLoading}
        />
      </Field>
    </>
  );
};

export default memo(TeamForm);
