import {
  Badge,
  Box,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import InventoryVehicleDomain, {
  AvailabilityStatus,
  VehicleCondition,
} from "entities/domain/inventory-vehicle";
import React, { useLayoutEffect, useRef, useState } from "react";
import { setSelectedVehicle } from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const getColorSchemeByAvailabilityStatus = (
  status: AvailabilityStatus | null
) => {
  switch (status) {
    case AvailabilityStatus.AVAILABLE:
      return "green";
    case AvailabilityStatus.SOLD:
      return "red";
    case AvailabilityStatus.RESERVED:
      return "yellow";
    default:
      return "gray";
  }
};

const VehicleItem = ({ item: vehicle }: { item: InventoryVehicleDomain }) => {
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const dispatch = useAppDispatch();
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isLongText, setIsLongText] = useState(false);

  useLayoutEffect(() => {
    const { current } = textRef;

    if (!current) {
      setIsLongText(false);
      return;
    }

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsLongText(hasOverflow);
    };

    if ("ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(current);
    }

    trigger();
  }, [textRef]);

  return (
    <Box
      mx={4}
      data-testid="vehicle-item"
      borderWidth="1px"
      borderRadius="lg"
      borderStyle="solid"
      borderColor={colorMode === "dark" ? "gray.200" : "gray.50"}
      px={2}
      py={1}
      key={vehicle.id}
      _hover={{
        cursor: "pointer",
        bgColor: colorMode === "dark" ? "gray.900" : "gray.50",
      }}
      onClick={() => {
        dispatch(setSelectedVehicle(vehicle));
      }}
    >
      <VStack>
        <HStack justifyContent="start" w="100%">
          <Badge
            colorPalette={getColorSchemeByAvailabilityStatus(
              vehicle.availabilityStatus
            )}
          >
            {vehicle.availabilityStatus}
          </Badge>
        </HStack>
        <Text fontWeight="bold">
          {vehicle.make} {vehicle.model}
        </Text>
        {vehicle.grossPrice ? (
          <Text fontWeight="bold" color="gray.400">
            {merchant.getCurrencySymbol()}
            {vehicle.grossPrice}
          </Text>
        ) : null}
        <Flex
          direction="column"
          alignItems="start"
          justifyContent="center"
          css={{
            ...(isLongText
              ? {
                  maskImage:
                    "linear-gradient(to bottom, black 50%, transparent 100%)",
                }
              : {}),
          }}
        >
          <Text
            ref={textRef}
            color={colorMode === "dark" ? "gray.600" : "gray.400"}
            maxHeight="150px"
            fontSize="sm"
            // as="pre"
            overflow="hidden"
            whiteSpace="pre-wrap"
            overflowY="hidden"
          >
            {vehicle.description}
          </Text>
        </Flex>
        <HStack justifyContent="space-between" w="100%">
          <Text
            fontSize="xs"
            color={colorMode === "dark" ? "gray.200" : "gray.500"}
          >
            {vehicle.year}
          </Text>
          <Badge
            colorPalette={
              vehicle.condition === VehicleCondition.NEW ? "green" : "blue"
            }
          >
            {vehicle.condition}
          </Badge>
        </HStack>
      </VStack>
    </Box>
  );
};

export default VehicleItem;
