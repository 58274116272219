import {
  Box,
  Button as ChakraButton,
  Heading as ChakraHeading,
  Flex,
  Image,
  List,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Checkbox } from "components/ui/checkbox";
import AlertBanner from "components/user-settings/AlertBanner";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";
import { ExtendedConnectSteps } from "../../../Integrations/ConnectIntegrationTypes";
import ProgressSteps from "./steps";

interface StepsContentProps {
  steps: ExtendedConnectSteps[] | undefined;
  imgWidth?: string;
  imgHeight?: string;
  nextButtonText?: string;
  previousButtonText?: string;
  openInNewTab?: boolean;
  showActionsBorder?: boolean;
}

const StepsContent = ({
  steps,
  imgWidth,
  imgHeight,
  nextButtonText = "Continue",
  previousButtonText = "Back",
  openInNewTab = false,
  showActionsBorder = false,
}: StepsContentProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [checked, setChecked] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const forwards = () => setCurrentStep(currentStep + 1);
  const backwards = () => setCurrentStep(currentStep - 1);

  if (steps)
    return (
      <>
        <Box>
          <ProgressSteps steps={steps} activeStep={currentStep} size="sm" />
        </Box>

        {steps && currentStep === 0 && (
          <Box mt={5}>
            <AlertBanner
              bgColor="rgb(206,230,255)"
              borderColor="rgb(206,230,255)"
              content="Please follow this short step-by-step guide to get started with Instagram successfully."
            />
          </Box>
        )}

        <ChakraHeading
          size="sm"
          pt={6}
          textAlign={isBaseSize ? "center" : "initial"}
        >
          {steps[currentStep].heading}
        </ChakraHeading>
        <Text py={2} textAlign={isBaseSize ? "center" : "initial"}>
          {steps[currentStep].description}
        </Text>
        {steps[currentStep].actionItem && (
          <Box
            p={2}
            borderStyle="solid"
            borderWidth="1px"
            borderColor="gray.50"
            borderRadius="md"
            my={2}
          >
            <Checkbox
              checked={checked}
              onCheckedChange={(e) => setChecked(!!e.checked)}
            >
              {steps[currentStep].actionItem}
            </Checkbox>
          </Box>
        )}
        <Box>
          <Text> {steps[currentStep].subPointsHeader}</Text>
          <List.Root>
            {steps[currentStep].subPoints?.map((point) => (
              <List.Item key={point}>{point}</List.Item>
            ))}
          </List.Root>
        </Box>

        <Text py={2} textAlign={isBaseSize ? "center" : "initial"}>
          {steps[currentStep].alternateStep}
        </Text>
        {steps[currentStep].buttonImage && (
          <>
            <Image
              src={steps[currentStep].buttonImage}
              width={imgWidth}
              height={imgHeight}
              onClick={
                openInNewTab
                  ? steps[currentStep].openInNewTab
                  : steps[currentStep].onContinue
              }
              cursor="pointer"
              mx="2.5rem"
              mb="1rem"
              alignSelf="end"
            />
          </>
        )}
        <Flex
          alignItems="center"
          borderWidth={showActionsBorder ? "1px" : 0}
          borderStyle="solid"
          borderColor="gray.50"
          borderRadius="md"
          p={2}
          justifyContent={currentStep > 0 ? "space-between" : "end"}
        >
          {currentStep > 0 && (
            <ChakraButton
              variant="ghost"
              colorPalette={colorScheme}
              onClick={backwards}
            >
              {previousButtonText}
            </ChakraButton>
          )}
          {!steps[currentStep].buttonImage && (
            <ChakraButton
              disabled={!checked}
              colorPalette={colorScheme}
              onClick={() => {
                forwards();
                setChecked(false);
              }}
            >
              {nextButtonText}
            </ChakraButton>
          )}
        </Flex>
      </>
    );

  return null;
};

export default StepsContent;
