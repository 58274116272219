import { Button, Group, HStack, Input, Text, VStack } from "@chakra-ui/react";

import ProfileAvatar from "components/profile/profile-avatar";
import { Tooltip } from "components/ui/tooltip";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface ProfileAreaProps {
  contactToDisplay: ContactDomain;
}

function ProfileArea({ contactToDisplay }: ProfileAreaProps) {
  const { editContact, getContactTags } = useContactsStore();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>("");

  useEffect(() => {
    setDisplayName(contactToDisplay.getDisplayName());
    setProfilePicture(contactToDisplay.getPicture());
    setName(contactToDisplay.name || "");
    setSurname(contactToDisplay.surname || "");
  }, [contactToDisplay]);

  const onSaveInfo = async () => {
    const isEdited: boolean =
      (name === contactToDisplay.name &&
        surname !== contactToDisplay.surname) ||
      (name !== contactToDisplay.name &&
        surname === contactToDisplay.surname) ||
      (name !== contactToDisplay.name && surname !== contactToDisplay.surname);

    if (!isEdited) return;

    editContact({
      id: contactToDisplay!.id!,
      name: name || undefined,
      surname: surname || undefined,
      tags: getContactTags(contactToDisplay).map((tag: TagsDomain) => tag.tag),
      tagIds: contactToDisplay.tagIds,
      address: contactToDisplay.address?.country
        ? {
            first_line: contactToDisplay.address.firstLine,
            second_line: contactToDisplay.address.secondLine,
            city: contactToDisplay.address.city,
            country: contactToDisplay.address.country,
            state: contactToDisplay.address.state,
            postcode: contactToDisplay.address.postcode,
          }
        : undefined,
      channels: contactToDisplay.channels.map((c: CustomerChannelDomain) => ({
        id: c.id,
        type: c.type,
        handle: c.handle,
        is_active: c.isActive,
        is_subscribed: c.isSubscribed,
      })),
      notes: contactToDisplay.notes,
    }).then((res) => {
      setDisplayName(res?.getDisplayName() || `${res?.name} ${res?.surname}`);
      setProfilePicture(res?.getPicture() || "");
    });
  };

  const handleOnBlur = async (event: any) => {
    if (contactToDisplay.isArchived) {
      return;
    }

    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    onSaveInfo();
    setIsEditing(false);
  };

  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <VStack gap={2}>
      <ProfileAvatar
        profilePicture={profilePicture}
        name={displayName}
        showInitials={false}
      />
      {isEditing ? (
        <HStack py="1.5rem" px="0.5rem">
          <Group onBlur={(event) => handleOnBlur(event)}>
            <Input
              colorPalette={colorScheme}
              mr={2}
              size="sm"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <Input
              size="sm"
              colorPalette={colorScheme}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              placeholder="Surname"
            />
          </Group>
        </HStack>
      ) : (
        <Button
          variant="ghost"
          onClick={() => {
            if (contactToDisplay.isArchived) {
              return;
            }

            setIsEditing(true);
          }}
          disabled={contactToDisplay.isArchived}
        >
          <Tooltip
            content="Click to edit contact name"
            disabled={contactToDisplay.isArchived}
          >
            <Text fontSize="md" fontWeight={700} _hover={{ color: "gray.300" }}>
              {displayName !== ""
                ? displayName
                : contactToDisplay.getPhoneNum()}
            </Text>
          </Tooltip>
        </Button>
      )}
    </VStack>
  );
}

export default ProfileArea;
