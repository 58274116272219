import { useAuth0 } from "@auth0/auth0-react";
import { Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { NumberInputField, NumberInputRoot } from "components/ui/number-input";
import { toaster } from "components/ui/toaster";
import { Tooltip } from "components/ui/tooltip";
import React, { useEffect, useState } from "react";
import { LuInfo } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";

const BroadcastAppUpdatedForm = () => {
  const auth0 = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [versionToBroadcast, setVersionToBroadcast] = useState<number | null>(
    null
  );
  const [socketServerVersion, setSocketServerVersion] = useState<number | null>(
    null
  );

  const broadcastAppUpdated = async () => {
    try {
      setIsLoading(true);

      if (!process.env.REACT_APP_SENTRY_RELEASE) {
        throw new Error("Could not get latest commit hash");
      }

      await AdminService.broadcastAppUpdated(auth0, versionToBroadcast!);

      toaster.create({
        type: "success",
        title: "Successfully broadcasted app update message",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't broadcast app update message", e);
      toaster.create({
        type: "error",
        title: "Couldn't broadcast app update message",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSocketVersion = async () => {
    try {
      const newSocketVersion = await AdminService.getSocketVersion(auth0);

      setSocketServerVersion(newSocketVersion);
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't get latest version from socket server", e);
    }
  };

  useEffect(() => {
    getSocketVersion();
  }, []);

  return (
    <VStack w="60%" gap={3} alignItems="start" mx="auto">
      <HStack ml={4} alignItems="center">
        <Text>
          {`Current build number: ${process.env.REACT_APP_BUILD_NUMBER}`}
        </Text>
        <Tooltip content="Make sure you refresh the page to see the latest build number">
          <Icon as={LuInfo} />
        </Tooltip>
      </HStack>
      <Text ml={4}>{`Server build number: ${socketServerVersion}`}</Text>
      <NumberInputRoot
        mt={4}
        alignSelf="center"
        disabled={isLoading || !process.env.REACT_APP_BUILD_NUMBER}
        value={versionToBroadcast?.toString() || ""}
        onValueChange={(valueChangeDetails) =>
          setVersionToBroadcast(valueChangeDetails.valueAsNumber)
        }
      >
        <NumberInputField />
      </NumberInputRoot>
      <Button
        alignSelf="center"
        colorPalette={colorScheme}
        onClick={() => setShowConfirmation(true)}
        mb={8}
        size="lg"
        disabled={
          isLoading ||
          !versionToBroadcast ||
          !process.env.REACT_APP_BUILD_NUMBER
        }
      >
        Broadcast App Updated
      </Button>
      <ConfirmationDialog
        headerText="Be careful!"
        messageText="Are you sure you want to broadcast to everybody that our frontend app has been updated?"
        buttonText="Yes"
        isOpen={showConfirmation}
        setIsOpen={setShowConfirmation}
        confirmationCallback={() => {
          setShowConfirmation(false);
          broadcastAppUpdated();
          getSocketVersion();
        }}
      />
    </VStack>
  );
};

export default BroadcastAppUpdatedForm;
