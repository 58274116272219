import {
  HStack,
  Icon,
  IconButton,
  Portal,
  useBreakpointValue,
} from "@chakra-ui/react";

import data from "@emoji-mart/data";
import AddCustomField from "components/shared/AddCustomField";
import EmojiPickerWithSuspense from "components/shared/LazyEmojiPicker";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import React, { useState } from "react";
import { LuSmile } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface ToolbarProps {
  isDisabled: boolean;
  customFields: { [key: string]: string };
  onEmojiSelect?: (emojiData: { native: string }) => void;
  onCustomFieldSelect?: (cf: string) => void;
  addNewCustomField: (key: string, value: string) => void;
}

const Toolbar = ({
  isDisabled,
  customFields,
  onEmojiSelect,
  onCustomFieldSelect,
  addNewCustomField,
}: ToolbarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);

  return (
    <HStack alignItems="center">
      {onCustomFieldSelect === undefined ? null : (
        <AddCustomField
          isDisabled={isDisabled}
          customFields={customFields}
          onCustomFieldSelect={onCustomFieldSelect}
          addNewCustomField={addNewCustomField}
          shouldAskForExampleValues={true}
        />
      )}
      {isBaseSize || onEmojiSelect === undefined ? null : (
        <PopoverRoot
          closeOnInteractOutside={true}
          open={openEmojiPopover}
          onOpenChange={(e) => {
            setOpenEmojiPopover(e.open);
          }}
        >
          <Portal>
            <PopoverContent w="100%">
              <EmojiPickerWithSuspense
                data={data}
                theme={colorMode}
                onEmojiSelect={onEmojiSelect}
              />
            </PopoverContent>
          </Portal>
          <PopoverTrigger asChild>
            <IconButton
              aria-label="Select Emoji"
              disabled={isDisabled}
              variant="ghost"
              colorPalette="yellow"
              onClick={() => {
                setOpenEmojiPopover(!openEmojiPopover);
              }}
              display="flex"
              alignItems="center"
            >
              <Icon as={LuSmile} height="2.1rem" width="2.1rem" />
            </IconButton>
          </PopoverTrigger>
        </PopoverRoot>
      )}
    </HStack>
  );
};

export default Toolbar;
