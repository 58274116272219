import { Box, Flex, Text } from "@chakra-ui/react";
import WarningTextComponent from "components/shared/WarningTextComponent";
import React, { ReactNode } from "react";

type FormItemHeaderProps = {
  title: string;
  subtitle: string;
};

const FormItemHeader = ({ title, subtitle }: FormItemHeaderProps) => {
  return (
    <Box width={{ base: "100%", sm: "55%", md: "65%" }}>
      <Text fontSize="sm">
        <b>{title}</b>
      </Text>
      <Text fontSize="xs" display={{ base: "none", sm: "block" }}>
        {subtitle}
      </Text>
    </Box>
  );
};

type FormItemInputWarningProps = {
  text?: string;
};

const FormItemInputWarning = ({ text }: FormItemInputWarningProps) => {
  return text ? <WarningTextComponent isValid={false} text={text} /> : <></>;
};

type FormItemInputRootProps = {
  children: ReactNode;
};

const FormItemInputRoot = ({ children }: FormItemInputRootProps) => {
  return (
    <Box
      width={{ base: "100%", sm: "45%", md: "35%" }}
      mt={{ base: "1rem", sm: "0" }}
    >
      {children}
    </Box>
  );
};

type FormItemRootProps = {
  children: ReactNode;
};

const FormItemRoot = ({ children }: FormItemRootProps) => {
  return (
    <Flex
      direction={{ base: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="flex-start"
      py={4}
      _notLast={{ borderBottom: "1px solid #e5ebf3" }}
    >
      {children}
    </Flex>
  );
};

type StandardFormItemProps = {
  title: string;
  subtitle: string;
  warningText?: string;
  children: ReactNode;
};

const StandardFormItem = ({
  title,
  subtitle,
  warningText,
  children,
}: StandardFormItemProps) => {
  return (
    <FormItemRoot>
      <FormItemHeader title={title} subtitle={subtitle} />
      <FormItemInputRoot>
        <FormItemInputWarning text={warningText} />
        {children}
      </FormItemInputRoot>
    </FormItemRoot>
  );
};

const FormItem = {
  Root: FormItemRoot,
  Header: FormItemHeader,
  InputRoot: FormItemInputRoot,
  InputWarning: FormItemInputWarning,
  Standard: StandardFormItem,
};

export default FormItem;
