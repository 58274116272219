import { Button, Text, VStack } from "@chakra-ui/react";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import TemplateDomain from "entities/domain/templates";
import React, { memo, useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface MessagingImpossibleProps {
  conversation: ConversationDomain | undefined;
  openSmsConversation: (messageText?: string) => Promise<void>;
  lastMessage?: MessageDomain;
  children: React.ReactNode;
  template: TemplateDomain | null;
}

const MessagingImpossible = ({
  conversation,
  openSmsConversation,
  lastMessage,
  template,
  children,
}: MessagingImpossibleProps) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const { merchant } = useAppSelector((state) => state.merchant);

  useEffect(() => {
    return () => {
      setIsClosed(false);
    };
  }, []);

  if (!conversation || !!template || isClosed) {
    return children;
  }

  if (conversation.isChannelDisconnected(merchant)) {
    if (
      conversation.channel === ConversationChannel.WHATSAPP &&
      merchant.channels.some((c) => c.channelName === ConversationChannel.SMS)
    ) {
      return (
        <VStack gap={2} py={2} w="100%">
          <Text cursor="default">
            You can’t send messages to this customer on WhatsApp.
          </Text>
          <Button onClick={() => openSmsConversation()}>Try SMS instead</Button>
        </VStack>
      );
    }

    return children;
  }

  if (lastMessage && lastMessage.isUnreachable()) {
    return (
      <VStack gap={2} py={2} w="100%">
        <Text cursor="default">
          This contact isn’t available on{" "}
          {ChannelDomain.getChannelName(conversation.channel)} right now.
        </Text>
        <Button
          onClick={() => {
            openSmsConversation(lastMessage.body);
          }}
        >
          Try SMS instead
        </Button>
        <Button variant="ghost" onClick={() => setIsClosed(true)}>
          Proceed anyway
        </Button>
      </VStack>
    );
  }

  return children;
};

export default memo(MessagingImpossible);
