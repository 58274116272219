import { Box, List, Text } from "@chakra-ui/react";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import AudienceCustomersListItem from "./AudienceCustomersListItem";

interface AudienceCustomersListProps {
  customers: ContactDomain[];
}

const AudienceCustomersList = ({ customers }: AudienceCustomersListProps) => {
  return (
    <Box
      maxHeight="500px"
      overflowY="auto"
      borderRadius="1rem"
      borderWidth="1px"
      p="1rem"
    >
      {customers.length ? (
        <List.Root listStyle="none">
          {customers.map((c) => (
            <AudienceCustomersListItem customer={c} />
          ))}
        </List.Root>
      ) : (
        <Text fontSize="lg">None</Text>
      )}
    </Box>
  );
};

export default AudienceCustomersList;
