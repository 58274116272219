import { defineRecipe } from "@chakra-ui/react";

export default defineRecipe({
  base: {
    fontWeight: "semibold",
    textAlign: "center",
    fontSize: "lg",
  },
});

