"use client";

import {
  ChakraProvider,
  createSystem,
  defaultConfig,
  defineConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import FuzeyTheme from "theme";
import { ColorModeProvider, type ColorModeProviderProps } from "./color-mode";
import { Toaster } from "./toaster";

export function Provider(props: ColorModeProviderProps) {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme, colorMode } = useAppSelector((state) => state.theme);
  const extendedTheme = useMemo(() => {
    const colorSchemeExtension = defineConfig({
      globalCss: {
        html: {
          colorPalette: colorScheme,
        },
      },
    });
    return createSystem(defaultConfig, FuzeyTheme, colorSchemeExtension);
  }, [colorScheme, merchant, colorMode]);

  return (
    <ChakraProvider value={extendedTheme}>
      <Toaster />
      <ColorModeProvider {...props} />
    </ChakraProvider>
  );
}
