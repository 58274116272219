import { Input, SpinnerProps } from "@chakra-ui/react";
import React, { Suspense, useEffect } from "react";

const LazyDatePicker = React.lazy(() => import("react-datepicker"));

let datePickerModule: any = null;
const loadDatePickerModule = async () => {
  if (!datePickerModule) {
    datePickerModule = await import("react-datepicker");
  }
  return datePickerModule;
};

export type { ReactDatePickerProps } from "react-datepicker";

interface DatePickerProps extends React.ComponentProps<typeof LazyDatePicker> {
  spinnerSize?: SpinnerProps["size"];
  loadStyles?: boolean;
}

const DatePickerWithSuspense: React.FC<DatePickerProps> = ({
  spinnerSize = "sm",
  loadStyles = true,
  ...props
}) => {
  useEffect(() => {
    loadDatePickerModule();

    if (loadStyles) {
      import(
        /* webpackChunkName: "datepicker-styles" */ "react-datepicker/dist/react-datepicker.css"
      ).catch((error) =>
        console.error("Failed to load date picker styles:", error)
      );
    }
  }, [loadStyles]);

  return (
    <Suspense fallback={<Input disabled={true} />}>
      <LazyDatePicker {...props} />
    </Suspense>
  );
};

export default DatePickerWithSuspense;
