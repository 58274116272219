import { AspectRatio, Flex, Icon, Image, Link, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { FaFilePdf } from "react-icons/fa";
import { LuDownload, LuFile } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import { SUPPORTED_MEDIA_TYPES } from "../FilePreview";

interface MediaTypeProps {
  type: string;
  url: string;
  id: string;
}

const MediaType = ({ type, url, id }: MediaTypeProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      {type.includes("image") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Image
          src={url}
          alt={`${type}-${id}`}
          boxSize="2xl"
          objectFit="cover"
          borderRadius="lg"
        />
      )}
      {type.includes("video") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <AspectRatio boxSize="2xl" borderRadius="lg">
          <video
            controls
            style={{
              borderRadius: "0.5rem",
            }}
          >
            <source src={url} type={type} />
          </video>
        </AspectRatio>
      )}
      {type.includes("audio") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <AspectRatio
          borderRadius="lg"
          maxWidth="15rem"
          width="15rem"
          height="15rem"
          maxHeight="15rem"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.50"
        >
          <audio
            controls
            style={{
              borderRadius: "0.5rem",
            }}
          >
            <source src={url} type={type} />
          </audio>
        </AspectRatio>
      )}
      {type.includes("pdf") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Flex
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.50"
          borderRadius="2xl"
          direction="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <Icon
            as={FaFilePdf}
            width="15rem"
            height="15rem"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
          <Link
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            download={true}
            display="inline-flex"
            gridGap={2}
            alignItems="center"
          >
            <Text>Download</Text>
            <Icon
              as={LuDownload}
              color={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          </Link>
        </Flex>
      )}
      {!SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Flex
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.50"
          borderRadius="2xl"
          direction="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <Icon
            as={LuFile}
            width="15rem"
            height="15rem"
            borderRadius="lg"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
          <Link
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            download={true}
            display="inline-flex"
            gridGap={2}
            alignItems="center"
          >
            <Text>Download</Text>
            <Icon
              as={LuDownload}
              color={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          </Link>
        </Flex>
      )}
    </>
  );
};

export default MediaType;
