import { Button, CloseButton, Flex } from "@chakra-ui/react";
import React from "react";

import FileListToSubmit from "./FileListToSubmit";

interface SubmitFileFormProps {
  size: "sm" | "md" | "lg";
  files: File[];
  submitButtonText?: string;
  isInModal?: boolean;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  onSubmit: (files: File[]) => Promise<void>;
}

const SubmitFileForm = ({
  size,
  files,
  isInModal = false,
  submitButtonText,
  setFiles,
  onSubmit,
}: SubmitFileFormProps) => {
  return files.length === 0 ? null : (
    <Flex
      {...(size === "sm"
        ? {
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }
        : {
            direction: "column-reverse",
            alignItems: "center",
            justifyContent: "space-between",
          })}
      mb={2}
    >
      <Button
        id="submit-files"
        alignSelf={isInModal ? "end" : "center"}
        size={size}
        onClick={() => onSubmit(files)}
        mt={4}
      >
        {submitButtonText || "Submit"}
      </Button>
      <FileListToSubmit size={size} files={files} setFiles={setFiles} />
      {!isInModal ? (
        <CloseButton
          id="clear-files"
          size={size}
          onClick={() => setFiles([])}
          m={2}
          position="absolute"
          right={0}
          top={0}
        />
      ) : null}
    </Flex>
  );
};

export default SubmitFileForm;
