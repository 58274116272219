import { Button, Text, useBreakpointValue } from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import React from "react";

interface NotifyGuestModalProps {
  showNotifyModal: boolean;
  closeNotifyModal: () => void;
  onNotifyCustomer: () => void;
  onDontNotifyCustomer: () => void;
}

const NotifyGuestModal = ({
  showNotifyModal,
  closeNotifyModal,
  onNotifyCustomer,
  onDontNotifyCustomer,
}: NotifyGuestModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <>
      <DialogRoot
        size={isBaseSize ? "full" : "sm"}
        open={showNotifyModal}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            closeNotifyModal();
          }
        }}
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>Notify guests?</DialogHeader>
          <DialogBody pb={6} mt={8}>
            <Text>Send a notification to existing guests.</Text>
          </DialogBody>
          <DialogFooter mx="auto">
            <Button variant="ghost" onClick={onDontNotifyCustomer}>
              Dont Send
            </Button>
            <Button onClick={onNotifyCustomer} mr={15}>
              Send
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default NotifyGuestModal;
