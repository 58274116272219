import { Flex, Icon, Image, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { IconType } from "react-icons";
import { LuArrowRight } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface BankItemProps {
  icon: string | IconType;
  imgAlt: string;
  bankName: string;
  onClick: () => void;
}

const BankListItem = ({ icon, imgAlt, bankName, onClick }: BankItemProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent="space-between"
      borderRadius="md"
      w="100%"
      cursor="pointer"
      alignItems="center"
      px={2}
      py={4}
      _hover={{
        bg: colorMode === "light" ? "gray.50" : "gray.700",
      }}
      onClick={onClick}
    >
      <Flex alignItems="center" gridGap={4}>
        {typeof icon === "string" ? (
          <Image alt={imgAlt} src={icon} width={6} />
        ) : (
          <Icon as={icon} width={6} height={6} />
        )}
        <Text>{bankName}</Text>
      </Flex>
      <Icon
        as={LuArrowRight}
        color={
          colorMode === "light" ? `${colorScheme}.400` : `${colorScheme}.200`
        }
      />
    </Flex>
  );
};

export default BankListItem;
