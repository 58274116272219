import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  colorScheme: string;
  colorMode: "light" | "dark";
}

const initialState: ThemeState = {
  colorScheme: "blue",
  colorMode: "light",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setColorScheme(state, action: PayloadAction<string>) {
      state.colorScheme = action.payload;
    },
    setColorMode(state, action: PayloadAction<"dark" | "light">) {
      state.colorMode = action.payload;
    },
  },
});

export const { setColorScheme, setColorMode } = themeSlice.actions;

export default themeSlice.reducer;
