import { defineSlotRecipe } from "@chakra-ui/react";
import { menuAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: menuAnatomy.keys(),
  base: {
    content: {
      borderRadius: "xl",
    },
  },
});
