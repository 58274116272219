import { Box, Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as FuzeyTarget } from "assets/icons/fuzey-target.svg";
import { ReactComponent as SwitchAccount } from "assets/icons/sidebar-svgrepo-com.svg";
import { useColorMode } from "components/ui/color-mode";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "components/ui/hover-card";

import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import React from "react";
import { setIsSelectorOpened } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  canManageCampaigns,
  canViewAgentsAnalytics,
  canViewPayments,
} from "util/permissions";
import {
  AnalyticsLink,
  CalendarLink,
  CampaignsLink,
  ContactLink,
  InboxLink,
  PaymentsLink,
  ReviewsLink,
  SettingsLink,
} from "./DesktopNavButton";
import ProfileSettings from "./ProfileSettings";

const otherMerchantsHaveUnreadMessages = (
  unreadCounts: UnreadCountDTO[],
  currentMerchantId: number
) => {
  return unreadCounts
    .filter((uc) => uc.merchant_id !== currentMerchantId)
    .some((uc) => {
      return uc.shared > 0;
    });
};

const DekstopBrowserNavigationBar = () => {
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant, isSelectorOpened } = useAppSelector(
    (state) => state.merchant
  );
  const { currentAgent, unreadCounts } = useAppSelector(
    (state) => state.agents
  );
  const { colorMode } = useColorMode();

  const hasUnreadInOtherMerchants = otherMerchantsHaveUnreadMessages(
    unreadCounts,
    merchant.id
  );

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      h="100%"
      minHeight="100%"
      width="5rem"
      flexShrink={0}
      overflowX="hidden"
      pb="1rem"
      pt={currentAgent && currentAgent.merchants.length > 1 ? 0 : "1rem"}
      bgColor={`${colorScheme}.900`}
      overflowY="auto"
    >
      <Flex
        direction="column"
        justifyContent="start"
        w="100%"
        alignItems="center"
        gridGap={2}
      >
        {currentAgent && currentAgent.merchants.length > 1 ? (
          <HoverCardRoot positioning={{ placement: "right" }}>
            <HoverCardTrigger height="100%" width="100%">
              <Flex
                w="100%"
                h="100%"
                display="flex" // not sure why I need this
                bgColor={`${colorScheme}.700`}
                cursor="pointer"
                justifyContent="center"
                alignItems="center"
                position="relative"
                py={4}
                onClick={() => {
                  dispatch(setIsSelectorOpened(!isSelectorOpened));
                }}
                css={{
                  _after: {
                    content: "''",
                    position: "absolute",
                    left: "-0.45rem",
                    zIndex: 4,
                    bottom: "-1.1rem",
                    width: "2.25rem",
                    height: "1.5rem",
                    background: `radial-gradient(1.5rem at top right, #0000 99%, var(--chakra-colors-${colorScheme}-700) 102%)`,
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <Icon
                  as={SwitchAccount}
                  width="2rem"
                  height="2rem"
                  position="relative"
                  css={{
                    "& path": {
                      stroke: "white",
                    },
                  }}
                />
                {hasUnreadInOtherMerchants ? (
                  <Box
                    position="absolute"
                    top={4}
                    right={5}
                    zIndex="1"
                    borderRadius="full"
                    height="0.75rem"
                    width="0.75rem"
                    bgColor={colorMode === "dark" ? "red.200" : "red.500"}
                  />
                ) : null}
              </Flex>
            </HoverCardTrigger>
            <HoverCardContent>
              <HoverCardArrow />
              Accounts
            </HoverCardContent>
          </HoverCardRoot>
        ) : (
          <Icon
            as={FuzeyTarget}
            width="3rem"
            height="3rem"
            mb={4}
            css={{
              "& path": {
                fill: "white",
              },
            }}
          />
        )}
        <InboxLink />
        <ContactLink />
        {!canManageCampaigns(merchant.id, currentAgent!) ? null : (
          <CampaignsLink />
        )}
        <CalendarLink />
        {!canViewPayments(merchant.id, currentAgent!) ? null : <PaymentsLink />}
        <ReviewsLink />
        {canViewAgentsAnalytics(merchant.id, currentAgent!) ? (
          <AnalyticsLink />
        ) : null}
        <SettingsLink />
      </Flex>
      <Flex m={4} pb={6}>
        <ProfileSettings />
      </Flex>
    </Flex>
  );
};

export default DekstopBrowserNavigationBar;
