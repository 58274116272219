import { defineSlotRecipe } from "@chakra-ui/react";
import { popoverAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: popoverAnatomy.keys(),
  base: {
    content: {
      borderRadius: "xl",
      overflow: "hidden",
    },
  },
});
