import { Box, Button, Text } from "@chakra-ui/react";
import { PaymentStatus } from "entities/domain/payments/payment-domain";
import usePaymentsStore from "hooks/use-payments-store";
import React, { useState } from "react";
import { selectPayment } from "redux/features/payments";
import { useAppSelector } from "redux/hooks";
import CancelPaymentModal from "./cancelPayment";
import EditPaymentModal from "./editPayment";

const MoreActions = () => {
  const { editPayment } = usePaymentsStore();
  const { payments, selectedPaymentId } = useAppSelector(
    (state) => state.payments
  );
  const selectedPayment = selectPayment(selectedPaymentId, payments);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [cancelPaymentOpen, setCancelPaymentOpen] = useState<boolean>(false);
  const [editPaymentOpen, setEditPaymentOpen] = useState<boolean>(false);

  const cancelPayment = () => {
    editPayment({
      id: selectedPayment?.id,
      status: PaymentStatus.CANCELED,
      payment_method: undefined,
    });
    setCancelPaymentOpen(false);
  };

  return (
    <>
      <Box>
        {selectedPayment?.canBeMarkedAsPaid() && (
          <Button
            colorPalette={colorScheme}
            variant="ghost"
            onClick={() => setEditPaymentOpen(true)}
            w="100%"
            _hover={{ backgroundColor: "inherit", opacity: 0.5 }}
          >
            <Text fontWeight="semibold">Mark as paid</Text>
          </Button>
        )}

        {selectedPayment?.canBeCancelled() && (
          <Button
            colorPalette={colorScheme}
            variant="ghost"
            onClick={() => setCancelPaymentOpen(true)}
            w="100%"
            _hover={{ backgroundColor: "inherit", opacity: 0.5 }}
          >
            <Text fontWeight="semibold">Cancel payment request</Text>
          </Button>
        )}
        {selectedPayment?.canDownloadAnInvoice() && (
          <Button
            colorPalette={colorScheme}
            variant="ghost"
            w="100%"
            _hover={{ backgroundColor: "inherit", opacity: 0.5 }}
          >
            <a href={selectedPayment?.invoiceUrl} download>
              <Text fontWeight="semibold">Download Invoice</Text>
            </a>
          </Button>
        )}

        <CancelPaymentModal
          isCancelPaymentsOpen={cancelPaymentOpen}
          onCancelPayment={cancelPayment}
          onClose={() => setCancelPaymentOpen(false)}
        />
        <EditPaymentModal
          isEditPaymentsOpen={editPaymentOpen}
          onClose={() => setEditPaymentOpen(false)}
        />
      </Box>
    </>
  );
};

export default MoreActions;
