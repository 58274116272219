import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Separator,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import QueryBuilder from "components/shared/QueryBuilder";
import { GroupConfig } from "components/shared/QueryBuilder/QueryCondition";
import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import AudienceDomain, { AudienceCriteria } from "entities/domain/audience";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { stringifyCriteria } from "entities/domain/criteria";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import React, { useEffect, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import { createAudienceSuccess } from "redux/features/audiences";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AudiencesService from "services/audiences";
import AudienceCustomersList from "../audience-customers-list/AudienceCustomersList";

interface QueryConfirmationModalProps {
  isOpen: boolean;
  groups: GroupConfig[];
  criteria: AudienceCriteria | null;
  audienceName: string | null;
  campaignChannel: ConversationChannel;
  onClose: () => void;
  onConfirm: (a: AudienceDomain) => void;
}

const QueryConfirmationModal = ({
  isOpen,
  criteria,
  audienceName,
  groups,
  campaignChannel,
  onClose,
  onConfirm,
}: QueryConfirmationModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chosenAudience, setChosenAudience] = useState<AudienceDomain | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [audienceCustomersList, setAudienceCustomersList] =
    useState<ContactListDomain>(new ContactListDomain([], 0));

  const tryCreatingAudience = async () => {
    if (!criteria || !audienceName) {
      return;
    }

    setIsLoading(true);

    try {
      const savedAudience = await AudiencesService.createAudience(
        auth0Context,
        {
          name: audienceName,
          merchantId: merchant.id,
          criteria: stringifyCriteria(criteria),
        }
      );

      if (savedAudience) {
        setChosenAudience(savedAudience);
        dispatch(createAudienceSuccess(savedAudience));

        const fetchedAudienceCustomersList =
          await AudiencesService.fetchAudienceCustomers(
            auth0Context,
            merchant.id,
            {
              id: savedAudience.id!,
              channels: [campaignChannel],
            }
          );

        setAudienceCustomersList(fetchedAudienceCustomersList);
      } else {
        throw new Error(
          "Failed to create audience. Check if the criteria is valid."
        );
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else if (err instanceof AxiosError) {
        setErrorMessage(
          err.response?.data.message || "Failed to create audience."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setChosenAudience(null);
      setErrorMessage(null);
      setAudienceCustomersList(new ContactListDomain([], 0));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    tryCreatingAudience();
  }, [criteria, isOpen]);

  const removeCreatedAudience = async () => {
    if (!chosenAudience) {
      return;
    }

    try {
      await AudiencesService.deleteAudience(auth0Context, merchant.id, {
        id: chosenAudience.id!,
      });
    } catch (err: unknown) {
      // eslint-disable-next-line
      console.error("Failed to delete created audience", err);
    }
  };

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          removeCreatedAudience();
          onClose();
        }
      }}
      size={isBaseSize ? "full" : "xl"}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>Are you satisfied with this audience?</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          {isLoading ? (
            <VStack>
              <Text>Generating Audience according to criteria...</Text>
              <Spinner
                size="lg"
                color={
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`
                }
              />
            </VStack>
          ) : null}

          {!isLoading && chosenAudience && !errorMessage ? (
            <VStack>
              <QueryBuilder
                entityNamePlural="customers"
                groups={groups}
                criteria={criteria}
                setCriteria={(newCriteria) => {}}
                isDisabled={true}
              />
              <Separator mb={4} />
              <Flex direction="column" w="100%" mx="auto">
                <HStack justifyContent="start" alignItems="center">
                  <Text fontSize="xl" fontWeight={700} my={3}>
                    Recipients
                  </Text>
                  <Badge
                    colorPalette={
                      audienceCustomersList.totalCount > 0 ? "green" : "gray"
                    }
                  >
                    {audienceCustomersList.totalCount} people
                  </Badge>
                  {isBaseSize ? null : (
                    <Link
                      color={`${colorScheme}.400`}
                      href={`/${merchant.id}/contacts?audience_id=${chosenAudience.id}`}
                      target="_blank"
                      display="inline-flex"
                      alignItems="center"
                      gridGap={1}
                    >
                      <Text>Full View</Text>
                      <Icon>
                        <LuExternalLink />
                      </Icon>
                    </Link>
                  )}
                </HStack>
                <AudienceCustomersList
                  customers={audienceCustomersList.contacts}
                />
              </Flex>
            </VStack>
          ) : null}

          {errorMessage ? (
            <Alert
              status="error"
              variant="subtle"
              title="Error occured when creating Audience"
            >
              {errorMessage}
            </Alert>
          ) : null}
        </DialogBody>

        <DialogFooter display="flex" gridGap={2}>
          <Button
            variant="ghost"
            colorPalette={colorScheme}
            onClick={() => {
              removeCreatedAudience();
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            id="query-confirmation-modal-confirm-button"
            disabled={!chosenAudience}
            colorPalette={colorScheme}
            onClick={() => {
              if (!chosenAudience) {
                return;
              }

              onConfirm(chosenAudience);
              onClose();
            }}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default QueryConfirmationModal;
