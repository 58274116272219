import { useAuth0 } from "@auth0/auth0-react";
import { VStack } from "@chakra-ui/react";
import { Checkbox } from "components/ui/checkbox";
import { toaster } from "components/ui/toaster";
import FileDomain from "entities/domain/file";
import React, { useCallback, useMemo, useState } from "react";
import { addFile } from "redux/features/files";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FilesService from "services/files";
import SmartFileUploader from "../SmartFileUploader";
import RecentFiles from "./RecentFiles";

interface QuickSelectProps {
  fileTypeAccept?: string;
  selectedFile: FileDomain | null;
  enableUrlShortening: boolean;
  openSearch: () => void;
  selectFile: (file: FileDomain | null) => void;
}

const QuickSelect: React.FC<QuickSelectProps> = ({
  fileTypeAccept,
  enableUrlShortening,
  selectedFile,
  openSearch,
  selectFile,
}) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [saveForFutureUse, setSaveForFutureUse] = useState<boolean>(false);
  const [fileAboutToBeUploaded, setFileAboutToBeUploaded] =
    useState<File | null>(null);
  const [fileNotForFutureUse, setFileNotForFutureUse] =
    useState<FileDomain | null>(null);

  const handleSaveForFutureUse = useCallback(
    (e: { checked: boolean; }) => {
      setSaveForFutureUse(!!e.checked);
    },
    []
  );



  const submitFiles = useCallback(
    async (filesToSubmit: File[]) => {
      setIsUploading(true);

      try {
        const uploadedFile = await FilesService.uploadFile(
          auth0Context,
          {
            file: filesToSubmit[0],
            isReusable: saveForFutureUse,
            needsShortUrl: enableUrlShortening,
          },
          merchant.id
        );

        if (saveForFutureUse) {
          dispatch(addFile(uploadedFile));
        } else {
          setFileNotForFutureUse(uploadedFile);
        }
        selectFile(uploadedFile);
      } catch (error: unknown) {
        // eslint-disable-next-line
        console.error("Error uploading files", error);
        toaster.create({
          type: "error",
          title: "Error occurred during file upload!",
        });
      } finally {
        setIsUploading(false);
        setSaveForFutureUse(false);
        setFileAboutToBeUploaded(null);
      }
    },
    [
      selectFile,
      saveForFutureUse,
      setSaveForFutureUse,
      setFileNotForFutureUse,
      enableUrlShortening,
    ]
  );

  const resetSelectedFile = useCallback(
    (newFiles: File[]) => {
      if (saveForFutureUse || !!fileNotForFutureUse) {
        return;
      }

      selectFile(null);
      setFileAboutToBeUploaded(newFiles[0] || null);
    },
    [selectFile, saveForFutureUse, fileNotForFutureUse]
  );

  const resetUploadedFileNotForFutureUse = useCallback(() => {
    setFileNotForFutureUse(null);
  }, [setFileNotForFutureUse]);

  const selectDragAndDropArea = useCallback(
    () => selectFile(fileNotForFutureUse),
    [fileNotForFutureUse]
  );
  const uploadedFiles = useMemo(
    () => (fileNotForFutureUse ? [fileNotForFutureUse] : []),
    [fileNotForFutureUse]
  );
  const isDragAndDropAreaSelected =
    !!fileNotForFutureUse &&
    !!selectedFile &&
    selectedFile.id === fileNotForFutureUse.id;

  return (
    <VStack gap={4}>
      <RecentFiles
        selectedFile={
          selectedFile &&
          !(fileNotForFutureUse && fileNotForFutureUse.id === selectedFile.id)
            ? selectedFile
            : null
        }
        selectFile={selectFile}
        openSearch={openSearch}
      />
      <SmartFileUploader
        accept={fileTypeAccept}
        size="md"
        submitButtonText="Upload"
        allowDragAndDrop={true}
        uploadedFiles={uploadedFiles}
        isDragAndDropAreaSelected={isDragAndDropAreaSelected}
        dragAndDropAreaSelect={selectDragAndDropArea}
        allowMultiple={false}
        onSubmit={submitFiles}
        onFileChange={resetSelectedFile}
        onClose={resetUploadedFileNotForFutureUse}
        isDisabled={isUploading}
      />
      {!fileAboutToBeUploaded ? null : (
        <Checkbox
          checked={saveForFutureUse}
          onCheckedChange={handleSaveForFutureUse}
          disabled={!fileAboutToBeUploaded}
        >
          Save for future use
        </Checkbox>
      )}
    </VStack>
  );
};

export default QuickSelect;
