import { Flex, Text } from "@chakra-ui/react";
import { Avatar } from "components/ui/avatar";
import React, { useState } from "react";
import { OptionProps, components } from "react-select";

export interface ChannelOptionTypes {
  value: string;
  label: string;
  icon?: string;
  color?: string;
}

const ChannelColumn = ({ item }: { item: ChannelOptionTypes }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Avatar
        size="xs"
        opacity={isLoaded ? 1 : 0}
        src={item.icon}
        name={item.label}
        background="transparent"
        loading="eager"
        css={{
          "& img": {
            objectFit: "contain",
            borderRadius: 0,
          },
        }}
        onLoad={() => setIsLoaded(true)}
      />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const ChannelOption = function (props: OptionProps<ChannelOptionTypes>) {
  return (
    <components.Option {...props}>
      <ChannelColumn item={props.data} />
    </components.Option>
  );
};

export default ChannelOption;
