import { Box, Flex, Icon, Separator, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { NumberInputField, NumberInputRoot } from "components/ui/number-input";
import AutomationDomain, {
  TimeOperation,
  TimeUnit,
} from "entities/domain/automation";
import React, { ChangeEvent, useEffect, useState } from "react";
import { LuAlarmClock, LuCheck, LuTrash2 } from "react-icons/lu";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

interface SelectOption {
  value: string;
  label: string;
}

interface WaitUntilProps {
  automation: AutomationDomain;
  setUpdatedAutomation: (automation: AutomationDomain) => void;
}

const WaitUntil = ({ automation, setUpdatedAutomation }: WaitUntilProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [timeOperation, setTimeOperation] = useState<TimeOperation | undefined>(
    automation?.time?.operation
  );
  const [timeAmount, setTimeAmount] = useState<number | undefined>(
    automation?.time?.amount
  );
  const [timeUnit, setTimeUnit] = useState<TimeUnit | undefined>(
    automation?.time?.unit
  );

  useEffect(() => {
    const time =
      !timeOperation && !timeAmount && !timeUnit
        ? null
        : {
            operation: timeOperation,
            amount: timeAmount,
            unit: timeUnit,
          };
    if (timeOperation && typeof timeAmount !== "undefined" && timeUnit) {
      setUpdatedAutomation(
        new AutomationDomain(
          automation.id,
          automation.name,
          automation.description,
          automation.merchantId,
          automation.triggerType,
          !timeOperation && !timeAmount && !timeUnit
            ? null
            : {
                ...automation.time,
                operation: timeOperation,
                amount: timeAmount,
                unit: timeUnit,
              },
          automation.frequency,
          automation.task,
          automation.templateId,
          automation.enabled
        )
      );
    }
  }, [timeOperation, timeAmount, timeUnit]);

  let text = "Instantly";

  if (timeOperation && timeAmount && timeUnit) {
    const time = `${timeAmount} ${timeAmount > 1 ? `${timeUnit}s` : timeUnit}`;

    text = `${time} ${timeOperation}`;
  }

  const [isEditingExecutionTime, setIsEditingExecutionTime] =
    useState<boolean>(false);

  const timePeriodOptions: SelectOption[] = [
    {
      label: "Before",
      value: "before",
    },
    {
      label: "After",
      value: "after",
    },
  ].filter((periodOption) =>
    automation
      .getAllowedTimeOperations()
      .includes(periodOption.value as TimeOperation)
  );

  const timeUnitOptions: SelectOption[] = [
    {
      label: "Second",
      value: "second",
    },
    {
      label: "Minute",
      value: "minute",
    },
    {
      label: "Hour",
      value: "hour",
    },
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
  ].filter((unitOption) =>
    automation.getAllowedTimeUnits().includes(unitOption.value as TimeUnit)
  );

  const [selectedTimePeriodValue, setSelectedTimePeriodValue] = useState<
    SingleValue<SelectOption>
  >(
    !timeOperation
      ? null
      : timePeriodOptions.find(({ value }) => value === timeOperation) || null
  );

  const [selectedTimeUnitValue, setSelectedTimeUnitValue] = useState<
    SingleValue<SelectOption>
  >(
    !timeUnit
      ? null
      : timeUnitOptions.find(({ value }) => value === timeUnit) || null
  );

  const handleTimePeriodChange = (e: SingleValue<SelectOption>) => {
    setTimeOperation(e?.value as TimeOperation);
    setSelectedTimePeriodValue(e);
  };

  const handleTimeAmountChange = ({
    valueAsNumber,
  }: {
    valueAsNumber: number;
  }) => {
    setTimeAmount(valueAsNumber);
  };

  const handleTimeUnitChange = (e: SingleValue<SelectOption>) => {
    setTimeUnit(e?.value as TimeUnit);
    setSelectedTimeUnitValue(e);
  };

  useEffect(() => {
    if (timeOperation && timeAmount && timeUnit) {
      setUpdatedAutomation(
        new AutomationDomain(
          automation.id,
          automation.name,
          automation.description,
          automation.merchantId,
          automation.triggerType,
          {
            ...automation.time,
            operation: timeOperation,
            amount: timeAmount,
            unit: timeUnit,
          },
          automation.frequency,
          automation.task,
          automation.templateId,
          automation.enabled
        )
      );
    }
  }, [timeOperation, timeAmount, timeUnit]);

  return (
    <>
      <Box px={6} my={5}>
        <Text fontWeight={700} pb="1rem">
          Send when:
        </Text>
        <Flex alignItems="center">
          <Icon
            as={LuAlarmClock}
            mr="1rem"
            color="currentColor"
            css={{
              height: "30px",
              width: "30px",
            }}
          />
          {!isEditingExecutionTime ? (
            <Flex
              borderRadius="full"
              border="1px solid gray"
              height="2.5rem"
              px="1rem"
              onClick={() => setIsEditingExecutionTime(true)}
              _hover={{
                cursor: "pointer",
                boxShadow: "0 0 1rem gray",
                border: "none",
                color: "white",
                backgroundColor: `${colorScheme}.600`,
              }}
              alignItems="center"
            >
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                textAlign="center"
              >
                {text}
              </Text>
            </Flex>
          ) : (
            <Flex
              wrap="nowrap"
              justifyContent="space-evenly"
              alignItems="center"
              width="100%"
            >
              <Flex direction="column" gridGap={3}>
                <NumberInputRoot
                  data-testid="input-time-amount"
                  step={1}
                  defaultValue={timeAmount?.toString() || "0"}
                  min={0}
                  max={30}
                  onValueChange={handleTimeAmountChange}
                >
                  <NumberInputField value={timeAmount} />
                </NumberInputRoot>
                <Select
                  id="select-time-unit"
                  placeholder="Select time unit"
                  onChange={handleTimeUnitChange}
                  options={timeUnitOptions}
                  value={selectedTimeUnitValue}
                  styles={getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  )}
                />
                <Select
                  id="select-time-period"
                  placeholder="Select time period"
                  onChange={handleTimePeriodChange}
                  options={timePeriodOptions}
                  value={selectedTimePeriodValue}
                  styles={getReactSelectStyles(
                    colorMode || "light",
                    colorScheme
                  )}
                />
              </Flex>
              <Icon
                as={LuTrash2}
                onClick={() => {
                  setTimeAmount(0);
                  setTimeUnit(undefined);
                  setTimeOperation(undefined);
                  setIsEditingExecutionTime(false);
                  setUpdatedAutomation(
                    new AutomationDomain(
                      automation.id,
                      automation.name,
                      automation.description,
                      automation.merchantId,
                      automation.triggerType,
                      null,
                      automation.frequency,
                      automation.task,
                      automation.templateId,
                      automation.enabled
                    )
                  );
                }}
                color="currentColor"
                css={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                }}
              />
              <Icon
                as={LuCheck}
                color={colorMode === "dark" ? "green.200" : "green.500"}
                onClick={() => setIsEditingExecutionTime(false)}
                css={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                }}
              />
            </Flex>
          )}
        </Flex>
      </Box>
      <Separator />
    </>
  );
};

export default WaitUntil;
