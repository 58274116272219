import { useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React, { ReactElement, useState } from "react";
import Select, {
  ActionMeta,
  GetOptionLabel,
  MultiValue,
  Props,
  PropsValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

type FuzeyDropdownProps<
  T extends { label: string; value: string } | null,
  U extends boolean
> = {
  width: string;
  setSelectedValues?: (t: string[]) => void;
  setSelectedValue?: (t: string | undefined) => void;
  getOptionLabels?: (t: SingleValue<T>) => any;
  control?: React.CSSProperties;
  menuList?: React.CSSProperties;
  menu?: React.CSSProperties;
  multiValueLabel?: React.CSSProperties;
  multiValueRemove?: React.CSSProperties;
  borderColor?: string;
  fontSize?: string;
  optionFontColor?: string;
  isSetOnSelect?: boolean;
  zIndex?: number;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  controlShouldRenderValue?: boolean;
  hideSelectedOptions?: boolean;
  options: T[];
  defaultValue?: PropsValue<T>;
  closeMenuOnSelect?: () => void;
} & Props;

function FuzeyDropdown<
  T extends { label: string; value: string } | null,
  U extends boolean
>({
  width,
  setSelectedValues,
  setSelectedValue,
  getOptionLabels,
  isSetOnSelect,
  control,
  multiValueLabel,
  multiValueRemove,
  menuList,
  menu,
  borderColor,
  fontSize,
  optionFontColor,
  zIndex,
  isSearchable = false,
  closeMenuOnSelect,
  isMulti,
  isClearable,
  defaultValue,
  hideSelectedOptions,
  options,
  controlShouldRenderValue,
  ...rest
}: FuzeyDropdownProps<T, U>): ReactElement {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [selectedOptions, setSelectedOptions] = useState<PropsValue<T>>(
    (defaultValue || []) as unknown as PropsValue<T>
  );

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const parseSelectedOptions = (
    newSelectedOptions: MultiValue<T>
  ): string[] => {
    return (
      newSelectedOptions?.map(
        (option: SingleValue<T>) => option?.value || ""
      ) || []
    );
  };

  const parseSelectedOption = (selectedOption: SingleValue<T>): string => {
    return selectedOption?.value || "";
  };

  const handleSelectedOptions = (newValue: PropsValue<T>) => {
    setSelectedOptions(newValue);

    if (isSetOnSelect) {
      if (isMulti === undefined || isMulti) {
        setSelectedValues!(parseSelectedOptions(newValue as MultiValue<T>));
      } else {
        setSelectedValue!(parseSelectedOption(newValue as SingleValue<T>));
      }
    }
  };

  const handleCloseMenu = () => {
    if (isSetOnSelect) {
      return;
    }

    if (isMulti === undefined || isMulti) {
      setSelectedValues!(
        parseSelectedOptions(selectedOptions as MultiValue<T>)
      );
    } else {
      setSelectedValue!(
        parseSelectedOption((selectedOptions as MultiValue<T>)[0])
      );
    }
  };

  const selectProps = {
    isMulti: isMulti === undefined ? (true as U) : (isMulti as U),
    value: selectedOptions,
    onChange: handleSelectedOptions as (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>
    ) => void,
    options,
    onMenuClose: handleCloseMenu,
    styles: getReactSelectStyles(colorMode as "dark" | "light" || "light", colorScheme, isBaseSize),
    getOptionLabel: (getOptionLabels as GetOptionLabel<unknown>) || undefined,
    isSearchable,
    isClearable: isClearable === undefined ? false : isClearable,
    closeMenuOnSelect:
      closeMenuOnSelect === undefined ? false : closeMenuOnSelect,
    controlShouldRenderValue:
      controlShouldRenderValue === undefined ? false : controlShouldRenderValue,
    hideSelectedOptions:
      hideSelectedOptions === undefined ? false : hideSelectedOptions,
    ...rest,
  };

  return <Select {...selectProps} />;
}

export default FuzeyDropdown;
