import { Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";

interface ConsentLineItemprops {
  title: string;
  text: string | undefined;
  flexDirection?: any;
}

const ConsentLineItem = ({
  title,
  text,
  flexDirection = "row",
}: ConsentLineItemprops) => {
  return (
    <>
      <Flex flexDirection={flexDirection}>
        <Text
          maxWidth="250px"
          mb="10px"
          css={{
            "@media screen and (max-width: 780px)": {
              maxWidth: "100%",
            },
          }}
          fontWeight={700}
          fontSize="sm"
        >
          {title}
        </Text>
        <Spacer />
        <Text
          maxWidth="250px"
          mb="10px"
          css={{
            "@media screen and (max-width: 780px)": {
              maxWidth: "100%",
            },
          }}
          fontSize="sm"
        >
          {text}
        </Text>
      </Flex>
    </>
  );
};

export default ConsentLineItem;
