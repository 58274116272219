import { Button, Flex, Icon } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { LuLock, LuLockOpen } from "react-icons/lu";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const OpenClosedPills = () => {
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const isOpenOrClosed = useAppSelector(
    (state) => state.conversations.isOpenOrClosed
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex
      gridGap={2}
      justifyContent="start"
      alignItems="center"
      p={4}
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
    >
      <Button
        colorPalette={colorScheme}
        variant={isOpenOrClosed === OpenClosedFilter.Open ? "subtle" : "ghost"}
        onClick={() => {
          dispatch(
            setActiveConversationOpenClosedFilter(OpenClosedFilter.Open)
          );
        }}
      >
        <Icon as={LuLockOpen} />
        Open
      </Button>
      <Button
        variant={
          isOpenOrClosed === OpenClosedFilter.Closed ? "subtle" : "ghost"
        }
        colorPalette={colorScheme}
        onClick={() => {
          dispatch(
            setActiveConversationOpenClosedFilter(OpenClosedFilter.Closed)
          );
        }}
      >
        <Icon as={LuLock} />
        Closed
      </Button>
    </Flex>
  );
};

export default OpenClosedPills;
