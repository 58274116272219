import { Auth0ContextInterface, User, useAuth0 } from "@auth0/auth0-react";
import { Icon, IconButton } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuSeparator,
  MenuTrigger,
} from "components/ui/menu";
import React, { memo, useEffect, useState } from "react";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import {
  LuCircleHelp,
  LuMenu,
  LuMoon,
  LuPaintbrush,
  LuSun,
} from "react-icons/lu";
import { setIsSelectorOpened } from "redux/features/merchant";
import { setColorScheme } from "redux/features/theme";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isLocalEnvironment, isMobileApp } from "util/methods";
import {
  canManageCampaigns,
  canViewAgentsAnalytics,
  canViewPayments,
} from "util/permissions";
import {
  AnalyticsLink,
  CalendarLink,
  CampaignsLink,
  ContactsLink,
  InboxLink,
  PaymentsLink,
  ReviewsLink,
  SettingsLink,
} from "./MobileNavButton";

const MobileBrowserNavigation = () => {
  const { isAuthenticated, logout } = useAuth0() as Auth0ContextInterface<User>;
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useAppDispatch();

  const [selectedLinkColor, setSelectedLinkColor] = useState<string>("inherit");

  useEffect(() => {
    if (colorMode === "light") {
      setSelectedLinkColor(`${colorScheme}.500`);
    } else {
      setSelectedLinkColor(`${colorScheme}.200`);
    }
  }, [colorScheme, colorMode]);

  const toggleColorScheme = (currentColorScheme: string) => {
    const colorSchemes = [
      "blue",
      "yellow",
      "green",
      "purple",
      "pink",
      "cyan",
      "teal",
    ];
    const currentColor = colorSchemes.indexOf(currentColorScheme);

    dispatch(setColorScheme(colorSchemes[currentColor + 1] || colorSchemes[0]));
  };

  const hasMultipleMerchants = currentAgent!.merchants.length > 1;

  return (
    <>
      <MenuRoot>
        <MenuTrigger asChild={true}>
          <IconButton aria-label="Navigate" variant="outline">
            <LuMenu />
          </IconButton>
        </MenuTrigger>
        <MenuContent
          opacity={1}
          zIndex={6}
          css={{
            "& .selected > button": {
              bgColor: selectedLinkColor,
              color: "white",
            },
          }}
        >
          <InboxLink />
          <ContactsLink />
          <CalendarLink />
          {!canManageCampaigns(merchant.id, currentAgent!) ? null : (
            <CampaignsLink />
          )}
          {!canViewPayments(merchant.id, currentAgent!) ? null : (
            <PaymentsLink />
          )}
          <ReviewsLink />
          {canViewAgentsAnalytics(merchant.id, currentAgent!) ? (
            <AnalyticsLink />
          ) : null}
          <SettingsLink />
          <MenuSeparator />
          <MenuItem
            onClick={() => {
              const faqUrl = "https://www.getfuzey.com/resources/faq";

              if (isMobileApp()) {
                window.natively.openExternalURL(faqUrl);
              } else {
                window.open(faqUrl, "_blank");
              }
            }}
            value="FAQ"
          >
            <Icon
              width="2rem"
              height="2rem"
              as={LuCircleHelp}
              color={colorMode === "dark" ? "white" : "gray.300"}
            />{" "}
            FAQ
          </MenuItem>
          {!isLocalEnvironment() ? null : (
            <>
              <MenuItem onClick={toggleColorMode} value="Toggle Color Mode">
                <Icon
                  as={colorMode === "dark" ? LuMoon : LuSun}
                  width="2rem"
                  height="2rem"
                  color={colorMode === "dark" ? "white" : "gray.300"}
                />{" "}
                Toggle Color Mode
              </MenuItem>
              <MenuItem
                value="Toggle Color Scheme"
                onClick={() => toggleColorScheme(colorScheme)}
              >
                <Icon
                  as={LuPaintbrush}
                  width="2rem"
                  height="2rem"
                  color={colorMode === "dark" ? "white" : "gray.300"}
                />{" "}
                Toggle Color Scheme
              </MenuItem>
            </>
          )}
          {hasMultipleMerchants ? (
            <MenuItem
              value="Switch Account"
              onClick={() => {
                dispatch(setIsSelectorOpened(true));
              }}
            >
              <Icon
                width="1.5rem"
                height="1.5rem"
                as={AiOutlineUserSwitch}
                color={colorMode === "dark" ? "white" : "gray.300"}
              />{" "}
              Switch Account
            </MenuItem>
          ) : null}
          {isAuthenticated ? (
            <MenuItem
              value="Log out"
              onClick={() => {
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }}
            >
              <Icon
                as={FiLogOut}
                width="2rem"
                height="2rem"
                color={colorMode === "dark" ? "white" : "gray.300"}
                css={{
                  "& path": {
                    fill: "transparent",
                  },
                }}
              />{" "}
              Log out
            </MenuItem>
          ) : null}
        </MenuContent>
      </MenuRoot>
    </>
  );
};

export default memo(MobileBrowserNavigation);
