import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import { useColorMode } from "components/ui/color-mode";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import useDebounce from "hooks/use-debounce";
import Select, { MultiValue, SingleValue } from "react-select";
import { setCampaigns } from "redux/features/campaigns";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CampaignsService from "services/campaigns";
import { getSelectStylesForQueryBuilder } from "util/methods";

interface AvailableOption {
  value: string;
  label: string;
}

const campaignToOption = (c: CampaignDomain): AvailableOption => ({
  value: c.id!,
  label: c.name,
});

const CampaignFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { campaigns } = useAppSelector((state) => state.campaigns);
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 100);

  useEffect(() => {
    CampaignsService.getCampaigns(
      auth0Context,
      "desc",
      searchText,
      [CampaignStatus.DONE],
      0,
      merchant.id
    ).then((response) => {
      dispatch(setCampaigns(response));
    });
  }, [debouncedSearchText]);

  const [availableOptions, setAvailableOptions] = useState<
    MultiValue<AvailableOption>
  >(campaigns.map(campaignToOption));

  const getSelectedCampaign = (campaignId: string): AvailableOption | null => {
    if (!campaignId) {
      return null;
    }

    const selectedCampaign = campaigns.find(
      (campaign) => campaign.id === campaignId
    );

    if (!selectedCampaign) {
      return null;
    }

    return campaignToOption(selectedCampaign);
  };

  const [selectedCampaingOptionsValue, setSelectedCampaignOptionsValue] =
    useState<SingleValue<AvailableOption>>(getSelectedCampaign(value));

  useEffect(() => {
    if (value === selectedCampaingOptionsValue?.value) {
      return;
    }

    setSelectedCampaignOptionsValue(getSelectedCampaign(value));
  }, [value]);

  useEffect(() => {
    if (!campaigns.length) {
      return;
    }

    setAvailableOptions(campaigns.map(campaignToOption));
  }, [campaigns]);

  return (
    <Select
      id={id}
      isMulti={false}
      placeholder="select campaign"
      isClearable={false}
      isSearchable={true}
      onInputChange={(newValue) => {
        setSearchText(newValue);
      }}
      value={selectedCampaingOptionsValue}
      onChange={(newValue) => {
        if (newValue && !("value" in newValue)) {
          return;
        }

        onChange(newValue?.value || "");
      }}
      options={availableOptions}
      styles={getSelectStylesForQueryBuilder(colorScheme, colorMode as "light" | "dark" || "light")}
    />
  );
};

export default CampaignFieldInput;
