import { Button, HStack, Icon, Input, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { InputGroup } from "components/ui/input-group";
import React, { memo, useState } from "react";
import { LuBot } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface MessageInputCoWriterProps {
  askAiForHelp: (prompt: string) => void;
  isDisabled: boolean;
  motive: "template" | "campaign";
}

const HELPER_TEXT = {
  template: "Let Fuzey help you write the perfect template message",
  campaign: "Let Fuzey help you write the perfect marketing message",
};

const PLACEHOLDER_TEXT = {
  template:
    "Help me write a template to promote our VIP event this weekend where we launch a new car",
  campaign:
    "Help me write a SMS campaign to promote our VIP event this weekend where we launch a new car",
};

const MessageInputCoWriter = ({
  askAiForHelp,
  isDisabled,
  motive,
}: MessageInputCoWriterProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [aiPrompt, setAiPrompt] = useState<string>("");

  return (
    <>
      <Field
        label="AI Co-writer"
        helperText={
          <HStack>
            <Icon
              as={LuBot}
              size="xs"
              color={colorMode === "dark" ? "gray.50" : "gray.900"}
            />
            <Text>{HELPER_TEXT[motive]}</Text>
          </HStack>
        }
      >
        <InputGroup
          w="100%"
          endElement={
            <Button
              colorPalette={colorScheme}
              size="xs"
              onClick={() => {
                askAiForHelp(aiPrompt);
                setAiPrompt("");
              }}
              disabled={isDisabled || !aiPrompt}
            >
              Ask
            </Button>
          }
        >
          <Input
            colorPalette={colorScheme}
            placeholder={PLACEHOLDER_TEXT[motive]}
            value={aiPrompt}
            onChange={(e) => {
              setAiPrompt(e.target.value);
            }}
          />
        </InputGroup>
      </Field>
    </>
  );
};

export default memo(MessageInputCoWriter);
