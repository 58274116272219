import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Icon,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as XeroIcon } from "assets/icons/xero.svg";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { toaster } from "components/ui/toaster";
import { getXeroOauth2Endpoint } from "components/user-settings/constants";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { updateMerchantSuccess } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import IntegrationService from "services/integrations";

interface XeroIntegrationItemProps {}

const XeroIntegrationItem = (_props: XeroIntegrationItemProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { open, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.XERO);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onDisconnectXero = async () => {
    try {
      await IntegrationService.removeXeroIntegration(auth0Context, merchant.id);

      merchant.integrations[IntegrationName.XERO].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not disconnect Xero at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      isConnected={isConnected}
      icon={<Icon as={XeroIcon} height="3rem" width="8rem" />}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectXero} />
        ) : null
      }
      primaryButton={
        isConnected || isDisabled ? null : (
          <>
            <Button
              colorPalette={colorScheme}
              onClick={() => {
                if (merchant.isIntegrationEnabled(IntegrationName.QUICKBOOKS)) {
                  onOpen();
                  return;
                }

                window.location.assign(getXeroOauth2Endpoint(merchant.id));
              }}
            >
              <Icon as={LuPlus} /> Connect
            </Button>
            <DialogRoot
              open={open}
              onOpenChange={({ open: newIsOpen }) => {
                if (!newIsOpen) {
                  onClose();
                }
              }}
              size={isBaseSize ? "full" : "md"}
            >
              <DialogBackdrop />
              <DialogContent>
                <DialogHeader>
                  Please disconnect your Quickbooks integration
                </DialogHeader>
                <DialogCloseTrigger />
                <DialogBody>
                  Your account is already connected to an invoicing software.
                  Please contact your Customer Success Manager to disconnect
                  from your account from Quickbooks before connecting with Xero.
                </DialogBody>
                <DialogFooter>
                  <Button colorPalette={colorScheme} onClick={onClose}>
                    Close
                  </Button>
                </DialogFooter>
              </DialogContent>
            </DialogRoot>
          </>
        )
      }
    >
      <IntegrationDescription text="Install Invoicing in 2 minutes" />
    </Integration>
  );
};

export default XeroIntegrationItem;
