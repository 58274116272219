import { Alert } from "components/ui/alert";
import React from "react";

interface UnexpectedErrorProps {
  message: string;
}

export default function UnexpectedError({ message }: UnexpectedErrorProps) {
  return <Alert status="error" title={message} />;
}
