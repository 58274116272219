import { Button, Flex, Icon, List, Text, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "components/ui/hover-card";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  StatusIcon,
} from "entities/domain/conversations/message-domain";
import React from "react";
import { LuCheck, LuCircleAlert } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

export default ({
  activeConversation,
  message,
}: {
  message: MessageDomain;
  activeConversation?: ConversationDomain;
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.IN_PROGRESS
  ) {
    return (
      <Icon
        as={LuCheck}
        boxSize="1rem"
        color={
          colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.700`
        }
      />
    );
  }

  if (
    activeConversation &&
    [StatusIcon.SUCCESS, StatusIcon.DELIVERED].includes(
      message.getStatusIcon(activeConversation.channel)
    )
  ) {
    const iconColor =
      message.getStatusIcon(activeConversation.channel) === StatusIcon.SUCCESS
        ? "white"
        : colorMode === "dark"
        ? `${colorScheme}.600`
        : `${colorScheme}.700`;

    return (
      <Flex alignItems="center" justifyContent="center">
        <Icon as={LuCheck} boxSize={4} color={iconColor} />
        <Icon as={LuCheck} boxSize={4} color={iconColor} ml={-2} />
      </Flex>
    );
  }

  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.FAILED
  ) {
    return (
      <HoverCardRoot>
        <HoverCardTrigger>
          <Button variant="ghost" size="xs" colorPalette="red">
            <Icon
              as={LuCircleAlert}
              className="statusIcon"
              color={colorMode === "dark" ? "red.600" : "red.400"}
            />
            <Text ml={2} color={colorMode === "dark" ? "red.600" : "red.400"}>
              Not delivered
            </Text>
          </Button>
        </HoverCardTrigger>
        <HoverCardContent>
          <HoverCardArrow />
          {activeConversation.channel === ConversationChannel.WHATSAPP &&
          message.isUnreachable() ? (
            <VStack>
              <Text>Unable to deliver message. Reasons can include:</Text>
              <List.Root fontSize="smaller">
                <List.Item>
                  The recipient phone number is not a WhatsApp phone number.
                </List.Item>
                <List.Item>
                  Recipient has not accepted WhatsApp&apos;s new Terms of
                  Service and Privacy Policy.
                </List.Item>
                <List.Item>Recipient using an old WhatsApp version.</List.Item>
              </List.Root>
            </VStack>
          ) : (
            <Text fontSize="smaller">{message.getFailedReason()}</Text>
          )}
        </HoverCardContent>
      </HoverCardRoot>
    );
  }

  return null;
};
