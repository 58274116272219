import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Heading,
  Icon,
  Text,
  useBreakpointValue,
  useSteps,
  VStack,
} from "@chakra-ui/react";

import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogRoot,
} from "components/ui/dialog";
import {
  StepsCompletedContent,
  StepsContent,
  StepsItem,
  StepsList,
  StepsRoot,
} from "components/ui/steps";

import { toaster } from "components/ui/toaster";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import UserGuideActionsDomain from "entities/domain/userGuides/user-guide-actions";
import { UserGuideStatus } from "entities/domain/userGuides/user-guides";
import React, { useEffect, useState } from "react";
import { LuBanknote, LuPhone, LuShare } from "react-icons/lu";
import { completeUserGuide } from "redux/features/merchant";
import {
  OnboardingStatus,
  setCurrentStep,
  setOnboardingStatus,
} from "redux/features/onboarding";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import UserGuidesService from "services/userGuides";
import { isMobileApp } from "util/methods";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const Onboarding = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);

  const dispatch = useAppDispatch();
  const { status, currentStep, progress } = useAppSelector(
    (state) => state.onboarding
  );

  const lastStepIndex = parseInt(
    Object.keys(progress).sort().pop() as string,
    10
  );
  const areAllStepsCompleted = currentStep > lastStepIndex;
  const isLastStep = currentStep === lastStepIndex;
  const isCurrentStepCompleted = progress[currentStep]?.isCompleted;

  const [mandatoryActions, setMandatoryActions] = useState<
    Array<UserGuideActionsDomain>
  >([]);
  const [socialsActions, setSocialsActions] = useState<
    Array<UserGuideActionsDomain>
  >([]);
  const [bankingActions, setBankingActions] = useState<
    Array<UserGuideActionsDomain>
  >([]);
  const steps = [
    {
      key: "step-1",
      component: <StepOne />,
      icon: LuPhone,
    },
    {
      key: "step-2",
      component: <StepTwo actions={socialsActions} />,
      icon: LuShare,
    },
    {
      key: "step-3",
      component: <StepThree actions={bankingActions} />,
      icon: LuBanknote,
    },
  ];

  const { value: activeStep, setStep } = useSteps({
    defaultStep: currentStep || 0,
    count: steps.length,
  });

  const handleCompleteOnboarding = (lastStepSkipped: boolean = false) => {
    UserGuidesService.completeGuide(
      auth0Context,
      merchant.id,
      MerchantUserGuides.ONBOARDING
    )
      .then((res) => {
        if (res) {
          dispatch(setOnboardingStatus(OnboardingStatus.COMPLETED));
          dispatch(completeUserGuide(MerchantUserGuides.ONBOARDING));
          dispatch(
            setCurrentStep({
              newCurrentStep: currentStep + 1,
              isPreviousStepSkipped: lastStepSkipped,
              isPreviousStepCompleted: !lastStepSkipped,
            })
          );
        }
      })
      .catch((err) => {
        setOnboardingStatus(OnboardingStatus.PAUSED);
        toaster.create({
          type: "error",
          title: "Onboarding couldn't be completed.",
          description: "Please try again later.",
        });
      });
  };

  const [onboardingEnabled, setOnboardingEnabled] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (status !== OnboardingStatus.IN_PROGRESS) {
      return;
    }

    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      if (typeof onboardingEnabled === "undefined") {
        setOnboardingEnabled(true);
      }

      UserGuidesService.getGuides(
        auth0Context,
        merchant.id,
        MerchantUserGuides.ONBOARDING
      ).then((res) => {
        setMandatoryActions(res.actions.filter((r) => r.group === "mandatory"));
        setSocialsActions(res.actions.filter((r) => r.group === "socials"));
        setBankingActions(res.actions.filter((r) => r.group === "banking"));
      });
    }
  }, [status]);

  useEffect(() => {
    if (
      mandatoryActions.filter(
        (action: UserGuideActionsDomain) =>
          action.status === UserGuideStatus.PENDING
      ).length
    ) {
      setStep(0);
      dispatch(
        setCurrentStep({
          newCurrentStep: 0,
          isPreviousStepSkipped: false,
          isPreviousStepCompleted: false,
        })
      );
    } else if (
      socialsActions.filter(
        (action: UserGuideActionsDomain) =>
          action.status === UserGuideStatus.PENDING
      ).length > 0
    ) {
      setStep(1);
      dispatch(
        setCurrentStep({
          newCurrentStep: 1,
          isPreviousStepSkipped: false,
          isPreviousStepCompleted: true,
        })
      );
    } else if (
      bankingActions.filter(
        (action: UserGuideActionsDomain) =>
          action.status === UserGuideStatus.PENDING
      ).length > 0
    ) {
      setStep(2);
      dispatch(
        setCurrentStep({
          newCurrentStep: 2,
          isPreviousStepSkipped: false,
          isPreviousStepCompleted: true,
        })
      );
    }
  }, [mandatoryActions, socialsActions, bankingActions]);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  let actionButtonText = "Continue";

  if (isLastStep) {
    actionButtonText = "Complete";
  }

  if (areAllStepsCompleted) {
    actionButtonText = "Close";
  }

  const [personalizedHelpTimeout, setPersonalizedHelpTimeout] = useState<
    number | undefined
  >();
  const [showPersonalizedHelp, setShowPersonalizedHelp] =
    useState<boolean>(false);

  useEffect(() => {
    if (personalizedHelpTimeout) {
      return;
    }

    const timeout = window.setTimeout(() => {
      setShowPersonalizedHelp(true);
    }, 1000 * 60 * 2);

    setPersonalizedHelpTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [personalizedHelpTimeout]);

  useEffect(() => {
    if (areAllStepsCompleted || !personalizedHelpTimeout) {
      return;
    }

    clearTimeout(personalizedHelpTimeout);
    setPersonalizedHelpTimeout(undefined);
    setShowPersonalizedHelp(false);
  }, [currentStep]);

  return (
    <DialogRoot
      size={isBaseSize ? "full" : "xl"}
      closeOnEscape={areAllStepsCompleted}
      closeOnInteractOutside={areAllStepsCompleted}
      open={!!onboardingEnabled}
      onOpenChange={({ open: newIsOpen }) => {
        if (newIsOpen) {
          return;
        }
        if (
          merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING) &&
          status === OnboardingStatus.IN_PROGRESS
        ) {
          setOnboardingStatus(OnboardingStatus.PAUSED);
        } else {
          setOnboardingEnabled(false);
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogBody>
          {showPersonalizedHelp && (
            <Alert
              status="info"
              borderRadius="lg"
              mt={4}
              title="Want Need help?"
            >
              <Button
                colorPalette={colorScheme}
                fontWeight={800}
                variant="subtle"
                textTransform="none"
                onClick={() => {
                  if (isMobileApp()) {
                    window.natively.openExternalURL(
                      "https://calendly.com/hector-105/30min"
                    );
                  } else {
                    window.open(
                      "https://calendly.com/hector-105/30min",
                      "_blank"
                    );
                  }
                }}
              >
                Schedule a call
              </Button>{" "}
              with one of our onboarding specialists and we&apos;ll get you set
              up!
            </Alert>
          )}
          <StepsRoot
            step={activeStep}
            colorPalette={colorScheme}
            my={6}
            count={steps.length}
          >
            <StepsList>
              {steps.map((step, index) => (
                <StepsItem
                  key={`${index}-Step-${index + 1}`}
                  index={index}
                  title={`Step ${index + 1}`}
                >
                  <Icon
                    as={step.icon}
                    color={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                </StepsItem>
              ))}
            </StepsList>
            {steps.map((step, index) => (
              <StepsContent key={`Steps-Content-${index}`} index={index}>
                {step.component}
              </StepsContent>
            ))}
            <StepsCompletedContent>
              <VStack>
                <Heading>🎉 Hooray!</Heading>
                <Text>You&apos;re all set up!</Text>
              </VStack>
            </StepsCompletedContent>
          </StepsRoot>
        </DialogBody>

        <DialogFooter
          justifyContent={
            activeStep === 0 || areAllStepsCompleted ? "end" : "space-between"
          }
        >
          {activeStep !== 0 && !areAllStepsCompleted && (
            <Button
              colorPalette={colorScheme}
              variant="ghost"
              onClick={() => {
                setOnboardingEnabled(undefined);
                setOnboardingStatus(OnboardingStatus.PAUSED);
              }}
            >
              Set up later
            </Button>
          )}
          <Box>
            <Button
              colorPalette={colorScheme}
              variant="outline"
              mr={3}
              onClick={() => {
                if (isLastStep) {
                  handleCompleteOnboarding(true);
                } else {
                  dispatch(
                    setCurrentStep({
                      newCurrentStep: currentStep + 1,
                      isPreviousStepSkipped: true,
                      isPreviousStepCompleted: false,
                    })
                  );
                }

                setStep(activeStep + 1);
              }}
            >
              Skip
            </Button>
            <Button
              colorPalette={colorScheme}
              disabled={!isCurrentStepCompleted && !areAllStepsCompleted}
              onClick={() => {
                if (areAllStepsCompleted) {
                  setOnboardingEnabled(false);
                }

                if (!isCurrentStepCompleted) {
                  return;
                }

                if (isLastStep) {
                  handleCompleteOnboarding();
                } else {
                  dispatch(
                    setCurrentStep({
                      newCurrentStep: currentStep + 1,
                      isPreviousStepSkipped: false,
                      isPreviousStepCompleted: true,
                    })
                  );
                }

                setStep(activeStep + 1);
              }}
            >
              {actionButtonText}
            </Button>
          </Box>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default Onboarding;
