const IconByManufacturer: {
  [key: string]: any;
} = {
  toyota: "/cars/toyota-svgrepo-com.svg",
  vauxhall: "/cars/vauxhall-svgrepo-com.svg",
  skoda: "/cars/skoda-svgrepo-com.svg",
  škoda: "/cars/skoda-svgrepo-com.svg",
  smart: "/cars/smart-svgrepo-com.svg",
  seat: "/cars/seat-svgrepo-com.svg",
  renault: "/cars/renault-alt-svgrepo-com.svg",
  plymouth: "/cars/plymouth-svgrepo-com.svg",
  peugeot: "/cars/peugeot-svgrepo-com.svg",
  opel: "/cars/opel-svgrepo-com.svg",
  mercury: "/cars/mercury-svgrepo-com.svg",
  maybach: "/cars/maybach-svgrepo-com.svg",
  lincoln: "/cars/lincoln-svgrepo-com.svg",
  honda: "/cars/honda-svgrepo-com.svg",
  isuzu: "/cars/isuzu-svgrepo-com.svg",
  ford: "/cars/ford-svgrepo-com.svg",
  chevrolet: "/cars/chevrolet-svgrepo-com.svg",
  nissan: "/cars/nissan-svgrepo-com.svg",
  volkswagen: "/cars/volkswagen-svgrepo-com.svg",
  hyundai: "/cars/hyundai-svgrepo-com.svg",
  bmw: "/cars/bmw-svgrepo-com.svg",
  daewoo: "/cars/daewoo-svgrepo-com.svg",
  hummer: "/cars/hummer-svgrepo-com.svg",
  audi: "/cars/audi-svgrepo-com.svg",
  kia: "/cars/kia-svgrepo-com.svg",
  subaru: "/cars/subaru-alt-svgrepo-com.svg",
  lexus: "/cars/lexus-svgrepo-com.svg",
  mazda: "/cars/mazda-svgrepo-com.svg",
  gmc: "/cars/gmc-svgrepo-com.svg",
  ram: "/cars/ram-svgrepo-com.svg",
  dodge: "/cars/ram-svgrepo-com.svg",
  jeep: "/cars/jeep-alt-svgrepo-com.svg",
  buick: "/cars/buick-svgrepo-com.svg",
  cadillac: "/cars/cadillac-svgrepo-com.svg",
  acura: "/cars/acura-svgrepo-com.svg",
  infiniti: "/cars/infiniti-svgrepo-com.svg",
  porsche: "/cars/porsche-svgrepo-com.svg",
  "land-rover": "/cars/land-rover-svgrepo-com.svg",
  landrover: "/cars/land-rover-svgrepo-com.svg",
  volvo: "/cars/volvo-svgrepo-com.svg",
  mini: "/cars/mini-svgrepo-com.svg",
  mitsubishi: "/cars/mitsubishi-svgrepo-com.svg",
  tesla: "/cars/tesla-svgrepo-com.svg",
  genesis: "/cars/genesis-svgrepo-com.svg",
  jaguar: "/cars/jaguar-alt-svgrepo-com.svg",
  fiat: "/cars/fiat-svgrepo-com.svg",
  "alfa-romeo": "/cars/alfa-romeo-alt-svgrepo-com.svg",
  alfaromeo: "/cars/alfa-romeo-alt-svgrepo-com.svg",
  maserati: "/cars/maserati-svgrepo-com.svg",
  bentley: "/cars/bentley-svgrepo-com.svg",
  ferrari: "/cars/ferrari-svgrepo-com.svg",
  lamborghini: "/cars/lamborghini-alt-svgrepo-com.svg",
  bugatti: "/cars/bugatti-svgrepo-com.svg",
  "rolls-royce": "/cars/rolls-royce-svgrepo-com.svg",
  rollsroyce: "/cars/rolls-royce-svgrepo-com.svg",
  mclaren: "/cars/mclaren-svgrepo-com.svg",
  lotus: "/cars/lotus-alt-svgrepo-com.svg",
  saab: "/cars/saab-alt-svgrepo-com.svg",
  chrysler: "/cars/chrysler-svgrepo-com.svg",
  citroen: "/cars/citroen-svgrepo-com.svg",
  citroën: "/cars/citroen-svgrepo-com.svg",
  fisker: "/cars/fisker-svgrepo-com.svg",
  "mercedes-benz": "/cars/mercedes-svgrepo-com.svg",
  mercedes: "/cars/mercedes-svgrepo-com.svg",
  mercedesbenz: "/cars/mercedes-svgrepo-com.svg",
};

export class VehicleRegistrationDomain {
  firstRegistrationDate: string | null;

  registrationDate: string | null;

  constructor(
    firstRegistrationDate: string | null,
    registrationDate: string | null
  ) {
    this.firstRegistrationDate = firstRegistrationDate;
    this.registrationDate = registrationDate;
  }
}

export class GovInspectionDomain {
  lastDate: string | null;

  dueDate: string | null;

  constructor(lastDate: string | null, dueDate: string | null) {
    this.lastDate = lastDate;
    this.dueDate = dueDate;
  }
}

export class ServiceDomain {
  lastDate: string | null;

  dueDate: string | null;

  constructor(lastDate: string | null, dueDate: string | null) {
    this.lastDate = lastDate;
    this.dueDate = dueDate;
  }
}

export class VehicleMakeAndModel {
  name: string;

  models: string[];

  constructor(name: string, models: string[]) {
    this.name = name;
    this.models = models;
  }
}

export default class VehicleDomain {
  id: string;

  merchantId: number;

  customerIds: number[];

  vin: string;

  make: string;

  model: string;

  vehicleClass: string;

  year: number;

  licensePlate: string;

  description: string;

  variant: string;

  interiorColor: string;

  exteriorColor: string;

  createdAt: string;

  updatedAt: string;

  registration: VehicleRegistrationDomain | null;

  govInspection: GovInspectionDomain | null;

  service: ServiceDomain | null;

  constructor(
    id: string,
    merchantId: number,
    customerIds: number[],
    vin: string,
    make: string,
    model: string,
    vehicleClass: string,
    year: number,
    licensePlate: string,
    description: string,
    variant: string,
    interiorColor: string,
    exteriorColor: string,
    createdAt: string,
    updatedAt: string,
    registration: VehicleRegistrationDomain | null = null,
    govInspection: GovInspectionDomain | null = null,
    service: ServiceDomain | null = null
  ) {
    this.id = id;
    this.merchantId = merchantId;
    this.customerIds = customerIds;
    this.vin = vin;
    this.make = make;
    this.model = model;
    this.vehicleClass = vehicleClass;
    this.year = year;
    this.licensePlate = licensePlate;
    this.description = description;
    this.variant = variant;
    this.interiorColor = interiorColor;
    this.exteriorColor = exteriorColor;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.registration = registration;
    this.govInspection = govInspection;
    this.service = service;
  }

  getCarManufacturer(): string | null {
    const manufacturers: string[] = Object.keys(IconByManufacturer);
    const normalizedMake = this.make.trim().toLowerCase();
    const normalizedDescription = this.description.trim().toLowerCase();
    const normalizedVariant = this.variant.trim().toLowerCase();

    for (let i = 0; i < manufacturers.length; i++) {
      const manufacturer = manufacturers[i];

      if (normalizedMake.includes(manufacturer)) {
        return manufacturer;
      }

      if (normalizedDescription.includes(manufacturer)) {
        return manufacturer;
      }

      if (normalizedVariant.includes(manufacturer)) {
        return manufacturer;
      }
    }

    return null;
  }

  getIconSource(): string | null {
    const manufacturer = this.getCarManufacturer();

    if (manufacturer) {
      return IconByManufacturer[manufacturer];
    }

    return null;
  }
}
