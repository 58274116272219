import { Flex, Icon, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React, { ReactNode } from "react";
import { LuArrowRightLeft, LuTrendingDown, LuTrendingUp } from "react-icons/lu";

const CampaignStat = ({
  value,
  header,
  description,
  icon,
  isGoodTrend,
  isTrendingSideways,
  isTrendingUp,
}: {
  value: number;
  header: string;
  description: string;
  icon?: ReactNode;
  isGoodTrend?: boolean;
  isTrendingSideways?: boolean;
  isTrendingUp?: boolean;
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="start"
      borderRadius="3xl"
      width="250px"
      height="300px"
      borderWidth="1px"
      borderStyle="solid"
      borderColor={
        isGoodTrend ? "green.400" : isTrendingSideways ? "gray.400" : "red.400"
      }
      p={6}
    >
      <Flex
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        mt={16}
      >
        <Text fontWeight="bold" fontSize="xl">
          {value}
        </Text>
        <Flex
          alignItems="start"
          justifyContent="start"
          gridGap={2}
          direction="column"
        >
          <Text fontWeight="bold" color="gray.400">
            {header}
          </Text>
          <Text fontWeight="light" color="gray.400" fontSize="sm">
            {description}
          </Text>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        h="100%"
        justifyContent={icon ? "space-between" : "start"}
        alignItems="start"
      >
        <Icon
          as={
            isTrendingUp
              ? LuTrendingDown
              : isTrendingSideways
              ? LuArrowRightLeft
              : LuTrendingUp
          }
          color={
            isGoodTrend
              ? colorMode === "dark"
                ? "green.200"
                : "green.500"
              : isTrendingSideways
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.500"
              : colorMode === "dark"
              ? "red.200"
              : "red.500"
          }
        />
      </Flex>
    </Flex>
  );
};

export default CampaignStat;
