import { Box, Icon, List, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { DialogBody } from "components/ui/dialog";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";
import { getChannelIcon } from "util/constants";

interface MergeSelectedContactProps {
  contactToMergeFrom: ContactDomain;
  contactToMergeInto: ContactDomain;
}

const MergeSelectedContact = ({
  contactToMergeFrom,
  contactToMergeInto,
}: MergeSelectedContactProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const contactChannelsByGroup = (
    contact: ContactDomain
  ): Map<string, CustomerChannelDomain[]> => {
    const result = new Map<string, CustomerChannelDomain[]>();

    contact?.channels.forEach((c: CustomerChannelDomain) => {
      const channelsPerGroup = result.get(c.getChannelGroup());
      if (channelsPerGroup) {
        channelsPerGroup.push(c);
      } else {
        result.set(c.getChannelGroup(), [c]);
      }
    });

    return result;
  };

  const calculateMergedGroups = (
    fromContact: ContactDomain,
    toContact: ContactDomain
  ): Set<string> => {
    const fromByGroup = contactChannelsByGroup(fromContact);
    const toByGroup = contactChannelsByGroup(toContact);

    const result = new Set([...fromByGroup.keys(), ...toByGroup.keys()]);

    return result;
  };

  const extendChannelsWithActive = (
    group: string,
    contactFrom: ContactDomain,
    contactTo: ContactDomain
  ): CustomerChannelDomain[] => {
    const fromChannels =
      contactFrom.channels.filter((chan) => chan.getChannelGroup() === group) ||
      [];

    const toChannels = contactChannelsByGroup(contactTo).get(group)! || [];

    const result = [...fromChannels, ...toChannels];
    return result;
  };

  return (
    <>
      <DialogBody style={{ padding: 0 }} mx="1.875rem">
        <Box
          background={colorMode === "dark" ? "gray.900" : "gray.50"}
          borderRadius="0.75rem"
          padding="1rem 1.5rem"
          mb={2}
        >
          <Text fontWeight="bold">
            {contactToMergeFrom.name} {contactToMergeFrom.surname}
          </Text>

          <List.Root gap={3} mt={3}>
            {contactToMergeFrom.channels.map((chan) => {
              return (
                <List.Item display="flex" key={chan.id} alignItems="center">
                  <Icon
                    asChild={true}
                    css={{
                      "& svg": {
                        height: "1rem",
                        width: "1rem",
                        margin: "0 auto",
                      },
                      marginRight: "0.5rem",
                    }}
                  >
                    <ReactSVG src={getChannelIcon(chan.type)} />
                  </Icon>
                  <Text>{chan.handle}</Text>
                </List.Item>
              );
            })}
          </List.Root>
        </Box>

        <Icon
          asChild={true}
          css={{
            "& svg": {
              height: "1rem",
              width: "1rem",
              margin: "0 auto",
              transform: "rotate(270deg)",
              fill: `${colorScheme}.400`,
            },
            "& path": {
              fill: `${colorScheme}.400`,
            },
          }}
        >
          <ReactSVG src="/left-arrow.svg" />
        </Icon>

        <Box
          mt={2}
          background={colorMode === "dark" ? "gray.900" : "gray.50"}
          borderRadius="0.75rem"
          padding="1rem 1.5rem"
        >
          <Text fontWeight="bold">
            {" "}
            {contactToMergeInto.name} {contactToMergeInto.surname}
          </Text>

          {Array.from(
            calculateMergedGroups(contactToMergeFrom, contactToMergeInto)
          ).map((group: string) => {
            return (
              <List.Root gap={3} mt={3} key={group}>
                <Text mt="0.75rem" fontSize="md" fontWeight="semibold">
                  {group}
                </Text>
                {extendChannelsWithActive(
                  group,
                  contactToMergeFrom,
                  contactToMergeInto
                )?.map((chan: CustomerChannelDomain) => {
                  return (
                    <List.Item display="flex" key={chan.id} alignItems="center">
                      <Icon
                        asChild={true}
                        css={{
                          "& svg": {
                            height: "1rem",
                            width: "1rem",
                            margin: "0 auto",
                          },
                          marginRight: "0.5rem",
                        }}
                      >
                        <ReactSVG src={getChannelIcon(chan.type)} />
                      </Icon>
                      <Text>{chan.handle}</Text>
                    </List.Item>
                  );
                })}
              </List.Root>
            );
          })}
        </Box>
      </DialogBody>
    </>
  );
};

export default MergeSelectedContact;
