import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import ReviewsService from "services/reviews";

const GoogleGmailCallback = () => {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const google_redirect_url = new URLSearchParams(useLocation().search);
  const state = google_redirect_url.get("state");
  const merchantIdFromState = state ? parseInt(state, 10) : null;
  const google_authorization_code = google_redirect_url.get("code");

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (!merchantIdFromState || typeof merchantIdFromState !== "number") {
      // eslint-disable-next-line
      console.error("Merchant ID didn't come with the sate to Gmail callback", {
        url: google_redirect_url,
        passedMerchantId: state,
      });
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Gmail account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);

      return;
    }

    const merchantIsAvailable = currentAgent!.merchants
      .map((m: AgentMerchantDomain) => m.id)
      .includes(merchantIdFromState);

    if (merchantIdFromState !== merchant.id) {
      if (!merchantIsAvailable) {
        // eslint-disable-next-line
        console.error(
          "Merchant ID is not available to agent during Gmail callback processing",
          {
            url: google_redirect_url,
            passedMerchantId: state,
            agentId: currentAgent!.id,
          }
        );
        localStorage.setItem(
          "callback_fail",
          JSON.stringify({
            title: "We couldn't connect your Gmail account...",
            description: "Please try again later or contact our support team.",
          })
        );
        window.location.assign(`/public/callback/fail`);

        return;
      }

      localStorage.setItem("fuzey:merchant:id", merchantIdFromState.toString());
      window.location.assign(window.location.href);

      return;
    }

    if (google_authorization_code) {
      ReviewsService.addGoogleGmailIntegration(
        auth0Context,
        google_authorization_code,
        merchant.id
      )
        .then(() => {
          localStorage.setItem(
            "callback_success",
            JSON.stringify({
              title: "Gmail was successfully connected!",
              description:
                "You can disconnect any time from your settings page.",
            })
          );
          window.location.assign(`/public/callback/success`);
          setIsLoading(false);
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);

          localStorage.setItem(
            "callback_fail",
            JSON.stringify({
              title: "We couldn't connect your Gmail account...",
              description:
                "Please try again later or contact our support team.",
            })
          );
          window.location.assign(`/public/callback/fail`);
          setIsLoading(false);
        });
    } else {
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Gmail account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);
      setIsLoading(false);
    }
  }, [merchant.id, currentAgent?.merchants]);

  if (isLoading) {
    return <Spinner />;
  }

  return <div>Redirecting...</div>;
};

export default GoogleGmailCallback;
