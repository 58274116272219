import { Button, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { InputGroup } from "components/ui/input-group";
import { NumberInputField, NumberInputRoot } from "components/ui/number-input";
import { toaster } from "components/ui/toaster";
import { Tooltip } from "components/ui/tooltip";
import { format } from "date-fns";
import { AttributeType } from "entities/domain/attributes/attribute-domain";
import CustomerAttributeDomain, {
  AttributeValueType,
} from "entities/domain/customers/contact-attribute-domain";
import useCustomerAttributesStore from "hooks/use-customer-attributes-store";
import React, {
  RefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

function useBlurHandler<T extends HTMLElement>(
  ref: RefObject<T>,
  onBlur: () => void
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onBlur, ref]);
}

interface EditableAttributeValueButtonProps {
  attributeName: string;
  attributeDisplayValue: string | undefined;
  setIsEditing: (value: boolean) => void;
}

const EditableAttributeValueButton: React.FC<
  EditableAttributeValueButtonProps
> = ({ attributeName, attributeDisplayValue, setIsEditing }) => {
  return (
    <Button
      unstyled={true}
      textTransform="none"
      onClick={() => setIsEditing(true)}
    >
      <Tooltip content={`Click to edit ${attributeName}`}>
        <Text _hover={{ color: "gray.300" }}>
          {attributeDisplayValue ?? "n/a"}
        </Text>
      </Tooltip>
    </Button>
  );
};

interface CustomerAttributeAccordionFormProps {
  customerId: number;
  attributeId: string;
  attributeName: string;
  attributeType: AttributeType;
}

interface CustomerAttributeIdAndDefaultValue<T extends AttributeValueType> {
  id: string;
  defaultValue: T;
}

interface IndividualCustomerAttributeFormProps<T extends AttributeValueType> {
  customerId: number;
  customerAttributeIdAndDefaultValue:
    | CustomerAttributeIdAndDefaultValue<T>
    | undefined;
  attributeId: string;
  onBlur: () => void;
  attributeValue: T;
  setAttributeValue: (attributeValue: T) => void;
}

const AttributeInputWrapper: React.FC<{
  attributeName: string;
  displayValue: string | undefined;
  setIsEditing: (value: boolean) => void;
  isEditing: boolean;
  children: React.ReactNode;
}> = ({ attributeName, children, isEditing, displayValue, setIsEditing }) => {
  return (
    <Field label={attributeName} w="100%">
      {isEditing ? (
        children
      ) : (
        <EditableAttributeValueButton
          attributeName={attributeName}
          attributeDisplayValue={displayValue}
          setIsEditing={setIsEditing}
        />
      )}
    </Field>
  );
};

const StringValuedCustomerAttributeAccordionForm = <
  T extends string | undefined
>({
  customerId,
  customerAttributeIdAndDefaultValue,
  attributeId,
  onBlur,
  attributeValue,
  setAttributeValue,
}: IndividualCustomerAttributeFormProps<T>) => {
  const { addCustomerAttribute, deleteCustomerAttribute } =
    useCustomerAttributesStore();

  const { merchant } = useAppSelector((state) => state.merchant);

  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: customerAttributeId, defaultValue } =
    customerAttributeIdAndDefaultValue ?? {
      id: undefined,
      defaultValue: undefined,
    };

  const ref = useRef<HTMLDivElement>(null);

  const onBlurCallback = useCallback(async () => {
    if (attributeValue !== defaultValue) {
      setIsLoading(true);

      if (!attributeValue) {
        const success: boolean = await deleteCustomerAttribute(
          customerId,
          customerAttributeId!,
          attributeId
        );

        if (!success) {
          toaster.create({
            type: "error",
            title: "Couldn't delete attribute value",
          });
        }
      } else {
        const addedCustomerAttribute:
          | CustomerAttributeDomain<AttributeValueType>
          | undefined = await addCustomerAttribute(customerId, {
          attribute_id: attributeId,
          value_string: attributeValue,
        });

        if (addedCustomerAttribute === undefined) {
          toaster.create({
            type: "error",
            title: "Couldn't add attribute",
          });
        }
      }

      setIsLoading(false);
    }

    if (attributeValue) {
      onBlur();
    }
  }, [
    onBlur,
    attributeValue,
    defaultValue,
    attributeId,
    customerId,
    merchant.groupId,
  ]);

  useBlurHandler(ref, onBlurCallback);

  return (
    <Flex ref={ref} w="100%">
      <InputGroup
        w="100%"
        endElement={isLoading ? <Spinner size="xs" /> : null}
      >
        <Input
          autoFocus
          colorPalette={colorScheme}
          placeholder="Enter some text"
          defaultValue={defaultValue}
          onChange={(e) => {
            const newValue = e.target.value;
            setAttributeValue((newValue !== "" ? newValue : undefined) as T);
          }}
        />
      </InputGroup>
    </Flex>
  );
};

const IntegerValuedCustomerAttributeAccordionForm = <
  T extends number | undefined
>({
  customerId,
  customerAttributeIdAndDefaultValue,
  attributeId,
  onBlur,
  attributeValue,
  setAttributeValue,
}: IndividualCustomerAttributeFormProps<T>) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const { addCustomerAttribute, deleteCustomerAttribute } =
    useCustomerAttributesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: customerAttributeId, defaultValue } =
    customerAttributeIdAndDefaultValue ?? {
      id: undefined,
      defaultValue: undefined,
    };

  const [attributeValueAsString, setAttributeValueAsString] = useState<
    string | undefined
  >(attributeValue ? String(defaultValue) : undefined);

  const ref = useRef<HTMLDivElement>(null);

  const onBlurCallback = useCallback(async () => {
    if (attributeValue !== defaultValue) {
      setIsLoading(true);

      if (!attributeValue) {
        const success: boolean = await deleteCustomerAttribute(
          customerId,
          customerAttributeId!,
          attributeId
        );

        if (!success) {
          toaster.create({
            type: "error",
            title: "Couldn't delete attribute value",
          });
        }
      } else {
        const addedCustomerAttribute:
          | CustomerAttributeDomain<AttributeValueType>
          | undefined = await addCustomerAttribute(customerId, {
          attribute_id: attributeId,
          value_integer: attributeValue,
        });

        if (addedCustomerAttribute === undefined) {
          toaster.create({
            type: "error",
            title: "Couldn't add attribute",
          });
        }
      }

      setIsLoading(false);
    }

    if (attributeValue) {
      onBlur();
    }
  }, [
    onBlur,
    attributeValue,
    defaultValue,
    attributeId,
    customerId,
    merchant.groupId,
  ]);

  useBlurHandler(ref, onBlurCallback);

  return (
    <Flex ref={ref} w="100%">
      <InputGroup
        w="100%"
        endElement={isLoading ? <Spinner size="xs" /> : null}
      >
        <NumberInputRoot
          autoFocus
          colorPalette={colorScheme}
          value={attributeValueAsString}
          onValueChange={({ value: valueAsString, valueAsNumber }) => {
            if (!/^-?[0-9]*$/.test(valueAsString)) {
              return;
            }

            setAttributeValueAsString(valueAsString);
            setAttributeValue(
              (Number.isNaN(valueAsNumber) ? undefined : valueAsNumber) as T
            );
          }}
        >
          <NumberInputField placeholder="Enter some integer" />
        </NumberInputRoot>
      </InputGroup>
    </Flex>
  );
};

const FloatValuedCustomerAttributeAccordionForm = <
  T extends number | undefined
>({
  customerId,
  customerAttributeIdAndDefaultValue,
  attributeId,
  onBlur,
  attributeValue,
  setAttributeValue,
}: IndividualCustomerAttributeFormProps<T>) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const { addCustomerAttribute, deleteCustomerAttribute } =
    useCustomerAttributesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: customerAttributeId, defaultValue } =
    customerAttributeIdAndDefaultValue ?? {
      id: undefined,
      defaultValue: undefined,
    };

  const [attributeValueAsString, setAttributeValueAsString] = useState<
    string | undefined
  >(attributeValue ? String(defaultValue) : undefined);

  const ref = useRef<HTMLDivElement>(null);

  const onBlurCallback = useCallback(async () => {
    if (attributeValue !== defaultValue) {
      setIsLoading(true);

      if (!attributeValue) {
        const success: boolean = await deleteCustomerAttribute(
          customerId,
          customerAttributeId!,
          attributeId
        );

        if (!success) {
          toaster.create({
            type: "error",
            title: "Couldn't delete attribute value",
          });
        }
      } else {
        const addedCustomerAttribute:
          | CustomerAttributeDomain<AttributeValueType>
          | undefined = await addCustomerAttribute(customerId, {
          attribute_id: attributeId,
          value_float: attributeValue,
        });

        if (addedCustomerAttribute === undefined) {
          toaster.create({
            type: "error",
            title: "Couldn't add attribute",
          });
        }
      }

      setIsLoading(false);
    }

    if (attributeValue) {
      onBlur();
    }
  }, [
    onBlur,
    attributeValue,
    defaultValue,
    attributeId,
    customerId,
    merchant.groupId,
  ]);

  useBlurHandler(ref, onBlurCallback);

  return (
    <Flex ref={ref} w="100%">
      <InputGroup
        w="100%"
        endElement={isLoading ? <Spinner size="xs" /> : null}
      >
        <NumberInputRoot
          colorPalette={colorScheme}
          value={attributeValueAsString}
          onValueChange={({ value: valueAsString, valueAsNumber }) => {
            if (!/^-?[0-9]*(\.?)[0-9]*$/.test(valueAsString)) {
              return;
            }

            setAttributeValueAsString(valueAsString);
            setAttributeValue(
              (Number.isNaN(valueAsNumber) ? undefined : valueAsNumber) as T
            );
          }}
        >
          <NumberInputField placeholder="Enter some decimal" />
        </NumberInputRoot>
      </InputGroup>
    </Flex>
  );
};

const BooleanValuedCustomerAttributeAccordionForm = <
  T extends boolean | undefined
>({
  customerId,
  customerAttributeIdAndDefaultValue,
  attributeId,
  onBlur,
  attributeValue,
  setAttributeValue,
}: IndividualCustomerAttributeFormProps<T>) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { addCustomerAttribute, deleteCustomerAttribute } =
    useCustomerAttributesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: customerAttributeId, defaultValue } =
    customerAttributeIdAndDefaultValue ?? {
      id: undefined,
      defaultValue: undefined,
    };

  const ref = useRef<HTMLDivElement>(null);

  const onBlurCallback = useCallback(async () => {
    if (attributeValue !== defaultValue) {
      setIsLoading(true);

      if (attributeValue === undefined) {
        const success: boolean = await deleteCustomerAttribute(
          customerId,
          customerAttributeId!,
          attributeId
        );

        if (!success) {
          toaster.create({
            type: "error",
            title: "Couldn't delete attribute value",
          });
        }
      } else {
        const addedCustomerAttribute:
          | CustomerAttributeDomain<AttributeValueType>
          | undefined = await addCustomerAttribute(customerId, {
          attribute_id: attributeId,
          value_boolean: attributeValue,
        });

        if (addedCustomerAttribute === undefined) {
          toaster.create({
            type: "error",
            title: "Couldn't add attribute",
          });
        }
      }

      setIsLoading(false);
    }

    if (attributeValue !== undefined) {
      onBlur();
    }
  }, [
    onBlur,
    attributeValue,
    defaultValue,
    attributeId,
    customerId,
    merchant.groupId,
  ]);

  useBlurHandler(ref, onBlurCallback);

  const trueOption = { label: "Yes", value: true };
  const falseOption = { label: "No", value: false };

  return (
    <Flex ref={ref} width="100%">
      <InputGroup
        width="100%"
        endElement={isLoading ? <Spinner size="xs" /> : null}
      >
        <Select
          isMulti={false}
          placeholder="Select an option"
          defaultValue={
            defaultValue === undefined
              ? null
              : defaultValue === true
              ? trueOption
              : falseOption
          }
          value={
            attributeValue === undefined
              ? null
              : attributeValue === true
              ? trueOption
              : falseOption
          }
          onChange={(
            option: SingleValue<{ label: string; value: boolean }>
          ) => {
            setAttributeValue((option ? option.value : undefined) as T);
          }}
          isClearable={true}
          options={[trueOption, falseOption]}
          styles={{
            ...{
              ...getReactSelectStyles(
                (colorMode as "dark" | "light") || "light",
                colorScheme
              ),
              container: (provided: any) => ({
                ...provided,
                width: "100%",
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 99999,
                pointerEvents: "all",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 99999,
                borderRadius: "1rem",
                pointerEvents: "all",
              }),
            },
          }}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      </InputGroup>
    </Flex>
  );
};

const DatetimeValuedCustomerAttributeAccordionForm = <
  T extends Date | undefined
>({
  customerId,
  customerAttributeIdAndDefaultValue,
  attributeId,
  onBlur,
  attributeValue,
  setAttributeValue,
}: IndividualCustomerAttributeFormProps<T>) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  const { colorMode } = useColorMode();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { addCustomerAttribute, deleteCustomerAttribute } =
    useCustomerAttributesStore();

  const { id: customerAttributeId, defaultValue } =
    customerAttributeIdAndDefaultValue ?? {
      id: undefined,
      defaultValue: undefined,
    };

  const ref = useRef<HTMLDivElement>(null);

  const onBlurCallback = useCallback(async () => {
    if (attributeValue !== defaultValue) {
      setIsLoading(true);

      if (!attributeValue) {
        const success: boolean = await deleteCustomerAttribute(
          customerId,
          customerAttributeId!,
          attributeId
        );

        if (!success) {
          toaster.create({
            type: "error",
            title: "Couldn't delete attribute value",
          });
        }
      } else {
        const addedCustomerAttribute:
          | CustomerAttributeDomain<AttributeValueType>
          | undefined = await addCustomerAttribute(customerId, {
          attribute_id: attributeId,
          value_datetime: attributeValue,
        });

        if (addedCustomerAttribute === undefined) {
          toaster.create({
            type: "error",
            title: "Couldn't add attribute",
          });
        }
      }

      setIsLoading(false);
    }

    if (attributeValue) {
      onBlur();
    }
  }, [
    onBlur,
    attributeValue,
    defaultValue,
    attributeId,
    customerId,
    merchant.groupId,
  ]);

  useBlurHandler(ref, onBlurCallback);

  return (
    <Flex ref={ref} w="100%">
      <InputGroup
        w="100%"
        endElement={isLoading ? <Spinner size="xs" /> : null}
      >
        <Input
          type="datetime-local"
          value={
            attributeValue ? format(attributeValue, "yyyy-MM-dd'T'HH:mm") : ""
          }
          onChange={(e) => {
            const newDate = new Date(e.target.value) as Date;
            const isValidDate = !Number.isNaN(newDate.getTime());

            if (isValidDate) {
              setAttributeValue(newDate as T);

              return;
            }

            setAttributeValue(undefined as T);
          }}
        />
      </InputGroup>
    </Flex>
  );
};

const formComponentsPerAttributeType = {
  [AttributeType.STRING]: StringValuedCustomerAttributeAccordionForm,
  [AttributeType.INTEGER]: IntegerValuedCustomerAttributeAccordionForm,
  [AttributeType.FLOAT]: FloatValuedCustomerAttributeAccordionForm,
  [AttributeType.BOOLEAN]: BooleanValuedCustomerAttributeAccordionForm,
  [AttributeType.DATETIME]: DatetimeValuedCustomerAttributeAccordionForm,
};

const displayValuesGettersPerAttributeType = new Map<
  AttributeType,
  (defaultValue: AttributeValueType) => string | undefined
>([
  [AttributeType.STRING, (defaultValue) => defaultValue as string | undefined],
  [
    AttributeType.INTEGER,
    (defaultValue) => (defaultValue as number | undefined)?.toString(),
  ],
  [
    AttributeType.FLOAT,
    (defaultValue) => (defaultValue as number | undefined)?.toString(),
  ],
  [
    AttributeType.BOOLEAN,
    (defaultValue) =>
      defaultValue === true ? "Yes" : defaultValue === false ? "No" : undefined,
  ],
  [
    AttributeType.DATETIME,
    (defaultValue) =>
      defaultValue
        ? format(defaultValue as Date, "d MMM yyyy, h:mm aa")
        : undefined,
  ],
]);

const CustomerAttributeAccordionForm: React.FC<
  CustomerAttributeAccordionFormProps
> = ({ customerId, attributeId, attributeName, attributeType }) => {
  const { contacts } = useAppSelector((state) => state.contacts);

  const customerAttribute:
    | CustomerAttributeDomain<AttributeValueType>
    | undefined = useMemo(() => {
    return contacts
      .find((c) => c.id === customerId)
      ?.getLatestAttributeWithAttributeId(attributeId);
  }, [contacts, customerId, attributeId]);
  const customerAttributeIdAndDefaultValue = customerAttribute
    ? { id: customerAttribute.id, defaultValue: customerAttribute.value }
    : undefined;

  const [isEditing, setIsEditing] = useState<boolean>(
    customerAttributeIdAndDefaultValue === undefined
  );

  const [attributeValue, setAttributeValue] = useState<AttributeValueType>(
    customerAttributeIdAndDefaultValue?.defaultValue
  );

  const FormComponent = formComponentsPerAttributeType[
    attributeType
  ] as React.FC<IndividualCustomerAttributeFormProps<AttributeValueType>>;

  return (
    <AttributeInputWrapper
      attributeName={attributeName}
      displayValue={displayValuesGettersPerAttributeType.get(attributeType)?.(
        customerAttributeIdAndDefaultValue?.defaultValue
      )}
      setIsEditing={setIsEditing}
      isEditing={isEditing}
    >
      <FormComponent
        customerId={customerId}
        customerAttributeIdAndDefaultValue={customerAttributeIdAndDefaultValue}
        attributeId={attributeId}
        onBlur={() => {
          setIsEditing(false);
        }}
        attributeValue={attributeValue as AttributeValueType}
        setAttributeValue={setAttributeValue}
      />
    </AttributeInputWrapper>
  );
};

export default memo(CustomerAttributeAccordionForm);
