import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";

import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import {
  AccordionItemContent,
  AccordionItemTrigger,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import { Tooltip } from "components/ui/tooltip";
import { format } from "date-fns";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { LuCheck, LuPenLine, LuX } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import SummaryService from "services/summary";

interface ContactNotesProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
}

const ContactNotes = ({ contactToDisplay, isExpanded }: ContactNotesProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { editContact, getContactTags } = useContactsStore();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [notesValue, setNotesValue] = useState<string>(
    contactToDisplay.notes || ""
  );
  const [defaultNotesValue, setDefaultNotesValue] = useState<{
    value: string;
  }>({
    value: notesValue || "",
  });

  useEffect(() => {
    setNotesValue(contactToDisplay.notes || "");
    setDefaultNotesValue({
      value: contactToDisplay.notes || "",
    });
  }, [contactToDisplay.notes]);

  const auth0Context = useAuth0();

  const createSummary = async () => {
    if (isLoading) return;
    let newSummary = null;

    try {
      setIsLoading(true);
      newSummary = await SummaryService.generateSummary(
        auth0Context,
        contactToDisplay!.id!,
        merchant.id
      );
      setIsEditing(true);
      setDefaultNotesValue({
        value: `${
          defaultNotesValue.value ? `${defaultNotesValue.value}\n\n` : ""
        }**Summary from ${format(
          new Date(),
          "dd/MM/yyyy, hh:mm a"
        )}**\n\n${newSummary}`,
      });
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error("Error creating summary", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveInfo = async (latestNotes: string) => {
    editContact({
      id: contactToDisplay!.id!,
      name: contactToDisplay.name || undefined,
      surname: contactToDisplay.surname || undefined,
      tags: getContactTags(contactToDisplay).map((tag: TagsDomain) => tag.tag),
      tagIds: contactToDisplay.tagIds,
      address: contactToDisplay.address?.country
        ? {
            first_line: contactToDisplay.address.firstLine,
            second_line: contactToDisplay.address.secondLine,
            city: contactToDisplay.address.city,
            country: contactToDisplay.address.country,
            state: contactToDisplay.address.state,
            postcode: contactToDisplay.address.postcode,
          }
        : undefined,
      channels: contactToDisplay.channels.map((c: CustomerChannelDomain) => ({
        id: c.id,
        type: c.type,
        handle: c.handle,
        is_active: c.isActive,
        is_subscribed: c.isSubscribed,
      })),
      notes: latestNotes,
    });

    setDefaultNotesValue({ value: latestNotes });
  };

  return (
    <>
      <AccordionItemTrigger>
        <HStack>
          <Icon
            as={LuPenLine}
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
          />
          <Text fontWeight="bold">Notes</Text>
        </HStack>
      </AccordionItemTrigger>
      <AccordionItemContent>
        <Flex
          justifyContent="center"
          alignItems="center"
          direction="column"
          px={4}
        >
          {isEditing && (
            <>
              <Box
                p={3}
                borderColor={`${colorScheme}.400`}
                borderWidth="1px"
                borderStyle="solid"
                borderRadius="md"
                w="100%"
                bgColor={colorMode === "dark" ? "black" : "gray.50"}
              >
                <EditorUltra
                  isDisabled={false}
                  defaultText={defaultNotesValue}
                  setText={(currentText: string) => {
                    setNotesValue(currentText);
                  }}
                  enabledPlugins={[
                    EditorUltraPlugin.AUTOFOCUS,
                    EditorUltraPlugin.RICHTEXT,
                  ]}
                />
                <Flex justifyContent="space-between" alignItems="center">
                  <IconButton
                    colorPalette="red"
                    variant="ghost"
                    size="xs"
                    aria-label="Cancel"
                    onClick={() => {
                      setIsEditing(false);
                      setNotesValue(defaultNotesValue.value);
                    }}
                  >
                    <Icon as={LuX} boxSize={3} />
                  </IconButton>
                  <IconButton
                    colorPalette="green"
                    variant="ghost"
                    aria-label="Save"
                    size="xs"
                    onClick={() => {
                      setIsEditing(false);
                      onSaveInfo(notesValue);
                    }}
                  >
                    <Icon as={LuCheck} boxSize={4} />
                  </IconButton>
                </Flex>
              </Box>
            </>
          )}
        </Flex>

        {!isEditing && notesValue && (
          <Tooltip
            content="Click to edit notes"
            disabled={contactToDisplay.isArchived}
          >
            <Box
              onClick={() => {
                if (contactToDisplay.isArchived) {
                  return;
                }
                setIsEditing(true);
              }}
              cursor={contactToDisplay.isArchived ? "default" : "pointer"}
              px={4}
            >
              <EditorUltra
                isPrivate={true}
                isEditable={false}
                defaultText={defaultNotesValue}
                setText={(currentText: string) => {
                  setNotesValue(currentText);
                }}
                enabledPlugins={[
                  EditorUltraPlugin.AUTOFOCUS,
                  EditorUltraPlugin.RICHTEXT,
                ]}
              />
            </Box>
          </Tooltip>
        )}

        {!isEditing && !notesValue && !contactToDisplay.isArchived && (
          <Flex justifyContent="center">
            <Button
              colorPalette={colorScheme}
              variant="ghost"
              color="gray.400"
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              {" "}
              Click here to add notes
            </Button>
          </Flex>
        )}

        {contactToDisplay.isArchived ? null : (
          <Flex justifyContent="center" mt={2}>
            <Button
              colorPalette={colorScheme}
              size="sm"
              onClick={() => createSummary()}
              disabled={isLoading}
            >
              {" "}
              Summarize conversation
            </Button>
          </Flex>
        )}
      </AccordionItemContent>
    </>
  );
};

export default ContactNotes;
