import { toaster } from "components/ui/toaster";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const useRedirectUnauthorized = () => {
  const navigate = useNavigate();
  const { merchant } = useAppSelector((state) => state.merchant);
  const timeoutRef = useRef<number | null>(null);

  const redirectToInbox = useCallback((
    customMessage: string = "You are not authorized to view this page",
  ) => {
    toaster.create({
      type: "error",
      title: customMessage,
    });

    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      navigate(`/${merchant.id}/inbox/`, { replace: true });
    }, 500);
  }, [merchant.id]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { redirectToInbox };
};

export default useRedirectUnauthorized;
