import { Auth0ContextInterface } from "@auth0/auth0-react";
import BookingsOutcomesCountDomain from "entities/domain/calendar/bookings-analytics";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import { BookingsOutcomesCountDTO } from "entities/dto/BookingsOutcomesCountDTO";
import { CalendarBookingDTO } from "entities/dto/CalendarDTO";
import bookingsOutcomesCountTransformFromDtoToDomain from "entities/transformers/bookings-outcomes-count-transformer";
import calendarTransformFromDtoToDomain from "entities/transformers/calendar-transformer";
import {
  CreateCalendarEventPayload,
  DeleteCalendarEventPayload,
  GetCalendarEventsPayload,
  UpdateCalendarEventPayload,
} from "redux/features/calendar";
import { RequestType } from "services/request-type";
import {
  deleteRequest,
  mutationRequest,
  putRequest,
  request,
} from "util/methods";

export default class CalendarsService {
  public static async getCalendarEvents(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: GetCalendarEventsPayload,
    merchantId: number
  ): Promise<CalendarBookingDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const calendarResponse = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/bookings?start_at=${payload.startAt}&end_at=${payload.endAt}&tz=${payload.timezone}`
    );

    const bookings: CalendarBookingDTO[] = calendarResponse.data;

    return bookings.map(calendarTransformFromDtoToDomain);
  }

  public static async createCalendarEvent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreateCalendarEventPayload,
    merchantId: number
  ): Promise<CalendarBookingDomain> {
    const accessToken = await getAccessTokenSilently();

    const calendarResponse = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/bookings`,
      {
        start_at: payload.startAt,
        end_at: payload.endAt,
        tz: payload.tz,
        title: payload.title,
        description: payload.description,
        vehicle_id: payload.vehicleId,
        agent_ids: payload.agentIds,
        customer_id: payload.customerId,
        notification_customer_channel_id: payload.notificationCustomerChannelId,
      }
    );

    const booking: CalendarBookingDTO = calendarResponse.data;

    return calendarTransformFromDtoToDomain(booking);
  }

  public static async updateCalendarEvent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateCalendarEventPayload,
    merchantId: number
  ): Promise<CalendarBookingDomain> {
    const accessToken = await getAccessTokenSilently();

    const calendarResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/bookings/${payload.bookingId}`,
      {
        start_at: payload.startAt,
        end_at: payload.endAt,
        tz: payload.tz,
        title: payload.title,
        description: payload.description,
        agent_ids: payload.agentIds,
        vehicle_id: payload.vehicleId,
        customer_id: payload.customerId,
        notify_customer: payload.notifyCustomer,
        notification_customer_channel_id: payload.notificationCustomerChannelId,
        outcome: payload.outcome,
      }
    );

    const booking: CalendarBookingDTO = calendarResponse.data;

    return calendarTransformFromDtoToDomain(booking);
  }

  public static async deleteCalendarEvent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteCalendarEventPayload,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/bookings/${payload.bookingId}`
    );
  }

  public static async getBookingsOutcomesCount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<BookingsOutcomesCountDomain> {
    const accessToken = await getAccessTokenSilently();
    const queryParams = new URLSearchParams();

    queryParams.append("date_from", dateFrom);
    queryParams.append("date_to", dateTo);

    const queryString = queryParams.toString();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/analytics/bookings?${queryString}`
    );

    const bookingsOutcomesCount: BookingsOutcomesCountDTO = data.data;

    return bookingsOutcomesCountTransformFromDtoToDomain(bookingsOutcomesCount);
  }
}
