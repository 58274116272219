import { Input } from "@chakra-ui/react";
import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import { Field } from "components/ui/field";
import React, { useState } from "react";
import { ChannelHandleType } from ".";

type EmailChannelProps = {
  setHandle: (value: ChannelHandleType | null) => void;
};

const EmailChannel = ({ setHandle }: EmailChannelProps) => {
  const [email, setEmail] = useState("");
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );

  const isValidEmail = (emailValue: string) => {
    return emailValue && emailValue !== "" && /\S+@\S+\.\S+/.test(emailValue);
  };

  const handleBlurEmail = () => {
    return isValidEmail(email)
      ? setWarning(undefined)
      : setWarning({
          key: "invalid_email",
          description: "Invalid email address.",
        });
  };

  const handleChangeEmail = (value: React.FormEvent<HTMLInputElement>) => {
    const newEmailValue = value.currentTarget.value;
    setEmail(newEmailValue);
    if (newEmailValue && isValidEmail(newEmailValue)) {
      setHandle({ identifier: newEmailValue });
    } else {
      setHandle(null);
    }
  };

  return (
    <Field label="Email" required>
      {warning && warning.key === "invalid_email" && (
        <WarningTextComponent isValid={false} text={warning.description} />
      )}
      <Input
        type="email"
        borderRadius="18px"
        placeholder="Enter email address"
        onChange={handleChangeEmail}
        value={email}
        autoFocus={true}
        onBlur={handleBlurEmail}
      />
    </Field>
  );
};

export default EmailChannel;
