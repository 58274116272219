import ReplySuggestionDomain, {
  ReplySuggestionStatus,
} from "entities/domain/reply_suggestion";
import { ReplySuggestionDTO } from "entities/dto/ReplySuggestionDTO";

export function replySuggestionTransformFromDtoToDomain(
  r: ReplySuggestionDTO
): ReplySuggestionDomain {
  let status: ReplySuggestionStatus;

  if (r.status === "queued" || r.status === "in_progress") {
    status = ReplySuggestionStatus.IN_PROGRESS;
  } else if (r.status === "generated") {
    status = ReplySuggestionStatus.GENERATED;
  } else if (r.status === "seen") {
    status = ReplySuggestionStatus.SEEN;
  } else if (r.status === "failed") {
    status = ReplySuggestionStatus.FAILED;
  } else {
    status = ReplySuggestionStatus.UNKNOWN;
  }

  return new ReplySuggestionDomain(
    r.id,
    r.conversation_id,
    status,
    r.reply_to_message_id,
    r.body,
    r.created_at
  );
}
