import { HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from "components/ui/hover-card";
import {
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";

import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";

interface Tier {
  description: string;
  name: string;
  isActive: boolean;
}

interface TiersBarProps {
  tiers: Tier[];
}

const TiersBar = ({ tiers }: TiersBarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [isActiveTierOpen, setIsActiveTierOpen] = useState<boolean>(true);

  return (
    <HStack
      mt={isBaseSize ? 2 : 4}
      gap={0}
      width="100%"
      borderRadius="full"
      bgGradient="to-r"
      gradientFrom={`yellow.${colorMode === "dark" ? "200" : "500"}`}
      gradientTo={`green.${colorMode === "dark" ? "200" : "500"}`}
    >
      {tiers.map((tier, index) =>
        tier.isActive ? (
          <PopoverRoot
            closeOnInteractOutside={false}
            closeOnEscape={false}
            defaultOpen={tier.isActive}
            key={tier.name}
            positioning={{ placement: "bottom" }}
            {...(tier.isActive
              ? {
                  isOpen: isActiveTierOpen,
                  onClose: () => setIsActiveTierOpen(false),
                  onOpen: () => setIsActiveTierOpen(true),
                }
              : {})}
          >
            <PopoverTrigger asChild>
              <HStack
                cursor="pointer"
                w="100%"
                mr={index !== tiers.length - 1 ? "-2px" : 0}
                justifyContent="center"
                borderColor={
                  tier.isActive
                    ? colorMode === "dark"
                      ? `${colorScheme}.400`
                      : `${colorScheme}.500`
                    : "transparent"
                }
                borderWidth="2px"
                borderLeftRadius={index === 0 ? "full" : 0}
                borderRightRadius={index === tiers.length - 1 ? "full" : 0}
                _hover={{
                  borderColor:
                    colorMode === "dark"
                      ? `${colorScheme}.400`
                      : `${colorScheme}.500`,
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              >
                <Text
                  fontSize="xs"
                  color={colorMode === "dark" ? "black" : "white"}
                >
                  {tier.name}
                </Text>
              </HStack>
            </PopoverTrigger>
            <PopoverContent
              borderRadius="xl"
              bg={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
              border="none"
            >
              <PopoverBody color={colorMode === "dark" ? "black" : "white"}>
                {tier.description}
              </PopoverBody>
            </PopoverContent>
          </PopoverRoot>
        ) : (
          <HoverCardRoot positioning={{ placement: "top" }}>
            <HoverCardTrigger asChild>
              <HStack
                cursor="pointer"
                w="100%"
                mr={index !== tiers.length - 1 ? "-2px" : 0}
                justifyContent="center"
                borderColor={
                  tier.isActive
                    ? colorMode === "dark"
                      ? `${colorScheme}.400`
                      : `${colorScheme}.500`
                    : "transparent"
                }
                borderWidth="2px"
                borderLeftRadius={index === 0 ? "full" : 0}
                borderRightRadius={index === tiers.length - 1 ? "full" : 0}
                _hover={{
                  borderColor:
                    colorMode === "dark"
                      ? `${colorScheme}.400`
                      : `${colorScheme}.500`,
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              >
                <Text
                  fontSize="xs"
                  color={colorMode === "dark" ? "black" : "white"}
                >
                  {tier.name}
                </Text>
              </HStack>
            </HoverCardTrigger>
            <HoverCardContent>
              <HoverCardArrow />
              {tier.description}
            </HoverCardContent>
          </HoverCardRoot>
        )
      )}
    </HStack>
  );
};

export default TiersBar;
