import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex, Icon, Spinner, Text, VStack } from "@chakra-ui/react";

import { ReactComponent as TagRemoveIcon } from "assets/icons/tag-minus-outline.svg";
import { ReactComponent as TagAddIcon } from "assets/icons/tag-plus-outline.svg";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import {
  ActionBarContent,
  ActionBarRoot,
  ActionBarSelectionTrigger,
  ActionBarSeparator,
} from "components/ui/action-bar";

import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import React, { useEffect, useState } from "react";
import {
  LuCheck,
  LuCopy,
  LuCopyCheck,
  LuEye,
  LuEyeOff,
  LuLock,
  LuLockOpen,
  LuPointer,
  LuPointerOff,
  LuSquare,
  LuUsersRound,
  LuX,
} from "react-icons/lu";

import { batch } from "react-redux";
import {
  OpenClosedFilter,
  clearSelectedConversations,
  disableBulkActionsToolbar,
  enableBulkActionsToolbar,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  updateConversationIsOpen,
  updateConversationIsRead,
  updateConversationIsSubscribed,
  updateConversationSelection,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import InboxService from "services/inbox";
import {
  MAX_ALLOWED_BULK_SELECTION,
  MAX_SAFE_SELECTION_AMOUNT,
} from "util/constants";
import { getChannelsFromConversations } from "util/methods";

interface BulkActionsProps {
  openAssignModal: () => void;
  openUpdateTagsModal: (isRemoval: boolean) => void;
}

const BulkActions = ({
  openAssignModal,
  openUpdateTagsModal,
}: BulkActionsProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const {
    selectedConversationIds,
    conversations,
    isOpenOrClosed,
    bulkActionsToolbarEnabled,
    bulkActionsToolbarLoading,
  } = useAppSelector((state) => state.conversations);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [shouldShowConfirmation, setShouldShowConfirmation] =
    useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] = useState<() => void>(
    () => () => {}
  );
  const [selectedConversationsChannels, setSelectedConversationsChannels] =
    useState<string[]>(
      getChannelsFromConversations(conversations, selectedConversationIds)
    );

  useEffect(() => {
    setSelectedConversationsChannels(
      getChannelsFromConversations(conversations, selectedConversationIds)
    );
  }, [selectedConversationIds, conversations]);

  const markAllAsReadOrUnread = async (shouldMarkRead: boolean) => {
    try {
      const updatedConversationIds = await InboxService.bulkMarkReadOrUnread(
        auth0Context,
        merchant.id,
        selectedConversationIds,
        shouldMarkRead
      );

      if (updatedConversationIds.length === 0) {
        toaster.create({
          type: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      batch(() => {
        updatedConversationIds.forEach((conversationId) => {
          dispatch(
            updateConversationIsRead({
              conversationId,
              isRead: shouldMarkRead,
            })
          );
        });
        dispatch(clearSelectedConversations());
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const bulkSubscribeOrUnsubscribe = async (shouldSubscribe: boolean) => {
    try {
      const updatedChannelIds =
        await ContactsService.bulkUpdateCustomerChannels(
          auth0Context,
          merchant.groupId,
          selectedConversationsChannels,
          shouldSubscribe
        );

      if (updatedChannelIds.length === 0) {
        toaster.create({
          type: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      batch(() => {
        selectedConversationIds.forEach((conversationId) => {
          dispatch(
            updateConversationIsSubscribed({
              conversationId,
              isSubscribed: shouldSubscribe,
            })
          );
        });
        dispatch(clearSelectedConversations());
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const openOrCloseAll = async () => {
    try {
      const shouldOpen = isOpenOrClosed === OpenClosedFilter.Closed;
      const updatedConversationIds =
        await InboxService.bulkOpenOrCloseConversations(
          auth0Context,
          merchant.id,
          selectedConversationIds,
          shouldOpen
        );

      if (updatedConversationIds.length === 0) {
        toaster.create({
          type: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      batch(() => {
        updatedConversationIds.forEach((conversationId) => {
          dispatch(
            updateConversationIsOpen({
              conversationId,
              isOpen: shouldOpen,
            })
          );
        });
        dispatch(clearSelectedConversations());
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const handleBulkReadOrUnread = (shouldMarkRead: boolean) => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    markAllAsReadOrUnread(shouldMarkRead).finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  const handleBulkOpenOrClose = () => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    openOrCloseAll().finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  return (
    <ActionBarRoot
      open={selectedConversationIds.length > 0}
      closeOnInteractOutside={false}
    >
      <ActionBarContent
        position="relative"
        bgColor={`${colorScheme}.solid`}
        py={0}
        pr={0}
        pl={3}
        overflow="hidden"
      >
        {!bulkActionsToolbarLoading ? null : (
          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            left={0}
            top={0}
            width="100%"
            height="100%"
            zIndex={2}
          >
            <Spinner />
          </Flex>
        )}
        <ActionBarSelectionTrigger
          my={3}
          borderColor={colorMode === "dark" ? "black" : "white"}
        >
          <VStack height="100%" gap={0} p={2} justifyContent="center">
            <Text
              fontSize="xl"
              color={colorMode === "dark" ? "black" : "white"}
            >
              {selectedConversationIds.length}
            </Text>
            <Text
              fontSize="md"
              color={colorMode === "dark" ? "black" : "white"}
            >
              Selected
            </Text>
          </VStack>
        </ActionBarSelectionTrigger>
        <ActionBarSeparator bg={colorMode === "dark" ? "black" : "white"} />
        <Button
          py={3}
          height="100%"
          borderRadius={0}
          display="flex"
          colorPalette={colorScheme}
          className="dark"
          variant="ghost"
          flexDirection="column"
          justifyContent="space-between"
          disabled={!bulkActionsToolbarEnabled}
          gridGap={2}
          color={colorMode === "dark" ? "black" : "white"}
          onClick={() => {
            if (
              selectedConversationIds.length ===
              conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
            ) {
              dispatch(clearSelectedConversations());

              return;
            }

            batch(() => {
              conversations.slice(0, 100).forEach((c) => {
                dispatch(
                  updateConversationSelection({
                    conversationId: c.id,
                    isSelected: true,
                  })
                );
              });
            });
          }}
        >
          <Icon
            as={
              selectedConversationIds.length ===
              conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
                ? LuCopyCheck
                : LuCopy
            }
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          {selectedConversationIds.length ===
          conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
            ? "Deselect All"
            : "Select All"}
        </Button>
        <Button
          py={3}
          height="100%"
          borderRadius={0}
          colorPalette={colorScheme}
          className="dark"
          variant="ghost"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gridGap={2}
          color={colorMode === "dark" ? "black" : "white"}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(() => () => {
                dispatch(disableBulkActionsToolbar());
                openUpdateTagsModal(false);
              });
              setShouldShowConfirmation(true);

              return;
            }

            dispatch(disableBulkActionsToolbar());
            openUpdateTagsModal(false);
          }}
        >
          <Icon
            as={TagAddIcon}
            w={6}
            h={6}
            fill={colorMode === "dark" ? "gray.800" : "white"}
          />
          Add Tag
        </Button>
        <Button
          height="100%"
          py={3}
          borderRadius={0}
          display="flex"
          colorPalette={colorScheme}
          className="dark"
          variant="ghost"
          flexDirection="column"
          justifyContent="space-between"
          gridGap={2}
          color={colorMode === "dark" ? "black" : "white"}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(() => () => {
                dispatch(disableBulkActionsToolbar());
                openUpdateTagsModal(true);
              });
              setShouldShowConfirmation(true);

              return;
            }

            dispatch(disableBulkActionsToolbar());
            openUpdateTagsModal(true);
          }}
        >
          <Icon
            as={TagRemoveIcon}
            w={6}
            h={6}
            fill={colorMode === "dark" ? "gray.800" : "white"}
          />
          Remove Tag
        </Button>
        <Button
          height="100%"
          borderRadius={0}
          py={3}
          display="flex"
          colorPalette={colorScheme}
          flexDirection="column"
          className="dark"
          variant="ghost"
          justifyContent="space-between"
          gridGap={2}
          color={colorMode === "dark" ? "black" : "white"}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(() => () => {
                dispatch(disableBulkActionsToolbar());
                openAssignModal();
              });
              setShouldShowConfirmation(true);

              return;
            }

            dispatch(disableBulkActionsToolbar());
            openAssignModal();
          }}
        >
          <Icon
            as={LuUsersRound}
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          Assign to
        </Button>
        <Button
          height="100%"
          borderRadius={0}
          py={3}
          colorPalette={colorScheme}
          className="dark"
          variant="ghost"
          display="flex"
          color={colorMode === "dark" ? "black" : "white"}
          flexDirection="column"
          justifyContent="space-between"
          gridGap={2}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(() => () => handleBulkOpenOrClose());
              setShouldShowConfirmation(true);

              return;
            }

            handleBulkOpenOrClose();
          }}
        >
          <Icon
            as={isOpenOrClosed === OpenClosedFilter.Open ? LuLock : LuLockOpen}
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          {isOpenOrClosed === OpenClosedFilter.Open ? "Close" : "Open"}
        </Button>
        <Button
          height="100%"
          borderRadius={0}
          py={3}
          display="flex"
          className="dark"
          variant="ghost"
          colorPalette={colorScheme}
          flexDirection="column"
          justifyContent="space-between"
          gridGap={2}
          color={colorMode === "dark" ? "black" : "white"}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(() => () => handleBulkReadOrUnread(true));
              setShouldShowConfirmation(true);

              return;
            }

            handleBulkReadOrUnread(true);
          }}
        >
          <Icon
            as={LuEye}
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          Read
        </Button>
        <Button
          height="100%"
          borderRadius={0}
          py={3}
          display="flex"
          colorPalette={colorScheme}
          flexDirection="column"
          className="dark"
          variant="ghost"
          justifyContent="space-between"
          color={colorMode === "dark" ? "black" : "white"}
          gridGap={2}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(() => () => handleBulkReadOrUnread(false));
              setShouldShowConfirmation(true);

              return;
            }

            handleBulkReadOrUnread(false);
          }}
        >
          <Icon
            as={LuEyeOff}
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          Unread
        </Button>
        <Button
          height="100%"
          borderRadius={0}
          py={3}
          display="flex"
          colorPalette={colorScheme}
          flexDirection="column"
          className="dark"
          variant="ghost"
          color={colorMode === "dark" ? "black" : "white"}
          justifyContent="space-between"
          gridGap={2}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            bulkSubscribeOrUnsubscribe(true);
          }}
        >
          <Icon
            as={LuPointer}
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          Subscribe
        </Button>
        <Button
          height="100%"
          borderRadius={0}
          display="flex"
          py={3}
          colorPalette={colorScheme}
          flexDirection="column"
          justifyContent="space-between"
          className="dark"
          color={colorMode === "dark" ? "black" : "white"}
          variant="ghost"
          gridGap={2}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
              setConfirmationAction(
                () => () => bulkSubscribeOrUnsubscribe(false)
              );
              setShouldShowConfirmation(true);

              return;
            }

            bulkSubscribeOrUnsubscribe(false);
          }}
        >
          <Icon
            as={LuPointerOff}
            w={6}
            h={6}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
          Unsubscribe
        </Button>

        <ConfirmationDialog
          isOpen={shouldShowConfirmation}
          setIsOpen={setShouldShowConfirmation}
          headerText="Are you sure about this?"
          messageText={`You're about to do a bulk action over ${selectedConversationIds.length} conversations.`}
          buttonText="Yes"
          confirmationCallback={() => {
            confirmationAction();
            setShouldShowConfirmation(false);
            setConfirmationAction(() => () => {});
          }}
        />
        <Button
          borderLeftWidth="1px"
          borderLeftStyle="solid"
          borderLeftColor={colorMode === "dark" ? "gray.800" : "white"}
          height="100%"
          borderRadius={0}
          colorPalette={colorScheme}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          py={3}
          color={colorMode === "dark" ? "black" : "white"}
          className="dark"
          variant="ghost"
          gridGap={2}
          disabled={!bulkActionsToolbarEnabled}
          onClick={() => {
            dispatch(clearSelectedConversations());
          }}
        >
          <Icon
            as={LuX}
            w={4}
            h={4}
            mx={2}
            color={colorMode === "dark" ? "gray.800" : "white"}
          />
        </Button>
      </ActionBarContent>
    </ActionBarRoot>
  );
};

export default BulkActions;
