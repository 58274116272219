import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import FullTemplateDomain from "entities/domain/templates/full_template";
import WhatsappTemplate from "entities/domain/whatsapp-template";

export interface CreateTemplatePayload {
  title: string;
  text: string;
  channels: string[];
  favourite: boolean;
  shortcut: string | null;
  subject: string | null;
  category: string | null;
  file?: File;
  mediaUrl?: string | null;
  mediaType?: string | null;
}

export enum TemplateFilter {
  WHATSAPP = "whatsapp",
  REGULAR = "regular",
}

export interface EditTemplatePayload {
  id: string;
  title: string;
  text: string;
  channels: string[];
  favourite: boolean;
  shortcut: string | null;
  subject: string | null;
  category: string | null;
  file?: File;
  mediaType?: string | null;
  mediaUrl?: string | null;
}

export interface SyncTemplatePayload {
  id: string;
}

export interface DeleteTemplatePayload {
  id: string;
}

export interface AbstractTemplate {
  getWholeText: () => string;
  getMedia: () => { type: string; url: string } | null;
  canEdit: () => boolean;
  isGeneric: () => boolean;
  isCustom: () => boolean;
  getWhatsAppStatusColor: () => string;
  isWhatsappTemplate: () => boolean;
  isRegularTemplate: () => boolean;
}

export type RegularOrWhatsappTemplate = FullTemplateDomain | WhatsappTemplate;

interface TemplatesState {
  isLoading: boolean;
  modalLoading: boolean;
  activeTemplateFilter: TemplateFilter | undefined;
  showPendingDeletion: boolean;
  errors: any[];
  templates: RegularOrWhatsappTemplate[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}
const initialState: TemplatesState = {
  templates: [],
  isLoading: true,
  modalLoading: false,
  activeTemplateFilter: TemplateFilter.WHATSAPP,
  showPendingDeletion: false,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

export const appendOrUpdateTemplate = <T extends RegularOrWhatsappTemplate>(
  template: T,
  templates: T[]
): T[] => {
  const exists = templates.some((t) => t.id === template.id);

  if (exists) {
    return templates.map((t) => (template.id === t.id ? template : t));
  }

  return [template, ...templates];
};

const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    fetchTemplates: (state) => {
      state.isLoading = true;
    },
    fetchTemplatesSuccess: (
      state,
      action: PayloadAction<FullTemplateDomain[] | WhatsappTemplate[]>
    ) => {
      state.isLoading = false;
      state.templates = action.payload;
      state.errors = [];
      state.toastMessage = {
        new: false,
        success: "",
        errors: [],
      };
    },
    fetchTemplatesFail: (state, action: PayloadAction<any[]>) => {
      state.errors = action.payload;
      state.isLoading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    setactiveTemplateFilter: (state, action: PayloadAction<TemplateFilter>) => {
      state.activeTemplateFilter = action.payload;
    },
    createTemplate: (state) => {
      state.modalLoading = true;
    },
    createTemplateSuccess: (state) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "Template created successfully!",
        errors: [],
        new: true,
      };
      state.errors = [];
    },
    createTemplateFail: (state, action: PayloadAction<any[]>) => {
      state.modalLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    editTemplate: (state) => {
      state.modalLoading = true;
    },
    propagateTemplateUpdate: (
      state,
      action: PayloadAction<RegularOrWhatsappTemplate>
    ) => {
      state.templates = appendOrUpdateTemplate(action.payload, state.templates);
    },
    editTemplateSuccess: (state) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "Template updated successfully!",
        errors: [],
        new: true,
      };
      state.errors = [];
    },
    editTemplateFail: (state, action: PayloadAction<any[]>) => {
      state.modalLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    deleteTemplate: (state) => {
      state.isLoading = true;
    },
    deleteTemplateSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload
      );
      state.toastMessage = {
        success: "Template deleted successfully!",
        errors: [],
        new: true,
      };
      state.errors = [];
    },
    deleteTemplateFail: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    resetStore(state) {
      state.templates = [];
      state.isLoading = true;
      state.modalLoading = false;
      state.activeTemplateFilter = TemplateFilter.WHATSAPP;
      state.showPendingDeletion = false;
      state.errors = [];
      state.toastMessage = {
        new: false,
        success: "",
        errors: [],
      };
    },
    setShowPendingDeletion: (state, action: PayloadAction<boolean>) => {
      state.showPendingDeletion = action.payload;
    },
  },
});

export const {
  fetchTemplates,
  fetchTemplatesSuccess,
  fetchTemplatesFail,
  createTemplate,
  createTemplateSuccess,
  createTemplateFail,
  editTemplate,
  editTemplateSuccess,
  editTemplateFail,
  deleteTemplate,
  deleteTemplateSuccess,
  deleteTemplateFail,
  propagateTemplateUpdate,
  setactiveTemplateFilter,
  resetStore,
  setShowPendingDeletion,
} = templateSlice.actions;

export default templateSlice.reducer;
