import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonGroup, Icon, Text } from "@chakra-ui/react";
import ImportContacts from "components/modals/import-contacts/ImportContacts";
import { useColorMode } from "components/ui/color-mode";
import React, { useState } from "react";
import { LuDownload, LuImport } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import { canImportContacts } from "util/permissions";
import ExportContactsModal from "../../ExportContactsModal";

interface ImportExportProps {}

const ImportExportButtons: React.FC<ImportExportProps> = (
  _props: ImportExportProps
) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  const [isUploadContacts, setIsUploadContacts] = useState<boolean>(false);
  const [isExportingCsv, setIsExportingCsv] = useState<boolean>(false);
  const [showExportContactsModal, setShowExportContactsModal] =
    useState<boolean>(false);

  const handleExportCsv = async (
    unsubOnly?: boolean,
    from?: Date,
    to?: Date
  ) => {
    setIsExportingCsv(true);

    try {
      await ContactsService.exportCustomersAsCsv(
        auth0Context,
        merchant.groupId,
        unsubOnly,
        from,
        to
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Error exporting contacts as CSV", e);
    } finally {
      setIsExportingCsv(false);
    }
  };

  return (
    <>
      <ButtonGroup attached colorPalette="gray">
        <Button
          onClick={() => setIsUploadContacts(true)}
          disabled={!canImportContacts(merchant.id, currentAgent!)}
        >
          <Icon
            as={LuImport}
            color={colorMode === "dark" ? "gray.900" : "white"}
          />
          <Text>Import</Text>
        </Button>
        <Button
          onClick={() => setShowExportContactsModal(true)}
          disabled={!canImportContacts(merchant.id, currentAgent!)}
        >
          <Icon
            as={LuDownload}
            color={colorMode === "dark" ? "gray.900" : "white"}
          />
          <Text>Export</Text>
        </Button>
      </ButtonGroup>
      <ExportContactsModal
        handleExportAll={(from?: Date | null, to?: Date | null) =>
          handleExportCsv(false, from!, to!)
        }
        handleExportUnsub={(from?: Date | null, to?: Date | null) =>
          handleExportCsv(true, from!, to!)
        }
        isOpen={showExportContactsModal}
        isExportingCsv={isExportingCsv}
        setIsOpen={setShowExportContactsModal}
      />
      <ImportContacts
        isOpen={isUploadContacts}
        onClose={() => setIsUploadContacts(false)}
      />
    </>
  );
};

export default ImportExportButtons;
