import { Box, Button, Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "components/shared/LazyPhoneInput";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import ContactFormService from "services/contactForm";
import getErrorDescription from "services/errorCodeConverter";
import * as yup from "yup";
import {
  ContactFormConfigProps,
  ContactFormInputConfigProps,
  parseContactFormConfig,
} from "./ContactFormConfig";
import Header from "./Header";
import Input from "./Input";
import PhoneInput from "./PhoneInput";
import PoweredByFooter from "./PoweredByFooter";
import UnexpectedError from "./UnexpectedError";

type Inputs = {
  first_name: string;
  last_name: string;
  phone_number: string;
  message: string;
  email: string;
};

// I shouldn't be proud of if but as we have a single repository and a single application
// we have to hack the global styling :P. Lazy loading prevents these css styles
// from leaking into the main platform.
const ContactFormStyles = React.lazy(() => import("./ContactFormStyles"));

const ContactFormSchema = yup.object().shape(
  {
    first_name: yup
      .string()
      .required("First Name is required.")
      .min(2, "Invalid First Name."),
    last_name: yup
      .string()
      .required("Last Name is required.")
      .min(2, "Invalid Last Name."),
    phone_number: yup
      .string()
      .test(
        "valid_phone_number",
        "Invalid Phone Number. Country code required (e.g +44).",
        (value) => {
          try {
            return value ? isValidPhoneNumber(value) : true;
          } catch (e) {
            return false;
          }
        }
      )
      .when("email", {
        is: (email: string) => !!email,
        then: (schema) => schema,
        otherwise: (schema) => schema.required("Phone Number is required."),
      }),
    email: yup
      .string()
      .email()
      .when("phone_number", {
        is: (phone: string) => !!phone,
        then: (schema) => schema,
        otherwise: (schema) => schema.required(),
      }),
    message: yup
      .string()
      .required("Message is required.")
      .min(5, "Invalid Message."),
  },
  [["email", "phone_number"]]
);

export default function ContactForm() {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const recaptchaRef = React.useRef<ReCAPTCHA>({} as ReCAPTCHA);

  const [formConfig, setFormConfig] = useState<ContactFormConfigProps | null>(
    null
  );
  const [formInputConfig, setFormInputConfig] =
    useState<ContactFormInputConfigProps | null>(null);
  // ideally we would rely on the isSubmitting coming from react-hook-form, however
  // google captcha does not provide a callback when the captcha challenge modal
  // is closed without a formal result, causing the form to stuck on isLoading state.
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [unexpectedError, setUnexpectedError] = useState<string>();

  const query_params = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    // @ts-ignore
    resolver: yupResolver(ContactFormSchema), // @ts-ignore
  });

  useEffect(() => {
    let config: ContactFormConfigProps | null = null;
    let inputConfig: ContactFormInputConfigProps | null = null;
    try {
      [config, inputConfig] = parseContactFormConfig(query_params);
    } catch (e) {
      // misconfiguration
    }

    if (config && config.merchantId) {
      setFormConfig(config);
      setFormInputConfig(inputConfig);
    } else {
      setUnexpectedError(
        getErrorDescription("contact_form_not_properly_set_up")
      );
    }
  }, []);

  const getCaptchaToken = async () => {
    const token = await recaptchaRef?.current?.executeAsync();
    setIsSubmitting(true);

    // it is necessary to reset captcha component so that it is ready for the next challenge
    // should it be necessary.
    recaptchaRef?.current?.reset();

    return token;
  };

  const formRef = useRef<any>(null);

  const fadeoutFormAndRedirectToSuccess = () => {
    if (formRef.current) {
      formRef.current.style.transition = "opacity 1s";
      formRef.current.style.opacity = "0";
    }
    setTimeout(() => {
      navigate({
        pathname: "/public/contact-form/success",
        search: `?config=${JSON.stringify(formConfig)}`,
      });
    }, 700);
  };

  const submitEnquiry: SubmitHandler<Inputs> = async ({
    first_name: name,
    last_name: surname,
    phone_number,
    email,
    message: body,
  }) => {
    const token = await getCaptchaToken();

    const success =
      token &&
      (await ContactFormService.submitEnquiry(token, {
        merchant_id: formConfig!.merchantId,
        name,
        surname,
        phone_number: phone_number.replaceAll(" ", ""),
        email,
        body,
      }));
    if (success) {
      fadeoutFormAndRedirectToSuccess();
    } else {
      setUnexpectedError(
        getErrorDescription("error_while_submitting_contact_form")
      );
      setIsSubmitting(false);
    }
  };

  let emailError;

  if (errors.email && errors.email.message) {
    emailError = {
      ...errors.email,
      message:
        errors.email.message[0].toUpperCase() + errors.email.message.slice(1),
    };
  }

  return (
    <Box w="100vw">
      <Flex
        data-state="open"
        _open={{
          animationName: "fade-in, scale-in",
          animationDuration: "300ms",
        }}
        as="form"
        onSubmit={handleSubmit(submitEnquiry)}
        w="100%"
        maxW="1000px"
        mx="auto"
        px={{ base: "1", sm: "3", md: "10" }}
        pb="5"
        flexDir="column"
        color={formConfig?.textColor || "#2b2e54"}
        bg={formConfig?.bgColor || "gray.50"}
        borderRadius={8}
        ref={formRef}
      >
        <Header {...formConfig} />
        <Stack flex="1" gap={formConfig?.compactVersion ? 2 : 6}>
          {unexpectedError && <UnexpectedError message={unexpectedError} />}

          <SimpleGrid
            minChildWidth="200px"
            gap={formConfig?.compactVersion ? 1 : 2}
          >
            <Input
              colorPalette={colorScheme}
              id="first_name"
              label="First Name"
              {...formInputConfig}
              {...register("first_name")}
              error={errors.first_name}
            />
            <Input
              colorPalette={colorScheme}
              id="last_name"
              label="Last Name"
              usePlaceholders={formConfig?.usePlaceholders}
              borderColor={formConfig?.borderColor}
              {...formInputConfig}
              {...register("last_name")}
              error={errors.last_name}
            />
          </SimpleGrid>

          <PhoneInput
            id="phone_number"
            label="Phone Number"
            {...formInputConfig}
            {...register("phone_number")}
            error={errors.phone_number}
          />

          <Input
            colorPalette={colorScheme}
            id="email"
            label="Email Address"
            usePlaceholders={formConfig?.usePlaceholders}
            borderColor={formConfig?.borderColor}
            {...formInputConfig}
            {...register("email")}
            error={emailError}
          />

          <Input
            colorPalette={colorScheme}
            as="textarea"
            id="message"
            label="Message"
            {...formInputConfig}
            borderRadius="3xl"
            py="2"
            h={formConfig?.compactVersion ? 16 : 24}
            {...register("message")}
            error={errors.message}
          />
        </Stack>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={
            process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY || "undefined"
          }
          size="invisible"
        />
        <Button
          type="submit"
          borderRadius="full"
          colorPalette={formConfig?.buttonColorScheme ?? colorScheme}
          mt={formConfig?.compactVersion ? 2 : 10}
          loading={isSubmitting}
          disabled={!formConfig}
        >
          Send
        </Button>
        {!formConfig?.noFooter && (
          <Box pt="4">
            <PoweredByFooter />
          </Box>
        )}
      </Flex>
      <React.Suspense>
        <ContactFormStyles />
      </React.Suspense>
    </Box>
  );
}
