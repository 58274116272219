import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Flex,
  Separator,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Switch } from "components/ui/switch";
import { toaster } from "components/ui/toaster";
import AutomationDomain from "entities/domain/automation";
import useAnalytics from "hooks/use-analytics";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import AutomationsService from "services/automations";
import Execute from "./Execute";
import Repeat from "./Repeat";
import Trigger from "./Trigger";
import WaitUntil from "./WaitUntil";

interface AutomationFormProps {
  isOpen: boolean;
  onClose: () => void;
  automation: AutomationDomain;
  handleAutomationUpdate: (a: AutomationDomain) => void;
}

const AutomationForm = ({
  isOpen,
  onClose,
  automation,
  handleAutomationUpdate,
}: AutomationFormProps) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { track } = useAnalytics();
  const [updatedAutomation, setUpdatedAutomation] =
    useState<AutomationDomain>(automation);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    setUpdatedAutomation(automation);
  }, [automation]);

  return (
    <>
      <DialogRoot
        open={isOpen}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
          }
        }}
        size={isBaseSize ? "full" : "md"}
      >
        <DialogBackdrop />
        <DialogContent px="20px">
          <DialogHeader fontSize="xl" fontWeight={700}>
            <Text>Set up your automation</Text>
          </DialogHeader>
          <DialogCloseTrigger />

          <Separator />

          <DialogBody px={0} mt={5}>
            <Trigger automation={updatedAutomation} />
            <Execute
              automation={updatedAutomation}
              setUpdatedAutomation={setUpdatedAutomation}
            />
            <WaitUntil
              automation={updatedAutomation}
              setUpdatedAutomation={setUpdatedAutomation}
            />
            {updatedAutomation.canRepeat() ? (
              <>
                <Repeat
                  automation={updatedAutomation}
                  setUpdatedAutomation={setUpdatedAutomation}
                />
                <Separator mt={5} />
              </>
            ) : null}
            <Flex mt={5} mx={6} alignItems="center">
              <Switch
                mr={3}
                checked={updatedAutomation.enabled}
                css={{
                  display: "flex",
                  alignSelf: "center",
                }}
                colorPalette="green"
                size="lg"
                onCheckedChange={(e) => {
                  setUpdatedAutomation(
                    new AutomationDomain(
                      updatedAutomation.id,
                      updatedAutomation.name,
                      updatedAutomation.description,
                      updatedAutomation.merchantId,
                      updatedAutomation.triggerType,
                      updatedAutomation.time,
                      updatedAutomation.frequency,
                      updatedAutomation.task,
                      updatedAutomation.templateId,
                      e.checked
                    )
                  );
                }}
              />
              <Text
                color={updatedAutomation.enabled ? "mediumspringgreen" : "red"}
              >
                {updatedAutomation.enabled ? "Enabled" : "Disabled"}
              </Text>
            </Flex>
          </DialogBody>

          <DialogFooter>
            <Button
              colorPalette={colorScheme}
              onClick={() => {
                if (updatedAutomation) {
                  track("edit_automation_attempt", {
                    automation_id: updatedAutomation.id,
                  });
                  AutomationsService.editAutomation(
                    auth0Context,
                    updatedAutomation
                  ).then(
                    (res) => {
                      if (!res) {
                        track("edit_automation_attempt_failed", {
                          automation_id: updatedAutomation.id,
                        });
                        toaster.create({
                          type: "error",
                          title: "Failed Updating Automation",
                        });
                        onClose();

                        return;
                      }

                      track("edit_automation_attempt_succeeded", {
                        automation_id: updatedAutomation.id,
                      });
                      toaster.create({
                        type: "success",
                        title: "Automation Updated Successfully",
                      });

                      handleAutomationUpdate(res);
                      onClose();
                    },
                    (reason) => {
                      track("edit_automation_attempt_failed", {
                        automation_id: updatedAutomation.id,
                      });

                      toaster.create({
                        type: "error",
                        title: `Error Occured: ${reason}`,
                      });
                    }
                  );
                }
              }}
              w="100%"
              borderRadius="full"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default AutomationForm;
