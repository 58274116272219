import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input, useBreakpointValue } from "@chakra-ui/react";
import { AxiosError } from "axios";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import { AttributeType } from "entities/domain/attributes/attribute-domain";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAttributeSuccess } from "redux/features/group";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AttributeService from "services/attributes";
import AttributeTypeSelect from "./attribute-type-select";

interface CreateAttributeProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const CreateContactAttribute = ({
  isOpen = true,
  onClose,
}: CreateAttributeProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { merchant } = useAppSelector((state) => state.merchant);

  const [attributeName, setAttributeName] = useState<string>("");
  const [attributeType, setAttributeType] = useState<AttributeType | null>(
    null
  );

  const [typeSelectionErrorMessage, setTypeSelectionErrorMessage] =
    useState<string>("");
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");

  const createAttribute = useCallback(async () => {
    const createdAttribute = await AttributeService.createAttribute(
      auth0Context,
      merchant.groupId,
      {
        name: attributeName,
        type: attributeType!,
      }
    );
    dispatch(createAttributeSuccess(createdAttribute));
  }, [attributeName, attributeType, merchant.groupId]);

  const navigateBack = useCallback(() => navigate(-1), []);

  const onClick = useCallback(async () => {
    const isNameValid = attributeName !== "";
    const isTypeValid = attributeType !== null;

    if (!isNameValid || !isTypeValid) {
      setNameErrorMessage(isNameValid ? "" : "Name is required.");
      setTypeSelectionErrorMessage(isTypeValid ? "" : "Type is required.");

      return;
    }

    try {
      await createAttribute();

      setNameErrorMessage("");
      setTypeSelectionErrorMessage("");

      if (typeof onClose === "undefined") {
        navigateBack();
      } else {
        onClose();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        setNameErrorMessage(error.response.data.message);
      } else {
        setNameErrorMessage(
          "An error occured. Could not create the attribute."
        );
      }
    }
  }, [attributeName, attributeType, createAttribute]);

  return (
    <DialogRoot
      size={isBaseSize ? "full" : "md"}
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          if (onClose) {
            onClose();
          } else {
            navigateBack();
          }
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>Create Attribute</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <Fieldset.Root width="100%">
            <Fieldset.Content>
              <Field
                errorText={nameErrorMessage}
                label="Name"
                invalid={nameErrorMessage !== ""}
              >
                <Input
                  colorPalette={colorScheme}
                  placeholder="Enter attribute name"
                  value={attributeName}
                  onChange={(e) => setAttributeName(e.target.value)}
                />
              </Field>
              <Field
                label="Data Type"
                errorText="Type is required."
                invalid={typeSelectionErrorMessage !== ""}
              >
                <AttributeTypeSelect
                  attributeTypeToShow={attributeType}
                  isDisabled={false}
                  hasError={typeSelectionErrorMessage !== ""}
                  onChange={(selectedOption: AttributeType | null) =>
                    setAttributeType(selectedOption)
                  }
                />
              </Field>
            </Fieldset.Content>
          </Fieldset.Root>
        </DialogBody>
        <DialogFooter>
          <Button onClick={onClick}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default CreateContactAttribute;
