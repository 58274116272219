import { ConditionType, LogicalOperationType } from "entities/domain/criteria";
import {
  Field,
  GroupConfig,
  GroupName,
} from "components/shared/QueryBuilder/QueryCondition";
import TagFieldInput from "./QueryFieldInputs/TagFieldInput";
import TagFieldPreview from "./QueryFieldPreviews/TagFieldPreview";
import TextFieldInput from "./QueryFieldInputs/TextFieldInput";
import TextFieldPreview from "./QueryFieldPreviews/TextFieldPreview";
import DateFieldInput from "./QueryFieldInputs/DateFieldInput";
import ChannelFieldInput from "./QueryFieldInputs/ChannelFieldInput";
import ChannelFieldPreview from "./QueryFieldPreviews/ChannelFieldPreview";
import YearFieldInput from "./QueryFieldInputs/YearFieldInput";
import MakeFieldInput from "./QueryFieldInputs/MakeFieldInput";
import ModelFieldInput from "./QueryFieldInputs/ModelFieldInput";
import CampaignFieldInput from "./QueryFieldInputs/CampaignFieldInput";
import CampaignFieldPreview from "./QueryFieldPreviews/CampaingFieldPreview";

export const customerFields = [
  {
    value: "tag_id",
    label: "tag",
    possibleConditions: [ConditionType.CONTAINS, ConditionType.NOT_CONTAINS],
    wordOrder: "reversed",
    inputComponent: TagFieldInput,
    previewComponent: TagFieldPreview,
  },
  {
    value: "customer_name",
    label: "name",
    wordOrder: "direct",
    possibleConditions: [ConditionType.EQUALS, ConditionType.NOT_EQUALS],
    inputComponent: TextFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "customer_surname",
    label: "surname",
    wordOrder: "direct",
    possibleConditions: [ConditionType.EQUALS, ConditionType.NOT_EQUALS],
    inputComponent: TextFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "channel_type",
    label: "channel",
    possibleConditions: [ConditionType.CONTAINS, ConditionType.NOT_CONTAINS],
    wordOrder: "reversed",
    inputComponent: ChannelFieldInput,
    previewComponent: ChannelFieldPreview,
  },
  {
    value: "campaign_id",
    label: "campaign",
    possibleConditions: [ConditionType.CONTAINS, ConditionType.NOT_CONTAINS],
    wordOrder: "reversed",
    inputComponent: CampaignFieldInput,
    previewComponent: CampaignFieldPreview,
  },
] as Field[];

export const vehicleFields = [
  {
    value: "vehicle_make",
    label: "make",
    wordOrder: "direct",
    possibleConditions: [ConditionType.EQUALS, ConditionType.NOT_EQUALS],
    inputComponent: MakeFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_model",
    label: "model",
    wordOrder: "direct",
    possibleConditions: [ConditionType.EQUALS, ConditionType.NOT_EQUALS],
    inputComponent: ModelFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_class",
    label: "class",
    wordOrder: "direct",
    possibleConditions: [ConditionType.EQUALS, ConditionType.NOT_EQUALS],
    inputComponent: TextFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_year",
    label: "year",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: YearFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_first_registration",
    label: "first registration",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: DateFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_registration",
    label: "registration",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: DateFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_government_inspection_due",
    label: "next MOT due date",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: DateFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_government_inspection_last",
    label: "last MOT date",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: DateFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_service_last",
    label: "last service date",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: DateFieldInput,
    previewComponent: TextFieldPreview,
  },
  {
    value: "vehicle_service_due",
    label: "next service due date",
    wordOrder: "direct",
    possibleConditions: [
      ConditionType.EQUALS,
      ConditionType.NOT_EQUALS,
      ConditionType.LESS_THAN,
      ConditionType.GREATER_THAN,
    ],
    inputComponent: DateFieldInput,
    previewComponent: TextFieldPreview,
  },
] as Field[];

export const customerGroup = {
  name: GroupName.CUSTOMER,
  limits: {
    [LogicalOperationType.AND]: 5,
    [LogicalOperationType.OR]: 5,
  },
  fields: customerFields,
} as GroupConfig;

export const vehicleGroup = {
  name: GroupName.VEHICLE,
  limits: {
    [LogicalOperationType.AND]: 1,
    [LogicalOperationType.OR]: 5,
  },
  fields: vehicleFields,
} as GroupConfig;
