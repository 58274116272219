import { Float, Icon } from "@chakra-ui/react";
import { Avatar } from "components/ui/avatar";
import { useColorMode } from "components/ui/color-mode";
import ReviewDomain, {
  ReviewProvider,
} from "entities/domain/reviews/review-domain";
import React, { useEffect } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useAppSelector } from "redux/hooks";

interface ReviewAvatarProps {
  review: ReviewDomain;
  isBig?: boolean;
}

const getIconByProvider = (
  provider: ReviewProvider,
  colorMode: "dark" | "light"
) => {
  switch (provider) {
    case ReviewProvider.GOOGLE:
      return (
        <Icon
          as={FaGoogle}
          color={colorMode === "dark" ? "gray.800" : "gray.600"}
        />
      );
    case ReviewProvider.FACEBOOK:
      return (
        <Icon
          as={FaFacebook}
          color={colorMode === "dark" ? "gray.800" : "gray.600"}
        />
      );
    default:
      return (
        <Icon
          as={FaGoogle}
          fill={colorMode === "dark" ? "gray.800" : "gray.600"}
        />
      );
  }
};

const ReviewAvatar = ({ review, isBig = false }: ReviewAvatarProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();

  const [shouldShowBadge, setShouldShowBadge] = React.useState<boolean>(false);

  useEffect(() => {
    if (!merchant) {
      return;
    }

    setShouldShowBadge(merchant.hasMoreThanOneReviewIntegration());
  }, [merchant]);

  return (
    <Avatar
      key={review.reviewer.customerId}
      size={isBig ? "xl" : "md"}
      src={review.reviewer.avatar || undefined}
      name={review.reviewer.name || undefined}
    >
      {shouldShowBadge ? (
        <Float
          placement="bottom-end"
          offsetX="1"
          offsetY="1"
          border="none"
          bgColor={colorMode === "light" ? "red.100" : "red.300"}
          p={isBig ? 2 : 1}
        >
          {getIconByProvider(
            review.provider,
            (colorMode as "dark" | "light") || "light"
          )}
        </Float>
      ) : null}
    </Avatar>
  );
};

export default ReviewAvatar;
