import { defineRecipe } from "@chakra-ui/react";

export default defineRecipe({
  base: {
    borderRadius: "full",
    fontWeight: "semibold",
  },
  // variants: {
  //   size: {
  //     xs: {
  //       fontSize: "xs",
  //       // textTransform: "capitalize",
  //     },
  //     sm: {
  //       fontSize: "sm",
  //     },
  //     md: {
  //       fontSize: "md",
  //       // px: 6,
  //       // py: 4,
  //     },
  //     lg: {
  //       fontSize: "lg",
  //       // px: 8,
  //       // py: 5,
  //     },
  //   },
  //   variant: {
  //     solid: ({ colorMode, colorScheme } : {
  //       colorMode: string;
  //       colorScheme: string;
  //     }) => ({
  //       borderRadius: "full",
  //       bgColor: ["white", "black"].includes(colorScheme)
  //         ? colorScheme
  //         : colorMode === "dark"
  //         ? `${colorScheme}.200`
  //         : `${colorScheme}.500`,
  //       color: colorMode === "dark" ? "gray.800" : "white",
  //       _hover: {
  //         bgColor: ["white", "black"].includes(colorScheme)
  //           ? colorMode === "dark"
  //             ? "gray.900"
  //             : "gray.50"
  //           : colorMode === "dark"
  //           ? `${colorScheme}.400`
  //           : `${colorScheme}.400`,
  //         color: colorMode === "dark" ? "gray.800" : "white",
  //       },
  //       _active: {
  //         bgColor: ["white", "black"].includes(colorScheme)
  //           ? colorMode === "dark"
  //             ? "gray.800"
  //             : "gray.100"
  //           : colorMode === "dark"
  //           ? `${colorScheme}.500`
  //           : `${colorScheme}.300`,
  //         color: colorMode === "dark" ? "gray.800" : "white",
  //       },
  //     }),
  //     outline: ({ colorMode, colorScheme }: {
  //       colorMode: string;
  //       colorScheme: string;
  //     }) => ({
  //       bgColor: "transparent",
  //       color: colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
  //       _hover: {
  //         bgColor:
  //           colorMode === "dark" ? `${colorScheme}.900` : `${colorScheme}.50`,
  //         color:
  //           colorMode === "dark" ? `${colorScheme}.400` : `${colorScheme}.400`,
  //       },
  //       _active: {
  //         bgColor: colorMode === "dark" ? `whiteAlpha.50` : `blackAlpha.50`,
  //         color:
  //           colorMode === "dark" ? `${colorScheme}.500` : `${colorScheme}.300`,
  //       },
  //     }),
  //   }
  // },
});
