import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";

const FailedReplySuggestion = ({ onDelete }: { onDelete: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    width="100%"
  >
    <Text flexGrow={1}>
      Oops, something went wrong while working on your reply suggestion
    </Text>
    <HStack pt={1}>{onDelete}</HStack>
  </Box>
);

export default FailedReplySuggestion;
