import { Button, HStack, Icon, Input, VStack } from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import React, { ChangeEventHandler, useEffect } from "react";
import { ReactSVG } from "react-svg";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { maxAllowedSize } from "util/methods";

interface UploadComponentProps {
  profilePicSrc: string;
  name?: string;
  onButtonClick: () => void;
  onRemoveImage?: () => void;
  setProfilePicture: (val: File) => void;
  inputRef: any;
}

const UploadComponent = ({
  profilePicSrc,
  onButtonClick,
  name,
  onRemoveImage,
  setProfilePicture,
  inputRef,
}: UploadComponentProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();

  const reader = new FileReader();

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(clearAttachments());

    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) {
      toaster.create({ type: "error", title: "No file selected" });
      return;
    }

    if (file.size > maxAllowedSize) {
      dispatch(clearAttachments());
      toaster.create({
        type: "error",
        title: `File size should be less than ${
          maxAllowedSize / 1024 / 1024
        }MB`,
      });
      return;
    }

    dispatch(addFileAttachment(file));

    reader.readAsDataURL(file);

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    if (setProfilePicture) setProfilePicture(file);

    reader.onerror = () => {
      toaster.create({ type: "error", title: "Failed to load a file" });
    };
  };

  useEffect(() => {
    return () => {
      dispatch(clearAttachments());
    };
  }, []);

  return (
    <HStack gap={4}>
      <Input
        colorPalette={colorScheme}
        display="none"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={onFileChange}
        id="input-file-upload"
        accept={"image/*"}
      />
      <ProfileAvatar profilePicture={profilePicSrc} name={name || ""} />
      <VStack alignItems="flex-start" gap={0}>
        <Button
          colorPalette={colorScheme}
          variant="ghost"
          onClick={onButtonClick}
        >
          <Icon
            mr={1}
            asChild={true}
            css={{
              "& svg": {
                height: "17px",
                width: "17px",
                "& path": {
                  fill:
                    colorMode === "dark"
                      ? `${colorScheme}.400`
                      : `${colorScheme}.700`,
                },
              },
            }}
          >
            <ReactSVG src="/upload-csv.svg" />
          </Icon>{" "}
          Upload
        </Button>
        {typeof onRemoveImage === "undefined" ? null : (
          <Button colorPalette="red" variant="ghost" onClick={onRemoveImage}>
            <Icon
              mr={1}
              asChild={true}
              css={{
                "& svg": {
                  height: "17px",
                  width: "17px",
                  "& path": {
                    fill: "currentColor",
                  },
                },
              }}
            >
              <ReactSVG src="/trash-can-light.svg" />
            </Icon>{" "}
            Remove
          </Button>
        )}
      </VStack>
    </HStack>
  );
};

export default UploadComponent;
