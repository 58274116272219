import getSymbolFromCurrency from "currency-symbol-map";
import { format, isSameDay, parseISO } from "date-fns";
import { updateFormatAccordingToCountry } from "util/methods";

export enum PaymentStatus {
  OUTSTANDING = "outstanding",
  PAID = "paid",
  CANCELED = "canceled",
  UNKNOWN = "unknown",
}
export enum PaymentMethod {
  CASH = "cash",
  CARD_PAYMENT = "card_payment",
  BANK_TRANSFER = "bank_transfer",
  CHECK = "check",
  OTHER = "other",
}

export enum PaymentAction {
  MARK_AS_PAID = "mark_as_paid",
  CANCEL = "cancel",
  DOWNLOAD_INVOICE = "download_invoice",
}

export default class PaymentDomain {
  id: string;

  amount: number;

  currency: string;

  customerId: string;

  customerName: string;

  createdAt: string;

  paidAt: string;

  dueAt: string;

  status: PaymentStatus;

  description: string;

  method: PaymentMethod;

  invoiceUrl: string;

  getPaymentStatusGroup(): PaymentStatus {
    return this.status;
  }

  getPaymentMethod(): string {
    switch (this.method) {
      case PaymentMethod.CARD_PAYMENT:
        return "Credit Card";
      case PaymentMethod.BANK_TRANSFER:
        return "Bank Transfer";
      case PaymentMethod.CASH:
        return "Cash Payment";
      case PaymentMethod.CHECK:
        return "Cheque Payment";
      case PaymentMethod.OTHER:
        return "Another payment method";
      default:
        return "";
    }
  }

  getDateDescription(): string {
    if (!this.paidAt) {
      return "";
    }

    const statusGroup = this.getPaymentStatusGroup();
    const datePaid = parseISO(
      this.paidAt.includes("Z") ? this.paidAt : `${this.paidAt}Z`
    );

    switch (statusGroup) {
      case PaymentStatus.PAID:
        return isSameDay(datePaid, new Date())
          ? `Today at ${format(datePaid, "h:mm a")}`
          : format(
              datePaid,
              updateFormatAccordingToCountry("dd/MM/yyyy HH:mm")
            );
      default:
        return "";
    }
  }

  getDisplayCreatedDate(): string {
    const dateCreated = parseISO(
      this.createdAt.includes("Z") ? this.createdAt : `${this.createdAt}Z`
    );

    return isSameDay(dateCreated, new Date())
      ? `Today at ${format(dateCreated, "h:mm a")}`
      : format(dateCreated, updateFormatAccordingToCountry("dd/MM/yyyy HH:mm"));
  }

  getDisplayDueDate(): string {
    if (!this.dueAt) {
      return "";
    }

    const dueDate = parseISO(
      this.dueAt.includes("Z") ? this.dueAt : `${this.dueAt}Z`
    );

    return isSameDay(dueDate, new Date())
      ? `Today at ${format(dueDate, "h:mm a")}`
      : format(dueDate, updateFormatAccordingToCountry("dd/MM/yyyy HH:mm"));
  }

  getDisplayAmt(): string {
    return `${getSymbolFromCurrency(this.currency)}${this.amount.toFixed(2)}`;
  }

  getMonthlyKey(): string {
    return format(
      parseISO(
        this.createdAt.includes("Z") ? this.createdAt : `${this.createdAt}Z`
      ),
      "MMMM yyyy"
    );
  }

  getAvailablePaymentActions(): PaymentAction[] {
    const actions: PaymentAction[] = [];
    if (this.status === PaymentStatus.OUTSTANDING) {
      actions.push(PaymentAction.MARK_AS_PAID);
      actions.push(PaymentAction.CANCEL);
    }

    if (this.invoiceUrl) {
      actions.push(PaymentAction.DOWNLOAD_INVOICE);
    }

    return actions;
  }

  canBeMarkedAsPaid(): boolean {
    return this.getAvailablePaymentActions().includes(
      PaymentAction.MARK_AS_PAID
    );
  }

  canBeCancelled(): boolean {
    return this.getAvailablePaymentActions().includes(PaymentAction.CANCEL);
  }

  canDownloadAnInvoice(): boolean {
    return this.getAvailablePaymentActions().includes(
      PaymentAction.DOWNLOAD_INVOICE
    );
  }

  private getCustomerInitials() {
    const arr = this.customerName.split(" ");
    const splitName = arr.filter((e) => String(e).trim());

    return splitName.length > 1
      ? `${splitName[0][0]}${splitName[1][0]}`
      : `${splitName[0][0]}`;
  }

  getPicture() {
    return "";
  }

  getStatusColor(): string {
    const statusGroup = this.getPaymentStatusGroup();
    switch (statusGroup) {
      case PaymentStatus.PAID:
        return "green";
      case PaymentStatus.OUTSTANDING:
        return "yellow";
      case PaymentStatus.CANCELED:
        return "red";
      case PaymentStatus.UNKNOWN:
      default:
        return "darkblue";
    }
  }

  constructor(
    id: string,
    amount: number,
    currency: string,
    customerId: string,
    customerName: string,
    createdAt: string,
    paidAt: string,
    dueAt: string,
    status: PaymentStatus,
    description: string,
    method: PaymentMethod,
    invoiceUrl: string
  ) {
    this.id = id;
    this.currency = currency;
    this.customerId = customerId;
    this.customerName = customerName;
    this.status = status;
    this.amount = amount;
    this.description = description;
    this.createdAt = createdAt;
    this.paidAt = paidAt;
    this.dueAt = dueAt;
    this.method = method;
    this.invoiceUrl = invoiceUrl;
  }
}
