/* eslint-disable import/prefer-default-export */
import { OptionTypes } from "components/shared/filter";
import TagsDomain from "entities/domain/tags/tags-domain";
import { TagDTO } from "entities/dto/TagDTO";
import { colorList } from "util/constants";

export const newTagTransformFromToOptions = (tag: string): OptionTypes => ({
  value: tag,
  label: tag,
  color: "gray",
});

export const tagToTagOption = (tag: TagsDomain): OptionTypes => ({
  value: tag.id,
  label: tag.tag,
  color: tag.color,
});

export const tagTransformFromDtoToDomain = (
  tagDto: TagDTO,
  index: number
): TagsDomain => {
  const color = colorList[index % colorList.length];

  return new TagsDomain(tagDto.name, color, tagDto.id);
};
