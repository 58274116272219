import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import MerchantGroup from "entities/domain/admin/merchants/merchant-group";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useRef,
  useState,
} from "react";
import Select, { SingleValue } from "react-select";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import SelectMerchant from "./select-merchant";
import SelectMerchantGroup from "./select-merchant-group";

const reader = new FileReader();

export interface MerchantCountryCodeOptionTypes {
  value: string;
  label: string;
}

export const merchantCountryCodeOptions: MerchantCountryCodeOptionTypes[] = [
  { value: "GB", label: "United Kingdom" },
  { value: "IE", label: "Ireland" },
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
  { value: "BH", label: "Bahrain" },
];

const AddMerchantForm = () => {
  const auth0Context = useAuth0();
  const { files } = useAppSelector((state) => state.attachments);
  const dispatch = useAppDispatch();
  const [merchantNameValue, setMerchantNameValue] = useState<string>("");
  const [countryCodeValue, setCountryCodeValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<MerchantCountryCodeOptionTypes>>(null);
  const [registeredCompanyIdValue, setRegisteredCompanyIdValue] =
    useState<string>("");
  const [googleReviewLink, setGoogleReviewLink] = useState<string>("");
  const [fbBusinessId, setFbBusinessId] = useState<string>("");
  const [googlePlaceIdValue, setGooglePlaceIdValue] = useState<string>("");
  const [googleAccountIdValue, setGoogleAccountIdValue] = useState<string>("");
  const [googleLocationIdValue, setGoogleLocationIdValue] =
    useState<string>("");
  const [stripeIdValue, setStripeIdValue] = useState<string>("");
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [selectedMerchantGroup, setSelectedMerchantGroup] =
    useState<MerchantGroup | null>(null);

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleChangeMerchantName = (event: ChangeEvent<HTMLInputElement>) =>
    setMerchantNameValue(event.target.value);

  const handleChangeCompanyId = (event: ChangeEvent<HTMLInputElement>) =>
    setRegisteredCompanyIdValue(event.target.value);

  const handleChangeGoogleReviewLink = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleReviewLink(event.target.value);

  const handleChangFbBusinessIdValue = (event: ChangeEvent<HTMLInputElement>) =>
    setFbBusinessId(event.target.value);

  const handleChangePlaceId = (event: ChangeEvent<HTMLInputElement>) =>
    setGooglePlaceIdValue(event.target.value);

  const handleChangeGoogleAccountId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleAccountIdValue(event.target.value);

  const handleChangeGoogleLocationId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleLocationIdValue(event.target.value);

  const handleChangeStripeId = (event: ChangeEvent<HTMLInputElement>) =>
    setStripeIdValue(event.target.value);

  const handleChangeCountryCode = (
    e: SingleValue<MerchantCountryCodeOptionTypes>
  ) => {
    setCountryCodeValue(e!.value);
    setSelectedCountryCodeValue(e);
  };

  const resetValues = () => {
    setMerchantNameValue("");
    setCountryCodeValue("");
    setSelectedCountryCodeValue(null);
    setRegisteredCompanyIdValue("");
    setGoogleReviewLink("");
    setFbBusinessId("");
    setGooglePlaceIdValue("");
    setGoogleAccountIdValue("");
    setStripeIdValue("");
    setSelectedMerchantGroup(null);
    setSelectedMerchant(null);
    dispatch(clearAttachments());
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    };
  };

  const isDisabled = (): boolean => !merchantNameValue || !countryCodeValue;

  const handleSaveMerchant = async () => {
    try {
      await AdminService.createMerchant(auth0Context, {
        name: merchantNameValue,
        country: countryCodeValue,
        registered_company_id: registeredCompanyIdValue,
        google_review_link: googleReviewLink,
        facebook_business_id: fbBusinessId,
        google_place_id: googlePlaceIdValue,
        google_account_id: googleAccountIdValue,
        google_location_id: googleLocationIdValue,
        stripe_account_id: stripeIdValue,
        logo: files[0],
        base_merchant_id: selectedMerchant?.id,
        group_id: selectedMerchantGroup?.id,
      });
      toaster.create({
        type: "success",
        title: "Merchant Created Successfully",
      });
    } catch (_error: unknown) {
      toaster.create({
        type: "error",
        title: "Error creating merchant",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <>
      <Fieldset.Root id="merchant-form-input">
        <Fieldset.Content>
          <Field
            label="Select a Merchant Group"
            helperText="Leave empty to create a new group"
          >
            <SelectMerchantGroup
              selectedMerchantGroup={selectedMerchantGroup}
              setSelectedMerchantGroup={setSelectedMerchantGroup}
              isClearable={true}
            />
          </Field>
          <Field label="Select a base Merchant">
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
              isClearable={true}
            />
          </Field>
          <Field label="Merchant Name" required>
            <Input
              value={merchantNameValue}
              onChange={handleChangeMerchantName}
              placeholder="Fuzey LTD"
            />
          </Field>
          <Field label="Country" required>
            <Select
              placeholder="Select Country Code"
              onChange={handleChangeCountryCode}
              options={merchantCountryCodeOptions}
              value={selectedCountryCodeValue}
              styles={{
                ...getReactSelectStyles(colorMode || "light", colorScheme),
                container: (provided: any) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </Field>
          <Field label="Registered Company ID">
            <Input
              value={registeredCompanyIdValue}
              onChange={handleChangeCompanyId}
              placeholder="0000000000"
            />
          </Field>
          <Field label="Google Review Link">
            <Input
              value={googleReviewLink}
              onChange={handleChangeGoogleReviewLink}
              placeholder="google.com/1231231"
            />
          </Field>
          <Field label="Facebook Business ID">
            <Input
              value={fbBusinessId}
              onChange={handleChangFbBusinessIdValue}
              placeholder="0000"
            />
          </Field>
          <Field label="Google Place ID">
            <Input
              value={googlePlaceIdValue}
              onChange={handleChangePlaceId}
              placeholder="0000"
            />
          </Field>
          <Field label="Google Location ID">
            <Input
              value={googleLocationIdValue}
              onChange={handleChangeGoogleLocationId}
              placeholder="0000"
            />
          </Field>

          <Field label="Google Account ID">
            <Input
              value={googleAccountIdValue}
              onChange={handleChangeGoogleAccountId}
              placeholder="0000"
            />
          </Field>
          <Field label="Stripe ID">
            <Input
              value={stripeIdValue}
              onChange={handleChangeStripeId}
              placeholder="0000"
            />
          </Field>
          <Field label="Merchant Logo">
            <input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept={"image/*"}
            />
          </Field>
        </Fieldset.Content>
        <Button
          onClick={handleSaveMerchant}
          disabled={isDisabled()}
          mb={30}
          mt={30}
          size="lg"
        >
          {" "}
          Save Merchant
        </Button>
      </Fieldset.Root>
    </>
  );
};

export default AddMerchantForm;
