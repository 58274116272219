import { Flex, VStack, useBreakpointValue } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { SkeletonText } from "components/ui/skeleton";
import React, { memo, useMemo } from "react";
import { useAppSelector } from "redux/hooks";

const getRandomIntegerBetween = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const SkeletonOverlay = ({ isInitialLoad }: { isInitialLoad: boolean }) => {
  const { colorMode } = useColorMode();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const amountOfMessages = isBaseSize ? 4 : 5;
  const messageConfigs = useMemo(
    () =>
      [...Array(amountOfMessages).keys()].map((i) => ({
        width: `${getRandomIntegerBetween(25, 60)}%`,
        numberOfLines:
          i % 2 === 0
            ? getRandomIntegerBetween(1, 3)
            : getRandomIntegerBetween(1, 4),
      })),
    []
  );

  return isInitialLoad ? (
    <Flex
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
      {...currentAgent!.getChatBackgroundStyles(
        (colorMode as "dark" | "light") || "light"
      )}
      position="absolute"
      zIndex={49}
      top={0}
      left={0}
      pt={20}
    >
      <VStack gap={4} w="100%" h="100%" p={4}>
        {[...Array(amountOfMessages).keys()].map((i) => (
          <Flex
            bgColor={colorMode === "dark" ? "gray.700" : "gray.200"}
            borderRadius="lg"
            alignSelf={i % 2 === 0 ? "start" : "end"}
            key={`skeleton-message-${i}`}
            padding={4}
            w={messageConfigs[i].width}
            h="fit-content"
            gridGap={4}
          >
            <SkeletonText
              noOfLines={messageConfigs[i].numberOfLines}
              gap={4}
              height={2}
              flexShrink={1}
              flexGrow={1}
            />
          </Flex>
        ))}
      </VStack>
    </Flex>
  ) : null;
};

export default memo(SkeletonOverlay);
