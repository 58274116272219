import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
} from "components/ui/accordion";
import { useColorMode } from "components/ui/color-mode";
import {
  DrawerBackdrop,
  DrawerBody,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerRoot,
} from "components/ui/drawer";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from "components/ui/menu";
import CreateContactAttribute from "components/user-settings/ContactAttributes/create-contact-attribute";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import VehicleDomain from "entities/domain/vehicle";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useRef, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { LuFileText, LuMessagesSquare, LuPlus } from "react-icons/lu";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { insertContact } from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { canDeleteContacts } from "util/permissions";
import ConfirmationDialog from "../ConfirmationDialog";
import AllAttachments from "./AllAttachments";
import ChannelsArea from "./ChannelsArea";
import ContactAddressDetails from "./ContactAddressDetails";
import ContactNotes from "./ContactNotes";
import ContactTagsArea from "./ContactTagsArea";
import CustomerAttributeAccordionForm from "./CustomerAttributesAccordionContent";
import ProfileArea from "./ProfileArea";
import VehicleInfoArea from "./VehicleInfoArea";

interface ContactDetailsInterface {
  contactToDisplay?: ContactDomain;
  onClose?: () => void;
  handleCloseContactDisplay?: () => void;
  onCreateNewTag?: () => void;
  setTagIdToBeDeleted?: (val: string) => void;
  onChannelClick?: (chanId: string) => void;
  mobileMarginX?: string;
  showDeleteButton?: boolean;
  setDisplayBackIcon?: (val: boolean) => void;
  setDisplayMoreIcon?: (val: boolean) => void;
  handleOpenMergeModal?: (val: ContactDomain) => void;
  displayPopover?: boolean;
}

const ContactDetails = (props: ContactDetailsInterface) => {
  const context = useOutletContext<ContactDetailsInterface>();

  let contactToDisplay: ContactDomain | undefined;
  let onChannelClick: ((chanId: string) => void) | undefined;
  let handleCloseContactDisplay: (() => void) | undefined;
  let showDeleteButton: boolean | undefined;
  let mobileMarginX: string | undefined;
  let setDisplayBackIcon: ((b: boolean) => void) | undefined;
  let setDisplayMoreIcon: ((m: boolean) => void) | undefined;
  let handleOpenMergeModal: ((c: ContactDomain) => void) | undefined;
  let displayPopover: boolean | undefined;
  let onCreateNewTag: (() => void) | undefined;
  let setTagIdToBeDeleted: ((t: string) => void) | undefined;
  let onClose: (() => void) | undefined;

  if (context && Object.keys(context).length) {
    contactToDisplay = context.contactToDisplay;
    onChannelClick = context.onChannelClick;
    handleCloseContactDisplay = context.handleCloseContactDisplay;
    showDeleteButton = context.showDeleteButton;
    mobileMarginX = context.mobileMarginX;
    setDisplayBackIcon = context.setDisplayBackIcon;
    setDisplayMoreIcon = context.setDisplayMoreIcon;
    handleOpenMergeModal = context.handleOpenMergeModal;
    displayPopover = context.displayPopover;
    onCreateNewTag = context.onCreateNewTag;
    setTagIdToBeDeleted = context.setTagIdToBeDeleted;
    onClose = context.onClose;
  } else if (Object.keys(props).length) {
    contactToDisplay = props.contactToDisplay;
    onChannelClick = props.onChannelClick;
    handleCloseContactDisplay = props.handleCloseContactDisplay;
    showDeleteButton = props.showDeleteButton;
    mobileMarginX = props.mobileMarginX;
    setDisplayBackIcon = props.setDisplayBackIcon;
    setDisplayMoreIcon = props.setDisplayMoreIcon;
    handleOpenMergeModal = props.handleOpenMergeModal;
    displayPopover = props.displayPopover;
    onCreateNewTag = props.onCreateNewTag;
    setTagIdToBeDeleted = props.setTagIdToBeDeleted;
    onClose = props.onClose;
  } else {
    return null;
  }

  if (
    !(
      contactToDisplay &&
      onClose &&
      handleCloseContactDisplay &&
      onCreateNewTag &&
      setTagIdToBeDeleted
    )
  ) {
    return null;
  }

  const dispatch = useAppDispatch();

  // If opened contact isn't in the first page of the contacts list, it won't be in Redux store
  // We need it there to be able to track changes to it
  useEffect(() => {
    if (!contactToDisplay) {
      return;
    }
    dispatch(insertContact(contactToDisplay));
  }, [contactToDisplay]);

  const { search } = useLocation();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { deleteContact } = useContactsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();
  const attributesModal = useDisclosure();

  const [hasAttachments, setHasAttachments] = useState<boolean>(false);
  const [hasVehicles, setHasVehicles] = useState<boolean>(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { attributes } = useAppSelector((state) => state.group);

  useEffect(() => {
    if (setDisplayBackIcon) setDisplayBackIcon(true);
    if (setDisplayMoreIcon) setDisplayMoreIcon(true);
  }, []);

  const { colorScheme } = useAppSelector((state) => state.theme);
  const contentRef = useRef<HTMLDivElement>(null);

  const content = (
    <>
      <Box
        h="100%"
        bgColor={
          colorMode === "dark"
            ? isBaseSize
              ? "gray.800"
              : "gray.700"
            : "white"
        }
        display="flex"
        flexDirection="column"
        mx={isBaseSize ? mobileMarginX : "initial"}
        overflowX="hidden"
        px={4}
      >
        <ProfileArea contactToDisplay={contactToDisplay} />
        <Box flexGrow={1}>
          <AccordionRoot
            collapsible={true}
            variant="subtle"
            colorPalette="gray"
            defaultValue={["-1"]}
          >
            <AccordionItem value="-1" border="none">
              <AccordionItemTrigger>
                <HStack>
                  <Icon
                    as={LuMessagesSquare}
                    width="1.25rem"
                    height="1.25rem"
                    css={{
                      "& stroke": {
                        fill:
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.900`,
                      },
                    }}
                  />
                  <Text fontWeight="bold">Channels</Text>
                </HStack>
              </AccordionItemTrigger>
              <AccordionItemContent>
                <ChannelsArea
                  contactToDisplay={contactToDisplay}
                  onChannelClick={onChannelClick}
                  merchant={merchant}
                />
              </AccordionItemContent>
            </AccordionItem>
            <AccordionItem value="0" border="none">
              <AccordionItemTrigger>
                <HStack>
                  <Icon
                    as={LuFileText}
                    width="1.25rem"
                    height="1.25rem"
                    color={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.900`
                    }
                  />
                  <Text fontWeight="bold">Custom Attributes</Text>
                </HStack>
              </AccordionItemTrigger>
              <AccordionItemContent>
                <VStack
                  w="100%"
                  h="100%"
                  alignItems="start"
                  justifyContent="start"
                  gap={4}
                >
                  {attributes.map((attr) => (
                    <CustomerAttributeAccordionForm
                      key={attr.id}
                      customerId={contactToDisplay!.id!}
                      attributeId={attr.id}
                      attributeName={attr.name}
                      attributeType={attr.type}
                    />
                  ))}
                  <Button
                    colorPalette={colorScheme}
                    gridGap={2}
                    mt={4}
                    size="sm"
                    alignSelf="center"
                    onClick={() => attributesModal.onOpen()}
                  >
                    <Icon
                      as={LuPlus}
                      color={
                        colorMode === "dark" ? `${colorScheme}.800` : "white"
                      }
                    />
                    <Text>Add Attributes</Text>
                  </Button>
                  <CreateContactAttribute
                    isOpen={attributesModal.open}
                    onClose={attributesModal.onClose}
                  />
                </VStack>
              </AccordionItemContent>
            </AccordionItem>
            <AccordionItem value="1" border="none" disabled={!hasAttachments}>
              <AllAttachments
                contactToDisplay={contactToDisplay!}
                isExpanded={true}
                onFirstLoad={(attachments: AttachmentDomain[]) => {
                  setHasAttachments(attachments.length > 0);
                }}
              />
            </AccordionItem>
            {merchant.isIntegrationEnabled(IntegrationName.KEYLOOP) ? (
              <AccordionItem value="2" border="none" disabled={!hasVehicles}>
                <VehicleInfoArea
                  contactToDisplay={contactToDisplay!}
                  isExpanded={true}
                  onFirstLoad={(vehicles: VehicleDomain[]) => {
                    setHasVehicles(vehicles.length > 0);
                  }}
                />
              </AccordionItem>
            ) : null}
            <AccordionItem value="3" border="none">
              <ContactTagsArea
                contactTagIds={contactToDisplay?.tagIds || []}
                contactId={contactToDisplay!.id!}
                onCreateNewTag={onCreateNewTag!}
                setTagIdToBeDeleted={setTagIdToBeDeleted!}
                isExpanded={true}
                isArchived={contactToDisplay!.isArchived}
              />
            </AccordionItem>
            <AccordionItem
              value="4"
              border="none"
              css={{
                "& [data-part='item-content']": {
                  overflow: "initial !important",
                },
              }}
            >
              <ContactAddressDetails
                contactAddress={contactToDisplay!.address}
                contactToDisplay={contactToDisplay!}
                isExpanded={true}
              />
            </AccordionItem>
            <AccordionItem value="5" border="none">
              <ContactNotes
                contactToDisplay={contactToDisplay!}
                isExpanded={true}
              />
            </AccordionItem>
          </AccordionRoot>
        </Box>
      </Box>
    </>
  );

  const DesktopContent = (
    <>
      <DrawerRoot
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            handleCloseContactDisplay();
          }
        }}
        open={!!contactToDisplay}
        onInteractOutside={handleCloseContactDisplay}
        size="md"
      >
        <DrawerBackdrop />
        <DrawerContent borderRadius="0 !important" px={0} ref={contentRef}>
          <DrawerCloseTrigger onClick={handleCloseContactDisplay} />
          {displayPopover && (
            <MenuRoot>
              <MenuTrigger asChild>
                <IconButton
                  m={2}
                  mr="auto"
                  aria-label="More actions"
                  size="xs"
                  variant="ghost"
                  colorPalette="gray"
                >
                  <Icon as={FiMoreHorizontal} />
                </IconButton>
              </MenuTrigger>
              <MenuContent portalRef={contentRef}>
                <MenuItem
                  value="edit"
                  onClick={() => {
                    navigate({
                      pathname: `/${merchant.id}/contacts/edit/${contactToDisplay?.id}`,
                      search: createSearchParams(search).toString(),
                    });
                  }}
                >
                  Edit Contact
                </MenuItem>
                <MenuItem
                  value="merge"
                  onClick={() => {
                    if (handleOpenMergeModal)
                      handleOpenMergeModal(contactToDisplay!);
                  }}
                >
                  Merge Contact
                </MenuItem>
                {showDeleteButton &&
                  canDeleteContacts(merchant.id, currentAgent!) && (
                    <MenuItem
                      value="delete"
                      onClick={() => {
                        setIsDeleteDialogOpen(true);
                      }}
                    >
                      Delete Contact
                    </MenuItem>
                  )}
              </MenuContent>
              <ConfirmationDialog
                headerText="Delete Contact?"
                messageText="Are you sure you want to delete this contact?"
                cancelButtonText="No"
                buttonText="Yes"
                isDangerous={true}
                isOpen={isDeleteDialogOpen}
                setIsOpen={setIsDeleteDialogOpen}
                confirmationCallback={() => {
                  if (!contactToDisplay) {
                    return;
                  }

                  deleteContact(contactToDisplay.id!);
                  handleCloseContactDisplay!();
                }}
              />
            </MenuRoot>
          )}
          <DrawerBody px={0}>{content}</DrawerBody>
        </DrawerContent>
      </DrawerRoot>
    </>
  );

  return isBaseSize ? (
    <Box w="100%" h="100%" display="flex" flexDirection="column">
      {content}
    </Box>
  ) : (
    DesktopContent
  );
};

export default ContactDetails;
