import { defineSlotRecipe } from "@chakra-ui/react";
import { switchAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: switchAnatomy.keys(),
  variants: {
    variant: {
      solid: {
        control: {
          bg: {
            _light: "gray.200",
            _dark: "gray.800",
          },
        },
      },
    },
  },
});
