import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Portal,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import data from "@emoji-mart/data";
import { humanizeCustomFieldKey } from "components/shared/CustomFieldMenuItem";
import FileSelector from "components/shared/FileSelector";
import EmojiPickerWithSuspense from "components/shared/LazyEmojiPicker";
import { useColorMode } from "components/ui/color-mode";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "components/ui/popover";
import CustomField from "components/user-settings/shared/CustomField";
import FileDomain from "entities/domain/file";
import React, { memo, useState } from "react";
import { LuPaperclip, LuSmile } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface MessageInputToolbarProps {
  insertIntoText: (text: string) => void;
  insertEmoji: (emoji: { native: string }) => void;
  customFields?: Record<string, string>;
  addAttachment?: (file: FileDomain) => Promise<void>;
}

const MessageInputToolbar = ({
  insertIntoText,
  insertEmoji,
  customFields,
  addAttachment,
}: MessageInputToolbarProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);
  const [isCustomFieldsPopoverOpen, setIsCustomFieldsPopoverOpen] =
    useState<boolean>(false);

  const fileSelector = useDisclosure();

  return (
    <Flex
      justifyContent="end"
      alignItems="center"
      w="100%"
      gridGap={4}
      css={{
        "> button": {
          display: "flex",
        },
      }}
    >
      <PopoverRoot
        closeOnInteractOutside={true}
        open={isCustomFieldsPopoverOpen}
        onOpenChange={(e) => setIsCustomFieldsPopoverOpen(e.open)}
      >
        <Portal>
          <PopoverContent
            maxHeight="300px"
            overflowY="auto"
            boxShadow="lg"
            borderRadius="md"
            zIndex="popover"
          >
            <Box p={6}>
              {customFields ? (
                Object.entries(customFields).map(([key, _value]) => (
                  <CustomField
                    key={key}
                    onClick={() => {
                      insertIntoText(`{${key}}`);
                    }}
                    displayKey={humanizeCustomFieldKey(key)}
                    fieldKey={key}
                  />
                ))
              ) : (
                <CustomField
                  key="customer_first_name"
                  onClick={() => {
                    insertIntoText("{customer_first_name}");
                  }}
                  displayKey="Customer First Name"
                  fieldKey="customer_first_name"
                />
              )}
            </Box>
          </PopoverContent>
        </Portal>

        <PopoverTrigger asChild>
          <Button
            unstyled={true}
            minWidth="unset"
            minHeight="unset"
            backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
            borderStyle="solid"
            borderWidth="2px"
            borderColor={colorMode === "dark" ? "green.300" : "green.600"}
            color={colorMode === "dark" ? "green.300" : "green.600"}
            rounded="full"
            height="1.75rem"
            width="1.75rem"
            textAlign="center"
            fontWeight={800}
            cursor="pointer"
          >
            {"{}"}
          </Button>
        </PopoverTrigger>
      </PopoverRoot>
      {isBaseSize ? null : (
        <PopoverRoot
          closeOnInteractOutside={true}
          open={openEmojiPopover}
          onOpenChange={(e) => setOpenEmojiPopover(e.open)}
        >
          <Portal>
            <PopoverContent width="auto">
              <EmojiPickerWithSuspense
                data={data}
                theme={colorMode}
                onEmojiSelect={insertEmoji}
              />
            </PopoverContent>
          </Portal>

          <PopoverTrigger asChild>
            <IconButton variant="ghost" colorPalette="yellow" rounded="full">
              <Icon as={LuSmile} height="1.75rem" width="1.75rem" />
            </IconButton>
          </PopoverTrigger>
        </PopoverRoot>
      )}
      {typeof addAttachment !== "undefined" ? (
        <label
          htmlFor="file-input-message-toolbar"
          style={{
            cursor: "pointer",
            margin: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="Attach File"
            variant="ghost"
            colorPalette={colorScheme}
            onClick={fileSelector.onOpen}
          >
            <Icon
              as={LuPaperclip}
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
              height="1.25rem"
              width="1.25rem"
            />
          </IconButton>
          <FileSelector
            isOpen={fileSelector.open}
            onClose={fileSelector.onClose}
            onFileSelect={async (selectedFile) => {
              if (selectedFile) {
                await addAttachment(selectedFile);
                fileSelector.onClose();
              }
            }}
          />
        </label>
      ) : null}
    </Flex>
  );
};

export default memo(MessageInputToolbar);
