import {
  Button,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  customerGroup,
  vehicleGroup,
} from "components/campaigns/form/steps/audience/QueryFields";
import { useColorMode } from "components/ui/color-mode";
import {
  Condition,
  LogicalOperation,
  LogicalOperationType,
} from "entities/domain/criteria";
import React from "react";
import { FiPlus } from "react-icons/fi";
import Select from "react-select";
import { useAppSelector } from "redux/hooks";
import { getSelectStylesForQueryBuilder } from "util/methods";
import QueryCondition, { GroupConfig, GroupName } from "./QueryCondition";

interface QueryOperationProps {
  operation: LogicalOperation;
  size: "sm" | "md";
  index: number;
  entityNamePlural: string;
  isDisabled: boolean;
  topLevelOperation: LogicalOperationType;
  groups: GroupConfig[];
  disabledFields: string[];
  operations: LogicalOperation[];
  setTopLevelOperation: (operation: LogicalOperationType) => void;
  setOperations: (operations: LogicalOperation[]) => void;
}

// We assume that all conditions in operation are of the same group
export const getOperationGroup = (operation: LogicalOperation): GroupConfig => {
  const firstCondition = operation.conditions[0] as Condition;

  if (!firstCondition) {
    return customerGroup;
  }

  const vehicleFieldKeys = vehicleGroup.fields.map((field) => field.value);

  if (vehicleFieldKeys.includes(firstCondition.key)) {
    return vehicleGroup;
  }

  return customerGroup;
};

export const canAddMoreGroups = (
  operations: LogicalOperation[],
  group: GroupConfig,
  topLevelOperation: LogicalOperationType
) => {
  const groupOperations = operations.filter(
    (operation) => getOperationGroup(operation).name === group.name
  );

  return groupOperations.length < group.limits[topLevelOperation];
};

const CONDITIONS_LIMIT = 5;

const QueryOperation = ({
  disabledFields,
  size,
  operation,
  index,
  entityNamePlural,
  groups,
  isDisabled,
  topLevelOperation,
  operations,
  setTopLevelOperation,
  setOperations,
}: QueryOperationProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const logicalOperationOptions = [
    {
      value: LogicalOperationType.AND,
      label: "all",
    },
    {
      value: LogicalOperationType.OR,
      label: "any",
    },
  ];
  const topLevelOperationOptions = [
    {
      value: LogicalOperationType.AND,
      label: "and",
    },
    {
      value: LogicalOperationType.OR,
      label: "or",
    },
  ];

  return (
    <>
      <HStack
        alignItems={isBaseSize ? "end" : "center"}
        flexWrap={isBaseSize ? "wrap" : "nowrap"}
        gap={size === "sm" ? 0 : 1}
      >
        <Text>
          {index === 0 ? (
            `Find ${entityNamePlural} with`
          ) : (
            <HStack gap={size === "sm" ? 0 : 1}>
              <Select
                isDisabled={isDisabled}
                defaultValue={topLevelOperationOptions[0]}
                isClearable={false}
                onChange={(v) => {
                  setTopLevelOperation(v?.value || LogicalOperationType.AND);
                }}
                options={topLevelOperationOptions}
                isSearchable={false}
                value={topLevelOperationOptions.find(
                  (tlo) => tlo.value === topLevelOperation
                )}
                styles={getSelectStylesForQueryBuilder(colorScheme, colorMode as "light" | "dark" || "light")}
              />
              <Text>has</Text>
            </HStack>
          )}
        </Text>
        <Select
          isDisabled={isDisabled}
          defaultValue={logicalOperationOptions[1]}
          isClearable={false}
          onChange={(v) => {
            setOperations(
              operations.map((op, opIndex) => {
                if (opIndex === index) {
                  return {
                    ...op,
                    operation: v?.value || LogicalOperationType.OR,
                  };
                }

                return op;
              })
            );
          }}
          options={logicalOperationOptions}
          isSearchable={false}
          value={
            operation.operation === LogicalOperationType.AND
              ? logicalOperationOptions[0]
              : logicalOperationOptions[1]
          }
          styles={getSelectStylesForQueryBuilder(colorScheme, colorMode as "light" | "dark" || "light")}
        />
        <Text>of the following criteria:</Text>
      </HStack>
      {operation.conditions.map((condition, conditionIndex) => (
        <QueryCondition
          key={JSON.stringify(condition)}
          disabledFields={disabledFields}
          size={size}
          isDisabled={isDisabled}
          isRemovable={operations.length > 1 || operation.conditions.length > 1}
          condition={condition as Condition}
          groups={groups}
          fields={getOperationGroup(operation).fields}
          conditionIndex={conditionIndex}
          operationIndex={index}
          editCondition={(updatedCondition) => {
            const newOperations = JSON.parse(
              JSON.stringify(operations)
            ) as LogicalOperation[];
            newOperations[index].conditions = newOperations[
              index
            ].conditions.map((c, cIndex) => {
              if (cIndex === conditionIndex) {
                return updatedCondition;
              }

              return c;
            });
            setOperations(newOperations);
          }}
          removeCondition={() => {
            setOperations(
              operations
                .map((op, opIndex) => {
                  if (opIndex === index) {
                    return {
                      ...op,
                      conditions: op.conditions.filter(
                        (c, cIndex) => cIndex !== conditionIndex
                      ),
                    };
                  }

                  return op;
                })
                .filter((op) => op.conditions.length > 0)
            );
          }}
        />
      ))}
      {isDisabled ? null : (
        <Button
          colorPalette="gray"
          size="xs"
          variant="outline"
          disabled={operation.conditions.length >= CONDITIONS_LIMIT}
          ml={isBaseSize ? 2 : 8}
          css={{
            "> span": {
              marginRight: "0.25rem",
            },
          }}
          onClick={() => {
            setOperations(
              operations.map((op, opIndex) => {
                if (opIndex === index) {
                  return {
                    operation: op.operation,
                    conditions: [
                      ...op.conditions,
                      {
                        key: getOperationGroup(operation).fields[0].value,
                        type: getOperationGroup(operation).fields[0]
                          .possibleConditions[0],
                        value: "",
                      } as Condition,
                    ],
                  };
                }

                return op;
              })
            );
          }}
        >
          <Icon as={FiPlus} /> Condition
        </Button>
      )}
    </>
  );
};

export default QueryOperation;
