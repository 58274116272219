import { useAuth0 } from "@auth0/auth0-react";
import {
  HStack,
  Text,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import { Slider } from "components/ui/slider";
import React, { useEffect, useState } from "react";
import {
  setFilterByPriceMax,
  setFilterByPriceMin,
} from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";

interface PriceFilterProps {}

const PriceFilter = (_props: PriceFilterProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const {
    filterOptions,
    filterByPriceMax,
    filterByPriceMin,
    filterByCondition,
    filterByModel,
    filterByMake,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [rangeSliderValues, setRangeSliderValues] = useState<number[]>([
    filterByPriceMin || filterOptions.minPrice,
    filterByPriceMax || filterOptions.maxPrice,
  ]);

  useEffect(() => {
    if (
      !filterByModel &&
      !filterByYearMin &&
      !filterByYearMax &&
      !filterByMake &&
      !filterByCondition &&
      !filterByAvailability
    ) {
      setRangeSliderValues([
        filterByPriceMin || filterOptions.minPrice,
        filterByPriceMax || filterOptions.maxPrice,
      ]);
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      filterByModel,
      filterByMake,
      filterByYearMin,
      filterByYearMax,
      null,
      null,
      filterByCondition,
      filterByAvailability
    ).then((newFilterOptions) => {
      setRangeSliderValues([
        filterByPriceMin || newFilterOptions.minPrice,
        filterByPriceMax || newFilterOptions.maxPrice,
      ]);
    });
  }, [
    filterOptions,
    filterByCondition,
    filterByModel,
    filterByMake,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
  ]);

  useEffect(() => {
    setRangeSliderValues([
      filterByPriceMin || filterOptions.minPrice,
      filterByPriceMax || filterOptions.maxPrice,
    ]);
  }, [filterOptions]);

  return filterOptions.minPrice === filterOptions.maxPrice ? null : (
    <Field label={
      <HStack justifyContent="space-between">
        <Text>Price</Text>
        <Text>
          {(filterByPriceMin || filterOptions.minPrice) ===
          (filterByPriceMax || filterOptions.maxPrice)
            ? `${merchant.getCurrencySymbol()} ${(
                filterByPriceMin || filterOptions.minPrice
              ).toLocaleString()}`
            : `${merchant.getCurrencySymbol()} ${(
                filterByPriceMin || filterOptions.minPrice
              ).toLocaleString()} - ${merchant.getCurrencySymbol()} ${(
                filterByPriceMax || filterOptions.maxPrice
              ).toLocaleString()}`}
        </Text>
      </HStack>
    }>
      <Slider
        w="90%"
        min={filterOptions.minPrice}
        max={filterOptions.maxPrice}
        disabled={filterOptions.minPrice === filterOptions.maxPrice}
        colorPalette={colorScheme}
        value={rangeSliderValues}
        onValueChange={({ value: newRangeSliderValues }) => {
          setRangeSliderValues(newRangeSliderValues);
        }}
        onValueChangeEnd={({ value: val }) => {
          const newMinValue = val[0];

          if (newMinValue === filterOptions.minPrice) {
            dispatch(setFilterByPriceMin(null));
          } else {
            dispatch(setFilterByPriceMin(newMinValue));
          }

          const newMaxValue = val[1];

          if (newMaxValue === filterOptions.maxPrice) {
            dispatch(setFilterByPriceMax(null));
          } else {
            dispatch(setFilterByPriceMax(newMaxValue));
          }
        }}
      />
    </Field>
  );
};

export default PriceFilter;
