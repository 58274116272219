import { useAuth0 } from "@auth0/auth0-react";
import { Button, Input, VStack } from "@chakra-ui/react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import Spinner from "components/spinner";
import { ClipboardIconButton, ClipboardRoot } from "components/ui/clipboard";
import { InputGroup } from "components/ui/input-group";
import React, { useEffect, useState } from "react";

import { useAppSelector } from "redux/hooks";
import MerchantService from "services/merchant";

const ApiKey = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const { merchant } = useAppSelector((state) => state.merchant);

  const loadApiKey = async () => {
    const currentApiKey = await MerchantService.getApiKey(
      auth0Context,
      merchant.id
    );
    if (currentApiKey) {
      setApiKey(currentApiKey);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadApiKey();
  }, []);

  const generateApiKey = async () => {
    setIsGenerating(true);
    const generatedApiKey = await MerchantService.generateApiKey(
      auth0Context,
      merchant.id
    );
    if (generatedApiKey) {
      setApiKey(generatedApiKey);
    }
    setIsGenerating(false);
  };

  return (
    <VStack gap={4} w="100%">
      {isLoading ? (
        <Spinner />
      ) : (
        <ClipboardRoot value={apiKey} asChild>
          <InputGroup
            endElement={
              <>
                {apiKey && <ClipboardIconButton />}
                <Button
                  colorPalette={apiKey ? "green" : colorScheme}
                  onClick={
                    apiKey ? () => setIsRefreshing(true) : generateApiKey
                  }
                  size="xs"
                  loading={isGenerating || isRefreshing}
                >
                  {apiKey ? "Refresh" : "Generate"}
                </Button>
              </>
            }
          >
            <Input
              colorPalette={colorScheme}
              disabled={true}
              value={
                apiKey
                  ? `${apiKey.substring(0, 4)}*****${apiKey.substring(
                      apiKey.length - 4,
                      apiKey.length
                    )}`
                  : ""
              }
              placeholder="Generate your API Key"
              readOnly
            />
          </InputGroup>
        </ClipboardRoot>
      )}
      <ConfirmationDialog
        isOpen={isRefreshing}
        setIsOpen={setIsRefreshing}
        headerText="Do you really want to refresh your current API Key?"
        messageText="If you refresh your API key, associated Zaps won't work until updated with a new API key in the Zapier console. Do you really want to refresh your current API Key?"
        buttonText="Refresh"
        confirmationCallback={generateApiKey}
      />
    </VStack>
  );
};

export default ApiKey;
