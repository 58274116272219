import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import BookingsOutcomesCountDomain from "entities/domain/calendar/bookings-analytics";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import CalendarsService from "services/calendar";
import { VictoryChart, VictoryLabel, VictoryLegend, VictoryPie } from "victory";
import customTheme from "./customVictoryTheme";

interface BookingsAnalyticsPageProps {
  fromDate: Date | null;
  toDate: Date | null;
}

const BookingsAnalyticsPage = ({
  fromDate,
  toDate,
}: BookingsAnalyticsPageProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [bookingsOutcomesCount, setBookingsOutcomesCount] =
    useState<BookingsOutcomesCountDomain | null>(null);

  const loadBookingsOutcomesCount = async () => {
    setIsLoading(true);

    try {
      const newBookingsOutcomesCount =
        await CalendarsService.getBookingsOutcomesCount(
          auth0Context,
          merchant.id,
          fromDate!.toISOString(),
          toDate!.toISOString()
        );

      setBookingsOutcomesCount(newBookingsOutcomesCount);
    } catch (error) {
      toaster.create({
        type: "error",
        title: "We were unable to retrieve analytics data at this time",
        description: "Please try again or contact us if the issue persists.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadBookingsOutcomesCount();
  }, [toDate, fromDate, merchant.id]);

  return isLoading ? null : (
    <Box w="100%" h="100%">
      <Flex
        gridGap={2}
        justifyContent={isBaseSize ? "center" : "space-between"}
        alignItems="center"
        w="100%"
        p={4}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
      >
        {isBaseSize ? null : (
          <Button colorPalette={colorScheme} variant="subtle">
            Chart 📊
          </Button>
        )}
        <HStack>
          <Badge fontSize={isBaseSize ? 12 : 16} colorPalette="orange">
            No Outcome: {bookingsOutcomesCount?.outcomes.noOutcome || 0}
          </Badge>
          <Badge fontSize={isBaseSize ? 12 : 16} colorPalette="gray">
            Total:{" "}
            {(bookingsOutcomesCount?.outcomes.showed.converted || 0) +
              (bookingsOutcomesCount?.outcomes.showed.notConverted || 0) +
              (bookingsOutcomesCount?.outcomes.notShowed.needsToBeContacted ||
                0) +
              (bookingsOutcomesCount?.outcomes.notShowed.contacted || 0) +
              (bookingsOutcomesCount?.outcomes.notShowed.rescheduled || 0) +
              (bookingsOutcomesCount?.outcomes.noOutcome || 0)}
          </Badge>
        </HStack>
      </Flex>
      <Flex direction={isBaseSize ? "column" : "row"} w="100%" px={8}>
        <VictoryChart theme={customTheme}>
          <VictoryPie
            standalone={false}
            width={300}
            height={300}
            data={[
              {
                x: bookingsOutcomesCount?.outcomes.showed.converted || 0,
                y: bookingsOutcomesCount?.outcomes.showed.converted || 0,
                fill: "mediumspringgreen",
              },
              {
                x: bookingsOutcomesCount?.outcomes.showed.notConverted || 0,
                y: bookingsOutcomesCount?.outcomes.showed.notConverted || 0,
                fill: "cornflowerblue",
              },
            ]}
            innerRadius={50}
            labelPosition="centroid"
            labelRadius={90}
            labels={({ datum }) => (datum.y ? `${datum.y}` : "")}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
          />
          <VictoryLabel
            textAnchor="middle"
            x={175}
            y={175}
            lineHeight={1.5}
            style={{
              fontSize: 16,
              fill: colorMode === "dark" ? "white" : "black",
            }}
            text={[
              "Showed up",
              (
                (bookingsOutcomesCount?.outcomes.showed.converted || 0) +
                (bookingsOutcomesCount?.outcomes.showed.notConverted || 0)
              ).toString(),
            ]}
          />
          <VictoryLegend
            orientation="vertical"
            data={[
              {
                name: "Bought a vehicle",
                symbol: { fill: "mediumspringgreen" },
              },
              {
                name: "Did not buy",
                symbol: { fill: "cornflowerblue" },
              },
            ]}
            style={{
              labels: {
                fill: colorMode === "dark" ? "white" : "black",
              },
            }}
          />
        </VictoryChart>
        <VictoryChart theme={customTheme}>
          <VictoryPie
            width={300}
            height={300}
            data={[
              {
                x:
                  bookingsOutcomesCount?.outcomes.notShowed
                    .needsToBeContacted || 0,
                y:
                  bookingsOutcomesCount?.outcomes.notShowed
                    .needsToBeContacted || 0,
                fill: "tomato",
              },
              {
                x: bookingsOutcomesCount?.outcomes.notShowed.contacted || 0,
                y: bookingsOutcomesCount?.outcomes.notShowed.contacted || 0,
                fill: "orange",
              },
              {
                x: bookingsOutcomesCount?.outcomes.notShowed.rescheduled || 0,
                y: bookingsOutcomesCount?.outcomes.notShowed.rescheduled || 0,
                fill: "gold",
              },
            ]}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            labels={({ datum }) => (datum.y ? `${datum.y}` : "")}
            innerRadius={50}
            labelRadius={90}
            labelPosition="centroid"
            colorScale="qualitative"
          />
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontSize: 16,
              fill: colorMode === "dark" ? "white" : "black",
            }}
            x={175}
            y={175}
            lineHeight={1.5}
            text={[
              "No show",
              (
                (bookingsOutcomesCount?.outcomes.notShowed.contacted || 0) +
                (bookingsOutcomesCount?.outcomes.notShowed.needsToBeContacted ||
                  0) +
                (bookingsOutcomesCount?.outcomes.notShowed.rescheduled || 0)
              ).toString(),
            ]}
          />
          <VictoryLegend
            orientation="vertical"
            data={[
              {
                name: "Needs to be contacted",
                symbol: { fill: "tomato" },
              },
              {
                name: "Contacted, not rescheduled",
                symbol: { fill: "orange" },
              },
              {
                name: "Rescheduled",
                symbol: { fill: "gold" },
              },
            ]}
            style={{
              labels: {
                fill: colorMode === "dark" ? "white" : "black",
              },
            }}
          />
        </VictoryChart>
      </Flex>
    </Box>
  );
};

export default BookingsAnalyticsPage;
