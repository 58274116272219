import PhoneInputWithSuspense, {
  isValidPhoneNumber,
} from "components/shared/LazyPhoneInput";
import { WarningTextProps } from "components/shared/WarningTextComponent";
import { useColorMode } from "components/ui/color-mode";
import { Field } from "components/ui/field";
import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import { ChannelHandleType } from ".";

type PhoneBasedChannelProps = {
  channelValue: string;
  setHandle: (value: ChannelHandleType | null) => void;
};

interface CountryCodeOptionTypes {
  value: string;
  label: string;
}

const countryCodeOptions: CountryCodeOptionTypes[] = [
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
];

const PhoneBasedChannel = ({
  channelValue,
  setHandle,
}: PhoneBasedChannelProps) => {
  const [countryCode, setCountryCode] = useState<string>("");
  const [identifierValue, setIdentifierValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<CountryCodeOptionTypes>>(null);
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  useEffect(() => {
    setWarning(undefined);
  }, []);

  const isValidPhone = () => {
    return identifierValue &&
      identifierValue !== "" &&
      !isValidPhoneNumber(identifierValue)
      ? setWarning({
          key: "invalid_phone_number",
          description: "* Invalid phone number format",
        })
      : setWarning(undefined);
  };

  const handleChangeCountryCode = (e: SingleValue<CountryCodeOptionTypes>) => {
    setCountryCode(e!.value);
    setSelectedCountryCodeValue(e);
    setIdentifierValue("");
    setWarning(undefined);
    setHandle({ country_code: e!.value });
  };

  const handleChangeIdentifier = (value: any) => {
    setIdentifierValue(value);
    setCountryCode("");
    setSelectedCountryCodeValue(null);
    if (value && isValidPhoneNumber(value)) {
      setHandle({ identifier: value });
    } else {
      setHandle(null);
    }
  };

  return (
    <>
      <Field
        label="Phone Number"
        helperText="You can manually enter phone number here"
        css={{
          "& .PhoneInputInput": {
            backgroundColor: "inherit",
            color: "inherit",
          },
        }}
        required
        invalid={warning && warning.key === "invalid_phone_number"}
        errorText={warning && warning.description}
      >
        <PhoneInputWithSuspense
          id="phoneInput"
          style={{ borderRadius: "18px", width: "100%" }}
          placeholder="Enter phone number"
          onChange={handleChangeIdentifier}
          value={identifierValue}
          autoFocus={true}
          onBlur={isValidPhone}
        />
      </Field>

      <Field
        label="Country Code"
        helperText="Or you can buy a phone number below"
        required
        style={{ display: channelValue === "sms" ? "" : "none" }}
      >
        <Select
          placeholder="Select Country Code"
          onChange={handleChangeCountryCode}
          options={countryCodeOptions}
          value={selectedCountryCodeValue}
          styles={getReactSelectStyles(colorMode || "light", colorScheme)}
        />
      </Field>
    </>
  );
};

export default PhoneBasedChannel;
