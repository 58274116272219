import { useColorMode } from "components/ui/color-mode";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { setColorMode } from "redux/features/theme";
import { useAppDispatch } from "redux/hooks";

const PublicRoot = () => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();

  useEffect(() => {
    dispatch(setColorMode((colorMode as "dark" | "light") || "light"));
  }, [colorMode]);

  return <Outlet />;
};

export default PublicRoot;
