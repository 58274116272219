import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogRoot,
} from "components/ui/dialog";
import { toaster } from "components/ui/toaster";
import React, { memo, useEffect, useState } from "react";
import { LuMail } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";

interface ExportToEmailModalProps {
  isOpen: boolean;
  conversationId: number;
  onClose: () => void;
}

const ExportToEmailModal = memo(
  ({ onClose, conversationId, isOpen }: ExportToEmailModalProps) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );

    const auth0Context = useAuth0();
    const { merchant } = useAppSelector((state) => state.merchant);
    const { colorScheme } = useAppSelector((state) => state.theme);

    const [email, setEmail] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const resetValues = () => {
      setIsSubmitting(false);
      setEmail("");
    };

    const submitExportAndClose = () => {
      setIsSubmitting(true);
      InboxService.exportConversation(
        auth0Context,
        conversationId,
        email,
        merchant.id
      )
        .then(() => {
          toaster.create({ type: "success", title: "Exported Successfully" });
          setIsSubmitting(false);
        })
        .catch(() =>
          toaster.create({
            type: "error",
            title: "Failed to export conversation",
          })
        )
        .finally(() => {
          resetValues();
          onClose();
        });
    };

    useEffect(() => {
      if (!isOpen) {
        resetValues();
      }
    }, [isOpen]);

    return (
      <DialogRoot
        open={isOpen}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
          }
        }}
        size={isBaseSize ? "full" : "xl"}
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogCloseTrigger _focus={{ outline: "none" }} />

          <Flex alignItems="center" padding="28px 40px">
            <Icon as={LuMail} height="22.4px" marginRight="12px" />
            <Heading as="h4">Export to Email</Heading>
          </Flex>
          {isSubmitting ? (
            <Box m={10}>
              <Spinner />
            </Box>
          ) : (
            <DialogBody>
              <Input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="e.g. leads@enquirymax.com"
              />
              <Button
                colorPalette={colorScheme}
                disabled={email === ""}
                onClick={submitExportAndClose}
                width="100%"
                mt={8}
                mb={4}
                size="md"
              >
                Confirm
              </Button>
            </DialogBody>
          )}
        </DialogContent>
      </DialogRoot>
    );
  }
);

export default ExportToEmailModal;
