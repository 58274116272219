import { Button, Flex, Icon, Input, Text } from "@chakra-ui/react";
import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import { Checkbox } from "components/ui/checkbox";
import { DialogBody, DialogFooter } from "components/ui/dialog";
import { NumberInputField, NumberInputRoot } from "components/ui/number-input";
import React from "react";
import { LuUpload } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface PaymentsStepOneProps {
  proceed: () => void;
  handleOnAmountChange: (amount: number) => void;
  servicesRendered: string;
  setServicesRendered: (servicesRendered: string) => void;
  warning: WarningTextProps | undefined;
  setProceedWithInvoice: (value: boolean) => void;
  proceedWithInvoice: boolean;
  showInvoice: boolean;
}

const PaymentsStepOne = ({
  proceed,
  warning,
  handleOnAmountChange,
  servicesRendered,
  setServicesRendered,
  setProceedWithInvoice,
  proceedWithInvoice,
  showInvoice,
}: PaymentsStepOneProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <>
      <DialogBody maxHeight="50vh" w="100%">
        <Flex width="100%" flexFlow="column nowrap" alignItems="center">
          <Text display="block" mb="4px" fontSize="md">
            Amount
          </Text>
          {warning && warning.key === "invalid_payment_amount" && (
            <WarningTextComponent
              isValid={false}
              text={warning.description}
              id="invalid-payment-amount"
            />
          )}
          <NumberInputRoot
            defaultValue="0"
            formatOptions={{
              style: "currency",
              currency: merchant.getCurrency(),
              currencyDisplay: "code",
              currencySign: "accounting",
            }}
            onValueChange={({ valueAsNumber }) => {
              handleOnAmountChange(valueAsNumber);
            }}
          >
            <NumberInputField />
          </NumberInputRoot>
        </Flex>

        <Flex
          width="100%"
          flexFlow="column nowrap"
          alignItems="center"
          mb="1rem"
        >
          <Text display="block" mb="4px" fontSize="md">
            Reference
          </Text>
          {warning && warning.key === "invalid_description" && (
            <WarningTextComponent
              isValid={false}
              text={warning.description}
              id="invalid-payment-description"
            />
          )}
          <Input
            placeholder="Details Here"
            value={servicesRendered}
            onChange={(e) => setServicesRendered(e.target.value)}
            id="amount-description"
          />
        </Flex>

        {showInvoice && (
          <Checkbox
            checked={proceedWithInvoice}
            onCheckedChange={(e) => setProceedWithInvoice(!!e.checked)}
          >
            Send with invoice
          </Checkbox>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          colorPalette={colorScheme}
          onClick={proceed}
          id="payment-step-one-confirm"
          disabled={!!warning}
        >
          Confirm and Send
          <Icon as={LuUpload} color="white" />
        </Button>
      </DialogFooter>
    </>
  );
};

export default PaymentsStepOne;
