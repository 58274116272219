import { Button, DialogBackdrop, Input, VStack } from "@chakra-ui/react";
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import React, { useCallback, useEffect, useState } from "react";
import { LuStepForward } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface AddCustomFieldModalProps {
  isOpen: boolean;
  onClose: () => void;
  newCustomFieldKey: string;
  setNewCustomFieldKey: (key: string) => void;
  isPersonalizedCustomField: boolean;
  addNewCustomField: (key: string, value: string) => void;
  onCustomFieldSelect: (cf: string) => void;
  customFields: { [key: string]: string };
}

const AddCustomFieldModal: React.FC<AddCustomFieldModalProps> = ({
  isOpen,
  onClose,
  newCustomFieldKey,
  setNewCustomFieldKey,
  isPersonalizedCustomField,
  addNewCustomField,
  onCustomFieldSelect,
  customFields,
}) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [newCustomFieldValue, setNewCustomFieldValue] = useState<string>("");
  const [newCustomFieldKeyError, setNewCustomFieldKeyError] =
    useState<string>("");

  useEffect(() => {
    if (!newCustomFieldKey) {
      setNewCustomFieldKeyError("");
      return;
    }

    if (
      customFields[newCustomFieldKey] !== undefined &&
      isPersonalizedCustomField === true
    ) {
      setNewCustomFieldKeyError("A custom field with this name already exists");
    } else {
      setNewCustomFieldKeyError("");
    }
  }, [newCustomFieldKey]);

  const resetCustomFieldForm = useCallback(() => {
    setNewCustomFieldKey("");
    setNewCustomFieldValue("");
    setNewCustomFieldKeyError("");
  }, []);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>Add Personalized Custom Field</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <VStack>
            <Field
              label="Name"
              invalid={!!newCustomFieldKeyError}
              required={true}
              errorText={newCustomFieldKeyError}
            >
              <Input
                size="lg"
                placeholder="pet_name"
                value={newCustomFieldKey}
                onChange={(e) => setNewCustomFieldKey(e.target.value)}
                disabled={!isPersonalizedCustomField}
              />
            </Field>
            <Field label="Example" required>
              <Input
                placeholder="Doctor Dog"
                value={newCustomFieldValue}
                onChange={(e) => setNewCustomFieldValue(e.target.value)}
                autoFocus
              />
            </Field>
          </VStack>
        </DialogBody>
        <DialogFooter display="flex" justifyContent="space-between">
          <Button
            colorPalette={colorScheme}
            variant="ghost"
            onClick={() => {
              onClose();
              resetCustomFieldForm();
            }}
          >
            Close
          </Button>
          <Button
            colorPalette={colorScheme}
            onClick={() => {
              addNewCustomField(newCustomFieldKey, newCustomFieldValue);
              onCustomFieldSelect(`{${newCustomFieldKey}}`);
              resetCustomFieldForm();
              onClose();
            }}
            disabled={
              !newCustomFieldKey ||
              !newCustomFieldValue ||
              !!newCustomFieldKeyError
            }
          >
            Create & Use <LuStepForward />
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default AddCustomFieldModal;
