import { useAuth0 } from "@auth0/auth0-react";
import { Button, Heading, Icon } from "@chakra-ui/react";
import { ReactComponent as GoogleIcon } from "assets/icons/google_colored.svg";
import { openReviewsIntegration } from "components/google";
import { toaster } from "components/ui/toaster";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { updateMerchantSuccess } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import IntegrationService from "services/integrations";

interface GoogleReviewsIntegrationItemProps {}

const GoogleReviewsIntegrationItem = (
  _props: GoogleReviewsIntegrationItemProps
) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);

  const { colorScheme } = useAppSelector((state) => state.theme);
  const isConnected = merchant.isIntegrationEnabled(
    IntegrationName.GOOGLE_REVIEWS
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onDisconnectGoogleReviews = async () => {
    try {
      await IntegrationService.removeGoogleIntegration(
        auth0Context,
        merchant.id
      );

      merchant.integrations[IntegrationName.GOOGLE_REVIEWS].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toaster.create({
        type: "error",
        title:
          "Uh Oh! We could not disconnect Google Reviews at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      isConnected={isConnected}
      icon={<Icon as={GoogleIcon} height="3rem" width="3rem" />}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectGoogleReviews} />
        ) : null
      }
      primaryButton={
        isConnected || isDisabled ? null : (
          <Button
            colorPalette={colorScheme}
            onClick={() => {
              openReviewsIntegration(merchant.id);
            }}
          >
            <Icon as={LuPlus} /> Connect
          </Button>
        )
      }
    >
      <Heading as="h3" size="lg">
        Google Reviews
      </Heading>
      <IntegrationDescription text="Install Reviews in 2 minutes" />
    </Integration>
  );
};

export default GoogleReviewsIntegrationItem;
