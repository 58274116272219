import { useAuth0 } from "@auth0/auth0-react";
import {
  AbsoluteCenter,
  Box,
  Button,
  Separator,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import React, { useCallback, useEffect, useState } from "react";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";
import { batch } from "react-redux";
import Select, { SingleValue } from "react-select";
import {
  addTeam,
  clearSelectedAgents,
  propagateTeamUpdate,
} from "redux/features/agents";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { getReactSelectStyles } from "util/methods";
import TeamForm from "./TeamForm";

interface AddToTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface TeamSelectOption {
  value: string;
  label: string;
}

const AddToTeamModal = ({ isOpen, onClose }: AddToTeamModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { teams, selectedAgentIds } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const [newTeamName, setNewTeamName] = useState<string>("");
  const [newTeamPurpose, setNewTeamPurpose] = useState<string>("");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>(
    teams.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  );

  useEffect(() => {
    setTeamOptions(
      teams.map((team) => ({
        label: team.name,
        value: team.id,
      }))
    );
  }, [teams]);

  const [selectedTeamValue, setSelectedTeamValue] =
    useState<SingleValue<TeamSelectOption>>(null);

  const [isCreatingNewTeam, setIsCreatingNewTeam] = useState<boolean>(false);

  const setNewTeamNameMemoized = useCallback(setNewTeamName, []);
  const setNewTeamPurposeMemoized = useCallback(setNewTeamPurpose, []);

  const updateTeam = async () => {
    setIsLoading(true);

    const selectedTeam = teams.find(
      (team) => team.id === selectedTeamValue?.value
    );

    if (!selectedTeam) {
      toaster.create({
        title: "Please select a team and then try again!",
        type: "error",
      });

      return;
    }

    try {
      const updatedTeam = await AgentsService.updateTeam(auth0Context, {
        merchant_id: merchant.id,
        id: selectedTeam.id,
        agent_ids: [
          ...new Set([...selectedTeam.agentIds, ...selectedAgentIds]),
        ],
      });

      batch(() => {
        dispatch(propagateTeamUpdate(updatedTeam));
        dispatch(clearSelectedAgents());
      });
      onClose();
      toaster.create({
        title: "Team updated successfully!",
        type: "success",
      });
    } catch (_e: unknown) {
      toaster.create({
        title: "Error updating team, try again later or contact support!",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createTeam = async () => {
    setIsLoading(true);

    try {
      const newTeam = await AgentsService.createTeam(auth0Context, {
        name: newTeamName,
        purpose: newTeamPurpose,
        merchant_id: merchant.id,
        agent_ids: selectedAgentIds,
      });

      batch(() => {
        dispatch(addTeam(newTeam));
        dispatch(clearSelectedAgents());
      });
      onClose();
      toaster.create({
        title: "Team created successfully!",
        type: "success",
      });
    } catch (_e: unknown) {
      toaster.create({
        title: "Error creating team, try again later or contact support!",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (teams.length === 0) {
      setIsCreatingNewTeam(true);
    } else if (!newTeamName && !newTeamPurpose) {
      setIsCreatingNewTeam(false);
    }
  }, [teams]);

  useEffect(() => {
    setSelectedTeamValue(null);
    setNewTeamName("");
    setNewTeamPurpose("");
  }, [isOpen]);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open: newIsOpen }) => {
        if (!newIsOpen) {
          onClose();
        }
      }}
      size={isBaseSize ? "full" : "md"}
    >
      <DialogBackdrop />
      <DialogContent zIndex={9999}>
        <DialogHeader>
          {isCreatingNewTeam ? "Create a new team" : "Add to a team"}
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <VStack>
            {isCreatingNewTeam ? null : (
              <Field label="Select a team">
                <Select
                  placeholder="Select team"
                  onChange={(newTeam) => setSelectedTeamValue(newTeam)}
                  isClearable={true}
                  options={teamOptions}
                  value={selectedTeamValue}
                  styles={{
                    ...{
                      ...getReactSelectStyles(
                        colorMode || "light",
                        colorScheme
                      ),
                      container: (provided: any) => ({
                        ...provided,
                        width: "100%",
                      }),
                    },
                  }}
                />
              </Field>
            )}
            {teams.length === 0 ? null : (
              <Box
                position="relative"
                py={isCreatingNewTeam ? 4 : 8}
                px={2}
                w="100%"
              >
                <Separator />
                <AbsoluteCenter
                  bg={colorMode === "dark" ? "gray.700" : "white"}
                  px={4}
                >
                  <Button
                    variant="ghost"
                    colorPalette="gray"
                    onClick={() => {
                      const newIsCreatingNewTeam = !isCreatingNewTeam;

                      if (newIsCreatingNewTeam) {
                        setSelectedTeamValue(null);
                      } else {
                        setNewTeamName("");
                        setNewTeamPurpose("");
                      }

                      setIsCreatingNewTeam(!isCreatingNewTeam);
                    }}
                  >
                    {isCreatingNewTeam
                      ? "or add to existing one"
                      : "or create a new one"}
                    {isCreatingNewTeam ? <LuChevronUp /> : <LuChevronDown />}
                  </Button>
                </AbsoluteCenter>
              </Box>
            )}
            {isCreatingNewTeam ? (
              <TeamForm
                teamName={newTeamName}
                setTeamName={setNewTeamNameMemoized}
                teamPurpose={newTeamPurpose}
                setTeamPurpose={setNewTeamPurposeMemoized}
                isLoading={isLoading}
              />
            ) : null}
          </VStack>
        </DialogBody>
        <DialogFooter>
          {!isCreatingNewTeam && selectedTeamValue ? (
            <Button
              onClick={updateTeam}
              colorPalette={colorScheme}
              disabled={isLoading}
            >
              Add
            </Button>
          ) : null}
          {isCreatingNewTeam && newTeamName && newTeamPurpose ? (
            <Button
              onClick={createTeam}
              colorPalette={colorScheme}
              disabled={isLoading}
            >
              Create Team
            </Button>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default AddToTeamModal;
