import {
  HStack,
  Separator,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useColorMode } from "components/ui/color-mode";
import { SkeletonText } from "components/ui/skeleton";
import React from "react";

const SkeletonOverlay = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <VStack
      h="100%"
      w="100%"
      position="absolute"
      top={0}
      left={0}
      overflow="hidden"
      zIndex={50}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      {[...Array(5).keys()].map((i) => (
        <>
          {i === 0 ? null : <Separator />}
          <HStack
            w="100%"
            justifyContent="start"
            alignItems="center"
            py={isBaseSize ? 4 : 6}
            px={isBaseSize ? 2 : 8}
          >
            <SkeletonText
              noOfLines={1}
              height={16}
              width={16}
              borderRadius="xl"
            />
            <SkeletonText noOfLines={1} height={4} />
            <SkeletonText noOfLines={1} height={4} />
            <SkeletonText noOfLines={1} height={4} />
            {isBaseSize ? null : <SkeletonText noOfLines={1} height={4} />}
          </HStack>
        </>
      ))}
    </VStack>
  );
};

export default SkeletonOverlay;
