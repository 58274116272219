import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input, useBreakpointValue } from "@chakra-ui/react";
import { AxiosError } from "axios";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateAttributeSuccess } from "redux/features/group";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AttributeService from "services/attributes";
import AttributeTypeSelect from "./attribute-type-select";

interface EditAttributeProps {}

const EditContactAttribute = (_props: EditAttributeProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { merchant } = useAppSelector((state) => state.merchant);

  const { attributeId: attributeIdParam } = useParams<{
    attributeId: string;
  }>();
  const { attributes } = useAppSelector((state) => state.group);
  const attributeToShow = attributes.find((a) => a.id === attributeIdParam);

  const [attributeName, setAttributeName] = useState(
    attributeToShow?.name || ""
  );

  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");

  const updateAttribute = useCallback(async () => {
    if (attributeToShow && attributeName) {
      const updatedAttribute = await AttributeService.updateAttribute(
        auth0Context,
        merchant.groupId,
        attributeToShow.id,
        {
          name: attributeName,
        }
      );
      dispatch(updateAttributeSuccess(updatedAttribute));
    }
  }, [attributeToShow?.id, merchant.groupId, attributeName]);

  const onClose = useCallback(() => {
    navigate({
      pathname: `/${merchant.id}/settings/attributes`,
    });
  }, []);

  const onClick = useCallback(async () => {
    const isNameValid = attributeName !== "";

    if (!isNameValid) {
      setNameErrorMessage(isNameValid ? "Name is required." : "");

      return;
    }

    try {
      await updateAttribute();

      setNameErrorMessage("");

      onClose();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          setNameErrorMessage(error.response.data.message);
        } else if (error.response?.status === 404) {
          setNameErrorMessage(
            "The attribute you are trying to update is not found in our records. Please contact support."
          );
        }
      } else {
        setNameErrorMessage(
          "An error occured. Could not create the attribute."
        );
      }
    }
  }, [attributeName, updateAttribute]);

  return (
    <DialogRoot
      size={isBaseSize ? "full" : "xl"}
      open={true}
      onInteractOutside={onClose}
      onEscapeKeyDown={onClose}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>Edit Attribute</DialogHeader>
        <DialogCloseTrigger onClick={onClose} />
        <DialogBody>
          <Fieldset.Root width="100%">
            <Fieldset.Content>
              <Field
                label="Name"
                errorText={nameErrorMessage}
                invalid={nameErrorMessage !== ""}
              >
                <Input
                  colorPalette={colorScheme}
                  placeholder="Enter new attribute name"
                  value={attributeName}
                  onChange={(e) => setAttributeName(e.target.value)}
                />
              </Field>
              <Field label="Data Type">
                <AttributeTypeSelect
                  attributeTypeToShow={attributeToShow?.type}
                  isDisabled={true}
                />
              </Field>
            </Fieldset.Content>
          </Fieldset.Root>
        </DialogBody>
        <DialogFooter>
          <Button onClick={onClick}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default EditContactAttribute;
