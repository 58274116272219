import {
  Badge,
  HStack,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import React from "react";
import { LuPlus, LuTrash2 } from "react-icons/lu";

interface BuildingBlockHeaderWrapperProps {
  name: string;
  isCloseable?: boolean;
  children?: React.ReactNode;
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BuildingBlockHeaderWrapper = ({
  name,
  children,
  isOpen,
  isCloseable = true,
  setIsOpen,
}: BuildingBlockHeaderWrapperProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  return (
    <HStack
      className="building-block-header-wrapper"
      p={2}
      {...(isOpen
        ? {
            borderBottomWidth: "2px",
            borderColor: colorMode === "dark" ? "gray.600" : "gray.200",
            borderStyle: "dashed",
          }
        : {})}
      w="100%"
      justifyContent="start"
      alignItems="center"
    >
      <Badge
        className="building-block-header-wrapper"
        colorPalette="gray"
        variant="solid"
        fontSize={isBaseSize ? "lg" : "xl"}
        height="100%"
        display="flex"
        alignItems="center"
        textTransform="uppercase"
      >
        {name}
      </Badge>
      {isOpen ? <HStack justifyContent="start">{children}</HStack> : null}
      {setIsOpen === undefined || !isCloseable ? null : (
        <IconButton
          aria-label="Close block"
          id={`${isOpen ? "close" : "open"}-${name.toLowerCase()}-button`}
          className="building-block-header-wrapper"
          ml="auto"
          onClick={() => setIsOpen(!isOpen)}
          justifySelf="end"
          colorPalette="gray"
          size="sm"
          borderRadius="full"
        >
          {isOpen ? <LuTrash2 /> : <LuPlus />}
        </IconButton>
      )}
    </HStack>
  );
};

export default BuildingBlockHeaderWrapper;
