import { useAuth0 } from "@auth0/auth0-react";
import { Button, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import TeamDomain from "entities/domain/team";
import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import Select, { SingleValue } from "react-select";
import {
  clearSelectedAgents,
  propagateTeamUpdate,
} from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { getReactSelectStyles } from "util/methods";

interface RemoveFromTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface TeamSelectOption {
  value: string;
  label: string;
}

const RemoveFromTeamModal = ({ isOpen, onClose }: RemoveFromTeamModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { teams, selectedAgentIds, agents } = useAppSelector(
    (state) => state.agents
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamsOfSelectedAgents, setTeamsOfSelectedAgents] = useState<
    TeamDomain[]
  >(
    teams.filter((t) =>
      t.agentIds.some((aid) => selectedAgentIds.includes(aid))
    )
  );

  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>(
    teamsOfSelectedAgents.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  );

  useEffect(() => {
    const newTeamsOfSelectedAgents = teams.filter((t) =>
      t.agentIds.some((aid) => selectedAgentIds.includes(aid))
    );

    setTeamsOfSelectedAgents(newTeamsOfSelectedAgents);
    setTeamOptions(
      newTeamsOfSelectedAgents.map((team) => ({
        label: team.name,
        value: team.id,
      }))
    );
  }, [teams, selectedAgentIds]);

  const [selectedTeamValue, setSelectedTeamValue] =
    useState<SingleValue<TeamSelectOption>>(null);


  const updateTeam = async () => {
    setIsLoading(true);

    const selectedTeam = teams.find(
      (team) => team.id === selectedTeamValue?.value
    );

    if (!selectedTeam) {
      toaster.create({
        title: "Please select a team and then try again!",
        type: "error",
      });

      return;
    }

    try {
      const updatedTeam = await AgentsService.updateTeam(auth0Context, {
        merchant_id: merchant.id,
        id: selectedTeam.id,
        agent_ids: selectedTeam.agentIds.filter(
          (a) => !selectedAgentIds.includes(a)
        ),
      });

      batch(() => {
        dispatch(propagateTeamUpdate(updatedTeam));
        dispatch(clearSelectedAgents());
      });
      onClose();
      toaster.create({
        title: "Team updated successfully!",
        type: "success",
      });
    } catch (_e: unknown) {
      toaster.create({
        title: "Error updating team, try again later or contact support!",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedTeamValue(null);
  }, [isOpen]);

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }} size={isBaseSize ? "full" : "md"}>
      <DialogBackdrop  />
      <DialogContent zIndex={99999}>
        <DialogHeader>Remove from a Team</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <VStack>
            <Field label="Select a team">
              <Select
                placeholder="Select team"
                onChange={(newTeam) => setSelectedTeamValue(newTeam)}
                isClearable={true}
                options={teamOptions}
                value={selectedTeamValue}
                styles={getReactSelectStyles(colorMode || "light", colorScheme)}
              />
            </Field>
            {selectedTeamValue ? (
              <Text
                color={colorMode === "dark" ? "gray.600" : "gray.400"}
                fontWeight="light"
              >
                By proceeding with this action you confirm removing{" "}
                {selectedAgentIds.length} agents from {selectedTeamValue.label}.
                Affected agents are:{" "}
                {selectedAgentIds
                  .map(
                    (aid) =>
                      agents.find((a) => a.id === aid)?.getFullName() || ""
                  )
                  .join(", ")}
                .
              </Text>
            ) : null}
          </VStack>
        </DialogBody>
        <DialogFooter>
          {selectedTeamValue ? (
            <Button
              onClick={updateTeam}
              colorPalette="red"
              disabled={isLoading}
            >
              Remove
            </Button>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default RemoveFromTeamModal;
