import { useAuth0 } from "@auth0/auth0-react";
import { Button, Heading, Icon } from "@chakra-ui/react";
import { ReactComponent as ZapierIcon } from "assets/icons/zapier-icon-svgrepo-com.svg";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import ApiKey from "components/user-settings/Integrations/items/zapier/ApiKey";
import Integration from "components/user-settings/shared/Integration";
import React, { useEffect, useState } from "react";
import { LuLink } from "react-icons/lu";
import { useNavigate } from "react-router";
import { useAppSelector } from "redux/hooks";
import MerchantService from "services/merchant";

const ZapierIntegrationItem = () => {
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isRevoking, setIsRevoking] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const loadApiKey = async () => {
    const currentApiKey = await MerchantService.getApiKey(
      auth0Context,
      merchant.id
    );

    if (currentApiKey) {
      setApiKey(currentApiKey);
    }
  };

  useEffect(() => {
    loadApiKey();
  }, []);

  const revokeApiKey = async () => {
    const isSuccess = await MerchantService.revokeApiKey(
      auth0Context,
      merchant.id
    );
    if (isSuccess) {
      setApiKey("");
    }
    setIsRevoking(false);
  };

  return (
    <Integration
      icon={<Icon as={ZapierIcon} height="3rem" width="3rem" />}
      secondaryButton={
        apiKey ? (
          <>
            <Button
              colorPalette="red"
              variant="ghost"
              onClick={() => {
                setIsRevoking(true);
              }}
              loading={isRevoking}
            >
              Revoke API key
            </Button>
            <ConfirmationDialog
              isOpen={isRevoking}
              setIsOpen={setIsRevoking}
              isDangerous={true}
              headerText="Do you really want to revoke your current API Key?"
              messageText="If you delete your API key, associated Zaps won't work until updated with a new API key in the Zapier console."
              buttonText="Delete"
              confirmationCallback={revokeApiKey}
            />
          </>
        ) : null
      }
      primaryButton={
        <Button
          size="sm"
          colorPalette={colorScheme}
          onClick={() => {
            navigate(`/${merchant.id}/settings/integrations/zapier`);
          }}
        >
          <Icon as={LuLink} /> Add URL
        </Button>
      }
      isConnected={!!apiKey}
    >
      <Heading as="h3" size="lg">
        Zapier
      </Heading>
      <ApiKey />
    </Integration>
  );
};

export default ZapierIntegrationItem;
