import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Card,
  Fieldset,
  Flex,
  HStack,
  Heading,
  List,
  Progress,
  Stack,
  StackSeparator,
  Table,
  Text,
  VStack,
} from "@chakra-ui/react";
import FileDragAndDrop from "components/shared/input-drag-drop/FileDragAndDrop";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBody,
  DialogCloseTrigger,
  DialogHeader,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import { Radio, RadioGroup } from "components/ui/radio";
import { SocketFileProgressStatus } from "entities/ISocketArgs";
import AudienceDomain from "entities/domain/audience";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { ImportContactFromCsvDTO } from "entities/dto/ContactDTO";
import { useWebSocket } from "hooks/use-socket";
import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { IoMdSad } from "react-icons/io";
import { LuCheck } from "react-icons/lu";
import Select, { MultiValue } from "react-select";
import { addTag } from "redux/features/tags";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import { getReactSelectStyles } from "util/methods";
import {
  ExtendedImportOptions,
  ImportOptionContent,
} from "./ExtendedImportContactsTypes";

interface UploadViaCsvOrIcloudProps {
  uploadMethod: ImportOptionContent;
  defaultChannels?: ConversationChannel[];
  onClose: () => void;
  setModalSize?: (val: string) => void;
  afterUploadAsyncCallback?: (
    uploadTagId?: string,
    uploadTagName?: string
  ) => Promise<void>;
}

interface PhoneBaseChannelOption {
  value: ConversationChannel;
  label: string;
}

const UploadViaCsvOrIcloud = ({
  uploadMethod,
  defaultChannels = [ConversationChannel.SMS],
  onClose,
  setModalSize,
  afterUploadAsyncCallback = async () => {},
}: UploadViaCsvOrIcloudProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!setModalSize) {
      return;
    }

    setModalSize("xl");
  }, []);

  const [marketingStatusEnabled, setMarketingStatusEnabled] =
    useState<boolean>(true);
  const phoneBasedChannelsOptions: PhoneBaseChannelOption[] = [
    {
      value: ConversationChannel.SMS,
      label: "SMS",
    },
    {
      value: ConversationChannel.WHATSAPP,
      label: "WhatsApp",
    },
  ];
  const [phoneBasedChannels, setPhoneBasedChannels] = useState<
    ConversationChannel[]
  >([]);
  const [selectedPhoneBasedChannels, setSelectedPhoneBasedChannels] = useState<
    MultiValue<PhoneBaseChannelOption>
  >(
    phoneBasedChannelsOptions.filter((pc) => defaultChannels.includes(pc.value))
  );
  const handleSelectedPhoneBasedChannels = (
    e: MultiValue<PhoneBaseChannelOption>
  ) => {
    if (!e || e.length === 0) {
      setSelectedPhoneBasedChannels([phoneBasedChannelsOptions[0]]);
      return;
    }

    if (!e.map((pc) => pc.value).includes(ConversationChannel.SMS)) {
      setSelectedPhoneBasedChannels([phoneBasedChannelsOptions[0], ...e]);
      return;
    }

    setSelectedPhoneBasedChannels(e);
  };

  useEffect(() => {
    setPhoneBasedChannels(selectedPhoneBasedChannels.map((pc) => pc.value));
  }, [selectedPhoneBasedChannels]);

  const [fileUploadResult, setFileUploadResult] =
    useState<ImportContactFromCsvDTO | null>(null);
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [processingStatus, setProcessingStatus] =
    useState<SocketFileProgressStatus>({} as SocketFileProgressStatus);
  const { addEventHandler, removeEventHandler } = useWebSocket();

  const handleFileUploadProgress = async (args: SocketFileProgressStatus) => {
    if (args.group_id !== merchant.groupId) {
      return;
    }

    if (args.id === fileUploadResult?.upload_id) {
      if (args.status === "done") {
        await afterUploadAsyncCallback(
          fileUploadResult.upload_tag_id,
          fileUploadResult.upload_tag
        );
        dispatch(
          addTag({
            id: fileUploadResult.upload_tag_id,
            name: fileUploadResult.upload_tag,
          })
        );
        setStep(5);
      } else if (args.status === "failed") {
        setFileUploadResult({
          ...fileUploadResult,
          success: false,
        });
        setStep(5);
      }
      setProcessingStatus({
        ...args,
        percentage:
          args.processed < args.total
            ? (args.processed / args.total) * 100
            : 100,
      });
    }
  };

  const [file, setFile] = useState<File | null>(null);

  const uploadValidLines = () => {
    setStep(3);
    onSubmitContactsFile(true);
  };

  useEffect(() => {
    addEventHandler("csv_upload_event", handleFileUploadProgress);

    return () => {
      removeEventHandler("csv_upload_event", handleFileUploadProgress);
    };
  }, [addEventHandler, removeEventHandler, fileUploadResult]);

  const onSubmitContactsFile = async (skip_invalid_rows: Boolean = false) => {
    const data = new FormData();

    if (!file) {
      return null;
    }

    data.append("file", file);

    const isCSVUpload = uploadMethod.title === ExtendedImportOptions.CSV.title;
    const autoGeneratedTag = AudienceDomain.generateUploadTag();

    if (isCSVUpload) {
      data.append("is_subscribed", marketingStatusEnabled.toString());
      data.append("upload_tag", autoGeneratedTag);
      data.append("phone_based_channels", phoneBasedChannels.join(","));
      data.append("skip_invalid_rows", skip_invalid_rows ? "true" : "false");
    }

    const res = await ContactsService.importCustomers(
      auth0Context,
      data,
      merchant.groupId
    );

    setFileUploadResult(res);

    if (res.success) {
      setProcessingStatus({
        status: "processing",
        percentage: 1,
        processed: 0,
        total: 100,
        id: res.upload_id,
        group_id: merchant.groupId,
      });
      setStep(4);
    } else {
      setStep(5);
    }

    return null;
  };

  return (
    <>
      <DialogHeader>{uploadMethod.title}</DialogHeader>
      <DialogCloseTrigger />
      <DialogBody>
        {(step === 2 || step === 3 || step === 4) && (
          <HStack alignItems="flex-start">
            <Box>
              <List.Root as="ol">
                {uploadMethod.instructions.map((point, i) => (
                  <List.Item fontSize="14px" mb="18px" key={i.toString()}>
                    {point}
                  </List.Item>
                ))}
              </List.Root>

              {uploadMethod.extendedInstructions && (
                <>
                  <Text fontWeight="700" mb={3}>
                    {uploadMethod.extendedInstructions.title}
                  </Text>
                  {uploadMethod.extendedInstructions.instructions.map(
                    (point, i) => (
                      <Flex
                        key={i.toString()}
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="18px"
                        mb="10px"
                        alignItems="center"
                      >
                        <LuCheck
                          style={{
                            color: `${colorScheme}.400`,
                            marginRight: 3,
                          }}
                        />
                        <Text color="#9496A9">{point}</Text>
                      </Flex>
                    )
                  )}
                </>
              )}
            </Box>

            {step === 2 && (
              <FileDragAndDrop
                accept="text/csv"
                onClose={() => {}}
                setIsLoading={() => setStep((curr) => (curr < 3 ? 3 : curr))}
                errorMessage="Could not upload contacts from Files"
                setFile={setFile}
                onSubmit={(files) => onSubmitContactsFile()}
              />
            )}

            {step === 3 && (
              <Flex
                height="273px"
                width="473px"
                bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
                borderRadius="12px"
                justify="center"
                align="center"
                flexDir="column"
              >
                <Text>Uploading...</Text>
                <Box width="90%" mt="1rem">
                  <Progress.Root size="md" value={null} />
                </Box>
              </Flex>
            )}

            {step === 4 && (
              <Flex
                height="273px"
                width="473px"
                bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
                borderRadius="12px"
                justify="center"
                align="center"
                flexDir="column"
              >
                <Text>Processing...</Text>
                <Box width="90%" mt="1rem">
                  <Progress.Root
                    size="md"
                    striped
                    animated
                    value={processingStatus.percentage}
                  >
                    <Progress.Track>
                      <Progress.Range />
                    </Progress.Track>
                  </Progress.Root>
                </Box>
              </Flex>
            )}
          </HStack>
        )}

        {step === 5 && fileUploadResult && fileUploadResult.success && (
          <Flex
            height="100%"
            width="100%"
            borderRadius="12px"
            justify="center"
            align="center"
            flexDir="column"
          >
            <VStack my="100px">
              <FiUsers size={50} />
              <Text fontSize="xl">Contacts created successfully</Text>
            </VStack>
          </Flex>
        )}

        {step === 5 && fileUploadResult && !fileUploadResult.success && (
          <Flex
            height="100%"
            maxH="500px"
            width="100%"
            borderRadius="12px"
            justify="center"
            align="center"
            flexDir="column"
          >
            {fileUploadResult.code !== "INVALID_ROWS" && (
              <Box color="red.300" mb="16px">
                <IoMdSad size={50} />
              </Box>
            )}
            <Text fontSize="xl" fontWeight="bold" mb={8}>
              {getErrorDescriptionOrDefault(
                fileUploadResult.code,
                "An unexpected error happened. Please check that your file is a valid CSV file and try again."
              )}
            </Text>
            {fileUploadResult.code === "INVALID_ROWS" && (
              <>
                <Table.Root
                  overflowY="auto"
                  w="90%"
                  mb="24px"
                  variant="line"
                  size="sm"
                  colorPalette={colorScheme}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeader>Line</Table.ColumnHeader>
                      <Table.ColumnHeader>Error</Table.ColumnHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {fileUploadResult.invalid_rows.map((row) =>
                      row.errors.map((error) => (
                        <Table.Row key={row.row_number}>
                          <Table.Cell>{row.row_number}</Table.Cell>
                          <Table.Cell>
                            {getErrorDescriptionOrDefault(
                              error.name,
                              "Unexpected error. Please check this line and try again."
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))
                    )}
                  </Table.Body>
                </Table.Root>
              </>
            )}
          </Flex>
        )}

        {step === 1 && (
          <Fieldset.Root>
            <Fieldset.Content>
              <Field
                label="Please select the marketing status of these customers. Note that this will apply to all customers in this list."
                required
              >
                <RadioGroup
                  my={6}
                  value={marketingStatusEnabled.toString()}
                  onValueChange={({ value }) =>
                    setMarketingStatusEnabled(value === "true")
                  }
                  border="1px solid lightslategray"
                  borderRadius="xl"
                  p={6}
                >
                  <Stack justifyContent="center">
                    <HStack gap={4}>
                      <Radio value="true">
                        <Text fontWeight="semibold">Subscribed</Text>
                      </Radio>
                      <Radio value="false">
                        <Text fontWeight="semibold">Unsubscribed</Text>
                      </Radio>
                    </HStack>
                    <Text pl={6} fontWeight="hairline">
                      {marketingStatusEnabled
                        ? `By selecting "Subscribed", you confirm that there is lawful basis for sending marketing related messages to these contacts from ${
                            merchant.name || "you"
                          }.`
                        : `By selecting "Unsubscribed", you confirm that you do not wish to send marketing related messages to these contacts.`}
                    </Text>
                  </Stack>
                </RadioGroup>
              </Field>
              {uploadMethod.title === ExtendedImportOptions.CSV.title && (
                <Field
                  label="Select communication channels that should be created from encountered phone numbers"
                  required
                >
                  <Select
                    placeholder="Select channels"
                    isMulti={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    isSearchable={false}
                    defaultValue={[phoneBasedChannelsOptions[0]]}
                    isOptionDisabled={(option) => {
                      return option.value === ConversationChannel.SMS;
                    }}
                    required={true}
                    onChange={handleSelectedPhoneBasedChannels}
                    options={phoneBasedChannelsOptions}
                    value={selectedPhoneBasedChannels}
                    styles={{
                      ...{
                        ...getReactSelectStyles(
                          colorMode || "light",
                          colorScheme
                        ),
                        container: (provided: any) => ({
                          ...provided,
                          width: "100%",
                        }),
                      },
                    }}
                  />
                  {merchant.country !== "US" && (
                    <Card.Root mt={4} mx="auto">
                      <Card.Header>
                        <Heading size="md">Supported Phone Numbers</Heading>
                      </Card.Header>

                      <Card.Body>
                        <Stack separator={<StackSeparator />} gap={4}>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Local format
                            </Heading>
                            <Text pt="2" fontSize="sm">
                              Can be uploaded in local format (e.g.
                              &quot;07403564079&quot;)
                            </Text>
                          </Box>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Local format without 0 prefix
                            </Heading>
                            <Text pt="2" fontSize="sm">
                              Can be uploaded in local format without the first
                              0 (e.g. &quot;7403564079&quot;)
                            </Text>
                          </Box>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              Full international format
                            </Heading>
                            <Text pt="2" fontSize="sm">
                              Can be uploaded in international format incl. the
                              + (e.g. &quot;+447403564079&quot;)
                            </Text>
                          </Box>
                          <Box>
                            <Heading size="xs" textTransform="uppercase">
                              International format without + sign
                            </Heading>
                            <Text pt="2" fontSize="sm">
                              Can be uploaded in international format without
                              the first + (e.g. &quot;447403564079&quot;)
                            </Text>
                          </Box>
                        </Stack>
                      </Card.Body>
                    </Card.Root>
                  )}
                </Field>
              )}
            </Fieldset.Content>
          </Fieldset.Root>
        )}
        <Flex justifyContent="end" mt={4} alignItems="center">
          {step === 5 &&
            fileUploadResult &&
            !fileUploadResult.success &&
            fileUploadResult.code === "INVALID_ROWS" && (
              <Button
                borderRadius="full"
                colorPalette={colorScheme}
                onClick={() => uploadValidLines()}
                mr={1}
              >
                Proceed with valid entries
              </Button>
            )}
          {step === 1 && (
            <Button
              borderRadius="full"
              colorPalette={colorScheme}
              onClick={() => setStep(2)}
            >
              Continue
            </Button>
          )}
          {step === 5 && (
            <Button
              borderRadius="full"
              colorPalette={colorScheme}
              onClick={() => onClose()}
            >
              Close
            </Button>
          )}
        </Flex>
      </DialogBody>
    </>
  );
};

export default UploadViaCsvOrIcloud;
