import { useAuth0 } from "@auth0/auth0-react";
import { Icon, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { ReactComponent as PaperPlaneButtonIcon } from "assets/icons/paper-plane-top-regular.svg";
import { AxiosError } from "axios";
import { ConfirmationDialogProps } from "components/shared/ConfirmationDialog";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import { MessageSubType } from "entities/domain/conversations/message-domain";
import { axiosErrorDataToDomainError } from "entities/domain/error/transformer";
import TemplateDomain, { CustomFields } from "entities/domain/templates";
import useAnalytics from "hooks/use-analytics";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { batch } from "react-redux";
import {
  OpenClosedFilter,
  clearMessageInput,
  setActiveConversationOpenClosedFilter,
} from "redux/features/conversations";
import { appendMessage, messagesSelector } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";

export type SendConfirmation =
  | {
      confirmationDialogComponent: React.FC<ConfirmationDialogProps>;
      propsExceptActionCallback: {
        headerText: string;
        messageText: string;
        buttonText: string;
        cancelButtonText: string;
        isOpen: boolean;
        isDangerous: boolean;
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
      };
    }
  | undefined;

interface SendButtonProps {
  isDisabled: boolean;
  text: string;
  onMessageSent: () => void;
  confirmation?: SendConfirmation;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

const SUBJECT_CHAR_LIMIT = 100;

const SendButton = ({
  isDisabled,
  text,
  onMessageSent,
  confirmation,
  textAreaRef,
}: SendButtonProps) => {
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { track } = useAnalytics();

  const auth0Context = useAuth0();
  const conversationMessages = useAppSelector(messagesSelector);
  const [isSending, setIsSending] = useState<boolean>(false);
  const {
    activeConversation,
    messageInput: {
      attachments,
      template,
      subject,
      replySuggestionId,
      replySuggestionCustomFields,
    },
  } = useAppSelector((state) => state.conversations);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const availableCustomFields = useAvailableCustomFields(template);

  const disabledIconColor =
    colorMode === "dark" ? `${colorScheme}.700` : "gray.400";
  const activeIconColor =
    colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.400`;

  const sendIcon = useMemo(
    () => (
      <Icon
        as={PaperPlaneButtonIcon}
        css={{
          "& path": {
            fill: isDisabled ? disabledIconColor : activeIconColor,
          },
        }}
      />
    ),
    [isDisabled, disabledIconColor, activeIconColor]
  );

  const sendMessage = useCallback(async () => {
    if (isDisabled) {
      return;
    }

    try {
      setIsSending(true);

      const requestBody: {
        body: string;
        conversation_id: number;
        title?: string;
        reply_to_message_id?: number;
        template_name?: string;
        custom_fields?: CustomFields;
        file_id?: string;
        reply_suggestion_id?: string;
      } = {
        body: text,
        conversation_id: activeConversation!.id,
        ...(replySuggestionId
          ? { reply_suggestion_id: replySuggestionId }
          : {}),
        ...(replySuggestionId && replySuggestionCustomFields
          ? { custom_fields: replySuggestionCustomFields }
          : {}),
      };

      if (activeConversation!.isEmailChannel()) {
        requestBody.title = subject?.slice(0, SUBJECT_CHAR_LIMIT);
        requestBody.reply_to_message_id = conversationMessages
          .filter(({ subType }) => subType === MessageSubType.REGULAR)
          .pop()?.id;
      }

      if (template) {
        if (
          activeConversation!.isTemplatesOnly() ||
          TemplateDomain.getTextFromTemplate(
            template.text,
            template.customFields
          ) === TemplateDomain.getTextFromTemplate(text, template.customFields)
        ) {
          requestBody.template_name = template.name;
        }
        requestBody.custom_fields = availableCustomFields;
      } else if (attachments.length) {
        // Only one attachment is allowed as of now. It shouldn't be possible
        // to have multiple attachments in the store however if it happens to be
        // like so - let's take last one as the attachment to send in order to
        // avoid any potential issues.
        const lastAttachment = attachments[attachments.length - 1];
        requestBody.file_id = lastAttachment.id;
      }

      const response = await InboxService.sendMessage(
        auth0Context,
        requestBody,
        merchant.id,
        activeConversation!.id
      );

      track("send_message", {
        conversation_id: activeConversation!.id,
        channel: activeConversation!.channel,
        contains_files: !!attachments.length,
        customer_id: activeConversation!.customerId,
        template_id: template?.id,
      });

      batch(() => {
        dispatch(
          appendMessage({
            message: response,
            conversationId: activeConversation!.id,
          })
        );
        dispatch(setActiveConversationOpenClosedFilter(OpenClosedFilter.Open));
        dispatch(clearMessageInput());
        onMessageSent();
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        const domainError = axiosErrorDataToDomainError(error);
        toaster.create({
          type: "error",
          title: "Message couldn't be sent!",
          description: domainError?.errorMessage,
        });
      }
    } finally {
      setIsSending(false);
    }
  }, [
    activeConversation,
    template,
    subject,
    conversationMessages,
    attachments,
    availableCustomFields,
    text, // NB! Text comes as a prop because we want instantenous updates
    isDisabled,
  ]);

  const sendOrTriggerConfirmation = useCallback(() => {
    if (confirmation) {
      const { propsExceptActionCallback } = confirmation;

      propsExceptActionCallback.setIsOpen(true);

      return;
    }

    sendMessage();
  }, [sendMessage, confirmation]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (isBaseSize) {
        return;
      }

      if (isDisabled) {
        return;
      }

      if (
        activeConversation?.isEmailChannel() &&
        e.key === "Enter" &&
        !e.shiftKey
      ) {
        return;
      }

      if (
        !activeConversation?.isEmailChannel() &&
        e.key === "Enter" &&
        !e.shiftKey
      ) {
        e.preventDefault();
        sendOrTriggerConfirmation();
        return;
      }

      if (
        activeConversation?.isEmailChannel() &&
        e.key === "Enter" &&
        e.shiftKey
      ) {
        e.preventDefault();
        sendOrTriggerConfirmation();
        return;
      }
    },
    [activeConversation, isBaseSize, isDisabled, sendOrTriggerConfirmation]
  );

  useEffect(() => {
    if (!textAreaRef.current) {
      return;
    }

    textAreaRef.current.addEventListener("keydown", handleKeyPress);

    return () => {
      if (!textAreaRef.current) {
        return;
      }

      textAreaRef.current.removeEventListener("keydown", handleKeyPress);
    };
  }, [textAreaRef.current, handleKeyPress]);

  const replySuggestionHasCustomFieldsWithoutValue = useMemo(
    () =>
      replySuggestionId &&
      replySuggestionCustomFields &&
      Object.keys(replySuggestionCustomFields || {})
        ? TemplateDomain.containsCustomFieldsWithoutValue(
            text,
            replySuggestionCustomFields || {}
          )
        : false,
    [replySuggestionId, replySuggestionCustomFields, text]
  );

  return (
    <>
      <IconButton
        colorPalette={colorScheme}
        aria-label="Send message"
        variant="ghost"
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& svg": {
            transition: "width 0.2s, height 0.2s",
            height: "1rem",
            width: "1rem",
          },
          "&:hover > svg": {
            width: isDisabled ? "1rem" : "0.8rem",
            height: isDisabled ? "1rem" : "0.8rem",
          },
        }}
        borderRadius="full"
        zIndex={6}
        size="xs"
        onClick={sendOrTriggerConfirmation}
        disabled={
          isDisabled || isSending || replySuggestionHasCustomFieldsWithoutValue
        }
      >
        {sendIcon}
      </IconButton>
      {confirmation ? (
        <confirmation.confirmationDialogComponent
          {...confirmation.propsExceptActionCallback}
          confirmationCallback={sendMessage}
        />
      ) : null}
    </>
  );
};

export default SendButton;
