import { Button, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import useAnalytics from "hooks/use-analytics";
import React, { useState } from "react";
import { FaBuilding } from "react-icons/fa";
import {
  LuCircuitBoard,
  LuFileStack,
  LuFileText,
  LuLock,
  LuMessageSquare,
  LuSmartphone,
  LuUnplug,
  LuUsersRound,
} from "react-icons/lu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import {
  canManageAutomations,
  canManageIntegrations,
  canManageRolePermissions,
} from "util/permissions";

interface DesktopUserSettingsProps {}

enum SettingsRoutes {
  ACCOUNT_OVERVIEW = "account",
  CHANNELS = "channels",
  INTEGRATION_HUB = "integrations",
  TEMPLATE_MESSAGES = "templates",
  AUTOMATIONS = "automations",
  TEAM_MEMBERS = "teammates",
  PERMISSIONS = "permissions",
  FILES = "files",
  CONTACT_ATTRIBUTES = "attributes",
}

const DESKTOP_ROUTE_BY_TAB_INDEX: { [key in number]: SettingsRoutes } = {
  0: SettingsRoutes.ACCOUNT_OVERVIEW,
  1: SettingsRoutes.CHANNELS,
  2: SettingsRoutes.INTEGRATION_HUB,
  3: SettingsRoutes.AUTOMATIONS,
  4: SettingsRoutes.TEMPLATE_MESSAGES,
  5: SettingsRoutes.TEAM_MEMBERS,
  6: SettingsRoutes.PERMISSIONS,
  7: SettingsRoutes.FILES,
  8: SettingsRoutes.CONTACT_ATTRIBUTES,
};

const TAB_INDEX_BY_ROUTE: { [key in SettingsRoutes]: number } = {
  [SettingsRoutes.ACCOUNT_OVERVIEW]: 0,
  [SettingsRoutes.CHANNELS]: 1,
  [SettingsRoutes.INTEGRATION_HUB]: 2,
  [SettingsRoutes.AUTOMATIONS]: 3,
  [SettingsRoutes.TEMPLATE_MESSAGES]: 4,
  [SettingsRoutes.TEAM_MEMBERS]: 5,
  [SettingsRoutes.PERMISSIONS]: 6,
  [SettingsRoutes.FILES]: 7,
  [SettingsRoutes.CONTACT_ATTRIBUTES]: 8,
};

const DesktopUserSettings = (_props: DesktopUserSettingsProps) => {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const { track } = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").filter((p) => !!p)[2];

  const [tabIndex, setTabIndex] = useState<number>(
    typeof TAB_INDEX_BY_ROUTE[path as SettingsRoutes] !== "undefined"
      ? TAB_INDEX_BY_ROUTE[path as SettingsRoutes]
      : 0
  );

  return (
    <Flex
      pr="1rem"
      direction="row"
      overflow="hidden"
      height="100%"
      width="100%"
      minWidth="100%"
      alignItems="start"
    >
      <VStack
        mt="5rem"
        gap={2}
        width="12rem"
        minWidth="12rem"
        maxWidth="12rem"
        pr={2}
      >
        <Button
          pl={8}
          variant={tabIndex === 0 ? "subtle" : "ghost"}
          colorPalette={colorScheme}
          borderLeftRadius={0}
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[0]}-settings`}
          justifyContent="start"
          alignItems="center"
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[0]}_settings`);
            setTabIndex(0);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[0]}`
            );
          }}
          color={
            tabIndex !== 0
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={FaBuilding}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 0
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
            padding={1}
          />
          <Text>Business</Text>
        </Button>
        <Button
          pl={8}
          w="100%"
          colorPalette={colorScheme}
          borderLeftRadius={0}
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[1]}-settings`}
          variant={tabIndex === 1 ? "subtle" : "ghost"}
          justifyContent="start"
          alignItems="center"
          disabled={!canManageIntegrations(merchant.id, currentAgent!)}
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[1]}_settings`);
            setTabIndex(1);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[1]}`
            );
          }}
        >
          <Icon
            as={LuSmartphone}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 1
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text
            color={
              tabIndex !== 1
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            Channels
          </Text>
        </Button>
        <Button
          pl={8}
          w="100%"
          borderLeftRadius={0}
          colorPalette={colorScheme}
          variant={tabIndex === 2 ? "subtle" : "ghost"}
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[2]}-settings`}
          justifyContent="start"
          alignItems="center"
          disabled={!canManageIntegrations(merchant.id, currentAgent!)}
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[2]}_settings`);
            setTabIndex(2);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[2]}`
            );
          }}
        >
          <Icon
            as={LuUnplug}
            padding="0.15rem"
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 2
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text
            color={
              tabIndex !== 2
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            Integrations
          </Text>
        </Button>
        <Button
          pl={8}
          w="100%"
          colorPalette={colorScheme}
          borderLeftRadius={0}
          variant={tabIndex === 3 ? "subtle" : "ghost"}
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[3]}-settings`}
          justifyContent="start"
          alignItems="center"
          disabled={!canManageAutomations(merchant.id, currentAgent!)}
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[3]}_settings`);
            setTabIndex(3);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[3]}`
            );
          }}
        >
          <Icon
            as={LuCircuitBoard}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 3
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text
            color={
              tabIndex !== 3
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            Automations
          </Text>
        </Button>
        <Button
          pl={8}
          variant={tabIndex === 4 ? "subtle" : "ghost"}
          colorPalette={colorScheme}
          borderLeftRadius={0}
          justifyContent="start"
          alignItems="center"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[4]}-settings`}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[4]}_settings`);
            setTabIndex(4);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[4]}`
            );
          }}
          color={
            tabIndex !== 4
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={LuMessageSquare}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 4
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text>Templates</Text>
        </Button>
        <Button
          pl={8}
          variant={tabIndex === 5 ? "subtle" : "ghost"}
          borderLeftRadius={0}
          colorPalette={colorScheme}
          justifyContent="start"
          alignItems="center"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[5]}-settings`}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[5]}_settings`);
            setTabIndex(5);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[5]}`
            );
          }}
          color={
            tabIndex !== 5
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={LuUsersRound}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 5
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text>Teammates</Text>
        </Button>
        {canManageRolePermissions(merchant.id, currentAgent!) ? (
          <Button
            pl={8}
            variant={tabIndex === 6 ? "subtle" : "ghost"}
            colorPalette={colorScheme}
            borderLeftRadius={0}
            justifyContent="start"
            alignItems="center"
            id={`${DESKTOP_ROUTE_BY_TAB_INDEX[6]}-settings`}
            w="100%"
            onClick={() => {
              track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[6]}_settings`);
              setTabIndex(6);
              navigate(
                `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[6]}`
              );
            }}
            color={
              tabIndex !== 6
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          >
            <Icon
              as={LuLock}
              width="1.5rem"
              height="1.5rem"
              color={
                tabIndex !== 6
                  ? colorMode === "dark"
                    ? "gray.200"
                    : "gray.600"
                  : colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.600`
              }
            />
            <Text>Role Permissions</Text>
          </Button>
        ) : null}
        <Button
          pl={8}
          variant={tabIndex === 7 ? "subtle" : "ghost"}
          colorPalette={colorScheme}
          borderLeftRadius={0}
          justifyContent="start"
          alignItems="center"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[7]}-settings`}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[7]}_settings`);
            setTabIndex(7);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[7]}`
            );
          }}
          color={
            tabIndex !== 7
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={LuFileStack}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 7
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : ((colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`) as string)
            }
          />
          <Text>Files</Text>
        </Button>
        <Button
          pl={8}
          variant={tabIndex === 8 ? "subtle" : "ghost"}
          colorPalette={colorScheme}
          borderLeftRadius={0}
          justifyContent="start"
          alignItems="center"
          id={`${DESKTOP_ROUTE_BY_TAB_INDEX[8]}-settings`}
          w="100%"
          onClick={() => {
            track(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[8]}_settings`);
            setTabIndex(8);
            navigate(
              `/${merchant.id}/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[8]}`
            );
          }}
          color={
            tabIndex !== 8
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          <Icon
            as={LuFileText}
            width="1.5rem"
            height="1.5rem"
            color={
              tabIndex !== 8
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.600"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.600`
            }
          />
          <Text>Contact Attributes</Text>
        </Button>
      </VStack>
      <Flex w="100%" h="100%" borderTopRadius="3xl" overflow="hidden">
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default DesktopUserSettings;
