import {
  Button,
  Fieldset,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as AutoTraderIcon } from "assets/icons/auto-trader.svg";
import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot } from "components/ui/dialog";
import { Field } from "components/ui/field";
import Integration from "components/user-settings/shared/Integration";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

const AutoTraderIntegrationItem = () => {
  const merchant = useAppSelector((state) => state.merchant.merchant);

  if (merchant.country !== "GB") {
    return null;
  }

  const {
    open,
    onOpen: originalOnOpen,
    onClose: originalOnClose,
  } = useDisclosure();

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [dealershipId, setDealershipId] = useState("");
  const [autoTraderLink, setAutoTraderLink] = useState("");
  const [postCode, setPostCode] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      dealershipId !== "" && autoTraderLink !== "" && postCode !== ""
    );
  }, [dealershipId, autoTraderLink, postCode]);

  const resetForm = () => {
    setDealershipId("");
    setAutoTraderLink("");
    setPostCode("");
    setIsFormValid(false);
  };

  const onClose = () => {
    resetForm();
    originalOnClose();
  };

  const onOpen = () => {
    resetForm();
    originalOnOpen();
  };

  const handleConnect = () => {
    const subject = encodeURIComponent(
      "AutoTrader Integration Request (Fuzey)"
    );
    const body = encodeURIComponent(
      `Hi,\nCould you please add ${merchant.name} dealership to our Fuzey integration please?\n\nDealer ID: ${dealershipId}\nAutoTrader Link: ${autoTraderLink}\nPost Code: ${postCode}\n\nMany Thanks!`
    );
    const cc = encodeURIComponent("support@getfuzey.com");
    window.location.href = `mailto:technical.support@autotrader.co.uk?subject=${subject}&body=${body}&cc=${cc}`;
    onClose();
  };

  const renderContent = () => {
    return (
      <Integration
        icon={<AutoTraderIcon />}
        primaryButton={
          <Button onClick={onOpen} colorPalette={colorScheme}>
            Connect
          </Button>
        }
      >
        <Text mt={2}>
          Connect your AutoTrader account to automatically sync your leads.
        </Text>
        <Text mt={2} fontSize="sm" color="gray.500">
          Note: This process requires sending an email to AutoTrader support.
        </Text>
      </Integration>
    );
  };

  return (
    <>
      {renderContent()}

      <DialogRoot open={open} onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }} size="md">
        <DialogBackdrop  />
        <DialogContent borderRadius="xl">
          <DialogCloseTrigger />
          <DialogHeader    textAlign="center">Connect to AutoTrader</DialogHeader>
          <DialogBody     p={4}>
            <Alert status="info" mb={3} px={3} title="To enable the integration, we`ll need to send an email to AutoTrader support with your details." />
            <Fieldset.Root gap={3} alignItems="stretch">
              <Fieldset.Content>
                <Field label="Dealership ID">
                  <Input
                    value={dealershipId}
                    onChange={(e) => setDealershipId(e.target.value)}
                    placeholder="12345"
                    bg={colorMode === "dark" ? "whiteAlpha.100" : "gray.100"}
                  />
                </Field>
                <Field label="Link to your AutoTrader dealerships page">
                  <Input
                    value={autoTraderLink}
                    onChange={(e) => setAutoTraderLink(e.target.value)}
                    placeholder="https://www.autotrader.co.uk/dealers/..."
                    bg={colorMode === "dark" ? "whiteAlpha.100" : "gray.100"}
                  />
                </Field>
                <Field label="Post Code">
                  <Input
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    placeholder="SW1A 1AA"
                    bg={colorMode === "dark" ? "whiteAlpha.100" : "gray.100"}
                  />
                </Field>
              </Fieldset.Content>
            </Fieldset.Root>
          </DialogBody>
          <DialogFooter>
            <Button
              onClick={handleConnect}
              colorPalette={colorScheme}
              width="100%"
              disabled={!isFormValid}
            >
              Request Access
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default AutoTraderIntegrationItem;
