import { Flex } from "@chakra-ui/react";
import { useColorMode } from "components/ui/color-mode";
import { SkeletonText } from "components/ui/skeleton";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface SkeletonOverlayProps {}

const SkeletonOverlay: React.FC<SkeletonOverlayProps> = () => {
  const { colorMode } = useColorMode();
  const {
    activeConversation,
    isLoadingActiveConversation,
    messageInput: { isPreparingInput },
  } = useAppSelector((state) => state.conversations);

  return !activeConversation ||
    isLoadingActiveConversation ||
    isPreparingInput ? (
    <Flex
      borderRadius="xl"
      position="absolute"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      width="100%"
      height="100%"
      zIndex={1000}
      top={0}
      left={0}
      p={4}
      justifyContent="start"
      alignItems="start"
    >
      <SkeletonText noOfLines={2} height={4} />
    </Flex>
  ) : null;
};

export default SkeletonOverlay;
