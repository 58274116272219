import { Box } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import AgentDomain from "entities/domain/agents/agent-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { checkMediaValidity } from "util/methods";
import MediaExpiredMessage from "./DownloadExpiredButton";
import ExpandableText from "./ExpandableText";
import MessageWrapper from "./MessageWrapper";

export default ({
  message,
  showTail,
}: {
  message: MessageDomain;
  showTail: boolean;
}) => {
  const { agents } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const [brokenMediaType, setBrokenMediaType] = useState<
    AttachmentType | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agent, setAgent] = useState<AgentDomain | undefined>(
    agents.find((a: AgentDomain) => a.id === message.agentId)
  );

  useEffect(() => {
    setAgent(agents.find((a: AgentDomain) => a.id === message.agentId));
  }, [agents]);

  useEffect(() => {
    const mediaUrl = message.attachments[0]?.url;

    if (mediaUrl) {
      checkMediaValidity(mediaUrl, () => {
        setBrokenMediaType(message?.getAttachmentType());
      });
    }
  }, [message.attachments]);

  return (
    <MessageWrapper
      message={message}
      activeConversation={activeConversation}
      agent={agent}
      showTail={showTail}
    >
      <>
        {activeConversation?.isChannelFacebookOrInstagram() &&
        brokenMediaType === AttachmentType.AUDIO ? (
          <MediaExpiredMessage />
        ) : (
          <>
            <Box mb={1}>
              {activeConversation?.channel === ConversationChannel.WHATSAPP ? (
                <EditorUltra
                  isPrivate={true}
                  fontColor={message.getFontColor()}
                  isEditable={false}
                  isScrollable={false}
                  maxHeight="150px"
                  showMore={true}
                  backgroundColor="inherit"
                  highlightUnknownCustomFields={false}
                  defaultText={{
                    value: message?.body,
                  }}
                  channels={[ConversationChannel.WHATSAPP]}
                  enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
                />
              ) : (
                <ExpandableText
                  message={message}
                  shouldLinkify={message.hasURLs()}
                />
              )}
            </Box>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio
              style={{
                width: "250px",
                height: "30px",
                margin: "5px",
              }}
              controls
              src={message.attachments[0].url}
              onError={(event: any) => {
                setBrokenMediaType(message?.getAttachmentType());
              }}
            >
              <source src={message.attachments[0].url} />
            </audio>
          </>
        )}
      </>
    </MessageWrapper>
  );
};
