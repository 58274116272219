import { defineSlotRecipe } from "@chakra-ui/react";
import { accordionAnatomy } from "@chakra-ui/react/anatomy";

export default defineSlotRecipe({
  slots: accordionAnatomy.keys(),
  variants: {
    variant: {
      subtle: {
        item: {
          borderRadius: "var(--accordion-radius)",
          _open: {
            bg: "#FFF", // true white for subtle accent
            _dark: {
              bg: "gray.800",
            },
          },
        },
      },

      enclosed: {
        root: {
          borderWidth: "1px",
          borderRadius: "var(--accordion-radius)",
          divideY: "1px",
          overflow: "hidden",
        },
        itemTrigger: {
          px: "var(--accordion-padding-x)",
        },
        itemContent: {
          px: "var(--accordion-padding-x)",
        },
        item: {
          _open: {
            bg: "bg.subtle",
          },
        },
      },

      plain: {},
    },
  },
});
