import {
  Avatar,
  Button,
  Fieldset,
  Flex,
  Heading,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import AgentDomain from "entities/domain/agents/agent-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import React, { memo, useEffect, useState } from "react";
import Select, { OptionProps, SingleValue, components } from "react-select";
import {
  clearSelectedConversations,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  updateConversationAgentAndTeamIds,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";

import { useAuth0 } from "@auth0/auth0-react";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import { LuUserRoundCheck, LuUserRoundX } from "react-icons/lu";
import { batch } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import InboxService from "services/inbox";

interface AssignAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  assignedAgentId: number | null | undefined;
  assignedTeamId: string | null | undefined;
  conversationId: number | undefined;
  customerId: number | undefined;
  conversationChannel: ConversationChannel | undefined;
}

const AgentOption = ({
  children,
  ...props
}: OptionProps<AgentSelectOption, false>) => {
  const {
    data: { value: optionAgentId },
  } = props;
  const { agents } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const optionAgent = agents.find((a: AgentDomain) => a.id === optionAgentId);

  return (
    <components.Option {...props}>
      <Flex justifyContent="start" alignItems="center" gridGap={4}>
        {optionAgent ? (
          <ProfileAvatar
            profilePicture={optionAgent.getPicture()}
            showInitials={true}
            name={optionAgent.getFullName()}
            avatarStyle={{
              colorPalette: optionAgent.color,
            }}
          />
        ) : (
          <Avatar.Root
            key="unassign-agent"
            size="sm"
            bgColor={
              colorMode === "dark" ? `${colorScheme}.500` : `${colorScheme}.50`
            }
          >
            <Icon
              as={LuUserRoundX}
              color={colorMode === "dark" ? "white" : "gray.800"}
              boxSize="1.5rem"
            />
          </Avatar.Root>
        )}
        {children}
      </Flex>
    </components.Option>
  );
};

interface AgentSelectOption {
  value: number | null;
  label: string;
}

interface TeamSelectOption {
  value: string | null;
  label: string;
}

const AssignAgentModal = memo(
  ({
    onClose,
    isOpen,
    assignedAgentId,
    assignedTeamId,
    conversationId,
    conversationChannel,
    customerId,
  }: AssignAgentModalProps) => {
    const dispatch = useAppDispatch();
    const auth0Context = useAuth0();
    const [agentId, setAgentId] = useState<number | null | undefined>(
      undefined
    );
    const { colorScheme } = useAppSelector((state) => state.theme);

    const navigate = useNavigate();
    const { search } = useLocation();

    const { selectedConversationIds, activeConversationId } = useAppSelector(
      (state) => state.conversations
    );
    const { colorMode } = useColorMode();
    const { assignAgent } = useConversationsStore();
    const { merchant } = useAppSelector((state) => state.merchant);
    const { agents, currentAgent, teams } = useAppSelector(
      (state) => state.agents
    );
    const { track } = useAnalytics();

    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );

    const [isBulkAssigning, setIsBulkAssigning] = useState<boolean>(false);

    const [agentOptions, setAgentOptions] = useState<AgentSelectOption[]>([
      {
        label: "Unassign",
        value: null,
      },
      ...agents
        .filter((a) => a.isActive)
        .map((a) => ({
          label: a.getFullName(),
          value: a.id,
        })),
    ]);

    useEffect(() => {
      setAgentId(assignedAgentId);
      setSelectedAgentValue(
        agentOptions.find(({ value }) => value === assignedAgentId) || null
      );
    }, [assignedAgentId, agentOptions, isOpen]);

    const [newTeamId, setNewTeamId] = useState<string | null | undefined>(
      undefined
    );

    useEffect(() => {
      const newAgentOptions = [
        {
          label: "Unassign",
          value: null,
        },
        ...agents
          .filter((a) => {
            if (!a.isActive) {
              return false;
            }

            if (!newTeamId) {
              return true;
            }

            const team = teams.find((t) => t.id === newTeamId);

            if (!team) {
              return true;
            }

            return team.agentIds.includes(a.id);
          })
          .map((a) => ({
            label: a.getFullName(),
            value: a.id,
          })),
      ];

      setAgentOptions(newAgentOptions);
    }, [agents, newTeamId]);

    const [selectedAgentValue, setSelectedAgentValue] = useState<
      SingleValue<AgentSelectOption>
    >(agentOptions.find(({ value }) => value === agentId) || null);

    const handleAgentChange = (e: SingleValue<AgentSelectOption>) => {
      const newAgentId = e?.value && Number(e.value);

      setAgentId(newAgentId);
      setSelectedAgentValue(e);
    };

    useEffect(() => {
      setNewTeamId(assignedTeamId);
    }, [assignedTeamId]);

    const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>([
      {
        label: "Unassign",
        value: null,
      },
      ...teams.map((team) => ({
        label: team.name,
        value: team.id,
      })),
    ]);
    const [selectedTeamValue, setSelectedTeamValue] =
      useState<SingleValue<TeamSelectOption>>(null);

    useEffect(() => {
      const newTeamOptions = [
        {
          label: "Unassign",
          value: null,
        },
        ...teams.map((team) => ({
          label: team.name,
          value: team.id,
        })),
      ];

      if (selectedTeamValue) {
        const selectedTeamStillExists = newTeamOptions.find(
          (team) => team.value === selectedTeamValue.value
        );

        if (!selectedTeamStillExists) {
          setSelectedTeamValue(null);
        }
      }

      setTeamOptions(newTeamOptions);
    }, [teams]);

    const assignAndClose = async () => {
      if (!conversationId) {
        if (selectedConversationIds.length) {
          setIsBulkAssigning(true);
          dispatch(startLoadingBulkActionsToolbar());

          try {
            const updatedConversationIds =
              await InboxService.bulkAssignConversations(
                auth0Context,
                merchant.id,
                selectedConversationIds,
                agentId,
                newTeamId
              );

            if (updatedConversationIds.length === 0) {
              toaster.create({
                type: "error",
                title: "Failed to perform bulk action. Please try again later.",
              });

              return;
            }

            batch(() => {
              updatedConversationIds.forEach((updatedConversationId) => {
                dispatch(
                  updateConversationAgentAndTeamIds({
                    conversationId: updatedConversationId,
                    agentId: agentId || null,
                    teamId: newTeamId || null,
                  })
                );
              });
              dispatch(clearSelectedConversations());
            });
          } catch (_error: unknown) {
            toaster.create({
              type: "error",
              title: "Failed to perform bulk action. Please try again later.",
            });
          } finally {
            dispatch(stopLoadingBulkActionsToolbar());
            setIsBulkAssigning(false);
            onClose();
          }
        }

        return;
      }

      await assignAgent(
        { conversationId, agentId, teamId: newTeamId },
        currentAgent!.id
      );

      if (activeConversationId && agentId !== currentAgent!.id) {
        navigate({
          pathname: `/${merchant.id}/inbox/`,
          search: createSearchParams(search).toString(),
        });
      }
      onClose();
    };

    useEffect(() => {
      if (!isOpen) {
        setSelectedAgentValue(null);
        setSelectedTeamValue(null);
        setAgentId(undefined);
        setNewTeamId(undefined);
      }
    }, [isOpen]);

    return (
      <DialogRoot
        open={isOpen}
        onOpenChange={({ open: newIsOpen }) => {
          if (!newIsOpen) {
            onClose();
          }
        }}
        size={isBaseSize ? "full" : "md"}
      >
        <DialogBackdrop />
        <DialogContent zIndex={999999}>
          <DialogCloseTrigger />

          <DialogHeader display="flex" alignItems="center" gap={1}>
            <Icon
              as={LuUserRoundCheck}
              color={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
            <Heading as="h4">Assign to</Heading>
          </DialogHeader>

          <DialogBody>
            <Fieldset.Root>
              <Fieldset.Content>
                <Field label="Team">
                  <Select
                    placeholder="Select Team"
                    onChange={(newTeam) => {
                      setNewTeamId(newTeam?.value);
                      setSelectedTeamValue(newTeam);
                    }}
                    isClearable={true}
                    options={teamOptions}
                    value={selectedTeamValue}
                    styles={{
                      ...{
                        ...getReactSelectStyles(
                          colorMode || "light",
                          colorScheme
                        ),
                        container: (provided: any) => ({
                          ...provided,
                          width: "100%",
                        }),
                      },
                    }}
                  />
                </Field>
                <Field label="Agent">
                  <Select
                    placeholder="Select an agent"
                    isClearable={true}
                    onChange={handleAgentChange}
                    options={agentOptions}
                    value={selectedAgentValue}
                    styles={{
                      ...{
                        ...getReactSelectStyles(
                          colorMode || "light",
                          colorScheme
                        ),
                        container: (provided: any) => ({
                          ...provided,
                          width: "100%",
                        }),
                      },
                    }}
                    components={{ Option: AgentOption }}
                  />
                </Field>
              </Fieldset.Content>
              <Button
                colorPalette={colorScheme}
                disabled={
                  !(
                    (conversationId || selectedConversationIds.length > 0) &&
                    (selectedAgentValue !== null || selectedTeamValue !== null)
                  ) || isBulkAssigning
                }
                onClick={() => {
                  track("assign_agent_attempt_succeeded", {
                    assigned_agent_id: agentId,
                    assigned_team_id: newTeamId,
                    conversation_id: conversationId || null,
                    customer_id: customerId,
                    channel: conversationChannel,
                  });
                  assignAndClose();
                }}
                width="100%"
                mt={8}
                mb={2}
                size="md"
              >
                Assign
              </Button>
            </Fieldset.Root>
          </DialogBody>
        </DialogContent>
      </DialogRoot>
    );
  }
);

export default AssignAgentModal;
