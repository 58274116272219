import {
    UseDisclosureProps,
    useBreakpointValue,
} from "@chakra-ui/react";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogHeader, DialogRoot } from "components/ui/dialog";
import React, { useCallback } from "react";
import SubmitFileForm from "./SubmitFileForm";

interface SubmitFileFormProps {
  disclosureProps?: UseDisclosureProps;
  files: File[];
  size: "sm" | "md" | "lg";
  submitButtonText?: string;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  onSubmit?: (files: File[]) => Promise<void>;
}

const SubmitFile = ({
  disclosureProps,
  size,
  submitButtonText,
  files,
  setFiles,
  onSubmit,
}: SubmitFileFormProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onClose = useCallback(() => {
    setFiles([]);
    if (
      disclosureProps !== undefined &&
      disclosureProps.onClose !== undefined
    ) {
      disclosureProps.onClose();
    }
  }, [disclosureProps]);

  if (onSubmit === undefined) {
    return null;
  }

  return disclosureProps ? (
    <DialogRoot       size={isBaseSize ? "full" : "md"}
      open={disclosureProps.open || false}
      onOpenChange={({ open: newIsOpen }) => { if (!newIsOpen) { onClose(); } }}
    >
      <DialogBackdrop  />
      <DialogContent>
        <DialogHeader>Upload File</DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <SubmitFileForm
            submitButtonText={submitButtonText}
            isInModal={true}
            size={isBaseSize ? "md" : size}
            files={files}
            setFiles={setFiles}
            onSubmit={onSubmit}
          />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  ) : (
    <SubmitFileForm
      submitButtonText={submitButtonText}
      size={size}
      files={files}
      setFiles={setFiles}
      onSubmit={onSubmit}
    />
  );
};

export default SubmitFile;
