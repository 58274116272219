import { useAuth0 } from "@auth0/auth0-react";

import MessageDomain from "entities/domain/conversations/message-domain";
import useAnalytics from "hooks/use-analytics";
import React, { useEffect, useState } from "react";

import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";

interface UseMagicWandProps {
  lastMessage?: MessageDomain;
  message?: string;
  setMessage: (m: string) => void;
  setIsLoading?: (loading: boolean) => void;
}

const useMagicWand = ({
  lastMessage,
  message,
  setMessage,
  setIsLoading,
}: UseMagicWandProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { track } = useAnalytics();
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );

  const [cachedMessage, setCachedMessage] = useState<string | undefined>();
  const [cachedImprovedMessage, setCachedImprovedMessage] = useState<
    string | undefined
  >();
  const [canCreateAutoSuggestion, setCanCreateAutoSuggestion] =
    useState<boolean>(false);

  const generateAutoReply = async () => {
    if (!lastMessage) {
      return;
    }

    if (typeof setIsLoading !== "undefined") {
      setIsLoading(true);
    }

    track("generate_auto_reply_attempt", {
      conversation_id: activeConversationId,
    });

    try {
      const suggestedReply = (
        await InboxService.getAutoReplyMessage(
          auth0Context,
          merchant.id,
          lastMessage.conversationId
        )
      ).suggested_reply;

      setMessage(suggestedReply);

      track("generate_auto_reply_attempt_succeeded", {
        conversation_id: activeConversationId,
      });
    } catch (error) {
      track("generate_auto_reply_attempt_failed", {
        conversation_id: activeConversationId,
      });
    } finally {
      if (typeof setIsLoading !== "undefined") {
        setIsLoading(false);
      }
    }
  };

  const executeAutoFix = async () => {
    if (!message) {
      return;
    }

    if (message === cachedMessage && cachedImprovedMessage) {
      track("get_cached_improved_message", {
        conversation_id: activeConversationId,
      });
      setMessage(cachedImprovedMessage);

      return;
    }

    if (message === cachedImprovedMessage) {
      track("get_original_message_back", {
        conversation_id: activeConversationId,
      });
      setMessage(cachedMessage!);

      return;
    }

    setCachedMessage(message);

    if (typeof setIsLoading !== "undefined") {
      setIsLoading(true);
    }

    let updatedMessage: string = message;

    try {
      track("get_improved_message_attempt", {
        conversation_id: activeConversationId,
      });

      updatedMessage = (
        await InboxService.getAutoFixedMessage(auth0Context, message)
      ).prettified_text;

      track("get_improved_message_attempt_succeeded", {
        conversation_id: activeConversationId,
      });
    } finally {
      if (typeof setIsLoading !== "undefined") {
        setIsLoading(false);
      }
    }

    setCachedImprovedMessage(updatedMessage);
    setMessage(updatedMessage);
  };

  useEffect(() => {
    setCanCreateAutoSuggestion(
      Boolean(
        lastMessage &&
          lastMessage.isIncoming &&
          lastMessage.body &&
          !lastMessage.isNotification() &&
          !lastMessage.getAttachmentType() &&
          !message
      )
    );
  }, [lastMessage, message]);

  return {
    abraKadabra: () => {
      if (canCreateAutoSuggestion) {
        generateAutoReply();
      } else {
        executeAutoFix();
      }
    },
    improvedMessage: cachedImprovedMessage,
  };
};

export default useMagicWand;
