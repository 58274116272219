import { Box, Spinner, Text } from "@chakra-ui/react";
import React from "react";

const LoadingReplySuggestion = ({ message }: { message: string }) => (
  <Box display="flex" alignItems="center" justifyContent="center" width="100%">
    <Spinner mr={2} />
    <Text>{message}</Text>
  </Box>
);

export default LoadingReplySuggestion;
