import { useAuth0 } from "@auth0/auth0-react";
import { Button, Fieldset, Input } from "@chakra-ui/react";
import { Field } from "components/ui/field";
import { toaster } from "components/ui/toaster";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import {
  BUTTON_COLOUR_SCHEME_VALUES,
  ContactFormDomain,
} from "entities/domain/contact-form";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ContactFormService from "services/contactForm";
import SelectMerchant from "../select-merchant";

const colourSchemeOptions = BUTTON_COLOUR_SCHEME_VALUES.map((colourScheme) => {
  return { value: colourScheme, label: colourScheme };
});

const ContactFormConfig = () => {
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [selectedMerchantPublicId, setSelectedMerchantPublicId] = useState<
    string | null
  >(null);
  const [contactFormConfig, setContactFormConfig] =
    useState<ContactFormDomain | null>(null);
  const auth0Context = useAuth0();

  const loadContactFormConfig = async () => {
    if (selectedMerchantPublicId) {
      setContactFormConfig(
        await ContactFormService.fetchConfigs(selectedMerchantPublicId)
      );
    }
  };

  useEffect(() => {
    setSelectedMerchantPublicId(selectedMerchant?.public_id || null);
  }, [selectedMerchant]);

  useEffect(() => {
    loadContactFormConfig();
  }, [selectedMerchantPublicId]);

  const saveConfig = async () => {
    try {
      if (selectedMerchant?.id) {
        await ContactFormService.saveConfigs(
          selectedMerchant.id,
          contactFormConfig || {},
          auth0Context
        );

        toaster.create({
          type: "success",
          title: "Contact Form Config has been saved successfully.",
        });
      }
    } catch (err) {
      toaster.create({
        type: "error",
        title: "An error occurred",
      });
    }
  };

  const handleSaveConfig = () => {
    saveConfig();
  };

  return (
    <Fieldset.Root>
      <Fieldset.Content>
        <Field label="Select Merchant" required>
          <SelectMerchant
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
          />
        </Field>
        {contactFormConfig && (
          <>
            <Field label="Background Colour">
              <Input
                value={contactFormConfig.bgColour || ""}
                onChange={(e) =>
                  setContactFormConfig({
                    ...contactFormConfig,
                    bgColour: e.target.value,
                  })
                }
              />
            </Field>
            <Field label="Font Colour">
              <Input
                value={contactFormConfig.fontColour || ""}
                onChange={(e) =>
                  setContactFormConfig({
                    ...contactFormConfig,
                    fontColour: e.target.value,
                  })
                }
              />
            </Field>
            <Field label="Border Colour">
              <Input
                value={contactFormConfig.borderColour || ""}
                onChange={(e) =>
                  setContactFormConfig({
                    ...contactFormConfig,
                    borderColour: e.target.value,
                  })
                }
              />
            </Field>
            <Field label="Title">
              <Input
                value={contactFormConfig.title || ""}
                onChange={(e) =>
                  setContactFormConfig({
                    ...contactFormConfig,
                    title: e.target.value,
                  })
                }
              />
            </Field>
            <Field label="Subtitle">
              <Input
                value={contactFormConfig.subtitle || ""}
                onChange={(e) =>
                  setContactFormConfig({
                    ...contactFormConfig,
                    subtitle: e.target.value,
                  })
                }
              />
            </Field>
            <Field label="Button Colour Scheme">
              <Select
                options={colourSchemeOptions}
                value={colourSchemeOptions.filter(
                  (option) =>
                    option.value === contactFormConfig.buttonColourScheme
                )}
                onChange={(e) =>
                  setContactFormConfig({
                    ...contactFormConfig,
                    buttonColourScheme: e?.value,
                  })
                }
                isClearable={true}
                menuPlacement="auto"
              />
            </Field>
          </>
        )}
      </Fieldset.Content>
      <Button
        onClick={handleSaveConfig}
        disabled={!selectedMerchant}
        mt={30}
        mb={30}
        size="lg"
      >
        Save
      </Button>
    </Fieldset.Root>
  );
};

export default ContactFormConfig;
