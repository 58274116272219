import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Icon as ChakraIcon,
  Fieldset,
  Flex,
  HStack,
  Icon,
  Input,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import SelectCustomer from "components/reviews/SelectCustomer";
import { OptionTypes } from "components/shared/filter";
import DatePickerWithSuspense from "components/shared/LazyDatePicker";
import { Avatar } from "components/ui/avatar";
import { useColorMode } from "components/ui/color-mode";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { Field } from "components/ui/field";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import VehicleDomain from "entities/domain/vehicle";
import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import { transformFromContactChannelToOptions } from "entities/transformers/contact-transformer";
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LuCar, LuUserRoundPlus } from "react-icons/lu";
import Select, {
  ActionMeta,
  GetOptionLabel,
  MultiValue,
  OptionProps,
  SingleValue,
  components,
} from "react-select";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import { getReactSelectStyles } from "util/methods";

interface AgentOptionTypes {
  value: number;
}
interface VehicleOptionTypes {
  value: string;
  label: string;
  icon?: string;
}
interface ChannelOptionTypes {
  value: string;
}

const VehicleColumn = ({ item }: { item: VehicleOptionTypes }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      px={8}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Avatar
        key={item.value}
        size="sm"
        src={item.icon}
        name={item.label}
        fallback={<Icon as={LuCar} boxSize={4} />}
      />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const VehicleOption = function (props: OptionProps<VehicleOptionTypes>) {
  return (
    <components.Option {...props}>
      <VehicleColumn item={props.data} />
    </components.Option>
  );
};

interface EventFormProps {
  openEventForm: boolean;
  closeNewEventForm: () => void;
  titleValue?: string;
  handleTitleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  startDateTime: Date | null;
  onStartDateChange: (date: Date | null) => void;
  endDateTime: Date | null;
  onEndDateChange: (date: Date | null) => void;
  selectedCustomer: ContactDomain | null;
  selectedVehicle: VehicleDomain | null;
  handleSelectedVehicle: (e: VehicleDomain | null) => void;
  customerVehicles: VehicleDomain[];
  selectedChannel?: ChannelOptionTypes | null;
  handleSelectedCustomer: (e: ContactDomain | null) => void;
  handleSelectedChannel: (e: SingleValue<ChannelOptionTypes>) => void;
  agentValue: MultiValue<AgentOptionTypes>;
  handleSelectedAgents: (e: MultiValue<AgentOptionTypes>) => void;
  handleDescChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  customerId?: number;
  defaultChannelId?: string;
  descValue?: string;
  onFormSubmit: () => void;
  SubmitButtonText: string;
  handleNotifyCustomer: (e: ChangeEvent<HTMLInputElement>) => void;
  notifyCustomer: boolean;
  setNewCustomer: (customer: ContactDomain) => void;
  customerEdited?: boolean;
  isEdit?: boolean;
  getOptionLabels: (op: OptionTypes) => ReactElement;
  notificationChannelSelected?: boolean;
  isDisabled?: boolean;
}

const EventForm = ({
  openEventForm,
  customerId,
  defaultChannelId,
  closeNewEventForm,
  titleValue,
  handleTitleChange,
  startDateTime,
  onStartDateChange,
  customerVehicles,
  selectedVehicle,
  handleSelectedVehicle,
  endDateTime,
  onEndDateChange,
  selectedCustomer,
  selectedChannel,
  handleSelectedCustomer,
  handleSelectedChannel,
  agentValue,
  handleSelectedAgents,
  handleDescChange,
  descValue,
  onFormSubmit,
  SubmitButtonText,
  handleNotifyCustomer,
  notifyCustomer,
  setNewCustomer,
  customerEdited,
  getOptionLabels,
  isEdit,
  notificationChannelSelected,
  isDisabled,
}: EventFormProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { agents } = useAppSelector((state) => state.agents);
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const [contactChannels, setContactChannels] = useState<
    CustomerChannelDomain[] | undefined
  >(undefined);
  const [selectedTime, setSelectedTime] = useState<Date>(new Date());

  const doNotNotifyOption = { value: "", label: "Do not notify" };
  const displayChannelNotificationOptions: boolean =
    !isEdit || customerEdited || (isEdit && !notificationChannelSelected);
  const displayChannelNotificationCheckbox: boolean | undefined =
    isEdit && !customerEdited && notificationChannelSelected;

  const channelOptions: OptionTypes[] | undefined = contactChannels
    ? [
        ...contactChannels.map(transformFromContactChannelToOptions),
        doNotNotifyOption,
      ]
    : [];

  const agentOptions: AgentOptionTypes[] = agents.map(
    transformFromAgentDomainToOptionType
  );

  const vehicleOptions: VehicleOptionTypes[] = useMemo(() => {
    return customerVehicles.map((vehicle) => ({
      value: vehicle.id!,
      label: `${vehicle.model} - ${vehicle.licensePlate}`,
      icon: vehicle.getIconSource() || undefined,
    }));
  }, [customerVehicles]);

  useEffect(() => {
    if (startDateTime) setSelectedTime(startDateTime);
  }, [startDateTime]);

  useEffect(() => {
    if (!notificationChannelSelected) {
      handleSelectedChannel(doNotNotifyOption);
    } else {
      handleSelectedChannel(null);
    }

    if (selectedCustomer && selectedCustomer?.id) {
      ContactsService.getContact(
        auth0Context,
        selectedCustomer.id!,
        merchant.groupId
      ).then((fetchedContact: ContactDomain | undefined) => {
        if (!fetchedContact) {
          return;
        }

        const defaultChannel = fetchedContact.channels.filter(
          (chan) => chan.id === defaultChannelId
        )[0];

        if (defaultChannel) {
          handleSelectedChannel(
            transformFromContactChannelToOptions(defaultChannel)
          );
        }

        setContactChannels(fetchedContact.channels);
      });
    }
  }, [selectedCustomer, customerId]);

  return (
    <DialogRoot
      open={openEventForm}
      size={isBaseSize ? "full" : "md"}
      onInteractOutside={closeNewEventForm}
      onEscapeKeyDown={closeNewEventForm}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>{isEdit ? "Update event" : "New event"}</DialogHeader>
        <DialogCloseTrigger onClick={closeNewEventForm} />
        <DialogBody>
          <Fieldset.Root id="event-form" data-testid="event-form">
            <Fieldset.Content>
              <Field
                label="Event Title"
                invalid={isDisabled && !titleValue}
                errorText="* Please add a title to continue"
                required
              >
                <Input
                  colorPalette={colorScheme}
                  value={titleValue}
                  variant="flushed"
                  placeholder="Demo Meeting"
                  onChange={handleTitleChange}
                  id="event-form-title"
                  required
                />
              </Field>
              <Box
                css={{
                  "& input": {
                    padding: isBaseSize ? "5px" : "10px",
                    border: "1px solid #e2e8f0",
                    borderRadius: "50px",
                    backgroundColor:
                      colorMode === "dark" ? "gray.900" : "white",
                  },
                  "& .react-datepicker__header": {
                    borderBottom: "none",
                  },
                }}
              >
                <Field mt={4}>
                  <HStack
                    justify="space-between"
                    css={{
                      "#event-form-end-date": {
                        width: "100%",
                      },
                      "#event-form-start-date": {
                        width: "100%",
                      },
                    }}
                  >
                    <DatePickerWithSuspense
                      id="event-form-start-date"
                      selected={startDateTime}
                      onChange={onStartDateChange}
                      selectsStart
                      startDate={startDateTime}
                      endDate={endDateTime}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, h:mm aa"
                    />

                    <DatePickerWithSuspense
                      id="event-form-end-date"
                      selected={endDateTime}
                      onChange={onEndDateChange}
                      selectsEnd
                      startDate={startDateTime}
                      endDate={endDateTime}
                      minDate={startDateTime}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, h:mm aa"
                      minTime={selectedTime}
                      maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                    />
                  </HStack>
                </Field>
              </Box>
              <Field
                mt={4}
                id="event-form-customer-dropdown-area"
                label="Customer"
              >
                <SelectCustomer
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={handleSelectedCustomer}
                />
                <Button
                  colorPalette={colorScheme}
                  variant="subtle"
                  size="xs"
                  onClick={() => setOpenNewContactForm(true)}
                  id="event-form-add-customer-button"
                >
                  <ChakraIcon
                    as={LuUserRoundPlus}
                    fontSize={15}
                    mr="5px"
                    ml="5px"
                    pt={0.5}
                    color="currentColor"
                  />
                  Add a new contact
                </Button>
              </Field>

              {selectedCustomer && displayChannelNotificationOptions && (
                <>
                  <Field
                    mt={4}
                    id="event-form-channel-notification-dropdown-area"
                    label="Notify Customer?"
                  >
                    <Select
                      id="event-form-channel-notification-dropdown"
                      placeholder="Select Channel"
                      value={selectedChannel}
                      onChange={
                        handleSelectedChannel as (
                          newValue: unknown,
                          actionMeta: ActionMeta<unknown>
                        ) => void
                      }
                      options={channelOptions}
                      getOptionLabel={
                        getOptionLabels as unknown as GetOptionLabel<unknown>
                      }
                      styles={{
                        ...{
                          ...getReactSelectStyles(
                            colorMode || "light",
                            colorScheme
                          ),
                          container: (provided: any) => ({
                            ...provided,
                            width: "100%",
                          }),
                        },
                      }}
                    />
                  </Field>
                  <Field
                    mt={4}
                    id="event-form-vehicle-dropdown-area"
                    label="Vehicle"
                  >
                    <Select
                      placeholder="Select Vehicle"
                      isMulti={false}
                      value={
                        selectedVehicle
                          ? ({
                              value: selectedVehicle.id,
                              label: `${selectedVehicle.model} - ${selectedVehicle.licensePlate}`,
                              icon:
                                selectedVehicle.getIconSource() || undefined,
                            } as VehicleOptionTypes)
                          : null
                      }
                      onChange={(
                        newSelectedVehicleOption: SingleValue<VehicleOptionTypes>
                      ) => {
                        if (!newSelectedVehicleOption) {
                          handleSelectedVehicle(null);
                          return;
                        }

                        const newSelectedVehicle = customerVehicles.find(
                          (v) => v.id === newSelectedVehicleOption.value
                        );
                        handleSelectedVehicle(newSelectedVehicle || null);
                      }}
                      options={vehicleOptions}
                      components={{
                        Option: VehicleOption,
                      }}
                      styles={{
                        ...{
                          ...getReactSelectStyles(
                            colorMode || "light",
                            colorScheme
                          ),
                          container: (provided: any) => ({
                            ...provided,
                            width: "100%",
                          }),
                        },
                      }}
                    />
                  </Field>
                </>
              )}

              <Field mt={4} label="Agent Assignee">
                <Select
                  isMulti
                  placeholder="Select Agent"
                  value={agentValue}
                  onChange={
                    handleSelectedAgents as (
                      newValue: unknown,
                      actionMeta: ActionMeta<unknown>
                    ) => void
                  }
                  options={agentOptions}
                  closeMenuOnSelect={false}
                  styles={{
                    ...{
                      ...getReactSelectStyles(
                        colorMode || "light",
                        colorScheme
                      ),
                      container: (provided: any) => ({
                        ...provided,
                        width: "100%",
                      }),
                    },
                  }}
                />
              </Field>
              <Field mt={4} label="Description">
                <Textarea
                  onChange={handleDescChange}
                  placeholder="Description here..."
                  value={descValue}
                />
              </Field>
            </Fieldset.Content>
          </Fieldset.Root>
          <Flex direction="column" margin="0 15px" pb="15px" pt="15px">
            {selectedCustomer && displayChannelNotificationCheckbox && (
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                mb="15px"
                id="event-form-customer-notification-checkbox-row"
              >
                <Text>Notify customer</Text>

                <input
                  style={{
                    marginLeft: "5px",
                  }}
                  id="event-form-customer-notification-checkbox"
                  checked={notifyCustomer}
                  type="checkbox"
                  onChange={handleNotifyCustomer}
                />
              </Flex>
            )}

            <Button
              colorPalette={colorScheme}
              w="100%"
              disabled={isDisabled}
              onClick={onFormSubmit}
              id="event-form-save-button"
            >
              {SubmitButtonText}
            </Button>
          </Flex>
          <QuickAddContactForm
            isOpen={openNewContactForm}
            onClose={() => setOpenNewContactForm(false)}
            setRecentlyCreatedContact={setNewCustomer}
          />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

export default EventForm;
