import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { useColorMode } from "components/ui/color-mode";
import { toaster } from "components/ui/toaster";
import AttributeDomain, {
  AttributeTypeDisplayNames,
} from "entities/domain/attributes/attribute-domain";
import { useSettingsContext } from "hooks/use-settings-context";
import React, { useCallback, useEffect, useRef } from "react";
import { LuPlus } from "react-icons/lu";
import { Outlet, useNavigate } from "react-router-dom";
import {
  DeleteAttributeForbiddenResponseData,
  deleteAttributeSuccess,
  fetchAttributesSuccess,
} from "redux/features/group";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AttributeService from "services/attributes";

const AttributeNameColumn = ({ item }: { item: AttributeDomain }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      textAlign="left"
      w="100%"
    >
      <Text
        w="100%"
        fontWeight={700}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {item.name}
      </Text>
    </Flex>
  );
};

const AttributeDataTypeColumn = ({ item }: { item: AttributeDomain }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      textAlign="left"
      w="100%"
    >
      <HStack gap={2} overflow="hidden" alignItems="start">
        <Tag.Root size="sm" variant="outline">
          <Tag.Label>
            {AttributeTypeDisplayNames[item.type].displayName}
          </Tag.Label>
        </Tag.Root>
      </HStack>
    </Flex>
  );
};

export const ContactAttributeSettings = () => {
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setActionButton } = useSettingsContext();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const { merchant } = useAppSelector((state) => state.merchant);
  const { attributes } = useAppSelector((state) => state.group);

  const fetchAttributes = useCallback(async () => {
    if (merchant && merchant.groupId) {
      const fetchedAttributes = await AttributeService.getAttributesForGroup(
        auth0Context,
        merchant.groupId
      );

      dispatch(fetchAttributesSuccess(fetchedAttributes));
    }
  }, [merchant.groupId]);

  useEffect(() => {
    fetchAttributes();
  }, [fetchAttributes]);

  const getIndividualActions = (
    attributeitem: AttributeDomain
  ): SmartListIndividualAction<AttributeDomain>[] | undefined => {
    if (!attributeitem) {
      return;
    }

    return [
      {
        label: "Edit",
        execute: () => {
          navigate({
            pathname: `/${merchant.id}/settings/attributes/${attributeitem.id}`,
          });
        },
      },
      {
        label: "Delete",
        execute: async () => {
          try {
            await AttributeService.deleteAttribute(
              auth0Context,
              merchant.groupId,
              attributeitem.id
            );

            dispatch(deleteAttributeSuccess(attributeitem));
          } catch (error: unknown) {
            if (!(error instanceof AxiosError) || !error.response) {
              return null;
            }

            const errorMessage =
              error.response.status === 403
                ? (error.response.data as DeleteAttributeForbiddenResponseData)
                    .message
                : "An unexpected error occured";

            toaster.create({
              type: "error",
              title: errorMessage,
            });
          }
        },
      },
    ];
  };

  useEffect(() => {
    if (!isBaseSize) {
      setActionButton(null);
      return;
    }

    setActionButton(
      <IconButton
        aria-label="Create Attribute"
        colorPalette={colorScheme}
        onClick={() => {
          navigate(`/${merchant.id}/settings/attributes/create`);
        }}
      >
        <Icon
          as={LuPlus}
          fill={colorMode === "dark" ? `${colorScheme}.800` : "white"}
        />
      </IconButton>
    );

    return () => setActionButton(null);
  }, [isBaseSize]);

  return (
    <VStack w="100%" h="100%" gap={0}>
      {isBaseSize ? null : (
        <Flex
          w="100%"
          justifyContent="start"
          alignItems="center"
          py={4}
          gridGap={2}
        >
          <Button
            colorPalette={colorScheme}
            gridGap={2}
            size="md"
            onClick={() => {
              navigate(`/${merchant.id}/settings/attributes/create`);
            }}
          >
            <Icon
              as={LuPlus}
              fill={colorMode === "dark" ? `${colorScheme}.800` : "white"}
            />
            <Text>Create Attribute</Text>
          </Button>
        </Flex>
      )}
      <Box
        w="100%"
        h="100%"
        {...(isBaseSize
          ? { overflow: "hidden" }
          : { overflow: "hidden", borderTopRadius: "3xl" })}
      >
        <Box h="100%" w="100%" mx="auto">
          <Box
            overflowY="auto"
            w="100%"
            h="100%"
            {...(isBaseSize
              ? {}
              : {
                  borderTopRadius: "3xl",
                  bgColor: colorMode === "dark" ? "gray.800" : "white",
                })}
            justifyContent="center"
            alignItems="start"
            ref={scrollContainerRef}
          >
            <SmartList
              hasBulkActions={false}
              itemIdentifier="id"
              columns={[
                {
                  label: "Name",
                  component: AttributeNameColumn,
                },
                {
                  label: "Data type",
                  component: AttributeDataTypeColumn,
                },
              ]}
              items={attributes}
              containerRef={scrollContainerRef}
              getIndividualActions={getIndividualActions}
            />
          </Box>
        </Box>
      </Box>
      <Outlet />
    </VStack>
  );
};
