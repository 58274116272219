import { VStack, useBreakpointValue } from "@chakra-ui/react";
import DesktopSwitchButton from "components/merchant-switch/desktop-button";
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from "components/ui/dialog";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import React, { useEffect, useState } from "react";
import { setIsSelectorOpened } from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface SwitchAccountProps {
  merchants: AgentMerchantDomain[];
  required?: boolean;
}

const SwitchAccount = ({ required = false, merchants }: SwitchAccountProps) => {
  const dispatch = useAppDispatch();
  const { merchant: currentMerchant, isSelectorOpened } = useAppSelector(
    (state) => state.merchant
  );
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { currentAgent, unreadCounts } = useAppSelector(
    (state) => state.agents
  );

  const [unreadCountByMerchant, setUnreadCountByMerchant] = useState<{
    [key: number]: number;
  }>({});

  useEffect(() => {
    const newUnreadCountByMerchant = unreadCounts.reduce(
      (acc, uc) => ({
        ...acc,
        [uc.merchant_id]: uc.shared,
      }),
      {}
    );

    setUnreadCountByMerchant(newUnreadCountByMerchant);
  }, [unreadCounts]);

  if (!currentAgent || !currentMerchant) {
    return null;
  }

  return (
    <DialogRoot
      {...(required
        ? {
            closeOnEscape: false,
            closeOnInteractOutside: false,
          }
        : {})}
      open={isSelectorOpened}
      size={isBaseSize ? "full" : "md"}
      onOpenChange={({ open: newIsOpen }) => {
        if (newIsOpen) {
          return;
        }

        dispatch(setIsSelectorOpened(false));
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader textAlign="center">Select Account</DialogHeader>
        {required ? null : <DialogCloseTrigger />}
        <DialogBody>
          <VStack w={isBaseSize ? "100%" : "80%"} mx="auto" mb={4}>
            {merchants
              .sort(AgentMerchantDomain.sortMerchantsAlphabetically)
              .map((merchant, index) => (
                <DesktopSwitchButton
                  key={merchant.id}
                  merchantOption={merchant}
                  index={index}
                  size="md"
                  unreadCount={unreadCountByMerchant[merchant.id]}
                />
              ))}
          </VStack>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

export default SwitchAccount;
