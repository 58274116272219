import { Flex, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import TagsList from "components/tags/list";
import { Alert } from "components/ui/alert";
import { useColorMode } from "components/ui/color-mode";
import React, { memo, useEffect, useState } from "react";
import { LuChevronDown } from "react-icons/lu";
import { useAppSelector } from "redux/hooks";

interface SnippetHeaderProps {
  customerName: string;
  date: string;
  tagIds: string[];
  isSubscribed: boolean;
  assignedTeamId: string | null;
}

const SnippetHeader = memo(
  ({
    customerName,
    date,
    tagIds,
    assignedTeamId,
    isSubscribed,
  }: SnippetHeaderProps) => {
    const { colorMode } = useColorMode();
    const { teams } = useAppSelector((state) => state.agents);
    const { selectedInbox } = useAppSelector((state) => state.conversations);

    const [teamName, setTeamName] = useState<string>("");

    useEffect(() => {
      if (assignedTeamId === null) {
        setTeamName("");
        return;
      }

      const team = teams.find((t) => t.id === assignedTeamId);
      setTeamName(team?.name || "");
    }, [assignedTeamId]);

    return (
      <VStack mb={2} alignItems="start" gap={0}>
        <HStack
          justifyContent="space-between"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Text
            flexGrow={1}
            flexShrink={0}
            flexBasis="auto"
            maxWidth="33%"
            fontWeight="bold"
            fontSize="sm"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {customerName}
          </Text>
          {isSubscribed ? (
            <TagsList tagIds={tagIds} defaultMaxVisibleTags={1} />
          ) : (
            <Flex justifySelf="start" alignItems="center" w="100%">
              <Alert
                p={1}
                pr={2}
                gap={1}
                w="fit-content"
                status="warning"
                borderRadius="full"
                variant="subtle"
                title="Unsubscribed"
              />
            </Flex>
          )}
          <Text
            flexShrink={0}
            flexGrow={0}
            flexBasis="auto"
            fontSize="xs"
            color={colorMode === "dark" ? "gray.600" : "gray.200"}
          >
            {date}
          </Text>
        </HStack>
        {teamName && selectedInbox !== assignedTeamId ? (
          <HStack gap={0.5}>
            <Icon
              as={LuChevronDown}
              transform="rotate(-90deg)"
              width="0.5rem"
              height="0.5rem"
              color={colorMode === "dark" ? "gray.600" : "gray.200"}
            />
            <Text
              fontSize="xs"
              fontWeight="extrabold"
              color={colorMode === "dark" ? "gray.600" : "gray.200"}
            >
              {teamName}
            </Text>
          </HStack>
        ) : null}
      </VStack>
    );
  }
);

export default SnippetHeader;
